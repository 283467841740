// Vendor
import React, { DragEvent, FC } from 'react';
import classnames from 'classnames/bind';
import { Button, makeStyles } from '@material-ui/core';

// Internal
import { mainColor } from '../../constants';
import { DragAndMoveTabItems, LeftPanelItems } from '../../types';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

const useStyles = makeStyles(() => ({
  root: {
    background: mainColor,
    flex: 'none',
    flexDirection: 'row',
    flexGrow: 0,
    fontSize: 14,
    gap: 6,
    order: 0,
    padding: 0,
    width: '50%',
  },
  button: {
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #E8E7EB',
    borderRadius: 9,
    justifyContent: 'center',
    marginBottom: 20,
    width: '100%',
  },
}));

export interface Props {
  dragAndMoveData: LeftPanelItems;
}

const LeftPanel: FC<Props> = (props) => {
  const classes = useStyles();

  const { dragAndMoveData } = props;
  const { buttonLabel, buttonOnClick, items, title } = dragAndMoveData;

  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    /* eslint-disable no-param-reassign */
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className={cx('root')}>
      <div
        key={`editing-tools-title-${buttonLabel}`}
        style={{ height: '100%' }}
      >
        <span data-testid='title' className={cx('title')}>
          {title}
        </span>
        <div className={cx('draggableRoot')}>
          {items.map((item: DragAndMoveTabItems) => (
            <div
              className={cx('draggableNode')}
              data-testid={item.name}
              draggable
              key={`${item.name}-draggable-node`}
              onDragStart={(event: DragEvent<HTMLDivElement>) =>
                onDragStart(event, JSON.stringify(item))
              }
            >
              {item.name}
            </div>
          ))}
        </div>
        {buttonLabel && (
          <Button
            className={classes.button}
            data-testid='button'
            onClick={buttonOnClick}
            style={{
              background: '#FFFFFF',
              border: '1px solid #E8E7EB',
              color: '#0B1641',
              marginTop: 20,
            }}
            variant='contained'
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LeftPanel;
