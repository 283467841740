// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { MenuItem } from '@material-ui/core';

// Internal
import { Select } from 'components';
import { TradingPartner } from 'query';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  gpo?: number;
  setGPO: (gpo: number) => void;
  tradingPartners: TradingPartner[];
}

const GPO: FC<Props> = (props) => {
  const { gpo, setGPO, tradingPartners } = props;

  return (
    <div className={cx('root')} data-testid='gpoStep'>
      <span className={cx('title')}>
        Which GPO would you like to choose a customer from?
      </span>
      <div className={cx('field')}>
        <span className={cx('key')}>GPO</span>
        <Select
          disabled={!tradingPartners}
          label={
            tradingPartners?.find((tp) => tp.id === gpo)?.name || 'Choose a GPO'
          }
          style={{
            marginLeft: 0,
            minWidth: 260,
            width: 136,
          }}
        >
          {tradingPartners.map((tp) => {
            const { id, name } = tp;
            return (
              <MenuItem
                key={id}
                onClick={() => {
                  setGPO(id);
                }}
                value={id}
                style={{
                  maxWidth: 'none',
                  paddingRight: 0,
                }}
              >
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default GPO;
