// Vendor
import React, { Dispatch, FC, SetStateAction } from 'react';
import classnames from 'classnames/bind';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import {
  ActionElement,
  ActionWithRulesAndAddersProps,
  QueryBuilder as ReactQueryBuilder,
  RuleGroupType,
  ValueSourceSelectorProps,
} from 'react-querybuilder';

// Internal
import { useDocumentTypes } from 'hooks';
import { IconTrash } from 'styles/images';
import {
  CustomComboSelector,
  CustomFieldSelector,
  CustomOperatorSelector,
  CustomValueEditor,
  CustomValueSourceSelector,
} from './components';
import { getFields, getInputType, getOperators, getValues } from './services';
import { FieldType } from '../../types';

// Styles
import './queryBuilder.css';
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  ifQuery: RuleGroupType;
  thenQuery: RuleGroupType;
  elseQuery: RuleGroupType;
  setIfQuery: Dispatch<SetStateAction<RuleGroupType>>;
  setThenQuery: Dispatch<SetStateAction<RuleGroupType>>;
  setElseQuery: Dispatch<SetStateAction<RuleGroupType>>;
  ruleSet: string;
}

// !! We have 3 seperate query builders for if(condition), then(action), and else(action) !!
const QueryBuilder: FC<Props> = (props) => {
  const {
    ifQuery,
    thenQuery,
    elseQuery,
    setIfQuery,
    setThenQuery,
    setElseQuery,
    ruleSet,
  } = props;

  const documentTypes = useDocumentTypes();

  const conditionFields = getFields(documentTypes, ruleSet);
  const actionFields = conditionFields.filter(
    (cf) => cf.label === 'Proposed Customer'
  );

  const {
    fieldData: { attributes },
    name: customerType,
  } = actionFields[0];
  const { attributes: secondaryAttributes, name: attributeName } =
    attributes[0];
  const defaultField = `${customerType}|${attributeName}${
    secondaryAttributes ? `|${secondaryAttributes[0].name}` : ''
  }`;

  const defaultReactQueryBuilderProps = {
    controlClassnames: { queryBuilder: 'queryBuilder-branches' },
    getDefaultField: defaultField,
    getInputType: (fieldName: string) =>
      getInputType(conditionFields as FieldType[], fieldName),
    getValueSources: () => ['value', 'select'],
    getValues: (fieldName: string) =>
      getValues(conditionFields as FieldType[], fieldName),
    showCloneButtons: true,
  };

  return (
    // Context provider required for drag and drop
    // https://react-querybuilder.js.org/docs/components/querybuilder#enabledraganddrop
    <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
      <div className={cx('title')}>Condition</div>
      <ReactQueryBuilder
        {...defaultReactQueryBuilderProps}
        addRuleToNewGroups
        // And and Or are default combinators but this is for casing purposes
        combinators={[
          { label: 'And', name: 'and' },
          { label: 'Or', name: 'or' },
          { label: 'Not', name: 'not' },
        ]}
        // queryBuilder-branches is for the cosmetic "branches" to the left of the rules
        controlElements={{
          combinatorSelector: CustomComboSelector,
          fieldSelector: CustomFieldSelector,
          operatorSelector: CustomOperatorSelector,
          removeRuleAction: (props: ActionWithRulesAndAddersProps) =>
            props.path[0] !== 0 ? <ActionElement {...props} /> : null,
          valueEditor: CustomValueEditor,
          valueSourceSelector: (props: ValueSourceSelectorProps) => (
            <CustomValueSourceSelector
              conditionFields={conditionFields}
              {...props}
            />
          ),
        }}
        fields={conditionFields}
        // Determining operators based on data type
        getOperators={(fieldName: string) =>
          getOperators(conditionFields as FieldType[], fieldName)
        }
        onQueryChange={(q) => setIfQuery(q)}
        translations={{
          // @ts-expect-error label type is string but element displays correctly
          removeGroup: { label: <IconTrash />, title: 'Remove rule' },
          // @ts-expect-error
          removeRule: { label: <IconTrash />, title: 'Remove rule' },
        }}
        query={ifQuery}
      />
      <div>
        <div className={cx('title')}>Action</div>
        <ReactQueryBuilder
          {...defaultReactQueryBuilderProps}
          combinators={[{ label: 'Then', name: 'then' }]}
          controlElements={{
            // There is no grouping button on the action query builders
            addGroupAction: () => null,
            combinatorSelector: CustomComboSelector,
            fieldSelector: CustomFieldSelector,
            operatorSelector: CustomOperatorSelector,
            removeRuleAction: (props: ActionWithRulesAndAddersProps) =>
              props.path[0] !== 0 ? <ActionElement {...props} /> : null,
            valueEditor: CustomValueEditor,
            valueSourceSelector: (props: ValueSourceSelectorProps) => (
              <CustomValueSourceSelector
                conditionFields={conditionFields}
                {...props}
              />
            ),
          }}
          fields={actionFields}
          // Action query builders should not allow the user to pick the operation, it's for assignment
          // so the only operator is equals and it's hard coded in the custom operator component
          operators={[{ name: '=', label: 'is' }]}
          onQueryChange={(q) => setThenQuery(q)}
          translations={{
            // @ts-expect-error label type is string but element displays correctly
            removeRule: { label: <IconTrash />, title: 'Remove rule' },
          }}
          query={thenQuery}
        />
        <ReactQueryBuilder
          {...defaultReactQueryBuilderProps}
          combinators={[{ label: 'Else', name: 'else' }]}
          controlElements={{
            addGroupAction: () => null,
            combinatorSelector: CustomComboSelector,
            fieldSelector: CustomFieldSelector,
            operatorSelector: CustomOperatorSelector,
            valueEditor: CustomValueEditor,
            valueSourceSelector: (props: ValueSourceSelectorProps) => (
              <CustomValueSourceSelector
                conditionFields={conditionFields}
                {...props}
              />
            ),
          }}
          fields={actionFields}
          operators={[{ name: '=', label: 'is' }]}
          onQueryChange={(q) => setElseQuery(q)}
          translations={{
            // @ts-expect-error label type is string but element displays correctly
            removeRule: { label: <IconTrash />, title: 'Remove rule' },
          }}
          query={elseQuery}
        />
      </div>
    </QueryBuilderDnD>
  );
};

export default QueryBuilder;
