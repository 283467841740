// Vendor
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useReducer } from 'react';
import { toLower } from 'lodash';

// Internal
import { KeyValueStorage } from 'common/helpers/keyValueStorage';

export const useQueryParams = (): URLSearchParams => {
  const { search } = useLocation();
  // Important to reuse the same reference
  return useMemo(() => new URLSearchParams(search), [search]);
};

type SerializeFn = (val: any) => string;
type DeserializeFn = (val: string) => any;

export interface ParamConfig {
  key: string;
  serialize: SerializeFn;
  parse: DeserializeFn;
  default?: any;
}

interface SearchConfig {
  module: string;
  params: ParamConfig[];
}

type Updater = (value: any) => void;
type ParamResult = [any, Updater];

const getSearchParamsResult = (
  currentParams: URLSearchParams,
  params: ParamConfig[],
  createParamUpdater: (
    targetParam: string
  ) => (serialize: SerializeFn) => Updater
): { [key: string]: ParamResult } =>
  params.reduce((acc, param) => {
    const target = currentParams.get(param.key);
    return {
      ...acc,
      [param.key]: [
        target ? param.parse(target) : param.default,
        createParamUpdater(param.key)(param.serialize),
      ],
    };
  }, {});

type SearchParamsState = string;

type SearchParamsAction =
  | { type: 'update'; payload: { key: string; value: string } }
  | { type: 'delete'; payload: { key: string } };

type SearchParamsReducer = (
  state: SearchParamsState,
  action: SearchParamsAction
) => SearchParamsState;

const reducer: SearchParamsReducer = (state, action) => {
  switch (action.type) {
    case 'update': {
      const currentURLParams = new URLSearchParams(state);
      currentURLParams.set(action.payload.key, action.payload.value);
      return currentURLParams.toString();
    }
    case 'delete': {
      const currentURLParams = new URLSearchParams(state);
      currentURLParams.delete(action.payload.key);
      return currentURLParams.toString();
    }
    default:
      return '';
  }
};

export const usePersistedSearchParams = (
  config: SearchConfig,
  storage: KeyValueStorage<string>
) => {
  const INTERNAL_KEY = 'SEARCH';
  const storeKey = `${INTERNAL_KEY}_${config.module}`;
  const params = useQueryParams();
  // We initialize our state with the current params
  const [state, dispatch] = useReducer(reducer, params.toString());
  const navigate = useNavigate();
  const location = useLocation();
  const moduleRegExp = new RegExp(`^[/]${config.module.toLowerCase()}$`);

  const createUpdater =
    (key: string) => (serialize: SerializeFn) => (value: any) => {
      if (value !== '') {
        dispatch({ type: 'update', payload: { key, value: serialize(value) } });
      } else {
        dispatch({ type: 'delete', payload: { key } });
      }
    };

  useEffect(() => {
    // We only synchronize when in the module search page
    const shouldSynchronize = moduleRegExp.test(toLower(location.pathname));
    const stateUpdated = state !== (storage.get(storeKey) || '');
    if (shouldSynchronize && stateUpdated) {
      storage.set(storeKey, state);
      navigate({ search: state }, { replace: true });
    }
  }, [state]);

  const result = useMemo(
    () => getSearchParamsResult(params, config.params, createUpdater),
    [params]
  );

  return {
    currentSearch: result,
    getPersistedSearch: () => storage.get(storeKey),
  };
};
