// Vendor
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { pipe } from 'fp-ts/lib/function';
import { chain, fromNullable, getOrElse, map } from 'fp-ts/lib/Option';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import classnames from 'classnames/bind';
import Box from '@material-ui/core/Box';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

// Internal
import { RowProcessingService } from 'flatfile-import/application/row-processor/row-processing-service';
import { useApi, useSnackbar } from 'hooks';
import {
  CUSTOMER_PUBLISH_CHUNK_SIZE,
  PARALLEL_BATCHES,
} from 'flatfile-import/constants';
import { formatPublishError } from 'services/OONRosterImport/error-service';
import { RosterValidator } from 'flatfile-import/domain/validator';
import { RosterAdapter } from 'flatfile-import/domain/roster-adapter';
import { AggregationService } from 'flatfile-import/application/aggregator/aggregation-service';
import {
  EntityWithError,
  EntityWithId,
  PublisherService,
} from 'flatfile-import/application/publisher/publisher-service';
import { PublishAdapter } from 'flatfile-import/application/publisher/publish-adapter';
import {
  ErrorImportAlert,
  SuccessImportAlert,
} from '../components/ResultImportAlert';
import ErrorDetailDialog from '../components/ErrorDetailDialog';
import FlatfileService from './service';
import { RootState } from '../../../reducers';

// Styles
import styles from './container.module.css';

/*
  Schema: Flatfile defined schema.
  Data: Shape of the data needed by the Schema -> Output adapter.
  Output: Business entity obtained from Schema using the adapter.
  POutput: Entity derived from business entity that we can publish through our API.
 */
export interface Props<Schema, Data, Output, POutput extends EntityWithId> {
  embedNode: string;
  containerId: string;
  rosterAdapter: RosterAdapter<Schema, Data, Output>;
  rosterValidator: RosterValidator<Schema>;
  publishAdapter: PublishAdapter<Output, POutput>;
  aggregator: AggregationService<Output>;
  publisher: PublisherService<Output, POutput>;
  formValues: Data;
  onFinish: () => void;
  onCancel: () => void;
  open: boolean;
  entityLabel: string;
}

const cx = classnames.bind(styles);

const RosterImport = <Schema, Data, Output, POutput extends EntityWithId>(
  props: Props<Schema, Data, Output, POutput>
) => {
  const {
    embedNode,
    containerId,
    rosterAdapter,
    rosterValidator,
    formValues,
    onCancel,
    onFinish,
    open,
    publisher,
    aggregator,
    publishAdapter,
    entityLabel,
  } = props;
  const snackbar = useSnackbar();
  const snackbarOpened = useSelector<RootState, boolean | undefined>(
    (state) => state?.common?.snackbar.open
  );
  const [showErrors, setShowErrors] = useState(false);
  const [processedCustomers, setProcessedCustomers] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(1);
  const [isInFlatfileFlow, setIsInFlatfileFlow] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [flatFileError, setFlatFileError] = useState(false);
  const [importErrors, setImportErrors] = useState<EntityWithError<POutput>[]>(
    []
  );
  const processIdRef = useRef('');

  const flatfileContainerRef = useRef<HTMLDivElement | null>(null);
  const api = useApi();
  const flatfileService = useMemo(
    () =>
      new FlatfileService<Schema, Data, Output>(containerId, embedNode, api),
    [api]
  );

  const removeFlatfileOnFinish = () => {
    const container = fromNullable(flatfileContainerRef.current);
    const remove = pipe(
      container,
      chain((containerElement: HTMLElement) =>
        fromNullable(containerElement.querySelector('iframe'))
      ),
      map((iframe) => () => iframe.remove()),
      getOrElse(() => () => {})
    );

    remove();
  };

  const scrollToFlatfileContainer = () => {
    const container = fromNullable(flatfileContainerRef.current);
    const scroll = pipe(
      container,
      map((el) => () => el.scrollIntoView({ behavior: 'smooth' })),
      getOrElse(() => () => {})
    );

    scroll();
  };

  const resetResultState = () => {
    setImportErrors([]);
    setFlatFileError(false);
    setProcessedCustomers(0); // We reset the count
    setTotalCustomers(1);
  };

  const cancelImport = () => {
    onCancel();
    setIsInFlatfileFlow(false);
    setIsProcessing(false);
  };

  const getProcessId = () => processIdRef.current;

  const handleUpload = useCallback((formValues: Data) => {
    aggregator.setGetProcessId(getProcessId);
    const rowProcessingService = new RowProcessingService(
      rosterAdapter,
      rosterValidator
    );

    resetResultState();
    setIsInFlatfileFlow(true);

    flatfileService.runImport(
      formValues,
      {
        aggregator,
        rowProcessingService,
      },
      {
        onCompleted: () => {
          setIsInFlatfileFlow(false);
          setIsProcessing(true);
          removeFlatfileOnFinish();

          // Persist customers
          aggregator.aggregator
            .size()
            .then((size) => {
              setTotalCustomers(size);
              return aggregator.getAggregatedEntities(
                CUSTOMER_PUBLISH_CHUNK_SIZE
              );
            })
            .then((customersGenerator) =>
              publisher.publish(
                customersGenerator,
                PARALLEL_BATCHES,
                (ids: string[]) =>
                  setProcessedCustomers((amount) => amount + ids.length),
                publishAdapter
              )
            )
            .then(
              ({
                withErrors,
                successfully,
              }: {
                withErrors: EntityWithError<POutput>[];
                successfully: POutput[];
              }) => {
                const hasErrors = withErrors.length > 0;
                const resultsMessage = {
                  message: !hasErrors ? (
                    <SuccessImportAlert records={successfully.length} />
                  ) : (
                    <ErrorImportAlert
                      records={withErrors.length}
                      onClick={() => {
                        setShowErrors(true);
                        snackbar.close();
                      }}
                    />
                  ),
                  variant: hasErrors ? 'error' : 'success',
                };
                setIsInFlatfileFlow(false);
                setIsProcessing(false);
                setImportErrors(
                  withErrors.map((e) => ({
                    ...e,
                    error: formatPublishError(e.error),
                  }))
                );
                snackbar.open(resultsMessage);
                onFinish();
              }
            )
            .then(() => aggregator.aggregator.clean());
        },
        onError: (error) => {
          // eslint-disable-next-line no-console
          console.error('Error occurred in Flatfile client:', error);
          // removeFlatfileOnFinish();
          // setFlatFileError(true);
          // setIsInFlatfileFlow(false);
          // setIsProcessing(false);
          return Promise.resolve({});
        },
        onInit: (x) => {
          // eslint-disable-next-line no-console
          console.log(
            'Started Flatfile session with batchId: ',
            x.session.batchId
          );
          processIdRef.current = x.session.batchId;
          scrollToFlatfileContainer();
        },
      }
    );
  }, []);

  useEffect(() => {
    if (open) {
      handleUpload(formValues);
    }
  }, [open]);

  const percentage = (processedCustomers * 100) / totalCustomers;

  return (
    <Dialog
      id='import-dialog'
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: '#8D8B99',
          opacity: '0.48',
        },
      }}
      maxWidth='xl'
      PaperProps={{
        style: {
          height: '80vh',
          borderRadius: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      keepMounted={
        snackbarOpened || showErrors || isInFlatfileFlow || isProcessing
      }
    >
      <ErrorDetailDialog
        entityLabel={entityLabel}
        entries={importErrors}
        open={showErrors}
        onClose={() => setShowErrors(false)}
      />
      {isProcessing && (
        <div className={cx('loading-section')}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
            }}
          >
            <CircularProgress
              variant='determinate'
              value={percentage}
              size='10em'
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className={cx('progress')}>{`${Math.round(
                percentage
              )}%`}</span>
            </Box>
          </Box>
          <p>Finishing import process...</p>
        </div>
      )}
      <div
        ref={flatfileContainerRef}
        id={containerId}
        className={
          isInFlatfileFlow || flatFileError
            ? cx('flatfile-container', 'base-container')
            : cx('base-container')
        }
      >
        {isInFlatfileFlow && (
          <div className={cx('close-btn')} onClick={cancelImport}>
            <CloseIcon
              style={{ color: 'black', height: '24px', width: '24px' }}
            />
          </div>
        )}
        {flatFileError && (
          <div className={cx('error-container')}>
            <ErrorIcon className={cx('error-icon')} />
            <span>An error occurred in the Flatfile client.</span>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default RosterImport;
