// Vendor
import React, { FC, ReactElement } from 'react';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';
import { v4 } from 'uuid';
import classnames from 'classnames/bind';

// Internal
import { Button } from 'components';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export type OnButtonClickFunction = (
  rowIndex: number,
  data: Record<string, string>
) => void;

type ButtonData = {
  title: ReactElement | string;
  displayedInRows?: number[];
  onClickHandler: OnButtonClickFunction;
};

export interface MultipleButtonsColumnInterface {
  type: 'multipleButtons';
  headerName: string;
  size: Size;
  buttonsData: ButtonData[];
}

export interface Props {
  data: any;
  rowOrder: number;
  buttonsData: ButtonData[];
  size?: Size;
}

export const MultipleButtonsCell: FC<Props> = ({
  data,
  buttonsData,
  rowOrder,
  size = 'small',
}) => (
  <TableCell size={size} className={cx('cell')}>
    {buttonsData.map((buttonData: ButtonData) => {
      const { title, onClickHandler, displayedInRows } = buttonData;

      // Button component
      const CellButton = (
        <Button
          color='secondary'
          dataTestId='cellButton'
          key={v4()}
          label={title}
          onClick={() => onClickHandler(rowOrder, data)}
          style={{
            marginRight: 10,
            width: 40,
          }}
          variant='outlined'
        />
      );

      // if the button is displaid only in certain rows
      if (displayedInRows) {
        // detect if button should be displayed in the current row
        return displayedInRows.map((displayedInRow: number) => {
          if (displayedInRow === rowOrder) {
            return CellButton;
          } else {
            return null;
          }
        });
      } else {
        return CellButton;
      }
    })}
  </TableCell>
);
