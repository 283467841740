// Vendor
import React from 'react';

const IconCurrentView = () => (
  <svg width='13' height='15' viewBox='0 0 13 15'>
    <defs>
      <path id='IconCurrentViewA' d='M0 0h24v24H0z' />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(-6 -4)'>
      <mask id='IconCurrentViewB' fill='#fff'>
        <use xlinkHref='#IconCurrentViewA' />
      </mask>
      <path
        fill='#011F42'
        fillRule='nonzero'
        stroke='#011F42'
        strokeWidth='.5'
        d='M17.312 6.5c.365 0 .675.128.93.383s.383.565.383.93v9.624c0 .365-.128.675-.383.93a1.266 1.266 0 0 1-.93.383H7.687c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93V7.813c0-.365.128-.675.383-.93s.565-.383.93-.383H9V5.078c0-.091.032-.168.096-.232a.316.316 0 0 1 .232-.096h.219c.091 0 .168.032.232.096a.316.316 0 0 1 .096.232V6.5h5.25V5.078c0-.091.032-.168.096-.232a.316.316 0 0 1 .232-.096h.219c.091 0 .168.032.232.096a.316.316 0 0 1 .096.232V6.5h1.312zm-9.625.875a.426.426 0 0 0-.314.123.426.426 0 0 0-.123.315v1.312h10.5V7.813a.426.426 0 0 0-.123-.315.426.426 0 0 0-.315-.123H7.687zm9.625 10.5a.426.426 0 0 0 .315-.123.426.426 0 0 0 .123-.315V10H7.25v7.437c0 .128.041.233.123.315a.426.426 0 0 0 .314.123h9.625zm-5.496-1.668a.4.4 0 0 1-.246.082.296.296 0 0 1-.218-.082l-1.86-1.86a.296.296 0 0 1-.082-.218.4.4 0 0 1 .082-.246l.246-.219a.26.26 0 0 1 .219-.11.34.34 0 0 1 .246.11l1.367 1.367 3.227-3.199a.4.4 0 0 1 .246-.082.26.26 0 0 1 .219.11l.246.218a.4.4 0 0 1 .082.246.296.296 0 0 1-.082.219l-3.692 3.664z'
        mask='url(#IconCurrentViewB)'
      />
    </g>
  </svg>
);

export default IconCurrentView;
