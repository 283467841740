// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconArrowUp: FC<Props> = ({ color = '#525f7f' }) => (
  <svg width='14' height='8' viewBox='0 0 14 8'>
    <path
      fill={color}
      fillRule='evenodd'
      d='M7.884.813l5.745 5.745a.625.625 0 1 1-.884.884L7 1.697 1.255 7.442a.625.625 0 1 1-.884-.884L6.553.376a.632.632 0 0 1 .894 0l.437.437z'
      opacity='.8'
    />
  </svg>
);

export default IconArrowUp;
