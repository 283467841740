// Vendor
import React from 'react';

// Internal
import {
  generateStatus,
  isRosterOON,
  removeRosterIdPrefix,
  truncateDatetime,
} from 'common/helpers';
import { CustomerDetails } from 'query';
import { NodeType } from 'common/config';
import { CotTooltip, ValueTooltip } from 'components/Tooltips';

export const getKeyValuePairs = (
  customer: CustomerDetails,
  isLocalCustomer: boolean,
  cots: any,
  nodeType?: NodeType
) => {
  const { endDate, id, memberId, names, startDate } = customer;

  const startDateTruncated = truncateDatetime(startDate);
  const endDateTruncated = truncateDatetime(endDate);

  const customerId =
    !isLocalCustomer && isRosterOON(memberId) ? removeRosterIdPrefix(id) : id;

  const name = names?.[0];
  const cot = cots?.[0];

  const cotTooltip = (
    <ValueTooltip title={<CotTooltip classesOfTrade={cots} />} />
  );

  const nameTooltip = <ValueTooltip header='Name' values={names} />;

  const rosterKeyValuePairs = [
    {
      title: 'Customer ID',
      value: customerId,
    },
    {
      title: 'Name',
      value: names?.[0],
      valueIcon: names && names.length > 1 ? nameTooltip : undefined,
      valueLength: names && names.length - 1,
    },
    {
      title: 'Class of Trade',
      value: cots?.[0]?.name,
      valueIcon: cotTooltip,
      valueLength: cots && cots.length - 1,
    },
    {
      title: 'Membership Status',
      value: generateStatus({ endDate, startDate }),
    },
    {
      title: 'Start Date',
      value: startDateTruncated,
    },
    {
      title: 'End Date',
      value: endDateTruncated,
    },
  ];

  const localKeyValuePairs = [
    {
      title: 'Internal ID',
      value: id,
    },
    {
      title: 'Name',
      value: name,
      valueIcon: names && names.length > 1 ? nameTooltip : undefined,
      valueLength: names && names.length - 1,
    },
    ...(nodeType === 'MFR'
      ? [
          {
            title: 'Administrative Account',
            value: 'N/A',
          },
        ]
      : []),
    ...(nodeType !== 'DIST'
      ? [
          {
            title: 'Class of Trade',
            value: cot?.name,
            valueIcon: cotTooltip,
            valueLength: cots && cots.length - 1,
          },
        ]
      : []),
    {
      title: 'Start Date',
      value: startDateTruncated,
    },
    {
      title: 'End Date',
      value: endDateTruncated,
    },
    ...(nodeType === 'GPO'
      ? [
          {
            title: 'Membership Status',
            value: generateStatus({ endDate, startDate }),
          },
        ]
      : []),
  ];

  return isLocalCustomer ? localKeyValuePairs : rosterKeyValuePairs;
};
