import { Box } from '@material-ui/core';
import React, { FC } from 'react';

interface TabsPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const TabberPanel: FC<TabsPanelProps> = (props) => {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
