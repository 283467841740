// Vendor
import { GridSize } from '@material-ui/core';
import { Eq as eqString } from 'fp-ts/string';
import { Eq, fromEquals, struct } from 'fp-ts/Eq';
import { isEmpty } from 'lodash';

// Internal
import {
  isSameOrAfterDate,
  maxLongTextLengthValidator,
  maxShortTextLengthValidator,
  required,
  withinDateRange,
} from 'common/validation';
import { SimpleCustomer } from 'pages/CustomerLists/Create/EditCustomerList';
import { DateRange } from 'common/types';
import { FormValues } from '../service';

const eqSimpleCustomer: Eq<SimpleCustomer> = struct({
  id: eqString,
  membershipEndDate: eqString,
  membershipStartDate: eqString,
});

// We do not use getEq from Array because it cares about order
export const eqSimpleCustomerList: Eq<Array<SimpleCustomer>> = fromEquals(
  (l1: SimpleCustomer[], l2: SimpleCustomer[]) =>
    l1.length === l2.length &&
    l1.every((l1cu) => l2.some((l2cu) => eqSimpleCustomer.equals(l1cu, l2cu)))
);

export const getCustomerListFields = () => [
  {
    label: 'Name',
    name: 'name',
    size: 6 as GridSize,
    type: 'text',
    validation: [required, maxShortTextLengthValidator],
    width: '55%',
  },
  {
    label: 'Description',
    name: 'description',
    size: 6 as GridSize,
    type: 'text',
    validation: [required, maxLongTextLengthValidator],
    width: '55%',
  },
  {
    label: 'Start Date',
    name: 'startDate',
    size: 6 as GridSize,
    type: 'date',
    validation: [required],
    width: '55%',
  },
  {
    label: 'End Date',
    name: 'endDate',
    size: 6 as GridSize,
    type: 'date',
    validation: [required],
    width: '55%',
  },
];

export const validateForm = (values: FormValues) => {
  const { customers = [], endDate = '', startDate = '' } = values;
  const errors: any = {};

  if (endDate) {
    errors.endDate = isSameOrAfterDate(startDate)(endDate);
  }

  // initialize array of customers errors
  errors.customers = [];

  if (startDate && endDate) {
    customers.forEach((customer: SimpleCustomer) => {
      if (!customer.checked) return;

      const { membershipStartDateErrors, membershipEndDateErrors } =
        validateMembershipDate(
          customer.membershipStartDate,
          customer.membershipEndDate,
          {
            startDate,
            endDate,
          }
        );

      errors.customers.push({
        ...(membershipStartDateErrors.length > 0 && {
          membershipStartDate: membershipStartDateErrors,
        }),
        ...(membershipEndDateErrors.length > 0 && {
          membershipEndDate: membershipEndDateErrors,
        }),
      });
    });
  }

  if (errors.customers.every((customer: SimpleCustomer) => isEmpty(customer))) {
    delete errors.customers;
  }

  return errors;
};

export const validateMembershipDate = (
  startDate: string,
  endDate: string,
  listDateRange: DateRange
): {
  membershipStartDateErrors: string[];
  membershipEndDateErrors: string[];
} => {
  let membershipStartDateErrors: string[] = [];
  let membershipEndDateErrors: string[] = [];

  membershipStartDateErrors.push(required(startDate) || '');
  membershipStartDateErrors.push(
    withinDateRange({
      startDate: listDateRange.startDate,
      endDate: listDateRange.endDate,
    })(startDate) || ''
  );

  membershipEndDateErrors.push(required(endDate) || '');
  membershipEndDateErrors.push(
    withinDateRange({
      startDate: listDateRange.startDate,
      endDate: listDateRange.endDate,
    })(endDate) || ''
  );
  membershipEndDateErrors.push(isSameOrAfterDate(startDate)(endDate) || '');

  membershipStartDateErrors = membershipStartDateErrors.filter(
    (e) => e.length > 0
  );
  membershipEndDateErrors = membershipEndDateErrors.filter((e) => e.length > 0);

  return { membershipStartDateErrors, membershipEndDateErrors };
};
