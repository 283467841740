// Vendor
import dayjs from 'dayjs';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { filter, get, keys } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/client';
import { ValidationErrors } from 'final-form';

// Internal
import { DocumentApiAction, DocumentApiArgs, useSnackbar } from 'hooks';
import { GET_CUSTOMERS_BY_IDS } from 'query';
import AddDocumentStepper from './Stepper';
import { CustomerWithDocuments } from '../types';

// Styles
import styles from './AddDocument.module.css';

const cx = classnames.bind(styles);

interface Props {
  change: (key: string, value: string) => void;
  changeSection: (section: string) => void;
  addDocument: (args: DocumentApiArgs) => void;
  customer: CustomerWithDocuments;
  errors: ValidationErrors;
  memberId: number;
  values: {};
}

const AddDocument: FC<Props> = ({
  addDocument,
  change,
  changeSection,
  customer,
  errors,
  memberId,
  values,
}) => {
  const snackbar = useSnackbar();
  // get the checked customer ids
  const addDocumentCustomerIds = filter(
    keys(get(values, 'entities', {})),
    (k) => get(values, `entities.${k}`, false)
  )
    // Remove underscore from the ids
    .map((x) => x.toString().substring(1));

  // get the latest updatedAt timestamps for the checked customer ids
  const { data: addDocumentCustomerData } = useQuery(GET_CUSTOMERS_BY_IDS, {
    variables: {
      ids: addDocumentCustomerIds,
      memberId,
    },
    skip: !addDocumentCustomerIds.length,
  });

  const manufacturer = get(values, 'manufacturer', '');
  const entities = get(addDocumentCustomerData, 'customers', []);
  const customers = [...entities, { id: customer.id }];

  const getMetadata = (val: object) => {
    const metadata: Record<string, any> = {};
    Object.entries(val).forEach(([key, value]) => {
      metadata[key] = value;
    });
    return { manufacturer, ...metadata };
  };

  const generateActions = (): DocumentApiAction[] =>
    Object.entries(values)
      .filter(
        ([k]) =>
          !(k === 'documentType' || k === 'manufacturer' || k === 'entities')
      )
      .map(([k, v]) => ({
        action: 'add',
        document: {
          id: uuidv4(),
          type: k,
          // @ts-ignore
          metadata: getMetadata(v),
        },
      }));

  const args: DocumentApiArgs[] = customers.map((c) => ({
    actions: generateActions(),
    id: c.id,
    memberId: `ml:member:${memberId}`,
    timestamp: dayjs(Date.now()).utc(),
  }));

  const onSubmit = () => {
    Promise.all(args.map((arg) => addDocument(arg)))
      .then(() => {
        snackbar.open({
          message: `Documents added successfully for: ${args
            .map(({ id }) => id)
            .join(', ')}`,
          variant: 'success',
        });
        changeSection('documentList');
      })
      .catch((err) => {
        snackbar.open({
          message: 'Failed to add documents',
          variant: 'error',
        });
        // eslint-disable-next-line no-console
        console.error('AddDocument', err);
      });
  };

  const handleCancel = () => {
    changeSection('documentList');
  };

  return (
    <div className={cx('root')}>
      <AddDocumentStepper
        change={change}
        customer={customer}
        errors={errors}
        handleCancel={handleCancel}
        memberId={memberId}
        onSubmit={onSubmit}
        values={values}
      />
    </div>
  );
};

export default AddDocument;
