// Internal
import { TableColumn, TableRow } from 'components/Table';
import { CustomerViewRow } from 'query';
import {
  displayAddress,
  generateStatus,
  linkToAuthCustomerDetail,
} from 'common/helpers';
import {
  ADDRESS_COLUMN,
  NAME_COLUMN,
  STATUS_COLUMN,
} from 'components/Cells/constants';

export const getColumns = (): TableColumn[] => [
  {
    displayTextFn: (data: any) => data.id,
    headerName: 'HIN',
    size: 'small',
    type: 'link',
    urlFn: (data: any) => linkToAuthCustomerDetail(data.memberId, data.id),
  },
  NAME_COLUMN,
  ADDRESS_COLUMN,
  STATUS_COLUMN,
  {
    dataField: 'startDate',
    format: 'short',
    headerName: 'RELATIONSHIP START DATE',
    size: 'small',
    type: 'date',
  },
];

export const getRows = (
  customers: CustomerViewRow[],
  customerStartDate: string | null
): TableRow[] => {
  if (customers.length === 0) return [];

  return customers.map((customer: CustomerViewRow) => {
    const {
      addresses: addressesProp,
      endDate,
      id,
      memberId,
      names,
      startDate,
    } = customer;

    const addresses =
      addressesProp?.map((address) => displayAddress(address)) ?? [];
    const status = generateStatus(customer);
    const isActive = status === 'Active';
    const date = !isActive
      ? endDate !== customerStartDate
        ? customerStartDate
        : endDate
      : startDate;

    return {
      isExpandable: false,
      data: {
        id,
        names,
        addresses,
        status,
        startDate: date,
        memberId,
      },
      type: 'expanding',
    };
  });
};
