// Vendor
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { uniq } from 'lodash';
import { fromNullable, getOrElse, map } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

// Internal
import { GET_PRODUCTS_FOR_LIST, GetProductsForListResult } from 'query';
import { toMediledgerProductListId } from 'common/helpers/mlIdUtils';
import { useTradingPartnerConfig } from 'hooks';
import { IconLoading } from 'styles/images';
import {
  ContractFormProps,
  MultiplePriceProduct,
  NUMBER_OF_PRICINGS_PER_PAGE,
} from 'pages/Contracts/Create/constants';
import { EmptyResults, Pagination, Select } from 'components';
import { productsToMultiplePricesProducts } from './service';
import { COLUMNS } from './constants';
import { SetPricingDrawer } from './ProductPricingDrawer/SetPricingDrawer';
import { ProductPricingTable } from './ProductPricingDrawer/ProductPricingTable';
import ProductRow from './ProductRow';
import { getProductsForListWhere } from '../SelectProductList/service';

// Styles
import styles from './container.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getPricingDateRange } from './ProductRow/SingleProductPricesRows/service';
import { calculatePricingPaginatedIndex } from '../../../service';

const cx = classnames.bind(styles);

interface Props {
  change: (key: string, value: string) => void;
  formRemoveBatch: (...args: any[]) => any;
  formRemove: (...args: any[]) => any;
  formPush: (...args: any[]) => any;
  formUpdate: (...args: any[]) => any;
  initialValues: any;
  values: ContractFormProps;
  totalProductsCountInfo: {
    count: number;
    loading: boolean;
    refetch: (variables?: Record<string, any>) => Promise<any>;
  };
}

export const SetPricing: FC<Props> = (props) => {
  const {
    change,
    formRemove,
    formRemoveBatch,
    formPush,
    formUpdate,
    initialValues,
    values,
    totalProductsCountInfo,
  } = props;
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  if (!memberId) {
    throw new Error('Member ID must be present.');
  }

  const selectedProductListsIDs: string[] =
    values.productLists
      ?.filter((list: any) => list.checked)
      .map((list: any) => toMediledgerProductListId(list.id, memberId)) || [];
  const [currentPage, updateCurrentPage] = useState(1);
  const [rows, setRows] = useState<MultiplePriceProduct[]>([]);

  // Get products from selected product lists
  const {
    data: productsData,
    loading: productsLoading,
    refetch: refetchProducts,
  } = useQuery<GetProductsForListResult>(GET_PRODUCTS_FOR_LIST, {
    variables: {
      distinct: 'from_id_full',
      order: [{ from_id_full: 'asc' }, { product: { description: 'asc' } }],
      where: getProductsForListWhere(
        selectedProductListsIDs,
        values.startDate,
        values.endDate
      ),
      offset: (currentPage - 1) * NUMBER_OF_PRICINGS_PER_PAGE,
      limit: NUMBER_OF_PRICINGS_PER_PAGE,
    },
    skip:
      selectedProductListsIDs.length === 0 ||
      !(!!values.startDate && !!values.endDate),
  });

  const products = useMemo(
    () => productsData?.products?.map((container) => container.product) ?? [],
    [productsData]
  );

  useEffect(() => {
    if (productsLoading) return;

    if (products.length > 0) {
      // Now we want to add products + pricings to our products array form value
      products.forEach((product, index) => {
        const i = index + (currentPage - 1) * NUMBER_OF_PRICINGS_PER_PAGE;
        const [pricingStartDate, pricingEndDate] = getPricingDateRange(
          values,
          product
        );

        const productId = `${product.kind}:${product.external_id}`;

        // This is to make sure we grab the correct initial values for the product pricings since
        // the indexes might be different from our EditContractWrapper so this is a safety net
        const getCurrentPricings =
          values.products?.find((p: { id: string }) => p.id === productId)
            ?.pricings ?? [];
        const getInitialPricings =
          initialValues.products?.find(
            (p: { id: string }) => p.id === productId
          )?.pricings ?? [];

        const pricings =
          getCurrentPricings?.length > 0
            ? getCurrentPricings
            : getInitialPricings?.length > 0
            ? getInitialPricings
            : [
                {
                  startDate: pricingStartDate,
                  endDate: pricingEndDate,
                },
              ];

        formUpdate('products', i, {
          description: product.description,
          externalId: product.external_id,
          id: `${product.kind}:${product.external_id}`,
          idType: product.kind,
          pricings,
          productPrices: product.prices,
          unitOfMeasure: product.unit_of_measure,
        });
      });
    }

    // Removes the corresponding priceType from the array if a product is removed
    const indexesToRemovePriceTypes: number[] = [];
    if (values.priceTypes && values.priceTypes.length > 0) {
      values.priceTypes.forEach(({ externalId }, i) => {
        // If there is no product in our products array (products array = products for our "Set Pricing" tab) that matches our priceType external id
        // we can remove the priceType from the priceTypes array because the product lists with that product are now unselected
        const foundProduct =
          products.some((p) => p.external_id === externalId) ||
          values.products?.some((p) => p.externalId === externalId);
        if (!foundProduct) indexesToRemovePriceTypes.push(i);
      });
      formRemoveBatch('priceTypes', indexesToRemovePriceTypes);
    }
  }, [products]);

  const loading = totalProductsCountInfo.loading || productsLoading;

  useEffect(() => {
    if (products === undefined) {
      return;
    }

    const updatedProductsData: MultiplePriceProduct[] =
      productsToMultiplePricesProducts(
        formPush,
        values,
        products,
        initialValues.products
      );
    setRows(updatedProductsData);
  }, [products]);

  const onPageChangeInnerHandler = useCallback(
    ({ currentPage }: { currentPage: number }) => {
      updateCurrentPage(currentPage);
      const scroll = pipe(
        fromNullable(document.querySelector('#body')),
        map(
          (el) => () =>
            setTimeout(() => el.scrollTo({ top: 0, behavior: 'smooth' }), 0)
        ),
        getOrElse(() => () => {})
      );
      refetchProducts({
        distinct: 'from_id_full',
        order: [{ from_id_full: 'asc' }, { product: { description: 'asc' } }],
        where: getProductsForListWhere(
          selectedProductListsIDs,
          values.startDate,
          values.endDate
        ),
        offset: (currentPage - 1) * NUMBER_OF_PRICINGS_PER_PAGE,
        limit: NUMBER_OF_PRICINGS_PER_PAGE,
      });
      scroll();
    },
    []
  );

  useEffect(() => {
    totalProductsCountInfo.refetch().then();
    refetchProducts().then();
  }, []);

  // empty string means drawer is closed
  const [openedProductId, setOpenedProductId] = React.useState<string>('');

  const prices =
    rows.find((r) => r.data.externalId === openedProductId)?.data
      .productPrices || [];

  const allPriceTypes = uniq(
    rows.map((row) => row.data.priceTypes.map((priceType) => priceType)).flat()
  );
  const priceTypeValues = values.priceTypes;
  const areAllPriceTypesTheSame =
    priceTypeValues?.length > 0
      ? priceTypeValues.every(
          ({ priceType }, i, arr) => priceType === arr[0].priceType
        )
      : false;

  useEffect(() => {
    if (areAllPriceTypesTheSame)
      change('overallPriceType', priceTypeValues[0]?.priceType);
  }, [priceTypeValues]);

  return (
    <>
      {loading && (
        <div className={cx('loading')}>
          <IconLoading />
        </div>
      )}
      {!loading && rows.length > 0 ? (
        <>
          <SetPricingDrawer
            open={openedProductId !== ''}
            productId={openedProductId}
            toggleDrawer={() => setOpenedProductId('')}
          >
            <ProductPricingTable prices={prices} />
          </SetPricingDrawer>
          <div className={cx('basisPriceType')}>
            <span className={cx('basisPriceTypeTitle')}>
              Contract Basis Price Type:
            </span>
            <Field
              // @ts-ignore
              component={Select}
              label={
                priceTypeValues.length !== 0 && !areAllPriceTypesTheSame
                  ? 'Multiple Price Types'
                  : values.overallPriceType
              }
              name='overallPriceType'
            >
              {allPriceTypes.map((priceType) => (
                <MenuItem
                  key={priceType}
                  onClick={() => {
                    change('overallPriceType', priceType);
                    for (let i = 0; i < rows.length; i += 1) {
                      if (
                        rows[i].data.priceTypes.find((pt) => pt === priceType)
                      ) {
                        const index = calculatePricingPaginatedIndex(
                          i,
                          currentPage
                        );
                        formUpdate('priceTypes', index, {
                          externalId: values.priceTypes[index].externalId,
                          priceType,
                        });
                      }
                    }
                  }}
                  value={priceType}
                  style={{
                    maxWidth: 'none',
                    paddingRight: 0,
                  }}
                >
                  {priceType}
                </MenuItem>
              ))}
            </Field>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column: any) => (
                    <TableCell key={column.headerName}>
                      {column.headerName}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((productRow: MultiplePriceProduct, index: number) => (
                  <ProductRow
                    key={productRow.data.id}
                    formRemove={formRemove}
                    formUpdate={formUpdate}
                    productIndex={
                      index + (currentPage - 1) * NUMBER_OF_PRICINGS_PER_PAGE
                    }
                    productRow={productRow}
                    values={values}
                    setOpenedProductId={setOpenedProductId}
                    currentPage={currentPage}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={cx('pagination')}
            currentPage={currentPage}
            numberPerPage={NUMBER_OF_PRICINGS_PER_PAGE}
            loading={totalProductsCountInfo.loading}
            onChangePage={onPageChangeInnerHandler}
            isDisplayed={(values.products?.length ?? 0) > 0}
            totalCount={totalProductsCountInfo.count}
          />
        </>
      ) : (
        !loading && <EmptyResults text='No products found' />
      )}
    </>
  );
};

export default SetPricing;
