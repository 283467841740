// Vendor
import React, { FC, Fragment } from 'react';
import classnames from 'classnames/bind';

// Internal
import { KeyValuePair, ViewSection } from 'components';
import { Attribute } from 'types/generated/Attribute';
import { truncateDatetime } from 'common/helpers';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  attributes: Attribute[];
  memberName?: string;
}

const Attributes: FC<Props> = (props) => {
  const { attributes, memberName = '' } = props;

  return (
    <ViewSection
      dataTestid='attributes'
      disableCollapse={attributes.length === 0}
      emptySection={attributes.length === 0}
      header='Attributes'
      sourceName={memberName}
    >
      <div className={cx('row')}>
        {attributes.map((attribute) => {
          const { endDate = '', name, startDate = '', value } = attribute;
          const formattedEndDate = truncateDatetime(endDate);
          const formattedStartDate = truncateDatetime(startDate);

          const keyValuePairs = [
            { title: name, value },
            ...(startDate
              ? [{ title: `${name} Start Date`, value: formattedStartDate }]
              : []),
            ...(endDate
              ? [{ title: `${name} End Date`, value: formattedEndDate }]
              : []),
          ];

          return (
            <Fragment key={name}>
              {keyValuePairs.map(({ title = '', value }) => (
                <KeyValuePair key={title} title={title} value={value} />
              ))}
            </Fragment>
          );
        })}
      </div>
    </ViewSection>
  );
};

export default Attributes;
