// Vendor
import React, { useRef, useState, DragEvent, useEffect } from 'react';
import classnames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import ReactFlow, {
  Edge,
  Node,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
  Connection,
  ReactFlowProvider,
  Controls,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { RuleGroupType } from 'react-querybuilder';
import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';

// Internal
import { useAppBarSetTitle, useDocumentTypes, useSnackbar } from 'hooks';
import { Breadcrumb, Button } from 'components';
import { INSERT_PROPOSAL_RULES, QUERY_PROPOSAL_RULES } from 'query';
import { DragAndMoveTabItems, NodeTypes, Pages, ReactFlowTypes } from './types';
import {
  areAnyQBValuesEmpty,
  getInitialQuery,
  getLeftPanelItems,
  handleSubmit,
  handleValidateBtn,
  onConnect,
  onDrop,
  updatePageNodesOnDoneClick,
} from './services';
import {
  baseReactFlowData,
  nodeTypes,
  ruleSets,
  startGenerateNodes,
} from './constants';
import { LeftPanel, Overview, QueryBuilder } from './components';
import { getFields } from './components/QueryBuilder/services';
import { triggers } from '../constants';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

const ManageRules = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const documentTypes = useDocumentTypes();

  const { id } = useParams() as { id: string };

  const [page, setPage] = useState<string>(Pages.MANAGE_RULES);
  const [crumbs, setCrumbs] = useState<string[]>([
    'Customer proposals',
    'Triggers',
    'Manage rules',
  ]);

  const leftPanelRuleSets: DragAndMoveTabItems[] = ruleSets.map((ruleSet) => ({
    description: '',
    name: ruleSet,
    nextPage: Pages.RULE_SET,
    nodeType: NodeTypes.RULE_SET,
  }));

  useAppBarSetTitle(
    `${triggers.find((trigger) => trigger.id === id)?.name} - ${
      crumbs[crumbs.length - 1]
    }`
  );

  const [insertProposalRules, { data: insertData, loading: loadingInsert }] =
    useMutation(INSERT_PROPOSAL_RULES);

  useEffect(() => {
    if (insertData !== undefined && !loadingInsert) {
      navigate('/customer-proposals/manage-rules', { replace: true });
    }
  }, [insertData]);

  const { data, loading, refetch } = useQuery(QUERY_PROPOSAL_RULES, {
    variables: {
      where: { id: { _eq: id } },
    },
  });
  const proposalRules = data?.dash_proposal_rules[0];

  useEffect(() => {
    refetch().then();
  }, []);

  // REACT FLOW
  const reactFlowWrapper = useRef<HTMLInputElement>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();

  // PAGE 1
  const [page1Nodes, setPage1Nodes, onPage1NodesChange] =
    useNodesState(startGenerateNodes);
  const [page1Edges, setPage1Edges, onPage1EdgesChange] = useEdgesState<Edge[]>(
    []
  );
  const [activePage1Node, setActivePage1Node] = useState<Node | any>({});

  // PAGE 2
  const [page2Nodes, setPage2Nodes, onPage2NodesChange] = useNodesState([]);
  const [activePage2Node, setActivePage2Node] = useState<Node | any>({});

  useEffect(() => {
    if (loading || !proposalRules || isEmpty(proposalRules.ruleData)) return;
    const { ruleData } = proposalRules;
    const newNodes = ruleData.nodes.map(
      (node: Node): Node => ({
        ...node,
        data: {
          ...node.data,
          setPage1Edges,
          setPage1Nodes,
        },
      })
    );
    setPage1Nodes(newNodes);
    setPage1Edges(ruleData.edges);
  }, [proposalRules]);

  const fields = getFields(documentTypes, activePage1Node.id);
  const { fieldData } = fields[0];
  const qbField2 = fieldData.attributes[0].name;
  const qbField = `proposedCustomer#root|${qbField2}`;
  const ifInitialQuery = getInitialQuery('and', qbField);
  const thenInitialQuery = getInitialQuery('then', qbField);
  const elseInitialQuery = getInitialQuery('else');

  // PAGE 3
  const [activePage3IfQuery, setActivePage3IfQuery] =
    useState<RuleGroupType>(ifInitialQuery);
  const [activePage3ThenQuery, setActivePage3ThenQuery] =
    useState<RuleGroupType>(thenInitialQuery);
  const [activePage3ElseQuery, setActivePage3ElseQuery] =
    useState<RuleGroupType>(elseInitialQuery);

  useEffect(() => {
    setActivePage3IfQuery(ifInitialQuery);
    setActivePage3ThenQuery(thenInitialQuery);
    setActivePage3ElseQuery(elseInitialQuery);
  }, [activePage1Node.id]);

  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [descriptionError, setDescriptionError] = useState<string | undefined>(
    undefined
  );
  const [queryBuilderError, setQueryBuilderError] = useState<boolean>(false);

  useEffect(() => {
    if (page === Pages.RULE) {
      setQueryBuilderError(
        areAnyQBValuesEmpty(
          activePage3IfQuery.rules.concat(
            activePage3ThenQuery.rules,
            activePage3ElseQuery.rules
          )
        )
      );
    }
  }, [activePage3IfQuery, activePage3ThenQuery, activePage3ElseQuery]);

  const handleBackBreadcrumb = (index: number) => {
    crumbs.pop();
    if (page === Pages.RULE && index === 2) crumbs.pop();

    const newPage = index === 2 ? Pages.MANAGE_RULES : Pages.RULE_SET;
    setPage(newPage);
    setCrumbs(crumbs);
    resetQueryBuilder();
  };

  const resetQueryBuilder = () => {
    setActivePage3IfQuery(ifInitialQuery);
    setActivePage3ThenQuery(thenInitialQuery);
    setActivePage3ElseQuery(elseInitialQuery);
  };

  const page1Data: ReactFlowTypes = {
    edges: page1Edges,
    nodes: page1Nodes,
    nodeTypes,
    onConnect: (params: Connection | Edge) =>
      onConnect(params, page1Edges, page1Nodes, setPage1Edges, setPage1Nodes),
    onDrop: (event: DragEvent<HTMLDivElement>) => {
      onDrop({
        event,
        extraData: {
          handleNextPage: (
            nextPage: string,
            nextCrumbs: string,
            activeNode: Node
          ) => {
            setPage(nextPage);
            setCrumbs(crumbs.concat(nextCrumbs));
            setActivePage1Node(activeNode);
          },
          setPage1Edges,
          setPage1Nodes,
        },
        reactFlowInstance,
        reactFlowWrapper,
        setNodes: setPage1Nodes,
      });
    },
    onEdgesChange: onPage1EdgesChange,
    onNodeDoubleClick: (_: React.MouseEvent, node: Node) => {
      // If we double click on a default node it should do nothing
      if (node.type === 'defaults') return;

      const { nextCrumbs, nextPage, nodes } = node.data;

      setActivePage1Node(node);
      setPage(nextPage);
      setCrumbs(crumbs.concat(`${nextCrumbs} rules`));
      setPage2Nodes(nodes);
    },
    onNodesChange: onPage1NodesChange,
  };

  const page2Data: ReactFlowTypes = {
    edges: [],
    nodes: page2Nodes,
    nodeTypes,
    onDrop: (event: DragEvent<HTMLDivElement>) =>
      onDrop({
        event,
        extraData: {
          activePage1NodeLabel: activePage1Node.data.name,
          handleNextPage: (
            nextPage: string,
            nextCrumbs: string,
            activeNode: Node
          ) => {
            setPage(nextPage);
            setCrumbs(crumbs.concat(nextCrumbs));
            setActivePage2Node(activeNode);
          },
          setActivePage1Node,
        },
        reactFlowInstance,
        reactFlowWrapper,
        setNodes: setPage2Nodes,
      }),
    onNodeDoubleClick: (_: React.MouseEvent, node: Node) => {
      // NOTE: page 2 has no defaults so all nodes that are double clicked are valid for this logic
      const { elseQuery, ifQuery, nextCrumbs, nextPage, thenQuery } = node.data;

      setActivePage2Node(node);
      setPage(nextPage);
      setCrumbs(crumbs.concat(`${nextCrumbs} rules`));
      if (ifQuery) {
        setActivePage3IfQuery(ifQuery);
        setActivePage3ThenQuery(thenQuery);
        setActivePage3ElseQuery(elseQuery);
      }
    },
    onNodesChange: onPage2NodesChange,
  };

  const reactFlowData = page === Pages.MANAGE_RULES ? page1Data : page2Data;

  const overViewData1 = {
    activeNode: activePage1Node,
    name: 'Rule sets',
    overviewDescriptionLabel: 'Rule set description',
    overviewNameLabel: 'Rule set name',
    setActiveNode: setActivePage1Node,
  };

  const overViewData2 = {
    activeNode: activePage2Node,
    name: 'Rules',
    overviewDescriptionLabel: 'Rule description',
    overviewNameLabel: 'Rule name',
    setActiveNode: setActivePage2Node,
  };

  const overViewData = page === Pages.RULE_SET ? overViewData1 : overViewData2;

  return (
    <>
      <Breadcrumb
        crumbs={crumbs}
        links={['/customer-proposals', '/customer-proposals/manage-rules']}
        handleBackBreadcrumb={handleBackBreadcrumb}
      />
      {loading ? (
        <div className={cx('circularProgress')}>
          <CircularProgress
            data-testid='circularProgress'
            disableShrink
            size={80}
          />
        </div>
      ) : (
        <>
          <div className={cx('column', 'toolbar')}>
            <div className={cx('buttons')}>
              <Button
                color='secondary'
                dataTestId='cancelBtn'
                disabled={loadingInsert}
                label='Cancel'
                onClick={() => {
                  if (page === Pages.MANAGE_RULES) {
                    navigate('/customer-proposals/manage-rules', {
                      replace: true,
                    });
                  } else if (page === Pages.RULE_SET) {
                    handleBackBreadcrumb(2);
                  } else {
                    handleBackBreadcrumb(3);
                    resetQueryBuilder();
                    setQueryBuilderError(false);
                  }
                }}
                style={{ marginRight: 8 }}
                variant='outlined'
              />
              {page === Pages.MANAGE_RULES && (
                <>
                  <Button
                    color='secondary'
                    dataTestId='validateBtn'
                    disabled={loadingInsert}
                    label='Validate'
                    onClick={() => {
                      handleValidateBtn({
                        edges: page1Edges,
                        nodes: page1Nodes,
                        setPage1Nodes,
                        snackbar,
                      });
                    }}
                    style={{ marginRight: 8 }}
                    variant='outlined'
                  />
                  <Button
                    dataTestId='submitBtn'
                    label='Save'
                    loading={loadingInsert}
                    onClick={() => {
                      handleSubmit({
                        edges: page1Edges,
                        nodes: page1Nodes,
                        setPage1Nodes,
                        snackbar,
                        insertProposalRules,
                        triggerId: id,
                        proposals: proposalRules?.proposals || false,
                      });
                    }}
                  />
                </>
              )}
              {page !== Pages.MANAGE_RULES && (
                // page 2/3 only
                <Button
                  dataTestId='doneBtn'
                  disabled={
                    !!descriptionError ||
                    !!nameError ||
                    queryBuilderError ||
                    (page === Pages.RULE_SET
                      ? !activePage1Node.data.name
                      : !activePage2Node.data.name)
                  }
                  label='Done'
                  onClick={() => {
                    if (page === Pages.RULE_SET) {
                      handleBackBreadcrumb(2);
                      updatePageNodesOnDoneClick(
                        activePage1Node,
                        page1Nodes,
                        setPage1Nodes,
                        [],
                        page2Nodes
                      );
                    }
                    if (page === Pages.RULE) {
                      handleBackBreadcrumb(3);

                      // doesRuleExist === false if creating new rule
                      const doesRuleExist = page2Nodes.some(
                        (node: Node) => node.id === activePage2Node.id
                      );

                      const page1NodeIndex = page1Nodes.findIndex(
                        (page1Node: Node) => page1Node.id === activePage1Node.id
                      );
                      const nodeRules = [
                        ...page1Nodes[page1NodeIndex].data.rules,
                      ];

                      if (doesRuleExist) {
                        updatePageNodesOnDoneClick(
                          activePage2Node,
                          page2Nodes,
                          setPage2Nodes,
                          [],
                          [],
                          activePage3IfQuery,
                          activePage3ThenQuery,
                          activePage3ElseQuery
                        );

                        const page2NodeIndex = nodeRules.findIndex(
                          (rule) => rule.name === activePage2Node.id
                        );

                        nodeRules[page2NodeIndex] = {
                          ...activePage2Node.data,
                          ifQuery: activePage3IfQuery,
                          thenQuery: activePage3ThenQuery,
                          elseQuery: activePage3ElseQuery,
                        };
                      } else {
                        // When we create a new rule it does NOT go into the react flow edit area
                        // it goes into the left container so we need to update the page 1 rules rather
                        // than any state in the page 2 edit area
                        nodeRules.push({
                          ...activePage2Node.data,
                          ifQuery: activePage3IfQuery,
                          thenQuery: activePage3ThenQuery,
                          elseQuery: activePage3ElseQuery,
                        });
                      }

                      const newNode: Node = {
                        ...page1Nodes[page1NodeIndex],
                        data: {
                          ...page1Nodes[page1NodeIndex].data,
                          rules: nodeRules,
                        },
                      };

                      page1Nodes[page1NodeIndex] = newNode;

                      setPage1Nodes(page1Nodes);
                      setActivePage1Node(newNode);

                      resetQueryBuilder();
                    }
                  }}
                />
              )}
            </div>
          </div>
          <ReactFlowProvider>
            {page !== Pages.MANAGE_RULES && (
              <div className={overViewData.name}>
                <Overview
                  activeNode={overViewData.activeNode}
                  descriptionError={descriptionError}
                  nameError={nameError}
                  overviewDescriptionLabel={
                    overViewData.overviewDescriptionLabel
                  }
                  overviewNameLabel={overViewData.overviewNameLabel}
                  setActiveNode={overViewData.setActiveNode}
                  setDescriptionError={setDescriptionError}
                  setNameError={setNameError}
                />
              </div>
            )}
            {page !== Pages.RULE ? (
              <div className={cx('row')}>
                <LeftPanel
                  dragAndMoveData={getLeftPanelItems(
                    activePage1Node,
                    crumbs,
                    leftPanelRuleSets,
                    page,
                    page1Nodes,
                    page2Nodes,
                    setActivePage1Node,
                    setActivePage2Node,
                    setCrumbs,
                    setPage
                  )}
                />
                <ReactFlow
                  {...baseReactFlowData}
                  {...reactFlowData}
                  className={cx('reactFlow')}
                  onInit={setReactFlowInstance}
                  ref={reactFlowWrapper}
                >
                  <Controls position='bottom-right' />
                </ReactFlow>
              </div>
            ) : (
              <QueryBuilder
                ifQuery={activePage3IfQuery}
                thenQuery={activePage3ThenQuery}
                elseQuery={activePage3ElseQuery}
                setIfQuery={setActivePage3IfQuery}
                setThenQuery={setActivePage3ThenQuery}
                setElseQuery={setActivePage3ElseQuery}
                ruleSet={activePage1Node.id}
              />
            )}
          </ReactFlowProvider>
        </>
      )}
    </>
  );
};

export default ManageRules;
