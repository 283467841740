import { constant, pipe } from 'fp-ts/function';
import { chain, fromNullable, getOrElse, map, Option } from 'fp-ts/Option';
import { compact, flatten } from 'fp-ts/Array';
import { Attribute } from 'types/generated/Attribute';
import { IdentifiersDelimiters } from '../application/row-processor/row-processing-service';
import { fromNullableOrEmptyString } from '../../common/fpUtils';
import { truncateDatetime } from '../../common/helpers';
import { SchemaWithCustomAttributes } from './schema';

export const processIdentifiers = (
  {
    hin,
    dea,
    _340b,
  }: {
    hin?: string;
    dea?: string;
    _340b?: string;
  },
  identifiersDelimiters: IdentifiersDelimiters
): string[] => {
  const toHinMlFormat = (value: string, delimiter?: string) =>
    pipe(
      fromNullable(delimiter),
      map((del) => value.split(del).map((val) => `hin:number:${val.trim()}`)),
      getOrElse(constant([`hin:number:${value.trim()}`]))
    );
  const toDeaMlFormat = (value: string, delimiter?: string) =>
    pipe(
      fromNullable(delimiter),
      map((del) => value.split(del).map((val) => `dea:number:${val.trim()}`)),
      getOrElse(constant([`dea:number:${value.trim()}`]))
    );
  const to340bMlFormat = (value: string, delimiter?: string) =>
    pipe(
      fromNullable(delimiter),
      map((del) => value.split(del).map((val) => `340b:number:${val.trim()}`)),
      getOrElse(constant([`340b:number:${value.trim()}`]))
    );

  const hinOpt = pipe(
    fromNullableOrEmptyString(hin),
    map((hinValue) => toHinMlFormat(hinValue, identifiersDelimiters.hin))
  );
  const deaOpt = pipe(
    fromNullableOrEmptyString(dea),
    map((deaValue) => toDeaMlFormat(deaValue, identifiersDelimiters.dea))
  );
  const _340bOpt = pipe(
    fromNullableOrEmptyString(_340b),
    map((_340b) => to340bMlFormat(_340b.trim(), identifiersDelimiters._340b))
  );

  return flatten(compact([hinOpt, deaOpt, _340bOpt]));
};

export const extractAttributesFromCustomColumns = (
  row: SchemaWithCustomAttributes
): Attribute[] => {
  // Flatfile returns the custom columns as custom_columnName
  const attrRegExp = /^custom_([A-Za-z0-9_]+)/;
  const customKeys = Object.keys(row).filter((key) => attrRegExp.test(key));
  const optionalAttributes: Option<Attribute>[] = customKeys.map((key) =>
    pipe(
      fromNullable(attrRegExp.exec(key)),
      map((res) => res[1]), // Here we extract the group value. For instance, for `custom_attr1`, the value would be `attr1`
      chain((attrKey) =>
        pipe(
          fromNullable(row[key]),
          map((value) => ({ name: attrKey, value }))
        )
      )
    )
  );
  return compact(optionalAttributes);
};

// Function that extracts all the attributes that are not custom (not start with custom_)
export const extractAttributes = (
  row: SchemaWithCustomAttributes,
  baseAttributes: string[]
): Attribute[] => {
  const attributeKeys = Object.keys(row).filter(
    (rowKey) =>
      !baseAttributes.some(
        (attrKey) => attrKey.toUpperCase() === rowKey.toUpperCase()
      ) && /^(?!custom_)/.test(rowKey)
  );

  const optionalAttributes = attributeKeys.reduce((acc, key) => {
    const attr: Option<Attribute> = pipe(
      fromNullable(row[key]),
      map((value) => ({ name: key, value }))
    );
    return acc.concat([attr]);
  }, [] as Option<Attribute>[]);

  return compact(optionalAttributes);
};

export const prepareDate = (
  value: string | undefined,
  formatter: (target: string | undefined) => string | undefined
): string | undefined =>
  pipe(
    fromNullable(value),
    map(truncateDatetime),
    chain(fromNullable),
    map(formatter),
    getOrElse(constant<string | undefined>(undefined))
  );
