// Vendor
import React, { FC, useState } from 'react';
import { Button, DialogContent, DialogActions, Grid } from '@material-ui/core';
import classnames from 'classnames/bind';

// Internal
import { DEFAULT_GREY, DISABLED_COLOR } from 'common/constants';
import { Dialog, FilterGroup } from 'components';
import { IconFilters } from 'styles/images';
import { FilterDefinitionsInterface } from 'components/FilterGroup';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  applyFilters: () => void;
  children?: any;
  filtersActive: boolean;
  filterDefinitions: FilterDefinitionsInterface[];
  handleClear: () => void;
  setFilters: (filters: any, filterId?: string) => void;
  disableFilters: boolean;
}

const AdvancedFilter: FC<Props> = (props) => {
  const {
    applyFilters,
    filtersActive,
    filterDefinitions,
    handleClear,
    setFilters,
    disableFilters,
  } = props;

  const [showDialog, toggleDialog] = useState(false);

  const handleApply = () => {
    toggleDialog(false);
    applyFilters();
  };

  return (
    <>
      <Button
        className={filtersActive ? cx('activated') : cx('default')}
        data-testid='advancedFilter'
        disabled={disableFilters}
        color='secondary'
        id='filterPopupButton'
        onClick={() => toggleDialog(true)}
        startIcon={
          <IconFilters color={disableFilters ? DISABLED_COLOR : DEFAULT_GREY} />
        }
        style={{
          marginLeft: 16,
          paddingLeft: 16,
          minWidth: 152,
          justifyContent: 'left',
        }}
        test-id='filter_button'
        variant='outlined'
      >
        <div className={cx('button')}>
          <span
            className={cx('text')}
            style={{ marginLeft: 8, marginRight: 8 }}
          >
            Filter
          </span>
        </div>
      </Button>
      {showDialog && (
        <Dialog
          title='Filter'
          toggleDialog={() => toggleDialog(false)}
          width='xs'
        >
          <DialogContent>
            <div className={cx('root')}>
              <Grid container spacing={0}>
                {filterDefinitions.map((filter) => (
                  <FilterGroup
                    key={filter.title}
                    setFilters={(
                      newFilters: {} | [],
                      allSelected?: boolean
                    ) => {
                      // TODO: don't hardcode the array name
                      const formatNewFilters = Array.isArray(newFilters)
                        ? { selectedPrograms: newFilters }
                        : newFilters;
                      setFilters(
                        {
                          ...formatNewFilters,
                          ...(typeof allSelected !== 'undefined' && {
                            allProgramsSelected: allSelected,
                          }),
                        },
                        filter.id
                      );
                    }}
                    {...filter}
                  />
                ))}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClear} color='secondary' variant='outlined'>
              Clear
            </Button>
            <Button onClick={handleApply} color='primary' variant='contained'>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AdvancedFilter;
