// Vendor
import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Internal
import { constructCustomer, formatFormIds } from 'services/CustomerMgmt';
import {
  useApi,
  useAppBarSetTitle,
  useDEAValidationOnly,
  useSnackbar,
  useTradingPartnerConfig,
} from 'hooks';
import {
  CustomerViewRow,
  GET_CUSTOMERS_BY_IDS,
  QUERY_CUSTOMER_LISTS,
  QUERY_MULTIPLE_COT,
  QueryClassOfTradeResult,
  QueryCustomersByIdsResult,
  QueryCustomersListResult,
} from 'query';
import {
  DEA_ID,
  HIBCC_ID,
  HRSA_ID,
  SHORT_POLL_INTERVAL,
} from 'common/constants';
import {
  generateStatus,
  isAuthorityData,
  parseMediledgerId,
} from 'common/helpers';
import CustomerMgmt from './CustomerMgmt';
import {
  CustomerFormProps,
  emptyFormObject,
  GPOAffiliation,
} from './constants';

interface Props {
  currentCustomer?: CustomerViewRow;
  isUpdateAction?: boolean;
}

interface IdentifierProps {
  // https://www.typescriptlang.org/docs/handbook/2/objects.html#index-signatures
  [key: string]: string | undefined;

  dea?: string;
  hin?: string;
  three40b?: string;
}

const Container: FC<Props> = (props) => {
  useAppBarSetTitle('Create Customer');

  const { currentCustomer, isUpdateAction } = props;
  const [identifiers, setIdentifiers] = useState<IdentifierProps>(() => {
    // Setting current identifiers for the identifier data select input if updating
    const ids: IdentifierProps = {};
    if (!isUpdateAction) return ids; // If not updating a customer we can return early
    const { identifiers: currentIds } = currentCustomer || {};
    currentIds?.forEach((identifier) => {
      const { domain: domainVal, id } = parseMediledgerId(identifier);
      const domain = domainVal === '340b' ? 'three40b' : domainVal;
      ids[domain] = id;
    });
    return ids;
  });
  const [parentId, setParentId] = useState<string>('');
  const [formValues, setFormValues] =
    useState<CustomerFormProps>(emptyFormObject);
  const [customErrors, setCustomErrors] = useState<{ key?: string }>({});
  const [createdCustomerId, setCreatedCustomerId] = useState<string>('');

  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const isSimpleDEA = useDEAValidationOnly();
  const { id: memberId } = useTradingPartnerConfig() ?? {};

  const { data: parentData } = useQuery<QueryCustomersByIdsResult>(
    GET_CUSTOMERS_BY_IDS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        memberId,
        ids: parentId,
      },
      skip: !parentId,
      onCompleted: () => onCompleted('parentId', memberId),
    }
  );

  const { data: deaData } = useQuery<QueryCustomersByIdsResult>(
    GET_CUSTOMERS_BY_IDS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        ids: identifiers.dea,
        memberId: DEA_ID,
        simpleDEA: isSimpleDEA,
      },
      skip: !identifiers.dea,
      onCompleted: () => onCompleted('dea', DEA_ID),
    }
  );

  const { data: hinData } = useQuery<QueryCustomersByIdsResult>(
    GET_CUSTOMERS_BY_IDS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        ids: identifiers.hin,
        memberId: HIBCC_ID,
      },
      skip: !identifiers.hin,
      onCompleted: () => onCompleted('hin', HIBCC_ID),
    }
  );

  const { data: three4bData } = useQuery<QueryCustomersByIdsResult>(
    GET_CUSTOMERS_BY_IDS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        ids: identifiers.three40b,
        memberId: HRSA_ID,
      },
      skip: !identifiers.three40b,
      onCompleted: () => onCompleted('three40b', HRSA_ID),
    }
  );

  const { data: gpoListsData } = useQuery<QueryCustomersListResult>(
    QUERY_CUSTOMER_LISTS,
    {
      variables: {
        where: { member_id: { _eq: memberId }, type: { _eq: 'gpo' } },
      },
      skip: !memberId,
    }
  );

  const { data: cotData } = useQuery<QueryClassOfTradeResult>(
    QUERY_MULTIPLE_COT,
    {
      variables: {
        memberId,
      },
      skip: !memberId,
    }
  );

  useQuery<QueryCustomersByIdsResult>(GET_CUSTOMERS_BY_IDS, {
    variables: {
      memberId,
      ids: createdCustomerId,
    },
    skip: !createdCustomerId,
    pollInterval: SHORT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ customers }) => {
      if (customers.length > 0) {
        navigate(`/customers/${createdCustomerId}`, { replace: true });
        snackbar.open({
          message: `Customer ${formValues.name} has been ${
            isUpdateAction ? 'updated' : 'created'
          }.`,
          variant: 'success',
        });
      }
    },
  });

  const parent: { endDate?: string | null; id?: string } =
    parentData?.customers[0] || {};
  const deaCustomers = deaData?.customers || [];
  const hinCustomers = hinData?.customers || [];
  const three4bCustomers = three4bData?.customers || [];
  const identifierData = deaCustomers.concat(hinCustomers, three4bCustomers);
  const gpoAffiliation = gpoListsData?.lists ?? [];
  const cots = cotData?.classesOfTrade ?? [];

  const onCompleted = (key: keyof CustomerFormProps, id?: number) => {
    const customError = getCustomErrors(id, formValues[key]);
    setCustomErrors((prevState) => ({ ...prevState, [key]: customError }));
  };

  const getCustomErrors = (
    memberId?: number,
    id?: string | GPOAffiliation[]
  ): string => {
    if (!memberId || !id) return '';
    const isIdentifier = isAuthorityData(memberId);
    const customer = isIdentifier
      ? identifierData.find(
          (identifier) =>
            identifier.id === id && identifier.memberId === memberId
        )
      : parentId === parent.id
      ? parent
      : null;
    return customer
      ? generateStatus({ endDate: customer?.endDate || parent.endDate }) ===
        'Expired'
        ? 'This identifier is expired.'
        : ''
      : `This ${isIdentifier ? 'identifier' : 'customer'} does not exist.`;
  };

  const validateIds = (values: CustomerFormProps) => {
    const ids = formatFormIds(values);
    setIdentifiers(ids);
  };

  const onSubmit = (values: CustomerFormProps) => {
    const apiArgs = constructCustomer(values, currentCustomer);
    const { id } = apiArgs;
    setCreatedCustomerId(id);
    api?.createCustomer(apiArgs);
  };

  return (
    <CustomerMgmt
      cots={cots}
      createLoading={!isEmpty(createdCustomerId)}
      currentCustomer={currentCustomer}
      customErrors={customErrors}
      gpoAffiliation={gpoAffiliation}
      identifierData={identifierData}
      isUpdateAction={isUpdateAction}
      onSubmit={onSubmit}
      setFormValues={setFormValues}
      setParentId={setParentId}
      validateIds={validateIds}
    />
  );
};

export default Container;
