// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { CircularProgress } from '@material-ui/core';

// Internal
import { Button } from 'components';
import { IconArrowLeft, IconArrowRight } from 'styles/images';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  className?: string;
  currentPage: number;
  filter?: string;
  isDisplayed?: boolean;
  loading?: boolean;
  numberPerPage: number;
  onChangePage: ({
    currentPage,
    filter,
  }: {
    currentPage: number;
    filter: string;
  }) => void;
  resetInput?: boolean;
  style?: {};
  totalCount: number;
}

const Pagination: FC<Props> = (props) => {
  const {
    className,
    currentPage,
    filter = '',
    isDisplayed = true,
    loading = false,
    numberPerPage,
    onChangePage,
    resetInput = false,
    style = {},
    totalCount,
  } = props;
  const [inputValue, changeInput] = useState(currentPage);

  useEffect(() => {
    if (currentPage === 1) changeInput(1);
  }, [currentPage, resetInput]);

  const nextPageCount = currentPage * numberPerPage;
  const lastPage = Math.ceil(totalCount / numberPerPage);
  const currentPageNumber =
    nextPageCount - (numberPerPage - 1)
      ? nextPageCount - (numberPerPage - 1)
      : 1;
  const paginationText =
    totalCount === 0
      ? null
      : nextPageCount < totalCount
      ? `${currentPageNumber} - ${nextPageCount} of ${totalCount}`
      : `${nextPageCount - numberPerPage + 1}-${totalCount} of ${totalCount}`;

  const changePage = ({
    inputNumber = 0,
    isDecrementing = false,
    isFirstPage = false,
    isLastPage = false,
  }) => {
    const newPage = isFirstPage
      ? 1
      : isLastPage
      ? lastPage
      : isDecrementing
      ? inputNumber || currentPage - 1
      : inputNumber || currentPage + 1;
    onChangePage({ filter, currentPage: newPage });
    changeInput(inputNumber || newPage);
  };

  const disableLeftArrow = currentPage === 1;
  const disableRightArrow = nextPageCount >= totalCount;
  const disableInput = disableLeftArrow && disableRightArrow;

  if (!isDisplayed) return null;

  return (
    <div
      className={cx('root', className)}
      data-testid='pagination'
      style={style}
    >
      <div className={cx('text')}>
        {loading ? (
          <CircularProgress disableShrink size={20} />
        ) : (
          paginationText
        )}
      </div>
      <Button
        color='secondary'
        dataTestId='leftArrowBtn'
        disabled={disableLeftArrow || loading}
        label={<IconArrowLeft />}
        onClick={() => changePage({ isDecrementing: true })}
        style={{
          borderRadius: 0,
          width: 50,
        }}
        variant='outlined'
      />
      <input
        className={cx('input')}
        disabled={disableInput || loading}
        name='page'
        onChange={(e) => {
          const value = parseInt(e.target.value, 10);
          const validInput =
            Number.isInteger(value) && value <= lastPage && value >= 1;
          if (validInput) {
            changeInput(value);
            const isDecrementing = value < currentPage;
            changePage({ inputNumber: value, isDecrementing });
          }
        }}
        type='text'
        value={inputValue}
      />
      <Button
        color='secondary'
        dataTestId='rightArrowBtn'
        disabled={disableRightArrow || loading}
        label={<IconArrowRight />}
        onClick={() => changePage({ isDecrementing: false })}
        style={{
          borderRadius: 0,
          width: 50,
        }}
        variant='outlined'
      />
    </div>
  );
};

export default Pagination;
