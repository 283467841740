import { AggregationService } from './aggregation-service';
import { DataAggregator } from '../data-aggregator';

export abstract class MainAggregationService<E>
  implements AggregationService<E>
{
  aggregator: DataAggregator<E>;

  getProcessId: () => string;

  constructor(aggregator: DataAggregator<E>) {
    this.aggregator = aggregator;
    this.getProcessId = () => '';
  }

  addBulk(entities: E[]): Promise<void> {
    return (
      this.aggregator
        .addBulk(entities)
        .then(() => {})
        // eslint-disable-next-line no-console
        .catch((e) => console.error('Batch was not saved with error: ', e))
    );
  }

  getAggregatedEntities(batchSize: number): AsyncGenerator<E[]> {
    return this.aggregator.aggregate(batchSize);
  }

  setGetProcessId(fn: () => string): void {
    this.getProcessId = fn;
    this.aggregator.setProcessId(this.getProcessId());
  }
}
