// Vendor
import React from 'react';
import classnames from 'classnames/bind';
import { Handle, Node } from 'reactflow';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

const DefaultNode = (props: Node) => {
  const { data } = props;

  return (
    <>
      <Handle
        className={cx('handle')}
        data-testid='handle'
        position={data.position}
        type={data.type}
      />
      <div data-testid='label' className={cx('name')} style={data.style}>
        {data?.name}
      </div>
    </>
  );
};

export default DefaultNode;
