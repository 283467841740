// Vendor
import { Button, MenuItem, Popover } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

// Internal
import { DEFAULT_GREY, DISABLED_COLOR } from 'common/constants';
import { IconFilters, IconArrowDown, IconArrowUp } from 'styles/images';

// Styles
import classnames from 'classnames/bind';
import styles from './index.module.css';
import { getDefaultItem } from './BasicFilter.service';

const cx = classnames.bind(styles);

export interface FilterItem {
  id: string;
  label: string;
  isDefault: boolean;
}

export interface Props {
  items?: FilterItem[];
  applyFilters: (selected: string, active: boolean) => void;
  disableFilters?: boolean;
  cFilter?: string;
  cFilterActive?: boolean;
}

const BasicFilter: FC<Props> = ({
  items,
  applyFilters,
  disableFilters = false,
  cFilter,
  cFilterActive,
}) => {
  const defaultItem = getDefaultItem(items);

  const [filtersActive, setFiltersActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedItem, selectItem] = useState(defaultItem?.label || '');

  // This effect allow us to force the load of the received (filters, filtersActive).
  useEffect(() => {
    if (cFilter !== undefined && !isEqual(cFilter, selectedItem)) {
      const item = items?.find((it) => it.id === cFilter)?.label;
      selectItem(item ?? '');
    }
    if (cFilterActive !== undefined && cFilterActive !== filtersActive) {
      setFiltersActive(cFilterActive);
    }
  }, [cFilter, cFilterActive]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (item: FilterItem) => {
    const areFiltersActive = !item.isDefault;
    handleClose();
    setFiltersActive(areFiltersActive);
    selectItem(item.label);
    applyFilters(item.id, areFiltersActive);
  };

  const open = Boolean(anchorEl);

  let buttonClasses;
  if (filtersActive) {
    if (open) {
      buttonClasses = cx('activated', 'open');
    } else {
      buttonClasses = cx('activated');
    }
  } else if (open) {
    buttonClasses = cx('default', 'open');
  } else {
    buttonClasses = cx('default');
  }

  return items && items.length > 0 ? (
    <>
      <Button
        test-id='filter_button'
        disabled={disableFilters}
        onClick={handleClick}
        style={{
          marginLeft: 16,
          paddingLeft: 16,
          minWidth: 170,
          justifyContent: 'left',
        }}
        color='secondary'
        variant='outlined'
        className={buttonClasses}
        startIcon={
          <IconFilters color={disableFilters ? DISABLED_COLOR : DEFAULT_GREY} />
        }
        endIcon={
          open ? (
            <IconArrowUp />
          ) : (
            <IconArrowDown
              color={disableFilters ? DISABLED_COLOR : DEFAULT_GREY}
            />
          )
        }
        data-testid='basicFilterOpenButton'
      >
        {selectedItem}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleClickItem(item)}
            data-testid={item.label}
          >
            {item.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  ) : (
    <div />
  );
};

export default BasicFilter;
