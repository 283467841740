// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconContracts: FC<Props> = ({ color = '#011F42' }) => (
  <svg width='11' height='15' viewBox='0 0 11 15'>
    <path
      fill={color}
      fillRule='nonzero'
      d='M5.637 10.676c.273-.219.601-.287.984-.205.383.082.656.278.82.588.055.127.146.191.274.191h.41c.128 0 .232.041.314.123a.426.426 0 0 1 .123.315.426.426 0 0 1-.123.314.426.426 0 0 1-.314.123h-.41c-.237 0-.447-.06-.63-.178a1.304 1.304 0 0 1-.437-.478.283.283 0 0 0-.273-.164.283.283 0 0 0-.273.164l-.22.41a.387.387 0 0 1-.382.246h-.027c-.2-.018-.328-.118-.383-.3l-.465-1.368-.3.848a1.066 1.066 0 0 1-.425.601c-.21.146-.442.219-.697.219h-.328a.426.426 0 0 1-.314-.123.426.426 0 0 1-.124-.314c0-.128.042-.233.124-.315a.426.426 0 0 1 .314-.123h.328c.146 0 .246-.073.3-.219l.493-1.476a.739.739 0 0 1 .246-.342.62.62 0 0 1 .383-.123.62.62 0 0 1 .383.123c.11.082.191.196.246.342l.383 1.12zM2.219 3.375a.21.21 0 0 1-.15-.068.21.21 0 0 1-.069-.15v-.438a.21.21 0 0 1 .068-.15.21.21 0 0 1 .15-.069h3.063a.21.21 0 0 1 .15.068.21.21 0 0 1 .069.15v.438a.21.21 0 0 1-.068.15.21.21 0 0 1-.15.069H2.218zM5.5 4.906a.21.21 0 0 1-.068.15.21.21 0 0 1-.15.069H2.218a.21.21 0 0 1-.15-.068.21.21 0 0 1-.069-.15v-.438a.21.21 0 0 1 .068-.15.21.21 0 0 1 .15-.069h3.063a.21.21 0 0 1 .15.068.21.21 0 0 1 .069.15v.438zm4.867-1.476c.255.255.383.565.383.93v9.077c0 .365-.128.675-.383.93a1.266 1.266 0 0 1-.93.383H1.562c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93V2.063c0-.365.128-.675.383-.93s.565-.383.93-.383H7.14c.364 0 .674.128.93.383l2.296 2.297zM7.25 1.652V4.25h2.625a.73.73 0 0 0-.137-.191L7.441 1.762a.406.406 0 0 0-.191-.11zm2.625 11.785V5.125H7.031a.633.633 0 0 1-.465-.191.633.633 0 0 1-.191-.465V1.625H1.562a.426.426 0 0 0-.314.123.426.426 0 0 0-.123.315v11.374c0 .128.041.233.123.315a.426.426 0 0 0 .314.123h7.875a.426.426 0 0 0 .315-.123.426.426 0 0 0 .123-.315z'
    />
  </svg>
);

export default IconContracts;
