import dayjs from 'dayjs';
import { Either, left, right } from 'fp-ts/Either';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import { RowError } from '../../domain/validator';

export type Validated<L, R> = Either<NonEmptyArray<L>, R>;

export interface FieldValidation<E> {
  (entity: E, delimiter?: string): Validated<RowError, E>;
}

// Utility function to apply validation only when there is a value
export const asNonRequiredFieldValidation =
  <I, E>(input: I | undefined, defaultRight: Validated<RowError, E>) =>
  (validationWhenNonEmpty: (field: I) => Validated<RowError, E>) => {
    if (
      input === undefined ||
      input === null ||
      (typeof input === 'string' && input === '')
    ) {
      return defaultRight;
    }

    return validationWhenNonEmpty(input);
  };

export const requiredField = (
  field: string | undefined
): Validated<string, string> => {
  const trimmedField = (field ?? '').trim();
  return trimmedField !== ''
    ? right(trimmedField)
    : left([`Field is required`]);
};

export const validateDate = (date: string): Validated<string, string> => {
  const isValidDate = dayjs(date).isValid();
  return isValidDate ? right(date) : left(['Invalid Date Format']);
};

export const validateNonEmptyStringField = <A>(
  row: A,
  field: keyof A
): Validated<RowError, A> =>
  (row[field] as string).trim().length > 0
    ? right(row)
    : left([{ field: field.toString(), message: 'Field cannot be empty' }]);
