// Vendor
import React from 'react';

const IconTradingPartnerManagement = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M7.625 9.5C8.08984 9.5 8.5 9.11719 8.5 8.625C8.5 8.16016 8.08984 7.75 7.625 7.75C7.13281 7.75 6.75 8.16016 6.75 8.625C6.75 9.11719 7.13281 9.5 7.625 9.5ZM11.125 8.625C11.125 8.16016 10.7148 7.75 10.25 7.75C9.75781 7.75 9.375 8.16016 9.375 8.625C9.375 9.11719 9.75781 9.5 10.25 9.5C10.7148 9.5 11.125 9.11719 11.125 8.625ZM13.75 8.625C13.75 8.16016 13.3398 7.75 12.875 7.75C12.3828 7.75 12 8.16016 12 8.625C12 9.11719 12.3828 9.5 12.875 9.5C13.3398 9.5 13.75 9.11719 13.75 8.625ZM19 7.3125C19 6.60156 18.3984 6 17.6875 6H6.3125C5.57422 6 5 6.60156 5 7.3125V16.9375C5 17.6758 5.57422 18.25 6.3125 18.25H17.6875C18.3984 18.25 19 17.6758 19 16.9375V7.3125ZM18.125 11.25V16.9375C18.125 17.1836 17.9062 17.375 17.6875 17.375H6.3125C6.06641 17.375 5.875 17.1836 5.875 16.9375V11.25H18.125ZM18.125 10.375H5.875V7.3125C5.875 7.09375 6.06641 6.875 6.3125 6.875H17.6875C17.9062 6.875 18.125 7.09375 18.125 7.3125V10.375Z'
      fill='white'
    />
  </svg>
);

export default IconTradingPartnerManagement;
