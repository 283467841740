// Vendor
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldSelectorProps, Option } from 'react-querybuilder';

// Internal
import SelectField from '../SelectField';
import { separateFieldName } from '../../services';

const CustomFieldSelector = (props: FieldSelectorProps) => {
  const { handleOnChange, options: optionProps, path, value = '' } = props;
  const options = optionProps as Option[];

  // We have to store 3 values in our rule because we have an entity (ex: customer type), attribute,
  // and optional secondary attribute
  const separatedFields = separateFieldName(value);

  const onlyOneCustomer = options.length === 1;

  return (
    <>
      {path[0] !== 0 && onlyOneCustomer && <div>And</div>}
      {onlyOneCustomer && (
        <div data-testid='oneOption' style={{ fontSize: 12 }}>
          {options[0].label}
        </div>
      )}
      <SelectField
        fieldIndex={onlyOneCustomer ? 1 : 0}
        fields={onlyOneCustomer ? separatedFields.slice(1) : separatedFields}
        handleOnChange={handleOnChange}
        items={
          onlyOneCustomer
            ? options[0].fieldData.attributes
            : options.map((option) => ({
                ...option,
                attributes: option.fieldData.attributes,
              }))
        }
        separatedFields={separatedFields}
      />
    </>
  );
};

export default CustomFieldSelector;
