// Vendor
import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { uniq } from 'lodash';
import { useQuery } from '@apollo/client';

// Internal
import { TableRow } from 'components/Table';
import {
  CustomerViewRow,
  QUERY_CUSTOMER_FOR_LIST_COUNT,
  QUERY_CUSTOMERS_FROM_LIST,
  QUERY_MULTIPLE_COT,
  QueryClassOfTradeResult,
  QueryCustomerViewCountResult,
  QueryCustomerViewInCustomerLists,
} from 'query';
import { Pagination, SearchBar, Table } from 'components';
import { NUMBER_PER_PAGE, SHORT_POLL_INTERVAL } from 'common/constants';
import ContractCustomerFilter from 'pages/Contracts/View/components/ContractCustomerDetails/ContractCustomerFilter';
import { Filters } from 'pages/Contracts/service';
import { COLUMNS } from './constants';
import { filtersToQuery, rawDataToRows } from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

interface Props {
  customerListId: string;
  memberId: number;
  setCustomersForExport: (val: CustomerViewRow[]) => void;
  setAreCustomersFiltered: (val: boolean) => void;
  setWhereCustomers: (val: any) => void;
}

const CustomersForListTable: FC<Props> = (props) => {
  const {
    customerListId,
    memberId,
    setCustomersForExport,
    setAreCustomersFiltered,
    setWhereCustomers,
  } = props;

  // SEARCH
  const [searchText, updateSearchText] = useState('');

  // PAGINATION
  const [currentPage, updateCurrentPage] = useState(1);

  // FILTERS
  const [filters, updateFilters] = useState<Filters | undefined>(undefined);
  const [filtersActive, updateFiltersActive] = useState<boolean>(false);

  // ORDER
  const [order] = useState({
    customer_timestamp: 'desc',
    customer_id: 'desc',
  });

  // WHERE CONDITION
  const [where, updateWhere] = useState(
    filtersToQuery(searchText, customerListId, memberId, filtersActive, filters)
  );

  const { data: customersData, loading } =
    useQuery<QueryCustomerViewInCustomerLists>(QUERY_CUSTOMERS_FROM_LIST, {
      variables: {
        where,
        order,
        offset: (currentPage - 1) * NUMBER_PER_PAGE,
        limit: NUMBER_PER_PAGE,
      },
      pollInterval: SHORT_POLL_INTERVAL,
    });

  const { data: countData, loading: countLoading } =
    useQuery<QueryCustomerViewCountResult>(QUERY_CUSTOMER_FOR_LIST_COUNT, {
      variables: {
        where,
      },
      pollInterval: SHORT_POLL_INTERVAL,
    });

  const customers = useMemo<CustomerViewRow[]>(
    () => customersData?.customers.map(({ customer }) => customer) || [],
    [customersData]
  );

  // Fetch COTs
  const cotIds = uniq(
    customers.flatMap((c) => c.classesOfTrade?.map((cot) => cot.classOfTradeId))
  )?.filter((cotId) => cotId);

  const { data: cotData, loading: cotLoading } =
    useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
      variables: {
        ids: cotIds,
        memberId,
      },
      skip: cotIds.length === 0 || memberId === undefined,
    });

  const rows: TableRow[] = useMemo<TableRow[]>(() => {
    if (!loading && !cotLoading) {
      return rawDataToRows(customerListId, customersData, cotData);
    }
    return [] as TableRow[];
  }, [customersData, cotData, loading, cotLoading]);

  useEffect(() => {
    setCustomersForExport(customers);
  }, [customers]);

  useEffect(() => {
    if (filtersActive || searchText !== '') {
      setAreCustomersFiltered(true);
    } else {
      setAreCustomersFiltered(false);
    }
    updateCurrentPage(1);
    updateWhere(
      filtersToQuery(
        searchText,
        customerListId,
        memberId,
        filtersActive,
        filters
      )
    );
  }, [searchText, filters, filtersActive]);

  useEffect(() => {
    setWhereCustomers(where?.customer ?? {});
  }, [where]);

  const isFiltered = searchText !== '' || filtersActive;
  const disableSearchFilter = !loading && customers.length === 0 && !isFiltered;

  return (
    <div>
      <div className={cx('row')}>
        <SearchBar
          disabled={disableSearchFilter}
          onSubmit={(text) => updateSearchText(text)}
          placeholder='Search by ID, identifier, name, or address'
          text={searchText || ''}
        />
        <ContractCustomerFilter
          applyFilters={(filters, filtersActive) => {
            updateFilters(filters);
            updateFiltersActive(filtersActive);
          }}
          disableFilters={disableSearchFilter}
        />
      </div>
      <Table
        columns={COLUMNS}
        emptyText='No customers found'
        isLoading={loading}
        rows={rows}
      />
      <Pagination
        loading={countLoading}
        currentPage={currentPage}
        className={cx('pagination')}
        numberPerPage={NUMBER_PER_PAGE}
        isDisplayed={rows.length > 0}
        totalCount={countData?.customerCount?.aggregate.count ?? -1}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
      />
    </div>
  );
};

export default CustomersForListTable;
