// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { first } from 'lodash';

// Internal
import { useIsAfterBushNetwork, useAppBarSetTitle } from 'hooks';
import {
  AuthorityIDMapping,
  isHIBCC,
  isHRSA,
  linkToAuthCustomers,
} from 'common/helpers';
import {
  CustomerDetails,
  QueryCustomersByIdsResult,
  GET_CUSTOMERS_BY_IDS,
} from 'query';
import { CONTRACT_PHARMACY } from 'common/constants';
import { BreadcrumbWithSearch } from 'components/BreadcrumbWithSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { Addresses } from 'pages/Customers/View/components';
import { getAuthorityIDBreadcrumb, getIDTitlePrefix } from './helpers';
import RelatedHINSection from './RelatedHIN';
import PharmaciesTable from './Pharmacies';
import Overview from './Overview';

// Styles
import styles from './IdentifierDataDetails.module.css';
const cx = classnames.bind(styles);

interface Props {
  customer: CustomerDetails;
  memberId: AuthorityIDMapping;
}

const IdentifierDataDetails: FC<Props> = (props) => {
  const { memberId, customer } = props;
  const { addresses = [], id, relationshipsFrom } = customer;
  const headerText = id ? `${getIDTitlePrefix(memberId)} ${id}` : '';
  const ids =
    relationshipsFrom
      ?.filter((r) => r.relationship === CONTRACT_PHARMACY)
      .map((r) => r.toId) ?? [];
  const memberName = AuthorityIDMapping[memberId];

  useAppBarSetTitle(headerText);

  const { isAfterBush, networkLoading } = useIsAfterBushNetwork();

  const { data, loading } = useQuery<QueryCustomersByIdsResult>(
    GET_CUSTOMERS_BY_IDS,
    {
      variables: {
        memberId,
        ids,
      },
      skip: ids.length === 0,
    }
  );

  const hasPharmaciesTable = !networkLoading && isAfterBush && isHRSA(memberId);
  const customers340B = (data?.customers ?? []).sort((custA, custB) => {
    const nameA = first(custA.names) ?? '';
    const nameB = first(custB.names) ?? '';
    return nameA.localeCompare(nameB, 'en', { sensitivity: 'base' });
  });

  return (
    <div className={cx('root')} data-testid='details'>
      <div className={cx('column')}>
        <BreadcrumbWithSearch
          module={memberName}
          storage={SessionStorage}
          crumbs={[getAuthorityIDBreadcrumb(memberId), headerText]}
          links={[linkToAuthCustomers(memberId)]}
        />
        <main className={cx('content')}>
          <Overview customer={customer} />
          <Addresses addresses={addresses ?? []} />
          {isAfterBush && isHIBCC(memberId) && (
            <RelatedHINSection customer={customer} />
          )}
          {hasPharmaciesTable && (
            <PharmaciesTable customers={customers340B} loading={loading} />
          )}
        </main>
      </div>
    </div>
  );
};

export default IdentifierDataDetails;
