// Vendor
import React, { FC, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import classnames from 'classnames/bind';

// Internal
import { WidthVariant, getWidth } from './helpers';
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  disabled: boolean;
  handleOnBlur?: () => void;
  input: {
    onBlur?: (e: any) => void;
    onChange: (newValue: any) => void;
    value?: string;
  };
  InputProps?: any;
  meta: {
    dirty: boolean;
    error: string;
    touched: boolean;
  };
  placeholder: string;
  widthVariant?: WidthVariant;
}

const PriceField: FC<Props> = (props) => {
  const {
    disabled = false,
    handleOnBlur,
    input: { onBlur, onChange, value },
    meta: { dirty, error },
    placeholder,
    widthVariant = 'default',
    ...overrides
  } = props;

  const [touched, setTouched] = useState<boolean>(false);

  return (
    <div
      className={cx('input-wrapper')}
      style={{ width: getWidth(widthVariant) }}
    >
      <CurrencyInput
        id='input-price'
        name='input-price'
        disabled={disabled}
        autoComplete='off'
        decimalSeparator='.'
        groupSeparator=','
        prefix='$'
        allowNegativeValue={false}
        decimalScale={4}
        decimalsLimit={4}
        step={1}
        onBlur={(e) => {
          setTouched(true);
          if (onBlur) onBlur(e);
          if (handleOnBlur) handleOnBlur();
        }}
        value={value}
        placeholder={placeholder}
        // 'onValueChange' is also called on input blur
        onValueChange={(newValue: string | undefined) => {
          setTouched(true);

          // do not call onChange callback, if prev and new values are the same
          if (value !== newValue) {
            onChange(newValue);
          }
        }}
        {...overrides}
      />
      <div className={cx('error')}>
        {error === 'Required' ? (touched || dirty) && error : error}
      </div>
    </div>
  );
};

export default PriceField;
