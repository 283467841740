// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import { KeyValuePair, ViewSection } from 'components';
import {
  CustomerDetails,
  QueryClassOfTradeResult,
  QUERY_MULTIPLE_COT,
} from 'query';
import { useTradingPartnerType } from 'hooks';
import { getKeyValuePairs } from './helpers';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  customer: CustomerDetails;
  isLocalCustomer: boolean;
  memberName: string;
}

const Overview: FC<Props> = (props) => {
  const { customer, isLocalCustomer, memberName } = props;
  const { classesOfTrade, memberId } = customer;

  const nodeType = useTradingPartnerType();

  const cotIds = classesOfTrade?.map((c) => c.classOfTradeId) || [];
  const { data } = useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
    variables: {
      memberId,
      ids: cotIds,
    },
    skip: !cotIds.length || memberId === undefined,
  });

  const cots = classesOfTrade?.map((cot) => ({
    ...data?.classesOfTrade?.find((c) => c.id === cot.classOfTradeId),
    endDate: cot.endDate,
    startDate: cot.startDate,
  }));

  const keyValuePairs = getKeyValuePairs(
    customer,
    isLocalCustomer,
    cots,
    nodeType
  );

  return (
    <ViewSection
      disableCollapse
      dataTestid='overview'
      header='Overview'
      sourceName={memberName}
    >
      <div className={cx('content')}>
        {keyValuePairs.map(({ title = '', value, valueIcon, valueLength }) => (
          <KeyValuePair
            key={title}
            title={title}
            value={value}
            valueIcon={valueIcon}
            valueLength={valueLength}
          />
        ))}
      </div>
    </ViewSection>
  );
};

export default Overview;
