import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApiService, useAuthService } from 'hooks';
import Login from './Login';

const LoginContainer: FC = () => {
  const query = new URLSearchParams(window.location.search);
  const [error, setError] = useState(undefined as Error | undefined);
  const auth = useAuthService();
  const api = useApiService();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query.get('code') != null && api) {
      auth?.ssoLoginCompleteAction(dispatch, api);
    } else if (query.get('error') != null) {
      auth?.ssoLoginErrorAction(
        dispatch,
        query.get('error') || '',
        query.get('error_description') || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(new Error('Password-based signin not implemented'));
  };

  const handleSsoSignin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (api) {
      auth?.ssoLoginAction(dispatch, api);
    }
  };

  return (
    <Login
      onSignin={auth?.isPasswordEnabled() ? handleSignin : undefined}
      onSsoSignin={auth?.isSsoEnabled() ? handleSsoSignin : undefined}
      error={error || auth?.authorizationError()}
      ready={!!auth?.isReady()}
      expired={!!auth?.isSessionExpired()}
    />
  );
};

export default LoginContainer;
