// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Internal
import Section from 'components/Section';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import {
  Product,
  QUERY_PRODUCT_LIST_BY_ID,
  QueryProductListResult,
} from 'query';
import {
  useApi,
  useAppBarSetTitle,
  useSnackbar,
  useTradingPartnerConfig,
} from 'hooks';
import { generateID } from 'common/utils';
import {
  getProductsToUpdate,
  updateProducts,
} from 'services/ProductListMgmt/service';
import { ProductList } from 'types/generated/ProductList';
import { IconSystemError } from 'styles/images';
import { Breadcrumb, Button } from 'components';
import { eqProductList, getProductListFields } from './service';
import CreateEditProductsTable from './CreateEditProductsTable';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);
export interface Props {
  existingRecord?: Partial<ProductList>;
  editOnCancel?: () => void;
  currentListProducts?: Product[];
  isError?: boolean;
}

const CreateEditProductList: FC<Props> = (props) => {
  const {
    existingRecord,
    editOnCancel,
    currentListProducts = [],
    isError,
  } = props;
  const isNewList = !existingRecord || !existingRecord.id;

  const { id: memberId } = useTradingPartnerConfig() ?? {};
  if (!memberId) {
    throw new Error('Member ID must be present.');
  }
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const api = useApi();

  useAppBarSetTitle(isNewList ? 'Create Product List' : 'Update Product List');

  const [createProductListId, setCreateProductListId] = useState('');

  const [fetchingProducts, setFetchingProducts] = useState<boolean>(true);

  // Query the updated object after submitting
  const { data: createdProductList, loading } =
    useQuery<QueryProductListResult>(QUERY_PRODUCT_LIST_BY_ID, {
      variables: {
        id: isNewList ? createProductListId : existingRecord.id,
        memberId,
      },
      skip: !(createProductListId && memberId),
      pollInterval: SHORT_POLL_INTERVAL,
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ productList }) => {
        if (productList) {
          navigate(
            productList.id
              ? `/product-lists/${productList.id}`
              : '/product-lists',
            { replace: true }
          );
          snackbar.open({
            message: `Product List ${productList.id} has been ${
              existingRecord ? 'updated' : 'created'
            }.`,
            variant: 'success',
          });
        }
      },
    });

  const createOnCancel = () => {
    navigate('/product-lists', { replace: true });
  };

  const onCancel = editOnCancel || createOnCancel;

  const onSubmit = (values: any) => {
    const targetListId =
      existingRecord && existingRecord.id ? existingRecord.id : generateID();

    setCreateProductListId(targetListId);

    const params = {
      id: targetListId,
      memberId: `ml:member:${memberId}`,
      timestamp: new Date().toISOString(),
      name: values.name,
      revisedAt: new Date().toISOString(),
      description: values.description ? values.description : null,
    };

    const productsToUpdate = getProductsToUpdate(
      currentListProducts,
      values.selectedProducts
    );

    // Product list should be updated if it is new or the values have changed
    if (
      isNewList ||
      existingRecord.name !== values.name ||
      existingRecord.description !== values.description
    ) {
      api?.createProductList(params);
    }

    return updateProducts(productsToUpdate, memberId, api, targetListId);
  };

  const creatingOrUpdating =
    loading || !!(createProductListId && !createdProductList?.productList);

  return isError ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('root')}>
      <Breadcrumb
        crumbs={
          !!existingRecord && !!existingRecord.id
            ? ['Product Lists', existingRecord.id]
            : ['Product Lists', 'Create Product List']
        }
        links={['/product-lists']}
      />
      <Form
        data-testid='form-hey'
        initialValues={{
          name: existingRecord?.name,
          description: existingRecord?.description,
          selectedProducts: currentListProducts,
        }}
        keepDirtyOnReinitialize
        onSubmit={(values) => onSubmit(values)}
        render={({ errors, form, handleSubmit, values, submitting }) => {
          const isUpdate = !!existingRecord;
          const hasUpdatedFields =
            Object.keys(form.getState().dirtyFields).length > 0;
          const shouldDisableUpdate = !hasUpdatedFields && isUpdate;
          const btnLabel = isUpdate ? 'Update' : 'Create';

          return (
            <form onSubmit={handleSubmit}>
              <>
                <section className={cx('buttons')}>
                  <Button
                    color='secondary'
                    dataTestId='cancelButton'
                    label='Cancel'
                    onClick={onCancel}
                    style={{
                      marginLeft: 8,
                      minWidth: 152,
                      padding: 0,
                    }}
                    variant='outlined'
                  />
                  <Button
                    dataTestId='submitButton'
                    disabled={
                      fetchingProducts ||
                      submitting ||
                      creatingOrUpdating ||
                      !isEmpty(errors) ||
                      shouldDisableUpdate
                    }
                    label={btnLabel}
                    loading={creatingOrUpdating}
                    style={{
                      marginLeft: 8,
                      minWidth: 152,
                      padding: 0,
                    }}
                    type='submit'
                  />
                </section>
                <Section
                  change={form.change}
                  fields={getProductListFields()}
                  title='Overview'
                  values={values}
                />
              </>
              <Field
                name='selectedProducts'
                isEqual={(l1: Product[], l2: Product[]) =>
                  eqProductList.equals(l1 ?? [], l2 ?? [])
                }
              >
                {(props) => (
                  <CreateEditProductsTable
                    currentListProducts={props.input.value}
                    onListProductsChange={props.input.onChange}
                    setFetchingProducts={setFetchingProducts}
                  />
                )}
              </Field>
            </form>
          );
        }}
      />
    </div>
  );
};

export default CreateEditProductList;
