// Vendor
import React from 'react';
import get from 'lodash/get';

// Internal
import {
  IconChargebacks,
  IconClassOfTrade,
  IconContracts,
  IconCreditMemos,
  IconCreditTxs,
  IconCustomers,
  IconCustomerProposals,
  IconIdentifierData,
  IconMember,
  IconProduct,
  IconRoster,
  IconRosters,
  IconTradingPartnerManagement,
} from 'styles/images';

export const getIcon = (color: string, link: string) => {
  const iconMapper = {
    chargebacks: <IconChargebacks color={color} />,
    'class-of-trade': <IconClassOfTrade color={color} />,
    contracts: <IconContracts color={color} />,
    'credit-memos': <IconCreditMemos color={color} />,
    'credit-transactions': <IconCreditTxs />,
    'customer-proposals': <IconCustomerProposals fill={color} />,
    customers: <IconCustomers color={color} />,
    'customer-lists': <IconCustomers color={color} />,
    dea: <IconIdentifierData color={color} />,
    hibcc: <IconIdentifierData color={color} />,
    hrsa: <IconIdentifierData color={color} />,
    members: <IconMember color={color} />,
    products: <IconProduct color={color} />,
    'product-lists': <IconProduct color={color} />,
    roster: <IconRoster color={color} />,
    rosters: <IconRosters color={color} />,
    admin: <IconTradingPartnerManagement />,
    'oon-roster': <IconRoster color={color} />,
  };

  return get(iconMapper, link, null);
};
