// Vendor
import { v4 } from 'uuid';
import { isUndefined, omit, orderBy, values } from 'lodash';

// Internal
import {
  maxLongTextLengthValidator,
  required,
  composeValidators,
  withinDateRange,
  getPriceGapText,
  datesHaveGaps,
  getPricesUncoveringText,
  maxIDLengthValidator,
  isSameOrAfterDate,
} from 'common/validation';
import { SectionField } from 'components/Section';
import { StringifiedProductPriceWithID } from 'common/types';
import { ProductPrice } from 'types/generated/Product';
import {
  convertDollarValueToAPIValue,
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  truncateDatetime,
  validatePricingsOverlapping,
} from 'common/helpers';
import { formatProductPricingPrice } from './components/PricingsEditTable/service';

export type ProductDataType = { name: string };

export type ProductPriceWithIdentifier = ProductPrice & { id: string };

export const getProductFields = (
  productTypes: ProductDataType[],
  unitsOfMeasure: ProductDataType[],
  isUpdateMode: boolean
): SectionField[] => [
  {
    isDisabled: isUpdateMode,
    label: 'Product ID',
    name: 'externalId',
    type: 'text',
    validation: [required, maxIDLengthValidator],
    size: 4,
  },
  {
    isDisabled: isUpdateMode,
    label: 'Product ID Type',
    name: 'idType',
    type: 'select',
    selectItems: productTypes.map(({ name }) => ({
      disabled: false,
      label: name,
      name,
    })),
    validation: [required],
    size: 4,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'text',
    validation: [required, maxLongTextLengthValidator],
    size: 4,
  },
  {
    label: 'Unit of Measure (UoM)',
    placeholder: 'Unit of Measure',
    name: 'unitOfMeasure',
    type: 'select',
    selectItems: unitsOfMeasure.map(({ name }) => ({
      label: name,
      name,
    })),
    validation: [required],
    size: 4,
  },
  {
    label: 'Start Date',
    name: 'startDate',
    type: 'date',
    validation: [required],
    size: 4,
  },
  {
    label: 'End Date',
    name: 'endDate',
    type: 'date',
    validation: [required],
    size: 4,
  },
];

/**
 * @description checks pricings errors object for error messages
 * @param pricingsErrors object, that contains pricing errors
 * @returns true, if all props of every 'pricingsErrors' item are 'undefined', or false, if at least 1 prop contains error message
 */
export const productPricingsContainErrors = (
  pricingsErrors: Record<string, string | number | undefined>[]
): boolean =>
  pricingsErrors.some(
    (errors) => !values(errors).every((value) => isUndefined(value))
  );

/**
 * @description callback that is designed to be passed to the 'validate' prop of react-final-form's 'Form' component
 * @param values form values
 * @returns object with error messages
 */
export const validateProductData = (values: Record<string, any>) => {
  const { endDate = '', pricings = [], startDate = '' } = values;
  const errors: any = {};

  const shortFormatStartDate = truncateDatetime(startDate);
  const shortFormatEndDate = truncateDatetime(endDate);

  // validate products end date
  errors.endDate = isSameOrAfterDate(shortFormatStartDate)(shortFormatEndDate);

  // initialize array of pricings errors
  errors.pricings = [];

  // go through every pricings data and validate it
  pricings.forEach((price: ProductPriceWithIdentifier) => {
    // Priority of pricing date range errors: 1) Required 2) End date must be after start date 3) Must be within product range
    // 4) WAC prices must cover product dates 5) Overlapping pricings dates 6) Gap between pricings dates
    let startDateError;
    let endDateError;

    const unorderedPricesOfType = getAllPricesByType(pricings, price.priceType);
    const allPricesOfType = orderBy(
      unorderedPricesOfType,
      (price) => price.startDate,
      ['asc']
    );

    // grabbing the index of the current price in the price by type array since it will be different from our price index in form values
    const indexByType = allPricesOfType.findIndex(
      (priceByType: ProductPriceWithIdentifier) => priceByType === price
    );

    startDateError = composeValidators(
      ...[
        withinDateRange({
          startDate: shortFormatStartDate,
          endDate: shortFormatEndDate,
          message: 'Must be within product date range.',
        }),
      ]
    )(price.startDate);
    endDateError = composeValidators(
      ...[
        isSameOrAfterDate(price.startDate),
        withinDateRange({
          startDate: shortFormatStartDate,
          endDate: shortFormatEndDate,
          message: 'Must be within product date range.',
        }),
      ]
    )(price.endDate);

    // check if this price is the last price in the price by type array and end date should equal products end date
    if (indexByType === allPricesOfType.length - 1) {
      if (price.endDate !== shortFormatEndDate) {
        if (!endDateError) endDateError = getPricesUncoveringText();
      }
    }

    // check if this price is the first price in the price by type array and start date should equal products start date
    if (indexByType === 0) {
      if (price.startDate !== shortFormatStartDate) {
        if (!startDateError) startDateError = getPricesUncoveringText();
      }
    }

    // validate pricings overlapping and return errors for every invalid pricing (by price type!)
    const pricingsOverlapError = validatePricingsOverlapping(
      allPricesOfType as ProductPrice[]
    );

    if (pricingsOverlapError[indexByType]) {
      if (!startDateError) startDateError = pricingsOverlapError[indexByType];
    }

    if (price.endDate && allPricesOfType[indexByType + 1]?.startDate) {
      const nextWAC = allPricesOfType[indexByType + 1];

      if (datesHaveGaps(price.endDate)(nextWAC.startDate)) {
        const gapError = getPriceGapText(price.endDate, nextWAC.startDate);
        if (!endDateError) endDateError = gapError;
      }
    }

    // if there is an invalid pricing, push pricing errors to errors object
    errors.pricings.push({
      startDate: startDateError,
      endDate: endDateError,
    });
  });

  // if no errors were added to the 'pricings' prop, we assign 'pricigns' to 'undefined'
  if (
    !errors.pricings.length ||
    !productPricingsContainErrors(errors.pricings)
  ) {
    errors.pricings = undefined;
  }
  return errors;
};

/**
 * @description formats pricings values for GraphQL API , removes props that the API does not expect
 * @param pricings object with pricings data, including id that we inject after fetching the pricings
 * @returns formatted pricings, that can be passed to GraphQL API
 */
export const formatProductPricingsForAPI = (
  pricings: ProductPriceWithIdentifier[]
): ProductPrice[] =>
  pricings.map((pricing: ProductPriceWithIdentifier) => {
    const { price, startDate, endDate } = pricing;
    const pricingWithoutId = omit(pricing, 'id');

    pricingWithoutId.price = convertDollarValueToAPIValue(price);
    pricingWithoutId.startDate = convertStartDateToUtcTimestamp(startDate);
    pricingWithoutId.endDate = convertEndDateToUtcTimestamp(endDate);

    return pricingWithoutId;
  });

/**
 * @description divides pricing price by 1_000_000 and optionally converts it to US currency, converts pricing date range into short format
 * @param pricings array of pricings with price multiplied by 1_000_000, and date range in UTC format
 * @param convertPriceToCurrency boolean, that defines if we need to add '$' sign and divide price value by 1_000_000 or not
 * @returns array of pricings with formatted price and date range in short format
 */
export const formatProductPricingsFromAPI = (
  pricings: ProductPrice[],
  convertPriceToCurrency?: boolean
): StringifiedProductPriceWithID[] =>
  pricings.map((pricing: ProductPrice): StringifiedProductPriceWithID => {
    const { priceType, price, startDate, endDate } = pricing;

    const formattedPricing: string = formatProductPricingPrice(
      price.toString(),
      !!convertPriceToCurrency
    );

    return {
      id: v4(),
      priceType,
      price: formattedPricing,
      startDate: truncateDatetime(startDate) || '',
      endDate: truncateDatetime(endDate) || '',
    };
  });

/**
 * @description sorts product pricings by start date in ascending order
 */
export const sortProductPricingsByStartDate = (
  pricings: (
    | StringifiedProductPriceWithID
    | ProductPrice
    | ProductPriceWithIdentifier
  )[],
  emptyInTheEnd: boolean = false
): (
  | StringifiedProductPriceWithID
  | ProductPrice
  | ProductPriceWithIdentifier
)[] => {
  // create a copy of pricings array so we don't mutate the argument itself later
  const pricingsCopy = [...pricings];

  return pricingsCopy.sort((a, b) =>
    emptyInTheEnd && !a.startDate ? 1 : a.startDate!.localeCompare(b.startDate!)
  );
};

export const getAllPricesByType = (
  pricings: (
    | StringifiedProductPriceWithID
    | ProductPrice
    | ProductPriceWithIdentifier
  )[],
  priceType: string
): any =>
  pricings.filter((pricing) => {
    if (pricing.priceType === priceType) return true;
    return false;
  });
