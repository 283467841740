// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { isEmpty, uniq } from 'lodash';
import { Paper } from '@material-ui/core';

// Internal
import { Table } from 'components';
import { RELATED_HINS } from 'common/constants';
import { IconLoading, IconSystemError } from 'styles/images';
import {
  GET_RELATED_HINS_BY_ID,
  GET_CUSTOMERS_BY_IDS,
  QueryRelationshipsResult,
  QueryCustomersByIdsResult,
  CustomerDetails,
} from 'query';
import { getColumns, getRows } from './helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  customer: CustomerDetails;
}

const RelatedHINSection: FC<Props> = (props) => {
  const { customer } = props;
  const { id, memberId } = customer;

  const {
    data: relatedHinData,
    loading: relatedHinLoading,
    error: relatedHinError,
  } = useQuery<QueryRelationshipsResult>(GET_RELATED_HINS_BY_ID, {
    variables: {
      id,
      memberId,
      relationshipType: RELATED_HINS,
    },
    skip: isEmpty(id),
  });

  const childrenHINs = relatedHinData?.children?.map((r) => r.toId) || [];
  const parentHINs = relatedHinData?.parents?.map((r) => r.fromId) || [];
  const allRelatedHINs = parentHINs
    .concat(childrenHINs)
    .filter((hin) => hin.length && hin !== id);
  const uniqueRelatedHINs = uniq(allRelatedHINs);

  const customerStartDate = customer.startDate;

  const { data, loading, error } = useQuery<QueryCustomersByIdsResult>(
    GET_CUSTOMERS_BY_IDS,
    {
      variables: {
        memberId,
        ids: uniqueRelatedHINs,
      },
      skip: isEmpty(uniqueRelatedHINs),
    }
  );

  if (isEmpty(uniqueRelatedHINs)) {
    return null;
  }

  if (loading || relatedHinLoading) {
    return (
      <Paper className={cx('paper')}>
        <div className={cx('loading')}>
          <IconLoading />
        </div>
      </Paper>
    );
  }

  const relatedCustomers = data?.customers || [];

  if (error || relatedHinError) {
    return (
      <Paper className={cx('paper')}>
        <IconSystemError />
      </Paper>
    );
  }

  const columns = getColumns();
  const rows = getRows(relatedCustomers, customerStartDate);

  return (
    <Paper className={cx('root')}>
      <div className={cx('column', 'content')}>
        <div className={cx('row')}>
          <header className={cx('header')}>Related HINs</header>
        </div>
        <Table
          columns={columns}
          isLoading={loading}
          emptyText="No Related HIN's found"
          rows={rows}
        />
      </div>
    </Paper>
  );
};

export default RelatedHINSection;
