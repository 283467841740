// Internal
import { AHD_ID, DEA_ID, HIBCC_ID, HRSA_ID, NPI_ID } from 'common/constants';

export enum AuthorityIDMapping {
  AHD = AHD_ID,
  DEA = DEA_ID,
  HIBCC = HIBCC_ID,
  HRSA = HRSA_ID,
  NPI = NPI_ID,
}

export const isAuthorityData = (memberId: number) =>
  Object.values(AuthorityIDMapping).includes(memberId);

export const isDEA = (memberId: AuthorityIDMapping) =>
  memberId === AuthorityIDMapping.DEA;
export const isHIBCC = (memberId: AuthorityIDMapping) =>
  memberId === AuthorityIDMapping.HIBCC;
export const isHRSA = (memberId: AuthorityIDMapping) =>
  memberId === AuthorityIDMapping.HRSA;

export interface MediledgerId {
  domain: string;
  memberId: number;
  type: string;
  id: string;
}

export const authorityIDToName = (memberId: AuthorityIDMapping) => {
  const prefixes: Record<AuthorityIDMapping, string> = {
    [AuthorityIDMapping.HRSA]: '340B',
    [AuthorityIDMapping.AHD]: 'AHD',
    [AuthorityIDMapping.DEA]: 'DEA',
    [AuthorityIDMapping.HIBCC]: 'HIN',
    [AuthorityIDMapping.NPI]: 'NPI',
  };
  return prefixes[memberId];
};

export const authorityIDToSource = (memberId: AuthorityIDMapping) => {
  const prefixes: Record<AuthorityIDMapping, string> = {
    [AuthorityIDMapping.AHD]: 'AHD',
    [AuthorityIDMapping.DEA]: 'DEA',
    [AuthorityIDMapping.HIBCC]: 'HIBCC',
    [AuthorityIDMapping.HRSA]: 'HRSA',
    [AuthorityIDMapping.NPI]: 'NPI',
  };
  return prefixes[memberId];
};

export const parseMediledgerId = (mlId: string): MediledgerId => {
  let memberId: number = 0;

  if (!mlId) {
    return {
      domain: '',
      id: '',
      memberId: 0,
      type: '',
    };
  }

  const [domain, type, id1Str, id2Str] = mlId.split(':');
  let id: string = id1Str ?? '';

  switch (domain.toLowerCase()) {
    case 'ml':
      memberId = parseInt(id1Str, 10);
      id = id2Str;
      break;

    case 'dea':
      memberId = AuthorityIDMapping.DEA;
      break;

    case 'hin':
    case 'hibcc':
      memberId = AuthorityIDMapping.HIBCC;
      break;

    case 'hrsa':
    case '340b':
      memberId = AuthorityIDMapping.HRSA;
      break;

    case 'npi':
      memberId = AuthorityIDMapping.NPI;
      break;

    case 'ahd':
      memberId = AuthorityIDMapping.AHD;
      break;

    default:
      break;
  }

  return { domain, id, memberId, type };
};
