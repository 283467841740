// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { keyBy } from 'lodash';

// Internal
import {
  QUERY_CREDIT_MEMOS,
  QUERY_CREDIT_MEMOS_COUNT,
  QueryCreditMemos,
  QueryCreditNotesCountResult,
  GET_TRADING_PARTNERS,
  QueryTradingPartners,
} from 'query';
import { IconSystemError } from 'styles/images';
import {
  useAppBarSetTitle,
  useTradingPartnerConfig,
  useTradingPartnerType,
} from 'hooks';
import { TableColumn, TableRow } from 'components/Table';
import { NUMBER_PER_PAGE } from 'common/constants';
import { Pagination, SearchBar, Table } from 'components';
import { creditMemoPath } from 'common/helpers';
import { buildWhereClause, rawDataToRows } from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const ChargebacksContainer: FC = () => {
  useAppBarSetTitle('Credit Transactions');

  // Get additional information
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const tpType = useTradingPartnerType();
  const isMFR = tpType === 'MFR';

  // Table definitions
  const columns: TableColumn[] = [
    // Unique ID for this specific credit note (generated by MediLedger)
    // Data source: creditNote.id
    {
      headerName: 'CREDIT MEMO',
      type: 'link',
      size: 'small',
      displayTextFn: (data: any) => data.id,
      urlFn: (data: any) => creditMemoPath(data.id),
    },
    // The debit memo ID value that this credit is linked to
    // Data source: creditNote.debit_memo_id
    {
      dataField: 'debitMemoId',
      headerName: 'DEBIT MEMO',
      type: 'expanding',
      size: 'small',
    },
    // Depending on the node, the submitting distributor or the receiving manufacturer utilizing the node’s trading partner config
    // Data source: distributor_name or manufacturer_name
    {
      dataField: 'tradingPartnerName',
      headerName: isMFR ? 'DISTRIBUTOR' : 'MANUFACTURER',
      type: 'expanding',
      size: 'small',
    },
    // Total amount of the credit
    // Data source: creditNote.credit_amount
    {
      dataField: 'creditAmount',
      headerName: 'CREDIT AMOUNT',
      type: 'expanding',
      size: 'small',
    },
    // Date & time Credit Note was generated
    // Data source: creditNote.created_on
    {
      dataField: 'creationDate',
      headerName: 'CREATION DATE',
      type: 'date',
      size: 'medium',
    },
  ];

  // Table rows
  const [rows, updateRows] = useState([] as TableRow[]);
  // Search
  const [searchTerm, updateSearchTerm] = useState('');
  // Order
  const [order] = useState({ created_on: 'desc' });
  // Pagination
  const [currentPage, updateCurrentPage] = useState(1);
  // Where condition
  const [where, updateWhere] = useState(buildWhereClause(searchTerm));

  // Fetch the data
  const {
    data: creditMemosData,
    loading,
    error,
  } = useQuery<QueryCreditMemos>(QUERY_CREDIT_MEMOS, {
    variables: {
      where,
      order,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      limit: NUMBER_PER_PAGE,
    },
  });
  const creditNotes = creditMemosData?.creditNotes || [];

  // Fetch the count
  const { data: countData, loading: countLoading } =
    useQuery<QueryCreditNotesCountResult>(QUERY_CREDIT_MEMOS_COUNT, {
      variables: {
        where,
      },
    });

  // Fetch trading partners
  const { data: tradingPartnersData, loading: tradingPartnersLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });

  // Format the raw data
  useEffect(() => {
    if (!loading && !tradingPartnersLoading) {
      const tradingPartners = keyBy(
        tradingPartnersData?.tradingPartners || [],
        (p) => p.id
      );
      updateRows(rawDataToRows(tradingPartners, isMFR, creditMemosData));
    }
  }, [creditMemosData, loading, tradingPartnersData, tradingPartnersLoading]);

  // Build the search query
  useEffect(() => {
    updateCurrentPage(1);
    updateWhere(buildWhereClause(searchTerm));
  }, [searchTerm]);

  const hasCreditNotes = creditNotes.length > 0;

  const isFiltered = searchTerm !== '';
  const disableSearchFilter = !loading && !hasCreditNotes && !isFiltered;

  // Table with layout
  return !memberId || error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('root')}>
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            disabled={disableSearchFilter}
            onSubmit={(text) => updateSearchTerm(text)}
            placeholder='Search by Credit Memo ID or Debit Memo ID'
          />
        </div>
      </section>
      <Table
        columns={columns}
        rows={rows}
        emptyText='No credit memos found'
        isLoading={loading || tradingPartnersLoading}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={countLoading}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={rows.length > 0}
        totalCount={countData?.creditNotesCount?.aggregate.count ?? -1}
      />
    </div>
  );
};

export default ChargebacksContainer;
