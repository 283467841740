// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { startCase } from 'lodash';

// Internal
import Table from 'components/Table';
import { NotFoundSection, Switch, ViewSection } from 'components';
import { filterExpiredLists } from 'common/helpers';
import { CustomerDetailsList } from 'query';
import { getColumns, getRows } from './helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  lists: CustomerDetailsList[];
  memberName?: string;
  title: string;
}

const Programs: FC<Props> = (props) => {
  const { lists: listsProps, memberName = '', title } = props;

  const sortedLists = listsProps.sort((listA, listB) => {
    const nameA = listA.list?.name ?? '';
    const nameB = listB.list?.name ?? '';
    return nameA.localeCompare(nameB, 'en', { sensitivity: 'base' });
  });

  const [activeOnly, toggleActiveOnly] = useState<boolean>(true);
  const [lists, setLists] = useState<CustomerDetailsList[]>([]);

  useEffect(() => {
    if (activeOnly) setLists(filterExpiredLists(sortedLists));
    else setLists(sortedLists);
  }, [activeOnly, listsProps]);

  const columns = getColumns(title);
  const rows = getRows(lists, title);

  return (
    <ViewSection
      dataTestid={title}
      disableCollapse={listsProps.length === 0}
      emptySection={listsProps.length === 0}
      header={startCase(title)}
      headerContent={
        title === 'Programs' &&
        listsProps.length > 0 && (
          <Switch
            checked={activeOnly}
            label='Only Active'
            onChange={() => toggleActiveOnly(!activeOnly)}
          />
        )
      }
      sourceName={memberName}
    >
      <div className={cx('tableContent')}>
        {lists.length === 0 ? (
          <NotFoundSection text={`No ${title} found`} />
        ) : (
          <Table
            columns={columns}
            isLoading={false}
            emptyText={`No ${title} found`}
            rows={rows}
          />
        )}
      </div>
    </ViewSection>
  );
};

export default Programs;
