import { Eq, struct } from 'fp-ts/Eq';
import { Eq as eqString } from 'fp-ts/string';
import { Semigroup } from 'fp-ts/Semigroup';
import { CustomerAddress } from 'types/generated/CustomerAddress';
import { CustomerClassOfTrade } from 'types/generated/CustomerClassOfTrade';
import { Attribute } from 'types/generated/Attribute';
import { Customer } from 'types/generated/Customer';
import { CustomerListMembership } from 'types/generated/CustomerListMembership';
import { getArraySemigroup } from 'common/fpUtils';
import { getOptionalEq } from 'common/eqUtils';
import { getConcatAll } from '../semigroup-util';

const eqCustomerAddress: Eq<CustomerAddress> = struct({
  address1: eqString,
  zipCode: eqString,
  state: eqString,
  city: eqString,
});

const eqCot: Eq<CustomerClassOfTrade> = struct({
  classOfTradeId: eqString,
});

const eqAttribute: Eq<Attribute> = struct({
  name: eqString,
  value: eqString,
});

const eqCustomerList = struct<CustomerListMembership>({
  listId: eqString,
  startDate: getOptionalEq(eqString),
  endDate: getOptionalEq(eqString),
});

const cotListSemigroup = getArraySemigroup<CustomerClassOfTrade>(eqCot);

const addressListSemigroup =
  getArraySemigroup<CustomerAddress>(eqCustomerAddress);

const stringListSemigroup = getArraySemigroup<string>(eqString);

const attributeListSemigroup = getArraySemigroup<Attribute>(eqAttribute);

const customerListListSemigroup = getArraySemigroup(eqCustomerList);

export const customerSemigroup: Semigroup<Customer> = {
  concat(x, y) {
    const lists = customerListListSemigroup.concat(
      x.lists ?? [],
      y.lists ?? []
    );
    return {
      id: x.id,
      memberId: x.memberId,
      timestamp: x.timestamp,
      addresses: addressListSemigroup.concat(
        x.addresses ?? [],
        y.addresses ?? []
      ),
      classesOfTrade: cotListSemigroup.concat(
        x.classesOfTrade ?? [],
        y.classesOfTrade ?? []
      ),
      identifiers: stringListSemigroup.concat(
        x.identifiers ?? [],
        y.identifiers ?? []
      ),
      names: stringListSemigroup.concat(x.names ?? [], y.names ?? []),
      startDate: x.startDate,
      endDate: x.endDate,
      attributes: attributeListSemigroup.concat(
        x.attributes ?? [],
        y.attributes ?? []
      ),
      lists: lists.length > 0 ? lists : undefined,
    };
  },
};

export const concatCustomers = (customers: Customer[]) =>
  getConcatAll<Customer>(customers, customerSemigroup);
