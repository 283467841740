// Vendor
import dayjs from 'dayjs';
import { MenuItem } from '@material-ui/core';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';
import { get, isEmpty, isEqual, omit } from 'lodash';
import { ValidationErrors } from 'final-form';

// Internal
import { required } from 'common/validation';
import { Button, DatePicker, Select, TextField } from 'components';
import { useDocumentTypes, useApi, useSnackbar, DocumentApiArgs } from 'hooks';
import { Document } from 'types/generated/Document';
import { CustomerWithDocuments } from '../types';

// Styles
import styles from './EditDocument.module.css';

const cx = classnames.bind(styles);

interface Props {
  change: (key: string, val: string) => void;
  changeSection: (section: string) => void;
  customer: CustomerWithDocuments;
  errors: ValidationErrors;
  memberId: number;
  selectedDocument: Document;
  values: any;
}

const EditDocument: FC<Props> = ({
  change,
  changeSection,
  customer,
  errors,
  memberId,
  selectedDocument,
  values,
}) => {
  const api = useApi();
  const snackbar = useSnackbar();

  const onClick = ({
    manufacturer,
    type,
  }: {
    manufacturer: string;
    type: string;
  }) => {
    // eslint-disable-next-line no-param-reassign
    values.manufacturer = manufacturer;
    const newDocument = { ...selectedDocument };
    newDocument.metadata = values;

    const apiArgs: DocumentApiArgs = {
      id: customer.id,
      memberId: `ml:member:${memberId}`,
      timestamp: dayjs(Date.now()).utc(),
      actions: [
        {
          action: 'edit',
          document: newDocument,
        },
      ],
    };

    api?.documents(apiArgs).then(() => {
      changeSection('documentList');
      snackbar.open({
        message: `${manufacturer}'s ${type} has been updated.`,
        variant: 'success',
      });
    });
  };

  const documentTypes = useDocumentTypes();
  const { metadata, type } = selectedDocument;
  const manufacturer = get(metadata, 'manufacturer', '');
  const currentValues = omit(metadata, ['manufacturer']);
  const noFieldsChanged = isEqual(values, currentValues);
  const DISABLE_SUBMIT = !isEmpty(errors) || noFieldsChanged;

  const currentDocType = documentTypes.find(
    (doc) => doc.type === selectedDocument.type
  );
  const displayedFields = currentDocType?.attributes
    // @ts-ignore
    .filter(({ fieldName }) => fieldName !== 'manufacturer');

  return (
    <>
      <section className={cx('buttonContainer')}>
        <Button
          color='secondary'
          dataTestId='cancelButton'
          label='Cancel'
          onClick={() => changeSection('documentList')}
          style={{
            width: 120,
          }}
          variant='outlined'
        />
        <Button
          dataTestId='confirmButton'
          disabled={DISABLE_SUBMIT}
          label='Confirm Update'
          onClick={() => onClick({ manufacturer, type })}
          style={{
            border: 'none',
            marginLeft: 8,
            padding: 0,
            width: 120,
          }}
        />
      </section>
      <section className={cx('editSection')}>
        <div className={cx('field')}>
          <span className={cx('title')}>Manufacturer</span>
          {manufacturer}
        </div>
        <div className={cx('field')}>
          <span className={cx('title')}>Type of Document</span>
          {type}
        </div>
        {(displayedFields || []).map((attribute: any) => {
          const { fieldName, isRequired, name, type } = attribute;
          const fieldValue = get(values, `${fieldName}`, '');

          return (
            <div key={fieldName} className={cx('field')}>
              <span className={cx('ellipsis', 'title')}>
                {name}
                {isRequired && ' *'}
              </span>
              {type === 'date' && (
                <Field
                  // @ts-ignore
                  component={DatePicker}
                  name={fieldName}
                  style={{ width: '100%' }}
                  validate={required}
                />
              )}
              {type === 'string' && (
                <Field
                  // @ts-ignore
                  component={TextField}
                  name={fieldName}
                  placeholder={name}
                  validate={isRequired ? required : undefined}
                />
              )}
              {type === 'boolean' && (
                <Field
                  // @ts-ignore
                  component={Select}
                  label={
                    fieldValue === 'true'
                      ? 'Yes'
                      : fieldValue === 'false'
                      ? 'No'
                      : ''
                  }
                  name={fieldName}
                  validate={required}
                >
                  <MenuItem
                    onClick={() => {
                      change(fieldName, 'true');
                    }}
                    value='true'
                    style={{
                      maxWidth: 'none',
                      paddingRight: 0,
                    }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      change(fieldName, 'false');
                    }}
                    value='false'
                    style={{
                      maxWidth: 'none',
                      paddingRight: 0,
                    }}
                  >
                    No
                  </MenuItem>
                </Field>
              )}
            </div>
          );
        })}
      </section>
    </>
  );
};

export default EditDocument;
