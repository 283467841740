// Vendor
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import { Breadcrumb, Stepper } from 'components';
import {
  GET_TRADING_PARTNERS,
  QueryCustomerByIdResult,
  QueryTradingPartners,
  QUERY_CUSTOMER_BY_ID,
} from 'query';
import {
  MappingAssociation,
  useApi,
  useAppBarSetTitle,
  useSnackbar,
  useTradingPartnerConfig,
} from 'hooks';
import { POLL_INTERVAL } from 'common/constants';
import RosterCustomer from './components/RosterCustomer';
import GPO from './components/GPO';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

const Container: FC = () => {
  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { id: memberId } = useTradingPartnerConfig() ?? {};

  const { data } = useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
    variables: { id: {} },
  });
  const tradingPartners =
    data?.tradingPartners?.filter((tp) => tp.type === 'gpo') ?? [];

  const { data: customerData, loading } = useQuery<QueryCustomerByIdResult>(
    QUERY_CUSTOMER_BY_ID,
    {
      variables: {
        id,
        memberId,
      },
      pollInterval: POLL_INTERVAL,
    }
  );

  const mappings = customerData?.customer?.fromCustomerMappings || [];
  const mappedIds = mappings.map(
    (mapping: { toCustomer: { id: string } }) => mapping.toCustomer.id
  );
  const names = customerData?.customer?.names || [];
  useAppBarSetTitle(names[0] || '{ }');

  const onSubmit = (toCustomer: {
    id: string;
    memberId: number;
    names: string[];
  }) => {
    const { id: toId, memberId: toMemberId, names } = toCustomer;
    const payload: MappingAssociation = {
      active: true,
      fromId: `ml:customer:${memberId}:${id}`,
      kind: 'mapping-association',
      memberId: `ml:member:${memberId}`,
      timestamp: dayjs(Date.now()).utc(),
      toId: `ml:customer:${toMemberId}:${toId}`,
    };
    api?.upsertCustomerAssociation(payload);

    navigate(`/customers/${encodeURIComponent(id)}`, { replace: true });

    snackbar.open({
      message: `Customer ${names[0]} has been mapped!`,
      variant: 'success',
    });
  };

  const [gpo, setGPO] = useState(0);
  const [toCustomer, setToCustomer] = useState({
    id: '',
    memberId: 0,
    names: [''],
  });

  const steps = [
    {
      content: (
        <GPO gpo={gpo} setGPO={setGPO} tradingPartners={tradingPartners} />
      ),
      isDisabled: !gpo,
      label: 'GPO',
    },
    {
      content: (
        <RosterCustomer
          customerId={toCustomer.id}
          gpo={gpo}
          mappedIds={mappedIds}
          setToCustomer={setToCustomer}
        />
      ),
      isDisabled: !toCustomer.id,
      label: 'ROSTER CUSTOMER',
    },
  ];

  const handleCancel = () => {
    navigate(`/customers/${encodeURIComponent(id)}`, { replace: true });
  };

  if (loading) return null;

  return (
    <div className={cx('root')}>
      <Breadcrumb crumbs={['Customers', names[0]]} links={[`/customers`]} />
      <div data-testid='createMapping'>
        <Stepper
          handleCancel={handleCancel}
          onSubmit={() => onSubmit(toCustomer)}
          steps={steps}
        />
      </div>
    </div>
  );
};

export default Container;
