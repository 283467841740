// Vendor
import Paper from '@material-ui/core/Paper';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';

// Internal
import { EmptyResults } from 'components';
import { formatCurrency, truncateDatetime } from 'common/helpers';
import { Pricing } from 'types/generated/Pricing';
import { Tooltip } from 'components/Tooltips';

// Styles
import styles from './Table.module.css';
const cx = classnames.bind(styles);

interface Props {
  pricings: Pricing[];
  bgColor: string;
}

const ProductPricingTable: FC<Props> = (props) => {
  const { bgColor, pricings } = props;

  const headerData = [
    'UOM',
    'CONTRACT PRICE',
    'BASIS PRICE',
    'START DATE',
    'END DATE',
  ];
  const pricingsToSort = cloneDeep(pricings);

  // Note: the `elevation={0}` is to remove the shadow of this table
  return (
    <Paper data-testid='pricingTierTable' className={cx('paper')} elevation={0}>
      {pricings?.length > 0 && (
        <Table>
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <TableHead>
            <TableRow style={{ background: bgColor, height: 32 }}>
              {headerData.map((data) => (
                <TableCell key={data} style={{ padding: '8px 16px' }}>
                  {data}
                </TableCell>
              ))}
              <TableCell style={{ background: bgColor }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              // Note: sort pricing by startDate before displaying.
              // Because the date is a ISO 8601 string, using the string directly to sort is ok
              pricingsToSort
                .sort((a, b) => a.startDate.localeCompare(b.startDate))
                .map((row: Pricing) => {
                  const {
                    contractPrice,
                    endDate,
                    startDate,
                    unitOfMeasure,
                    wac,
                  } = row;
                  const startDateTruncated = truncateDatetime(startDate);
                  const endDateTruncated = truncateDatetime(endDate);
                  const contractPriceDisplayed = formatCurrency(contractPrice);
                  const wacDisplayed =
                    Number(wac) < 0 ? '' : formatCurrency(wac);

                  return (
                    <TableRow
                      key={startDate + endDate}
                      style={{ background: bgColor, height: 32 }}
                    >
                      <TableCell style={{ paddingBottom: 0 }}>
                        <Tooltip title={unitOfMeasure}>{unitOfMeasure}</Tooltip>
                      </TableCell>
                      <TableCell style={{ paddingBottom: 0 }}>
                        <Tooltip title={contractPriceDisplayed}>
                          {contractPriceDisplayed}
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{ padding: '16px 16px 0' }}>
                        <Tooltip title={wacDisplayed}>{wacDisplayed}</Tooltip>
                      </TableCell>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          verticalAlign: 'middle',
                        }}
                      >
                        <span className={cx('row')}>
                          <Tooltip title={startDateTruncated}>
                            {startDateTruncated}
                          </Tooltip>
                        </span>
                      </TableCell>
                      <TableCell style={{ paddingBottom: 0 }}>
                        <Tooltip title={endDateTruncated}>
                          {endDateTruncated}
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{ background: bgColor }} />
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
      )}
      {!pricings?.length && <EmptyResults text='No pricing found' />}
    </Paper>
  );
};

export default ProductPricingTable;
