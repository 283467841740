export * from './chargebacks';
export * from './classOfTrade';
export * from './contract';
export * from './customer';
export * from './customerLists';
export * from './networkUpgrade';
export * from './parent';
export * from './relationships';
export * from './tradingPartners';
export * from './creditMemos';
export * from './products';
export * from './productLists';
export * from './association';
export * from './proposals';

/**
 * Wraps the search query with wildcard characters %
 * @param s
 */
export const wrapSearchText = (s: string) => {
  if (!s) {
    return '%';
  }
  return `%${s.replace(/%/g, '\\%')}%`;
};
