// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { first, get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

// Internal
import IconLink from 'styles/images/IconLink';
import {
  authorityIDToSource,
  extractRelationshipIds,
  formatAddresses,
  formatDate,
  generateStatus,
  getCotText,
  isDEA,
  linkToAuthCustomerDetail,
  parseMediledgerId,
  truncateDatetime,
} from 'common/helpers';
import {
  GET_IDENTIFIER_DATA,
  QUERY_MULTIPLE_COT,
  QueryClassOfTradeResult,
  QueryIdentifierDataResult,
} from 'query';
import { POLL_INTERVAL, RELATED_HINS } from 'common/constants';
import { useDEAValidationOnly } from 'hooks';
import { Divider } from '@material-ui/core';
import { KeyValuePair } from 'components';
import { KeyValueProps } from 'components/ViewSection';
import { Tooltip } from 'components/Tooltips';

// Styles
import inlineStyles from './inlineStyles';
import styles from './Identifier.module.css';
const cx = classnames.bind(styles);

export interface Props {
  classes: {
    divider: string;
  };
  identifier: string;
  notLast: boolean;
}

const tooltip = (
  <Tooltip
    disableHoverListener={false}
    title="Has related HIN's"
    placement='top-start'
  >
    <IconLink className={cx('icon')} width='14' height='14' />
  </Tooltip>
);

const Identifier: FC<Props> = (props) => {
  const { classes, identifier, notLast } = props;

  const { memberId, id } = parseMediledgerId(identifier);
  const isSimpleDEA = useDEAValidationOnly() && isDEA(memberId);

  const { data, loading } = useQuery<QueryIdentifierDataResult>(
    GET_IDENTIFIER_DATA,
    {
      variables: {
        memberId,
        id,
        simpleDEA: isSimpleDEA,
      },
      skip: !id.length || memberId === undefined,
    }
  );

  const identifierData = data?.identifier;

  const cotIds =
    identifierData?.classesOfTrade?.map((cot) => cot.classOfTradeId) || [];
  const { data: cotData, loading: cotLoading } =
    useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
      variables: {
        ids: cotIds,
        memberId,
      },
      skip: isSimpleDEA || !cotIds.length || memberId === undefined,
      pollInterval: POLL_INTERVAL,
    });
  const cot = first(cotData?.classesOfTrade || []);

  const source = authorityIDToSource(memberId);

  const {
    addresses,
    endDate,
    names,
    startDate,
    timestamp = '',
  } = identifierData || {};

  if (loading || cotLoading) return null;

  const referBackIds =
    identifier && identifierData
      ? extractRelationshipIds([identifierData], RELATED_HINS)
      : new Set<string>();

  const name = names?.[0];
  const formattedAddresses = formatAddresses(addresses ?? []);
  const address = get(formattedAddresses, '[0].fullAddress', '');
  const startDateTruncated = truncateDatetime(startDate);
  const endDateTruncated = truncateDatetime(endDate);
  const formattedUpdatedAt = formatDate(timestamp);
  const status = generateStatus({ endDate, startDate });
  const { domain } = parseMediledgerId(identifier);
  const cotTitle = getCotText(memberId);

  const hasCustomerDetails = id.length && !isDEA(memberId) && identifierData;
  const idPair: KeyValueProps = { key: domain.toUpperCase(), value: id };
  if (hasCustomerDetails) {
    idPair.linkUrl = linkToAuthCustomerDetail(memberId, id);
  }
  if (referBackIds.size > 0) {
    idPair.valueIcon = tooltip;
  }

  const simpleDEAKeyValues = [
    idPair,
    { key: 'Start Date', value: startDateTruncated },
    { key: 'End Date', value: endDateTruncated },
    { key: 'Status', value: status },
    { key: 'Updated At', value: formattedUpdatedAt },
  ];

  const normalKeyValues = [
    idPair,
    { key: 'Name', value: name },
    { key: 'Address', value: address },
    { key: cotTitle, value: cot?.name },
    { key: 'Start Date', value: startDateTruncated },
    { key: 'End Date', value: endDateTruncated },
    { key: 'Status', value: status },
    { key: 'Source', value: source },
    { key: 'Updated At', value: formattedUpdatedAt },
  ];

  const keyValues =
    isSimpleDEA || !identifierData ? simpleDEAKeyValues : normalKeyValues;

  return (
    <div key={id}>
      <div className={cx('content')}>
        {keyValues.map((props) => {
          const {
            key = '',
            linkUrl,
            value,
            valueIcon,
          } = props as KeyValueProps;
          return (
            <KeyValuePair
              key={key}
              linkUrl={linkUrl}
              title={key}
              value={value}
              valueIcon={valueIcon}
            />
          );
        })}
      </div>
      {notLast && (
        <Divider
          classes={{
            root: classes.divider,
          }}
        />
      )}
    </div>
  );
};

export default withStyles(inlineStyles)(Identifier);
