// Vendor
import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

// Internal
import AdvancedFilter from 'components/Filters/AdvancedFilter';
import { FilterDefinitionsInterface } from 'components/FilterGroup';
import { Filters } from 'pages/Contracts/service';

export interface Props {
  applyFilters: (filters: Filters, filtersActive: boolean) => void;
  disableFilters: boolean;
  cFilters?: Filters;
  cFiltersActive?: boolean;
}

const ContractCustomerFilter: FC<Props> = (props) => {
  const { applyFilters, disableFilters, cFilters, cFiltersActive } = props;

  const initialFilters: Filters = {
    showValidWithNoWarning: true,
    showValidWithWarning: true,
    showInvalidWithError: true,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [filtersActive, setFiltersActive] = useState(false);

  // This effect allow us to force the load of the received (filters, filtersActive).
  useEffect(() => {
    if (cFilters !== undefined && !isEqual(cFilters, filters)) {
      setFilters(cFilters);
    }
    if (cFiltersActive !== undefined && cFiltersActive !== filtersActive) {
      setFiltersActive(cFiltersActive);
    }
  }, [cFilters, cFiltersActive]);

  useEffect(() => {
    setFiltersActive(!isEqual(filters, initialFilters));
  }, [filters]);

  const filterDefinitions: FilterDefinitionsInterface[] = [
    {
      filters: [
        { label: 'Valid with No Warning', name: 'showValidWithNoWarning' },
        { label: 'Valid with Warning', name: 'showValidWithWarning' },
        { label: 'Invalid with Error', name: 'showInvalidWithError' },
      ],
      filterValues: {
        showValidWithNoWarning: filters.showValidWithNoWarning,
        showValidWithWarning: filters.showValidWithWarning,
        showInvalidWithError: filters.showInvalidWithError,
      },
      fullSize: false,
      title: 'VALIDATION',
    },
  ];

  const handleClear = () => setFilters(initialFilters);

  return (
    <AdvancedFilter
      applyFilters={() => {
        applyFilters(
          {
            ...filters,
          },
          filtersActive
        );
      }}
      filtersActive={filtersActive}
      filterDefinitions={filterDefinitions}
      handleClear={handleClear}
      setFilters={(newFilters) =>
        setFilters({
          ...filters,
          ...newFilters,
        })
      }
      disableFilters={disableFilters}
    />
  );
};

export default ContractCustomerFilter;
