// Vendor
import { v4 } from 'uuid';
import { uniq } from 'lodash';

// Internal
import { ContractPricingRow, Product } from 'query';
import { formatCurrency, formatDate, truncateDatetime } from 'common/helpers';
import { Pricing } from 'types/generated/Pricing';
import {
  ContractProductPrice,
  MultiplePriceProduct,
  ContractFormProps,
} from 'pages/Contracts/Create/constants';
import {
  getInitialContractProductPrice,
  getPricingDateRange,
} from './ProductRow/SingleProductPricesRows/service';

/**
 * @description loop through every fetched 'Product[]', and return
 * either the 'MultiplePriceProduct' representation of the 'Product',
 * or 'MultiplePriceProduct' with pricings from fetched 'ContractPricingRow', if `fetchedContractPricings` was passed
 * @param formPush
 * @param values contract header data
 * @param products fetched products
 * @param fetchedContractPricings contracts initial products and pricings
 */
export const productsToMultiplePricesProducts = (
  formPush: (...args: any[]) => any,
  values: any,
  products?: Product[],
  fetchedContractPricings?: ContractPricingRow[]
): MultiplePriceProduct[] => {
  if (!products) return [];

  return products.map((product) => {
    // the variable below will store pricings for 'MultiplePriceProduct'
    let contractProductPricings: ContractProductPrice[] | undefined;

    // if form is in update mode (products with pricings were fetched)
    if (fetchedContractPricings?.length) {
      // use 'Product' data to find its pricing entity in fetched 'ContractPricingRow[]'
      const fetchedPricingEntity = findProductInFetchedContractPricings(
        product,
        fetchedContractPricings
      );

      // convert 'Pricing[]' into 'ContractProductPrice[]'
      contractProductPricings = convertPricingsToContractPricings(
        fetchedPricingEntity?.pricing
      );
    }

    // return fetched 'ContractPricingRow', converted into 'MultiplePriceProduct'
    return convertProductToMultiplePricesProduct(
      formPush,
      values,
      product,
      contractProductPricings
    );
  });
};

const findProductInFetchedContractPricings = (
  product: Product,
  fetchedContractProducts: ContractPricingRow[]
) =>
  fetchedContractProducts.find(
    (fetchedProduct) => product.external_id === fetchedProduct.externalId
  );

/**
 * @description converts pricings to contract pricings
 * @param pricings array of pricings or a single pricing
 * @returns array of contract pricings or a single contract pricing
 */
const convertPricingsToContractPricings = (
  pricings: Pricing[] | undefined
): ContractProductPrice[] | undefined => {
  if (pricings) {
    // if provided argument is an array
    return pricings.map((pricing) => ({
      id: v4(),
      startDate: pricing.startDate,
      endDate: pricing.endDate,
      contractPrice: pricing.contractPrice / 1_000_000,
      wac: pricing.wac / 1_000_000,
      unitOfMeasure: pricing.unitOfMeasure,
    }));
  } else {
    return undefined;
  }
};

// product from query
export const convertProductToMultiplePricesProduct = (
  formPush: (...args: any[]) => any,
  values: ContractFormProps,
  product: Product,
  pricing: ContractProductPrice[] | undefined
): MultiplePriceProduct => {
  const [pricingStartDate, pricingEndDate] = getPricingDateRange(
    values,
    product
  );

  const priceTypes = uniq(product.prices.map((price) => price.priceType));
  const defaultPriceType =
    priceTypes.find((priceType) => priceType === 'WAC') || priceTypes[0];

  const priceTypeValue = values.priceTypes?.find(
    (priceType) => priceType.externalId === product.external_id
  );
  if (!priceTypeValue) {
    formPush('priceTypes', {
      externalId: product.external_id,
      priceType: defaultPriceType,
    });
  }

  return {
    type: 'expanding',
    isExpandable: false,
    data: {
      id: product.id,
      externalId: product.external_id,
      idType: product.kind,
      description: product.description,
      relationships: product.relationships,
      unitOfMeasure: product.unit_of_measure,
      priceTypes: uniq(product.prices.map((price) => price.priceType)),
      priceValue: formatCurrency(product.prices[0].price),
      pricing: pricing || [
        getInitialContractProductPrice(
          truncateDatetime(pricingStartDate) || '',
          truncateDatetime(pricingEndDate) || ''
        ),
      ],
      productPrices: product.prices.map((p) => ({
        ...p,
        price: p.price / 1_000_000,
      })),
      rawPriceValue: product.prices[0].price,
      startDate: truncateDatetime(product.start_date),
      endDate: truncateDatetime(product.end_date),
      updatedAt: formatDate(new Date(product.timestamp).toISOString()),
    },
  };
};
