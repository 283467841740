// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { DialogContent } from '@material-ui/core';

// Internal
import { Button, Dialog } from 'components';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  deleteMapping: () => void;
  toggleDialog: (val: boolean) => void;
  tradingPartner: {
    names: string[];
  };
}

const DeleteMapping: FC<Props> = (props) => {
  const { deleteMapping, toggleDialog, tradingPartner } = props;

  return (
    <Dialog
      title='Remove mapped customer'
      toggleDialog={() => toggleDialog(false)}
    >
      <DialogContent>
        <div className={cx('title')}>
          Are you sure you want to remove <b>{tradingPartner.names[0]}</b>{' '}
          mapping?
        </div>
        <div className={cx('buttons')}>
          <Button
            color='secondary'
            dataTestId='noButton'
            label='No'
            onClick={() => {
              toggleDialog(false);
            }}
            style={{ width: 120 }}
            variant='outlined'
          />
          <Button
            dataTestId='yesButton'
            label='Yes'
            onClick={() => {
              deleteMapping();
              toggleDialog(false);
            }}
            style={{
              marginLeft: 8,
              width: 120,
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteMapping;
