// External
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { identity } from 'fp-ts/function';

// Internal
import {
  QUERY_CUSTOMER_LISTS,
  QUERY_CUSTOMER_LISTS_COUNT,
  QueryCustomersListCountResult,
  QueryCustomersListResult,
  wrapSearchText,
} from 'query';
import { TableRow } from 'components/Table';
import { IconSystemError } from 'styles/images';
import { Button, Pagination, Table } from 'components';
import SearchBar from 'components/SearchBar';
import {
  useAppBarSetTitle,
  useIsFirstRender,
  useTradingPartnerConfig,
} from 'hooks';
import { NUMBER_PER_PAGE } from 'common/constants';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import {
  CurrentPageParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { withMemberId } from 'services/queryService';
import { COLUMNS } from './constants';
import { filtersToQuery, rawDataToRows } from './service';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

const CustomerLists: FC = () => {
  const { id: memberId } = useTradingPartnerConfig() ?? { id: NaN };
  const navigate = useNavigate();
  const { currentSearch } = usePersistedSearchParams(
    {
      module: 'customer-lists',
      params: [SearchParamConfig, CurrentPageParamConfig],
    },
    SessionStorage
  );
  const [searchTerm, updateSearchTerm] = currentSearch[SearchParamConfig.key];
  const [currentPage, updateCurrentPage] =
    currentSearch[CurrentPageParamConfig.key];
  const [rows, updateRows] = useState([] as TableRow[]);
  const searchText = wrapSearchText(searchTerm);
  const [where, updateWhere] = useState(
    withMemberId(filtersToQuery(searchText), memberId)
  );

  useAppBarSetTitle('Customer Lists');

  const {
    data: customerLists,
    loading,
    error,
    refetch,
  } = useQuery<QueryCustomersListResult>(QUERY_CUSTOMER_LISTS, {
    variables: {
      where,
      order: { timestamp: 'desc' },
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
    },
  });
  const lists = customerLists?.lists || [];

  const {
    data: countData,
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<QueryCustomersListCountResult>(QUERY_CUSTOMER_LISTS_COUNT, {
    variables: {
      where,
    },
  });

  const refetchData = () => Promise.all([refetch(), refetchCount()]);

  useEffect(() => {
    refetchData().then(identity);
  }, []);

  useEffect(() => {
    if (!countLoading) {
      updateRows(rawDataToRows(customerLists));
    }
  }, [customerLists, countLoading]);

  const firstRender = useIsFirstRender();
  useEffect(() => {
    if (
      (firstRender && currentPage === 1) ||
      (!firstRender && currentPage > 1)
    ) {
      updateCurrentPage(1);
    }
    updateWhere(withMemberId(filtersToQuery(searchText), memberId));
  }, [searchText]);

  const hasLists = lists.length > 0;

  const isFiltered = searchText !== '%';
  const disableSearchFilter = !loading && !hasLists && !isFiltered;

  return !loading && error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('root')}>
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            test-id='search-bar'
            onSubmit={updateSearchTerm}
            placeholder='Search by ID, name, or description'
            text={searchTerm}
            disabled={disableSearchFilter}
          />
        </div>
        <Button
          dataTestId='createCustomerListBtn'
          label='Create Customer List'
          onClick={() => navigate('/customer-lists/create', { replace: true })}
          style={{
            marginRight: 8,
            minWidth: 172,
          }}
        />
      </section>
      <Table
        columns={COLUMNS}
        rows={rows}
        emptyText='No customer lists found'
        isLoading={countLoading}
      />
      <Pagination
        className={cx('pagination')}
        numberPerPage={NUMBER_PER_PAGE}
        currentPage={currentPage}
        loading={countLoading}
        isDisplayed={hasLists}
        totalCount={countData?.customerListsCount?.aggregate.count ?? -1}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
      />
    </div>
  );
};

export default CustomerLists;
