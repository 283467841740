// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { capitalize, concat, isEmpty, uniq } from 'lodash';
import { Chip } from '@material-ui/core';

// Internal
import {
  Proposal,
  useApi,
  useAppBarSetTitle,
  useShouldSendProposalToExtFlag,
  useSnackbar,
} from 'hooks';
import {
  GET_CUSTOMER_PROPOSAL,
  GET_TRADING_PARTNERS,
  QUERY_CUSTOMER_LISTS,
  QueryCustomersListResult,
  QueryTradingPartners,
  UPDATE_PROPOSAL_STATUS,
} from 'query';
import { Breadcrumb, Button, KeyValuePair, ViewSection } from 'components';
import { pluckId } from 'common/helpers';
import {
  formatProposalForCreateCustomer,
  getCustomerKeyValues,
  getSourceKeyValues,
} from './service';
import { formatImpact, getStatusChipColor, ProposalStatus } from '../service';
import { getTPNameLabelPairs } from '../../Contracts/Create/service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const APPROVE = 'APPROVE';
const REJECT = 'REJECT';

const ProposalViewContainer: FC = () => {
  const api = useApi();
  const snackbar = useSnackbar();
  const shouldSendProposalToExt = useShouldSendProposalToExtFlag();

  const { id, timestamp } = useParams() as { id: string; timestamp: string };

  const [processingUpdate, setProcessingUpdate] = useState<
    false | typeof REJECT | typeof APPROVE
  >(false);

  const { data, refetch } = useQuery(GET_CUSTOMER_PROPOSAL, {
    variables: {
      id,
      timestamp,
    },
  });

  const [updateProposal, { data: updateData }] = useMutation(
    UPDATE_PROPOSAL_STATUS
  );

  useEffect(() => {
    refetch().then(() => {
      setProcessingUpdate(false);
    });
  }, [updateData]);

  const proposal: Proposal = (data?.dash_customer_proposals ?? [])[0];

  const listIds = uniq(
    concat(proposal?.existing?.lists ?? [], proposal?.proposal?.lists ?? [])
      .map((list) => list?.listId)
      .filter((list) => list)
  );

  const { data: listData } = useQuery<QueryCustomersListResult>(
    QUERY_CUSTOMER_LISTS,
    {
      variables: {
        where: {
          member_id: { _eq: pluckId(proposal?.existing?.memberId) },
          id: { _in: listIds },
        },
      },
      skip: !listIds || listIds.length === 0,
    }
  );

  const allLists = listData?.lists ?? [];

  const [gpoList, setGpoList] = useState(
    [] as { name: string; label: string }[]
  );
  // Fetch trading partners
  const { data: tradingPartnersData, loading: tradingPartnersLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });
  const tradingPartners = tradingPartnersData?.tradingPartners ?? [];

  useEffect(() => {
    if (!tradingPartnersLoading && tradingPartners.length > 0) {
      // Convert trading partners to name/label pairs
      setGpoList([...getTPNameLabelPairs('gpo', tradingPartners)]);
    }
  }, [tradingPartnersData, tradingPartnersLoading]);

  useAppBarSetTitle(id);

  if (isEmpty(proposal) || tradingPartnersLoading) return null;

  const { existing, impact, proposal: p, triggerSource } = proposal;
  const sourceKeyValues = existing
    ? getSourceKeyValues(existing, triggerSource)
    : [];
  const currentKeyValues = existing
    ? getCustomerKeyValues(allLists, gpoList, existing, impact, p)
    : [];
  const proposedKeyValues = getCustomerKeyValues(allLists, gpoList, p, []);

  return (
    <div className={cx('root')}>
      <Breadcrumb
        crumbs={['Customer Proposals', id]}
        links={['/customer-proposals']}
      />
      <div className={cx('row', 'toolbar')}>
        <div className={cx('row', 'left')}>
          <div className={cx('column')}>
            <span className={cx('toolbarTitle')}>Status</span>
            <Chip
              label={capitalize(proposal.status)}
              style={{
                backgroundColor: getStatusChipColor(proposal.status),
                color: '#fff',
                height: 20,
                fontFamily: 'Graphik',
                fontSize: 12,
                width: 90,
              }}
              data-testid='chipCellChip'
            />
          </div>
          <div className={cx('column')}>
            <span className={cx('toolbarTitle')}>Impact</span>
            {formatImpact(impact)}
          </div>
        </div>
        {proposal.status === 'PENDING' && (
          <div className={cx('row')}>
            <Button
              color='secondary'
              dataTestId='rejectButton'
              disabled={!!processingUpdate}
              label='Reject'
              loading={processingUpdate === REJECT}
              onClick={() => {
                setProcessingUpdate(REJECT);
                updateProposal({
                  variables: {
                    id,
                    status: ProposalStatus.REJECTED,
                    timestamp,
                  },
                });
                snackbar.open({
                  message: 'Customer Proposal Rejected.',
                  variant: 'error',
                });
              }}
              style={{
                minHeight: 40,
                width: 109,
              }}
              variant='outlined'
            />
            <Button
              dataTestId='approveButton'
              disabled={!!processingUpdate}
              label='Approve'
              loading={processingUpdate === APPROVE}
              onClick={() => {
                setProcessingUpdate(APPROVE);
                const promise = shouldSendProposalToExt
                  ? api?.shareProposal(proposal)
                  : api?.createCustomer(
                      formatProposalForCreateCustomer(proposal)
                    );
                promise?.then(() => {
                  updateProposal({
                    variables: {
                      id,
                      status: ProposalStatus.APPROVED,
                      timestamp,
                    },
                  });
                  snackbar.open({
                    message: 'Customer Proposal Approved.',
                    variant: 'success',
                  });
                });
              }}
              style={{
                marginLeft: 32,
                minHeight: 40,
                width: 109,
              }}
            />
          </div>
        )}
      </div>
      <ViewSection
        dataTestid='sourceData'
        disableCollapse
        emptySection={false}
        header='Source data'
      >
        <div className={cx('content')}>
          {sourceKeyValues.map(({ title = '', value = '' }) => (
            <KeyValuePair key={title} title={title} value={value} />
          ))}
        </div>
      </ViewSection>
      <ViewSection
        dataTestid='currentCustomer'
        disableCollapse
        emptySection={false}
        header='Current customer'
      >
        <div className={cx('content')}>
          {currentKeyValues.map(
            ({ title = '', titleIcon = undefined, value = '' }) => (
              <KeyValuePair
                key={title}
                title={title}
                titleIcon={titleIcon}
                value={value}
              />
            )
          )}
        </div>
      </ViewSection>
      <ViewSection
        dataTestid='proposedCustomer'
        disableCollapse
        emptySection={false}
        header='Proposed customer'
      >
        <div className={cx('content')}>
          {proposedKeyValues.map(({ title = '', value = '' }) => (
            <KeyValuePair key={title} title={title} value={value} />
          ))}
        </div>
      </ViewSection>
    </div>
  );
};

export default ProposalViewContainer;
