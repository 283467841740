import * as t from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { optional, strict } from 'io-ts-extra';

const ApiConfig = strict(
  {
    endpoint: optional(t.string),
  },
  'ApiConfig'
);

// eslint-disable-next-line no-redeclare
export type ApiConfig = t.TypeOf<typeof ApiConfig>;

const AuthConfig = strict(
  {
    anonymous: t.boolean,
    'clock-interval-ms': optional(t.number),
    'exp-tolerance-ms': optional(t.number),
    mode: t.string,
    'secure-cookies': optional(t.boolean),
  },
  'AuthConfig'
);

export enum AuthorityDatabases {
  '340b' = '340b',
  dea = 'dea',
  hin = 'hin',
}

export const AuthorityDatabasesV = t.keyof({
  [AuthorityDatabases['340b']]: null,
  [AuthorityDatabases.dea]: null,
  [AuthorityDatabases.hin]: null,
});

// eslint-disable-next-line no-redeclare
export type AuthConfig = t.TypeOf<typeof AuthConfig>;

const DocumentTypeAttribute = strict(
  {
    defaultValue: optional(t.unknown), // TODO: Should be optional?
    fieldName: t.string,
    isRequired: t.boolean,
    name: t.string,
    type: t.string,
  },
  'DocumentTypeAttribute'
);

// eslint-disable-next-line no-redeclare
export type DocumentTypeAttribute = t.TypeOf<typeof DocumentTypeAttribute>;

const DocumentType = strict(
  {
    attributes: t.array(DocumentTypeAttribute),
    type: t.string,
  },
  'DocumentType'
);

const PriceType = strict(
  {
    name: t.string,
    type: t.string,
  },
  'PriceType'
);

const UnitOfMeasureType = strict(
  {
    name: t.string,
    type: t.string,
  },
  'UnitOfMeasureType'
);

const ProductTypeType = strict(
  {
    name: t.string,
    type: t.string,
  },
  'ProductTypeType'
);

// eslint-disable-next-line no-redeclare
export type DocumentType = t.TypeOf<typeof DocumentType>;
// eslint-disable-next-line no-redeclare
export type PriceType = t.TypeOf<typeof PriceType>;
// eslint-disable-next-line no-redeclare
export type UnitOfMeasureType = t.TypeOf<typeof UnitOfMeasureType>;
// eslint-disable-next-line no-redeclare
export type ProductTypeType = t.TypeOf<typeof ProductTypeType>;

const Attribute = strict({ name: t.string, type: t.string }, 'Attribute');

// eslint-disable-next-line no-redeclare
export type Attribute = t.TypeOf<typeof Attribute>;

const CustomerConfig = strict(
  {
    attributes: optional(t.array(Attribute)),
    documentTypes: optional(t.array(DocumentType)),
  },
  'CustomerConfig'
);

const ProductConfig = strict(
  {
    priceTypes: optional(t.array(PriceType)),
    unitsOfMeasure: optional(t.array(UnitOfMeasureType)),
    productTypes: optional(t.array(ProductTypeType)),
  },
  'ProductConfig'
);

// eslint-disable-next-line no-redeclare
export type CustomerConfig = t.TypeOf<typeof CustomerConfig>;
// eslint-disable-next-line no-redeclare
export type ProductConfig = t.TypeOf<typeof ProductConfig>;

const CustomerMgmtConfig = strict(
  {
    'required-fields': t.array(t.string),
  },
  'CustomerMgmtConfig'
);

// eslint-disable-next-line no-redeclare
export type CustomerMgmtConfig = t.TypeOf<typeof CustomerMgmtConfig>;

const FeatureFlagsConfig = strict(
  {
    'acceptable-authority-databases': optional(t.array(AuthorityDatabasesV)),
    chargebacks: optional(t.boolean),
    'credit-memos': optional(t.boolean),
    'contracts-module': optional(t.boolean),
    'contract-management': optional(t.boolean),
    'csv-upload': optional(t.boolean),
    'customer-management': optional(t.boolean),
    'customers-module': optional(t.boolean),
    'customer-lists-module': optional(t.boolean),
    'dea-validation-only-mode': optional(t.boolean),
    'displayable-trading-partner-types': optional(t.array(t.string)),
    'manual-mapping': optional(t.boolean),
    products: optional(t.boolean),
    'product-lists': optional(t.boolean),
    proposals: optional(t.boolean),
    roster: optional(t.boolean),
    'roster-trading-partner-types': optional(t.array(t.string)),
    'simplified-contracts': optional(t.boolean),
    'use-latest-customers-view': optional(t.boolean),
    'contract-details-export': optional(t.boolean),
    'flatfile-local-customers-import': optional(t.boolean),
    'flatfile-oon-customers-import': optional(t.boolean),
    'should-send-proposal-to-ext': optional(t.boolean),
    'flatfile-contract-header-import': optional(t.boolean),
    'flatfile-contract-pricing-import': optional(t.boolean),
  },
  'FeatureFlagsConfig'
);

// eslint-disable-next-line no-redeclare
export type FeatureFlagsConfig = t.TypeOf<typeof FeatureFlagsConfig>;

export enum NodeType {
  DIST = 'DIST',
  GPO = 'GPO',
  HS = 'HS',
  MFR = 'MFR',
  NETMAN = 'NETMAN',
}

const NodeTypeV = t.keyof(
  {
    [NodeType.DIST]: null,
    [NodeType.GPO]: null,
    [NodeType.HS]: null,
    [NodeType.MFR]: null,
    [NodeType.NETMAN]: null,
  },
  'NodeType'
);

const TradingPartnerConfig = strict(
  {
    id: t.number,
    name: t.string,
    type: NodeTypeV,
  },
  'TradingPartnerConfig'
);

// eslint-disable-next-line no-redeclare
export type TradingPartnerConfig = t.TypeOf<typeof TradingPartnerConfig>;

export const DashboardConfigTypeValidator = strict(
  {
    api: optional(ApiConfig),
    auth: AuthConfig,
    customer: CustomerConfig,
    'customer-management': optional(CustomerMgmtConfig),
    disabledGpos: optional(t.array(t.string)),
    'enable-browser-warnings': t.boolean,
    'feature-flags': FeatureFlagsConfig,
    'trading-partner': TradingPartnerConfig,
    product: optional(ProductConfig),
    'roster-attribute-columns': optional(t.array(t.string)),
  },
  'DashboardConfig'
);

export type DashboardConfig = t.TypeOf<typeof DashboardConfigTypeValidator>;

interface ConfigRequestError {
  kind: 'config-request-error';
  msg: string;
}

interface ConfigValidationError {
  kind: 'config-validation-error';
  reasons: string[];
  msg: string;
}

export type ConfigError = ConfigRequestError | ConfigValidationError;

export type ConfigState = E.Either<ConfigError, DashboardConfig>;

// Validate the Configuration downloaded and generate the App State
export const generateConfigStateFrom = (config: unknown): ConfigState => {
  const validationResult = DashboardConfigTypeValidator.decode(config);
  const report = PathReporter.report(validationResult);
  return pipe(
    validationResult,
    E.map((cfg) => cfg as DashboardConfig),
    E.mapLeft(() => ({
      kind: 'config-validation-error',
      reasons: report,
      msg: 'Config file validation failed.',
    }))
  );
};

export const getConfigErrorMessage = (cfgError: ConfigError): string => {
  switch (cfgError.kind) {
    case 'config-validation-error':
      return `
        ${cfgError.msg}
        Reasons:
        ${cfgError.reasons.join('\n')}
      `
        .trim()
        .replace(/^[\t|\s]+/gm, '');
    case 'config-request-error':
      return cfgError.msg || 'Config validation file request failed.';
    default:
      return '';
  }
};
