// Vendor
import { pipe } from 'fp-ts/lib/function';
import { chain, none, some, fromNullable, getOrElse } from 'fp-ts/Option';

// Internal
import { Customer } from 'types/generated/Customer';
import {
  OON_CUSTOMER_BASE_FIELDS,
  OonCustomerRosterSchema,
} from '../customer-roster';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  parseMediledgerId,
} from '../../../../common/helpers';
import { CustomerRosterInputData } from '../customer-roster-adapter';
import {
  extractAttributes,
  extractAttributesFromCustomColumns,
  prepareDate,
  processIdentifiers,
} from '../../adapter-utils';
import { RosterAdapter } from '../../roster-adapter';
import { CUSTOMER_DEFAULT_IDENTIFIER_DELIMITERS } from '../../../constants';

class OonRosterAdapter
  implements
    RosterAdapter<OonCustomerRosterSchema, CustomerRosterInputData, Customer>
{
  convert = (
    row: OonCustomerRosterSchema,
    data: CustomerRosterInputData
  ): Customer => {
    const {
      id,
      address1,
      city,
      state,
      zipCode,
      address2,
      cot,
      startDate,
      endDate,
      dea,
      hin,
      name,
      name2,
      address3,
      parentId,
    } = row;
    const { rosterId, memberId, timestamp, identifiersDelimiters } = data;
    const processedStartDate = prepareDate(
      startDate,
      convertStartDateToUtcTimestamp
    );
    const processedEndDate = prepareDate(endDate, convertEndDateToUtcTimestamp);
    const { memberId: rawMemberId } = parseMediledgerId(memberId);
    const mappedId = `${rosterId}_${id}`;
    const baseIdentifier = `ml:customer:${rawMemberId}:${mappedId}`;
    const baseListId = `${rosterId}_${rosterId}`;

    return {
      id: mappedId,
      memberId,
      timestamp,
      addresses:
        address1 && city && state && zipCode
          ? [
              {
                address1,
                city,
                state,
                zipCode,
                address2,
                addressType: 'primary',
                address3,
                startDate: processedStartDate,
                endDate: processedEndDate,
              },
            ]
          : undefined,
      classesOfTrade: cot
        ? [
            {
              classOfTradeId: `${rosterId}_${cot}`,
              startDate: processedStartDate,
              endDate: processedEndDate,
            },
          ]
        : undefined,
      startDate: processedStartDate,
      endDate: processedEndDate,
      identifiers: processIdentifiers(
        { dea, hin, _340b: row['340b'] },
        identifiersDelimiters ?? CUSTOMER_DEFAULT_IDENTIFIER_DELIMITERS
      ).concat([baseIdentifier]),
      names: [name].concat(name2 ? [name2] : []),
      attributes: extractAttributes(row, OON_CUSTOMER_BASE_FIELDS).concat(
        extractAttributesFromCustomColumns(row)
      ),
      parentId: pipe(
        fromNullable(parentId),
        chain((pId) => (pId === id ? none : some(`${rosterId}_${pId}`))),
        getOrElse<string | undefined>(() => undefined)
      ),
      lists: [
        {
          listId: baseListId,
          startDate: processedStartDate,
          endDate: processedEndDate,
        },
      ],
    };
  };
}

export default new OonRosterAdapter();
