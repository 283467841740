// Vendor
import React, { FC } from 'react';
import { omit } from 'lodash';
import { Form } from 'react-final-form';

// Internal
import { Document } from 'types/generated/Document';
import EditDocument from './EditDocument';
import { CustomerWithDocuments } from '../types';

interface Props {
  changeSection: (section: string) => void;
  customer: CustomerWithDocuments;
  memberId: number;
  selectedDocument: Document;
}

const Container: FC<Props> = (props) => {
  const { changeSection, customer, memberId, selectedDocument } = props;

  return (
    <Form
      onSubmit={() => {}}
      initialValues={omit(selectedDocument.metadata, ['manufacturer'])}
      render={({ errors, form, handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <EditDocument
            change={form.change}
            changeSection={changeSection}
            customer={customer}
            errors={errors}
            memberId={memberId}
            selectedDocument={selectedDocument}
            values={values}
          />
        </form>
      )}
    />
  );
};

export default Container;
