// Vendor
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import React, { FC } from 'react';

// Internal
import { ActionModal } from 'components';
import { useApi, useSnackbar } from 'hooks';
import { TradingPartnerEntity } from 'query';

interface Props {
  canBeActivated: boolean;
  partner: TradingPartnerEntity;
  toggleModal: (val: boolean) => void;
  onLoadingChange: (loading: boolean) => void;
}

const Container: FC<Props> = (props) => {
  dayjs.extend(utc);

  const { canBeActivated, partner, toggleModal, onLoadingChange } = props;
  const { id, name } = partner;
  const api = useApi();
  const snackbar = useSnackbar();

  const confirmationClickHandler = (value: boolean): void => {
    if (!value) {
      toggleModal(false);
    } else {
      if (canBeActivated) activate();
      else deactivate();
      toggleModal(false);
    }
  };

  const activate = () => {
    onLoadingChange(true);
    const tpDTO = {
      memberId: `ml:member:${id}`,
      timestamp: dayjs().utc().toISOString(),
    };
    api
      ?.tradingPartnerAuthorize(tpDTO)
      .then(() => {
        snackbar.open({
          message: `${name} has been authorized.`,
          variant: 'success',
        });
      })
      .catch(() => {
        snackbar.open({
          message: `An error occurred trying to authorize ${name}`,
          variant: 'error',
        });
      })
      .finally(() => onLoadingChange(false));
  };

  const deactivate = () => {
    onLoadingChange(true);
    const dto = {
      memberId: `ml:member:${id}`,
      timestamp: dayjs().utc().toISOString(),
    };

    api
      ?.tradingPartnerDeauthorize(dto)
      .then(() => {
        snackbar.open({
          message: `${name} has been deauthorized.`,
          variant: 'success',
        });
      })
      .finally(() => onLoadingChange(false));
  };

  return (
    <ActionModal
      title={`Do you want to ${
        canBeActivated ? '' : 'de'
      }authorize ${name} as a trading partner?`}
      bodyText={`Any future data will ${
        canBeActivated ? '' : 'not '
      }be shared to them after your confirmation.`}
      toggleModal={toggleModal}
      onConfirmationClick={confirmationClickHandler}
    />
  );
};

export default Container;
