// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';
import { get } from 'lodash';

// Internal
import { Checkbox } from 'components';
import { useDocumentTypes } from 'hooks';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  disabled?: boolean;
  values: {
    manufacturer: string;
  };
}

const Documents: FC<Props> = (props) => {
  const { disabled, values } = props;
  const documentTypes = useDocumentTypes();

  const { manufacturer } = values;
  const types = documentTypes.filter(({ attributes }) =>
    attributes.some(
      // @ts-ignore
      ({ defaultValue }: { defaultValue: string }) =>
        defaultValue === manufacturer
    )
  );

  const inputText = `Now, which documents from ${manufacturer} do you want to add?`;
  const reviewText = `Documents added to ${manufacturer}`;

  return (
    <div className={cx('root')}>
      <span className={cx('title')}>{disabled ? reviewText : inputText}</span>
      <section className={cx('editSection')}>
        <div className={cx('field')}>
          {
            // @ts-ignore
            types.map(({ type }) => {
              const formValue = get(values, `documentType.${type}`, false);
              return (
                !(disabled && !formValue) && (
                  <Field
                    key={type}
                    checked={formValue}
                    // @ts-ignore
                    component={Checkbox}
                    disabled={disabled}
                    label={type}
                    name={`documentType.${type}`}
                    style={{ marginTop: 20 }}
                    type='checkbox'
                  />
                )
              );
            })
          }
        </div>
      </section>
    </div>
  );
};

export default Documents;
