// Internal
import { dateRangesOverlap } from 'common/validation';
import { ContractProductPrice } from 'pages/Contracts/Create/constants';
import { ProductPrice } from 'types/generated/Product';

export type PricingErrors = {
  [pricingId: string]: string;
};

/**
 * @description validates contract or product pricings and returns overlapping pricings ids and thier errors
 * @param pricings array of contract or product pricings
 * @returns indexes of overlapping pricings and their error message
 */
export const validatePricingsOverlapping = (
  pricings: ContractProductPrice[] | ProductPrice[]
): PricingErrors => {
  const pricingsOverlapErrorObject: PricingErrors = {};

  // Loop through every pricing
  pricings.forEach((currentPricing, currentIndex) => {
    const currentPricingDateRange = {
      startDate: currentPricing.startDate || '',
      endDate: currentPricing.endDate || '',
    };

    if (!currentPricingDateRange.startDate || !currentPricingDateRange.endDate)
      return;

    // Go through each pricing and compare its date range with current pricings date range
    // eslint-disable-next-line consistent-return
    pricings.forEach((pricing, i) => {
      // Exclude current pricing
      if (currentIndex === i) return;

      const pricingDateRange = {
        startDate: pricing.startDate || '',
        endDate: pricing.endDate || '',
      };

      // eslint-disable-next-line consistent-return
      if (!pricingDateRange.startDate || !pricingDateRange.endDate) return;

      const errorMessage = dateRangesOverlap(currentPricingDateRange)(
        pricingDateRange
      );

      if (errorMessage) {
        // if validation failed, push indexes of both failed pricings and their error messages to 'pricingsOverlapErrorObject',
        pricingsOverlapErrorObject[currentIndex] = errorMessage;
        pricingsOverlapErrorObject[i] = errorMessage;
      }
    });
  });

  return pricingsOverlapErrorObject;
};
