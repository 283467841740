import { Eq, fromEquals } from 'fp-ts/Eq';

export const getUnorderedArrayEq = <A>(eqElement: Eq<A>): Eq<A[]> =>
  fromEquals(
    (x: A[], y: A[]) =>
      x.length === y.length &&
      x.every((x1) => y.some((y1) => eqElement.equals(x1, y1)))
  );

export const getOptionalEq = <A>(eqElement: Eq<A>): Eq<A | undefined> =>
  fromEquals((x, y) =>
    x === undefined || y === undefined ? x === y : eqElement.equals(x, y)
  );

export const eqAny = fromEquals<any>((e1: any, e2: any) => e1 === e2);
