// Constants

export enum TradingPartnerStatus {
  ACTIVE = 'authorize',
  CONFIRMED = 'confirmed',
  INACTIVE = 'deauthorize',
  PENDING = 'pending',
}

export const canBeActivated = (status: string | undefined): boolean =>
  status === TradingPartnerStatus.CONFIRMED ||
  status === TradingPartnerStatus.INACTIVE;
export const cabBeDeactivated = (status: string | undefined): boolean =>
  status === TradingPartnerStatus.ACTIVE;

export const getBackgroundColor = (status: string | undefined): string => {
  const colorsMap: Record<string, string> = {
    [TradingPartnerStatus.ACTIVE]: '#00BC96',
    [TradingPartnerStatus.PENDING]: '#F9BC60',
    [TradingPartnerStatus.CONFIRMED]: '#F9BC60',
    [TradingPartnerStatus.INACTIVE]: '#1482FC',
  };

  return status
    ? colorsMap[status] || colorsMap[TradingPartnerStatus.INACTIVE]
    : colorsMap[TradingPartnerStatus.INACTIVE];
};

export const getMemberType = (type: string | undefined): string => {
  const memberTypesMap: Record<string, string> = {
    mfr: 'Manufacturer',
    dist: 'Distributor',
    gpo: 'GPO',
  };

  return type ? memberTypesMap[type] || '' : '';
};

export const getStatusText = (status: string | undefined): string => {
  const invalidStatus = 'Invalid Status';
  const statusMap: Record<string, string> = {
    [TradingPartnerStatus.ACTIVE]: 'Authorized',
    [TradingPartnerStatus.PENDING]: 'Pending Registration',
    [TradingPartnerStatus.CONFIRMED]: 'Awaiting Authorization',
    [TradingPartnerStatus.INACTIVE]: 'Deauthorized',
  };

  return status ? statusMap[status] || invalidStatus : invalidStatus;
};
