// Vendor
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import arrayMutators from 'final-form-arrays';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Form, FormSpy } from 'react-final-form';
import { ValidationErrors } from 'final-form';

// Internal
import MultiPageForm from 'components/MultipageForm';
import { Breadcrumb } from 'components/index';
import { IconCustomers, IconList, IconProduct } from 'styles/images';
import {
  GET_CONTRACT_EVENTS_BY_HOUR,
  GET_TRADING_PARTNERS,
  QUERY_ASSOCIATIONS_COUNT,
  QueryAssociationsCountResult,
  QueryTradingPartners,
} from 'query';
import {
  useApi,
  useAppBarSetTitle,
  useIsFirstRender,
  useSnackbar,
  useTradingPartnerConfig,
} from 'hooks';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import { contractPath } from 'common/helpers';
import { isEmpty } from 'lodash';
import { ContractMode, ContractFormProps, emptyFormObject } from './constants';
import Overview from './components/Overview';
import SelectProductList from './components/ProductLists/SelectProductList/container';
import SetPricing from './components/ProductLists/SetPricing/container';
import CustomerList from './components/CustomerList/container';
import {
  calculateDisabledButtons,
  formValidation,
  getTPNameLabelPairs,
  onCancel,
  onSubmit,
  pricingTabDisabledCallback,
} from './service';
import { toMediledgerProductListId } from '../../../common/helpers/mlIdUtils';
import { getProductsForListWhere } from './components/ProductLists/SelectProductList/service';

interface Props {
  contractModeType?: ContractMode;
  fetchedData?: ContractFormProps;
}

const CreateEditContract: FC<Props> = (props) => {
  const { contractModeType = 'create', fetchedData = emptyFormObject } = props;

  const api = useApi();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { id: memberId } = useTradingPartnerConfig() ?? { id: NaN };
  const [params] = useSearchParams();

  const formRef = useRef<ContractFormProps>(emptyFormObject);
  const formErrorsRef = useRef<ValidationErrors>();

  const [buttonsLoading, setButtonsLoading] = useState({
    submit: false,
    next: true,
  });

  const firstRender = useIsFirstRender();
  const initialValues = useMemo(
    () => (firstRender ? fetchedData : formRef.current),
    [fetchedData]
  );

  // Set to true after create/update, so we can start querying for the contract
  // to view details pg
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Set contract id here is needed because we generate a new id on NEW contract
  // creation and we need to query by the id to visit the details pg afterwards
  const [contractId, setContractId] = useState(fetchedData.contractId);

  const [entitiesList, setEntitiesList] = useState(
    [] as { name: string; label: string }[]
  );
  const [distList, setDistList] = useState(
    [] as { name: string; label: string }[]
  );
  const [formValues, setFormValues] = useState<ContractFormProps | undefined>(
    undefined
  );

  const initialStep = Number.parseInt(params.get('initialStep') || '', 10) || 1;

  const breadCrumbText =
    contractModeType === 'create'
      ? 'Create Contract'
      : `Update Contract ${contractId}`;

  useAppBarSetTitle(breadCrumbText);

  // Fetch trading partners
  const { data: tradingPartnersData, loading: tradingPartnersLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });
  const tradingPartners = tradingPartnersData?.tradingPartners ?? [];

  useEffect(() => {
    setContractId(formRef.current.contractId);
  }, [formRef.current.contractId]);

  useEffect(() => {
    if (!tradingPartnersLoading && tradingPartners.length > 0) {
      // Convert trading partners to name/label pairs
      setEntitiesList([
        ...getTPNameLabelPairs('gpo', tradingPartners),
        ...getTPNameLabelPairs('health-system', tradingPartners),
      ]);
      setDistList(getTPNameLabelPairs('dist', tradingPartners));
    }
    setButtonsLoading({ ...buttonsLoading, next: tradingPartnersLoading });
  }, [tradingPartnersData, tradingPartnersLoading]);

  const [activeStep, setActiveStep] = useState(initialStep - 1);
  const [activeSubStep, setActiveSubStep] = useState(0);

  const selectedProductListsIDs: string[] =
    formValues?.productLists
      ?.filter((list: any) => list.checked)
      .map((list: any) => toMediledgerProductListId(list.id, memberId)) || [];

  const {
    data: countData,
    loading: countLoading,
    refetch: countRefetch,
  } = useQuery<QueryAssociationsCountResult>(QUERY_ASSOCIATIONS_COUNT, {
    variables: {
      where: getProductsForListWhere(
        selectedProductListsIDs,
        formValues?.startDate ?? '',
        formValues?.endDate ?? ''
      ),
    },
    skip:
      !formValues ||
      !formValues?.startDate ||
      !formValues?.endDate ||
      !memberId,
  });

  const totalProductsCount = useMemo(
    () => countData?.associations?.aggregate?.count || 0,
    [countData]
  );

  // Effect to setButtonsLoading while still loading the total amount of associations
  useEffect(() => {
    setButtonsLoading((currentState) => ({
      ...currentState,
      next: countLoading,
      submit: countLoading,
    }));
  }, [countLoading]);

  // We query for the updated/created contract
  const { client } = useQuery(GET_CONTRACT_EVENTS_BY_HOUR, {
    variables: {
      memberId,
      contractId,
    },
    skip: !(isFormSubmitted && memberId),
    pollInterval: SHORT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ dash_get_contract_events_by_hour }) => {
      // This callback will check if the contract was created
      const contractIdExists =
        dash_get_contract_events_by_hour &&
        dash_get_contract_events_by_hour[0]?.hour;
      if (contractIdExists) {
        const tab = contractModeType === 'create' ? 1 : activeStep + 1;

        navigate(`${contractPath(memberId, contractId)}?initialTab=${tab}`, {
          replace: true,
        });

        const verbInMessage =
          contractModeType === 'create' ? 'created' : 'updated';
        snackbar.open({
          message: `Contract ${contractId} has been ${verbInMessage}.`,
          variant: 'success',
        });
      }
    },
  });

  // When user click [Create]/[Update] button
  const onPreSubmit = async () => {
    try {
      if (contractModeType === 'create' && contractId.length !== 0) {
        // If user has typed contract id manually, enable checking if that id exist
        const { data } = await client.query({
          query: GET_CONTRACT_EVENTS_BY_HOUR,
          variables: { memberId, contractId },
        });
        const contractIdExists =
          data.dash_get_contract_events_by_hour &&
          data.dash_get_contract_events_by_hour[0]?.hour;

        if (contractIdExists) {
          snackbar.open({
            message: `Contract with id ${contractId} already exist. Please use another id.`,
            variant: 'error',
          });
        } else {
          await onSubmit(
            api,
            buttonsLoading,
            fetchedData,
            formRef.current,
            memberId,
            setButtonsLoading,
            setContractId,
            setIsFormSubmitted
          );
        }
      } else {
        await onSubmit(
          api,
          buttonsLoading,
          initialValues,
          formRef.current,
          memberId,
          setButtonsLoading,
          setContractId,
          setIsFormSubmitted
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`An error occurred when persisting contract: ${e}`);
    }
  };

  return (
    <>
      <Breadcrumb
        crumbs={['Contracts', breadCrumbText]}
        links={['/contracts']}
      />
      <Form
        data-testid='contract-form'
        initialValues={initialValues}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={onPreSubmit}
        validate={(values) => formValidation(values, totalProductsCount)}
        render={({ errors, form, handleSubmit, values }) => {
          formRef.current = values;
          formErrorsRef.current = errors;

          const buttonsStatus = calculateDisabledButtons(
            errors,
            values,
            fetchedData,
            activeStep,
            activeSubStep,
            buttonsLoading
          );

          const noProductErrors = { ...errors };
          delete noProductErrors.products;
          delete noProductErrors.productError;
          delete noProductErrors.missingPricingsToFill;

          return (
            <form onSubmit={handleSubmit}>
              <MultiPageForm
                activeStep={activeStep}
                activeSubStep={activeSubStep}
                buttonsDisabled={buttonsStatus}
                buttonsLoading={{
                  ...buttonsLoading,
                  back: false,
                  cancel: false,
                }}
                isStepperDisabled={
                  contractModeType === 'create' ||
                  (activeStep === 0 && !isEmpty(noProductErrors))
                }
                isUpdateMode={contractModeType === 'update'}
                onCancel={() =>
                  onCancel(
                    activeStep,
                    contractId,
                    contractModeType,
                    memberId,
                    navigate,
                    setActiveStep
                  )
                }
                setActiveStep={setActiveStep}
                setActiveSubStep={setActiveSubStep}
                stepperElements={[
                  {
                    icon: <IconList />,
                    label: 'Overview',
                  },
                  {
                    error:
                      !!errors?.productError || !!errors?.missingPricingsToFill,
                    icon: <IconProduct />,
                    label: 'Product Lists',
                  },
                  {
                    icon: <IconCustomers />,
                    label: 'Customer Lists',
                  },
                ]}
                stepsData={[
                  {
                    views: [
                      <Overview
                        change={form.change}
                        distList={distList}
                        formMode={contractModeType}
                        formUpdate={form.mutators.update}
                        entitiesList={entitiesList}
                        key='OverviewViewWrapper'
                        oldSelectedDistributors={fetchedData.distributors || []}
                        values={values}
                        initialValues={initialValues}
                      />,
                    ],
                  },
                  {
                    tabs: [
                      {
                        disabled: false,
                        label: 'Select Product List',
                      },
                      {
                        disabled: pricingTabDisabledCallback(
                          values.productLists
                        ),
                        label: 'Set Pricing',
                        loading: buttonsLoading.next,
                      },
                    ],
                    views: [
                      <SelectProductList
                        formRemoveBatch={form.mutators.removeBatch}
                        formUpdate={form.mutators.update}
                        initialValues={initialValues}
                        setButtonsLoading={setButtonsLoading}
                        values={values}
                      />,
                      <SetPricing
                        totalProductsCountInfo={{
                          count: totalProductsCount,
                          loading: countLoading,
                          refetch: countRefetch,
                        }}
                        change={form.change}
                        formRemoveBatch={form.mutators.removeBatch}
                        formRemove={form.mutators.remove}
                        formPush={form.mutators.push}
                        formUpdate={form.mutators.update}
                        initialValues={initialValues}
                        values={values}
                      />,
                    ],
                  },
                  {
                    views: [
                      <CustomerList
                        buttonsLoading={buttonsLoading}
                        change={form.change}
                        setButtonsLoading={setButtonsLoading}
                        values={values}
                        memberId={memberId}
                      />,
                    ],
                  },
                ]}
                submitText={
                  contractModeType === 'create'
                    ? 'Create Contract'
                    : 'Update Contract'
                }
              />
              <FormSpy
                subscription={{ values: true }}
                onChange={(props) =>
                  // eslint-disable-next-line react/prop-types
                  setFormValues(props.values as ContractFormProps)
                }
              />
            </form>
          );
        }}
      />
    </>
  );
};

export default CreateEditContract;
