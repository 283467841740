// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { first, get, uniqBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';

// Internal
import {
  CustomerDetails,
  CustomerHistoryTimelineEntry,
  GET_TRADING_PARTNERS,
  QUERY_CUSTOMER_HISTORY,
  QueryCustomerDetailsResult,
  QueryCustomerHistoryResult,
  QueryTradingPartners,
} from 'query';
import { IconSystemError } from 'styles/images';
import { POLL_INTERVAL } from 'common/constants';
import { getSearchParam, parseMediledgerId } from 'common/helpers';
import { useTradingPartnerConfig } from 'hooks';
import Details from './components/Details';
import { getDetailsQuery } from './service';
import { getTPNameLabelPairs } from '../../Contracts/Create/service';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  isLocalCustomer?: boolean;
}

const Container: FC<Props> = (props) => {
  const { isLocalCustomer = false } = props;

  const { search } = useLocation() as { search: string };

  const { id } = useParams() as { id: string };

  let memberId: string | number | undefined;

  // FOR LOCAL CUSTOMER
  const { id: tpId } = useTradingPartnerConfig() ?? {};

  // FOR ROSTER CUSTOMER
  const { memberId: memberIdParams } = useParams<{
    memberId: string;
  }>();

  if (isLocalCustomer) {
    memberId = tpId;
  } else {
    memberId = memberIdParams;
  }

  const isHistoryView = !!getSearchParam(search, 'historyView');
  const urlTimestamp = getSearchParam(search, 'update');
  const urlMappingTimestamp = getSearchParam(search, 'mappingTimestamp');

  const mappingsWhere = isLocalCustomer
    ? { from_id: { _eq: `ml:customer:${memberId}:${id}` } }
    : { to_id: { _eq: `ml:customer:${memberId}:${id}` } };

  const {
    data: customerUpdatesData = {},
    loading: historyLoading,
    refetch: historyRefetch,
  } = useQuery<QueryCustomerHistoryResult>(QUERY_CUSTOMER_HISTORY, {
    variables: {
      id,
      memberId,
      mappingsWhere,
    },
    pollInterval: POLL_INTERVAL,
    skip: !id || !memberId,
  });

  const [gpoList, setGpoList] = useState(
    [] as { name: string; label: string }[]
  );
  // Fetch trading partners
  const { data: tradingPartnersData, loading: tradingPartnersLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });
  const tradingPartners = tradingPartnersData?.tradingPartners ?? [];

  useEffect(() => {
    if (!tradingPartnersLoading && tradingPartners.length > 0) {
      // Convert trading partners to name/label pairs
      setGpoList([...getTPNameLabelPairs('gpo', tradingPartners)]);
    }
  }, [tradingPartnersData, tradingPartnersLoading]);

  const { customers: historyCustomers, mappings: historyMappings } =
    customerUpdatesData;

  const duplicatedCustomerUpdates: CustomerHistoryTimelineEntry[] = [];
  historyCustomers?.forEach((e) =>
    duplicatedCustomerUpdates.push({
      id: e.id,
      isMapping: false,
      timestamp: e.timestamp,
    })
  );
  historyMappings?.forEach((e) => {
    const { id } = isLocalCustomer
      ? parseMediledgerId(e.fromId)
      : parseMediledgerId(e.toId);
    duplicatedCustomerUpdates.push({
      id,
      isMapping: true,
      timestamp: e.timestamp,
    });
  });
  duplicatedCustomerUpdates.sort((a, b) =>
    a.timestamp < b.timestamp ? 1 : -1
  );
  const customerUpdates = uniqBy(duplicatedCustomerUpdates, 'timestamp');

  const mostRecentUpdate = first(customerUpdatesData?.customers ?? []);

  if (
    customerUpdatesData?.customers &&
    customerUpdatesData?.customers.length > 0 &&
    !!mostRecentUpdate &&
    mostRecentUpdate.timestamp !== customerUpdatesData.customers[0].timestamp
  ) {
    historyRefetch().then();
  }

  const timestamp =
    urlMappingTimestamp ||
    (isHistoryView ? urlTimestamp : first(customerUpdates)?.timestamp);

  const detailsQuery = getDetailsQuery(isLocalCustomer, !isHistoryView);
  const { data, error, loading, refetch } =
    useQuery<QueryCustomerDetailsResult>(detailsQuery, {
      variables: {
        id,
        memberId,
        timestamp,
      },
      skip: !id || !memberId || !timestamp || historyLoading,
    });

  useEffect(() => {
    if (timestamp) refetch().then();
  }, [customerUpdatesData]);

  useEffect(() => {
    historyRefetch().then();
  }, [memberId, id]);

  const customer: CustomerDetails | undefined = get(data, 'customer', [])[0];
  const mappingsData: any[] = get(data, 'mappingsHistory', []);

  if (loading || historyLoading || tradingPartnersLoading) return null;

  if (error || !customer || !timestamp) {
    return (
      <div className={cx('errorCode')}>
        <IconSystemError />
      </div>
    );
  }

  return (
    <Details
      customer={customer}
      customerUpdates={customerUpdates}
      historyTimestamp={timestamp || ''}
      isHistoryView={isHistoryView}
      isLocalCustomer={isLocalCustomer}
      mappings={mappingsData}
      refetch={(timestampProp) => {
        refetch({
          id,
          memberId,
          timestamp: timestampProp || timestamp,
        }).then();
      }}
      gpoList={gpoList}
    />
  );
};

export default Container;
