// Vendor
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import {
  useAppBarSetTitle,
  useIsFirstRender,
  useTradingPartnerConfig,
} from 'hooks';
import { NUMBER_PER_PAGE } from 'common/constants';
import { TableRow } from 'components/Table';
import {
  QUERY_PRODUCT_LISTS,
  QUERY_PRODUCT_LISTS_COUNT,
  QueryProductListsCountResult,
  QueryProductListsResult,
} from 'query';
import { Pagination, SearchBar, Table } from 'components';
import { IconSystemError } from 'styles/images';
import {
  ButtonsLoading,
  ContractFormProps,
} from 'pages/Contracts/Create/constants';
import { withMemberId } from 'services/queryService';
import {
  getColumns,
  productListsFiltersToQuery,
  rawDataToRows,
} from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

dayjs.extend(isBetween);

export interface Props {
  formRemoveBatch: (...args: any[]) => any;
  formUpdate: (...args: any[]) => any;
  initialValues: any;
  setButtonsLoading: Dispatch<SetStateAction<ButtonsLoading>>;
  values: ContractFormProps;
}

const SelectProductList: FC<Props> = (props) => {
  const {
    formRemoveBatch,
    formUpdate,
    initialValues,
    setButtonsLoading,
    values,
  } = props;
  useAppBarSetTitle('Product Lists');

  // Get additional information
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  if (!memberId) {
    throw new Error('Member ID must be present.');
  }

  // Search
  const [currSearch, search] = useState('');
  const [currentPage, updateCurrentPage] = useState(1);

  // Table rows
  const [rows, updateRows] = useState([] as TableRow[]);
  // Order
  const [order] = useState({ timestamp: 'desc' });
  // Where condition
  const [where, updateWhere] = useState(
    withMemberId(productListsFiltersToQuery(currSearch), memberId)
  );

  // Fetch the data
  const {
    data: productListsData,
    loading: productListsLoading,
    error,
    refetch,
  } = useQuery<QueryProductListsResult>(QUERY_PRODUCT_LISTS, {
    variables: {
      where,
      order,
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
    },
    skip: !memberId,
  });

  // Fetch the count
  const { data: countData, loading: countLoading } =
    useQuery<QueryProductListsCountResult>(QUERY_PRODUCT_LISTS_COUNT, {
      variables: {
        where,
        order,
        limit: NUMBER_PER_PAGE,
        offset: (currentPage - 1) * NUMBER_PER_PAGE,
      },
      skip: !memberId,
    });

  // Refetch on page load
  useEffect(() => {
    refetch({
      where,
      order,
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
    }).then();
  }, []);

  // Format the raw data
  useEffect(() => {
    if (!productListsLoading && productListsData) {
      updateRows(
        rawDataToRows(formRemoveBatch, formUpdate, productListsData, values)
      );
    }
  }, [productListsData, productListsLoading, values]);

  useEffect(() => {
    if (productListsLoading) return;

    const indexesToRemove: number[] = [];
    values.productLists?.forEach((productList, i) => {
      if (
        !productListsData?.productLists?.find((p) => p.id === productList.id)
      ) {
        indexesToRemove.push(i);
      }
    });
    formRemoveBatch('productLists', indexesToRemove);

    productListsData?.productLists?.forEach((list, i) => {
      const getCurrentValue = values.productLists?.find(
        (p: { id: string; checked: any }) => p.id === list.id
      );
      const getInitialValue = initialValues.productLists.find(
        (p: { id: string; checked: any }) => p.id === list.id
      );

      const checked = getCurrentValue
        ? getCurrentValue.checked
        : getInitialValue
        ? getInitialValue.checked
        : false;

      formUpdate('productLists', i, {
        checked,
        id: list.id,
      });
    });
  }, [productListsData]);

  // Build the search query
  const firstRender = useIsFirstRender();
  useEffect(() => {
    // First render help us to identify when we navigate through URL
    if (
      (firstRender && currentPage === 1) ||
      (!firstRender && currentPage > 1)
    ) {
      updateCurrentPage(1);
    }
    updateWhere(withMemberId(productListsFiltersToQuery(currSearch), memberId));
  }, [currSearch]);

  useEffect(() => {
    const loading = productListsLoading;
    setButtonsLoading({ submit: loading, next: loading });

    // set next/submit button loading state to 'false' on the component unmount
    return () => {
      setButtonsLoading({ submit: false, next: false });
    };
  }, [productListsLoading]);

  if (productListsLoading || !productListsData) {
    return <div />;
  }

  const hasProductLists = rows.length > 0;
  const isFiltered = currSearch !== '';
  const disableSearchFilter =
    !productListsLoading && !hasProductLists && !isFiltered;

  // Table with layout
  return !memberId || error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div>
      <section>
        <div className={cx('search')}>
          <SearchBar
            onSubmit={search}
            placeholder='Search by ID, name or description'
            disabled={disableSearchFilter}
            text={currSearch || ''}
          />
        </div>
      </section>
      <Table
        columns={getColumns()}
        emptyText='No product lists found'
        isLoading={productListsLoading}
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={countLoading}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={rows.length > 0}
        totalCount={countData?.productListsCount?.aggregate.count || 0}
      />
    </div>
  );
};

export default SelectProductList;
