// Vendor
import { struct, Eq } from 'fp-ts/lib/Eq';
import { Eq as eqString } from 'fp-ts/lib/string';
import { Eq as eqNumber } from 'fp-ts/lib/number';

// Internal
import { Relationship } from 'types/generated/Relationship';
import { ProductPrice } from 'types/generated/Product';
import { eqAny, getOptionalEq, getUnorderedArrayEq } from 'common/eqUtils';
import {
  maxLongTextLengthValidator,
  maxShortTextLengthValidator,
  required,
} from 'common/validation';
import { SectionField } from 'components/Section';
import { Product } from 'query';

export const getProductListFields = (): SectionField[] => [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    validation: [required, maxShortTextLengthValidator],
    size: 6,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'text',
    validation: [required, maxLongTextLengthValidator],
    size: 6,
  },
];

const eqRelationship = struct<Relationship>({
  relationship: eqString,
  to: eqString,
  endDate: getOptionalEq(eqString),
  startDate: getOptionalEq(eqString),
});

const eqProductPricing = struct<ProductPrice>({
  price: eqNumber,
  priceType: eqString,
  endDate: getOptionalEq(eqString),
  startDate: getOptionalEq(eqString),
});

export const eqProduct = struct<Product>({
  id: eqString,
  external_id: eqString,
  member_id: eqString,
  active: getOptionalEq(eqAny),
  description: eqString,
  kind: eqString,
  prices: getUnorderedArrayEq(eqProductPricing),
  end_date: eqString,
  relationships: getUnorderedArrayEq(eqRelationship),
  revisedAt: eqString,
  start_date: eqString,
  timestamp: eqString,
  unit_of_measure: eqString,
});

export const eqProductList: Eq<Array<Product>> = getUnorderedArrayEq(eqProduct);
