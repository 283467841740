// Internal
import { AuthorityDatabases } from 'common/config';
import {
  formatIdentifiers,
  localCustomerPath,
  removeRosterIdPrefix,
  rosterCustomerPath,
} from 'common/helpers';
import { IDENTIFIERS_COLUMN, TYPE_COLUMN } from 'components/Cells/constants';
import { TableColumn, TableRow } from 'components/Table';

export const getColumns = (
  acceptableAuthData: AuthorityDatabases[],
  isLocalCustomer: boolean
): TableColumn[] => [
  {
    displayTextFn: (data: any) => data.id,
    urlFn: (data: any) => {
      const linkUrl = isLocalCustomer
        ? localCustomerPath(data.id)
        : rosterCustomerPath(data.memberId, data.memberName, data.fullId);
      return linkUrl;
    },
    headerName: 'ID',
    size: 'small',
    type: 'link',
  },
  ...(acceptableAuthData.length > 0 ? [IDENTIFIERS_COLUMN] : []),
  TYPE_COLUMN,
];

export interface Relationship {
  id: string;
  identifiers: string[];
  relationship: 'Parent' | 'Top Parent' | 'Child';
}

export const getRows = (
  acceptableAuthData: AuthorityDatabases[],
  relationships: Relationship[],
  memberId: number,
  memberName: string
): TableRow[] => {
  if (relationships.length === 0) return [];

  return relationships.map((r) => {
    const { id: idProp, identifiers: identifiersProp, relationship } = r;

    const id = removeRosterIdPrefix(idProp);
    const identifiers = formatIdentifiers(identifiersProp);

    const isExpandable =
      acceptableAuthData.length > 0 && identifiers?.length > 1;

    return {
      type: 'expanding',
      isExpandable,
      data: {
        fullId: idProp,
        id,
        identifiers,
        type: relationship,
        memberId,
        memberName,
      },
    };
  });
};
