// Vendor
import React, { FC } from 'react';

const IconChange: FC = () => (
  <svg width='16' height='16' viewBox='0 0 24 24' fill='none'>
    <circle cx='12' cy='12' r='12' fill='#6342FF' />
    <path
      d='M16 13L18 15L16 17'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 15H18'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 11L6 9L8 7'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 9H6'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconChange;
