// Vendor
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import { NUMBER_PER_PAGE } from 'common/constants';
import Table, { TableRow } from 'components/Table';
import { useIsFirstRender } from 'hooks';
import {
  QUERY_CUSTOMER_LISTS,
  QUERY_CUSTOMER_LISTS_COUNT,
  QueryCustomersListCountResult,
  QueryCustomersListResult,
  wrapSearchText,
} from 'query';
import { Pagination, SearchBar } from 'components';
import { IconSystemError } from 'styles/images';
import { withMemberId } from 'services/queryService';
import { filtersToQuery, getColumns, rawDataToRows } from './services';
import { ButtonsLoading, ContractFormProps } from '../../constants';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

export interface Props {
  buttonsLoading: ButtonsLoading;
  change: (key: string, value: string) => void;
  setButtonsLoading: Dispatch<SetStateAction<ButtonsLoading>>;
  values: ContractFormProps;
  memberId: number;
}

const CustomerList: FC<Props> = (props) => {
  const { buttonsLoading, change, setButtonsLoading, values, memberId } = props;
  const { endDate, startDate } = values;

  // Search
  const [searchTerm, updateSearchTerm] = useState('');
  const [currentPage, updateCurrentPage] = useState(1);

  const [rows, updateRows] = useState([] as TableRow[]);
  const searchText = wrapSearchText(searchTerm);
  const [where, updateWhere] = useState(
    withMemberId(filtersToQuery(endDate, startDate, searchText), memberId)
  );

  // Load data
  const {
    data: customerListsData,
    loading,
    error,
    refetch,
  } = useQuery<QueryCustomersListResult>(QUERY_CUSTOMER_LISTS, {
    variables: {
      where,
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      order: { timestamp: 'desc' },
    },
  });

  // Load count
  const { data: countData, loading: countLoading } =
    useQuery<QueryCustomersListCountResult>(QUERY_CUSTOMER_LISTS_COUNT, {
      variables: {
        where,
      },
    });

  // Refetch on page load
  useEffect(() => {
    refetch({ data: customerListsData }).then();
  }, []);

  useEffect(() => {
    if (!countLoading && customerListsData) {
      updateRows(rawDataToRows(customerListsData, values));
    }
  }, [customerListsData, countLoading, searchTerm, searchText, values]);

  const firstRender = useIsFirstRender();

  // Search
  useEffect(() => {
    if (
      (firstRender && currentPage === 1) ||
      (!firstRender && currentPage > 1)
    ) {
      updateCurrentPage(1);
    }
    updateWhere(
      withMemberId(filtersToQuery(endDate, startDate, searchText), memberId)
    );
  }, [searchTerm]);

  useEffect(() => {
    setButtonsLoading({ ...buttonsLoading, submit: loading });
  }, [loading]);

  if (loading || !customerListsData) {
    return <div />;
  }

  const hasCustomerLists = rows.length > 0;
  const isFiltered = searchTerm !== '';
  const disableSearchFilter = !loading && !hasCustomerLists && !isFiltered;

  return !loading && error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('floatRight', 'root')}>
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            disabled={disableSearchFilter}
            onSubmit={updateSearchTerm}
            placeholder='Search by ID, name, or description'
            test-id='search-bar'
            text={searchTerm}
          />
        </div>
      </section>
      <Table
        columns={getColumns(change, values.customerList)}
        emptyText='No customer lists found'
        isLoading={countLoading}
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        isDisplayed={rows.length > 0}
        loading={countLoading}
        numberPerPage={NUMBER_PER_PAGE}
        onChangePage={({ currentPage }: { currentPage: number }) => {
          updateCurrentPage(currentPage);
        }}
        totalCount={countData?.customerListsCount?.aggregate.count ?? -1}
      />
    </div>
  );
};

export default CustomerList;
