// Internal
import {
  ErrorCode,
  IllegalIdentifierReference,
  MissingIdentifierReference,
} from '../../types/generated/ErrorCode';
import { authorityIDToName, parseMediledgerId } from './authorityData';

const ERROR_CODE_MISSING_IDENTIFIER_REFERENCE: MissingIdentifierReference['code'] =
  'missing-identifier-reference';
const ERROR_CODE_ILLEGAL_IDENTIFIER_REFERENCE: IllegalIdentifierReference['code'] =
  'illegal-identifier-reference';

const identifierErrorCodes = [
  ERROR_CODE_MISSING_IDENTIFIER_REFERENCE,
  ERROR_CODE_ILLEGAL_IDENTIFIER_REFERENCE,
];

type code =
  | MissingIdentifierReference['code']
  | IllegalIdentifierReference['code'];

export const getValidIdentifiers = (
  identifiers: string[],
  customerErrors?: ErrorCode[]
): string[] => {
  const identifierErrors =
    (customerErrors?.filter((e) =>
      identifierErrorCodes.includes(e.code as code)
    ) as (MissingIdentifierReference | IllegalIdentifierReference)[]) ?? [];
  return identifiers.filter((identifier) => {
    const { id } = parseMediledgerId(identifier);
    if (id === '') {
      return false; // if "id" is an empty string, the identifier is considered invalid.
    }
    const severity = identifierErrors.find(
      (e) => parseMediledgerId(e.extraProperties.identifier).id === id
    )?.severity;
    return severity !== 'ERROR' && severity !== 'WARNING';
  });
};

export const identifiersToDisplayValues = (identifiers: string[]): string[] =>
  identifiers.map((identifier) => {
    const { memberId, id } = parseMediledgerId(identifier);
    const authorityName = authorityIDToName(memberId);
    if (authorityName) {
      return `${authorityName} ${id}`;
    } else {
      return id;
    }
  });
