// Vendor
import React, { FC } from 'react';

interface Props {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
}

const IconAlert: FC<Props> = (props) => {
  const {
    className = '',
    fill = '#525F7F',
    height = 16,
    width = 16,
    ...rest
  } = props;
  return (
    <svg
      className={className}
      height={height}
      width={width}
      viewBox='0 0 24 24'
      {...rest}
    >
      <defs>
        <path
          id='alert-id'
          d='M12 20.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm0-1.5a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5zm-.006-5.576c-.492 0-.852-.336-.852-.9V8.718c0-.552.36-.888.852-.888.48 0 .84.336.84.888v4.056c0 .564-.36.9-.84.9zm-.984 1.536c0-.54.444-.984.984-.984s.984.444.984.984-.444.984-.984.984a.988.988 0 0 1-.984-.984z'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='alert-mask' fill='#fff'>
          <use xlinkHref='#alert-id' />
        </mask>
        <g fill={fill} fillOpacity='1' mask='url(#alert-mask)'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
    </svg>
  );
};

export default IconAlert;
