import { FilterStatus, getWhereClause } from '../../../Contracts/List/service';

export const getWhereClauseForCustomerList = (
  statusFilter: FilterStatus,
  searchText: string,
  fromId: string,
  listStartDate: string,
  listEndDate: string
) => ({
  ...getWhereClause(statusFilter, searchText),
  associations: {
    from_id_full: { _eq: fromId },
    active: { _eq: true },
    kind: { _eq: 'contract-list-association' },
  },
  _and: [
    {
      _or: [
        { start_date: { _is_null: true } },
        { start_date: { _lte: listEndDate } },
      ],
    },
    {
      _or: [
        { end_date: { _is_null: true } },
        { end_date: { _gte: listStartDate } },
      ],
    },
  ],
});
