// Vendor
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { uniq } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { identity } from 'fp-ts/function';

// Internal
import {
  QUERY_CUSTOMER_HISTORY_BY_DATE,
  QUERY_CUSTOMER_HISTORY_BY_DATE_COUNT,
  QUERY_CUSTOMER_LISTS,
  QUERY_CUSTOMER_MEMBERSHIPS,
  QUERY_CUSTOMERS_FOR_LIST,
  QUERY_CUSTOMERS_COUNT,
  QUERY_MULTIPLE_COT,
  QueryClassOfTradeResult,
  QueryCustomerMembershipsResult,
  QueryCustomersListResult,
  QueryCustomerView,
  QueryCustomerViewCountResult,
} from 'query';
import {
  IconInNetwork,
  IconOutOfNetwork,
  IconSystemError,
} from 'styles/images';
import {
  useApi,
  useApiService,
  useAppBarSetTitle,
  useAuthDatabases,
  useCsvUploadFlag,
  useCustomerMngmtFlag,
  useFlatfileLocalCustomersImportFlag,
  useFlatfileOonCustomersImportFlag,
  useIsFirstRender,
  useRosterAttributeColumns,
  useSnackbar,
  useTradingPartnerConfig,
  useTradingPartnerType,
} from 'hooks';
import { TableRow } from 'components/Table';
import { NUMBER_PER_PAGE } from 'common/constants';
import { isRosterOON, isSameDay } from 'common/helpers';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import { FiltersSerializationService } from 'common/helpers/filtersSerializationService';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import {
  CurrentPageParamConfig,
  FiltersActiveParamConfig,
  FiltersParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import CustomerFilter from 'pages/Customers/List/CustomerFilter';
import SearchBar from 'components/SearchBar';
import { Button, Pagination, Table } from 'components';
import { BreadcrumbWithSearch } from 'components/BreadcrumbWithSearch';
import { CSV_TYPE, onDownload } from 'services/export';
import customerAdapter from 'flatfile-import/domain/customer/local-customer/local-customer-adapter';
import RosterImport from 'pages/OONRosterImport/RosterImport/container';
import customerRosterValidatorService from 'flatfile-import/application/row-processor/customer/customer-roster-validator-service';
import { FLATFILE_FLOW } from 'flatfile-import/constants';
import { HttpCustomerPublisher } from 'flatfile-import/infrastructure/customer/http-customer-publisher';
import { CustomerRosterInputData } from 'flatfile-import/domain/customer/customer-roster-adapter';
import { Customer } from 'types/generated/Customer';
import { CustomerRosterSchema } from 'flatfile-import/domain/customer/customer-roster';
import { BatchPublisherService } from 'flatfile-import/application/publisher/batch-publisher-service';
import { UpsertCustomer } from 'types/generated/UpsertCustomer';
import { toMediledgerMemberId } from 'common/helpers/mlIdUtils';
import { CustomerAggregationService } from 'flatfile-import/application/aggregator/customer/customer-aggregation-service';
import upsertPublishAdapter from 'flatfile-import/application/publisher/customer/upsert-customer-publish-adapter';
import { PersistedAggregator } from 'flatfile-import/infrastructure/persisted-aggregator';
import { db } from 'flatfile-import/infrastructure/customer/customer-db';
import {
  concatCustomers,
  customerSemigroup,
} from 'flatfile-import/domain/customer/customer';
import { getColumns, getRows } from './service';
import {
  Filters,
  filtersToQuery,
  getProgramStatusWhere,
} from './CustomerFilter/helpers';
import UploadCustomers from './UploadCustomers';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

interface Props {
  isLocalCustomer?: boolean;
}

const CustomersContainer: FC<Props> = (props) => {
  const [rosterImportOpen, setRosterImportOpen] = useState(false);
  const { isLocalCustomer = false } = props;

  // FOR LOCAL CUSTOMERS
  const { id } = useTradingPartnerConfig() ?? {};

  // FOR ROSTER CUSTOMERS
  const { memberName } = useParams() as {
    memberName: string;
  };
  const { memberId: memberIdParams } = useParams<{
    memberId: string;
  }>();

  let memberId: number | undefined;
  if (isLocalCustomer) {
    memberId = id;
  } else {
    memberId = memberIdParams ? parseInt(memberIdParams, 10) : undefined;
  }

  useAppBarSetTitle(isLocalCustomer ? 'Customers' : memberName);

  // GET ADDITIONAL INFORMATION
  const acceptableAuthData = useAuthDatabases();
  const api = useApi();
  const apiService = useApiService();
  const snackbar = useSnackbar();
  const tpType = useTradingPartnerType();

  if (!apiService) {
    throw new Error('apiService instance is required but not found.');
  }

  // SEARCH
  const searchModule = isLocalCustomer
    ? 'customers'
    : `roster/(.+)/${encodeURIComponent(memberName)}`;
  const { currentSearch } = usePersistedSearchParams(
    {
      module: searchModule,
      params: [
        SearchParamConfig,
        CurrentPageParamConfig,
        FiltersParamConfig,
        FiltersActiveParamConfig,
      ],
    },
    SessionStorage
  );
  const [searchText, updateSearchText] = currentSearch[SearchParamConfig.key];

  // PAGINATION
  const [currentPage, updateCurrentPage] =
    currentSearch[CurrentPageParamConfig.key];

  // FILTERS
  const [currentFilters, updateFilters] = currentSearch[FiltersParamConfig.key];
  const [activeFilters, updateFiltersActive] =
    currentSearch[FiltersActiveParamConfig.key];
  const filters: Filters | undefined = useMemo(
    () => currentFilters && FiltersSerializationService.parse(currentFilters),
    [currentFilters]
  ) as Filters;
  const areFiltersActive = !!filters && activeFilters;

  const attrColumns = useRosterAttributeColumns();

  // TABLE DEFINITIONS
  const { columns, hasCotColumn, hasProgramColumn } = getColumns(
    isLocalCustomer,
    tpType,
    acceptableAuthData,
    attrColumns
  );

  // TABLE ROWS
  const [rows, updateRows] = useState([] as TableRow[]);

  // ORDER
  const [order] = useState({ timestamp: 'desc' });

  // WHERE CONDITION
  const [where, updateWhere] = useState(
    filtersToQuery(filters as Filters, areFiltersActive, searchText, memberId)
  );
  const [selectedDate, updateSelectedDate] = useState(new Date());

  // EXPORT STATE
  const [isExportLoading, toggleExportLoading] = useState(false);
  const [showImportDialog, toggleImportDialog] = useState(false);

  // SELECT WHICH QUERY TO USE
  const [skipHistoryQuery, updateSkipHistoryQuery] = useState(true);

  const membershipsWhere: any = {};
  const conditionsArray: any[] = [];
  if (filters) {
    membershipsWhere.list_id = { _in: filters.selectedPrograms };
    getProgramStatusWhere(conditionsArray, filters, selectedDate);
  }
  if (conditionsArray.length > 0) membershipsWhere._and = conditionsArray;

  // FETCH DATA
  const customersQuery = skipHistoryQuery
    ? QUERY_CUSTOMERS_FOR_LIST
    : QUERY_CUSTOMER_HISTORY_BY_DATE;
  const {
    data: customersData,
    loading,
    error,
    refetch,
  } = useQuery<QueryCustomerView>(customersQuery, {
    variables: {
      date: skipHistoryQuery ? '' : selectedDate,
      limit: NUMBER_PER_PAGE,
      memberId: skipHistoryQuery ? '' : memberId,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      order,
      where,
      includeMemberships: false,
    },
    skip: !memberId,
  });

  // FETCH COUNT
  const countQuery = skipHistoryQuery
    ? QUERY_CUSTOMERS_COUNT
    : QUERY_CUSTOMER_HISTORY_BY_DATE_COUNT;
  const {
    data: countData,
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<QueryCustomerViewCountResult>(countQuery, {
    variables: {
      date: skipHistoryQuery ? '' : selectedDate,
      memberId: skipHistoryQuery ? '' : memberId,
      where,
    },
    skip: !memberId,
  });

  const customers = customersData?.customers || [];
  const customerIDs = customers.map((customer) => customer.id);
  const [now] = useState(new Date());

  // Fetch memberships by customer IDs
  const { data: membershipsData, loading: membershipsLoading } =
    useQuery<QueryCustomerMembershipsResult>(QUERY_CUSTOMER_MEMBERSHIPS, {
      variables: {
        where: {
          _and: [
            { customer_member_id: { _eq: memberId } },
            { customer_id: { _in: customerIDs } },
            {
              _or: [
                { start_date: { _is_null: true } },
                { start_date: { _lte: now } },
              ],
            },
            {
              _or: [
                { end_date: { _is_null: true } },
                { start_date: { _lte: now } },
              ],
            },
          ],
        },
        order: { rank: 'asc' },
        includeList: true,
      },
      skip: !hasProgramColumn || !memberId || customers.length === 0,
    });

  // FETCH PROGRAMS
  const { data: programsData, loading: programsLoading } =
    useQuery<QueryCustomersListResult>(QUERY_CUSTOMER_LISTS, {
      variables: {
        where: { member_id: { _eq: memberId }, type: { _eq: 'program' } },
      },
      skip: (tpType !== 'GPO' && isLocalCustomer) || !memberId,
    });

  const programs = programsData?.lists ?? [];

  // FETCH COTS
  const cotIds = hasCotColumn
    ? uniq(
        customers
          .flatMap((c) => c.classesOfTrade?.map((cot) => cot.classOfTradeId))
          .filter((cotId) => cotId)
      )
    : [];
  const { data: cotData, loading: cotLoading } =
    useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
      variables: {
        ids: cotIds,
        memberId,
      },
      skip: cotIds.length === 0 || memberId === undefined,
    });

  // If going from roster customers -> local customers we stay on the same component so we refetch here for this case
  useEffect(() => {
    const where = filtersToQuery(
      filters as Filters,
      areFiltersActive,
      searchText,
      memberId
    );
    updateWhere(where);
    Promise.all([refetch({ where }), refetchCount({ where })]).then(identity);
  }, [memberId]);

  // FORMAT RAW DATA
  useEffect(() => {
    if (!loading && !cotLoading && !membershipsLoading) {
      updateRows(
        getRows(
          hasCotColumn,
          hasProgramColumn,
          acceptableAuthData,
          memberName,
          customersData,
          cotData,
          membershipsData,
          attrColumns
        )
      );
    }
  }, [
    customersData,
    cotData,
    membershipsData,
    loading,
    cotLoading,
    membershipsLoading,
  ]);

  // BUILD THE SEARCH QUERY
  const firstRender = useIsFirstRender();
  useEffect(() => {
    // Skip the history query if the selected date is today
    updateSkipHistoryQuery(isSameDay(selectedDate, new Date()));
    // First render help us to identify when we navigate through URL
    if (
      (firstRender && currentPage === 1) ||
      (!firstRender && currentPage > 1)
    ) {
      updateCurrentPage(1);
    }
    updateWhere(
      filtersToQuery(filters as Filters, areFiltersActive, searchText, memberId)
    );
  }, [filters, areFiltersActive, searchText, selectedDate]);

  const isFiltered = searchText !== '' || areFiltersActive;

  const checkForStatus = (exportId: string) => {
    api?.exportStatus(exportId).then(({ data }) => {
      // @ts-ignore
      const { status } = data;
      if (status === 'complete') {
        // @ts-ignore
        const { downloadPath } = data.result;
        toggleExportLoading(false);
        api?.exportDownload(downloadPath).then(({ data }) => {
          const rosterText = !isLocalCustomer
            ? `${memberName.toUpperCase()}_`
            : '';
          const filteredText = isFiltered ? 'FILTERED_' : '';

          onDownload(
            data,
            `${rosterText}CUSTOMERS_${filteredText}${dayjs()
              .utc()
              .format('YYYY_MM_DD')}.csv`,
            CSV_TYPE
          );
          snackbar.open({
            message:
              'All set! Your customer export file is ready and downloading.',
            variant: 'success',
          });
        });
      } else if (status === 'error') {
        toggleExportLoading(false);
      } else {
        window.setTimeout(() => checkForStatus(exportId), 500);
      }
    });
  };

  const handleExport = () => {
    toggleExportLoading(true);
    api
      ?.exportCustomersGenerate({
        areCustomersLocal: isLocalCustomer,
        date: selectedDate,
        format: 'csv',
        memberId,
        membershipsWhere,
        where,
      })
      .then(({ data }: { data: any }) => {
        const { exportId, status } = data;
        toggleExportLoading(status === 'processing');
        snackbar.open({
          message: `Hang tight! Your customer export file is being prepared for download. This might take a few minutes and we'll notify you when it's ready. Leaving or refreshing the page will cancel the export.`,
          variant: 'info',
        });
        checkForStatus(exportId);
      });
  };

  const customerMngmtEnabled = useCustomerMngmtFlag();
  const csvUploadEnabled = useCsvUploadFlag();
  const navigate = useNavigate();

  const isOON = isRosterOON(memberId ?? -1);

  const hasCustomers = rows.length > 0;
  const disableSearchFilter = loading || (!hasCustomers && !isFiltered);
  const placeholderText = `Search by ID${
    acceptableAuthData.length > 0 ? ', identifier' : ''
  }, name or address`;
  const localFlatfileImportEnabled =
    useFlatfileLocalCustomersImportFlag() ?? false;
  const oonFlatfileImportEnabled = useFlatfileOonCustomersImportFlag() ?? false;

  const runRosterImport = useCallback(() => {
    if (isOON) {
      navigate(`/oon-roster/import/${memberId}/${memberName}`);
    } else {
      setRosterImportOpen(true);
    }
  }, []);

  const refetchCustomers = useCallback(() => {
    Promise.all([refetch(), refetchCount()]).then(identity);
  }, [refetch, refetchCount]);

  const onFinishRosterImport = useCallback(() => {
    refetchCustomers();
    setRosterImportOpen(false);
  }, []);

  const publisherService = useMemo(() => {
    const publisher = new HttpCustomerPublisher(apiService, isOON);
    return new BatchPublisherService(publisher);
  }, [apiService]);
  const mlMemberId = toMediledgerMemberId((memberId as number).toString());
  const aggregationService = useMemo(
    () =>
      new CustomerAggregationService(
        new PersistedAggregator(db, customerSemigroup, concatCustomers)
      ),
    []
  );

  return !memberId || error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div
      className={cx('root', isLocalCustomer && 'isLocalCustomer')}
      data-testid='customers'
    >
      <RosterImport<
        CustomerRosterSchema,
        CustomerRosterInputData,
        Customer,
        UpsertCustomer
      >
        entityLabel='Customer'
        rosterValidator={customerRosterValidatorService}
        publisher={publisherService}
        open={rosterImportOpen}
        containerId='local'
        embedNode={FLATFILE_FLOW.Local}
        rosterAdapter={customerAdapter}
        formValues={{
          memberId: mlMemberId,
          rosterId: '',
          timestamp: new Date().toISOString(),
        }}
        onFinish={onFinishRosterImport}
        onCancel={() => setRosterImportOpen(false)}
        aggregator={aggregationService}
        publishAdapter={upsertPublishAdapter}
      />
      {showImportDialog && (
        <UploadCustomers toggleDialog={toggleImportDialog} />
      )}
      {!isLocalCustomer && (
        <>
          <BreadcrumbWithSearch
            module='roster'
            storage={SessionStorage}
            crumbs={['Roster', memberName]}
            links={[`/roster`]}
          />
          <Chip
            avatar={isOON ? <IconOutOfNetwork /> : <IconInNetwork />}
            data-testid='chip'
            label={isOON ? 'Out of network' : 'In network'}
            style={{
              backgroundColor: isOON ? '#6342FF' : '#00A8F3',
              borderRadius: 40,
              color: '#fff',
              fontFamily: 'Graphik',
              fontSize: 14,
              height: 36,
              lineHeight: 20,
              margin: '16px 0 40px',
              padding: '8px 24px',
              width: 'fit-content',
            }}
          />
        </>
      )}
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            disabled={disableSearchFilter}
            onSubmit={(searchText) => updateSearchText(searchText)}
            placeholder={placeholderText}
            text={searchText || ''}
          />
          {!programsLoading && (
            <CustomerFilter
              applyFilters={(filters: Filters, areFiltersActive: boolean) => {
                updateFilters(filters);
                updateSelectedDate(filters.selectedDate);
                updateFiltersActive(areFiltersActive);
              }}
              cFilters={filters}
              cFiltersActive={areFiltersActive}
              disableFilters={disableSearchFilter}
              isRoster={!isLocalCustomer}
              lists={programs}
            />
          )}
        </div>
        <div className={cx('floatRight', 'row')}>
          {((isLocalCustomer && localFlatfileImportEnabled) ||
            (isOON && oonFlatfileImportEnabled)) && (
            <Button
              dataTestId='uploadRosterBtn'
              label='Upload Customers'
              onClick={runRosterImport}
              style={{
                marginRight: 8,
                minWidth: 152,
              }}
            />
          )}
          {customerMngmtEnabled && isLocalCustomer && (
            <Button
              dataTestId='createCustomerBtn'
              label='Create Customer'
              onClick={() => navigate('/customers/create', { replace: true })}
              style={{
                marginRight: 8,
                minWidth: 152,
              }}
            />
          )}
          {csvUploadEnabled && isLocalCustomer && (
            <Button
              color='secondary'
              dataTestId='importCustomersBtn'
              label='Import Customer Data'
              onClick={() => toggleImportDialog(true)}
              style={{
                marginRight: 8,
                minWidth: 152,
                padding: 0,
              }}
              variant='outlined'
            />
          )}
          <Button
            dataTestId='export-customers'
            disabled={!hasCustomers || isExportLoading}
            label={isFiltered ? 'Export Filtered List' : 'Export All'}
            loading={isExportLoading}
            onClick={handleExport}
            style={{
              minWidth: 152,
              padding: 0,
            }}
          />
        </div>
      </section>
      <Table
        columns={columns}
        emptyText='No customers found'
        isLoading={loading || programsLoading || cotLoading}
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        isDisplayed={hasCustomers}
        loading={countLoading}
        numberPerPage={NUMBER_PER_PAGE}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
          refetch({ offset: (currentPage - 1) * NUMBER_PER_PAGE }).then();
        }}
        totalCount={countData?.customerCount?.aggregate.count ?? 0}
      />
    </div>
  );
};

export default CustomersContainer;
