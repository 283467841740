// Vendor
import classnames from 'classnames/bind';
import MUITooltip from '@material-ui/core/Tooltip';
import React, { CSSProperties, FC, useState } from 'react';
import { PopperPlacementType } from '@material-ui/core';

// Styles
import styles from './Tooltip.module.css';
const cx = classnames.bind(styles);

interface Props {
  alwaysActive?: boolean;
  children?: any;
  disabled?: boolean;
  disableHoverListener?: boolean;
  placement?: string;
  title?: any;
  style?: CSSProperties;
}

const Tooltip: FC<Props> = (props) => {
  const [isTooltipOpen, toggleTooltip] = useState(false);
  const {
    alwaysActive,
    children,
    disabled = false,
    disableHoverListener = true,
    placement = 'top',
    title = '',
    ...rest
  } = props;

  const tooltipProps: { open?: boolean } = {};
  if (disableHoverListener) tooltipProps.open = isTooltipOpen;

  const isEllipsisActive = (e: any) => {
    if (alwaysActive) {
      toggleTooltip(true);
      return;
    }
    const activeEllipsis = e.target.offsetWidth < e.target.scrollWidth;
    if (activeEllipsis) toggleTooltip(true);
  };

  return (
    title && (
      <MUITooltip
        {...tooltipProps}
        data-testid='tooltip'
        disableFocusListener
        disableTouchListener
        disableHoverListener={disabled || disableHoverListener}
        placement={placement as PopperPlacementType}
        title={title}
        {...rest}
      >
        <div
          className={cx('root')}
          onMouseEnter={(e) => isEllipsisActive(e)}
          onMouseLeave={() => toggleTooltip(false)}
        >
          {children}
        </div>
      </MUITooltip>
    )
  );
};

export default Tooltip;
