import * as types from 'actions/types';
import { DashboardConfig } from 'common/config';

export interface ConfigState {
  dashboard?: DashboardConfig;
}

const configReducer = (
  state: ConfigState = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: types.CoronaAction
): ConfigState => ({ ...state });

export default configReducer;
