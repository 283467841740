// Vendor
import React, { FC } from 'react';

// Internal
import { ProductPrice } from 'types/generated/Product';
import { isSameOrBeforeDate } from 'common/validation';
import { formatCurrency } from 'common/helpers';
import Table, { TableRow } from 'components/Table';
import { PRODUCTS_PRICING_COLUMNS } from './constants';

// Styles
import 'react-datepicker/dist/react-datepicker.css';

export interface ProductPricingTableProps {
  prices: ProductPrice[];
}

export const ProductPricingTable: FC<ProductPricingTableProps> = ({
  prices,
}) => {
  const rows = prices
    .sort((a, b) => (isSameOrBeforeDate(a.startDate)(b.startDate) ? -1 : 1))
    .map(
      (p) =>
        ({
          data: { ...p, price: formatCurrency(p.price, false) },
          isExpandable: false,
          type: 'expanding',
        } as TableRow)
    );

  return (
    <Table
      columns={PRODUCTS_PRICING_COLUMNS}
      rows={rows}
      emptyText='No prices for this product'
      isLoading={false}
    />
  );
};
