// Vendor
import { defaultOperators } from 'react-querybuilder';

// Clang only supports the following: export type Oper = '==' | '!=' | '>' | '>=' | '<' | '<=' | 'contains';
export const dateOperators = [
  { name: '==', label: 'is' },
  { name: '!=', label: 'is not' },
  { name: '<', label: 'before' },
  { name: '<=', label: 'on or before' },
  { name: '>', label: 'after' },
  { name: '>=', label: 'on or after' },
  ...defaultOperators.filter((op) => ['null', 'notNull'].includes(op.name)),
];

export const numberOperators = [
  { name: '==', label: 'is' },
  { name: '!=', label: 'is not' },
  { name: '<', label: 'less than' },
  { name: '<=', label: 'less than or equal to' },
  { name: '>', label: 'greater than' },
  { name: '>=', label: 'greater than or equal to' },
  ...defaultOperators.filter((op) => ['null', 'notNull'].includes(op.name)),
];

export const stringOperators = [
  { name: '==', label: 'is' },
  { name: '!=', label: 'is not' },
  ...defaultOperators.filter((op) =>
    ['contains', 'null', 'notNull'].includes(op.name)
  ),
];

export const arrayOperators = [
  ...defaultOperators.filter((op) =>
    ['contains', 'null', 'notNull'].includes(op.name)
  ),
];
