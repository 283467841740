// Vendor
import MuiTextField from '@material-ui/core/TextField';
import React, { forwardRef } from 'react';

interface TextFieldProps {
  className?: string;
  customError?: string;
  disabled: boolean;
  handleOnBlur?: () => void;
  input: {
    onBlur?: (e: any) => void;
    onChange: (e: any) => void;
    value?: string;
  };
  InputProps?: any;
  meta: {
    error: string;
    touched: boolean;
  };
  placeholder: string;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (componentProps, ref) => {
    const {
      className = '',
      customError,
      disabled = false,
      handleOnBlur,
      input: { onBlur, onChange, value },
      meta: { error, touched },
      placeholder,
      ...overrides
    } = componentProps;

    return (
      <MuiTextField
        data-testid='textField'
        inputRef={ref}
        error
        fullWidth
        autoComplete='off'
        onBlur={(e) => {
          if (onBlur) onBlur(e);
          if (handleOnBlur) handleOnBlur();
        }}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        className={className}
        helperText={touched ? error || customError : ''}
        onChange={onChange}
        placeholder={placeholder}
        type='text'
        value={value}
        variant='outlined'
        {...overrides}
      />
    );
  }
);

export default TextField;
