// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';

// Internal
import { ErrorWithMessage } from 'common/helpers';
import { RED_ERROR_COLOR, YELLOW_WARNING_COLOR } from 'common/constants';
import { IconErrorAlert } from 'styles/images';
import { ErrorsTooltip, Tooltip } from 'components/Tooltips';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface ErrorsColumnInterface {
  type: 'errors';
  headerName?: '';
  errorsField: string;
  warningsField: string;
  disableHoverListener?: boolean;
  size?: Size;
}

export interface Props {
  handleRowClick?: (data: any) => void;
  severityErrors: ErrorWithMessage[];
  severityWarnings: ErrorWithMessage[];
  disableHoverListener?: boolean;
  size?: Size;
}

const ErrorCell: FC<Props> = (props) => {
  const {
    severityErrors,
    severityWarnings,
    disableHoverListener = false,
    handleRowClick,
    size = 'small',
  } = props;

  return (
    <TableCell
      align='right'
      data-testid='errorCell'
      onClick={handleRowClick}
      size={size}
    >
      <div test-id='errorCell' className={cx('row')}>
        {severityErrors.length > 0 && (
          <Tooltip
            disableHoverListener={disableHoverListener}
            title={<ErrorsTooltip errors={severityErrors} marginTop={false} />}
          >
            <IconErrorAlert className={cx('icon')} fill={RED_ERROR_COLOR} />
          </Tooltip>
        )}
        {severityWarnings.length > 0 && (
          <Tooltip
            disableHoverListener={disableHoverListener}
            title={
              <ErrorsTooltip errors={severityWarnings} marginTop={false} />
            }
          >
            <IconErrorAlert
              className={cx('icon')}
              fill={YELLOW_WARNING_COLOR}
            />
          </Tooltip>
        )}
      </div>
    </TableCell>
  );
};

export default ErrorCell;
