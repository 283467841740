export interface GPOAffiliation {
  checked: boolean;
  endDate: string;
  listId: string;
  name: string;
  startDate: string;
}

export interface CustomerFormProps {
  three40b: string;
  address1: string;
  address2: string;
  address3: string;
  addressEndDate: string;
  addressStartDate: string;
  addressType: string;
  city: string;
  classOfTrade: string;
  cotEndDate: string;
  cotStartDate: string;
  dea: string;
  endDate: string;
  gpoAffiliation: GPOAffiliation[];
  hin: string;
  name: string;
  parentId: string;
  startDate: string;
  state: string;
  zip: string;
}

export const emptyFormObject: CustomerFormProps = {
  three40b: '',
  address1: '',
  address2: '',
  address3: '',
  addressEndDate: '',
  addressStartDate: '',
  addressType: '',
  city: '',
  classOfTrade: '',
  cotEndDate: '',
  cotStartDate: '',
  dea: '',
  endDate: '',
  gpoAffiliation: [],
  hin: '',
  name: '',
  parentId: '',
  startDate: '',
  state: '',
  zip: '',
};
