// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import Paper from '@material-ui/core/Paper';

// Internal
import { formatDate, truncateDatetime } from 'common/helpers';
import { KeyValuePair } from 'components';
import { Product } from 'query';
import { useAppBarSetTitle } from 'hooks';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  detailsInfo: Product;
}

const Overview: FC<Props> = ({ detailsInfo }) => {
  useAppBarSetTitle(`Product ID: ${detailsInfo.external_id}`);

  return (
    <Paper className={cx('paperMargin')}>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Overview</header>
        <div className={cx('content')}>
          <KeyValuePair title='Product ID' value={detailsInfo?.external_id} />
          <KeyValuePair title='Product ID Type' value={detailsInfo?.kind} />
          <KeyValuePair
            title='Unit of Measure'
            value={detailsInfo?.unit_of_measure}
          />
          <KeyValuePair title='Description' value={detailsInfo?.description} />
          <KeyValuePair
            title='Start Date'
            value={truncateDatetime(detailsInfo?.start_date)}
          />
          <KeyValuePair
            title='End Date'
            value={truncateDatetime(detailsInfo?.end_date)}
          />
          <KeyValuePair
            title='Updated At'
            data-testid='updatedAt'
            value={formatDate(detailsInfo?.timestamp)}
          />
        </div>
      </div>
    </Paper>
  );
};

export default Overview;
