import { RelationshipRow } from 'query';

export type HasRelationships = {
  relationshipsTo?: RelationshipRow[];
  relationshipsFrom?: RelationshipRow[];
};

export const extractRelationshipIds = (
  customers: HasRelationships[],
  relationshipType?: string
): Set<string> => {
  const relationshipsTo = customers
    .flatMap((c) => c.relationshipsTo || [])
    .filter((r) => !relationshipType || r.relationship === relationshipType)
    .map((r) => r.fromId);
  const relationshipsFrom = customers
    .flatMap((c) => c.relationshipsFrom || [])
    .filter((r) => !relationshipType || r.relationship === relationshipType)
    .map((r) => r.toId);
  const allRelationshipIds = relationshipsTo.concat(relationshipsFrom);
  return new Set(allRelationshipIds);
};
