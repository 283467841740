// Vendor
import React, { FC } from 'react';

// Internal
import Table, { TableColumn, TableRow } from 'components/Table';
import { NAME_COLUMN } from 'components/Cells/constants';
import { TradingPartnerWithStatus } from '../NodeHealthContainer';
import { getStatusInfo } from './helpers';

const columns: TableColumn[] = [
  {
    headerName: 'MEMBER ID',
    dataField: 'id',
    size: 'small',
    type: 'expanding',
    style: {
      lineHeight: '15px',
    },
  },
  NAME_COLUMN,
  {
    headerName: 'CMI STATUS',
    dataField: 'status',
    colorField: 'statusChipColor',
    size: 'medium',
    type: 'chip',
  },
  {
    headerName: '',
    title: 'Test',
    size: 'small',
    type: 'button',
    style: {
      width: '80px',
      border: '1px solid #E4EBEF',
      borderRadius: '5px',
      backgroundColor: 'white',
      color: '#525f7f',
    },
    handleOnClickFn: (data: any) => () => data.onClick(),
    disabledFn: (data: any) => data.disabled,
  },
];

export interface Props {
  getStatusForNode: (nodeId: number) => void;
  tradingPartners: TradingPartnerWithStatus[];
  loading: boolean;
}

export const NodeHealthComponent: FC<Props> = ({
  getStatusForNode,
  tradingPartners,
  loading,
}) => {
  const rows: TableRow[] = tradingPartners.map(
    (tp: TradingPartnerWithStatus) => {
      const { label, color } = getStatusInfo(tp.status);
      return {
        type: 'expanding',
        isExpandable: false,
        data: {
          ...tp,
          names: tp.name,
          status: label,
          statusChipColor: color,
          onClick: () => getStatusForNode(tp.id),
          disabled: tp.status === 'pending',
        },
      };
    }
  );

  return (
    <Table
      columns={columns}
      rows={rows}
      isLoading={loading}
      emptyText='No nodes found'
    />
  );
};
