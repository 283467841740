// Vendor
import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

// Internal
import AdvancedFilter from 'components/Filters/AdvancedFilter';
import { FilterDefinitionsInterface } from 'components/FilterGroup';
import { Filters } from './services';
import { ProposalStatus } from '../service';

export interface Props {
  applyFilters: (filters: Filters, filtersActive: boolean) => void;
  disableFilters: boolean;
  cFilters?: Filters;
  cFiltersActive?: boolean;
  triggerSources: string[];
}

const ListFilter: FC<Props> = (props) => {
  const {
    applyFilters,
    disableFilters,
    cFilters,
    cFiltersActive,
    triggerSources,
  } = props;

  const initialFilters: Filters = {
    showApproved: true,
    showPending: true,
    showRejected: true,
    allSourcesSelected: true,
    selectedSources: triggerSources,
    dateRange: {
      from: null,
      to: null,
    },
  };

  const [filters, setFilters] = useState(initialFilters);
  const [filtersActive, setFiltersActive] = useState(false);

  // This effect allow us to force the load of the received (filters, filtersActive).
  useEffect(() => {
    if (cFilters !== undefined && !isEqual(cFilters, filters)) {
      setFilters(cFilters);
    }
    if (cFiltersActive !== undefined && cFiltersActive !== filtersActive) {
      setFiltersActive(cFiltersActive);
    }
  }, [cFilters, cFiltersActive]);

  useEffect(() => {
    setFiltersActive(!isEqual(filters, initialFilters));
  }, [filters]);

  const filterDefinitions: FilterDefinitionsInterface[] = [
    {
      filters: [
        { label: ProposalStatus.APPROVED, name: 'showApproved' },
        { label: ProposalStatus.PENDING, name: 'showPending' },
        { label: ProposalStatus.REJECTED, name: 'showRejected' },
      ],
      filterValues: {
        showApproved: filters.showApproved,
        showPending: filters.showPending,
        showRejected: filters.showRejected,
      },
      fullSize: false,
      title: 'STATUS',
    },
    {
      filters: [{ label: 'Date Range', name: 'dateRange', type: 'date-range' }],
      filterValues: { dateRange: filters.dateRange },
      initialValues: { dateRange: initialFilters.dateRange },
      title: 'DATE RANGE',
    },
    {
      allSelected: filters.allSourcesSelected,
      filters: triggerSources.map((source) => ({
        label: source,
        name: source,
      })),
      filterValues: filters.selectedSources,
      initialValues: initialFilters.selectedSources,
      title: 'TRIGGER SOURCE',
    },
  ];

  const handleClear = () => setFilters(initialFilters);

  return (
    <AdvancedFilter
      applyFilters={() => {
        applyFilters(
          {
            ...filters,
          },
          filtersActive
        );
      }}
      filtersActive={filtersActive}
      filterDefinitions={filterDefinitions}
      handleClear={handleClear}
      setFilters={(newFilters) => {
        if (newFilters.selectedPrograms) {
          setFilters({
            ...filters,
            selectedSources: newFilters.selectedPrograms,
            allSourcesSelected: newFilters.allProgramsSelected,
          });
        } else {
          setFilters({
            ...filters,
            ...newFilters,
          });
        }
      }}
      disableFilters={disableFilters}
    />
  );
};

export default ListFilter;
