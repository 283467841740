// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconCreditMemos: FC<Props> = ({ color = '#011F42' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='5myp61wd7a'
        d='M14.562.625c.365 0 .675.128.93.383s.383.565.383.93v9.625c0 .364-.128.674-.383.93-.255.254-.565.382-.93.382H1.437c-.364 0-.674-.128-.93-.383-.254-.255-.382-.565-.382-.93V1.939c0-.365.128-.675.383-.93s.565-.383.93-.383h13.124zM1.437 1.5c-.127 0-.232.041-.314.123-.082.082-.123.187-.123.315V3.25h14V1.938c0-.128-.041-.233-.123-.315-.082-.082-.187-.123-.315-.123H1.437zM14.562 12c.128 0 .233-.041.315-.123.082-.082.123-.187.123-.314V5.875H1v5.688c0 .127.041.232.123.314.082.082.187.123.314.123h13.125zM5.375 9.922c0 .091-.032.168-.096.232s-.141.096-.232.096H3.078c-.091 0-.169-.032-.232-.096-.064-.064-.096-.141-.096-.232v-.219c0-.091.032-.168.096-.232.063-.064.141-.096.232-.096h1.969c.091 0 .168.032.232.096s.096.141.096.232v.219zm5.25 0c0 .091-.032.168-.096.232s-.141.096-.232.096H6.578c-.091 0-.169-.032-.232-.096-.064-.064-.096-.141-.096-.232v-.219c0-.091.032-.168.096-.232.063-.064.141-.096.232-.096h3.719c.091 0 .168.032.232.096s.096.141.096.232v.219z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g transform='translate(-16 -459) translate(0 319) translate(0 128) translate(20 17)'>
        <mask id='uef2vbawbb' fill='#fff'>
          <use xlinkHref='#5myp61wd7a' />
        </mask>
        <use fill='#011F42' fillRule='nonzero' xlinkHref='#5myp61wd7a' />
        <g fill={color} mask='url(#uef2vbawbb)'>
          <path d='M0 0L24 0 24 24 0 24z' transform='translate(-4 -5)' />
        </g>
      </g>
    </g>
  </svg>
);

export default IconCreditMemos;
