// Vendor
import React, { FC, useEffect, useRef } from 'react';
import { Button, InputAdornment, makeStyles } from '@material-ui/core';
import classnames from 'classnames/bind';

// Internal
import { Tooltip } from '../Tooltips';
import { TextField } from '../index';
import { IconSearch } from '../../styles/images';

// Styles
import styles from './index.module.css';
import muiStyles from './styles';

const cx = classnames.bind(styles);
const useStyles = makeStyles(muiStyles());

export interface Props {
  className?: string;
  disabled: boolean;
  onSubmit: (text: string) => void;
  placeholder: string;
  text?: string;
}

const SearchBar: FC<Props> = (props) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    className = '',
    disabled,
    onSubmit,
    placeholder = 'Search',
    text,
    ...overrides
  } = props;

  // We update the ref so the input will have the corresponding value
  useEffect(() => {
    if (text && inputRef.current) {
      inputRef.current.value = text;
    }
  }, [text]);

  return (
    <div className={cx('root', className)} data-testid='search'>
      <Tooltip
        disableHoverListener={false}
        disabled={disabled}
        style={{ width: '100%' }}
        placement='top-start'
        title={placeholder}
        data-testid='searchTooltip'
      >
        <TextField
          ref={inputRef}
          disabled={disabled}
          input={{ onChange: () => {} }}
          meta={{
            error: '',
            touched: false,
          }}
          placeholder={placeholder}
          InputProps={{
            classes: {
              root: classes.outlinedInput,
            },
            endAdornment: (
              <InputAdornment position='end'>
                <Button
                  test-id='search_button'
                  classes={{
                    label: classes.label,
                    root: classes.root,
                  }}
                  disabled={disabled}
                  disableFocusRipple
                  color='primary'
                  onClick={() => {
                    onSubmit(inputRef.current?.value ?? '');
                  }}
                  variant='contained'
                  data-testid='searchButton'
                >
                  <IconSearch />
                </Button>
              </InputAdornment>
            ),
            onKeyDown: (e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onSubmit(inputRef.current?.value ?? '');
              }
            },
          }}
          {...overrides}
        />
      </Tooltip>
    </div>
  );
};

export default SearchBar;
