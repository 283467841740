import { ApiService } from 'actions/api';
import { Buffer } from 'buffer';
import { AuthDriver } from './driver';

export class SamlDriver implements AuthDriver {
  /** Returns Promise<void> */
  async ssoLoginStart(
    apiService: ApiService,
    redirectUri: string | boolean | number,
    csrf: string | boolean | number
  ) {
    // go to URL
    const redirectUriParam = encodeURIComponent(redirectUri);
    const csrfParam = encodeURIComponent(csrf);
    window.location.href = apiService.url(
      `/v1/saml/login?redirect_uri=${redirectUriParam}&state=${csrfParam}`
    );
  }

  /** Returns Promise<Session> */
  async ssoLoginComplete(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    apiService: ApiService,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    redirectUri: string | boolean | number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    csrf: string | boolean | number
  ) {
    const sp = new URLSearchParams(window.location.search);
    // @ts-ignore
    const code = Buffer.from(sp.get('code'), 'base64').toString();
    const session = JSON.parse(code);
    // eslint-disable-next-line no-console
    console.log('SAML login complete', session);
    return session;
  }

  async ssoLogoutStart(
    apiService: ApiService,
    redirectUri: string | boolean | number,
    cookieName: string
  ) {
    // Redirect to the logout endpoint of the API
    const redirectUriParam = encodeURIComponent(redirectUri);
    window.location.href = apiService.url(
      `/v1/saml/logout?redirect_uri=${redirectUriParam}&cookie_name=${cookieName}`
    );
  }
}
