// Vendor
import { get } from 'lodash';

// Internal
import { DEFAULT_EMPTY_STATE } from 'common/constants';
import { CustomerAddress } from 'types/generated/CustomerAddress';

export const displayAddress = ({
  address1,
  address2,
  address3,
  city,
  state,
  zipCode,
}: CustomerAddress) =>
  formatAddress([address1, address2, address3, city, state, zipCode]);

export const displayAddressTypeAndAddress = (addresses: FormattedAddress[]) => {
  const { addressType, fullAddress } = addresses[0];
  const formatType = addressType ? `${formatAddressType(addressType)}: ` : '';
  return [{ fullAddress: `${formatType}${fullAddress}` }];
};

export const formatAddress = (arr: (string | undefined)[]) =>
  arr?.filter((a) => !!a && a.trim().length > 0).join(', ') || '';

type AddressType = 'primary' | 'shipTo' | 'billTo' | 'mailTo' | '';

export type FormattedAddress = {
  fullAddress: string;
  addressType: AddressType;
};

const getAddressType = (addressType: string): AddressType => {
  const currentTypes: Record<string, AddressType> = {
    primary: 'primary',
    shipTo: 'shipTo',
    billTo: 'billTo',
    mailTo: 'mailTo',
    '': '',
  };

  return currentTypes[addressType] ?? 'primary';
};

export const formatAddresses = (
  addresses: CustomerAddress[]
): FormattedAddress[] =>
  addresses.map((address) => ({
    fullAddress: displayAddress(address),
    addressType: getAddressType(address.addressType ?? 'primary'),
  }));

export const formatAddressType = (
  addressType: CustomerAddress['addressType'] = ''
) => {
  const addressTypeMapper = {
    billto: 'Bill To',
    mailto: 'Mail To',
    primary: 'Primary',
    shipto: 'Ship To',
  };

  return get(addressTypeMapper, addressType.toLowerCase(), DEFAULT_EMPTY_STATE);
};
