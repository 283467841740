// Vendor
import React, { FC } from 'react';

// Internal
import { KeyValueStorage } from 'common/helpers/keyValueStorage';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import Breadcrumb, { Props as BreadcrumbProps } from 'components/Breadcrumb';

export interface Props extends BreadcrumbProps {
  module: string;
  storage: KeyValueStorage<string>;
}

export const BreadcrumbWithSearch: FC<Props> = (props) => {
  const { module, storage, links, ...rest } = props;

  const { getPersistedSearch } = usePersistedSearchParams(
    { module, params: [] },
    storage
  );
  const currentSearch = getPersistedSearch();

  const moduleRegExp = new RegExp(`/${module.toLowerCase()}`);
  const updatedLinks = currentSearch
    ? (links || []).map((link) =>
        moduleRegExp.test(link.toLowerCase())
          ? `${link}?${currentSearch}`
          : link
      )
    : links;

  return <Breadcrumb links={updatedLinks} {...rest} />;
};
