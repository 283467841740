// Vendor
import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';

// Internal
import { Tooltip } from 'components/Tooltips';
import { formatDate, truncateDatetime } from 'common/helpers';

export interface DateColumnInterface {
  type: 'date';
  headerName?: string;
  size?: Size;
  dataField: string;
  format?: 'long' | 'short';
}

export interface Props {
  data: string;
  handleRowClick?: (data: any) => void;
  size?: Size;
  format?: 'long' | 'short';
}

const DateCell: FC<Props> = (props) => {
  const { data, format, handleRowClick, size = 'small' } = props;
  const dateString =
    format === 'short' ? truncateDatetime(data) : formatDate(data);

  return (
    <TableCell onClick={handleRowClick} size={size}>
      <Tooltip title={dateString}>{dateString}</Tooltip>
    </TableCell>
  );
};

export default DateCell;
