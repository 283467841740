export type WidthVariant = 'default' | 'full';

export const getWidth = (variant: WidthVariant) => {
  switch (variant) {
    case 'default':
      return '80%';
    case 'full':
    default:
      return '100%';
  }
};
