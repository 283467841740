// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Tooltip } from 'components/Tooltips';
import { IconInformation } from 'styles/images';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  header?: string;
  title?: any;
  values?: string[] | null;
}

const ValueTooltip: FC<Props> = (props) => {
  const { header, title, values } = props;

  return (
    <Tooltip
      disableHoverListener={false}
      placement='left'
      title={
        title || (
          <div className={cx('tooltipRoot')}>
            {header}
            {values?.map((value: any) => (
              <div className={cx('value')} key={value}>
                {value}
              </div>
            ))}
          </div>
        )
      }
    >
      <IconInformation className={cx('iconAlert')} fill='#525f7f' />
    </Tooltip>
  );
};

export default ValueTooltip;
