// Vendor
import React, { FC, Fragment } from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  crumbs: string[];
  handleBackBreadcrumb?: any;
  links?: string[];
}

const Breadcrumb: FC<Props> = (props) => {
  const { crumbs = [], handleBackBreadcrumb, links = [] } = props;

  return (
    <div className={cx('root')} data-testid='breadcrumbs'>
      {crumbs.map((crumb, i) =>
        i !== crumbs.length - 1 ? (
          <Fragment key={crumb}>
            <Link
              className={cx('lightBlue')}
              data-testid='link'
              onClick={() => {
                if (handleBackBreadcrumb) handleBackBreadcrumb(i);
              }}
              to={links[i]}
            >
              {crumb}
            </Link>
            <span className={cx('darkBlue')}>&nbsp;/&nbsp;</span>
          </Fragment>
        ) : (
          <span key={crumb} className={cx('darkBlue')}>
            {crumb}
          </span>
        )
      )}
    </div>
  );
};

export default Breadcrumb;
