// Vendor
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isUndefined, sortBy } from 'lodash';

// Internal
import {
  CustomerDetailsList,
  CustomerViewRow,
  ListType,
  QueryClassOfTradeResult,
} from 'query';
import { CustomerClassOfTrade } from 'types/generated/CustomerClassOfTrade';
import { Eligibility, Relationship } from 'types/generated/Relationship';
import { CustomerListMembership } from 'types/generated/CustomerListMembership';
import { isRosterOON } from './roster';

dayjs.extend(utc);

export const isListExpired = (membership: CustomerListMembership): boolean => {
  // The date such as "2020-03-15" will be transformed to UTC zoned format like "2020-03-15T00:00:00Z"
  // So to get back the original date, we'll revert the process
  const formatterStr = 'YYYY-MM-DD';
  const today = dayjs().format(formatterStr);
  const startDate = membership.startDate
    ? dayjs(membership.startDate).utc().format(formatterStr)
    : null;
  const endDate = membership.endDate
    ? dayjs(membership.endDate).utc().format(formatterStr)
    : null;

  // Note: because of the formatterStr, we can simply compare the string date
  const validStartDate = startDate === null || startDate <= today;
  const validEndDate = endDate === null || today <= endDate;

  return !(validStartDate && validEndDate);
};

export const filterExpiredLists = (
  memberships: CustomerDetailsList[]
): CustomerDetailsList[] =>
  memberships.filter((membership) => !isListExpired(membership));

/**
 * Duplicates can happen because the underlying join fetches data from the historical table of customer lists
 * We de-duplicate here until we properly implement a current view table of customer lists
 */
export const partitionUniqueCustomerLists = (
  memberships: CustomerDetailsList[]
) => {
  const partitions: { [key in ListType]: CustomerDetailsList[] } = {
    eligibility: [],
    gpo: [],
    program: [],
    contract: [],
  };

  memberships?.forEach((list) => {
    if (!list.list) return;
    const { type } = list.list;
    const partition = partitions[type];
    const hasList = partition.some(
      (e) =>
        e.listId === list.listId &&
        e.startDate === list.startDate &&
        e.endDate === list.endDate
    );

    if (!hasList) {
      partition.push(list);
    }
  });

  return partitions;
};

/**
 * Returns customer memberships with type program or contract (customer list)
 * @param memberships
 */
export const getUniqueCustomerPrograms = ({
  memberships,
}: {
  memberships: CustomerDetailsList[];
}): CustomerDetailsList[] => {
  const result: CustomerDetailsList[] = [];

  memberships.forEach((list) => {
    if (!list.list) return;
    const { type } = list.list;
    if (type === 'program' || type === 'contract') {
      const hasList = result.some(
        (e) =>
          e.listId === list.listId &&
          e.startDate === list.startDate &&
          e.endDate === list.endDate
      );

      if (!hasList) {
        result.push(list);
      }
    }
  });

  return result;
};

interface StatusGenerationParams {
  date?: string;
  endDate?: string | null;
  startDate?: string | null;
}

type Status = 'Inactive' | 'Expired' | 'Active' | null;

export const generateStatus = ({
  date = '',
  endDate = '',
  startDate = '',
}: StatusGenerationParams): Status => {
  if (endDate === '' && startDate === '') return null;
  const dateNow =
    !isUndefined(date) && dayjs(date).isValid()
      ? dayjs(date).valueOf()
      : dayjs.utc().valueOf();
  const startDateNum = startDate ? dayjs.utc(startDate).valueOf() : NaN;
  const endDateNum = endDate ? dayjs.utc(endDate).valueOf() : NaN;
  return dateNow <= startDateNum
    ? 'Inactive'
    : dateNow >= endDateNum
    ? 'Expired'
    : 'Active';
};

// Also in api/lib/router/export/customer/helpers.ts - refactor to ts-shared in the future
export const isActiveEntity = ({
  endDate,
  startDate,
}: StatusGenerationParams): Boolean => {
  if (!endDate && !startDate) return true;
  const dateNow = dayjs.utc().valueOf();
  const startDateNum = startDate ? dayjs.utc(startDate).valueOf() : NaN;
  const endDateNum = endDate ? dayjs.utc(endDate).valueOf() : NaN;
  if (dateNow < startDateNum) return false;
  if (dateNow > endDateNum) return false;
  return true;
};

export const getValidCustomerCOTFields = (
  customer: CustomerViewRow,
  cotData: QueryClassOfTradeResult | undefined
): string[] => {
  const queriedCots = cotData?.classesOfTrade ?? [];
  const dateNow = dayjs.utc().valueOf();
  const endDateNum = customer.endDate
    ? dayjs.utc(customer.endDate).valueOf()
    : 0;

  const classesOfTrade = [...(customer.classesOfTrade || [])];

  const pluckCOTFields = (cots: CustomerClassOfTrade[]) =>
    cots
      .map((cot) => {
        const id =
          queriedCots.find(
            (queriedCot) => cot?.classOfTradeId === queriedCot.id
          )?.id || '';
        return isRosterOON(customer.memberId) ? removeRosterIdPrefix(id) : id;
      })
      .filter((x) => x) ?? [];

  const sortedCOTs =
    classesOfTrade?.sort((a, b) => {
      const { endDate: endDateA } = a;
      const { endDate: endDateB } = b;
      const endDateANum = endDateA ? dayjs.utc(endDateA).valueOf() : 0;
      const endDateBNum = endDateB ? dayjs.utc(endDateB).valueOf() : 0;
      return endDateBNum - endDateANum;
    }) || [];

  const validCOTs = pluckCOTFields(sortedCOTs.filter(isActiveEntity));

  // only show most recent COT if the customer is endDated
  if (endDateNum < dateNow && endDateNum !== 0) {
    return pluckCOTFields([sortedCOTs[0]]);
  }

  return validCOTs;
};

/**
 * Remove the part of the ID up to and including the first underscore
 *
 * @example
 *   rosterID_customerID > customerID
 *   customer_id > id
 *   roster_id_customerID > id_customerID
 *
 * @param id
 */
export const removeRosterIdPrefix = (id: string): string => {
  const index = id.indexOf('_');
  if (index >= 0) {
    return id.substring(index + 1);
  }

  return id;
};

export const sortEligibilitiesByStartDate = (
  eligibilities: Eligibility[]
): Eligibility[] => sortBy(eligibilities, ['startDate', 'endDate']);

const gpoIdToName = (
  id: string,
  gpoList: { name: string; label: string }[]
): string => {
  const gpo = gpoList.find((gpo) => gpo.name === id);
  return gpo?.label || id;
};

export const getGpoAffiliations = (
  gpoList: { name: string; label: string }[],
  memberships: CustomerDetailsList[],
  relationships?: Relationship[]
): CustomerDetailsList[] => {
  const result: CustomerDetailsList[] = [];

  const addGpoToList = (list: CustomerDetailsList) => {
    const hasList = result.some(
      (e) =>
        e.listId === list.listId &&
        e.startDate === list.startDate &&
        e.endDate === list.endDate
    );

    if (!hasList) {
      result.push(list);
    }
  };

  memberships.forEach((list) => {
    if (!list.list) return;
    if (list.list.type === ListType.gpo) {
      addGpoToList({
        ...list,
        list: {
          id: list.list.id,
          name: gpoIdToName(list.list.name, gpoList),
          type: ListType.gpo,
          timestamp: list.list.timestamp,
        },
      });
    }
  });

  relationships?.forEach((relationship) => {
    if (relationship.relationship === 'gpo-affiliation') {
      addGpoToList({
        listId: relationship.to,
        startDate: relationship.startDate,
        endDate: relationship.endDate,
        list: {
          id: relationship.to,
          name: gpoIdToName(relationship.to, gpoList),
          type: ListType.gpo,
          timestamp: relationship.startDate || '',
        },
      });
    }
  });

  return result;
};
