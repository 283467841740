export interface KeyValueStorage<T> {
  get(key: string): T | null;
  set(key: string, value: T): void;
  clear(): void;
  remove(key: string): void;
}

export const SessionStorage: KeyValueStorage<string> = {
  get(key: string): string | null {
    return window.sessionStorage.getItem(key);
  },
  set(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
  },
  clear(): void {
    window.sessionStorage.clear();
  },
  remove(key: string): void {
    window.sessionStorage.removeItem(key);
  },
};
