// Internal
import { TableColumn } from 'components/Table';
import { manageRulesPath } from 'common/helpers';
import { TriggersType } from './types';

export const COLUMNS = (
  handleCheckbox: (id: string) => void
): TableColumn[] => [
  {
    displayTextFn: (data: any) => data.name,
    headerName: 'TRIGGER',
    size: 'small',
    type: 'link',
    urlFn: (data: any) => manageRulesPath(data.id),
  },
  {
    checkedFn: (data: any) => data.proposals || false,
    disabledFn: () => false,
    handleOnChangeFn: handleCheckbox,
    headerName: 'PROPOSALS?',
    idFn: (data: any) => data.id,
    size: 'small',
    type: 'checkBox',
  },
  {
    dataField: 'run',
    headerName: '',
    size: 'small',
    type: 'expanding',
  },
];

export const triggers: TriggersType[] = [
  {
    id: 'no-mapping-found',
    name: 'New or Updated GPO Customer with no mapping',
  },
  {
    id: 'mapping-update',
    name: 'New or Updated GPO Customer with mapping',
  },
  {
    id: 'auth-customer-update',
    name: 'Update from Identifier Data',
  },
  {
    id: 'local-customer-update',
    name: 'Update to Internal Customer',
  },
];
