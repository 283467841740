import Dexie from 'dexie';
import { Customer } from 'types/generated/Customer';
import { Database } from '../db';

export interface FlatfileCustomer extends Customer {
  processId: string;
}

const previousDb = new Dexie('flatfile-customers-db');
previousDb.version(1).stores({ customers: '[id+processId]' });

export const db = new Database<FlatfileCustomer>('customers', 1.1);
