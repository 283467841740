// Vendor
import React from 'react';

const IconCredit = () => (
  <svg width='19' height='15' viewBox='0 0 19 15'>
    <path
      fill='#00BC96'
      d='M18.467 3.035v-1.5c0-.828-.672-1.5-1.5-1.5h-15c-.828 0-1.5.672-1.5 1.5v1.5h18zm-1.5 11c.828 0 1.5-.672 1.5-1.5v-5.5h-18v5.5c0 .828.672 1.5 1.5 1.5h15zm-5.875-2h-4.25c-.206 0-.375-.169-.375-.375v-1.25c0-.206.169-.375.375-.375h4.25c.206 0 .375.169.375.375v1.25c0 .206-.169.375-.375.375zm-6 0h-2.25c-.206 0-.375-.169-.375-.375v-1.25c0-.206.169-.375.375-.375h2.25c.206 0 .375.169.375.375v1.25c0 .206-.169.375-.375.375z'
    />
  </svg>
);

export default IconCredit;
