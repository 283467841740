// Vendor
import get from 'lodash/get';

// Internal
import { AuthorityDatabases } from 'common/config';
import { DrawerEntry } from './LocalData';

export const getIdentifierData = (
  type: string,
  acceptableAuthData: AuthorityDatabases[]
): DrawerEntry[] => {
  const DEFAULT_IDENTIFIER_DATA = [
    ...(acceptableAuthData.includes(AuthorityDatabases.hin)
      ? [{ hash: 'hibcc', text: 'HIN', subEntries: [] }]
      : []),
    ...(acceptableAuthData.includes(AuthorityDatabases.dea)
      ? [{ hash: 'dea', text: 'DEA', subEntries: [] }]
      : []),
    ...(acceptableAuthData.includes(AuthorityDatabases['340b'])
      ? [{ hash: 'hrsa', text: '340B', subEntries: [] }]
      : []),
  ];

  const DIST = DEFAULT_IDENTIFIER_DATA;
  const GPO = DEFAULT_IDENTIFIER_DATA;
  const MFR = DEFAULT_IDENTIFIER_DATA;

  const dataMapper = { DIST, GPO, MFR };

  return get(dataMapper, type, DEFAULT_IDENTIFIER_DATA);
};
