// Vendor
import React from 'react';
import classnames from 'classnames/bind';
import { isEqual, orderBy, uniqBy } from 'lodash';

// Internal
import { ClassOfTrade, CustomerMappingCustomer, TradingPartner } from 'query';
import { AuthorityDatabases, NodeType } from 'common/config';
import { TableColumn, TableRow } from 'components/Table';
import { displayAddress, formatIdentifiers } from 'common/helpers';
import { IconRemove } from 'styles/images';
import {
  ADDRESS_COLUMN,
  COT_COLUMN,
  EXTERNAL_ID_COLUMN,
  IDENTIFIERS_COLUMN,
  INTERNAL_ID_COLUMN,
  NAME_COLUMN,
} from 'components/Cells/constants';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export const getCustomerMappingList = (
  mappings: any[],
  isLocalCustomer: boolean
) => {
  // Note: filter(c => c) to remove any `null` in the array
  const mappedCustomers = isLocalCustomer
    ? mappings
        ?.map((m) => ({
          ...m.toCustomer,
          sources: m.sources,
        }))
        .filter((c) => c)
    : mappings
        ?.map((m) => ({
          ...m.fromCustomer,
          sources: m.sources,
        }))
        .filter((c) => c);
  const orderedCustomers = orderBy(mappedCustomers, ['timestamp'], ['desc']);
  return uniqBy(orderedCustomers, (c) => c.id);
};

export const getRows = (
  toggleDialog: (toggle: boolean) => void,
  setTradingPartner: (tp: any) => void,
  isLocalCustomer: boolean,
  acceptableAuthData: AuthorityDatabases[],
  tpType: NodeType,
  customers?: CustomerMappingCustomer[],
  allCots?: ClassOfTrade[],
  partners?: TradingPartner[]
): TableRow[] => {
  if (!customers || customers.length === 0) return [];

  return customers.map((customer) => {
    const {
      addresses: rawAddresses,
      classesOfTrade,
      id,
      identifiers: rawIdentifiers,
      memberId,
      names,
      sources,
    } = customer;

    const addresses =
      rawAddresses?.map((address) => displayAddress(address)) ?? [];
    const partner = partners?.find((p) => p?.id === memberId);
    const memberName = partner?.name;
    const identifiers = formatIdentifiers(rawIdentifiers ?? []);

    const isExpandable =
      (names && names.length > 1) ||
      (acceptableAuthData.length > 0 && identifiers.length > 1) ||
      addresses.length > 1;

    const isMappingRemoveable =
      sources?.some((source) => isEqual(source, { source: 'manual' })) &&
      sources.length === 1 &&
      isLocalCustomer;

    const custom = isMappingRemoveable ? (
      <div
        onClick={() => {
          setTradingPartner({
            id,
            memberId,
            names,
          });
          toggleDialog(true);
        }}
      >
        <IconRemove className={cx('removeIcon')} />
      </div>
    ) : null;

    const distNodeAndMfrPartner =
      tpType === NodeType.DIST && partner?.type === 'mfr';

    if (isLocalCustomer) {
      return {
        isExpandable,
        data: {
          id,
          memberId,
          memberName,
          names,
          identifiers: acceptableAuthData.length > 0 ? identifiers : [],
          addresses,
          custom,
          noLinkData: distNodeAndMfrPartner || tpType === NodeType.GPO,
        },
        type: 'expanding',
      };
    }

    const cots =
      classesOfTrade?.map(
        (classOfTrade) =>
          allCots?.find((cot) => cot.id === classOfTrade.classOfTradeId)?.name
      ) || [];

    return {
      isExpandable,
      data: {
        id,
        names,
        identifiers: acceptableAuthData.length > 0 ? identifiers : [],
        addresses,
        cots,
        custom,
        noLinkData: distNodeAndMfrPartner || tpType === NodeType.GPO,
      },
      type: 'expanding',
    };
  });
};

export const getHeaders = (
  isLocalCustomer: boolean,
  acceptableAuthData: AuthorityDatabases[]
): TableColumn[] => {
  const custom: TableColumn = {
    headerName: '',
    dataField: 'custom',
    type: 'expanding',
    size: 'small',
  };

  const partner: TableColumn = {
    headerName: 'TRADING PARTNER',
    dataField: 'memberName',
    type: 'expanding',
    size: 'small',
  };

  const localHeaders: TableColumn[] = [
    EXTERNAL_ID_COLUMN,
    partner,
    NAME_COLUMN,
    ...(acceptableAuthData.length > 0 ? [IDENTIFIERS_COLUMN] : []),
    ADDRESS_COLUMN,
    custom,
  ];

  const rosterHeaders: TableColumn[] = [
    INTERNAL_ID_COLUMN,
    NAME_COLUMN,
    ...(acceptableAuthData.length > 0 ? [IDENTIFIERS_COLUMN] : []),
    ADDRESS_COLUMN,
    COT_COLUMN,
    custom,
  ];

  return isLocalCustomer ? localHeaders : rosterHeaders;
};
