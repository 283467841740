import {
  END_DATE_COLUMN,
  PRICE_COLUMN,
  PRICE_TYPE_COLUMN,
  START_DATE_COLUMN,
} from 'components/Cells/constants';
import { TableColumn } from 'components/Table';

export const PRICINGS_COLUMNS: TableColumn[] = [
  PRICE_TYPE_COLUMN,
  PRICE_COLUMN,
  START_DATE_COLUMN,
  END_DATE_COLUMN,
];
