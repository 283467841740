// Vendor
import React, { FC } from 'react';

// Internal
import { Document } from 'types/generated/Document';
import DocumentList from './DocumentList';
import { CustomerWithDocuments } from '../types';

interface Props {
  changeSection: (section: string) => void;
  currentPage: number;
  customer: CustomerWithDocuments;
  isHistoryView: boolean;
  paginationToggle: (page: number) => void;
  refetch: ({ offset }: { offset: number }) => void;
  setDocument: (doc: Document) => void;
  toggleDialog: (val: boolean) => void;
  toggleHistory: (val: boolean) => void;
  totalCount: number;
  updateDeletePayload: (payload: {
    manufacturer: string;
    documentType: string;
  }) => void;
}

const DocumentListContainer: FC<Props> = ({
  changeSection,
  currentPage,
  customer,
  isHistoryView,
  paginationToggle,
  refetch,
  setDocument,
  toggleDialog,
  toggleHistory,
  totalCount,
  updateDeletePayload,
}) => (
  <DocumentList
    changeSection={changeSection}
    currentPage={currentPage}
    customer={customer}
    isHistoryView={isHistoryView}
    paginationToggle={paginationToggle}
    refetch={refetch}
    setDocument={setDocument}
    toggleDialog={toggleDialog}
    toggleHistory={toggleHistory}
    totalCount={totalCount}
    updateDeletePayload={updateDeletePayload}
  />
);

export default DocumentListContainer;
