// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';

// Internal
import { ChargebackRequestRow } from 'query';
import { contractPath, formatCurrency, truncateDatetime } from 'common/helpers';
import { convertDollarValueToAPIValue } from 'common/helpers/currency';
import { composeValidators, required } from 'common/validation';
import PriceField from 'components/PriceField';
import TextField from 'components/TextField';
import { AuditedData } from 'types/generated/AuditedData';
import ClaimHistory, { Action } from '../ClaimHistory';
import { getHistory, getIcon, renderCardData, renderField } from '../helpers';
import ChargebackField from '../ChargebackField';

// Styles
import styles from '../chargeback.module.css';

const cx = classnames.bind(styles);

export interface Props {
  chargeback: ChargebackRequestRow;
  status: string;
  isEditing: boolean;
  handleSubmit?: (data: AuditedData) => void;
  handleCancel?: () => void;
}

export interface FormProps {
  contractId: string;
  contractPrice: string;
  wacPrice: string;
}

const Audit: FC<Props> = ({
  chargeback,
  status,
  isEditing,
  handleSubmit,
  handleCancel,
}) => {
  const history: Action[] = getHistory(chargeback);

  const onSubmit = async (values: FormProps) => {
    if (handleSubmit) {
      handleSubmit({
        contractId: values.contractId,
        contractPrice: convertDollarValueToAPIValue(values.contractPrice),
        wacPrice: convertDollarValueToAPIValue(values.wacPrice),
      });
    }
  };

  return (
    <Form
      data-testid='audit-form'
      initialValues={{
        contractId: chargeback.contract_id,
        contractPrice: chargeback.contract_price / 1_000_000,
        wacPrice: chargeback.wac_price / 1_000_000,
      }}
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          <div
            className={cx('row')}
            data-testid={`audit:chargeback:${chargeback.id}`}
          >
            <div
              className={cx('card', 'column', 'yellow')}
              data-testid='chargebackCard'
            >
              {renderField('Adjudication Results', status, true)}
              {renderCardData(status)}
            </div>
            <div
              className={cx('column', 'container')}
              data-testid='chargebackDetails'
            >
              {/* Rebill */}
              <div data-testid={`audit:rebill:${chargeback.id}`}>
                <div className={cx('row', 'spaceBetween')}>
                  <div className={cx('row')} style={{ width: '100%' }}>
                    <div
                      className={cx('iconBackground', 'yellowOpacity')}
                      data-testid='chargebackIcon'
                    >
                      {getIcon(status, 'Rebill')}
                    </div>
                    <header className={cx('header')}>
                      Rebill {chargeback.id}
                    </header>
                  </div>
                </div>
                <div className={cx('leftMargin', 'row')}>
                  {renderField('Invoice ID', chargeback.sale.id)}
                  {renderField('Debit Memo', chargeback.debit_memo_id)}
                  {renderField(
                    'Submission Date',
                    truncateDatetime(chargeback.timestamp)
                  )}
                </div>
                <div className={cx('leftMargin', 'row')}>
                  {/* Contract ID field */}
                  {isEditing && (
                    <ChargebackField
                      title='Contract ID'
                      isCardField={false}
                      testId='contract_id_field'
                    >
                      <Field
                        // @ts-ignore
                        component={TextField}
                        name='contractId'
                        validate={composeValidators(required)}
                      />
                    </ChargebackField>
                  )}
                  {!isEditing &&
                    renderField(
                      'Contract ID',
                      chargeback.contract_id,
                      false,
                      true,
                      contractPath(
                        chargeback.contract_member_id ?? 0,
                        chargeback.contract_id ?? ''
                      )
                    )}
                  {renderField('Quantity', `${chargeback.sale.quantity}`)}
                  {renderField('UoM', chargeback.sale.unit_of_measure)}
                </div>
                <div className={cx('leftMargin', 'row')}>
                  {/* WAC Price field */}
                  {isEditing && (
                    <ChargebackField
                      title='WAC Price'
                      isCardField={false}
                      testId='wac_price_field'
                    >
                      <Field
                        // @ts-ignore
                        component={PriceField}
                        widthVariant='full'
                        name='wacPrice'
                        validate={composeValidators(required)}
                      />
                    </ChargebackField>
                  )}
                  {!isEditing &&
                    renderField(
                      'WAC Price',
                      formatCurrency(chargeback.wac_price)
                    )}

                  {/* Contract Price field */}
                  {isEditing && (
                    <ChargebackField
                      title='Contract Price'
                      isCardField={false}
                      testId='contract_price_field'
                    >
                      <Field
                        // @ts-ignore
                        component={PriceField}
                        widthVariant='full'
                        name='contractPrice'
                        validate={composeValidators(required)}
                      />
                    </ChargebackField>
                  )}
                  {!isEditing &&
                    renderField(
                      'Contract Price',
                      formatCurrency(chargeback.contract_price)
                    )}

                  {renderField(
                    'Claim Amount',
                    formatCurrency(chargeback.submitted_amount)
                  )}
                </div>
              </div>
              {/* Credit */}
              <div data-testid={`audit:credit:${chargeback.id}`}>
                <div className={cx('row', 'spaceBetween')}>
                  <div className={cx('row')} style={{ width: '100%' }}>
                    <div
                      className={cx('iconBackground', 'yellowOpacity')}
                      data-testid='chargebackIcon'
                    >
                      {getIcon(status, 'Credit')}
                    </div>
                    <header className={cx('header')}>
                      Credit {chargeback.id}
                    </header>
                  </div>
                </div>
                <div className={cx('leftMargin', 'row')}>
                  {renderField('Invoice ID', chargeback.sale.id)}
                  {renderField('Debit Memo', chargeback.debit_memo_id)}
                  {renderField(
                    'Submission Date',
                    truncateDatetime(chargeback.timestamp)
                  )}
                </div>
                <div className={cx('leftMargin', 'row')}>
                  {renderField(
                    'Contract ID',
                    chargeback.contract_id,
                    false,
                    true,
                    contractPath(
                      chargeback.contract_member_id ?? 0,
                      chargeback.contract_id ?? ''
                    )
                  )}
                  {renderField('Quantity', `${-chargeback.sale.quantity}`)}
                  {renderField('UoM', chargeback.sale.unit_of_measure)}
                </div>
                <div className={cx('leftMargin', 'row')}>
                  {renderField(
                    'WAC Price',
                    formatCurrency(chargeback.wac_price)
                  )}
                  {renderField(
                    'Contract Price',
                    formatCurrency(chargeback.contract_price)
                  )}
                  {renderField(
                    'Claim Amount',
                    formatCurrency(-chargeback.submitted_amount)
                  )}
                </div>
              </div>

              {/* Buttons */}
              {isEditing && (
                <div className={cx('paragraph')}>
                  <Button
                    data-testid='audit_submit_button'
                    variant='contained'
                    color='primary'
                    style={{ marginTop: '8px', float: 'right' }}
                    onClick={handleSubmit}
                    disabled={submitting || hasValidationErrors}
                  >
                    Submit
                  </Button>
                  <Button
                    data-testid='audit_cancel_button'
                    variant='outlined'
                    color='secondary'
                    style={{
                      marginRight: '8px',
                      marginTop: '8px',
                      float: 'right',
                    }}
                    onClick={() => (handleCancel ? handleCancel() : () => {})}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                </div>
              )}

              <div className={cx('leftMargin', 'row', 'divider')}>
                <ClaimHistory actions={history} />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default Audit;
