import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Styles
import styles from '../chargeback.module.css';

const cx = classnames.bind(styles);

interface FieldsProps {
  children: any;
  isCardField: boolean;
  title: string;
  testId: string;
}

const ChargebackField: FC<FieldsProps> = ({
  children,
  isCardField,
  title,
  testId,
}) => {
  const cardField = isCardField;
  const ellipsis = !isCardField;
  const letterSpacing = isCardField;
  const white = isCardField;

  return (
    <div className={cx('column', 'propertyGroup', { cardField })}>
      <div
        className={cx('key', 'row', { ellipsis }, { letterSpacing }, { white })}
        data-testid={`${testId}-key`}
      >
        {title}
      </div>
      <div
        className={cx('column', 'value', { white })}
        data-testid={`${testId}-value`}
      >
        {children}
      </div>
    </div>
  );
};

export default ChargebackField;
