// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Internal
import { QUERY_PRODUCT_LIST_BY_ID, QueryProductListResult } from 'query';
import { IconSystemError } from 'styles/images';
import { useAppBarSetTitle, useTradingPartnerConfig } from 'hooks';
import { BreadcrumbWithSearch } from 'components/BreadcrumbWithSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import { Button } from 'components';
import { productListPath } from 'common/helpers';
import Overview from './components/ProductListOverview';
import ViewProductsTable from './components/ViewProductsTable';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const ProductListDetails: FC = () => {
  const navigate = useNavigate();

  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const { id } = useParams() as { id: string };

  if (!memberId) {
    // We should fail to avoid invalid associations
    throw new Error('Member ID must be present.');
  }

  useAppBarSetTitle(`Product List ID: ${id}`);

  const { data, loading, error } = useQuery<QueryProductListResult>(
    QUERY_PRODUCT_LIST_BY_ID,
    {
      variables: {
        id,
        memberId,
      },
      pollInterval: SHORT_POLL_INTERVAL,
    }
  );

  const productList = data?.productList;

  if (loading) return null;

  if (error || !productList) {
    return (
      <div className={cx('errorCode')}>
        <IconSystemError />
      </div>
    );
  }

  return (
    <div className={cx('root')}>
      <BreadcrumbWithSearch
        crumbs={['Product Lists', id]}
        links={['/product-lists']}
        module='product-lists'
        storage={SessionStorage}
      />
      <section className={cx('row', 'toolbar')}>
        <Button
          dataTestId='updateProductList'
          label='Update'
          onClick={() =>
            navigate(`${productListPath(id)}/update`, { replace: true })
          }
          style={{ minWidth: 90 }}
        />
      </section>
      <Overview detailsInfo={productList} />
      <ViewProductsTable id={id} memberId={memberId} />
    </div>
  );
};

export default ProductListDetails;
