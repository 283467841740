// Vendor
import React, { FC, Fragment, useState } from 'react';
import { connect } from 'react-redux';

// Internal
import { Document } from 'types/generated/Document';
import AddDocument from './AddDocument';
import DeleteDocument from './DeleteDocument';
import DocumentDetails from './DocumentDetails';
import DocumentList from './DocumentList';
import EditDocument from './EditDocument';
import { CustomerWithDocuments } from './types';

interface Props {
  customer: CustomerWithDocuments;
  isHistoryView: boolean;
  memberId: number;
  refetch: () => void;
  toggleHistory: (val: boolean) => void;
}

const Documents: FC<Props> = ({
  customer,
  isHistoryView,
  memberId,
  toggleHistory,
  refetch,
}) => {
  const [currentPage, paginationToggle] = useState(1);
  const [currentSection, changeSection] = useState('documentList');
  const [deletePayload, updateDeletePayload] = useState(
    {} as {
      documentType: string;
      manufacturer: string;
    }
  );
  const [selectedDocument, setDocument] = useState({} as Document);
  const [showDialog, toggleDialog] = useState(false);

  const documents = customer.documents || [];
  const totalCount = documents.length;

  return (
    <>
      {showDialog && selectedDocument && (
        <DeleteDocument
          changeSection={changeSection}
          customer={customer}
          deletePayload={deletePayload}
          memberId={memberId}
          selectedDocument={selectedDocument}
          toggleDialog={toggleDialog}
        />
      )}
      {currentSection === 'addDocument' && (
        // @ts-ignore
        <AddDocument
          changeSection={changeSection}
          customer={customer}
          memberId={memberId}
        />
      )}
      {currentSection === 'documentDetails' && selectedDocument && (
        <DocumentDetails
          changeSection={changeSection}
          customer={customer}
          isHistoryView={isHistoryView}
          selectedDocument={selectedDocument}
          setDocument={setDocument}
          toggleDialog={toggleDialog}
          updateDeletePayload={updateDeletePayload}
        />
      )}
      {currentSection === 'documentList' && (
        <DocumentList
          changeSection={changeSection}
          currentPage={currentPage}
          customer={customer}
          isHistoryView={isHistoryView}
          paginationToggle={paginationToggle}
          refetch={refetch}
          setDocument={setDocument}
          toggleDialog={toggleDialog}
          toggleHistory={toggleHistory}
          totalCount={totalCount}
          updateDeletePayload={updateDeletePayload}
        />
      )}
      {currentSection === 'editDocument' && (
        <EditDocument
          changeSection={changeSection}
          customer={customer}
          memberId={memberId}
          selectedDocument={selectedDocument}
        />
      )}
    </>
  );
};

export default connect()(Documents);
