// Vendor
import React, { FC } from 'react';
import { MenuItem, TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';

// Internal
import Select from 'components/Select';
import { composeValidators } from 'common/validation';
import { extractFieldFromName, getSelectLabel } from './service';

import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface SelectInputColumnInterface {
  name: (index: number) => string;
  type: 'selectInput';
  items: {
    disabled?: boolean;
    label: string;
  }[];
  handleOnChangeFn: (inputName: string, itemLabel: string) => void;
  // if there is some nested form data that we need to map through,
  // we can get direct access to it using `specificFormData` prop
  specificFormData?: Record<string, any>[];
  validation?: any[];
  disabledFn?: (formData: any) => boolean;
  size?: Size;
  placeholder?: string;
}

export type SelectPricingTypeInputColumnInterface =
  SelectInputColumnInterface & {
    headerName: 'PRICE TYPE';
  };

export type Props = Omit<SelectInputColumnInterface, 'type' | 'name'> & {
  name: string;
  formData: any;
  placeholder?: string;
};

const SelectInputCell: FC<Props> = (props) => {
  const {
    name,
    handleOnChangeFn,
    formData,
    items,
    validation = [],
    size = 'small',
    placeholder = '',
    disabledFn = () => false,
    specificFormData = {},
  } = props;

  const cellSizeClass = size === 'small' ? cx('cell-small') : cx('cell-medium');

  const isDisabled = disabledFn(formData);

  const fieldName = extractFieldFromName(name);

  return (
    <TableCell size={size} className={`${cx('cell')} ${cx(cellSizeClass)}`}>
      <Field
        // @ts-ignore
        component={Select}
        className={cx('root')}
        disabled={isDisabled}
        label={getSelectLabel(
          fieldName,
          placeholder,
          formData,
          specificFormData
        )}
        name={name}
        id={name}
        validate={composeValidators(...validation)}
      >
        {items.map(({ disabled = false, label }) => (
          <MenuItem
            disabled={disabled}
            key={label}
            value={label}
            onClick={() => {
              handleOnChangeFn(name, label);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Field>
    </TableCell>
  );
};

export default SelectInputCell;
