// Vendor
import React from 'react';
import { ConnectionLineComponentProps } from 'reactflow';

export const Line = ({
  fromX,
  fromY,
  toX,
  toY,
}: ConnectionLineComponentProps) => (
  <g>
    <path
      fill='none'
      stroke='#6095ec'
      strokeWidth={1.5}
      d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
    />
    <circle
      cx={toX}
      cy={toY}
      fill='#fff'
      r={3}
      stroke='#6095ec'
      strokeWidth={1.5}
    />
  </g>
);

export default Line;
