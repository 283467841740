import Dexie, { Table } from 'dexie';
import { EntityWithId } from 'flatfile-import/application/publisher/publisher-service';

export interface FlatfileEntity extends EntityWithId {
  processId: string;
}

export interface LocalDatabase<E extends FlatfileEntity> extends Dexie {
  entities: Table<E, [string, string]>;
}

export class Database<E extends FlatfileEntity>
  extends Dexie
  implements LocalDatabase<E>
{
  entities!: Table<E, [string, string]>;

  constructor(entityName: string, version: number = 1) {
    super(`flatfile-${entityName}-db`);
    this.version(version).stores({ entities: '[id+processId]' });
  }
}
