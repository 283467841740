// Vendor
import React, { FC } from 'react';
import { TableRow as MUITableRow, TableCell } from '@material-ui/core';

// Internal
import Table, { TableColumn, TableRow } from '../Table';

export interface NestedTableRowInterface {
  type: 'table';
  columns: TableColumn[];
  rows: TableRow[];
  isLoading: boolean;
  emptyText: string;
  colSpan: number;
}

export interface Props {
  columns: TableColumn[];
  rows: TableRow[];
  isLoading: boolean;
  emptyText: string;
  colSpan: number;
}

const NestedTableRow: FC<Props> = (props) => {
  const { columns, rows, isLoading, emptyText, colSpan } = props;

  return (
    <MUITableRow style={{ width: '100%' }}>
      <TableCell colSpan={colSpan}>
        <Table
          columns={columns}
          rows={rows}
          isLoading={isLoading}
          emptyText={emptyText}
        />
      </TableCell>
    </MUITableRow>
  );
};

export default NestedTableRow;
