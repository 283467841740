// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import Paper from '@material-ui/core/Paper';

// Internal
import { KeyValuePair } from 'components';
import { CreditMemoRow } from 'query';
import { formatCurrency, formatDate } from 'common/helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  creditMemo: CreditMemoRow;
  tradingPartnerName?: string;
  isMFR: boolean;
}

const CreditMemoDetails: FC<Props> = ({
  creditMemo,
  tradingPartnerName,
  isMFR,
}) => (
  <Paper className={cx('paperMargin')}>
    <div className={cx('column', 'paperContent')}>
      <div className={cx('content')}>
        <KeyValuePair title='Credit Memo' value={creditMemo.id} width='20%' />
        <KeyValuePair
          title={isMFR ? 'Distributor' : 'Manufacturer'}
          value={tradingPartnerName}
          width='20%'
        />
        <KeyValuePair
          title='Credit Amount'
          value={formatCurrency(creditMemo.credit_amount)}
          width='20%'
        />
        <KeyValuePair
          title='Debit Memo'
          value={creditMemo.debit_memo_id}
          width='20%'
        />
        <KeyValuePair
          title='Creation Date'
          value={formatDate(creditMemo.created_on)}
          width='20%'
        />
      </div>
    </div>
  </Paper>
);

export default CreditMemoDetails;
