// Vendor
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { IconExit } from 'styles/images';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  title: string;
  toggleDialog: () => void;
}

const Title: FC<Props> = ({ title, toggleDialog }) => (
  <DialogTitle>
    <div className={cx('root')}>
      <h1 className={cx('title')}>{title}</h1>
      <button
        className={cx('closeButton')}
        onClick={toggleDialog}
        type='button'
      >
        <IconExit />
      </button>
    </div>
  </DialogTitle>
);

export default Title;
