// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';

// Internal
import { Tooltip } from 'components/Tooltips';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface LinkColumnInterface {
  headerName?: string;
  displayTextFn: (data: any) => string;
  urlFn: (data: any) => string;
  type: 'link';
  size?: Size;
}

export interface Props {
  displayTextFn: (data: any) => string;
  urlFn: (data: any) => string;
  data: any;
  size?: Size;
}

const LinkCell: FC<Props> = (props) => {
  const { displayTextFn, urlFn, data, size = 'small' } = props;

  const url = urlFn(data);
  const displayText = displayTextFn(data);

  return (
    <TableCell size={size}>
      <Tooltip title={displayText}>
        {data.noLinkData ? (
          displayText
        ) : (
          <Link className={cx('link')} to={url} data-testid='linkCellLink'>
            {displayText}
          </Link>
        )}
      </Tooltip>
    </TableCell>
  );
};

export default LinkCell;
