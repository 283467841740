// Vendor
import dayjs from 'dayjs';

// Internal
import { CSV_TYPE, onDownload } from 'services/export';
import { ExportLoading } from './container';

type LoadType = 'headerLoading' | 'pricingLoading' | 'customersLoading';

const loadTypesLabelMap: Record<LoadType, string> = {
  headerLoading: 'contract header',
  pricingLoading: 'contract pricing',
  customersLoading: 'contract customer',
};

export const checkForStatus = (
  loadType: LoadType,
  api: any,
  fileName: string,
  exportId: string,
  isExportLoading: boolean,
  snackbar: {
    open: (payload: any) => void;
    close: () => void;
  },
  toggleExportLoading: (isExportLoading: false) => void
) =>
  api?.exportStatus(exportId).then(({ data }: { data: any }) => {
    const { status } = data;
    if (status === 'complete') {
      const { downloadPath } = data.result;
      toggleExportLoading(false);
      api?.exportDownload(downloadPath).then(({ data }: { data: any }) => {
        if (data && data.size) {
          onDownload(data, fileName, CSV_TYPE);

          if (!isExportLoading) {
            snackbar.open({
              message: `All set! Your ${loadTypesLabelMap[loadType]} export file is ready and downloading.`,
              variant: 'success',
            });
          }
        }
      });
    } else if (status === 'error') {
      toggleExportLoading(false);
      snackbar.open({
        message: `An error occurred while exporting your ${loadTypesLabelMap[loadType]} export file.`,
        variant: 'error',
      });
    } else {
      window.setTimeout(
        () =>
          checkForStatus(
            loadType,
            api,
            fileName,
            exportId,
            isExportLoading,
            snackbar,
            toggleExportLoading
          ),
        500
      );
    }
  });

type setExportLoadingFn = (
  exportLoading:
    | ExportLoading
    | ((exportLoading: ExportLoading) => ExportLoading)
) => void;

export const handleExport = (
  api: any,
  customersWhere: Object,
  exportsLoading: ExportLoading,
  payload: {
    contractId: string;
    memberId: string;
    timestampHour: string;
  },
  pricingsWhere: Object,
  snackbar: {
    open: (payload: any) => void;
    close: () => void;
  },
  toggleExportLoading: setExportLoadingFn
) => {
  const { contractId, timestampHour } = payload;

  toggleExportLoading({
    customersLoading: true,
    headerLoading: true,
    pricingLoading: true,
  });
  const isExportLoading = Object.values(exportsLoading).includes(true);

  const steps = [
    {
      csvSuffix: 'HEADER',
      first: true,
      generateApi: api?.exportContractHeaderGenerate,
      loadType: 'headerLoading' as LoadType,
      payload,
    },
    {
      csvSuffix: 'PRICING',
      generateApi: api?.exportContractPricingGenerate,
      loadType: 'pricingLoading' as LoadType,
      payload: {
        ...payload,
        where: pricingsWhere,
      },
    },
    {
      csvSuffix: 'CUSTOMERS',
      generateApi: api?.exportContractCustomersGenerate,
      loadType: 'customersLoading' as LoadType,
      payload: {
        ...payload,
        where: customersWhere,
      },
    },
  ];

  steps.forEach((step) => {
    const {
      csvSuffix,
      generateApi,
      first,
      loadType,
      payload: stepPayload,
    } = step;

    generateApi(stepPayload).then(({ data }: { data: any }) => {
      const { exportId, status } = data;

      toggleExportLoading((exportState) => ({
        ...exportState,
        [loadType]: status === 'processing',
      }));

      if (first) {
        snackbar.open({
          message: `Hang tight! Your contract export files are being prepared for download. This might take a few minutes and we'll notify you when it's ready. Leaving or refreshing the page will cancel the export.`,
          variant: 'info',
        });
      }

      const fileName = `${contractId}_${dayjs(timestampHour)
        .utc()
        .format('YYYY_MM_DD')}_${csvSuffix}.csv`;

      checkForStatus(
        loadType,
        api,
        fileName,
        exportId,
        isExportLoading,
        snackbar,
        (headerLoading: boolean) => {
          toggleExportLoading((exportState) => ({
            ...exportState,
            [loadType]: headerLoading,
          }));
        }
      );
    });
  });
};
