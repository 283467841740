// Vendor
import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';
import { FormApi } from 'final-form';
import { Field } from 'react-final-form';
import classnames from 'classnames/bind';

// Internal
import DatePicker from 'components/DatePicker';
import { composeValidators } from 'common/validation';
import { truncateDatetime } from 'common/helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface DateInputColumnInterface {
  fallbackDate: string;
  disabledFn?: (data: any) => boolean;
  form?: FormApi;
  format: 'short';
  handleOnChangeFn?: (
    id: string,
    updatedDate: string,
    visualOrder: number
  ) => void;
  headerName: string;
  hiddenFn?: (data: any) => boolean;
  maxDate?: Date;
  minDate?: Date;
  name?: (index: any) => string;
  nameFn?: (data: any) => string;
  placeholder?: string;
  size?: Size;
  type: 'dateInput';
  validation: any[];
}

export type Props = Omit<
  DateInputColumnInterface,
  'format' | 'headerName' | 'type' | 'name'
> & { name: string | undefined; formData: any; placeholder?: string };

const DateInputCell: FC<Props> = (props) => {
  const {
    fallbackDate,
    disabledFn = () => false,
    formData,
    hiddenFn = () => {},
    maxDate,
    minDate,
    name,
    nameFn,
    placeholder = '',
    size = 'small',
    validation = [],
  } = props;

  const isDisabled = disabledFn(formData) || false;
  const isHidden = hiddenFn(formData) || false;

  return (
    <TableCell size={size} className={cx('dateInputCell')}>
      {!isHidden && (
        <Field
          fallbackDate={fallbackDate}
          format={(value) => (value ? truncateDatetime(value) : '')}
          // @ts-ignore
          component={DatePicker}
          maxDate={maxDate}
          minDate={minDate}
          name={nameFn ? nameFn(formData) : name || ''}
          disabled={isDisabled}
          id={name}
          placeholder={placeholder}
          validate={composeValidators(...validation)}
        />
      )}
    </TableCell>
  );
};

export default DateInputCell;
