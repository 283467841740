// Vendor
import { constant, pipe } from 'fp-ts/function';
import { fromNullable, getOrElse, map } from 'fp-ts/Option';

// Internal
import { Customer } from 'types/generated/Customer';
import { UpsertCustomer } from 'types/generated/UpsertCustomer';
import { Attribute } from 'types/generated/Attribute';
import { compact } from 'fp-ts/Array';
import { fromNullableOrEmptyString } from '../../../../common/fpUtils';
import { PublishAdapter } from '../publish-adapter';

class UpsertCustomerPublishAdapter
  implements PublishAdapter<Customer, UpsertCustomer>
{
  convert(customer: Customer): UpsertCustomer {
    const endDate = pipe(
      fromNullableOrEmptyString(customer.endDate?.trim()),
      getOrElse(constant<string | undefined>(undefined))
    );

    // Filter empty attributes
    const attributes = pipe(
      fromNullable(customer.attributes),
      map((attrs) =>
        compact(
          attrs.map((attr) =>
            pipe(fromNullableOrEmptyString(attr.value), map(constant(attr)))
          )
        )
      ),
      getOrElse(constant<Attribute[]>([]))
    );

    return {
      ...customer,
      endDate,
      attributes,
    };
  }
}

export default new UpsertCustomerPublishAdapter();
