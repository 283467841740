// Internal
import { productPath } from 'common/helpers';
import {
  DESCRIPTION_COLUMN,
  END_DATE_COLUMN,
  PRODUCT_ID_TYPE_COLUMN,
  START_DATE_COLUMN,
  UOM_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { TableColumn } from 'components/Table';

export const COLUMNS: TableColumn[] = [
  {
    headerName: 'PRODUCT ID',
    type: 'link',
    size: 'small',
    displayTextFn: (data: any) => data.externalId,
    urlFn: (data: any) => productPath(data.id),
  },
  PRODUCT_ID_TYPE_COLUMN,
  DESCRIPTION_COLUMN,
  UOM_COLUMN,
  START_DATE_COLUMN,
  END_DATE_COLUMN,
  UPDATED_AT_COLUMN,
];
