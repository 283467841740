import { wrapSearchText } from 'query';

export interface Filters {
  showValid: boolean;
  showInvalid: boolean;
  expiredWithinRoster?: boolean;
  activeWithinRoster?: boolean;
  expiredWithinProgram?: boolean;
  activeWithinProgram?: boolean;
  selectedPrograms: string[];
  allProgramsSelected?: boolean;
  selectedDate: Date;
}

/**
 * Translates the customer filters to a where clause for Hasura query
 *
 * @param filters
 * @param filtersActive
 * @param searchText
 * @param memberId
 */
export const filtersToQuery = (
  filters: Filters,
  filtersActive: boolean,
  searchText: string | undefined = undefined,
  memberId: number | undefined = undefined
) => {
  // Default where clause
  const whereObject: any = {};

  if (memberId !== undefined && memberId !== null) {
    whereObject.member_id = { _eq: memberId };
  }

  if (searchText !== undefined && searchText !== null) {
    whereObject._search_text = { _ilike: wrapSearchText(searchText) };
  }

  // Return default if no filters are selected
  if (!filtersActive) {
    return whereObject;
  }

  const { selectedDate } = filters;
  const conditionsArray = [];

  // Valid and invalid filters
  if (filters.showValid && !filters.showInvalid) {
    conditionsArray.push({ has_errors: { _neq: true } });
  } else if (!filters.showValid && filters.showInvalid) {
    conditionsArray.push({ has_errors: { _eq: true } });
  } else if (!filters.showValid && !filters.showInvalid) {
    conditionsArray.push({ has_errors: { _in: [] } });
  }

  // Expired and active within roster filters
  if (filters.activeWithinRoster && !filters.expiredWithinRoster) {
    conditionsArray.push({ start_date: { _lte: selectedDate } });
    conditionsArray.push({
      _or: [
        { end_date: { _is_null: true } },
        { end_date: { _gte: selectedDate } },
      ],
    });
  } else if (!filters.activeWithinRoster && filters.expiredWithinRoster) {
    conditionsArray.push({
      _or: [
        { start_date: { _gt: selectedDate } },
        { end_date: { _lt: selectedDate } },
      ],
    });
  } else if (!filters.activeWithinRoster && !filters.expiredWithinRoster) {
    conditionsArray.push({ start_date: { _is_null: true } });
    conditionsArray.push({ end_date: { _is_null: true } });
  }

  if (filters.selectedPrograms) {
    // Get a list of selected programs
    const programList = filters.selectedPrograms.filter(
      (value) => value !== 'noProgram'
    );
    const noProgramSelected = filters.selectedPrograms.includes('noProgram');

    // Program filters
    if (!filters.allProgramsSelected) {
      if (!noProgramSelected) {
        // No programs are selected and No program option is also not selected
        if (programList.length === 0) {
          whereObject.id = { _is_null: true };
          return whereObject;
        }
      }

      if (programList.length > 0) {
        if (noProgramSelected) {
          conditionsArray.push({
            _or: [
              { _not: { list_memberships: {} } },
              { list_memberships: { list_id: { _in: programList } } },
            ],
          });
        } else {
          conditionsArray.push({
            list_memberships: { list_id: { _in: programList } },
          });
        }
      } else if (noProgramSelected) {
        conditionsArray.push({ _not: { list_memberships: {} } });
      }
    }
  }

  getProgramStatusWhere(conditionsArray, filters, selectedDate, true);

  if (conditionsArray.length > 0) {
    whereObject._and = conditionsArray;
  }

  return whereObject;
};

export const getProgramStatusWhere = (
  conditionsArray: any[],
  filters: Filters,
  selectedDate: Date,
  isCustomerWhere?: boolean
) => {
  // Expired and active within program filters
  if (filters.activeWithinProgram && !filters.expiredWithinProgram) {
    conditionsArray.push(
      addListMemberships(
        {
          _or: [
            { start_date: { _is_null: true } },
            { start_date: { _lte: selectedDate } },
          ],
        },
        isCustomerWhere
      )
    );
    conditionsArray.push(
      addListMemberships(
        {
          _or: [
            { end_date: { _is_null: true } },
            { end_date: { _gte: selectedDate } },
          ],
        },
        isCustomerWhere
      )
    );
  } else if (!filters.activeWithinProgram && filters.expiredWithinProgram) {
    conditionsArray.push(
      addListMemberships(
        {
          _or: [
            { start_date: { _gte: selectedDate } },
            { end_date: { _lte: selectedDate } },
          ],
        },
        isCustomerWhere
      )
    );
  } else if (!filters.activeWithinProgram && !filters.expiredWithinProgram) {
    conditionsArray.push(
      addListMemberships({ start_date: { _is_null: true } }, isCustomerWhere)
    );
    conditionsArray.push(
      addListMemberships({ end_date: { _is_null: true } }, isCustomerWhere)
    );
  }
};

const addListMemberships = (query: any, isCustomerWhere?: boolean) => {
  if (isCustomerWhere) return { list_memberships: query };
  return query;
};
