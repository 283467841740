// Vendor
import DialogContent from '@material-ui/core/DialogContent';
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { ApolloError } from '@apollo/client';

// Internal
import Dialog from 'components/Dialog';
import { Pagination, SearchBar } from 'components';
import CustomersListLite from 'pages/Customers/List/ListLite/container';
import { CustomerViewRow } from 'query';
import { IconSystemError } from 'styles/images';
import { NUMBER_PER_PAGE } from 'common/constants';

// Styles
import styles from './SearchDialog.module.css';
const cx = classnames.bind(styles);

interface Props {
  countLoading: boolean;
  currentPage: number;
  customers: CustomerViewRow[];
  error?: ApolloError;
  loading: boolean;
  memberId?: number;
  refetch: ({ offset }: { offset: number }) => void;
  searchText: string;
  setParentId: (parentId: string) => void;
  toggleDialog: (val: boolean) => void;
  totalCount: number;
  updatePagination: (page: number) => void;
  updateSearchTerm: (text: string) => void;
}

const SearchDialog: FC<Props> = (props) => {
  const [resetInput, changeResetInput] = useState(false);

  const {
    countLoading,
    currentPage,
    customers,
    error,
    loading,
    memberId,
    refetch,
    searchText,
    setParentId,
    toggleDialog,
    totalCount,
    updatePagination,
    updateSearchTerm,
  } = props;

  const hasCustomers = customers.length > 0;
  const isFiltered = searchText !== '';
  const disableSearchFilter = !loading && !hasCustomers && !isFiltered;

  return (
    <Dialog
      title='Customers'
      toggleDialog={() => toggleDialog(false)}
      width='lg'
    >
      <DialogContent>
        {error || !memberId ? (
          <div className={cx('errorCode')}>
            <IconSystemError />
          </div>
        ) : (
          <div className={cx('root')} data-testid='searchDialogContent'>
            <SearchBar
              className={cx('search')}
              onSubmit={(text) => updateSearchTerm(text)}
              placeholder='Search by ID, identifier, name or address'
              disabled={disableSearchFilter}
            />
            <CustomersListLite
              customers={customers}
              handleRowClick={(data: any) => {
                setParentId(data.id);
                toggleDialog(false);
              }}
              loading={loading}
            />
            <Pagination
              className={cx('pagination')}
              currentPage={currentPage}
              numberPerPage={NUMBER_PER_PAGE}
              loading={countLoading}
              onChangePage={({ currentPage }) => {
                changeResetInput(false);
                updatePagination(currentPage);
                refetch({ offset: (currentPage - 1) * NUMBER_PER_PAGE });
              }}
              resetInput={resetInput}
              isDisplayed={hasCustomers}
              totalCount={totalCount}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SearchDialog;
