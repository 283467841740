import gql from 'graphql-tag';
import { Attribute } from 'types/generated/Attribute';
import { CustomerAddress } from 'types/generated/CustomerAddress';
import { CustomerClassOfTrade } from 'types/generated/CustomerClassOfTrade';
import { CustomerListMembership } from 'types/generated/CustomerListMembership';
import { ErrorCode } from 'types/generated/ErrorCode';
import { Document } from 'types/generated/Document';
import { Relationship } from 'types/generated/Relationship';
import { CustomerMappingSource } from 'types/generated/CustomerMappingSource';
import { RelationshipRow } from './relationships';
import { ContractViewRow } from './contract';

interface CustomerRow {
  id: string;
  attributes?: Attribute[];
  memberId: number;
  timestamp: string;
  names: string[] | null;
  startDate: string | null;
  endDate: string | null;
  parentId: string | null;
  identifiers: string[] | null;
  addresses: CustomerAddress[] | null;
  classesOfTrade: CustomerClassOfTrade[] | null;
  hasErrors: boolean;
  hasWarnings?: boolean;
  errors: ErrorCode[] | null;
  lists?: {
    listId: string;
    startDate: string;
    endDate: string;
  }[];
  relationships?: Relationship[];
  fromCustomerMappings?: any;
}

export interface Customer extends CustomerRow {
  memberships: CustomerListMembership[];
  topParentId?: string;
}

export interface QueryCustomerByIdResult {
  customer?: Customer;
}

export interface QueryCustomersWithTopParentId {
  customers?: Customer[];
}

const CUSTOMER_FIELDS = `
  id
  memberId: member_id
  timestamp
  names
  startDate: start_date
  endDate: end_date
  parentId: parent_id
  addresses
  classesOfTrade: classes_of_trade
  identifiers
  hasErrors: has_errors
  errors
  relationships
`;

export const QUERY_CUSTOMERS_COUNT = gql`
  query QueryCustomersCount($where: dash_customers_bool_exp!) {
    customerCount: dash_customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const QUERY_CUSTOMER_BY_ID = gql`
  query QueryCustomerByID($memberId: Int!, $id: String!) {
    customer: dash_customers_by_pk(
      id: $id
      member_id: $memberId
    ) {
      ${CUSTOMER_FIELDS}
      attributes
      fromCustomerMappings: from_customer_mappings {
        toCustomer: to_customer {
          id
          memberId: member_id
          timestamp
        }
      }

      memberships: list_memberships(
        order_by: {
          rank: asc
        }
      ) {
        startDate: start_date
        endDate: end_date
        listId: list_id
        list {
          id
          name
          type
          timestamp
          gpoCustomerIdRecord: gpo_customer_id_record {
            id
            memberId: member_id
            names
            timestamp
          }
        }
      }
    }
  }
`;

export const QUERY_CUSTOMER_BY_ID_WITH_TOP_PARENT = gql`
  query QueryCustomerByIDWithTopParent($memberId: Int!, $id: String!) {
    customers: dash_customers_history_with_parent_chain_relationships (
      where: { member_id: { _eq: $memberId }, id: { _eq: $id } },
      limit: 1
    ) {
      topParentId: top_parent_id
      ${CUSTOMER_FIELDS}
      fromCustomerMappings: from_customer_mappings {
        toCustomer: to_customer {
          id
          memberId: member_id
          timestamp
        }
      }
      memberships: list_memberships(
        order_by: { rank: asc }
      ) {
        startDate: start_date
        endDate: end_date
        listId: list_id
      }
    }
  }
`;

export enum ListType {
  eligibility = 'eligibility',
  gpo = 'gpo',
  program = 'program',
  contract = 'contract',
}

export interface CustomerDetailsList extends CustomerListMembership {
  list?: {
    id: string;
    name: string;
    timestamp: string;
    type: ListType;
    gpoCustomerIdRecord?: {
      id: string;
      memberId: number;
      names: string[];
    };
  };
}

export interface CustomerMappingCustomer {
  id: string;
  memberId: number;
  timestamp: string;
  identifiers: string[] | null;
  addresses: CustomerAddress[] | null;
  classesOfTrade: CustomerClassOfTrade[] | null;
  names: string[] | null;
  sources?: CustomerMappingSource[];
}

export interface CustomerDetails extends Customer {
  documents: Document[];
  memberships: CustomerDetailsList[];
  fromCustomerMappings: {
    toCustomer: CustomerMappingCustomer;
    sources?: CustomerMappingSource[];
  }[];
  toCustomerMappings: {
    fromCustomer: CustomerMappingCustomer;
    sources?: CustomerMappingSource[];
  }[];
  relationshipsFrom: RelationshipRow[];
  relationshipsTo: RelationshipRow[];
}

export interface CustomerMappingsHistory {
  id: string;
  memberId: number;
  timestamp: string;
  isMapping: boolean;
  sources: CustomerMappingSource[];
  fromCustomer?: CustomerMappingCustomer;
  toCustomer?: CustomerMappingCustomer;
}

export interface QueryIdentiferDataDetailsResult {
  customer?: CustomerDetails;
}

export interface QueryCustomerDetailsResult {
  customer: CustomerDetails[];
  mappingsHistory: CustomerMappingsHistory[];
}

const QUERY_CUSTOMER_DETAILS_INTERNAL = `
    attributes
    ${CUSTOMER_FIELDS}
    documents
    memberships: list_memberships(
      order_by: {
        rank: asc
      }
    ) {
      startDate: start_date
      endDate: end_date
      listId: list_id
      list {
        id
        name
        type
        timestamp
        gpoCustomerIdRecord: gpo_customer_id_record {
          id
          memberId: member_id
          names
          timestamp
        }
      }
    }

    fromCustomerMappings: from_customer_mappings {
      toCustomer: to_customer {
        id
        memberId: member_id
        identifiers
        names
        addresses
        classesOfTrade: classes_of_trade
        timestamp
      },
      sources
    }

    toCustomerMappings: to_customer_mappings {
      fromCustomer: from_customer {
        id
        memberId: member_id
        identifiers
        names
        addresses
        classesOfTrade: classes_of_trade
        timestamp
      },
      sources
    }

    relationshipsFrom: from_relationships {
      fromId: from_id
      fromMemberId: from_member_id
      fromTimestamp: from_timestamp
      toId: to_id
      toMemberId: to_member_id
      relationship
    }

    relationshipsTo: to_relationships {
      fromId: from_id
      fromMemberId: from_member_id
      fromTimestamp: from_timestamp
      toId: to_id
      toMemberId: to_member_id
      relationship
    }
`;

export const QUERY_AUTH_CUSTOMER_DETAILS = gql`
  query QueryAuthCustomerDetails($memberId: Int!, $id: String!, $timestamp: timestamptz!) {
    customer: dash_customers_history_by_pk(
      id: $id,
      member_id: $memberId
      timestamp: $timestamp
    ) {
      ${QUERY_CUSTOMER_DETAILS_INTERNAL}
    }
  }
`;

const MAPPINGS_HISTORY = `
  mappingsHistory: dash_get_local_customer_mappings(
    args: {
      from_id_param: $id,
      from_member_id_param: $memberId,
      upper_bound_timestamp_param: $timestamp
    },
    order_by: {from_timestamp: desc}
  ) {
    id: to_id
    memberId: to_member_id
    timestamp: to_timestamp
    upperBoundTimestamp: from_timestamp
    isMapping: is_mapping
    sources
    toCustomer: to_customer {
      id
      memberId: member_id
      identifiers
      names
      addresses
      classesOfTrade: classes_of_trade
      timestamp
    }
  }
`;

export const QUERY_LOCAL_CUSTOMER_DETAILS = gql`
  query QueryLocalCustomerDetails($memberId: Int!, $id: String!, $timestamp: timestamptz!) {
    customer: dash_customers_history(
      where: {
        id: {_eq: $id},
        member_id: {_eq: $memberId},
        timestamp: {_lte: $timestamp}
      },
      order_by: {
        timestamp: desc
      },
      limit: 1
    ) {
      ${QUERY_CUSTOMER_DETAILS_INTERNAL}
    }
    ${MAPPINGS_HISTORY}
  }
`;

export const QUERY_LOCAL_CUSTOMER_DETAILS_LATEST = gql`
  query QueryLocalCustomerDetailsLatest($memberId: Int!, $id: String!, $timestamp: timestamptz!) {
    customer: dash_customers(
      where: {
        id: {_eq: $id},
        member_id: {_eq: $memberId}
      },
      limit: 1
    ) {
      ${QUERY_CUSTOMER_DETAILS_INTERNAL}
    }
    ${MAPPINGS_HISTORY}
  }
`;

export const QUERY_THIRD_PARTY_CUSTOMER_DETAILS = gql`
  query QueryThirdPartyCustomerDetails($memberId: Int!, $id: String!, $timestamp: timestamptz!) {
    customer: dash_customers_history(
      where: {
        id: {_eq: $id},
        member_id: {_eq: $memberId},
        timestamp: {_lte: $timestamp}
      },
      order_by: {
        timestamp: desc
      },
      limit: 1
    ) {
      ${QUERY_CUSTOMER_DETAILS_INTERNAL}
    }

    mappingsHistory: dash_get_third_party_customer_mappings(
      args: {
        to_id_param: $id,
        to_member_id_param: $memberId,
        upper_bound_timestamp_param: $timestamp
      },
      order_by: {to_timestamp: desc}
    ) {
      id: from_id
      memberId: from_member_id
      timestamp: from_timestamp
      upperBoundTimestamp: to_timestamp
      isMapping: is_mapping
      sources
      fromCustomer: from_customer {
        id
        memberId: member_id
        identifiers
        names
        addresses
        classesOfTrade: classes_of_trade
        timestamp
      }
    }
  }
`;

const CUSTOMER_VIEW_FIELDS = `
  addresses
  attributes
  classesOfTrade: classes_of_trade
  endDate: end_date
  errors
  hasErrors: has_errors
  hasWarnings: has_warnings
  id
  identifiers
  memberId: member_id
  memberships: list_memberships(
    order_by: {
      rank: asc
    },
  ) {
    endDate: end_date
    list {
      id
      name
      timestamp
      type
    }
    listId: list_id
    startDate: start_date
  }
  names
  parentId: parent_id
  relationshipsFrom: from_relationships {
    fromId: from_id
    fromMemberId: from_member_id
    fromTimestamp: from_timestamp
    toId: to_id
    toMemberId: to_member_id
    relationship
  }
  relationshipsTo: to_relationships {
    fromId: from_id
    fromMemberId: from_member_id
    fromTimestamp: from_timestamp
    toId: to_id
    toMemberId: to_member_id
    relationship
  }
  startDate: start_date
  timestamp
`;

export interface CustomerViewRow extends CustomerRow {
  memberships: CustomerDetailsList[];
  relationshipsFrom: RelationshipRow[];
  relationshipsTo: RelationshipRow[];
}

export interface QueryCustomerView {
  customers: CustomerViewRow[];
}

export interface QueryCustomerViewInCustomerLists {
  customers: {
    customer: CustomerViewRow;
  }[];
}

export const QUERY_CUSTOMER_VIEW = gql`
  query QueryCustomers(
    $id: String
    $isError: Boolean
    $limit: Int
    $memberId: Int!
    $offset: Int
    $searchText: String
    $simpleDEA: Boolean! = false
    $skipRelationships: Boolean! = false
  ) {
    customers: dash_customers(
      limit: $limit
      offset: $offset
      order_by: { timestamp: desc }
      where: {
        id: { _eq: $id }
        has_errors: { _eq: $isError }
        member_id: { _eq: $memberId }
        _search_text: { _ilike: $searchText }
      }
    ) {
      addresses @skip(if: $simpleDEA)
      attributes @skip(if: $simpleDEA)
      classesOfTrade: classes_of_trade @skip(if: $simpleDEA)
      endDate: end_date
      errors @skip(if: $simpleDEA)
      hasErrors: has_errors @skip(if: $simpleDEA)
      id
      identifiers @skip(if: $simpleDEA)
      memberId: member_id
      names @skip(if: $simpleDEA)
      parentId: parent_id @skip(if: $simpleDEA)
      relationshipsFrom: from_relationships @skip(if: $skipRelationships) {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      relationshipsTo: to_relationships @skip(if: $skipRelationships) {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      startDate: start_date
      timestamp
    }
  }
`;

export const QUERY_CUSTOMER_HISTORY_BY_DATE = gql`
  query CustomerHistoryByDate(
    $limit: Int!
    $offset: Int
    $where: dash_customers_history_bool_exp!
    $order: [dash_customers_history_order_by!]
    $memberId: Int!
    $membershipsWhere: dash_customer_list_memberships_history_bool_exp
    $date: timestamptz!
  ) {
    customers: dash_get_customer_history_by_date(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
      args: { member_id_param: $memberId, date_param: $date }
    ) {
      addresses
      attributes
      classesOfTrade: classes_of_trade
      endDate: end_date
      errors
      hasErrors: has_errors
      hasWarnings: has_warnings
      id
      identifiers
      memberId: member_id
      memberships: list_memberships(
        order_by: { rank: asc }
        where: $membershipsWhere
      ) {
        endDate: end_date
        list {
          id
          name
          timestamp
          type
        }
        listId: list_id
        startDate: start_date
      }
      names
      parentId: parent_id
      relationshipsFrom: from_relationships {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      relationshipsTo: to_relationships {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      startDate: start_date
      timestamp
    }
  }
`;

export const QUERY_CUSTOMERS_FROM_LIST = gql`
  query QueryCustomersFromList(
    $where: dash_customer_list_memberships_bool_exp!,
    $order: [dash_customer_list_memberships_order_by!],
    $limit: Int,
    $offset: Int
  ) {
    customers: dash_customer_list_memberships(
      limit: $limit,
      offset: $offset,
      where: $where,
      order_by: $order
    ) {
      customer {
        ${CUSTOMER_VIEW_FIELDS}
      }
    }
  }
`;

export const QUERY_CUSTOMER_FOR_LIST_COUNT = gql`
  query CustomerForListCount($where: dash_customer_list_memberships_bool_exp) {
    customerCount: dash_customer_list_memberships_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const QUERY_CUSTOMER_HISTORY_BY_DATE_COUNT = gql`
  query CustomerHistoryByDateCount(
    $where: dash_customers_history_bool_exp!
    $memberId: Int!
    $date: timestamptz!
  ) {
    customerCount: dash_get_customer_history_by_date_aggregate(
      where: $where
      args: { member_id_param: $memberId, date_param: $date }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const QUERY_CUSTOMERS_FOR_LIST = gql`
  query QueryCustomersForList(
    $limit: Int!
    $offset: Int
    $where: dash_customers_bool_exp!
    $order: [dash_customers_order_by!]
    $includeMemberships: Boolean!
    $membershipsWhere: dash_customer_list_memberships_bool_exp
  ) {
    customers: dash_customers(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
    ) {
      addresses
      attributes
      classesOfTrade: classes_of_trade
      errors
      hasErrors: has_errors
      id
      identifiers
      memberId: member_id
      startDate: start_date
      endDate: end_date
      lists
      names
      timestamp
      memberships: list_memberships(
        order_by: { rank: asc }
        where: $membershipsWhere
      ) @include(if: $includeMemberships) {
        endDate: end_date
        list {
          id
          name
          timestamp
          type
        }
        listId: list_id
        startDate: start_date
      }
    }
  }
`;

export interface QueryCustomerListMembership extends CustomerListMembership {
  listId: string;
  listMemberId: number;
  customerId: string;
  customerMemberId: number;
  startDate?: string;
  endDate?: string;
  list?: {
    id: string;
    name: string;
    timestamp: string;
    type: string;
  };
}

export interface QueryCustomerMembershipsResult {
  memberships: QueryCustomerListMembership[];
}

export const QUERY_CUSTOMER_MEMBERSHIPS = gql`
  query QueryCustomerMemberships(
    $where: dash_customer_list_memberships_bool_exp!
    $order: [dash_customer_list_memberships_order_by!]
    $includeList: Boolean!
  ) {
    memberships: dash_customer_list_memberships(
      where: $where
      order_by: $order
    ) {
      startDate: start_date
      endDate: end_date
      listId: list_id
      listMemberId: list_member_id
      customerId: customer_id
      customerMemberId: customer_member_id
      list @include(if: $includeList) {
        id
        name
        timestamp
        type
      }
    }
  }
`;

export interface QueryCustomerViewCountResult {
  customerCount?: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_CUSTOMER_VIEW_TOTAL_COUNT = gql`
  query QueryCustomerTotalCount(
    $memberId: Int!
    $isError: Boolean
    $searchText: String
  ) {
    customerCount: dash_customers_aggregate(
      where: {
        member_id: { _eq: $memberId }
        has_errors: { _eq: $isError }
        _search_text: { _ilike: $searchText }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export interface CustomerHistoryEntry {
  id: string;
  timestamp: string;
}

export interface CustomerHistoryTimelineEntry {
  id: string;
  isMapping: boolean;
  timestamp: string;
}

export interface CustomerMappingsHistoryEntry {
  fromId: string;
  id: string;
  timestamp: string;
  toId: string;
}

export interface QueryCustomerHistoryResult {
  customers?: CustomerHistoryEntry[];
  mappings?: CustomerMappingsHistoryEntry[];
}

export const QUERY_AUTH_CUSTOMER_HISTORY = gql`
  query QueryAuthCustomerHistory($memberId: Int!, $id: String!) {
    customers: dash_customers_history(
      where: { member_id: { _eq: $memberId }, id: { _eq: $id } }
      order_by: { timestamp: desc }
    ) {
      memberId: member_id
      id
      timestamp
      names
      identifiers
      hasErrors: has_errors
      errors
      parent {
        id
        identifiers
      }
    }
  }
`;

export const QUERY_CUSTOMER_HISTORY = gql`
  query QueryCustomerHistory(
    $id: String!
    $mappingsWhere: dash_mapping_events_bool_exp!
    $memberId: Int!
  ) {
    customers: dash_customers_history(
      where: { member_id: { _eq: $memberId }, id: { _eq: $id } }
      order_by: { timestamp: desc }
    ) {
      id
      timestamp
    }
    mappings: dash_mapping_events(
      where: $mappingsWhere
      order_by: { timestamp: desc }
    ) {
      fromId: from_id
      kind
      timestamp
      toId: to_id
    }
  }
`;

export interface QueryCustomersByIdsResult {
  customers: CustomerViewRow[];
}

export const GET_CUSTOMERS_BY_IDS = gql`
  query QueryCustomersByIds(
    $memberId: Int!
    $ids: [String!]!
    $limit: Int
    $offset: Int
    $simpleDEA: Boolean! = false
  ) {
    customers: dash_customers(
      where: { member_id: { _eq: $memberId }, id: { _in: $ids } }
      order_by: { timestamp: desc }
      limit: $limit
      offset: $offset
    ) {
      addresses @skip(if: $simpleDEA)
      classesOfTrade: classes_of_trade @skip(if: $simpleDEA)
      endDate: end_date
      errors @skip(if: $simpleDEA)
      hasErrors: has_errors @skip(if: $simpleDEA)
      id
      identifiers @skip(if: $simpleDEA)
      memberId: member_id
      names @skip(if: $simpleDEA)
      relationshipsFrom: from_relationships @skip(if: $simpleDEA) {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      relationshipsTo: to_relationships @skip(if: $simpleDEA) {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      startDate: start_date
      timestamp
    }
  }
`;

export interface QueryIdentifierDataResult {
  identifier: CustomerViewRow;
}

export const GET_IDENTIFIER_DATA = gql`
  query QueryIdentifierData(
    $memberId: Int!
    $id: String!
    $simpleDEA: Boolean! = false
  ) {
    identifier: dash_customers_by_pk(id: $id, member_id: $memberId) {
      addresses @skip(if: $simpleDEA)
      classesOfTrade: classes_of_trade @skip(if: $simpleDEA)
      endDate: end_date
      errors @skip(if: $simpleDEA)
      hasErrors: has_errors @skip(if: $simpleDEA)
      id
      identifiers @skip(if: $simpleDEA)
      memberId: member_id
      names @skip(if: $simpleDEA)
      relationshipsFrom: from_relationships @skip(if: $simpleDEA) {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      relationshipsTo: to_relationships @skip(if: $simpleDEA) {
        fromId: from_id
        fromMemberId: from_member_id
        fromTimestamp: from_timestamp
        toId: to_id
        toMemberId: to_member_id
        relationship
      }
      startDate: start_date
      timestamp
    }
  }
`;

export interface QueryCustomerListContractsResult {
  dash_contract_headers?: ContractViewRow[];
}

export const GET_CUSTOMER_LIST_CONTRACTS = gql`
  query QueryCustomerListContracts(
    $limit: Int!
    $offset: Int!
    $where: dash_contract_headers_bool_exp
    $order: [dash_contract_headers_order_by!]
  ) {
    dash_contract_headers(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order
    ) {
      contract_entities
      description
      distributors
      end_date
      errors
      list_id
      list_member_id
      metadata
      open
      resubmission_window_days
      start_date
      submission_window_days
      timestamp
      latest_timestamp
      type
      pricings(
        distinct_on: product_id
        order_by: [{ product_id: desc }, { product_timestamp: desc }]
        limit: 100
      ) {
        product_id
        has_errors
        errors
      }
    }
  }
`;

export interface QueryCustomerListContractsTotalCountResult {
  dash_contract_headers_aggregate?: {
    aggregate: {
      count: number;
    };
  };
}

export const GET_CUSTOMER_LIST_CONTRACTS_TOTAL_COUNT = gql`
  query QueryCustomerListContractsTotalCount(
    $where: dash_contract_headers_bool_exp
  ) {
    dash_contract_headers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
