// Vendor
import arrayMutators from 'final-form-arrays';
import { isEmpty, isEqual } from 'lodash';
import { Field, Form } from 'react-final-form';
import classnames from 'classnames/bind';
import React, { FC, useCallback, useMemo, useRef } from 'react';

// Internal
import { Button } from 'components';
import Section from 'components/Section';
import { useIsFirstRender } from 'hooks';
import CustomersTable from './CustomersTable/container';
import {
  eqSimpleCustomerList,
  getCustomerListFields,
  validateForm,
} from './service';
import { FormValues } from '../service';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

export interface Props {
  createLoading: boolean;
  fetchedData: {};
  handleOnSubmit: any;
  id: string;
  onCancel: () => void;
}

const CreateEditCustomersList: FC<Props> = (props) => {
  const { createLoading, fetchedData, handleOnSubmit, id, onCancel } = props;

  const formRef = useRef<FormValues>();

  const firstRender = useIsFirstRender();
  const initialValues = useMemo(
    () => (firstRender ? fetchedData : formRef.current),
    [fetchedData]
  );

  // Handler necessary to avoid submitting when pressing enter in an overview text field
  const formKeyHandler = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  return (
    <Form
      data-testid='form-hey'
      initialValues={initialValues}
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={(values) => handleOnSubmit(values)}
      validate={validateForm}
      render={({ errors, form, handleSubmit, values, submitting }) => {
        formRef.current = values;
        const isUpdate = !!id;
        const hasUpdatedFields = !isEqual(fetchedData, values);
        const disableUpdate = !hasUpdatedFields && isUpdate;
        const btnLabel = isUpdate ? 'Update' : 'Create';

        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <form onSubmit={handleSubmit} onKeyDown={formKeyHandler}>
            <>
              <section className={cx('buttons')}>
                <Button
                  color='secondary'
                  dataTestId='cancelButton'
                  label='Cancel'
                  onClick={onCancel}
                  variant='outlined'
                  disabled={submitting || createLoading}
                />
                <Button
                  dataTestId='submitButton'
                  disabled={
                    submitting ||
                    createLoading ||
                    !isEmpty(errors) ||
                    disableUpdate
                  }
                  label={btnLabel}
                  loading={createLoading}
                  type='submit'
                />
              </section>
              <Section
                change={form.change}
                fields={getCustomerListFields()}
                title='Overview'
                values={values as FormValues}
              />
              <Field
                name='selectedCustomers'
                isEqual={(l1, l2) =>
                  eqSimpleCustomerList.equals(l1 ?? [], l2 ?? [])
                }
              >
                {() => (
                  <CustomersTable
                    formUpdate={form.mutators.update}
                    listId={id}
                    values={values}
                  />
                )}
              </Field>
            </>
          </form>
        );
      }}
    />
  );
};

export default CreateEditCustomersList;
