// Internal
import { toMediledgerListId } from 'common/helpers/mlIdUtils';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
} from 'common/helpers';
import { SimpleCustomer } from './EditCustomerList';

export interface FormValues {
  customers: SimpleCustomer[];
  description: string;
  endDate: string;
  name: string;
  startDate: string;
}

export const emptyFormValues = {
  customers: [],
  description: '',
  endDate: '',
  name: '',
  startDate: '',
};

const upsertCustomerAssociation = (
  active: boolean,
  api: any,
  customers: SimpleCustomer[],
  listId: string,
  memberId: number
) =>
  customers.map((customer) =>
    api?.upsertCustomerAssociation({
      active,
      memberId: `ml:member:${memberId}`,
      fromId: `ml:customer:${memberId}:${customer.id}`,
      timestamp: new Date().toISOString(),
      kind: 'list-association',
      toId: toMediledgerListId(listId, memberId),
      timespans: [
        {
          startDate: convertStartDateToUtcTimestamp(
            customer.membershipStartDate
          ),
          endDate: convertEndDateToUtcTimestamp(customer.membershipEndDate),
        },
      ],
    })
  );

export const updateCustomerList = async (
  initialCustomers: SimpleCustomer[],
  customers: SimpleCustomer[],
  memberId: number,
  listId: string,
  api: any
) => {
  const customersToRemove = getCustomersToRemove(customers, initialCustomers);
  const customersToUpdate = getCustomersToUpdate(customers, initialCustomers);
  const customersToAdd = getCustomersToAdd(customers, initialCustomers);

  const removeRequests = upsertCustomerAssociation(
    false,
    api,
    customersToRemove,
    listId,
    memberId
  );

  const upsertRequests = upsertCustomerAssociation(
    true,
    api,
    customersToUpdate.concat(customersToAdd),
    listId,
    memberId
  );

  return Promise.all(upsertRequests.concat(removeRequests));
};

export const getCustomersToRemove = (
  customers: SimpleCustomer[],
  initialCustomers: SimpleCustomer[]
) =>
  initialCustomers.filter((customer) =>
    customers.some((c) => c.id === customer.id && !c.checked)
  );

export const getCustomersToUpdate = (
  customers: SimpleCustomer[],
  initialCustomers: SimpleCustomer[]
) =>
  customers.filter((customer) =>
    initialCustomers.some(
      (c) =>
        c.id === customer.id &&
        customer.checked &&
        (customer.membershipStartDate !== c.membershipStartDate ||
          customer.membershipEndDate !== c.membershipEndDate)
    )
  );

export const getCustomersToAdd = (
  customers: SimpleCustomer[],
  initialCustomers: SimpleCustomer[]
) =>
  customers.filter((customer) =>
    initialCustomers.every((c) => c.id !== customer.id && c.checked)
  );
