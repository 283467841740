export default () => ({
  outlinedInput: {
    borderRadius: 6,
  },
  label: {
    width: 'unset',
  },
  root: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: '38px',
    width: '40px',
  },
});
