// Vendor
import React from 'react';

// Internal
import {
  AuthorityIDMapping,
  formatDate,
  generateStatus,
  isHIBCC,
  truncateDatetime,
} from 'common/helpers';
import { ClassOfTrade, CustomerDetails } from 'query';
import { ValueTooltip } from 'components/Tooltips';

export const getIDTitlePrefix = (memberId: AuthorityIDMapping) => {
  const prefixes: Record<AuthorityIDMapping, string> = {
    [AuthorityIDMapping.DEA]: 'DEA ID',
    [AuthorityIDMapping.HIBCC]: 'HIN ID',
    [AuthorityIDMapping.HRSA]: '340B ID',
    [AuthorityIDMapping.AHD]: 'AHD ID',
    [AuthorityIDMapping.NPI]: 'NPI ID',
  };

  return prefixes[memberId];
};

export const getAuthorityIDBreadcrumb = (memberId: AuthorityIDMapping) =>
  AuthorityIDMapping[memberId];

export const getOverviewData = (
  customer: CustomerDetails,
  customerCot: ClassOfTrade | undefined
) => {
  const { endDate, id, names: rawNames, startDate, timestamp } = customer;
  const names = rawNames?.filter((name) => name);
  const firstName = names?.[0] ?? '';

  const isHIN = isHIBCC(customer.memberId);

  const endDateUTC = truncateDatetime(endDate);
  const startDateUTC = truncateDatetime(startDate);
  const status = generateStatus({ endDate, startDate });
  const updatedAtUTC = formatDate(timestamp);

  const idKey = isHIN ? 'HIN #' : '340B ID';
  const cotKey = isHIN ? 'COT' : 'Type';

  const nameTooltip = <ValueTooltip header='Name' values={names} />;

  return [
    { title: idKey, value: id },
    {
      title: 'Name',
      value: firstName,
      valueIcon: names && names.length > 1 ? nameTooltip : undefined,
      valueLength: names && names.length - 1,
    },
    { title: cotKey, value: customerCot?.name },
    { title: 'Status', value: status },
    { title: 'Start Date', value: startDateUTC },
    { title: 'End Date', value: endDateUTC },
    { title: 'Updated At', value: updatedAtUTC },
  ];
};
