// Vendor
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Internal
import {
  Product,
  QUERY_PRODUCT_LIST_BY_ID,
  GET_PRODUCTS_FOR_LIST,
  QueryProductListResult,
  GetProductsForListResult,
} from 'query';
import { useTradingPartnerConfig } from 'hooks';
import { ProductList } from 'types/generated/ProductList';
import { toMediledgerProductListId } from 'common/helpers/mlIdUtils';
import { productListPath } from 'common/helpers';
import CreateEditProductList from './container';

const EditProductList: FC = () => {
  const navigate = useNavigate();
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const { id } = useParams() as { id: string };
  if (!memberId) {
    throw new Error('Member ID must be present.');
  }

  const [existingRecord, setExistingRecord] = useState<ProductList>();

  const { data, loading, error } = useQuery<QueryProductListResult>(
    QUERY_PRODUCT_LIST_BY_ID,
    {
      variables: {
        id,
        memberId,
      },
      skip: !(id && memberId),
    }
  );

  const {
    data: assignedProducts,
    loading: assignedProductsLoading,
    refetch,
  } = useQuery<GetProductsForListResult>(GET_PRODUCTS_FOR_LIST, {
    variables: {
      order: { product: { description: 'asc' } },
      where: {
        active: { _eq: true },
        kind: { _eq: 'product-association' },
        to_id_full: { _eq: toMediledgerProductListId(id, memberId) },
      },
    },
  });

  useEffect(() => {
    if (!loading && data && !assignedProductsLoading) {
      setExistingRecord(data.productList);
    }
  }, [data, loading, assignedProductsLoading]);

  const onCancel = () => {
    navigate(`${productListPath(id)}`, { replace: true });
  };

  useEffect(() => {
    // We re-fetch so we are not stuck with cache
    refetch().then();
  }, [id]);

  const currentListProducts = useMemo(
    () =>
      (assignedProducts?.products ?? []).map(
        ({ product }) => product as Product
      ),
    [assignedProducts]
  );

  if (loading || assignedProductsLoading) {
    return <div />;
  }

  return (
    <CreateEditProductList
      editOnCancel={onCancel}
      existingRecord={existingRecord}
      isError={!!error}
      currentListProducts={currentListProducts}
    />
  );
};

export default EditProductList;
