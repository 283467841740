// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Paper } from '@material-ui/core';

// Internal
import { isRosterOON } from 'common/helpers';
import {
  useAppBarSetTitle,
  useConfig,
  useRosterTradingPartnerTypes,
} from 'hooks';
import {
  GET_TRADING_PARTNERS,
  QueryTradingPartners,
  wrapSearchText,
} from 'query';
import { EmptyResults, RosterFilter } from 'components';
import {
  RosterKindParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import SearchBar from 'components/SearchBar';
import { filtersToQuery } from './helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

// Constants
const DEFAULT_DISABLED_ROSTERS = ['amerisource', 'healthtrust', 'vizient'];

const Roster: FC = () => {
  useAppBarSetTitle('Roster');
  const dashboardConfig = useConfig();
  const rosterTradingPartnerTypes = useRosterTradingPartnerTypes();
  const { currentSearch } = usePersistedSearchParams(
    { module: 'roster', params: [SearchParamConfig, RosterKindParamConfig] },
    SessionStorage
  );
  // Search
  const [searchTextValue, updateSearchText] =
    currentSearch[SearchParamConfig.key];
  // Filters
  const [filter, updateFilter] = currentSearch[RosterKindParamConfig.key];

  const { data, loading } = useQuery<QueryTradingPartners>(
    GET_TRADING_PARTNERS,
    {
      variables: {
        id: filtersToQuery(filter),
        name: wrapSearchText(searchTextValue),
      },
    }
  );
  const rosters =
    data?.tradingPartners?.filter((p) =>
      rosterTradingPartnerTypes.some(
        (type) => type.toLowerCase() === p.type?.toLowerCase()
      )
    ) || [];
  const disabledRosters =
    dashboardConfig?.disabledGpos || DEFAULT_DISABLED_ROSTERS;
  const isFiltered = searchTextValue !== '' || filter !== 'all';
  const hasRosters = rosters.length > 0;
  const disableSearchFilter = !loading && !hasRosters && !isFiltered;

  return (
    <main className={cx('root', 'column')}>
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            text={searchTextValue || ''}
            onSubmit={(text) => {
              updateSearchText(text);
            }}
            placeholder='Search by roster name'
            disabled={disableSearchFilter}
          />
          <RosterFilter
            applyFilters={(id) => updateFilter(id)}
            disableFilters={disableSearchFilter}
            selectedItem={filter}
          />
        </div>
      </section>
      <section className={cx('content')}>
        {rosters &&
          orderBy(rosters, (roster) => roster.name.toUpperCase()).map(
            ({ id, name }) => {
              const to = `/roster/${id}/${encodeURIComponent(name)}`;
              const disabled = disabledRosters.includes(name);
              const isOON = isRosterOON(id);

              return (
                <Link
                  className={cx('column', 'link', { disabled })}
                  to={to}
                  key={id}
                >
                  <Paper key={id} className={cx('column', 'paper')}>
                    <div className={cx('name')}>{name.toUpperCase()}</div>
                    <div className={cx('network', { isOON })}>
                      {isOON ? 'Out-Of' : 'In'}-Network
                    </div>
                  </Paper>
                </Link>
              );
            }
          )}
        {!loading && !hasRosters && <EmptyResults text='No rosters found' />}
      </section>
    </main>
  );
};

export default Roster;
