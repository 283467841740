// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconArrowDown: FC<Props> = ({ color = '#525f7f' }) => (
  <svg width='14' height='8' viewBox='0 0 14 8'>
    <path
      fill={color}
      fillRule='evenodd'
      d='M7.884 7.187l5.745-5.745a.625.625 0 1 0-.884-.884L7 6.303 1.255.558a.625.625 0 1 0-.884.884l6.182 6.182a.632.632 0 0 0 .894 0l.437-.437z'
      opacity='.8'
    />
  </svg>
);

export default IconArrowDown;
