// Internal
import { AuthorityDatabases, TradingPartnerConfig } from 'common/config';
import { GPO, MFR } from 'common/constants';
import {
  decorateAndPartitionCustomerErrorsBySeverity,
  displayAddress,
  formatIdentifiers,
  getValidCustomerCOTFields,
  partitionUniqueCustomerLists,
} from 'common/helpers';
import {
  ADDRESS_COLUMN,
  COT_COLUMN,
  ERRORS_COLUMN,
  IDENTIFIERS_COLUMN,
  INTERNAL_ID_COLUMN,
  NAME_COLUMN,
  PROGRAM_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { TableColumn, TableRow } from 'components/Table';
import { CustomerViewRow, QueryClassOfTradeResult } from 'query';

export const getRows = (
  hasCotColumn: boolean,
  hasProgramColumn: boolean,
  acceptableAuthData: AuthorityDatabases[],
  activeCustomer: any,
  customers?: CustomerViewRow[],
  cotQueryResult?: QueryClassOfTradeResult
): TableRow[] => {
  if (!customers || customers.length === 0) return [];

  return customers.map((customer) => {
    const { id, memberId, timestamp } = customer;

    const identifiers = formatIdentifiers(customer.identifiers ?? []);
    const addresses =
      customer.addresses?.map((address) => displayAddress(address)) ?? [];
    const names = customer.names ?? [];

    const { severityErrors, severityWarnings } =
      decorateAndPartitionCustomerErrorsBySeverity(customer);

    const cots = hasCotColumn
      ? getValidCustomerCOTFields(customer, cotQueryResult)
      : [];

    const { program } = partitionUniqueCustomerLists(customer.memberships);
    const programs = program.map((l) => l.list?.name);

    const isExpandable =
      (acceptableAuthData.length > 0 && identifiers?.length > 1) ||
      names.length > 1 ||
      addresses.length > 1 ||
      (hasCotColumn && cots?.length > 1) ||
      (hasProgramColumn && programs?.length > 1);

    return {
      isExpandable,
      data: {
        id,
        memberId,
        names,
        identifiers: acceptableAuthData.length > 0 ? identifiers : [],
        addresses,
        cots,
        programs,
        timestamp,
        severityErrors,
        severityWarnings,
      },
      isActiveRow: activeCustomer?.id === id,
      rowHoverBorder: true,
      type: 'expanding',
    };
  });
};

export const getHeaders = (
  tpType: TradingPartnerConfig['type'] | undefined,
  acceptableAuthData: AuthorityDatabases[],
  handleRowClick?: (data: any) => void
): {
  columns: TableColumn[];
  hasCotColumn: boolean;
  hasProgramColumn: boolean;
} => {
  // If the entire row is a link (click handler), it doesn't make sense to have separate text link inside of that row
  const id: TableColumn = handleRowClick
    ? {
        headerName: 'INTERNAL ID',
        dataField: 'id',
        type: 'expanding',
        size: 'small',
      }
    : INTERNAL_ID_COLUMN;

  const isGPO = tpType === GPO;
  const isMFR = tpType === MFR;

  const hasCotColumn = isGPO || isMFR;
  const hasProgramColumn = isGPO;

  return {
    columns: [
      id,
      NAME_COLUMN,
      ...(acceptableAuthData.length > 0 ? [IDENTIFIERS_COLUMN] : []),
      ADDRESS_COLUMN,
      ...(isGPO || isMFR ? [COT_COLUMN] : []),
      ...(isGPO ? [PROGRAM_COLUMN] : []),
      UPDATED_AT_COLUMN,
      ERRORS_COLUMN,
    ],
    hasCotColumn,
    hasProgramColumn,
  };
};
