export const getBreadcrumbLinks = (
  customerUrl: string,
  isLocalCustomer: boolean,
  memberId: number,
  memberName: string,
  showHistory: boolean
) => {
  const localLinks = [
    '/customers',
    ...(!showHistory ? [`${customerUrl}#overview`] : []),
  ];

  const rosterLinks = [
    '/roster',
    `/roster/${memberId}/${encodeURIComponent(memberName)}`,
  ];

  return isLocalCustomer ? localLinks : rosterLinks;
};

export const getBreadcrumbText = (
  isLocalCustomer: boolean,
  memberName: string,
  name: string,
  showHistory: boolean
) => {
  const localCrumbs = [
    'Customers',
    name,
    ...(!showHistory ? ['Add New Document'] : []),
  ];

  const rosterCrumbs = ['Roster', memberName, name];

  return isLocalCustomer ? localCrumbs : rosterCrumbs;
};
