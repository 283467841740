import gql from 'graphql-tag';

import { Association } from 'types/generated/Association';

export interface QueryAssociationsResult {
  associations: Association[];
}

export const QUERY_ASSOCIATIONS = gql`
  query queryAssociations(
    $where: dash_associations_bool_exp!
    $order: [dash_associations_order_by]
    $limit: Int
    $offset: Int
  ) {
    associations: dash_associations(
      where: $where
      order_by: $order
      limit: $limit
      offset: $offset
    ) {
      active
      fromId: from_id_full
      toId: to_id
      kind
      memberId: member_id
      timespans
      timestamp
    }
  }
`;

export interface QueryAssociationsCountResult {
  associations: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_ASSOCIATIONS_COUNT = gql`
  query queryAssociationsCount($where: dash_associations_bool_exp!) {
    associations: dash_associations_aggregate(
      where: $where
      distinct_on: [from_id]
    ) {
      aggregate {
        count
      }
    }
  }
`;
