// Vendor
import React, { FC } from 'react';
import { FormControlLabel, Radio as RadioMUI } from '@material-ui/core';

export interface Props {
  checked: boolean;
  componentValue: string;
  disabled?: boolean;
  handleOnClick?: () => void;
  input: {
    onChange: (e: any) => void;
  };
  label: string;
}

const Radio: FC<Props> = ({
  checked = false,
  componentValue,
  disabled = false,
  handleOnClick,
  input: { onChange },
  label = '',
}) => (
  <FormControlLabel
    control={
      <RadioMUI
        checked={checked}
        data-testid='radio'
        disabled={disabled}
        onChange={onChange}
        onClick={() => {
          if (handleOnClick) handleOnClick();
        }}
      />
    }
    label={label}
    value={componentValue}
  />
);

export default Radio;
