// Vendor
import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Tab, Tabs } from '@material-ui/core';
import { first, orderBy } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Internal
import { Button, HistoryTimeline, HistoryView, Status } from 'components';
import { BreadcrumbWithSearch } from 'components/BreadcrumbWithSearch';
import {
  getGpoAffiliations,
  getUniqueCustomerPrograms,
  localCustomerPath,
  removeCustomerIdFromIds,
  rosterCustomerPath,
} from 'common/helpers';
import { CustomerDetails, CustomerHistoryTimelineEntry } from 'query';
import {
  useAppBarSetTitle,
  useAuthDatabases,
  useCustomerMngmtFlag,
  useDocumentTypes,
  useTradingPartnerType,
} from 'hooks';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { getBreadcrumbLinks, getBreadcrumbText } from './helpers';
import {
  Addresses,
  Attributes,
  Contracts,
  Documents,
  Identifiers,
  Mapping,
  Overview,
  Programs,
  Relationships,
} from '.';

// Styles
import styles from './Details.module.css';

const cx = classnames.bind(styles);

interface Props {
  customer: CustomerDetails;
  customerUpdates: CustomerHistoryTimelineEntry[];
  historyTimestamp: string;
  isHistoryView: boolean;
  isLocalCustomer?: boolean;
  mappings: any[];
  refetch: (timestamp?: string | undefined) => void;
  gpoList: { name: string; label: string }[];
}

const Details: FC<Props> = (props) => {
  const {
    customer,
    customerUpdates,
    historyTimestamp,
    isHistoryView,
    isLocalCustomer = true,
    mappings,
    refetch,
    gpoList,
  } = props;
  const [statusCollapsed, setStatusCollapsed] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { memberName } = useParams() as { memberName: string };

  const hash = location.hash.substring(1) || 'overview';

  const [showHistory, toggleHistory] = useState(true);
  useEffect(() => {
    if (hash === 'overview') toggleHistory(true);
  }, [toggleHistory, hash]);

  const acceptableAuthData = useAuthDatabases();
  const customerMngmtEnabled = useCustomerMngmtFlag();
  const documentTypes = useDocumentTypes();
  const tpType = useTradingPartnerType();
  const isDIST = tpType === 'DIST';
  const isMFR = tpType === 'MFR';
  const isGPO = tpType === 'GPO';

  const {
    addresses,
    attributes,
    errors,
    id,
    memberId,
    names,
    parentId,
    relationships,
  } = customer;

  const name = first(names) ?? '';
  const identifiers = removeCustomerIdFromIds(customer.identifiers ?? []);

  useAppBarSetTitle(name || '{ }');

  const gpo = getGpoAffiliations(
    gpoList,
    customer.memberships,
    customer.relationships
  );
  const programs = getUniqueCustomerPrograms(customer);

  const tabsData = [
    { label: 'Customer Overview', value: 'overview' },
    ...(documentTypes.length > 0 &&
    ((isLocalCustomer && isGPO) || (!isLocalCustomer && isMFR))
      ? [{ label: 'Documents', value: 'documents' }]
      : []),
  ];

  const customerUrl = isLocalCustomer
    ? localCustomerPath(id)
    : rosterCustomerPath(memberId, memberName, id);

  const setHash = ({ currentTab = 'overview' }) => {
    const { search } = location;
    navigate(
      {
        search,
        pathname: customerUrl,
        hash: currentTab,
      },
      { replace: true }
    );
  };

  const searchModule = useMemo(() => {
    const rosterModule = `roster/(.+)/${encodeURIComponent(memberName)}`;
    const rosterRegExp = new RegExp(`/${rosterModule}`);
    const isRoster = rosterRegExp.test(location.pathname);
    return isRoster ? rosterModule : 'customers';
  }, [location, memberName]);

  const handleOnClickUpdate = () =>
    navigate(`${localCustomerPath(id)}/update`, { replace: true });

  return (
    <div className={cx('root')}>
      <div className={cx(hash === 'documents' ? 'documents-column' : 'column')}>
        <BreadcrumbWithSearch
          module={searchModule}
          crumbs={getBreadcrumbText(
            isLocalCustomer,
            memberName,
            name,
            showHistory
          )}
          links={getBreadcrumbLinks(
            customerUrl,
            isLocalCustomer,
            memberId,
            memberName,
            showHistory
          )}
          storage={SessionStorage}
        />
        {isLocalCustomer && (
          <div className={cx('buttons')}>
            {customerMngmtEnabled && !isHistoryView && (
              <Button
                dataTestId='updateButton'
                label='Update'
                onClick={handleOnClickUpdate}
                style={{
                  marginBottom: 8,
                  minWidth: 152,
                }}
              />
            )}
          </div>
        )}
        <main className={cx('content')}>
          {isHistoryView && <HistoryView />}
          {((isGPO && showHistory) || (!isLocalCustomer && isMFR)) && (
            <Tabs
              onChange={(__, currentTab) => setHash({ currentTab })}
              style={{ float: 'left' }}
              value={hash}
            >
              {tabsData.map(({ label, value }) => (
                <Tab key={value} label={label} value={value} />
              ))}
            </Tabs>
          )}
          {hash === 'overview' && (
            <div className={cx('overview')}>
              <Status
                collapsed={statusCollapsed}
                entity={customer}
                onCollapsedChange={setStatusCollapsed}
                timestamp={historyTimestamp}
              />
              <Overview
                customer={customer}
                isLocalCustomer={isLocalCustomer}
                memberName={memberName}
              />
              <Addresses addresses={addresses ?? []} memberName={memberName} />
              {acceptableAuthData.length > 0 && (
                <Identifiers
                  customerErrors={errors ?? []}
                  identifiers={identifiers}
                />
              )}
              <Attributes
                attributes={attributes ?? []}
                memberName={memberName}
              />
              <Relationships
                customerId={id}
                isLocalCustomer={isLocalCustomer}
                memberId={memberId}
                memberName={memberName}
                parentId={parentId}
              />
              {(!isLocalCustomer || !isDIST) && (
                <Programs
                  lists={programs}
                  memberName={memberName}
                  title='Programs'
                />
              )}
              {isLocalCustomer && isMFR && (
                <Contracts customer={customer} relationships={relationships} />
              )}
              {isLocalCustomer && isMFR && (
                <Programs lists={gpo} title='GPO affiliation' />
              )}
              <Mapping
                customer={customer}
                isLocalCustomer={isLocalCustomer}
                mappings={mappings}
              />
            </div>
          )}
          {hash === 'documents' && (
            <Documents
              customer={customer}
              isHistoryView={isHistoryView}
              memberId={memberId}
              refetch={refetch}
              toggleHistory={toggleHistory}
            />
          )}
        </main>
      </div>
      {showHistory && (
        <HistoryTimeline
          pathname={customerUrl}
          refetch={refetch}
          timestamp={historyTimestamp}
          updates={orderBy(customerUpdates, (h) => h.timestamp, ['desc'])}
        />
      )}
    </div>
  );
};

export default Details;
