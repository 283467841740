// Vendor
import { Dictionary } from 'lodash';

// Internal
import {
  CreditMemoRow,
  QueryCreditMemos,
  TradingPartner,
  wrapSearchText,
} from 'query';
import { TableRow } from 'components/Table';
import { formatCurrency } from 'common/helpers';

export interface CreditMemo {
  id: string;
  debitMemoId: string;
  tradingPartnerName: string;
  creditAmount: string;
  creationDate: string;
}

export function rawDataToRows(
  tradingPartners: Dictionary<TradingPartner>,
  isMFR: boolean,
  queryResult?: QueryCreditMemos
): TableRow[] {
  if (
    !queryResult ||
    !queryResult.creditNotes ||
    queryResult.creditNotes.length === 0
  ) {
    return [];
  }

  return queryResult.creditNotes.map((cnRow) => {
    const tradingPartnerName = getTradingPartnerName(
      cnRow,
      tradingPartners,
      isMFR
    );

    return {
      type: 'expanding',
      data: <CreditMemo>{
        id: cnRow.id,
        debitMemoId: cnRow.debit_memo_id,
        tradingPartnerName,
        creditAmount: formatCurrency(cnRow.credit_amount),
        creationDate: cnRow.created_on,
      },
      isExpandable: false,
    };
  });
}

export function buildWhereClause(searchTerm: string): any {
  const wrappedSearchTerm = wrapSearchText(searchTerm);
  return {
    _or: [
      { id: { _ilike: wrappedSearchTerm } },
      { debit_memo_id: { _ilike: wrappedSearchTerm } },
    ],
  } as any;
}

export function getTradingPartnerName(
  creditMemo: CreditMemoRow,
  tradingPartners: Dictionary<TradingPartner>,
  isMFR: boolean
) {
  let tradingPartnerId: number;

  if (isMFR) {
    tradingPartnerId = creditMemo.distributor_member_id;
  } else {
    tradingPartnerId = creditMemo.manufacturer_member_id;
  }

  return tradingPartners[tradingPartnerId]?.name;
}
