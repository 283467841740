// Vendor
import React from 'react';

const IconInvalidName = () => (
  <svg width='32' height='29' viewBox='0 0 32 29'>
    <path
      fill='#CB6671'
      fillRule='nonzero'
      d='M28.982 28.5c.656 0 1.221-.21 1.695-.629.474-.42.766-.93.875-1.531.11-.602.019-1.194-.273-1.777L18.154 1.813c-.328-.584-.793-.976-1.395-1.176-.601-.2-1.203-.2-1.804 0-.602.2-1.067.592-1.395 1.176L.435 24.563c-.291.583-.383 1.175-.273 1.777.11.601.4 1.112.875 1.531.474.42 1.039.629 1.695.629h26.25zm0-1.75H2.732a.796.796 0 0 1-.738-.438.883.883 0 0 1-.027-.875L15.09 2.687c.183-.291.438-.437.766-.437s.583.146.766.438l13.125 22.75a.883.883 0 0 1-.028.874.796.796 0 0 1-.738.438zm-12.906-7a.633.633 0 0 0 .465-.191.633.633 0 0 0 .191-.465l.383-9.188a.485.485 0 0 0-.137-.465c-.127-.127-.3-.191-.52-.191h-1.202c-.22 0-.392.064-.52.191a.485.485 0 0 0-.137.465l.383 9.242c0 .146.064.283.192.41a.633.633 0 0 0 .464.192h.438zm-.219 4.156c.438 0 .802-.146 1.094-.437.292-.292.437-.656.437-1.094 0-.437-.145-.802-.437-1.094a1.458 1.458 0 0 0-.933-.43l-.16-.007c-.438 0-.803.146-1.095.437a1.484 1.484 0 0 0-.437 1.094c0 .438.146.802.437 1.094.292.291.657.437 1.094.437z'
    />
  </svg>
);

export default IconInvalidName;
