// Vendor
import React, { useState } from 'react';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import { ValueEditorProps } from 'react-querybuilder';

// Internal
import { EARLIEST_DATE, SHORT_DATE_FORMAT } from 'common/constants';
import { required } from 'common/validation';
import { DatePicker, TextField } from 'components';
import { ValuesType } from 'pages/CustomerProposals/RuleConfig/ManageRules/types';
import { separateFieldName } from '../../services';
import SelectField from '../SelectField';

const CustomValueEditor = (props: ValueEditorProps) => {
  const {
    handleOnChange,
    inputType,
    operator,
    value,
    values: fieldValuesProp,
    valueSource: valueSourceProp,
  } = props;
  const valueSource: string = valueSourceProp;
  const fieldValues: ValuesType[] = fieldValuesProp?.[0]?.options;

  const [error, setError] = useState<string>('');

  if (valueSource === 'value' && value.includes('#root')) handleOnChange('');

  if (operator === 'notNull' || operator === 'null') return null;

  if (valueSource === 'select') {
    const separatedFields = separateFieldName(value || fieldValues[0]);

    return (
      <SelectField
        fieldIndex={0}
        fields={[...separatedFields]}
        handleOnChange={handleOnChange}
        items={fieldValues}
        separatedFields={separatedFields}
      />
    );
  } else if (inputType === 'date') {
    return (
      <div style={{ position: 'relative', top: 12 }} data-testid='datepicker'>
        <DatePicker
          fallbackDate={EARLIEST_DATE}
          input={{
            onChange: (d) => {
              setError(required(d) || '');
              handleOnChange(
                d ? dayjs(d.valueOf()).format(SHORT_DATE_FORMAT) : ''
              );
            },
            value: dayjs(value).isValid() ? value : '',
          }}
          meta={{
            error,
            dirty: !!error,
            touched: !!error,
          }}
          placeholder='Enter date *'
        />
      </div>
    );
  }

  return (
    <TextField
      disabled={false}
      input={{
        onChange: (e) => {
          setError(required(e.target.value) || '');
          handleOnChange(e.target.value);
        },
        value,
      }}
      meta={{
        error,
        touched: !!error,
      }}
      placeholder='Enter value *'
      {...{ style: { width: 'fit-content' } }}
    />
  );
};

export default CustomValueEditor;
