// Vendor
import isBetween from 'dayjs/plugin/isBetween';
import isSameOfAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjs from 'dayjs';
import { DateRange } from 'common/types';

// Internal
import {
  LONG_TEXT,
  MAX_ID_LENGTH,
  MAX_PRODUCT_PRICE,
  NO_WHITESPACES_ERROR_MESSAGE,
  NO_WHITESPACES_REGEX,
  SHORT_TEXT,
} from 'common/constants';
import { formatCurrency, isRosterOON, truncateDatetime } from './helpers';

dayjs.extend(isBetween);
dayjs.extend(isSameOfAfter);
dayjs.extend(isSameOrBefore);

export const PRICE_OVERLAP_TEXT =
  'Effectivity dates for the same basis price cannot overlap.';
export const getPriceGapText = (date1: string, date2: string) =>
  `${date1} and ${date2} must not have days gap between.`;
export const getPricesUncoveringText = () =>
  `Prices must cover product effectivity dates`;

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const maxLength = (max: number) => (value: string) => {
  if (!value) return undefined;
  return value.length <= max ? undefined : `Maximum length is ${max}`;
};

export const required = (value: any) => (value ? undefined : 'Required');

export const maxPriceValue = (max: number) => (value: number) =>
  value > max ? `Maximum value is ${formatCurrency(max, false)}` : undefined;

export const matchRegexValue = (regex: RegExp) => (value: string) =>
  regex.test(value);

export const nonEmptySpaces = (value: any) =>
  /\s+/i.test(value) ? 'Field should not contain empty spaces' : '';

export const isOonMemberId = (value: string) => {
  const parsed = Number(value);
  const valid = !Number.isNaN(parsed) && isRosterOON(parsed);
  return valid ? undefined : 'Invalid OON Member ID';
};

type withinDateRangeArgs = {
  startDate?: string | null;
  endDate?: string | null;
  message?: string;
};
export const withinDateRange =
  ({ startDate, endDate, message }: withinDateRangeArgs) =>
  (value: string | null) => {
    if (!value || !startDate || !endDate) return undefined;
    if (isSameOrBeforeDate(endDate)(startDate)) return undefined;
    // value could be a full UTC date time, so we want to keep them every date as a simple date to compare them.
    const parsedStartDate = dayjs(truncateDatetime(startDate));
    const parsedEndDate = dayjs(truncateDatetime(endDate));
    const targetDate = dayjs(truncateDatetime(value));

    if (targetDate.isBetween(parsedStartDate, parsedEndDate)) return undefined;
    else if (
      targetDate.isSame(parsedStartDate) ||
      targetDate.isSame(parsedEndDate)
    )
      return undefined;
    else if (message) {
      return message;
    } else {
      return `Must be between date ranges ${truncateDatetime(startDate)} and ${
        truncateDatetime(endDate) || '--'
      }`;
    }
  };

export const dateRangesOverlap =
  ({ startDate, endDate }: DateRange) =>
  // eslint-disable-next-line consistent-return
  ({ startDate: nextStartDate, endDate: nextEndDate }: DateRange) => {
    const nextStartDateOverlaps = !withinDateRange({ startDate, endDate })(
      nextStartDate
    );

    const nextEndDateOverlaps = !withinDateRange({ startDate, endDate })(
      nextEndDate
    );

    const startDateOverlaps = !withinDateRange({
      startDate: nextStartDate,
      endDate: nextEndDate,
    })(startDate);

    const endDateOverlaps = !withinDateRange({
      startDate: nextStartDate,
      endDate: nextEndDate,
    })(endDate);

    if (
      nextStartDateOverlaps ||
      nextEndDateOverlaps ||
      startDateOverlaps ||
      endDateOverlaps
    ) {
      return PRICE_OVERLAP_TEXT;
    }
    return undefined;
  };

export const datesHaveGaps =
  (lastEndDate: string) => (nextStartDate: string) => {
    if (!lastEndDate || !nextStartDate) return undefined;
    const d1 = dayjs(lastEndDate);
    if (d1.isBefore(nextStartDate) && d1.add(1, 'days').isBefore(nextStartDate))
      return getPriceGapText(lastEndDate, nextStartDate);
    return undefined;
  };

export const isSameOrAfterDate =
  (date?: string | null | undefined) => (value: string | null | undefined) => {
    if (!value || !date) return undefined;
    if (dayjs(value).isSameOrAfter(date)) return undefined;
    else return `Must be after date: ${truncateDatetime(date)}`;
  };

export const isSameOrBeforeDate =
  (date?: string | null | undefined) => (value: string | null | undefined) => {
    if (!value || !date) return undefined;
    if (dayjs(value).isSameOrBefore(date)) return undefined;
    else return `Must be before date: ${truncateDatetime(date)}`;
  };

// validators for fields API, that are passed to 'validation' prop
export const maxPriceLengthValidator = (value: string) =>
  maxPriceValue(MAX_PRODUCT_PRICE)(Number.parseFloat(value));

export const maxLongTextLengthValidator = (value: string) =>
  maxLength(LONG_TEXT)(value);

export const maxShortTextLengthValidator = (value: string) =>
  maxLength(SHORT_TEXT)(value);

export const maxIDLengthValidator = (value: string) =>
  maxLength(MAX_ID_LENGTH)(value);

export const nonEmptyValidator = (value: string) =>
  matchRegexValue(NO_WHITESPACES_REGEX)(value)
    ? undefined
    : NO_WHITESPACES_ERROR_MESSAGE;
