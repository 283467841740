// Vendor
import React from 'react';

const IconReturn = () => (
  <svg width='16' height='16' viewBox='0 0 16 16'>
    <path
      fill='#CB6671'
      d='M7.949 15.785c4.28 0 7.75-3.47 7.75-7.75 0-4.275-3.49-7.757-7.765-7.75-2.07.004-3.95.82-5.34 2.147L1.48 1.316c-.473-.473-1.28-.138-1.28.53v4.19c0 .413.335.75.75.75h4.189c.668 0 1.003-.809.53-1.281L4.364 4.2c.964-.903 2.212-1.403 3.538-1.415 2.887-.025 5.321 2.312 5.296 5.295-.023 2.83-2.318 5.205-5.25 5.205-1.285 0-2.5-.459-3.457-1.298-.148-.13-.372-.123-.511.017l-1.24 1.24c-.152.151-.144.4.015.544 1.375 1.241 3.196 1.997 5.194 1.997z'
    />
  </svg>
);

export default IconReturn;
