// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconFilters: FC<Props> = ({ color = '#525F7F' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path d='M3 16.4H12.6' stroke={color} strokeLinecap='square' />
    <path d='M18.2 16.4H20.6' stroke={color} strokeLinecap='square' />
    <circle cx='15.4' cy='16.4' r='1.9' stroke={color} />
    <path d='M3 8.4H6.2' stroke={color} strokeLinecap='square' />
    <path d='M11 8.4H20.6' stroke={color} strokeLinecap='square' />
    <circle cx='8.20005' cy='8.4' r='1.9' stroke={color} />
  </svg>
);

export default IconFilters;
