// Vendor
import { flatten } from 'lodash';

// Internal
import { DocumentType } from 'common/config';

export const formatValue = ({ value }: { value: unknown }) =>
  value === 'true' ? 'Yes' : value === 'false' ? 'No' : value;

export const getDocumentAttribute = (
  documentTypes: DocumentType[],
  key: string
) => {
  const allAttributes = documentTypes.map(
    (documentType) => documentType.attributes
  );
  return flatten(allAttributes).find(
    (attribute) => attribute.fieldName === key
  );
};
