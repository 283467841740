// Vendor
import React, { FC } from 'react';
import MUISnackbar from '@material-ui/core/Snackbar';

// Internal
import { useSnackbar } from 'hooks';
import SnackbarContentWrapper from './SnackbarContentWrapper';

interface Props {
  classes: any;
  duration?: number | null;
  message?: string | Element;
  open: boolean;
  variant?: 'error' | 'info' | 'session' | 'success' | 'warning';
}

const Snackbar: FC<Props> = ({
  duration = 4000,
  message,
  open,
  variant,
  ...rest
}) => {
  const snackbar = useSnackbar();
  if (!variant) return null;

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={duration}
      onClose={snackbar.close}
      open={open}
      {...rest}
    >
      <SnackbarContentWrapper message={message} variant={variant} />
    </MUISnackbar>
  );
};

export default Snackbar;
