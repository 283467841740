import { Publisher } from 'flatfile-import/application/publisher';
import { ApiService } from 'actions/api';

export interface EntityWithError {
  id: string;
  error: any;
}

export interface BatchProcessResponse {
  successful: string[];
  rejected: EntityWithError[];
}

export abstract class HttpPublisher<E>
  implements Publisher<E[], BatchProcessResponse>
{
  apiService: ApiService;

  path: string;

  protected constructor(apiService: ApiService, path: string) {
    this.apiService = apiService;
    this.path = path;
  }

  publish(records: E[]): Promise<BatchProcessResponse> {
    // Remove undesired properties from a customer to prevent API errors.
    const cleanedRecords = records.map((record) => {
      const raw = { ...record } as any;
      delete raw.processId;
      return raw;
    });

    const data = {
      entities: cleanedRecords,
      path: this.path,
    };

    return this.apiService
      .post<BatchProcessResponse>('/v1/process-batch', data, {
        'content-type': 'application/json; charset=UTF-8',
        'content-length': JSON.stringify(data).length,
      })
      .then((res) => res.data);
  }
}
