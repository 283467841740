// Vendor
import { isEqual, uniqWith } from 'lodash';

// Internal
import { TableColumn, TableRow } from 'components/Table';
import { ContractCustomer } from 'query';
import {
  clampEndDate,
  clampStartDate,
  decorateAndPartitionCustomerErrorsBySeverity,
  displayAddress,
  formatIdentifiers,
  parseMediledgerId,
  sortEligibilitiesByStartDate,
} from 'common/helpers';
import { AuthorityDatabases } from 'common/config';
import { Eligibility } from 'types/generated/Relationship';
import {
  ADDRESS_COLUMN,
  ERRORS_COLUMN,
  IDENTIFIERS_COLUMN,
  INTERNAL_ID_COLUMN,
  NAME_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';

export const getColumns = (
  acceptableAuthData: AuthorityDatabases[],
  isMFR: boolean,
  simplifiedContracts: boolean
): TableColumn[] => {
  const simpleId: TableColumn = {
    dataField: 'id',
    headerName: isMFR ? 'INTERNAL ID' : 'EXTERNAL ID',
    size: 'small',
    type: 'expanding',
  };

  const id = isMFR && !simplifiedContracts ? INTERNAL_ID_COLUMN : simpleId;

  return [
    id,
    NAME_COLUMN,
    ...(acceptableAuthData.length > 0 ? [IDENTIFIERS_COLUMN] : []),
    ADDRESS_COLUMN,
    {
      dataField: 'startDates',
      headerName: 'START DATE',
      size: 'small',
      type: 'expanding',
    },
    {
      dataField: 'endDates',
      headerName: 'END DATE',
      size: 'small',
      type: 'expanding',
    },
    UPDATED_AT_COLUMN,
    ERRORS_COLUMN,
  ];
};

export const getRows = (
  acceptableAuthData: AuthorityDatabases[],
  customers: ContractCustomer[],
  contractId: string
): TableRow[] => {
  if (customers.length === 0) return [];

  return customers.map((customer: ContractCustomer) => {
    const {
      addresses: addressesProp,
      endDate,
      id,
      identifiers: idsProp,
      names,
      relationships,
      startDate,
      timestamp,
    } = customer;

    const identifiers = formatIdentifiers(idsProp ?? []);
    const addresses =
      addressesProp?.map((address) => displayAddress(address)) ?? [];

    const contractRanges: Eligibility[] = [];
    relationships?.forEach((r) => {
      if (r.relationship === 'eligibility') {
        const toId = parseMediledgerId(r.to).id;
        if (toId === contractId) {
          contractRanges.push(r);
        }
      }
    });

    const sortedContractRanges = sortEligibilitiesByStartDate(contractRanges);

    const uniqueContractRanges = uniqWith(sortedContractRanges, isEqual);

    const startDates = uniqueContractRanges.map((r) =>
      clampStartDate(startDate, r.startDate)
    );

    const endDates = uniqueContractRanges.map((r) =>
      clampEndDate(endDate, r.endDate)
    );

    const { severityErrors, severityWarnings } =
      decorateAndPartitionCustomerErrorsBySeverity(customer, contractId);

    const isExpandable =
      (names && names.length > 1) ||
      addresses.length > 1 ||
      (acceptableAuthData.length > 0 && identifiers.length > 1) ||
      startDates.length > 1 ||
      endDates.length > 1;

    return {
      isExpandable,
      data: {
        id,
        names,
        identifiers,
        addresses,
        startDates,
        endDates,
        timestamp,
        severityErrors,
        severityWarnings,
      },
      type: 'expanding',
    };
  });
};
