// Vendor
import React, { FC, useMemo, useRef } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { Form } from 'react-final-form';
import classnames from 'classnames/bind';
import arrayMutators from 'final-form-arrays';

// Internal
import Section from 'components/Section';
import { Breadcrumb, Button } from 'components';
import { TableRow } from 'components/Table';
import { ExpandingRowInterface } from 'components/Rows/ExpandingRow';
import { StringifiedProductPriceWithID } from 'common/types';
import { v4 } from 'uuid';
import {
  getProductFields,
  ProductDataType,
  validateProductData,
} from './service';
import {
  generateProductPricingRow,
  getRowsFieldsData,
} from './components/PricingsEditTable/service';
import { PricingsEditTable } from './components/PricingsEditTable';

// Styles
import styles from './CreateEditProductsForm.module.css';

const cx = classnames.bind(styles);

export interface FormProps {
  name?: string;
  description?: string;
}

export interface Props {
  handleOnSubmit: any;
  id: string;
  externalId?: string;
  onCancel: () => void;
  description?: string;
  startDate?: string;
  endDate?: string;
  unitOfMeasure?: string;
  idType?: string;
  pricings?: StringifiedProductPriceWithID[];
  priceTypes: ProductDataType[];
  unitsOfMeasure: ProductDataType[];
  productTypes: ProductDataType[];
  fetchingProducts: boolean;
}

const CreateEditProductsForm: FC<Props> = (props) => {
  const formRef = useRef<FormProps>();
  const {
    handleOnSubmit,
    id,
    onCancel,
    description,
    startDate,
    endDate,
    unitOfMeasure,
    idType,
    externalId,
    priceTypes,
    unitsOfMeasure,
    productTypes,
    pricings: fetchedPricings,
    fetchingProducts,
  } = props;
  const emptyProductPricingRow: TableRow = generateProductPricingRow({
    id: v4(),
    priceType: '',
    price: '',
    startDate: '',
    endDate: '',
  });

  // initialize the function only once, when we get fetched data from API
  const initialPricings = useMemo<StringifiedProductPriceWithID[]>(() => {
    if (fetchingProducts) {
      return [];
    } else {
      const initialCreateFlowRows: ExpandingRowInterface[] = [
        emptyProductPricingRow,
      ] as ExpandingRowInterface[];

      const initialCreateFlowPricings: StringifiedProductPriceWithID[] =
        getRowsFieldsData(
          initialCreateFlowRows
        ) as StringifiedProductPriceWithID[];

      return fetchedPricings || initialCreateFlowPricings;
    }
  }, [fetchedPricings]);

  return (
    <div className={cx('root')} data-testid='product-mgmt'>
      <Breadcrumb
        crumbs={
          externalId ? ['Products', externalId] : ['Products', 'Create Product']
        }
        links={['/products']}
      />
      <Form
        data-testid='form-hey'
        initialValues={{
          externalId,
          idType,
          startDate,
          endDate,
          description,
          unitOfMeasure,
          pricings: initialPricings,
        }}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={handleOnSubmit}
        validate={validateProductData}
        render={({
          errors,
          form,
          handleSubmit,
          values,
          submitting,
          initialValues,
        }) => {
          formRef.current = values;
          const isUpdateMode = !!id;

          // 'form.getState().dirty' does not work properly if we remove the last pricing, it's probably a react-final-form-arrays bug
          // after deleting the last row it will do 2 renders:
          // on first render `dirty` will be `true`
          // on second render `dirty` will be `false` again, despite fact that `initialValues` differ from `values`,
          // so we manually compare `initialValues` with `values` to check the values differences
          const isDirty = !isEqual(initialValues, values);

          const updateFormUnchanged = !isDirty && isUpdateMode;

          const btnLabel = isUpdateMode ? 'Update' : 'Create';

          const isSubmitButtonDisabled =
            !isEmpty(errors) ||
            fetchingProducts ||
            submitting ||
            updateFormUnchanged;

          return (
            <form onSubmit={handleSubmit}>
              <section className={cx('buttons')}>
                <Button
                  color='secondary'
                  dataTestId='cancelButton'
                  label='Cancel'
                  onClick={onCancel}
                  style={{
                    marginLeft: 8,
                    minWidth: 152,
                    padding: 0,
                  }}
                  variant='outlined'
                />
                <Button
                  dataTestId='submitButton'
                  disabled={isSubmitButtonDisabled}
                  label={btnLabel}
                  loading={fetchingProducts || submitting}
                  style={{
                    marginLeft: 8,
                    minWidth: 152,
                    padding: 0,
                  }}
                  type='submit'
                />
              </section>
              <Section
                change={form.change}
                fields={getProductFields(
                  productTypes,
                  unitsOfMeasure,
                  isUpdateMode
                )}
                title='Overview'
                values={values}
              />
              <PricingsEditTable
                form={form}
                formValues={values}
                priceTypes={priceTypes}
              />
            </form>
          );
        }}
      />
    </div>
  );
};

export default CreateEditProductsForm;
