// Vendor
import { Semigroup } from 'fp-ts/lib/Semigroup';
import { struct } from 'fp-ts/Eq';
import { Eq as eqString } from 'fp-ts/string';
import { Eq as eqNumber } from 'fp-ts/number';
import { Eq as eqBoolean } from 'fp-ts/boolean';

// Internal
import { getOptionalEq } from 'common/eqUtils';
import { getArraySemigroup } from '../../../common/fpUtils';
import { getConcatAll } from '../semigroup-util';
import { RawContractPricing, RawPricing } from './contract-pricing-roster';

const eqPricing = struct<RawPricing>({
  contractPrice: getOptionalEq(eqNumber),
  endDate: getOptionalEq(eqString),
  fet: getOptionalEq(eqNumber),
  wac: getOptionalEq(eqNumber),
  startDate: getOptionalEq(eqString),
  fetInclusiveContractPrice: getOptionalEq(eqBoolean),
  priceType: eqString,
});
const pricingListSemigroup = getArraySemigroup(eqPricing);

export const contractPricingSemigroup: Semigroup<RawContractPricing> = {
  concat(x, y): RawContractPricing {
    const { id, contractId, productDescription, productId, timestamp } = x;
    return {
      id,
      contractId,
      productDescription,
      productId,
      timestamp,
      pricing: pricingListSemigroup.concat(x.pricing, y.pricing),
    };
  },
};

export const concatContractPricings = (contractHeaders: RawContractPricing[]) =>
  getConcatAll<RawContractPricing>(contractHeaders, contractPricingSemigroup);
