// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconMember: FC<Props> = ({ color = '#011F42' }) => (
  <svg width='13' height='15' viewBox='0 0 13 15'>
    <path
      fill={color}
      fillRule='nonzero'
      d='M8.96 8.625c.657 0 1.268.164 1.833.492a3.634 3.634 0 0 1 1.34 1.34c.328.565.492 1.176.492 1.832v1.148c0 .365-.128.675-.383.93a1.266 1.266 0 0 1-.93.383H1.687c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93V12.29c0-.656.164-1.267.492-1.832a3.634 3.634 0 0 1 1.34-1.34 3.586 3.586 0 0 1 1.832-.492c.237 0 .547.064.93.191.255.073.465.128.629.164.273.055.574.083.902.083.328 0 .629-.028.902-.083.164-.036.374-.09.63-.164.382-.127.692-.191.929-.191zm2.79 4.812V12.29c0-.766-.273-1.422-.82-1.969a2.685 2.685 0 0 0-1.97-.82c-.145 0-.373.046-.683.137-.62.2-1.212.3-1.777.3s-1.158-.1-1.777-.3c-.31-.091-.538-.137-.684-.137-.766 0-1.422.273-1.969.82a2.685 2.685 0 0 0-.82 1.97v1.147c0 .128.041.233.123.315a.426.426 0 0 0 .314.123h9.625a.426.426 0 0 0 .315-.123.426.426 0 0 0 .123-.315zM6.5 7.75a3.47 3.47 0 0 1-1.764-.465 3.405 3.405 0 0 1-1.271-1.271A3.47 3.47 0 0 1 3 4.25c0-.638.155-1.226.465-1.764A3.47 3.47 0 0 1 6.5.75a3.47 3.47 0 0 1 1.764.465A3.47 3.47 0 0 1 10 4.25a3.47 3.47 0 0 1-.465 1.764A3.47 3.47 0 0 1 6.5 7.75zm0-6.125c-.73 0-1.349.255-1.86.766-.51.51-.765 1.13-.765 1.859 0 .73.255 1.349.766 1.86.51.51 1.13.765 1.859.765.73 0 1.349-.255 1.86-.766.51-.51.765-1.13.765-1.859 0-.73-.255-1.349-.766-1.86A2.531 2.531 0 0 0 6.5 1.626z'
    />
  </svg>
);

export default IconMember;
