// Vendor
import React, { CSSProperties, FC } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export interface Props {
  checked?: boolean;
  disabled?: boolean;
  handleOnChange?: any;
  input: {
    onChange: (e: any) => void;
  };
  label: string;
  style?: CSSProperties;
}

const CheckboxComponent: FC<Props> = ({
  checked = false,
  disabled = false,
  handleOnChange,
  input: { onChange },
  label = '',
  style = {},
}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={checked}
        color='primary'
        disabled={disabled}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={(e) => {
          onChange(e);
          if (handleOnChange) handleOnChange();
        }}
      />
    }
    label={label}
    style={{ ...style }}
  />
);

export default CheckboxComponent;
