/**
 * @description
 * we can have 2 different types of input names:
 *  simple input name, ex. `priceType`, or
 *  nested input name if we work with arrays ex. `pricings.4.priceType`.
 *  This function extracts field name from the input name
 * @param name input name
 * @returns field name
 */
export const extractFieldFromName = (name: string) => {
  const lastIndexOfDot = name.lastIndexOf('.') + 1;

  return name.substring(lastIndexOfDot, name.length);
};

export const getSelectLabel = (
  fieldName: string,
  placeholder: string,
  formData: Record<string, any>,
  specificFormData: Record<string, any> = {}
) => formData?.[fieldName] || specificFormData?.[fieldName] || `${placeholder}`;
