// Vendor
import { Edge, Node } from 'reactflow';

// Internal
import { TableRow } from 'components/Table';
import { Program } from 'types/generated/Clang';
import { TriggersType } from './types';
import { translateProgram } from './services/clangTranslator';

export const getRows = (triggers: TriggersType[]): TableRow[] => {
  if (!triggers) return [];

  return triggers.map((trigger: TriggersType) => ({
    type: 'expanding',
    data: {
      id: trigger.id,
      name: trigger.name,
      proposals: trigger.proposals,
    },
  }));
};

export const getProgram = async (
  edges: Edge[],
  nodes: Node[],
  snackbar: { open: (config: object) => void },
  triggerId: string
): Promise<Program | null> => {
  try {
    const orderedNodes: Node[] = [];
    const orderedEdges: Edge[] = [...edges];

    const compare = (a: Edge, b: Edge) => {
      if (a.source === 'default_0') return -1;
      if (b.target === 'default_1') return -1;
      if (a.target === b.source) return -1;
      return 0;
    };
    orderedEdges.sort(compare);

    orderedEdges.forEach((edge: Edge, i) => {
      if (i + 1 === orderedEdges.length) return;
      const targetNode = nodes.find((node) => node.id === edge.target);
      if (targetNode) orderedNodes.push(targetNode);
    });

    const program = translateProgram(
      triggerId,
      triggerId,
      new Date().toISOString(),
      new Date().toISOString(),
      orderedNodes
    );

    snackbar.open({
      message: 'Successfully translated program',
      variant: 'success',
    });

    return program;
  } catch (e) {
    snackbar.open({
      message: 'Failed to translate program',
      variant: 'error',
    });
    return null;
  }
};
