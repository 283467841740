// Vendor
import React, { FC, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import classnames from 'classnames/bind';

// Internal
import { IconErrorRed } from 'styles/images';
import { Button } from 'components';
import { defaultStyle } from './inlineStyles';

// Styles
import styles from './PasswordLogin.module.css';

export const cx = classnames.bind(styles);

interface Props {
  error?: Error;
  onSignin: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const PasswordLogin: FC<Props> = (props) => {
  const { error, onSignin } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const hasError = error != null;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSignin(e);
      }}
    >
      <div className={cx('inputBlock', 'firstBlock')}>
        <p className={cx('subHeader')}>Email</p>
        <TextField
          InputProps={
            hasError
              ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconErrorRed />
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          error={hasError}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Enter your email'
          style={defaultStyle}
          value={email}
          data-testid='email-field'
          variant='outlined'
        />
      </div>
      <div className={cx('inputBlock')}>
        <p className={cx('subHeader')}>Password</p>
        <TextField
          InputProps={
            hasError
              ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconErrorRed />
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          error={hasError}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Enter your password'
          style={defaultStyle}
          type='password'
          value={password}
          data-testid='password-field'
          variant='outlined'
        />
      </div>
      <Button
        dataTestId='signinButton'
        disabled={!email.length || !password.length}
        label='Sign in'
        style={{ width: '128px' }}
        type='submit'
      />
    </form>
  );
};
