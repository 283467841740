// Vendor
import { capitalize } from 'lodash';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';

// Internal
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  formatDate,
  formatIdentifiers,
  pluckId,
  proposalPath,
  truncateDatetime,
} from 'common/helpers';
import { UPDATED_AT_COLUMN } from 'components/Cells/constants';
import { TableColumn, TableRow } from 'components/Table';
import { Proposal } from 'hooks';
import { wrapSearchText } from 'query';
import { formatImpact, getStatusChipColor, ProposalStatus } from '../service';

export interface Filters {
  showApproved: boolean;
  showPending: boolean;
  showRejected: boolean;
  allSourcesSelected: boolean;
  selectedSources: string[];
  dateRange: {
    from: string | null;
    to: string | null;
  };
}

export const buildWhereClause = (
  searchText: string,
  filters: Filters,
  filtersActive: boolean
) => {
  // Default where clause
  const whereObject: any = {};

  if (searchText !== undefined && searchText !== null) {
    whereObject._search_text = { _ilike: wrapSearchText(searchText) };
  }

  // Return default if no filters are selected
  if (!filtersActive) {
    return whereObject;
  }

  // Status filters
  const statuses: string[] = [];
  if (filters.showApproved) statuses.push(ProposalStatus.APPROVED);
  if (filters.showPending) statuses.push(ProposalStatus.PENDING);
  if (filters.showRejected) statuses.push(ProposalStatus.REJECTED);

  // Trigger sources filters
  if (!filters.allSourcesSelected) {
    whereObject.trigger_source = { _in: filters.selectedSources };
  }

  whereObject.status = { _in: statuses };

  const from = pipe(
    O.fromNullable(filters.dateRange.from),
    O.chain((date) => O.fromNullable(truncateDatetime(date))),
    O.chain((date) => O.fromNullable(convertStartDateToUtcTimestamp(date))),
    O.getOrElseW(() => null)
  );

  const to = pipe(
    O.fromNullable(filters.dateRange.to),
    O.chain((date) => O.fromNullable(truncateDatetime(date))),
    O.chain((date) => O.fromNullable(convertEndDateToUtcTimestamp(date))),
    O.getOrElseW(() => null)
  );
  if (to && from) {
    whereObject._and = {
      timestamp: {
        _gte: from,
        _lte: to,
      },
    };
  } else if (to) {
    whereObject.timestamp = { _lte: to };
  } else if (from) {
    whereObject.timestamp = { _gte: from };
  }
  return whereObject;
};

export const getColumns = (): TableColumn[] => [
  {
    displayTextFn: (data: any) => data.customerId,
    headerName: 'CUSTOMER ID',
    size: 'small',
    type: 'link',
    urlFn: (data: any) =>
      proposalPath(data.customerId, data.nonFormattedTimestamp),
  },
  {
    dataField: 'identifier',
    headerName: 'IDENTIFIER',
    size: 'small',
    type: 'expanding',
  },
  {
    dataField: 'triggerSource',
    headerName: 'TRIGGER SOURCE',
    size: 'small',
    type: 'expanding',
  },
  {
    dataField: 'impact',
    headerName: 'IMPACT',
    size: 'medium',
    type: 'expanding',
  },
  {
    colorField: 'statusChipColor',
    dataField: 'status',
    headerName: 'STATUS',
    size: 'small',
    type: 'chip',
  },
  UPDATED_AT_COLUMN,
];

export const getRows = (proposals: Proposal[]): TableRow[] => {
  if (proposals.length === 0) return [];

  return proposals.map((p: Proposal) => {
    const { impact, proposal, status, timestamp, triggerSource } = p;
    const { id, identifiers } = proposal;

    return {
      isExpandable: false,
      data: {
        identifier: pluckId(formatIdentifiers(identifiers || [])[0]),
        customerId: id,
        triggerSource,
        impact: formatImpact(impact),
        status: capitalize(status),
        statusChipColor: getStatusChipColor(status),
        timestamp: formatDate(timestamp),
        nonFormattedTimestamp: timestamp,
      },
      type: 'expanding',
    };
  });
};
