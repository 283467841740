import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import React, { FC, useEffect, useState } from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';

import {
  ConfigState,
  DashboardConfig,
  generateConfigStateFrom,
} from 'common/config';
import {
  generateApp,
  AppLoadingComponent,
  AppComponent,
  AppErrorComponent,
  GenAppDependencies,
} from './common/helpers/appHelper';

import 'styles/Theme.css';

interface AppState {
  config: O.Option<ConfigState>;
}

const genAppDependencies: GenAppDependencies = {
  AppError: AppErrorComponent,
  AppLoading: AppLoadingComponent,
  App: AppComponent,
};

export const RootContainer: FC = () => {
  const [appState, setAppState] = useState<AppState>({ config: O.none });
  // Effect for loading the Application Config
  useEffect(() => {
    axios
      .get<DashboardConfig>('./v1/dashboard.json')
      .then((response) => generateConfigStateFrom(response.data))
      .then((optConfig: ConfigState) =>
        setAppState({ config: O.some(optConfig) })
      )
      .catch(() =>
        setAppState({
          config: O.some(
            E.left({
              kind: 'config-request-error',
              msg: 'Config validation file request failed.',
            })
          ),
        })
      );
  }, []);

  // Generate the App component based in the current config
  const App = generateApp(appState.config)(genAppDependencies);
  return <App />;
};

render(<RootContainer />, window.document.getElementById('root'));
