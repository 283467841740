// Vendor
import React, { Dispatch, FC, SetStateAction } from 'react';
import classnames from 'classnames/bind';
import { TextField } from '@material-ui/core';
import { Node } from 'reactflow';

// Internal
import { maxLength, required } from 'common/validation';
import { SHORT_TEXT } from 'common/constants';
import { updateActiveNodeData } from '../../services';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  activeNode: Node;
  descriptionError: string | undefined;
  nameError: string | undefined;
  overviewDescriptionLabel?: string;
  overviewNameLabel?: string;
  setActiveNode: Dispatch<SetStateAction<Node>>;
  setDescriptionError: Dispatch<SetStateAction<string | undefined>>;
  setNameError: Dispatch<SetStateAction<string | undefined>>;
}

const Overview: FC<Props> = (props) => {
  const {
    activeNode,
    descriptionError,
    nameError,
    overviewDescriptionLabel = '',
    overviewNameLabel = '',
    setActiveNode,
    setDescriptionError,
    setNameError,
  } = props;

  return (
    <div className={cx('root', 'column')}>
      <span className={cx('title')}>Overview</span>
      <div className={cx('row')}>
        <div className={cx('field')}>
          {overviewNameLabel} *
          <TextField
            data-testid='name'
            error={!!nameError}
            helperText={nameError}
            name='name'
            onChange={(e) => {
              const { value } = e.target;
              setNameError(maxLength(SHORT_TEXT)(value) || required(value));
              updateActiveNodeData(
                activeNode,
                e.target.name,
                setActiveNode,
                e.target.value
              );
            }}
            style={{ marginTop: 8, width: '100%' }}
            type='text'
            value={activeNode.data?.name}
            variant='outlined'
          />
        </div>
        <div className={cx('field')}>
          {overviewDescriptionLabel}
          <TextField
            data-testid='description'
            error={!!descriptionError}
            helperText={descriptionError}
            name='description'
            onChange={(e) => {
              const { value } = e.target;
              setDescriptionError(maxLength(500)(value));
              updateActiveNodeData(
                activeNode,
                e.target.name,
                setActiveNode,
                e.target.value
              );
            }}
            style={{ marginTop: 8, width: '100%' }}
            type='text'
            value={activeNode.data?.description}
            variant='outlined'
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
