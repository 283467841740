import cookies from 'js-cookie';

const COOKIE_PREFIX = 'cbk_';
const SESSION_COOKIE_NAME = 'session';

function cookieAttributes(secureCookies: boolean): cookies.CookieAttributes {
  return { secure: secureCookies, samesite: 'strict' };
}

export function getSessionCookieName(): string {
  return COOKIE_PREFIX + SESSION_COOKIE_NAME;
}

export function readCookie(name: string): any {
  let json = cookies.get(COOKIE_PREFIX + name) || null;
  if (json === 'undefined') {
    json = null;
  }
  // @ts-ignore
  return JSON.parse(json);
}

export function readSession(): any {
  return readCookie(SESSION_COOKIE_NAME);
}

export function getToken(): string | null {
  const session = readSession();
  return session?.token;
}

export function writeCookie(
  name: string,
  value: any,
  secureCookies: boolean
): void {
  cookies.set(
    COOKIE_PREFIX + name,
    JSON.stringify(value),
    cookieAttributes(secureCookies)
  );
}

export function writeSession(session: any, secureCookies: boolean): void {
  writeCookie(SESSION_COOKIE_NAME, session, secureCookies);
}
