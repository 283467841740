import { ApiService } from 'actions/api';
import { AuthDriver } from './driver';

export class Oauth2Driver implements AuthDriver {
  /** Returns Promise<void> */
  async ssoLoginStart(
    apiService: ApiService,
    redirectUri: string | number | boolean,
    csrf: string | number | boolean
  ) {
    // go to URL
    const redirectUriParam = encodeURIComponent(redirectUri);
    const csrfParam = encodeURIComponent(csrf);
    window.location.href = apiService.url(
      `/v1/oauth2/authorization?redirect_uri=${redirectUriParam}&state=${csrfParam}`
    );
  }

  /** Returns Promise<Session> */
  async ssoLoginComplete(
    apiService: ApiService,
    redirectUri: string | number | boolean,
    csrf: string | number | boolean
  ) {
    const sp = new URLSearchParams(window.location.search);
    const state = sp.get('state');
    if (state !== csrf) {
      throw new Error('Invalid CSRF state');
    }
    const req = {
      code: sp.get('code'),
      redirect_uri: redirectUri,
    };
    return apiService
      .post('/v1/oauth2/token', req)
      .then((response) => response.data);
  }

  async ssoLogoutStart(
    apiService: ApiService,
    redirectUri: string | boolean | number,
    cookieName: string
  ) {
    // Redirect to the logout endpoint of the API
    const redirectUriParam = encodeURIComponent(redirectUri);
    window.location.href = apiService.url(
      `/v1/oauth2/logout?redirect_uri=${redirectUriParam}&cookie_name=${cookieName}`
    );
  }
}
