import {
  END_DATE_COLUMN,
  START_DATE_COLUMN,
  PRICE_COLUMN,
  PRICE_TYPE_COLUMN,
} from 'components/Cells/constants';

export const PRODUCTS_PRICING_COLUMNS = [
  PRICE_TYPE_COLUMN,
  PRICE_COLUMN,
  START_DATE_COLUMN,
  END_DATE_COLUMN,
];
