// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Internal
import { useTradingPartnerConfig } from 'hooks';
import { QUERY_PRODUCT_BY_ID, QueryProductResult } from 'query';
import { IconSystemError } from 'styles/images';
import { BreadcrumbWithSearch } from 'components/BreadcrumbWithSearch';
import { Button, Table } from 'components';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { StringifiedProductPriceWithID } from 'common/types';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import { productPath } from 'common/helpers';
import { pricingsToRows } from '../Create/components/PricingsEditTable/service';
import { PRICINGS_COLUMNS } from './service';
import Overview from './Overview';
import {
  formatProductPricingsFromAPI,
  sortProductPricingsByStartDate,
} from '../Create/service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const ProductDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { id: memberId } = useTradingPartnerConfig() ?? {};

  const { data, loading, error } = useQuery<QueryProductResult>(
    QUERY_PRODUCT_BY_ID,
    {
      variables: {
        id,
        memberId,
      },
      pollInterval: SHORT_POLL_INTERVAL,
    }
  );

  const product = data?.product;

  const formattedProductPricings: StringifiedProductPriceWithID[] =
    formatProductPricingsFromAPI(data?.product.prices || [], true);

  // sort fetched pricings by start date
  const sortedProductPricings: StringifiedProductPriceWithID[] =
    sortProductPricingsByStartDate(
      formattedProductPricings
    ) as StringifiedProductPriceWithID[];

  if (loading) return null;

  if (error || !product) {
    return (
      <div className={cx('errorCode')}>
        <IconSystemError />
      </div>
    );
  }

  return (
    <div className={cx('root')}>
      <BreadcrumbWithSearch
        crumbs={['Products', product.external_id]}
        links={['/products']}
        module='products'
        storage={SessionStorage}
      />
      <section className={cx('row', 'toolbar')}>
        <Button
          dataTestId='updateButton'
          label='Update'
          onClick={() =>
            navigate(`${productPath(id)}/update`, { replace: true })
          }
          style={{ minWidth: 90 }}
        />
      </section>
      <Overview detailsInfo={product} />
      <Table
        columns={PRICINGS_COLUMNS}
        isLoading={loading}
        emptyText='No pricings found'
        rows={pricingsToRows(sortedProductPricings)}
      />
    </div>
  );
};

export default ProductDetails;
