// Vendor
import crypto from 'crypto';
import {
  cloneDeep,
  Dictionary,
  get,
  last,
  partition,
  uniq,
  upperFirst,
  lowerCase,
} from 'lodash';

// Internal
import {
  ChargebackRequestRow,
  ContractCustomer,
  ContractViewRow,
  ContractViewRowPricing,
  CustomerDetails,
  CustomerViewRow,
  TradingPartner,
} from 'query';
import { CustomerAddress } from 'types/generated/CustomerAddress';
import { ErrorCode } from 'types/generated/ErrorCode';
import { Eligibility } from 'types/generated/Relationship';

import {
  authorityIDToName,
  truncateDatetime,
  parseMediledgerId,
  formatCurrency,
  removeRosterIdPrefix,
} from '.';

export type Customer = CustomerDetails | CustomerViewRow | ContractCustomer;

export type ContractError = ErrorCode & {
  entityType: 'ContractHeader' | 'ContractPricing';
};

export type Details = {
  key: string;
  value: string | null | undefined;
};

export type MessageWithDetails = {
  message: string;
  details: Details[];
};

export type ErrorWithMessage = ErrorCode & MessageWithDetails;

export const formatOONErrorValues = (errors: ErrorWithMessage[]) =>
  errors.map((error) => {
    const details = error.details.map((detail) => ({
      ...detail,
      value: removeRosterIdPrefix(detail.value ?? ''),
    }));

    return {
      ...error,
      details,
    };
  });

function formatField(field: string): string {
  const formatText: { [field: string]: string } = {
    addresses: 'Address',
    classesOfTrade: 'Class of Trade',
    contractEntities: 'Contract Entity',
    contractType: 'Contract Type',
    distributors: 'Distributor',
    identifiers: 'Identifier',
    lists: 'List',
    names: 'Name',
    parentId: 'Parent',
    pricing: 'Contract Price',
    startDate: 'Start Date',
    endDate: 'End Date',
    zipCode: 'Zip Code',
    address1: 'Line 1',
    contract_entities: 'Entity',
    wacPrice: 'WAC price',
    contractPrice: 'Contract price',
    'sale.quantity': 'Sale quantity',
    'sale.invoiceDate': 'Invoice date',
    'sale.productId': 'Product ID',
    'sale.unitOfMeasure': 'Unit of Measure',
    memberId: 'Member ID',
    manufacturer: 'Manufacturer ID',
    submissionDate: 'Submission date',
    contract: 'Contract ID',
    'contract.startDate': 'Contract start date',
    'contract.endDate': 'Contract end date',
    'customer.startDate': 'Customer start date',
    'customer.endDate': 'Customer end date',
    'dist.startDate': 'Distributor start date',
    'dist.endDate': 'Distributor end date',
    'contract.id': 'Contract ID',
    'distributorCustomer.id': 'Customer ID',
    quantity: 'Quantity',
    amount: 'Amount',
    'original.sale.invoiceDate': 'Original invoice date',
    'original.timestamp': 'Original timestamp',
  };
  return formatText?.[field] || field;
}

function getDisplayAuth(identifier: string): string {
  const { memberId } = parseMediledgerId(identifier);
  // 0 means that identifier could not be parsed
  // TODO: improve the method signature
  if (memberId === 0) {
    return '';
  }
  return authorityIDToName(memberId) || '';
}

function getRelationshipType(relationship: string): string {
  switch (relationship) {
    case 'direct-parent':
      return 'Parent';
    case 'eligibility':
      return 'Contract';
    case 'primary-identifier':
      return 'Primary Identifier';
    default:
      return relationship;
  }
}

function getRelatedRecord(identifier: string): string {
  const auth = getDisplayAuth(identifier);
  const suffix = getNumberSuffix(identifier, false);
  return auth ? `${auth} ${suffix}` : suffix;
}

function getDisplayAddress(address: CustomerAddress): string {
  const addressType = address.addressType ?? '';
  if (addressType === 'mailTo') {
    return 'The mailing address';
  }
  if (addressType === 'shipTo') {
    return 'The shipping address';
  }
  if (addressType === 'billTo') {
    return 'The billing address';
  }
  return 'The primary address';
}

function getTradingPartnerName(
  identifier: string,
  tradingPartners?: Dictionary<TradingPartner>
): string {
  if (!tradingPartners) {
    return identifier;
  }

  const { memberId } = parseMediledgerId(identifier);
  // 0 means that identifier could not be parsed
  if (memberId === 0) {
    return identifier;
  }

  return (
    get(tradingPartners, memberId)?.name || memberId.toString(10) || identifier
  );
}

export const getNumberSuffix = (
  number: string,
  toUpper: boolean = true
): string => {
  const suffix = last(number.split(':')) ?? '';
  if (toUpper) {
    return suffix.toUpperCase();
  }
  return suffix;
};

export type EntityDetails =
  | Customer
  | ContractViewRowPricing
  | ContractViewRow
  | ChargebackRequestRow;

const getMessage = (
  error: ErrorCode | ContractError,
  entity: EntityDetails,
  tradingPartners?: Dictionary<TradingPartner>
): MessageWithDetails | undefined => {
  let productId = null;
  if ((entity as ContractViewRowPricing).product_id) {
    productId = (entity as ContractViewRowPricing).product_id;
  }

  switch (error.code) {
    case 'missing-field':
      return {
        message: 'The following field is missing and is required.',
        details: [
          {
            key: 'Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };

    case 'at-least-one-required':
      if (error.extraProperties.fieldName === 'identifiers') {
        return {
          message: 'At least one identifier must be provided: HIN, DEA, 340B.',
          details: [],
        };
      }

      return {
        message: 'The following field is missing and at least one is required.',
        details: [
          {
            key: 'Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };

    case 'start-date-after-end-date':
      if ((entity as Customer).addresses !== undefined) {
        return {
          message: 'The customer start date is after the customer end date.',
          details: [
            {
              key: 'Customer Start Date',
              value: truncateDatetime(error.extraProperties.startDate),
            },
            {
              key: 'Customer End Date',
              value: truncateDatetime((entity as Customer).endDate),
            },
          ],
        };
      }
      return {
        message: 'The contract start date is after the contract end date.',
        details: [
          {
            key: 'Contract Start Date',
            value: truncateDatetime(error.extraProperties.startDate),
          },
          {
            key: 'Contract End Date',
            value: truncateDatetime((entity as ContractViewRow).end_date),
          },
        ],
      };

    case 'customer-class-of-trade-start-date-after-end-date':
      return {
        message:
          'The customer class of trade start date is after the customer class of trade end date.',
        details: [
          {
            key: 'COT',
            value: error.extraProperties.data.classOfTradeId,
          },
          {
            key: 'Customer COT Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Customer COT End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
        ],
      };

    case 'customer-list-membership-start-date-after-end-date':
      return {
        message:
          'The customer list membership start date is after the customer list membership end date.',
        details: [
          {
            key: 'List',
            value: error.extraProperties.data.listId,
          },
          {
            key: 'Customer List Membership Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Customer List Membership End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
        ],
      };

    case 'customer-address-start-date-after-end-date':
      return {
        message:
          'The customer address start date is after the customer address end date.',
        details: [
          {
            key: 'Address Type',
            value: getDisplayAddress(error.extraProperties.data),
          },
          {
            key: 'Customer Address Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Customer Address End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
        ],
      };

    case 'relationship-start-date-after-end-date':
      return {
        message:
          'The relationship start date is after the relationship end date.',
        details: [
          {
            key: 'Relationship',
            value: getRelationshipType(error.extraProperties.data.relationship),
          },
          {
            key: 'Related Record',
            value: getRelatedRecord(error.extraProperties.data.to),
          },
        ],
      };

    case 'pricing-start-date-after-end-date':
      return {
        message:
          'A pricing line has a pricing start date after the pricing end date.',
        details: [
          {
            key: 'Product',
            value: productId,
          },
          {
            key: 'Pricing Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Pricing End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
        ],
      };

    case 'contract-entity-v2-start-date-after-end-date': {
      return {
        message:
          'The trading partner membership to this contract has a start date after the end date.',
        details: [
          {
            key: 'Trading Partner',
            value: getTradingPartnerName(
              error.extraProperties.data.id,
              tradingPartners
            ),
          },
          {
            key: 'Contract Membership Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Contract Membership End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
        ],
      };
    }

    case 'customer-address-inconsistent-date-range': {
      return {
        message:
          'The customer address date range does not fall within the overall customer date range.',
        details: [
          {
            key: 'Address Type',
            value: getDisplayAddress(error.extraProperties.data),
          },
          {
            key: 'Customer Address Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Customer Address End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
          {
            key: 'Customer Start Date',
            value:
              truncateDatetime((entity as Customer).startDate) ?? '(Invalid)',
          },
          {
            key: 'Customer End Date',
            value: truncateDatetime((entity as Customer).endDate),
          },
        ],
      };
    }

    case 'customer-class-of-trade-inconsistent-date-range': {
      return {
        message:
          'The customer class of trade date range does not fall within the overall customer date range.',
        details: [
          {
            key: 'COT',
            value: error.extraProperties.data.classOfTradeId,
          },
          {
            key: 'Customer COT Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Customer COT End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
          {
            key: 'Customer Start Date',
            value:
              truncateDatetime((entity as Customer).startDate) ?? '(Invalid)',
          },
          {
            key: 'Customer End Date',
            value: truncateDatetime((entity as Customer).endDate),
          },
        ],
      };
    }

    case 'customer-list-membership-inconsistent-date-range': {
      return {
        message:
          'The customer list membership date range does not fall within the overall customer date range.',
        details: [
          {
            key: 'List',
            value: error.extraProperties.data.listId,
          },
          {
            key: 'Customer List Membership Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Customer List Membership End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
          {
            key: 'Customer Start Date',
            value:
              truncateDatetime((entity as Customer).startDate) ?? '(Invalid)',
          },
          {
            key: 'Customer End Date',
            value: truncateDatetime((entity as Customer).endDate),
          },
        ],
      };
    }

    case 'contract-entity-v2-inconsistent-date-range': {
      return {
        message:
          'The trading partner membership date range to this contract does not fall within the overall contract date range.',
        details: [
          {
            key: 'Trading Partner',
            value: getTradingPartnerName(
              error.extraProperties.data.id,
              tradingPartners
            ),
          },
          {
            key: 'Contract Membership Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Contract Membership End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
          {
            key: 'Contract  Start Date',
            value:
              truncateDatetime((entity as ContractViewRow).start_date) ??
              '(Invalid)',
          },
          {
            key: 'Contract  End Date',
            value: truncateDatetime((entity as ContractViewRow).end_date),
          },
        ],
      };
    }

    case 'customer-address-missing-field': {
      return {
        message: 'A field is missing in the customer address.',
        details: [
          {
            key: 'Address Type',
            value: getDisplayAddress(error.extraProperties.data),
          },
          {
            key: 'Missing Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };
    }

    case 'customer-class-of-trade-missing-field': {
      return {
        message: 'A field is missing in the customer class of trade.',
        details: [
          {
            key: 'Class of Trade',
            value: error.extraProperties.data.classOfTradeId,
          },
          {
            key: 'Missing Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };
    }

    case 'customer-list-membership-missing-field': {
      return {
        message: 'A field is missing in the customer list membership.',
        details: [
          {
            key: 'List',
            value: error.extraProperties.data.listId,
          },
          {
            key: 'Missing Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };
    }

    case 'relationship-missing-field': {
      return {
        message: 'A field is missing in the relationship data to the customer.',
        details: [
          {
            key: 'Relationship Type',
            value: getRelationshipType(error.extraProperties.data.relationship),
          },
          {
            key: 'Missing Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };
    }

    case 'pricing-missing-field': {
      return {
        message: 'The pricing line has a missing field.',
        details: [
          {
            key: 'Product',
            value: productId,
          },
          {
            key: 'Pricing Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Pricing End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
          {
            key: 'Missing Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };
    }

    case 'contract-entity-v2-missing-field': {
      return {
        message:
          'The trading partner membership on this contract has a missing field.',
        details: [
          {
            key: 'Trading Partner',
            value: getTradingPartnerName(
              error.extraProperties.data.id,
              tradingPartners
            ),
          },
          {
            key: 'Missing Field',
            value: formatField(error.extraProperties.fieldName),
          },
        ],
      };
    }

    case 'customer-class-of-trade-invalid-reference': {
      return {
        message: 'The customer class of trade does not exist in the system.',
        details: [
          {
            key: 'COT',
            value: error.extraProperties.data.classOfTradeId,
          },
        ],
      };
    }

    case 'customer-list-membership-invalid-reference': {
      return {
        message: 'The customer list does not exist in the system.',
        details: [
          {
            key: 'List',
            // We take care of data when { data: null }, it cause CBK-5654
            value: error.extraProperties.data?.listId,
          },
        ],
      };
    }

    case 'relationship-illegal-date': {
      const { extraProperties } = error;
      const data = extraProperties.data as Eligibility;

      return {
        message:
          'The customer relationship has a date range outside of the overall relationship date range.',
        details: [
          {
            key: 'Relationship Type',
            value: getRelationshipType(data.relationship),
          },
          {
            key: 'Related Record',
            value: getRelatedRecord(data.to),
          },
          {
            key: 'Date Field',
            value: formatField(extraProperties.fieldName),
          },
          {
            key: 'Customer Relationship Date',
            value:
              error.extraProperties.fieldName === 'endDate'
                ? truncateDatetime(data.endDate)
                : truncateDatetime(data.startDate),
          },
          {
            key: 'Overall Relationship Date',
            value: truncateDatetime(extraProperties.expectedDateRange),
          },
        ],
      };
    }

    case 'relationship-inconsistent-date-range': {
      const { extraProperties } = error;
      const data = extraProperties.data as Eligibility;

      return {
        message:
          'The customer relationship date range does not fall within the overall customer date range.',
        details: [
          {
            key: 'Relationship Type',
            value: getRelationshipType(data.relationship),
          },
          {
            key: 'Related Record',
            value: getRelatedRecord(data.to),
          },
          {
            key: 'Relationship Start Date',
            value: truncateDatetime(data.startDate),
          },
          {
            key: 'Relationship End Date',
            value: truncateDatetime(data.endDate),
          },
          {
            key: 'Customer Start Date',
            value:
              truncateDatetime((entity as Customer).startDate) ?? '(Invalid)',
          },
          {
            key: 'Customer End Date',
            value: truncateDatetime((entity as Customer).endDate),
          },
        ],
      };
    }

    case 'relationship-invalid-reference': {
      return {
        message: 'The relationship references an invalid record.',
        details: [
          {
            key: 'Relationship Type',
            value: getRelationshipType(error.extraProperties.data.relationship),
          },
          {
            key: 'Related Record',
            value: getRelatedRecord(error.extraProperties.data.to),
          },
        ],
      };
    }

    case 'relationship-malformed-mediledger-id': {
      return {
        message:
          'There is an integration issue. Please contact technical support. Relationship data has an incorrectly formatted MediLedger ID.',
        details: [
          {
            key: 'Relationship Type',
            value: getRelationshipType(error.extraProperties.data.relationship),
          },
          {
            key: 'Related Record',
            value: getRelatedRecord(error.extraProperties.data.to),
          },
        ],
      };
    }

    case 'relationship-missing-primary-id': {
      return {
        message: 'The identifier cannot be set as a primary identifier.',
        details: [
          {
            key: 'Identifier Type',
            value: getDisplayAuth(error.extraProperties.identifier),
          },
          {
            key: 'Identifier Number',
            value: getNumberSuffix(error.extraProperties.identifier),
          },
        ],
      };
    }

    case 'customer-class-of-trade-illegal-date': {
      return {
        message:
          'The customer class of trade has a date range outside of the overall class of trade date range.',
        details: [
          {
            key: 'COT',
            value: error.extraProperties.data.classOfTradeId,
          },
          {
            key: 'Date Field',
            value: formatField(error.extraProperties.fieldName),
          },
          {
            key: 'Customer COT Date',
            value:
              error.extraProperties.fieldName === 'endDate'
                ? truncateDatetime(error.extraProperties.data.endDate)
                : truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Overall COT Date',
            value: truncateDatetime(error.extraProperties.expectedDateRange),
          },
        ],
      };
    }

    case 'customer-list-membership-illegal-date': {
      return {
        message:
          'The customer list membership has a date range outside of the overall list date range.',
        details: [
          {
            key: 'List',
            value: error.extraProperties.data.listId,
          },
          {
            key: 'Date Field',
            value: formatField(error.extraProperties.fieldName),
          },
          {
            key: 'Customer List Member Date',
            value:
              error.extraProperties.fieldName === 'endDate'
                ? truncateDatetime(error.extraProperties.data.endDate)
                : truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Overall List Date',
            value: truncateDatetime(error.extraProperties.expectedDateRange),
          },
        ],
      };
    }

    case 'pricing-illegal-date': {
      return {
        message:
          'The pricing line date range does not fall within the overall contract date range.',
        details: [
          {
            key: 'Product',
            value: productId,
          },
          {
            key: 'Pricing Start Date',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Pricing End Date',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
          {
            key: 'Date Field',
            value: formatField(error.extraProperties.fieldName),
          },
          {
            key:
              error.extraProperties.fieldName === 'endDate'
                ? 'Contract End Date'
                : 'Contract Start Date',
            value: truncateDatetime(error.extraProperties.expectedDateRange),
          },
        ],
      };
    }

    case 'invalid-reference': {
      if ((entity as Customer).addresses !== undefined) {
        return {
          message: 'This customer references an invalid record.',
          details: [
            {
              key: 'Field',
              value: formatField(error.extraProperties.fieldName),
            },
            {
              key: 'Record',
              value: error.extraProperties.data,
            },
          ],
        };
      } else if ((entity as ContractViewRowPricing).product_id !== undefined) {
        return {
          message: 'The following pricing line references an invalid record.',
          details: [
            {
              key: 'Product',
              value: productId,
            },
            {
              key: 'Field',
              value: formatField(error.extraProperties.fieldName),
            },
            {
              key: 'Record',
              value: error.extraProperties.data,
            },
          ],
        };
      } else if ((entity as ChargebackRequestRow).debit_memo_id !== undefined) {
        return {
          message: 'This chargeback references an invalid record.',
          details: [
            {
              key: 'Field',
              value: formatField(error.extraProperties.fieldName),
            },
            {
              key: 'Record',
              value: error.extraProperties.data,
            },
          ],
        };
      } else {
        return {
          message: 'This contract references an invalid record.',
          details: [
            {
              key: 'Field',
              value: formatField(error.extraProperties.fieldName),
            },
            {
              key: 'Record',
              value: error.extraProperties.data,
            },
          ],
        };
      }
    }

    case 'duplicated-entity': {
      return {
        message: 'This chargeback is a duplicate of another chargeback.',
        details: [
          {
            key: 'Duplicated Chargeback ID',
            value: error.extraProperties.id,
          },
          {
            key: 'Duplicated Chargeback Member ID',
            value: error.extraProperties.memberId,
          },
        ],
      };
    }

    case 'missing-identifier-reference': {
      return {
        message: 'This customer references an invalid identifier record.',
        details: [
          {
            key: 'Type',
            value: getDisplayAuth(error.extraProperties.identifier),
          },
          {
            key: 'Identifier',
            value: getNumberSuffix(error.extraProperties.identifier),
          },
        ],
      };
    }

    case 'illegal-identifier-reference': {
      return {
        message: 'This customer references an expired identifier record.',
        details: [
          {
            key: 'Type',
            value: getDisplayAuth(error.extraProperties.identifier),
          },
          {
            key: 'Identifier',
            value: getNumberSuffix(error.extraProperties.identifier),
          },
        ],
      };
    }

    case 'unacceptable-value': {
      const entityType =
        error.extraProperties.fieldName === 'operation'
          ? 'chargeback'
          : 'contract';
      return {
        message: `The ${entityType} type is not a valid type and must be one of the following.`,
        details: [
          {
            key: 'Acceptable Value',
            value: error.extraProperties.acceptableValues.join(', '),
          },
        ],
      };
    }

    case 'date-range-overlap': {
      return {
        message: 'The following pricing lines have overlapping dates.',
        details: [
          {
            key: 'Product',
            value: productId,
          },
          {
            key: 'Pricing Start Date 1',
            value: truncateDatetime(error.extraProperties.data.startDate),
          },
          {
            key: 'Pricing End Date 1',
            value: truncateDatetime(error.extraProperties.data.endDate),
          },
          {
            key: 'Pricing Start Date 2',
            value: truncateDatetime(error.extraProperties.data2.startDate),
          },
          {
            key: 'Pricing End Date 2',
            value: truncateDatetime(error.extraProperties.data2.endDate),
          },
        ],
      };
    }
    case 'missing-mapping':
      return {
        message: `There was no valid mapping for customer ${
          error.extraProperties.customerId
        } to trading partner ${getTradingPartnerName(
          error.extraProperties.tradingPartner,
          tradingPartners
        )}.`,
        details: [],
      };

    case 'invalid-mapping-reference':
      return {
        message: `The mapped customer referencing ${
          error.extraProperties.from
            ? `${error.extraProperties.mapping.fromId}`
            : `${
                error.extraProperties.mapping.toId
              } from trading partner ${getTradingPartnerName(
                error.extraProperties.mapping.toMemberId,
                tradingPartners
              )}`
        } did not exist.`,
        details: [],
      };

    case 'no-eligible-customers':
      return {
        message: `There were no eligible customers for contract ${error.extraProperties.contractId} mapped to local customer ${error.extraProperties.customerId}.`,
        details: [],
      };

    case 'multiple-eligible-customers':
      return {
        message: `There were multiple mapped eligible customers for contract ${error.extraProperties.contractId} mapped.`,
        details: [],
      };

    case 'no-eligible-pricings': {
      const { contractId, productId, invoiceDate } = error.extraProperties;
      return {
        message: `There were no eligible contract pricings for product ${productId} on contract ${contractId} on ${truncateDatetime(
          invoiceDate
        )}.`,
        details: [],
      };
    }
    case 'invalid-value': {
      const {
        fieldName,
        value,
        condition,
        conditioningFieldName,
        conditioningValue,
      } = error.extraProperties;
      let conditionText = '';
      switch (condition) {
        case 'EQ': {
          conditionText = 'must be equal to';
          break;
        }
        case 'NEQ': {
          conditionText = 'must not be equal to';
          break;
        }
        case 'GT': {
          conditionText = 'must be greater than';
          break;
        }
        case 'GTE': {
          conditionText = 'must be greater than or equal to';
          break;
        }
        case 'LT': {
          conditionText = 'must be less than';
          break;
        }
        case 'LTE': {
          conditionText = 'must be less than or equal to';
          break;
        }
        default:
          break;
      }

      let isMoney = false;
      switch (fieldName) {
        case 'amount':
        case 'wacPrice':
        case 'contractPrice':
          isMoney = true;
          break;
        default:
          break;
      }

      const displayValue = isMoney ? formatCurrency(value) : `${value}`;
      const displayConditioningValue = isMoney
        ? formatCurrency(conditioningValue)
        : `${conditioningValue}`;

      if (conditioningFieldName) {
        return {
          message: `The ${formatField(
            fieldName
          )} ${conditionText} the ${formatField(conditioningFieldName)}`,
          details: [
            {
              key: formatField(fieldName),
              value: displayValue,
            },
            {
              key: formatField(conditioningFieldName),
              value: displayConditioningValue,
            },
          ],
        };
      } else {
        return {
          message: `${formatField(
            fieldName
          )} ${conditionText} ${displayConditioningValue}`,
          details: [
            {
              key: formatField(fieldName),
              value: displayValue,
            },
          ],
        };
      }
    }
    case 'invalid-wac-price': {
      const { data } = error.extraProperties;
      if (data.wac <= data.contractPrice) {
        return {
          message: `The WAC price is less than the contract price.`,
          details: [
            {
              key: 'Product',
              value: productId,
            },
            {
              key: 'WAC price',
              value: formatCurrency(data.wac),
            },
            {
              key: 'Contract price',
              value: formatCurrency(data.contractPrice),
            },
          ],
        };
      } else {
        return undefined;
      }
    }

    case 'referenced-entity-has-unacceptable-value': {
      const data = error.extraProperties;
      if (data.fieldName === 'contract') {
        return {
          message: `The referenced contract ${
            data.fieldValue
          } has an unacceptable value for ${formatField(
            data.refEntityFieldName
          )}`,
          details: [
            {
              key: formatField(data.refEntityFieldName),
              value: data.refEntityFieldValue,
            },
            {
              key: 'Acceptable values',
              value: `${data.acceptableValues.join(', ')}`,
            },
          ],
        };
      } else {
        return undefined;
      }
    }

    case 'invalid-date': {
      const data = error.extraProperties;
      switch (data.condition) {
        case 'PAST':
        case 'FUTURE':
          return {
            message: `${formatField(data.fieldName)} is in the ${lowerCase(
              data.condition
            )}`,
            details: [
              {
                key: formatField(data.fieldName),
                value: truncateDatetime(data.fieldValue),
              },
            ],
          };
        case 'BEFORE':
        case 'AFTER':
          // This is a special term, used to indicate that the validation is against the current time.
          // The window can be computed via |conditioningValue - now|
          if (data.conditioningFieldName === 'CUT-OFF-DATE') {
            return {
              message: `${formatField(data.fieldName)} is ${lowerCase(
                data.condition
              )} the cut-off date`,
              details: [
                {
                  key: formatField(data.fieldName),
                  value: truncateDatetime(data.fieldValue),
                },
                {
                  key: 'Cut-off date',
                  value: truncateDatetime(data.conditioningValue),
                },
              ],
            };
          }

          return {
            message: `${formatField(data.fieldName)} is ${lowerCase(
              data.condition
            )} ${formatField(data.conditioningFieldName)}`,
            details: [
              {
                key: formatField(data.fieldName),
                value: truncateDatetime(data.fieldValue),
              },
              {
                key: formatField(data.conditioningFieldName),
                value: truncateDatetime(data.conditioningValue),
              },
            ],
          };
        case 'NEQ':
        default:
          return {
            message: `${formatField(
              data.fieldName
            )} is not equal to ${formatField(data.conditioningFieldName)}`,
            details: [
              {
                key: formatField(data.fieldName),
                value: truncateDatetime(data.fieldValue),
              },
              {
                key: formatField(data.conditioningFieldName),
                value: truncateDatetime(data.conditioningValue),
              },
            ],
          };
      }
    }

    case 'entity-not-in-contract': {
      const data = error.extraProperties;
      const entityName = getTradingPartnerName(data.entityId, tradingPartners);
      return {
        message: `${
          data.entityType === 'GPO' ? 'GPO' : 'Distributor'
        } ${entityName} is not in the contract ${data.contractId}`,
        details: [],
      };
    }

    case 'invalid-string-value': {
      const data = error.extraProperties;
      const fieldName = formatField(data.fieldName);
      let message = '';
      if ((entity as ChargebackRequestRow).debit_memo_id !== undefined) {
        message = `${fieldName} doesn't match the value in the original chargeback`;
      } else {
        message = `${fieldName} doesn't match the expected value`;
      }
      return {
        message,
        details: [
          {
            key: 'Expected value',
            value: data.conditioningValue,
          },
          {
            key: 'Actual value',
            value: data.fieldValue,
          },
        ],
      };
    }

    case 'invalid-chargeback': {
      const data = error.extraProperties;
      let message = '';
      if (data.reason === 'missing-original') {
        message = `There is no associated original or rebill chargeback`;
      } else if (data.reason === 'original-not-approved') {
        message = `The associated original or rebill chargeback must be approved`;
      } else if (data.reason === 'after-valid-return') {
        message = `Must not submit a chargeback on the same thread after a valid return`;
      } else if (data.reason === 'after-valid-credit-only') {
        message = `Must not submit a chargeback on the same thread after a valid credit only`;
      } else {
        // Catch all bucket. Shouldn't get here.
        message = `The chargeback is invalid because of error code ${data.reason}`;
      }
      return {
        message,
        details: [],
      };
    }

    case 'generic-zkp-chargeback-error': {
      const rawMessage = error.extraProperties.description;
      // Note: remove the "Stack backtrace" to make the message cleaner. This is just a short-term improvement.
      const sanitizedMessage = rawMessage.slice(
        0,
        rawMessage.indexOf('Stack backtrace')
      );
      return {
        message: sanitizedMessage,
        details: [],
      };
    }

    default:
      return {
        message: 'Unknown Error',
        details: [],
      };
  }
};

// Return Error Message
const errorCodeToMessage = (
  error: ErrorCode | ContractError,
  entity: EntityDetails,
  tradingPartners?: Dictionary<TradingPartner>
): ErrorWithMessage => {
  const message = getMessage(error, entity, tradingPartners);
  const details = message ? message.details : [];

  // Include HDA numbers in the details if applicable
  const hdaCodes = error.identifiers
    ?.filter((id) => id.startsWith('hda:number:'))
    .map((id) => id.split(':')[2]);

  if (hdaCodes?.length > 0) {
    details.push({
      key: 'HDA Error Code',
      value: hdaCodes.sort().join(', '),
    });
  }

  return {
    ...error,
    message: upperFirst(message?.message),
    details,
  };
};

const hashErrorWithMessage = (error: ErrorWithMessage): string => {
  const line =
    error.message +
    error.details.map(({ key, value }) => `${key}${value ?? ''}`).join('');
  return crypto.createHash('sha1').update(line).digest('hex');
};

const getUniqueErrors = (data: ErrorWithMessage[]): ErrorWithMessage[] => {
  const messageHashes = new Set<string>();
  const uniqueErrors: ErrorWithMessage[] = [];
  data?.forEach((error) => {
    const hash = hashErrorWithMessage(error);
    if (!messageHashes.has(hash)) {
      uniqueErrors.push(error);
      messageHashes.add(hash);
    }
  });
  return uniqueErrors;
};

// Add error message to each error
const decoratedErrors = (
  errors: ErrorCode[],
  entity: EntityDetails,
  tradingPartners?: Dictionary<TradingPartner>
): ErrorWithMessage[] => {
  const allErrors = errors.map((error) =>
    errorCodeToMessage(error, entity, tradingPartners)
  );
  return getUniqueErrors(allErrors);
};

const dropNonRelevantWarningsForContract = (
  forContractId: string,
  errors: ErrorWithMessage[]
): ErrorWithMessage[] =>
  errors.filter(
    (w) =>
      w.severity !== 'WARNING' ||
      !w.counterparty ||
      !w.counterparty.startsWith('ml:contract-header:') ||
      w.counterparty.endsWith(`:${forContractId}`)
  );

/*
  Return 3 properties:
    severityErrors - errors with severity of 'ERROR'
    severityWarnings - errors with severity of 'WARNING'
    errorsWithMessages - all errors with their messages
*/
export const decorateAndPartitionCustomerErrorsBySeverity = (
  customer: Customer,
  forContractId?: string
): {
  severityErrors: ErrorWithMessage[];
  severityWarnings: ErrorWithMessage[];
  errorsWithMessages: ErrorWithMessage[];
} => {
  const allErrors: ErrorWithMessage[] = decoratedErrors(
    customer.errors || [],
    customer
  );
  const errorsWithMessages = forContractId
    ? dropNonRelevantWarningsForContract(forContractId, allErrors)
    : allErrors;
  const [severityWarnings, severityErrors] = partition(
    errorsWithMessages,
    (error) => error.severity === 'WARNING'
  );

  return {
    severityErrors,
    severityWarnings,
    errorsWithMessages,
  };
};

export const decorateAndPartitionContractErrorsBySeverity = (
  contract: ContractViewRow,
  tradingPartners?: Dictionary<TradingPartner>
): {
  severityErrors: ErrorWithMessage[];
  severityWarnings: ErrorWithMessage[];
  errorsWithMessages: ErrorWithMessage[];
} => {
  const headerErrors: ErrorWithMessage[] = decoratedErrors(
    contract.errors || [],
    contract,
    tradingPartners
  );
  const pricingErrors: ErrorWithMessage[] = (contract.pricings || []).flatMap(
    (pricing) => decoratedErrors(pricing.errors ?? [], pricing, tradingPartners)
  );
  const errorsWithMessages = headerErrors.concat(pricingErrors);
  const [severityWarnings, severityErrors] = partition(
    errorsWithMessages,
    (error) => error.severity === 'WARNING'
  );

  return {
    severityErrors,
    severityWarnings,
    errorsWithMessages,
  };
};

export const decorateAndPartitionChargebackErrorsBySeverity = (
  chargeback: ChargebackRequestRow,
  tradingPartners?: Dictionary<TradingPartner>
): {
  severityErrors: ErrorWithMessage[];
  severityWarnings: ErrorWithMessage[];
  errorsWithMessages: ErrorWithMessage[];
} => {
  const rawErrors = (chargeback.errors ?? []).concat(
    chargeback.responses?.flatMap((r) => r.errors) ?? []
  );
  const errorsWithMessages: ErrorWithMessage[] = decoratedErrors(
    rawErrors,
    chargeback,
    tradingPartners
  );
  const [severityWarnings, severityErrors] = partition(
    errorsWithMessages,
    (error) => error.severity === 'WARNING'
  );

  return {
    severityErrors,
    severityWarnings,
    errorsWithMessages,
  };
};

export const dirtyCustomer = (customer: CustomerViewRow): CustomerViewRow => {
  const dirtyCustomer = cloneDeep(customer);
  // Note: use uniq() for each of the field below because one piece of data can result into multiple errors
  customer.errors?.forEach((err) => {
    const addresses = dirtyCustomer.addresses ?? [];
    const cots = dirtyCustomer.classesOfTrade ?? [];
    const clms = dirtyCustomer.memberships ?? [];
    const ids = dirtyCustomer.identifiers ?? [];

    switch (err.code) {
      case 'customer-address-inconsistent-date-range':
      case 'customer-address-missing-field':
      case 'customer-address-start-date-after-end-date':
        addresses.push(err.extraProperties.data);
        dirtyCustomer.addresses = uniq(addresses);
        break;
      case 'customer-class-of-trade-illegal-date':
      case 'customer-class-of-trade-inconsistent-date-range':
      case 'customer-class-of-trade-invalid-reference':
      case 'customer-class-of-trade-missing-field':
      case 'customer-class-of-trade-start-date-after-end-date':
        cots.push(err.extraProperties.data);
        dirtyCustomer.classesOfTrade = uniq(cots);
        break;
      case 'customer-list-membership-illegal-date':
      case 'customer-list-membership-inconsistent-date-range':
      case 'customer-list-membership-invalid-reference':
      case 'customer-list-membership-missing-field':
      case 'customer-list-membership-start-date-after-end-date':
        clms.push(err.extraProperties.data);
        dirtyCustomer.memberships = uniq(clms);
        break;
      case 'illegal-identifier-reference':
      case 'missing-identifier-reference':
        ids?.push(err.extraProperties.identifier);
        dirtyCustomer.identifiers = uniq(ids);
        break;
      case 'invalid-reference':
        // This is about the parentId only
        dirtyCustomer.parentId = err.extraProperties.data;
        break;
      case 'start-date-after-end-date':
        dirtyCustomer.startDate = err.extraProperties.startDate;
        break;
      case 'relationship-illegal-date':
      case 'relationship-inconsistent-date-range':
      case 'relationship-invalid-reference':
      case 'relationship-malformed-mediledger-id':
      case 'relationship-missing-field':
      case 'relationship-missing-primary-id':
      case 'relationship-start-date-after-end-date':
        // Currently we don't handle relationships editing yet, so ignore these for now.
        // Although it will be very challenging to add these relationship errors back, because
        // the data model are not compatible between what the customer has, and what the errors has
        break;
      // All other errors are not relevant or doesn't change the customer, so ignore them as well
      default:
        break;
    }
  });
  return dirtyCustomer;
};

export const hasError = (errors: ErrorCode[]) =>
  errors.some((code) => code.severity === 'ERROR');
