// Vendor
import React, { FC, Fragment } from 'react';
import classnames from 'classnames/bind';

// Internal
import { IconErrorAlert } from 'styles/images';
import { ErrorWithMessage } from 'common/helpers/errors';
import {
  NUMBER_ERRORS_DISPLAYED,
  NUMBER_WARNINGS_DISPLAYED,
  RED_ERROR_COLOR,
  YELLOW_WARNING_COLOR,
} from 'common/constants';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

// Constants
const SEVERITY_ERROR = 'ERROR';

export interface Props {
  errors: ErrorWithMessage[];
  marginTop?: boolean;
}

const ErrorsTooltip: FC<Props> = ({ errors = [], marginTop = true }) => {
  const failures = errors.filter(({ severity }) => severity === SEVERITY_ERROR);
  const warnings = errors.filter(({ severity }) => severity !== SEVERITY_ERROR);

  const additionalFailureCount = failures.length - NUMBER_ERRORS_DISPLAYED;
  const additionalWarningCount = warnings.length - NUMBER_WARNINGS_DISPLAYED;

  return (
    <>
      {failures.length > 0 && (
        <div className={cx('column', { marginTop }, 'smallColumn')}>
          <b className={cx('key')}>Errors</b>
          {failures
            .slice(0, NUMBER_ERRORS_DISPLAYED)
            .map(({ message, details }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={cx('value')}>
                <IconErrorAlert className={cx('icon')} fill={RED_ERROR_COLOR} />
                {message}
                {details.map(({ key, value }) => (
                  <div
                    key={key}
                    style={{ paddingLeft: '30px' }}
                    data-testid={key}
                  >
                    <strong>{key}:</strong> {value}
                  </div>
                ))}
              </div>
            ))}
          {additionalFailureCount > 0 && (
            <div className={cx('value', 'additionalCount')}>
              +{additionalFailureCount} more
            </div>
          )}
        </div>
      )}
      {warnings.length > 0 && (
        <div className={cx('column', { marginTop }, 'smallColumn')}>
          <b className={cx('key')}>Warnings</b>
          {warnings
            .slice(0, NUMBER_WARNINGS_DISPLAYED)
            .map(({ message, details }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={cx('value')}>
                <IconErrorAlert
                  className={cx('icon')}
                  fill={YELLOW_WARNING_COLOR}
                />
                {message}
                {details.map(({ key, value }) => (
                  <div
                    key={key}
                    style={{ paddingLeft: '30px' }}
                    data-testid={key}
                  >
                    <strong>{key}:</strong> {value}
                  </div>
                ))}
              </div>
            ))}
          {additionalWarningCount > 0 && (
            <div className={cx('value', 'additionalCount')}>
              +{additionalWarningCount} more
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ErrorsTooltip;
