// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconProduct: FC<Props> = ({ color = '#002E5F' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='IconProductA'
        d='M10.094.75c.182 0 .337.064.465.191a.633.633 0 0 1 .191.465v2.188a.633.633 0 0 1-.191.465.633.633 0 0 1-.465.191h-.219v9.625a.84.84 0 0 1-.26.615.84.84 0 0 1-.615.26H2a.84.84 0 0 1-.615-.26.84.84 0 0 1-.26-.615V4.25H.906a.633.633 0 0 1-.465-.191.633.633 0 0 1-.191-.465V1.406c0-.182.064-.337.191-.465A.633.633 0 0 1 .906.75h9.188zM9 13.875V4.25H2V6h2.406a.21.21 0 0 1 .15.068.21.21 0 0 1 .069.15v.438a.21.21 0 0 1-.068.15.21.21 0 0 1-.15.069H2v1.75h2.406a.21.21 0 0 1 .15.068.21.21 0 0 1 .069.15v.438a.21.21 0 0 1-.068.15.21.21 0 0 1-.15.069H2v1.75h2.406a.21.21 0 0 1 .15.068.21.21 0 0 1 .069.15v.438a.21.21 0 0 1-.068.15.21.21 0 0 1-.15.069H2v1.75h7zm.875-10.5v-1.75h-8.75v1.75h8.75z'
      />
    </defs>
    <g fill={color} fillRule='evenodd' transform='translate(7 4)'>
      <mask id='IconProductB' fill='#fff'>
        <use xlinkHref='#IconProductA' />
      </mask>
      <use fill={color} fillRule='nonzero' xlinkHref='#IconProductA' />
      <g fill={color} mask='url(#IconProductB)'>
        <path d='M-7-4h24v24H-7z' />
      </g>
    </g>
  </svg>
);

export default IconProduct;
