// Vendor
import React, { FC, ReactElement } from 'react';
import classnames from 'classnames/bind';

// Internal
import { IconNotFound } from 'styles/images';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  children?: ReactElement;
  text?: string;
}

const NotFoundSection: FC<Props> = (props) => {
  const { children, text = 'Not Found' } = props;

  return (
    <div className={cx('root')}>
      <IconNotFound />
      <p className={cx('text')}>{children || text}</p>
    </div>
  );
};

export default NotFoundSection;
