// Vendor
import { first } from 'lodash';

// Internal
import { TableColumn, TableRow } from 'components/Table';
import { CustomerViewRow } from 'query';
import {
  ADDRESS_COLUMN,
  END_DATE_COLUMN,
  START_DATE_COLUMN,
} from 'components/Cells/constants';

export const getColumns = (): TableColumn[] => [
  {
    dataField: 'name',
    headerName: 'PHARMACY NAME',
    size: 'small',
    type: 'expanding',
  },
  ADDRESS_COLUMN,
  {
    dataField: 'city',
    headerName: 'CITY',
    size: 'small',
    type: 'expanding',
  },
  {
    dataField: 'state',
    headerName: 'STATE',
    size: 'small',
    type: 'expanding',
  },
  {
    dataField: 'zipCode',
    headerName: 'POSTAL CODE',
    size: 'small',
    type: 'expanding',
  },
  START_DATE_COLUMN,
  END_DATE_COLUMN,
];

export const getRows = (customers: CustomerViewRow[]): TableRow[] => {
  if (customers.length === 0) return [];

  return customers.map((customer: CustomerViewRow) => {
    const { addresses, endDate, names, startDate } = customer;
    const firstAddress = first(addresses);

    return {
      isExpandable: false,
      data: {
        name: names,
        addresses: firstAddress?.address1,
        city: firstAddress?.city,
        state: firstAddress?.state,
        zipCode: firstAddress?.zipCode,
        startDate,
        endDate,
      },
      type: 'expanding',
    };
  });
};
