import React, { FC, useEffect, useReducer } from 'react';
import classnames from 'classnames/bind';

import { NodeHealthComponent } from '../NodeHealthComponent';
import { TradingPartner } from '../../../../query';
import { useApi, useAppBarSetTitle } from '../../../../hooks';
import { mapStatusFromResponse } from '../NodeHealthComponent/helpers';

import styles from './index.module.css';
import { Breadcrumb } from '../../../../components';
import { useShowableTradingPartners } from '../../TradingPartnerManagement/TradingPartners/service';

const cx = classnames.bind(styles);

export type ConnectionStatus = 'successful' | 'error' | 'pending' | 'unknown';

export interface TradingPartnerWithStatus extends TradingPartner {
  status: ConnectionStatus;
}

type State = TradingPartnerWithStatus[];
type Action =
  | { type: 'setPartners'; payload: { partners: State } }
  | {
      type: 'updatePartner';
      payload: { id: number; status: ConnectionStatus };
    };

const initialState: TradingPartnerWithStatus[] = [];
const reducer = (currentState: State, action: Action) => {
  switch (action.type) {
    case 'setPartners':
      return action.payload.partners;
    case 'updatePartner': {
      const foundIdx = currentState.findIndex(
        (partner) => partner.id === action.payload.id
      );
      if (foundIdx >= 0) {
        const previousPartners = currentState.slice(0, foundIdx);
        const updatedPartner = {
          ...currentState[foundIdx],
          status: action.payload.status,
        };
        const finalPartners = currentState.slice(
          foundIdx + 1,
          currentState.length
        );
        return previousPartners.concat([updatedPartner]).concat(finalPartners);
      }
      return currentState;
    }
    default:
      return currentState;
  }
};

export const NodeHealthContainer: FC<{}> = () => {
  useAppBarSetTitle('Node Health Check');
  const api = useApi();
  const { partners: partnersToShow, loading } = useShowableTradingPartners();
  const [partners, dispatch] = useReducer(reducer, initialState); // useReducer is necessary because the multiple async state updates.

  useEffect(() => {
    const partnersWithStatus: TradingPartnerWithStatus[] =
      partnersToShow
        ?.filter((tp) => tp.visualStatus === 'authorize')
        .map((partner) => ({
          ...partner,
          status: 'unknown',
        })) || [];

    dispatch({
      type: 'setPartners',
      payload: {
        partners: partnersWithStatus,
      },
    });
  }, [partnersToShow]);

  const updatePartner = (memberId: number, status: ConnectionStatus) => {
    dispatch({ type: 'updatePartner', payload: { id: memberId, status } });
  };

  const getStatusForNode = (id: number): void => {
    updatePartner(id, 'pending');
    api
      ?.checkNodeHealthStatus(id)
      .then((response) =>
        response.data.testResults.find((res) => res.memberId === id.toString())
      )
      .then((nodeStatusResult) =>
        nodeStatusResult
          ? updatePartner(id, mapStatusFromResponse(nodeStatusResult?.status))
          : updatePartner(id, 'error')
      )
      .catch(() => updatePartner(id, 'error'));
  };

  return (
    <div className={cx('main-container')}>
      <Breadcrumb
        crumbs={['Admin Dashboard', 'Node Health Check']}
        links={['/admin', '/admin/node-health-check']}
      />
      <div className={cx('nodes-health-container')}>
        <NodeHealthComponent
          getStatusForNode={getStatusForNode}
          tradingPartners={partners}
          loading={loading}
        />
      </div>
    </div>
  );
};
