export default {
  error: {
    backgroundColor: '#f0cbcf',
    color: '#cb6671',
  },
  icon: {
    marginRight: '8px',
    position: 'relative',
    top: 4,
  },
  info: {
    backgroundColor: '#b3d6fe',
    color: '#1482fc',
  },
  session: {
    backgroundColor: '#b3d6fe',
    color: '#1482fc',
  },
  success: {
    backgroundColor: '#bbf1e5',
    color: '#00bc96',
  },
  warning: {
    backgroundColor: '#ffebcc',
    color: '#f99704',
  },
  'error-alert': {
    backgroundColor: '#f0cbcf',
    color: '#cb6671',
  },
};
