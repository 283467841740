// External
import { Dispatch, ReactNode, SetStateAction } from 'react';

// Internal
import { MultiPageFormStep } from '../service';

type OnNextCallback = (activeStep: number, activeSubStep: number) => void;

export const internalOnNext = (
  stepsData: MultiPageFormStep[],
  activeStep: number,
  activeSubStep: number,
  setActiveStep: Dispatch<SetStateAction<number>>,
  setActiveSubStep: Dispatch<SetStateAction<number>>,
  externalOnNext: OnNextCallback
) => {
  const nextStepViews: ReactNode[] = stepsData[activeStep + 1]?.views;

  const currentStepViews: ReactNode[] = stepsData[activeStep]?.views;

  // if current step has sub-steps && substep can be incremented on click
  if (
    currentStepViews &&
    currentStepViews.length > 1 &&
    activeSubStep < currentStepViews.length - 1
  ) {
    // internal steps calculations
    setActiveSubStep(activeSubStep + 1);

    // custom logic from external callback
    externalOnNext(activeStep, activeSubStep + 1);
    // if next step exists and has sub-steps
  } else if (nextStepViews && nextStepViews.length > 1) {
    // internal steps calculations
    setActiveStep(activeStep + 1);
    setActiveSubStep(0);

    // custom logic from external callback
    externalOnNext(activeStep + 1, 0);
  } else {
    // internal steps calculations
    setActiveStep(activeStep + 1);

    // custom logic from external callback
    externalOnNext(activeStep + 1, 0);
  }
};

export const internalOnBack = (
  stepsData: MultiPageFormStep[],
  activeStep: number,
  activeSubStep: number,
  setActiveStep: Dispatch<SetStateAction<number>>,
  setActiveSubStep: Dispatch<SetStateAction<number>>
) => {
  const prevStepViews: ReactNode[] = stepsData[activeStep - 1]?.views;

  const currentStepViews: ReactNode[] = stepsData[activeStep]?.views;

  // if current step has sub-steps && sub-step can be decremented on click
  if (currentStepViews && currentStepViews.length > 1 && activeSubStep > 0) {
    // internal steps calculations
    setActiveSubStep(activeSubStep - 1);
  } else if (prevStepViews && prevStepViews.length > 1) {
    // internal steps calculations
    setActiveStep(activeStep - 1);
    setActiveSubStep(prevStepViews.length - 1);
  } else {
    // internal steps calculations
    setActiveStep(0);
  }
};
