// Vendor
import { combineReducers } from 'redux';

// Internal
import { AppService } from 'actions';
import common from './common';
import configReducer from './config';

const reducers = {
  common,
  service: AppService.reducer,
  config: configReducer,
};

const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
