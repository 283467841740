// Vendor
import React from 'react';

const IconUpload = () => (
  <svg width='55' height='38' viewBox='0 0 55 38'>
    <path
      fill='#525F7F'
      d='M44 38c2.005 0 3.853-.48 5.543-1.442 1.69-.961 3.022-2.276 3.996-3.944.974-1.668 1.461-3.492 1.461-5.471 0-1.98-.53-3.831-1.59-5.556s-2.478-3.068-4.254-4.029c.23-.848.344-1.725.344-2.63 0-1.696-.43-3.28-1.29-4.75-.858-1.47-2.033-2.629-3.523-3.477-1.49-.848-3.093-1.272-4.812-1.272-1.432 0-2.807.31-4.125.933-1.375-1.98-3.151-3.535-5.328-4.666C28.245.566 25.896 0 23.375 0c-2.635 0-5.099.636-7.39 1.908-2.292 1.273-4.126 2.998-5.5 5.175-1.376 2.177-2.12 4.566-2.235 7.167-2.464.848-4.454 2.333-5.973 4.453C.76 20.823 0 23.185 0 25.786c0 2.205.559 4.24 1.676 6.107 1.117 1.866 2.62 3.35 4.511 4.453C8.079 37.449 10.142 38 12.375 38H44zm-.333-3H11.722c-1.736 0-3.356-.43-4.86-1.29-1.506-.858-2.692-2.033-3.56-3.523C2.434 28.698 2 27.095 2 25.375c0-2.406.81-4.512 2.43-6.316 1.62-1.805 3.617-2.88 5.99-3.223-.058-.458-.087-.945-.087-1.461 0-2.234.565-4.297 1.693-6.188 1.129-1.89 2.648-3.394 4.557-4.511C18.493 2.559 20.576 2 22.833 2c2.605 0 4.948.716 7.032 2.148 2.083 1.433 3.588 3.295 4.513 5.586 1.39-1.49 3.068-2.234 5.035-2.234 1.968 0 3.632.673 4.992 2.02 1.36 1.346 2.04 2.964 2.04 4.855 0 1.604-.492 3.008-1.476 4.21 2.025.345 3.703 1.276 5.034 2.794S52 24.687 52 26.75c0 2.292-.81 4.24-2.43 5.844S45.98 35 43.666 35zM26.8 30c.286 0 .53-.101.73-.304.201-.202.302-.448.302-.737v-13.1l5.846 5.899c.229.231.487.347.773.347.287 0 .516-.116.688-.347l.516-.434c.23-.231.344-.491.344-.78 0-.29-.115-.521-.344-.695l-8.425-8.589c-.23-.173-.487-.26-.774-.26-.287 0-.516.087-.688.26l-8.511 8.59c-.172.173-.258.404-.258.693 0 .29.086.55.258.781l.516.434c.172.231.401.347.688.347.286 0 .544-.116.773-.347l5.846-5.9v13.1c0 .29.1.536.301.738.201.203.445.304.731.304h.688z'
      opacity='.198'
    />
  </svg>
);

export default IconUpload;
