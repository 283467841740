// Vendor
import gql from 'graphql-tag';

export interface QueryClassOfTradeResult {
  classesOfTrade?: ClassOfTrade[];
}

export interface ClassOfTrade {
  id: string;
  memberId: number;
  description: string;
  name: string;
  startDate: string;
  endDate: string;
  timestamp: string;
}

export const QUERY_MULTIPLE_COT = gql`
  query QueryMultipleClassOfTrade($ids: [String!], $memberId: Int!) {
    classesOfTrade: dash_classes_of_trade(
      where: { id: { _in: $ids }, member_id: { _eq: $memberId } }
      distinct_on: id
      order_by: [{ id: desc }, { timestamp: desc }]
    ) {
      id
      memberId: member_id
      description
      name
      startDate: start_date
      endDate: end_date
      timestamp
    }
  }
`;
