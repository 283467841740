// Vendor
import React, {
  CSSProperties,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames/bind';
import { FormControl, Select as MUISelect } from '@material-ui/core';
import { get } from 'lodash';

// Internal
import { DEFAULT_GREY, DISABLED_COLOR } from 'common/constants';
import { IconArrowDown, IconArrowUp, IconFilters } from 'styles/images';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  children?: Element[] | ReactElement[];
  disabled?: boolean;
  icon?: string;
  label: string;
  style?: CSSProperties;
  className?: string;
  meta?: {
    error: string;
    touched: boolean;
  };
}

const Select: FC<Props> = (props) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [touched, setTouched] = useState(false);

  const {
    children,
    disabled,
    icon,
    label = '',
    style = {},
    meta,
    className = '',
  } = props;

  useEffect(() => {
    if (open) {
      setTouched(true);
    }
  }, [open]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const iconMap = (icon: string) => {
    const iconList = {
      filter: <IconFilters color={disabled ? DISABLED_COLOR : DEFAULT_GREY} />,
    };
    return <span className={cx('icon')}>{get(iconList, icon, null)}</span>;
  };

  return (
    <FormControl variant='outlined' className={className}>
      <MUISelect
        displayEmpty
        disabled={disabled}
        IconComponent={() => (
          <span className={cx('arrow')}>
            {open ? (
              <IconArrowUp />
            ) : (
              <IconArrowDown color={disabled ? DISABLED_COLOR : DEFAULT_GREY} />
            )}
          </span>
        )}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: 27,
          },
          getContentAnchorEl: null,
        }}
        onChange={handleChange}
        onBlur={() => {
          setTouched(true);
        }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        renderValue={(value: any): ReactNode => (
          <div className={cx('row')}>
            <span className={cx('icon')}>{icon ? iconMap(icon) : null}</span>
            <span className={disabled ? cx('disabled') : cx('text')}>
              {label || value}
            </span>
          </div>
        )}
        style={{ ...style }}
        value={value}
        defaultValue={value}
        data-testid='select'
      >
        {children}
      </MUISelect>
      {meta
        ? meta.error &&
          !open &&
          touched && (
            <div className={cx('error')}>
              {meta.error === 'Required'
                ? !open && touched && meta.error
                : meta.error}
            </div>
          )
        : null}
    </FormControl>
  );
};

export default Select;
