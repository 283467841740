// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconRoster: FC<Props> = ({ color = '#002E5F' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='IconRosterA'
        d='M7.906 7.313a.21.21 0 0 1 .15.068.21.21 0 0 1 .069.15v.438a.21.21 0 0 1-.068.15.21.21 0 0 1-.15.069H4.843a.21.21 0 0 1-.15-.069.21.21 0 0 1-.069-.15V7.53a.21.21 0 0 1 .068-.15.21.21 0 0 1 .15-.068h3.063zm0 2.625a.21.21 0 0 1 .15.068.21.21 0 0 1 .069.15v.438a.21.21 0 0 1-.068.15.21.21 0 0 1-.15.069H4.843a.21.21 0 0 1-.15-.069.21.21 0 0 1-.069-.15v-.438a.21.21 0 0 1 .068-.15.21.21 0 0 1 .15-.068h3.063zM3.312 7.094c.183 0 .338.064.465.191a.633.633 0 0 1 .192.465.633.633 0 0 1-.192.465.633.633 0 0 1-.465.191.633.633 0 0 1-.464-.191.633.633 0 0 1-.192-.465c0-.182.064-.337.192-.465a.633.633 0 0 1 .464-.191zm0 2.625c.183 0 .338.064.465.191a.633.633 0 0 1 .192.465.633.633 0 0 1-.192.465.633.633 0 0 1-.465.191.633.633 0 0 1-.464-.191.633.633 0 0 1-.192-.465c0-.182.064-.337.192-.465a.633.633 0 0 1 .464-.191zM9.437 2.5c.365 0 .675.128.93.383s.383.565.383.93v9.624c0 .365-.128.675-.383.93a1.266 1.266 0 0 1-.93.383H1.562c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93V3.813c0-.365.128-.675.383-.93s.565-.383.93-.383H3.75c0-.474.173-.884.52-1.23.346-.347.756-.52 1.23-.52s.884.173 1.23.52c.347.346.52.756.52 1.23h2.187zM5.5 2.063a.426.426 0 0 0-.314.123.426.426 0 0 0-.124.314c0 .128.042.232.124.314a.426.426 0 0 0 .314.124.426.426 0 0 0 .314-.124.426.426 0 0 0 .123-.314.426.426 0 0 0-.123-.314.426.426 0 0 0-.314-.123zm3.937 11.156V4.03a.21.21 0 0 0-.068-.15.21.21 0 0 0-.15-.068H8.125v.875a.426.426 0 0 1-.123.314.426.426 0 0 1-.315.123H3.312a.426.426 0 0 1-.314-.123.426.426 0 0 1-.123-.314v-.875H1.781a.21.21 0 0 0-.15.068.21.21 0 0 0-.069.15v9.188a.21.21 0 0 0 .069.15.21.21 0 0 0 .15.068H9.22a.21.21 0 0 0 .15-.068.21.21 0 0 0 .068-.15z'
      />
    </defs>
    <g fill={color} fillRule='evenodd' transform='translate(7 4)'>
      <mask id='IconRosterB' fill='#fff'>
        <use xlinkHref='#IconRosterA' />
      </mask>
      <use fill={color} fillRule='nonzero' xlinkHref='#IconRosterA' />
      <g fill={color} mask='url(#IconRosterB)'>
        <path d='M-7-4h24v24H-7z' />
      </g>
    </g>
  </svg>
);

export default IconRoster;
