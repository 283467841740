// Vendor
import React from 'react';

const IconNotFound = () => (
  <svg width='79px' height='67px' viewBox='0 0 79 67' version='1.1'>
    <defs>
      <rect id='path-1' x='0' y='0.85712953' width='71' height='29' rx='4' />
      <filter
        x='-9.9%'
        y='-17.2%'
        width='119.7%'
        height='148.3%'
        filterUnits='objectBoundingBox'
        id='filter-2'
      >
        <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          stdDeviation='2'
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
        />
        <feComposite
          in='shadowBlurOuter1'
          in2='SourceAlpha'
          operator='out'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.48627451   0 0 0 0 0.560784314   0 0 0 0 0.592156863  0 0 0 0.138385052 0'
          type='matrix'
          in='shadowBlurOuter1'
        />
      </filter>
    </defs>
    <g
      id='Empty-States'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='02_Empty-State-Copy'
        transform='translate(-309.000000, -631.000000)'
      >
        <g id='Top' transform='translate(289.000000, 586.000000)'>
          <g id='GTIN-Description' transform='translate(23.426316, 17.000000)'>
            <g
              id='Empty-Error-State'
              transform='translate(0.573684, 28.000000)'
            >
              <ellipse
                id='Oval'
                fill='#F5F6FA'
                cx='35.6770574'
                cy='33.4164589'
                rx='33.1982544'
                ry='33.4164589'
              />
              <g id='Tables' transform='translate(0.000000, 19.109885)'>
                <use
                  fill='black'
                  fillOpacity='1'
                  filter='url(#filter-2)'
                  xlinkHref='#path-1'
                />
                <rect
                  stroke='#F5F6FA'
                  strokeWidth='1'
                  strokeLinecap='square'
                  fill='#FFFFFF'
                  fillRule='evenodd'
                  x='0.5'
                  y='1.35712953'
                  width='70'
                  height='28'
                  rx='4'
                />
                <path
                  d='M8.45294702,10.3100805 L29.2756597,10.3100805 C30.0609959,10.3100805 30.6976367,10.9467212 30.6976367,11.7320574 C30.6976367,12.5173936 30.0609959,13.1540344 29.2756597,13.1540344 L8.45294702,13.1540344 C7.66761082,13.1540344 7.03097004,12.5173936 7.03097004,11.7320574 C7.03097004,10.9467212 7.66761082,10.3100805 8.45294702,10.3100805 Z'
                  id='Rectangle-4'
                  fill='#E6E8F2'
                />
                <path
                  d='M8.52197697,16.2908244 L62.478023,16.2908244 C63.2633592,16.2908244 63.9,16.9274651 63.9,17.7128013 C63.9,18.4981375 63.2633592,19.1347783 62.478023,19.1347783 L8.52197697,19.1347783 C7.73664078,19.1347783 7.1,18.4981375 7.1,17.7128013 C7.1,16.9274651 7.73664078,16.2908244 8.52197697,16.2908244 Z'
                  id='Rectangle-4-Copy'
                  fill='#E6E8F2'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconNotFound;
