// Vendor
import MenuItem from '@material-ui/core/MenuItem';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';
import { get } from 'lodash';

// Internal
import { required } from 'common/validation';
import { DatePicker, Select, TextField } from 'components';
import { useDocumentTypes } from 'hooks';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  change?: (key: string, value: string) => void;
  disabled?: boolean;
  values: {
    documentType: {};
    manufacturer: string;
  };
}

const InputValues: FC<Props> = (props) => {
  const { change, disabled, values } = props;
  const documentTypes = useDocumentTypes();

  const types = documentTypes.filter(({ attributes }) =>
    attributes.some(
      // @ts-ignore
      ({ defaultValue }: { defaultValue: string }) =>
        defaultValue === values.manufacturer || ''
    )
  );

  const docTypes = Object.entries(values.documentType || {})
    .filter(([, v]) => v !== false)
    .map(([k]) => k);

  return (
    <div className={cx('root')}>
      {docTypes.map((docType, i) => {
        const lastType = i === docTypes.length - 1;
        // @ts-ignore
        const selectedDocument = types.find((doc) => doc.type === docType);
        const displayedFields =
          selectedDocument &&
          // @ts-ignore
          selectedDocument.attributes.filter(
            (attribute: { fieldName: string }) => {
              const { fieldName } = attribute;
              return fieldName !== 'manufacturer';
            }
          );

        return (
          <section
            key={docType}
            className={cx('editSection', { marginBottom: lastType })}
          >
            <span className={cx('title')}>{docType}</span>
            <div className={cx('row')}>
              {displayedFields?.map(
                (attribute: {
                  fieldName: string;
                  isRequired: boolean;
                  name: string;
                  type: string;
                }) => {
                  const { fieldName, isRequired, name, type } = attribute;
                  const fieldValue = get(
                    values,
                    `${docType}.${fieldName}`,
                    ''
                  ) as string;

                  return (
                    !(disabled && !fieldValue) && (
                      <div key={fieldName} className={cx('field')}>
                        <span className={cx('key')}>
                          {name}
                          {isRequired && ' *'}
                        </span>
                        {type === 'date' && (
                          <Field
                            // @ts-ignore
                            component={DatePicker}
                            disabled={disabled}
                            name={`${docType}.${fieldName}`}
                            style={{ width: '100%' }}
                            validate={required}
                          />
                        )}
                        {type === 'string' && (
                          <Field
                            // @ts-ignore
                            component={TextField}
                            disabled={disabled}
                            name={`${docType}.${fieldName}`}
                            placeholder={name}
                            validate={isRequired ? required : undefined}
                          />
                        )}
                        {type === 'boolean' && (
                          <Field
                            // @ts-ignore
                            component={Select}
                            disabled={disabled}
                            label={
                              fieldValue === 'true'
                                ? 'Yes'
                                : fieldValue === 'false'
                                ? 'No'
                                : ''
                            }
                            name={`${docType}.${fieldName}`}
                            validate={isRequired ? required : undefined}
                          >
                            <MenuItem
                              onClick={() => {
                                if (change)
                                  change(`${docType}.${fieldName}`, 'true');
                              }}
                              value='true'
                              style={{
                                maxWidth: 'none',
                                paddingRight: 0,
                              }}
                            >
                              Yes
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                if (change)
                                  change(`${docType}.${fieldName}`, 'false');
                              }}
                              value='false'
                              style={{
                                maxWidth: 'none',
                                paddingRight: 0,
                              }}
                            >
                              No
                            </MenuItem>
                          </Field>
                        )}
                      </div>
                    )
                  );
                }
              )}
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default InputValues;
