// Vendor
import React, { FC } from 'react';

interface Props {
  fill?: string;
}

const IconSuccess: FC<Props> = ({ fill = '#00BC96', ...rest }) => (
  <svg {...rest} width='12' height='13' viewBox='0 0 12 13'>
    <path
      fill={fill}
      fillRule='nonzero'
      d='M6 .688c1.047 0 2.016.261 2.906.785a5.873 5.873 0 0 1 2.121 2.12c.524.891.785 1.86.785 2.907a5.634 5.634 0 0 1-.785 2.906 5.873 5.873 0 0 1-2.12 2.121c-.891.524-1.86.785-2.907.785a5.634 5.634 0 0 1-2.906-.785 5.873 5.873 0 0 1-2.121-2.12A5.634 5.634 0 0 1 .187 6.5c0-1.047.262-2.016.786-2.906a5.873 5.873 0 0 1 2.12-2.121A5.634 5.634 0 0 1 6 .688zm0 1.125c-.844 0-1.625.21-2.344.632a4.727 4.727 0 0 0-1.71 1.711A4.549 4.549 0 0 0 1.311 6.5c0 .844.211 1.625.633 2.344.422.719.992 1.289 1.711 1.71.719.423 1.5.633 2.344.633.844 0 1.625-.21 2.344-.632a4.727 4.727 0 0 0 1.71-1.711c.423-.719.633-1.5.633-2.344 0-.844-.21-1.625-.632-2.344a4.727 4.727 0 0 0-1.711-1.71A4.549 4.549 0 0 0 6 1.812zm3.281 3.046a.291.291 0 0 1 .094.211c0 .078-.031.141-.094.188L5.25 9.266a.291.291 0 0 1-.21.093.223.223 0 0 1-.188-.093L2.719 7.133a.291.291 0 0 1-.094-.211c0-.078.031-.14.094-.188l.539-.539a.253.253 0 0 1 .187-.07c.078 0 .149.023.211.07l1.383 1.43L8.367 4.32a.253.253 0 0 1 .188-.07c.078 0 .148.031.21.094l.516.515z'
    />
  </svg>
);

export default IconSuccess;
