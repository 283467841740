// Vendor
import React, { FC } from 'react';
import { Form } from 'react-final-form';

// Internal
import { useApi, DocumentApiArgs } from 'hooks';
import AddDocument from './AddDocument';
import { CustomerWithDocuments } from '../types';

interface Props {
  changeSection: (section: string) => void;
  customer: CustomerWithDocuments;
  memberId: number;
}

const Container: FC<Props> = (props) => {
  const { changeSection, customer, memberId } = props;
  const api = useApi();
  const addDocument = (args: DocumentApiArgs) => api?.documents(args);

  return (
    <Form
      onSubmit={() => {}}
      render={({ errors, form, handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <AddDocument
            addDocument={addDocument}
            change={form.change}
            changeSection={changeSection}
            customer={customer}
            errors={errors}
            memberId={memberId}
            values={values}
          />
        </form>
      )}
    />
  );
};

export default Container;
