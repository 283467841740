// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { identity } from 'fp-ts/function';

// Internal
import SearchBar from 'components/SearchBar';
import { TableRow } from 'components/Table';
import { IconSystemError } from 'styles/images';
import {
  useAppBarSetTitle,
  useIsFirstRender,
  useTradingPartnerConfig,
} from 'hooks';
import { NUMBER_PER_PAGE } from 'common/constants';
import { Button, Pagination, Table } from 'components';
import {
  QUERY_PRODUCT_LISTS,
  QUERY_PRODUCT_LISTS_COUNT,
  QueryProductListsCountResult,
  QueryProductListsResult,
} from 'query';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import {
  CurrentPageParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { withMemberId } from 'services/queryService';
import { COLUMNS, filtersToQuery, rawDataToRows } from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const ProductLists: FC = () => {
  useAppBarSetTitle('Product Lists');

  // Get additional information
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const navigate = useNavigate();

  // Search
  const { currentSearch: searchMap } = usePersistedSearchParams(
    {
      module: 'product-lists',
      params: [SearchParamConfig, CurrentPageParamConfig],
    },
    SessionStorage
  );
  const [currSearch, search] = searchMap[SearchParamConfig.key];
  const [currentPage, updateCurrentPage] =
    searchMap[CurrentPageParamConfig.key];

  // Table rows
  const [rows, updateRows] = useState([] as TableRow[]);
  // Order
  const [order] = useState({ timestamp: 'desc' });
  // Where condition
  const [where, updateWhere] = useState(
    withMemberId(filtersToQuery(currSearch), memberId)
  );

  // Fetch the data
  const {
    data: productListsData,
    loading,
    error,
    refetch,
  } = useQuery<QueryProductListsResult>(QUERY_PRODUCT_LISTS, {
    variables: {
      where,
      order,
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
    },
    skip: !memberId,
  });
  const productLists = productListsData?.productLists || [];

  // Fetch the count
  const {
    data: countData,
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<QueryProductListsCountResult>(QUERY_PRODUCT_LISTS_COUNT, {
    variables: {
      where,
      order,
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
    },
    skip: !memberId,
  });

  const refetchData = () =>
    Promise.all([refetch({ data: productListsData }), refetchCount()]);

  useEffect(() => {
    refetchData().then(identity);
  }, []);

  // Format the raw data
  useEffect(() => {
    if (!loading && productListsData) {
      updateRows(rawDataToRows(productListsData));
    }
  }, [productListsData, loading]);

  // Build the search query
  const firstRender = useIsFirstRender();
  useEffect(() => {
    // First render help us to identify when we navigate through URL
    if (
      (firstRender && currentPage === 1) ||
      (!firstRender && currentPage > 1)
    ) {
      updateCurrentPage(1);
    }
    updateWhere(withMemberId(filtersToQuery(currSearch), memberId));
  }, [currSearch]);

  const hasProductLists = productLists.length > 0;

  const isFiltered = currSearch !== '';
  const disableSearchFilter = !loading && !hasProductLists && !isFiltered;

  // Table with layout
  return !memberId || error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('root')}>
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            onSubmit={search}
            placeholder='Search by ID, name or description'
            disabled={disableSearchFilter}
            text={currSearch || ''}
          />
        </div>
        <div className={cx('floatRight', 'row')}>
          <Button
            dataTestId='createProductList'
            label='Create Product List'
            onClick={() => navigate('/product-lists/create', { replace: true })}
            style={{
              marginRight: 8,
              minWidth: 152,
            }}
          />
        </div>
      </section>
      <Table
        columns={COLUMNS}
        emptyText='No product lists found'
        isLoading={loading}
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={countLoading}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={hasProductLists}
        totalCount={countData?.productListsCount?.aggregate.count || 0}
      />
    </div>
  );
};

export default ProductLists;
