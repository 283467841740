// Vendor
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

// Internal
import { NonLinearTab } from 'components/MultipageForm/service';
import { TabberButtons } from './TabberButtons';
import { TabberViews } from './TabberViews';

export interface NonLinearTabberProps {
  setTab?: Dispatch<SetStateAction<number>>;
  tab?: number;
  tabs: NonLinearTab[];
  views: ReactNode[];
  fullWidth?: boolean;
}

const NonLinearTabber: FC<NonLinearTabberProps> = (props) => {
  const {
    setTab: setExternalTab = undefined,
    tab: externalTab = undefined,
    tabs,
    views,
    fullWidth = false,
  } = props;

  const [internalTab, setInternalTab] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (setExternalTab) {
      setExternalTab(tabIndex);
    } else if (!externalTab) {
      setInternalTab(tabIndex);
    }
  };

  const tab = externalTab || internalTab;

  return (
    <div>
      <TabberButtons
        onChange={handleChange}
        tab={tab}
        tabs={tabs}
        fullWidth={fullWidth}
      />
      <TabberViews views={views} tab={tab} />
    </div>
  );
};

export default NonLinearTabber;
