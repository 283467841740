// Vendor
import { Dialog, Slide } from '@material-ui/core';
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames/bind';

// Internal
import { IconAlertCustom } from 'styles/images';
import {
  EntityWithError,
  EntityWithId,
} from 'flatfile-import/application/publisher/publisher-service';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface ErrorDetailDialogProps<E extends EntityWithId> {
  entries: EntityWithError<E>[];
  open: boolean;
  onClose: () => void;
  entityLabel: string;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction='left' ref={ref} {...props} />
);

const ErrorDetailDialog = <E extends EntityWithId>(
  props: ErrorDetailDialogProps<E>
) => {
  const { entries, open, onClose, entityLabel } = props;

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          position: 'absolute',
          maxHeight: '100vh',
          height: '100vh',
          right: '0',
          margin: '0',
          width: '500px',
          borderRadius: '0',
          zIndex: 999,
          fontFamily: 'Graphik',
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#8D8B99',
          opacity: '0.48',
        },
      }}
      TransitionComponent={Transition as any}
      keepMounted
    >
      <div style={{ padding: '32px' }}>
        <div className={cx('header-container')}>
          <IconAlertCustom width='24' height='24' fill='#EF4444' />
          <h2 className={cx('header-title')}>Import Failures</h2>
          <CloseIcon
            onClick={onClose}
            className={cx('header-close-icon')}
            data-testid='close-icon-btn'
          />
        </div>
        <div className={cx('header-separator')} />
        <div className={cx('table-wrapper')}>
          <table cellPadding='8' className={cx('table-container')}>
            <thead className={cx('table-head')}>
              <tr>
                <th className={cx('table-column-id')}>{`${entityLabel} ID`}</th>
                <th className={cx('table-column-error')}>Errors</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry) => (
                <tr key={entry.entity.id} className={cx('table-row')}>
                  <td className={cx('table-row-error-wrapper')}>
                    {entry.entity.id}
                  </td>
                  <td className={cx('table-row-error-wrapper')}>
                    {entry.error}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorDetailDialog;
