export function withMemberId(where: any, memberId?: number): any {
  if (!memberId) {
    return where;
  }

  return {
    ...where,
    member_id: { _eq: memberId },
  };
}
