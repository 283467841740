// Vendor
import dayjs from 'dayjs';
import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import { orderBy } from 'lodash';

// Internal
import { Button, EmptyResults, Pagination } from 'components';
import { IconDocument, IconRemove } from 'styles/images';
import { truncateDatetime } from 'common/helpers';
import { ERROR_STATE_MSG, NUMBER_PER_PAGE } from 'common/constants';
import { useTradingPartnerType } from 'hooks';
import { Document } from 'types/generated/Document';
import { Tooltip } from 'components/Tooltips';
import { CustomerWithDocuments } from '../types';

// Styles
import styles from './DocumentList.module.css';

const cx = classnames.bind(styles);

interface Props {
  changeSection: (section: string) => void;
  currentPage: number;
  customer: CustomerWithDocuments;
  isHistoryView: boolean;
  paginationToggle: (page: number) => void;
  refetch: ({ offset }: { offset: number }) => void;
  setDocument: (doc: Document) => void;
  toggleDialog: (val: boolean) => void;
  toggleHistory: (val: boolean) => void;
  totalCount: number;
  updateDeletePayload: (payload: {
    manufacturer: string;
    documentType: string;
  }) => void;
}

const DocumentList: FC<Props> = ({
  changeSection,
  currentPage,
  customer,
  isHistoryView,
  paginationToggle,
  refetch,
  setDocument,
  toggleDialog,
  toggleHistory,
  totalCount,
  updateDeletePayload,
}) => {
  useEffect(() => {
    if (toggleHistory) toggleHistory(true);
  }, [toggleHistory]);

  const tpType = useTradingPartnerType();
  const isGPO = tpType === 'GPO';
  const { documents, errors = [], timestamp = '' } = customer;
  const isErrorState = errors?.some((err) => err.severity === 'ERROR');
  const customerUpdateAfterCurrDate = dayjs(timestamp).isAfter(dayjs());
  const disabledAddDoc = isErrorState || customerUpdateAfterCurrDate;

  const onChangePage = ({ currentPage }: { currentPage: number }) => {
    paginationToggle(currentPage);
    refetch({ offset: (currentPage - 1) * NUMBER_PER_PAGE });
  };

  return (
    <>
      <section className={cx('toolbar')}>
        {isGPO && !isHistoryView && (
          <Tooltip
            disabled={!isErrorState}
            disableHoverListener={false}
            placement='bottom'
            title={<p>{ERROR_STATE_MSG}</p>}
          >
            <Button
              dataTestId='addDocumentBtn'
              disabled={disabledAddDoc}
              label='Add New Document'
              onClick={() => {
                toggleHistory(false);
                changeSection('addDocument');
              }}
              style={{
                border: 'none',
                width: '160px',
              }}
            />
          </Tooltip>
        )}
      </section>
      {orderBy(documents, (doc) => doc.updatedAt, ['desc'])?.map((document) => {
        const { id, metadata, type, updatedAt } = document;
        const status = 'active';
        const { manufacturer } = metadata as any;

        return (
          <div key={id} className={cx('document', 'row')}>
            <button
              className={cx('button', 'row')}
              onClick={() => {
                setDocument({ ...document });
                changeSection('documentDetails');
              }}
              type='button'
            >
              <IconDocument />
              <div className={cx('column', 'leftText')}>
                <span className={cx('title')}>{type}</span>
                {manufacturer}
              </div>
            </button>
            <div className={cx('center', 'column')}>
              <div className={cx('row')}>
                <span className={cx('circle', status)} />
                {isGPO && !isHistoryView && (
                  <div
                    onClick={() => {
                      if (!isErrorState) {
                        setDocument({ ...document });
                        updateDeletePayload({
                          manufacturer,
                          documentType: type,
                        });
                        toggleDialog(true);
                      }
                    }}
                  >
                    <Tooltip
                      disabled={!isErrorState}
                      disableHoverListener={false}
                      placement='bottom'
                      title={<p>{ERROR_STATE_MSG}</p>}
                    >
                      <IconRemove
                        className={cx('removeIcon', { disabled: isErrorState })}
                        color={
                          isErrorState ? 'rgba(82, 95, 127, 0.35)' : '#4F5F82'
                        }
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
              <span className={cx('date')}>{truncateDatetime(updatedAt)}</span>
            </div>
          </div>
        );
      })}
      <div style={{ display: 'flex' }}>
        <Pagination
          className={cx('pagination')}
          currentPage={currentPage}
          numberPerPage={NUMBER_PER_PAGE}
          onChangePage={onChangePage}
          isDisplayed={documents?.length > 0}
          totalCount={totalCount}
        />
      </div>
      {!documents?.length && <EmptyResults text='No documents found' />}
    </>
  );
};

export default DocumentList;
