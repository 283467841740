export const DEFAULT_EMPTY_STATE = '';
export const POLL_INTERVAL = 2000;
export const SHORT_POLL_INTERVAL = 1000;
export const OUT_OF_NETWORK_DIGIT = 50000;

export const ERROR_STATE_MSG =
  'Please ensure the latest customer update is valid before making changes to documents.';
export const ID_PREFIX_340B = 'ml:customer:77777:';
export const ID_PREFIX_DEA = 'ml:customer:99999:';
export const ID_PREFIX_HIN = 'ml:customer:88888:';
export const NUMBER_PER_PAGE = 50;
export const NUMBER_ERRORS_DISPLAYED = 2;
export const NUMBER_WARNINGS_DISPLAYED = 2;

// Authority Data
export const AHD_ID = 55555;
export const DEA_ID = 99999;
export const HIBCC_ID = 88888;
export const HRSA_ID = 77777;
export const NPI_ID = 66666;

export const DIST = 'DIST';
export const GPO = 'GPO';
export const MFR = 'MFR';

// Colors
export const DEFAULT_GREY = '#525f7f';
export const DISABLED_COLOR = '#e4ebef';
export const RED_ERROR_COLOR = '#cb6671';
export const YELLOW_WARNING_COLOR = '#f9bc60';

// Dates
export const LONG_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const SHORT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_MMDDYYYY = 'MM/DD/YYYY';
export const EARLIEST_DATE = '0001-01-01';
export const LATEST_DATE = '9999-12-31';

// Regex
export const REGEX_DIGITS = /\d/;
export const REGEX_PREFIX_340B = /340b:number:/;
export const REGEX_PREFIX_DEA = /dea:number:/;
export const REGEX_PREFIX_HIN = /hin:number:/;

// Relationships
export const RELATED_HINS = 'hin-refer-back';
export const CONTRACT_PHARMACY = 'pharmacy';

// These colors are taken from Figma
export const GREY_COLOR: string = '#F7F8FA';
export const WHITE_COLOR: string = '#FFFFFF';

export const addressTypes = [
  { label: 'Primary', name: 'primary' },
  { label: 'Ship To', name: 'shipTo' },
  { label: 'Bill To', name: 'billTo' },
  { label: 'Mail To', name: 'mailTo' },
];

export const states = [
  { label: 'Alabama', name: 'AL' },
  { label: 'Alaska', name: 'AK' },
  { label: 'Arizona', name: 'AZ' },
  { label: 'Arkansas', name: 'AR' },
  { label: 'California', name: 'CA' },
  { label: 'Colorado', name: 'CO' },
  { label: 'Connecticut', name: 'CT' },
  { label: 'Delaware', name: 'DE' },
  { label: 'Florida', name: 'FL' },
  { label: 'Georgia', name: 'GA' },
  { label: 'Hawaii', name: 'HI' },
  { label: 'Idaho', name: 'ID' },
  { label: 'Illinois', name: 'IL' },
  { label: 'Indiana', name: 'IN' },
  { label: 'Iowa', name: 'IA' },
  { label: 'Kansas', name: 'KS' },
  { label: 'Kentucky', name: 'KY' },
  { label: 'Louisiana', name: 'LA' },
  { label: 'Maine', name: 'ME' },
  { label: 'Maryland', name: 'MD' },
  { label: 'Massachusetts', name: 'MA' },
  { label: 'Michigan', name: 'MI' },
  { label: 'Minnesota', name: 'MN' },
  { label: 'Mississippi', name: 'MS' },
  { label: 'Missouri', name: 'MO' },
  { label: 'Montana', name: 'MT' },
  { label: 'Nebraska', name: 'NE' },
  { label: 'Nevada', name: 'NV' },
  { label: 'New Hampshire', name: 'NH' },
  { label: 'New Jersey', name: 'NJ' },
  { label: 'New Mexico', name: 'NM' },
  { label: 'New York', name: 'NY' },
  { label: 'North Carolina', name: 'NC' },
  { label: 'North Dakota', name: 'ND' },
  { label: 'Ohio', name: 'OH' },
  { label: 'Oklahoma', name: 'OK' },
  { label: 'Oregon', name: 'OR' },
  { label: 'Pennsylvania', name: 'PA' },
  { label: 'Rhode Island', name: 'RI' },
  { label: 'South Carolina', name: 'SC' },
  { label: 'South Dakota', name: 'SD' },
  { label: 'Tennessee', name: 'TN' },
  { label: 'Texas', name: 'TX' },
  { label: 'Utah', name: 'UT' },
  { label: 'Vermont', name: 'VT' },
  { label: 'Virginia', name: 'VA' },
  { label: 'Washington', name: 'WA' },
  { label: 'West Virginia', name: 'WV' },
  { label: 'Wisconsin', name: 'WI' },
  { label: 'Wyoming', name: 'WY' },
];

export const SHORT_TEXT = 100;
export const LONG_TEXT = 5000;
export const MAX_ID_LENGTH = 50;
export const MAX_PRODUCT_PRICE = 1000000000;

export const NO_WHITESPACES_REGEX = /^\S*\w*\S*$/;
export const NO_WHITESPACES_ERROR_MESSAGE = 'No whitespaces are allowed';
