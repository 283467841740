// Internal
import {
  isRosterOON,
  localCustomerPath,
  removeRosterIdPrefix,
  rosterCustomerPath,
} from 'common/helpers';
import { TableColumn } from 'components/Table';

export const ADDRESS_COLUMN: TableColumn = {
  dataField: 'addresses',
  headerName: 'ADDRESS',
  size: 'medium',
  type: 'expanding',
};

export const CONTRACT_ENTITY_COLUMN: TableColumn = {
  dataField: 'contractEntities',
  headerName: 'CONTRACT ENTITY',
  size: 'small',
  type: 'expanding',
};

export const COT_COLUMN: TableColumn = {
  dataField: 'cots',
  headerName: 'COT',
  size: 'small',
  type: 'expanding',
};

export const DESCRIPTION_COLUMN: TableColumn = {
  dataField: 'description',
  headerName: 'DESCRIPTION',
  size: 'medium',
  type: 'expanding',
};

export const END_DATE_COLUMN: TableColumn = {
  dataField: 'endDate',
  format: 'short',
  headerName: 'END DATE',
  size: 'small',
  type: 'date',
};

export const ERRORS_COLUMN: TableColumn = {
  errorsField: 'severityErrors',
  size: 'small',
  type: 'errors',
  warningsField: 'severityWarnings',
};

export const EXTERNAL_ID_COLUMN: TableColumn = {
  displayTextFn: (data: any) =>
    isRosterOON(data.memberId) ? removeRosterIdPrefix(data.id) : data.id,
  headerName: 'EXTERNAL ID',
  size: 'small',
  type: 'link',
  urlFn: (data: any) =>
    rosterCustomerPath(data.memberId, data.memberName, data.id),
};

export const IDENTIFIERS_COLUMN: TableColumn = {
  dataField: 'identifiers',
  headerName: 'IDENTIFIERS',
  size: 'small',
  type: 'expanding',
};

export const INTERNAL_ID_COLUMN: TableColumn = {
  displayTextFn: (data: any) => data.id,
  headerName: 'INTERNAL ID',
  size: 'small',
  type: 'link',
  urlFn: (data: any) => localCustomerPath(data.id),
};

export const MANUFACTURER_COLUMN: TableColumn = {
  dataField: 'manufacturer',
  headerName: 'MANUFACTURER',
  size: 'small',
  type: 'expanding',
};

export const NAME_COLUMN: TableColumn = {
  dataField: 'names',
  headerName: 'NAME',
  size: 'small',
  type: 'expanding',
};

export const PRICE_COLUMN: TableColumn = {
  dataField: 'price',
  headerName: 'PRICE',
  size: 'medium',
  type: 'expanding',
};

export const PRICE_TYPE_COLUMN: TableColumn = {
  dataField: 'priceType',
  headerName: 'PRICE TYPE',
  size: 'medium',
  type: 'expanding',
};

export const PRODUCT_ID_TYPE_COLUMN: TableColumn = {
  dataField: 'idType',
  headerName: 'PRODUCT ID TYPE',
  size: 'small',
  type: 'expanding',
};

export const PROGRAM_COLUMN: TableColumn = {
  dataField: 'programs',
  headerName: 'PROGRAM',
  size: 'small',
  type: 'expanding',
};

export const REASON_COLUMN: TableColumn = {
  dataField: 'reason',
  headerName: 'REASON',
  size: 'medium',
  type: 'expanding',
};

export const START_DATE_COLUMN: TableColumn = {
  dataField: 'startDate',
  format: 'short',
  headerName: 'START DATE',
  size: 'small',
  type: 'date',
};

export const STATUS_COLUMN: TableColumn = {
  dataField: 'status',
  headerName: 'STATUS',
  size: 'small',
  type: 'expanding',
};

export const TYPE_COLUMN: TableColumn = {
  dataField: 'type',
  headerName: 'TYPE',
  size: 'small',
  type: 'expanding',
};

export const UOM_COLUMN: TableColumn = {
  dataField: 'uom',
  headerName: 'UOM',
  size: 'medium',
  type: 'expanding',
};

export const UPDATED_AT_COLUMN: TableColumn = {
  dataField: 'timestamp',
  format: 'long',
  headerName: 'UPDATED AT',
  size: 'small',
  type: 'date',
};
