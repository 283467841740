// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconRosters: FC<Props> = ({ color = '#002E5F' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='IconRostersA'
        d='M.937 1.875c.26 0 .482-.091.665-.273.182-.183.273-.404.273-.665 0-.26-.091-.481-.273-.664C1.419.091 1.198 0 .937 0 .677 0 .456.091.273.273.091.456 0 .677 0 .937s.091.482.273.665c.183.182.404.273.664.273zm8.829-.39c.065 0 .12-.023.166-.069.045-.046.068-.1.068-.166V.625c0-.065-.023-.12-.068-.166C9.886.413 9.83.391 9.766.391H2.734c-.065 0-.12.022-.166.068-.045.046-.068.1-.068.166v.625c0 .065.023.12.068.166.046.046.101.068.166.068h7.032zM.937 5c.26 0 .482-.091.665-.273.182-.183.273-.404.273-.665 0-.26-.091-.481-.273-.664-.183-.182-.404-.273-.665-.273-.26 0-.481.091-.664.273-.182.183-.273.404-.273.664s.091.482.273.665C.456 4.909.677 5 .937 5zm8.829-.39c.065 0 .12-.023.166-.069.045-.046.068-.1.068-.166V3.75c0-.065-.023-.12-.068-.166-.046-.046-.101-.068-.166-.068H2.734c-.065 0-.12.022-.166.068-.045.046-.068.1-.068.166v.625c0 .065.023.12.068.166.046.046.101.068.166.068h7.032zM.937 8.124c.26 0 .482-.091.665-.273.182-.183.273-.404.273-.665 0-.26-.091-.481-.273-.664-.183-.182-.404-.273-.665-.273-.26 0-.481.091-.664.273-.182.183-.273.404-.273.664s.091.482.273.665c.183.182.404.273.664.273zm8.829-.39c.065 0 .12-.023.166-.069.045-.046.068-.1.068-.166v-.625c0-.065-.023-.12-.068-.166-.046-.046-.101-.068-.166-.068H2.734c-.065 0-.12.022-.166.068-.045.046-.068.1-.068.166V7.5c0 .065.023.12.068.166.046.046.101.068.166.068h7.032z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(7 8)'>
      <mask id='IconRostersB' fill='#fff'>
        <use xlinkHref='#IconRostersA' />
      </mask>
      <use fill={color} fillRule='nonzero' xlinkHref='#IconRostersA' />
      <g fill={color} mask='url(#IconRostersB)'>
        <path d='M0 0L24 0 24 24 0 24z' transform='translate(-7 -8)' />
      </g>
    </g>
  </svg>
);

export default IconRosters;
