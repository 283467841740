// Vendor
import React from 'react';

const IconHistoryView = () => (
  <svg width='14' height='14' viewBox='0 0 14 14'>
    <defs>
      <path id='IconHistoryViewA' d='M0 0h24v24H0z' />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(-6 -5)'>
      <mask id='IconHistoryB' fill='#fff'>
        <use xlinkHref='#IconHistoryViewA' />
      </mask>
      <path
        fill='#011F42'
        fillRule='nonzero'
        d='M19.398 11.781a6.573 6.573 0 0 1-.916 3.39 6.852 6.852 0 0 1-2.474 2.475c-1.04.611-2.17.916-3.39.916a6.633 6.633 0 0 1-2.462-.464 6.746 6.746 0 0 1-2.078-1.286.273.273 0 0 1-.11-.232c0-.1.028-.178.083-.232l.465-.465a.327.327 0 0 1 .232-.11.38.38 0 0 1 .232.082c.493.438 1.049.78 1.668 1.026.62.246 1.276.369 1.97.369.984 0 1.895-.246 2.734-.738a5.515 5.515 0 0 0 1.996-1.996c.492-.839.738-1.75.738-2.735 0-.984-.246-1.896-.738-2.734a5.515 5.515 0 0 0-1.996-1.996 5.307 5.307 0 0 0-2.735-.739c-.747 0-1.454.142-2.119.424a5.526 5.526 0 0 0-1.764 1.19l1.34 1.312c.11.11.137.233.082.37a.3.3 0 0 1-.3.204H6.327a.316.316 0 0 1-.232-.095A.316.316 0 0 1 6 9.484V6.012a.3.3 0 0 1 .205-.301c.137-.055.26-.027.37.082l1.23 1.203a6.867 6.867 0 0 1 2.174-1.463A6.628 6.628 0 0 1 12.617 5c1.222 0 2.352.305 3.39.916a6.886 6.886 0 0 1 2.475 2.46c.611 1.03.916 2.166.916 3.405zm-4.566 2.461a.3.3 0 0 1-.219.123.34.34 0 0 1-.246-.068l-2.406-1.75V7.953c0-.091.032-.169.096-.232a.316.316 0 0 1 .232-.096h.656c.091 0 .169.032.233.096a.316.316 0 0 1 .095.232v3.938l1.86 1.367c.073.036.118.1.137.191a.296.296 0 0 1-.055.246l-.383.547z'
        mask='url(#IconHistoryB)'
      />
    </g>
  </svg>
);

export default IconHistoryView;
