// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import {
  QueryCustomerView,
  QueryCustomerViewCountResult,
  QUERY_CUSTOMER_HISTORY_BY_DATE,
  QUERY_CUSTOMER_HISTORY_BY_DATE_COUNT,
  wrapSearchText,
} from 'query';
import { NUMBER_PER_PAGE } from 'common/constants';
import { IconSystemError } from 'styles/images';
import RosterCustomer from './RosterCustomer';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  customerId: string;
  gpo: number;
  mappedIds: string[];
  setToCustomer: (toCustomer: {
    id: string;
    memberId: number;
    names: string[];
  }) => void;
}

const Container: FC<Props> = (props) => {
  const [currentPage, updatePagination] = useState(1);
  const [searchTextValue, updateSearchText] = useState('');
  const [resetInput, changeResetInput] = useState(false);
  const [selectedDate] = useState(new Date());
  const [order] = useState({ timestamp: 'desc' });

  const { customerId, gpo: memberId, mappedIds, setToCustomer } = props;

  const searchText = !searchTextValue
    ? undefined
    : wrapSearchText(searchTextValue);
  const [where, updateWhere] = useState({
    id: { _nin: mappedIds },
    _search_text: { _ilike: searchText },
  });

  const paginationFilters = { limit: NUMBER_PER_PAGE, offset: 0 };

  useEffect(() => {
    updateWhere({
      ...where,
      id: { _nin: mappedIds },
    });
  }, [mappedIds]);

  const { data, loading, error, refetch } = useQuery<QueryCustomerView>(
    QUERY_CUSTOMER_HISTORY_BY_DATE,
    {
      variables: {
        where,
        order,
        ...paginationFilters,
        memberId,
        date: selectedDate,
      },
      skip: !memberId,
    }
  );

  const { data: countData, loading: countLoading } =
    useQuery<QueryCustomerViewCountResult>(
      QUERY_CUSTOMER_HISTORY_BY_DATE_COUNT,
      {
        variables: { where, memberId, date: selectedDate },
        skip: !memberId,
      }
    );

  const totalCount = countData?.customerCount?.aggregate.count || 0;
  const customers = data?.customers || [];

  return error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <RosterCustomer
      countLoading={countLoading}
      currentPage={currentPage}
      customerId={customerId}
      customers={customers}
      loading={loading}
      onChangePage={(currentPage: number) => {
        changeResetInput(false);
        updatePagination(currentPage);
        refetch({ offset: (currentPage - 1) * NUMBER_PER_PAGE });
      }}
      onSubmit={(text: string) => {
        updateSearchText(text);
        updatePagination(1);
        updateWhere({
          ...where,
          _search_text: { _ilike: wrapSearchText(text) },
        });
      }}
      resetInput={resetInput}
      searchText={searchText}
      setToCustomer={setToCustomer}
      totalCount={totalCount}
    />
  );
};

export default Container;
