// Vendor
import React, { FC, ReactElement } from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

// Internal
import { Tooltip } from 'components/Tooltips';
import MultipleValues from './MultipleValues';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  linkUrl?: string;
  value?: string | null;
  valueIcon?: ReactElement;
  valueLength?: number | null;
}

const Value: FC<Props> = (props) => {
  const { linkUrl, value, valueIcon, valueLength } = props;

  return (
    <div data-testid='value' className={cx('ellipsis', 'value', 'row')}>
      {valueLength && valueLength > 0 ? (
        <MultipleValues value={value} valueLength={valueLength} />
      ) : (
        <Tooltip title={value}>
          {linkUrl ? (
            <Link className={cx('link')} data-testid='link' to={linkUrl}>
              {value}
            </Link>
          ) : (
            value
          )}
        </Tooltip>
      )}
      {valueIcon}
    </div>
  );
};

export default Value;
