// Vendor
import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { Button as ButtonMUI, CircularProgress } from '@material-ui/core';

// Internal
import {
  BUTTON,
  COLOR,
  CONTAINED,
  FontSize,
  IconStateVariant,
  LARGE,
  MEDIUM,
  PRIMARY,
  SIZE,
  SMALL,
  TYPE,
  VARIANT,
  VISIBILITY,
  VISIBLE,
  XSMALL,
} from './constants';

export interface Props {
  color?: COLOR;
  dataTestId: string;
  disabled?: boolean;
  endIcon?: ReactElement;
  id?: string;
  label: string | ReactNode;
  loading?: boolean;
  minWidth?: number;
  onClick?: any;
  startIcon?: ReactElement;
  style?: React.CSSProperties;
  type?: TYPE;
  variant?: VARIANT;
  visibility?: VISIBILITY;
  size?: SIZE;
}

const getFontSize = (size: SIZE) => {
  switch (size) {
    case XSMALL:
    case SMALL:
      return FontSize.SMALL;
    case MEDIUM:
      return FontSize.MEDIUM;
    case LARGE:
      return FontSize.LARGE;
    default: {
      return FontSize.MEDIUM;
    }
  }
};

const iconColors = {
  active: '#0b1641',
  default: '#ffffff',
  disabled: '#8d8b99',
  hover: '#ffffff',
};

const Button: FC<Props> = ({
  color = PRIMARY,
  dataTestId = 'button',
  disabled = false,
  endIcon,
  id,
  label = '',
  loading,
  onClick,
  startIcon,
  style,
  type = BUTTON,
  variant = CONTAINED,
  visibility = VISIBLE,
  size = LARGE,
}) => {
  const [btnState, setBtnState] = useState<IconStateVariant>('default');

  const colorizedStartIcon = startIcon
    ? React.cloneElement(startIcon, {
        color: iconColors[btnState],
      })
    : '';
  const colorizedEndIcon = endIcon
    ? React.cloneElement(endIcon, {
        color: iconColors[btnState],
      })
    : '';

  return (
    <ButtonMUI
      color={color}
      data-testid={dataTestId}
      disabled={disabled}
      endIcon={colorizedStartIcon}
      id={id}
      onClick={onClick}
      onMouseOver={() => {
        if (disabled) return;
        setBtnState('hover');
      }}
      onMouseOut={() => {
        if (disabled) return;
        setBtnState('default');
      }}
      startIcon={colorizedEndIcon}
      style={{
        ...style,
        fontSize: getFontSize(size),
        visibility,
      }}
      type={type}
      variant={variant}
    >
      {loading ? (
        <CircularProgress
          data-testid='circularProgress'
          disableShrink
          size={20}
        />
      ) : (
        label
      )}
    </ButtonMUI>
  );
};

export default Button;
