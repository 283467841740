// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import {
  IconBackgroundPill,
  IconBackgroundSyringe,
  IconMediLedgerLogo,
} from 'styles/images';
import { PasswordLogin } from './PasswordLogin';
import SsoLogin from './SsoLogin';

// Styles
import styles from './Login.module.css';

export const cx = classnames.bind(styles);

interface Props {
  onSignin?: (e: React.FormEvent<HTMLFormElement>) => void;
  onSsoSignin?: (e: React.FormEvent<HTMLFormElement>) => void;
  error?: Error;
  ready: boolean;
  expired: boolean;
}

export const Login: FC<Props> = (props) => {
  const { onSignin, onSsoSignin, error, expired, ready } = props;

  return (
    <div className={cx('root')}>
      <div className={cx('top')}>
        <IconBackgroundSyringe
          className={cx('syringe')}
          height={240}
          width={310}
        />
        <IconBackgroundPill className={cx('pill')} height={290} width={310} />
      </div>
      <div className={cx('bottom')} />
      <div className={cx('container')}>
        <IconMediLedgerLogo
          className={cx('logo')}
          height='260px'
          width='260px'
        />
        <div className={cx('form', { ready: !!error || ready })}>
          <h1 className={cx('title')}>Welcome Back!</h1>
          {expired && (
            <p className={cx('subtext')}>Your session has expired!</p>
          )}
          <p className={cx('subtext')}>Please sign in below.</p>
          <p className={cx('loginError', { show: !!error })}>
            {error?.message}
          </p>
          {onSignin ? (
            <PasswordLogin error={error} onSignin={onSignin} />
          ) : null}
          {onSsoSignin ? <SsoLogin onSsoSignin={onSsoSignin} /> : null}
        </div>
        {onSignin ? (
          <p key='p' className={cx('link')}>
            Forgot your password?
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Login;
