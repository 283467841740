// Vendor
import Paper from '@material-ui/core/Paper';
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { KeyValuePair } from 'components';
import { DEFAULT_EMPTY_STATE } from 'common/constants';
import { Member, TradingPartnerEntity } from 'query';
import { formatDate } from 'common/helpers';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  partner: TradingPartnerEntity;
}

const Details: FC<Props> = ({ partner }) => {
  const { id, name, timestamp, member = {} } = partner;
  const { cmiPeerId, blockchainAccountId } = member as Member;
  const formatTimestamp = formatDate(timestamp ?? '');

  return (
    <Paper className={cx('paperMargin')}>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Member Details</header>
        <div className={cx('row')}>
          <KeyValuePair
            title='MediLedger ID'
            width='15%'
            value={id.toString() || DEFAULT_EMPTY_STATE}
          />
          <KeyValuePair
            title='Member Name'
            width='15%'
            value={name || DEFAULT_EMPTY_STATE}
          />
          <KeyValuePair
            title='Peer ID'
            width='25%'
            value={cmiPeerId || DEFAULT_EMPTY_STATE}
          />
          <KeyValuePair
            title='Blockchain ID'
            width='25%'
            value={blockchainAccountId || DEFAULT_EMPTY_STATE}
          />
          <KeyValuePair
            title='Registration Timestamp'
            width='20%'
            value={formatTimestamp || DEFAULT_EMPTY_STATE}
          />
        </div>
      </div>
    </Paper>
  );
};

export default Details;
