import { none, Option, some } from 'fp-ts/Option';
import { concatAll, Semigroup } from 'fp-ts/Semigroup';

export const getConcatAll = <E>(
  entities: E[],
  semigroup: Semigroup<E>
): Option<E> => {
  if (entities.length === 0) {
    return none;
  }

  if (entities.length === 1) {
    return some(entities[0]);
  }

  return some(
    concatAll(semigroup)(entities[0])(entities.slice(1, entities.length))
  );
};
