import { Dispatch } from 'redux';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { DashboardConfig } from 'common/config';
import { AbstractService } from './core';
import { ApiService } from './api';
import { AuthService } from './auth';
import * as types from './types';
import { browserDetect } from './browserCheck';

export * from './api';
export * from './auth';

/** Top level application service. Spawns child services. */
export class AppService extends AbstractService {
  config: DashboardConfig;

  api: ApiService;

  auth: AuthService;

  static reducer(state: any = null, action: types.CoronaAction) {
    let update: { [path: string]: any };
    let result;
    switch (action.type) {
      case types.SERVICE_UPDATE:
        // eslint-disable-next-line no-case-declarations
        const [path, data] = action.payload;
        update = {};
        result = state.clone();
        if (path.length > 0) {
          update[path[0]] = Object.assign(state[path[0]].clone(), data);
          result = Object.assign(result, update);
        } else {
          result = Object.assign(result, data);
        }
        return result;
      default:
        return state;
    }
  }

  constructor(cfg: DashboardConfig) {
    super();
    this.config = cfg;
    // these services may not cross-reference or depend on each other
    // since they will get new pointers to their instances
    // whenever the state updates
    this.api = new ApiService(cfg.api || { endpoint: undefined }, ['api']);
    this.auth = new AuthService(cfg.auth, ['auth']);
  }

  initializeAction(
    apollo: ApolloClient<NormalizedCacheObject>,
    dispatch: Dispatch<types.CoronaAction>
  ) {
    if (this.config['enable-browser-warnings']) {
      browserDetect();
    }
    this.auth.initializeAction(dispatch);
  }
}

export function create(config: DashboardConfig) {
  return new AppService(config);
}
