// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { IconEmptyResults } from 'styles/images';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  text?: string;
}

const EmptyResults: FC<Props> = (props) => {
  const { text = 'No results found' } = props;

  return (
    <div className={cx('root')} data-testid='emptyResults'>
      <IconEmptyResults />
      <p className={cx('text')}>{text}</p>
    </div>
  );
};

export default EmptyResults;
