// Vendor
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import classnames from 'classnames/bind';

// Internal
import {
  EARLIEST_DATE,
  LATEST_DATE,
  SHORT_DATE_FORMAT,
} from 'common/constants';
import { withinDateRange } from 'common/validation';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  disabled?: boolean;
  fallbackDate: string;
  input: {
    onBlur?: () => void;
    onChange: (date: string) => void;
    value: string;
  };
  maxDate?: Date;
  minDate?: Date;
  meta: {
    dirty: boolean;
    error: string | string[];
    touched: boolean;
  };
  placeholder?: string;
}

const DatePicker: FC<Props> = (props) => {
  const {
    disabled,
    fallbackDate,
    input: { onBlur, onChange, value },
    maxDate,
    meta: { dirty, error },
    minDate,
    placeholder,
  } = props;

  const [touched, setTouched] = useState(false);

  const formatError = () => {
    if (Array.isArray(error)) {
      return (
        <div className={cx('error')}>
          {error
            .filter((e) => e.length > 0)
            .map((e) => (
              <div key={e}>{e}</div>
            ))}
        </div>
      );
    }

    return (
      <div className={cx('error')}>
        {error === 'Required' ? (touched || dirty) && error : error}
      </div>
    );
  };

  const handleChange = (date: any) => {
    setTouched(true);
    if (onBlur) onBlur();

    if (!date) {
      onChange('');
    } else if (
      withinDateRange({
        startDate: minDate?.toDateString(),
        endDate: maxDate?.toDateString(),
      })(date)
    ) {
      onChange(fallbackDate);
      // Only update the value of the date picker is the date is valid (this is necessary since
      // we now handle on change for every keystroke with onChangeRaw)
    } else if (dayjs(date.valueOf()).isValid()) {
      onChange(dayjs(date.valueOf()).format(SHORT_DATE_FORMAT));
    }
  };

  return (
    <>
      <ReactDatePicker
        calendarClassName={cx('calendar')}
        className={cx('input')}
        disabled={disabled}
        maxDate={maxDate ?? dayjs(LATEST_DATE).toDate()}
        minDate={minDate ?? dayjs(EARLIEST_DATE).toDate()}
        dateFormat='yyyy-MM-dd'
        onChangeRaw={({ target }) => {
          const { value } = target;
          handleChange(value);
        }}
        onChange={(date) => {
          // If date is null the dayjs to string will always display the current day
          // (this will stop the user from being able to remove the date from the picker)
          handleChange(date === null ? '' : dayjs(date?.toString()));
        }}
        placeholderText={placeholder}
        selected={value === '' ? null : dayjs(value).toDate()}
        onBlur={() => {
          setTouched(true);
        }}
      />
      <div style={{ position: 'relative' }}>{formatError()}</div>
    </>
  );
};

export default DatePicker;
