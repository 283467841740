// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import { TableRow } from 'components/Table';
import { Pagination, SearchBar, Table } from 'components';
import {
  filtersToQuery,
  getColumnsForViewPage,
  productsToRowsForViewPage,
} from 'services/ProductListMgmt/service';
import { NUMBER_PER_PAGE, SHORT_POLL_INTERVAL } from 'common/constants';
import {
  GET_PRODUCTS_FOR_LIST,
  GET_PRODUCTS_FOR_LIST_COUNT,
  GetProductsForListCountResult,
  GetProductsForListResult,
} from 'query';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  id: string;
  memberId: number;
}

const ViewProductsTable: FC<Props> = (props) => {
  const { id, memberId } = props;

  // Table rows
  const [rows, updateRows] = useState([] as TableRow[]);
  // Search
  const [searchText, updateSearchText] = useState('');
  // Pagination
  const [currentPage, updateCurrentPage] = useState(1);
  // Where condition
  const [where, updateWhere] = useState(filtersToQuery('', memberId, id));

  // Fetch the data
  const { data: productsData, loading: isLoading } =
    useQuery<GetProductsForListResult>(GET_PRODUCTS_FOR_LIST, {
      variables: {
        order: { product: { description: 'asc' } },
        where,
        offset: (currentPage - 1) * NUMBER_PER_PAGE,
        limit: NUMBER_PER_PAGE,
      },
      pollInterval: SHORT_POLL_INTERVAL,
    });
  const products = productsData?.products || [];

  // Fetch the count
  const { data: countData, loading: isCountLoading } =
    useQuery<GetProductsForListCountResult>(GET_PRODUCTS_FOR_LIST_COUNT, {
      variables: {
        where,
      },
      pollInterval: SHORT_POLL_INTERVAL,
    });

  // Search filter
  const hasProductLists = products.length > 0;
  const isFiltered = searchText !== '';
  const disableSearchFilter = !isLoading && !hasProductLists && !isFiltered;

  // Format the raw data
  useEffect(() => {
    if (!isLoading) {
      updateRows(productsToRowsForViewPage(productsData));
    }
  }, [productsData, isLoading]);

  // Build the search query
  useEffect(() => {
    updateCurrentPage(1);
    updateWhere(filtersToQuery(searchText, memberId, id));
  }, [searchText]);

  return (
    <div>
      <div className={cx('row')}>
        <SearchBar
          placeholder='Search by product ID or description'
          onSubmit={(text) => updateSearchText(text)}
          disabled={disableSearchFilter}
        />
      </div>
      <Table
        columns={getColumnsForViewPage()}
        emptyText='No products found'
        isLoading={isLoading}
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={isCountLoading}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={hasProductLists}
        totalCount={countData?.productCount?.aggregate.count || 0}
      />
    </div>
  );
};

export default ViewProductsTable;
