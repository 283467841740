// Vendor
import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';

// Internal
import { Button } from 'components';

export interface ButtonColumnInterface {
  type: 'button';
  headerName: string;
  size: Size;
  title: string;
  style: React.CSSProperties;
  handleOnClickFn: (data: any) => () => void;
  disabledFn: (data: any) => boolean;
}

interface Props {
  id: string;
  size: Size;
  title: string;
  handleOnClickFn: (data: any) => () => void;
  style: React.CSSProperties;
  disabledFn: (data: any) => boolean;
  data: any;
}

export const ButtonCell: FC<Props> = ({
  size,
  title,
  handleOnClickFn,
  style,
  id,
  disabledFn,
  data,
}) => {
  const onClick = handleOnClickFn(data);
  const disabled = disabledFn(data);
  return (
    <TableCell size={size}>
      <Button
        dataTestId={id}
        id={id}
        label={title}
        onClick={onClick}
        style={style}
        disabled={disabled}
      />
    </TableCell>
  );
};
