// Vendor
import { getApplicativeValidation, map as mapE } from 'fp-ts/Either';
import { getSemigroup } from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import { sequenceT } from 'fp-ts/Apply';

// Internal
import { ContractPricingRoster } from 'flatfile-import/domain/contract-pricing/contract-pricing-roster';
import { RosterValidator, RowError } from '../../../domain/validator';
import { validateNonEmptyStringField } from '../validation';

class ContractPricingValidationService
  implements RosterValidator<ContractPricingRoster>
{
  validate(row: ContractPricingRoster) {
    // Applicative instance with semigroup for collecting errors.
    const nonEmptyArrayAp = getApplicativeValidation(getSemigroup<RowError>());
    return pipe(
      // Sequence will create the placeholder curried function and will apply the ap for each Either instance.
      sequenceT(nonEmptyArrayAp)(
        validateNonEmptyStringField(row, 'id'),
        validateNonEmptyStringField(row, 'productId')
      ),
      mapE(() => row)
    );
  }
}

export default new ContractPricingValidationService();
