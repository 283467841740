// Vendor
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client';
import { SetStateAction, Dispatch, RefObject, DragEvent } from 'react';
import { Edge, Node, Connection, ReactFlowInstance } from 'reactflow';

export type ValuesType = {
  label: string;
  name: string;
  attributes?: {
    inputType?: string;
    label: string;
    name: string;
    attributes?: {
      label: string;
      name: string;
    }[];
  }[];
};

export type SubAttributesType = {
  attributes?: SubAttributesType[];
  inputType?: string;
  label: string;
  name: string;
};

export interface AttributesType {
  attributes?: SubAttributesType[];
  customerTypes: string[];
  inputType?: string;
  label: string;
  name: string;
  ruleSets: string[];
  valueSources?: string[];
  values?: ValuesType[];
}

export interface FieldType {
  fieldData: {
    attributes: AttributesType[];
  };
  label: string;
  name: string;
}

export enum Pages {
  MANAGE_RULES = 'manage-rules',
  RULE_SET = 'rule-set',
  RULE = 'rule',
}

export enum NodeTypes {
  RULE = 'rule',
  RULE_SET = 'ruleSet',
}

export type LeftPanelItems = {
  buttonLabel?: string;
  buttonOnClick?: () => void;
  items: DragAndMoveTabItems[];
  title: string;
};

export type DragAndMoveTabItems = {
  description: string;
  edges?: Edge[];
  name: string;
  nextPage: string;
  nodes?: Node[];
  nodeType: NodeTypes.RULE_SET | NodeTypes.RULE;
};

export type ReactFlowTypes = {
  edges: Edge[];
  edgeTypes?: any;
  nodes: Node[];
  nodeTypes: any;
  onConnect?: (params: Connection | Edge) => void;
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  onEdgesChange?: any;
  onNodeDoubleClick: (event: React.MouseEvent, node: Node) => void;
  onNodesChange: any;
};

export type HandleValidate = {
  edges: Edge[];
  nodes: Node[];
  setPage1Nodes: Dispatch<SetStateAction<Node[]>>;
  snackbar: { open: (config: object) => void };
};

export interface HandleSubmitValidate extends HandleValidate {
  triggerId: string;
  insertProposalRules: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>;
  proposals: boolean;
}

export type OnDrop = {
  event: DragEvent<HTMLDivElement>;
  extraData: {};
  reactFlowInstance: ReactFlowInstance | any;
  reactFlowWrapper: RefObject<HTMLDivElement>;
  setNodes: Dispatch<SetStateAction<Node[]>>;
};
