// Vendor
import React from 'react';

const IconChevronLeft = () => (
  <svg width='13' height='12' viewBox='0 0 7 12'>
    <defs>
      <path
        id='IconChevronLeftA'
        d='M10.667 2.875v6.77a.52.52 0 1 1-1.042 0v-6.77h-6.77a.52.52 0 1 1 0-1.042h7.285a.527.527 0 0 1 .527.527v.515z'
      />
    </defs>
    <use
      fill='#757575'
      fillRule='evenodd'
      opacity='.8'
      transform='rotate(-135 6.5 6)'
      xlinkHref='#IconChevronLeftA'
    />
  </svg>
);

export default IconChevronLeft;
