// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { Step, StepLabel, Stepper as MuiStepper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Internal
import { Button } from 'components';

// Styles
import inlineStyles from './inlineStyles';
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  classes: {
    root: string;
  };
  handleCancel: () => void;
  onSubmit: () => void;
  steps: {
    content: any;
    isDisabled: boolean;
    label: string;
  }[];
}

const Stepper: FC<Props> = (props) => {
  const { classes, handleCancel, onSubmit, steps } = props;
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={cx('root')} data-testid='stepper'>
      <div className={cx('row')}>
        <MuiStepper
          alternativeLabel
          activeStep={activeStep}
          classes={{
            root: classes.root,
          }}
        >
          {steps.map(({ label }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </MuiStepper>
        <div className={cx('buttonContainer')}>
          <Button
            color='secondary'
            dataTestId='backBtn'
            label={activeStep === 0 ? 'Cancel' : 'Back'}
            onClick={activeStep === 0 ? handleCancel : handleBack}
            style={{
              width: 120,
            }}
            variant='outlined'
          />
          <Button
            dataTestId='nextBtn'
            disabled={steps[activeStep].isDisabled}
            label={activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            onClick={activeStep === steps.length - 1 ? onSubmit : handleNext}
            style={{
              marginLeft: 16,
              width: 120,
            }}
          />
        </div>
      </div>
      {steps[activeStep].content}
    </div>
  );
};

// @ts-ignore
export default withStyles(inlineStyles)(Stepper);
