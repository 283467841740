import { RawContractPricing } from 'flatfile-import/domain/contract-pricing/contract-pricing-roster';
import { ApiService } from '../../../actions';
import { HttpPublisher } from '../http-publisher';

export class HttpContractPricingPublisher extends HttpPublisher<RawContractPricing> {
  apiService: ApiService;

  path: string;

  constructor(apiService: ApiService) {
    const path = `/v2/contract/pricing/upsert`;
    super(apiService, path);
    this.path = path;
    this.apiService = apiService;
  }
}
