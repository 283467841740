// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { chain, keyBy, uniqWith, isEqual } from 'lodash';
import { useQuery } from '@apollo/client';

// Internal
import Table from 'components/Table';
import { NotFoundSection, ViewSection } from 'components';
import {
  clampEndDate,
  clampStartDate,
  parseMediledgerId,
  sortEligibilitiesByStartDate,
} from 'common/helpers';
import { POLL_INTERVAL } from 'common/constants';
import {
  GET_TRADING_PARTNERS,
  GetContractsResult,
  QUERY_CONTRACT_HEADER_BY_IDS,
  QueryTradingPartners,
  CustomerDetails,
} from 'query';
import { useTradingPartnerType } from 'hooks';
import { Eligibility, Relationship } from 'types/generated/Relationship';
import { ContractEntity } from 'types/generated/ContractEntity';
import { getColumns, getRows } from './helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface ContractsForView {
  contract_entities?: ContractEntity[];
  description: string;
  distributors?: ContractEntity[];
  endDates: string[];
  list_id: string;
  list_member_id: number;
  startDates: string[];
}

interface Props {
  customer: CustomerDetails;
  relationships?: Relationship[];
}

const Contracts: FC<Props> = (props) => {
  const { customer, relationships } = props;

  const tpType = useTradingPartnerType();

  const { data: tpData, loading: tpLoading } = useQuery<QueryTradingPartners>(
    GET_TRADING_PARTNERS,
    {
      variables: { id: {} },
    }
  );
  const tradingPartners = keyBy(tpData?.tradingPartners || [], (p) => p.id);

  const contracts: Eligibility[] = [];
  relationships?.forEach((r) => {
    if (r.relationship === 'eligibility') {
      const { id } = parseMediledgerId(r.to);
      contracts.push({ ...r, to: id });
    }
  });
  const contractsGroupedById = chain(contracts)
    .groupBy('to')
    .map((allLists, listId) => ({ listId, allLists }))
    .value();

  const contractIds = contractsGroupedById.map((p) => p.listId);

  const { data, loading } = useQuery<GetContractsResult>(
    QUERY_CONTRACT_HEADER_BY_IDS,
    {
      variables: {
        ids: contractIds,
      },
      skip: contractIds.length === 0,
      pollInterval: POLL_INTERVAL,
    }
  );

  if (loading || tpLoading) {
    return null;
  }

  const contractData = data?.dash_contract_headers || [];

  const contractsDataForView: ContractsForView[] = contractsGroupedById.flatMap(
    ({ allLists, listId }) => {
      const contract = contractData.find((c) => c.list_id === listId);
      if (!contract) {
        // eslint-disable-next-line no-console
        console.warn(
          `There's no contract data for ${listId}. This could be a bug!`
        );
        return [];
      }
      const {
        list_id,
        contract_entities,
        description,
        distributors,
        list_member_id,
      } = contract;

      const sortedLists = sortEligibilitiesByStartDate(allLists);

      const uniqueSortedLists = uniqWith(sortedLists, isEqual);

      const startDates = uniqueSortedLists.map((l) =>
        clampStartDate(customer.startDate, l.startDate)
      );
      const endDates = uniqueSortedLists.map((l) =>
        clampEndDate(customer.endDate, l.endDate)
      );
      return [
        {
          list_id,
          contract_entities,
          description,
          distributors,
          list_member_id,
          startDates,
          endDates,
        },
      ];
    }
  );

  const columns = getColumns(tpType);
  const rows = getRows(contractsDataForView, tradingPartners);

  return (
    <ViewSection
      dataTestid='contracts'
      disableCollapse={contractIds.length === 0}
      emptySection={contractIds.length === 0}
      header='Contracts'
    >
      <div className={cx('tableContent')}>
        {contractsDataForView.length === 0 ? (
          <NotFoundSection text='No Contracts found' />
        ) : (
          <Table
            columns={columns}
            isLoading={loading || tpLoading}
            emptyText='No Contracts found'
            rows={rows}
          />
        )}
      </div>
    </ViewSection>
  );
};

export default Contracts;
