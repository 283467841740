// Vendor
import React, { FC } from 'react';

// Internal
import { getValidIdentifiers } from 'common/helpers';
import { ErrorCode } from 'types/generated/ErrorCode';
import { ViewSection } from 'components';
import Identifier from './Identifier';

export interface Props {
  identifiers: string[];
  customerErrors?: ErrorCode[];
}

const Identifiers: FC<Props> = (props) => {
  const { customerErrors, identifiers } = props;

  const validIds = getValidIdentifiers(identifiers, customerErrors);

  return (
    <ViewSection
      dataTestid='identifiers'
      disableCollapse={validIds.length === 0}
      emptySection={validIds.length === 0}
      header='Identifiers'
    >
      {validIds?.map((identifier: string, i) => {
        const notLast = i < validIds.length - 1;
        return (
          <Identifier
            identifier={identifier}
            notLast={notLast}
            key={identifier}
          />
        );
      })}
    </ViewSection>
  );
};

export default Identifiers;
