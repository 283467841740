// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Button } from 'components';
import { IconPlus, IconTrash } from 'styles/images';

// Styles
import styles from './ListRow.module.css';

const cx = classnames.bind(styles);

export interface Props {
  addRow?: any;
  buttonType?: ListRowButtonType;
  children: any;
  id?: any;
  onSubmit: any;
  secondaryButtonType?: ListRowButtonType;
}

enum ListRowButtonType {
  DELETE,
  ADD,
}

const ListRow: FC<Props> = (props) => {
  const { addRow, onSubmit, buttonType, children, secondaryButtonType } = props;

  const submitButton = (onSubmit: any, buttonType?: ListRowButtonType) => {
    if (buttonType === ListRowButtonType.DELETE) {
      return (
        <Button
          dataTestId='deleteRowBtn'
          color='secondary'
          label={<IconTrash />}
          onClick={onSubmit}
          style={{
            marginTop: 27,
            width: 40,
          }}
          type='button'
          variant='outlined'
        />
      );
    } else {
      return (
        <Button
          dataTestId='addRowBtn'
          color='secondary'
          label={<IconPlus />}
          onClick={onSubmit}
          style={{
            marginLeft: 16,
            marginTop: 27,
            width: 40,
          }}
          type='button'
          variant='outlined'
        />
      );
    }
  };

  return (
    <div className={cx('content-wrapper')}>
      {children}
      <div className={cx('buttons-wrapper')}>
        {submitButton(onSubmit, buttonType)}
        {addRow && submitButton(addRow, secondaryButtonType)}
      </div>
    </div>
  );
};

export default ListRow;
