export default (theme: {
  transitions: {
    create: (
      arg0: string,
      arg1: {
        easing: any;
        duration: any;
      }
    ) => any;
    easing: {
      sharp: any;
    };
    duration: {
      leavingScreen: any;
      enteringScreen: any;
    };
  };
}) => ({
  root: {},
  divider: {
    margin: 0,
    width: '100%',
  },
  drawerClose: {
    backgroundColor: '#1482fc',
    borderRadius: 0,
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 56,
    zIndex: 1,
  },
  drawerOpen: {
    backgroundColor: '#1482fc',
    borderRadius: 0,
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 256,
    zIndex: 1,
  },
  indent: {
    paddingLeft: '24px !important',
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#011f42 !important',
      color: '#fff',
    },
  },
  listItemProfile: {
    height: 56,
  },
  listItemSelected: {
    backgroundColor: '#011f42 !important',
    color: '#fff',
  },
});
