// Vendor
import React from 'react';

const IconDelete = () => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none'>
    <rect x='0.5' y='0.5' width='35' height='35' rx='17.5' fill='white' />
    <path
      d='M23.625 12.375L12.375 23.625'
      stroke='#0B1641'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M23.625 23.625L12.375 12.375'
      stroke='#0B1641'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x='0.5' y='0.5' width='35' height='35' rx='17.5' stroke='#E8E7EB' />
  </svg>
);

export default IconDelete;
