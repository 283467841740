import React from 'react';

const IconCreditTxs = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='a'
        d='M14.562.625c.365 0 .675.128.93.383s.383.565.383.93v9.625c0 .364-.128.674-.383.93a1.266 1.266 0 0 1-.93.382H1.437c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93V1.939c0-.365.128-.675.383-.93s.565-.383.93-.383h13.124zM1.437 1.5a.426.426 0 0 0-.314.123.426.426 0 0 0-.123.315V3.25h14V1.938a.426.426 0 0 0-.123-.315.426.426 0 0 0-.315-.123H1.437zM14.562 12a.426.426 0 0 0 .315-.123.426.426 0 0 0 .123-.314V5.875H1v5.688c0 .127.041.232.123.314a.426.426 0 0 0 .314.123h13.125zM5.375 9.922a.316.316 0 0 1-.096.232.316.316 0 0 1-.232.096H3.078a.316.316 0 0 1-.232-.096.316.316 0 0 1-.096-.232v-.219c0-.091.032-.168.096-.232a.316.316 0 0 1 .232-.096h1.969c.091 0 .168.032.232.096a.316.316 0 0 1 .096.232v.219zm5.25 0a.316.316 0 0 1-.096.232.316.316 0 0 1-.232.096H6.578a.316.316 0 0 1-.232-.096.316.316 0 0 1-.096-.232v-.219c0-.091.032-.168.096-.232a.316.316 0 0 1 .232-.096h3.719c.091 0 .168.032.232.096a.316.316 0 0 1 .096.232v.219z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(4 5)'>
      <mask id='b' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <use fill='#011F42' fillRule='nonzero' xlinkHref='#a' />
      <g fill='#002E5F' mask='url(#b)'>
        <path d='M-4-5h24v24H-4z' />
      </g>
    </g>
  </svg>
);

export default IconCreditTxs;
