// Vendor
import React from 'react';
import { get, orderBy } from 'lodash';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

// Internal
import {
  IconCorrectPay,
  IconCredit,
  IconCrossPay,
  IconInvalidChargeback,
  IconOriginal,
  IconRebill,
  IconReturn,
} from 'styles/images';
import { Tooltip } from 'components/Tooltips';
import { creditMemoPath, truncateDatetime } from 'common/helpers';
import { ChargebackRequestRow, CreditMemoRow } from 'query';
import dayjs from 'dayjs';
import Field from './ChargebackField';
import { Action } from './ClaimHistory';

// Styles
import styles from './chargeback.module.css';

const cx = classnames.bind(styles);

export const getIcon = (adjudicationStatus: string, cbkType: string) => {
  const iconMapper = {
    'Correct-Pay': <IconCorrectPay />,
    'Cross-Pay': <IconCrossPay />,
    'Credit-Only': <IconReturn />,
    Credit: <IconCredit />,
    Original:
      adjudicationStatus === 'Rejected' ? (
        <IconInvalidChargeback />
      ) : (
        <IconOriginal />
      ),
    Rebill: <IconRebill />,
    Return: <IconReturn />,
  };

  return get(iconMapper, cbkType, null);
};

export const ApproveButton = withStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 188, 150, 1)',
    '&:hover': {
      backgroundColor: 'rgba(0, 188, 150, 0.7)',
    },
    marginTop: '8px',
    float: 'right',
  },
}))(Button);

export const RejectButton = withStyles(() => ({
  root: {
    backgroundColor: 'rgba(203, 102, 113, 1)',
    '&:hover': {
      backgroundColor: 'rgba(203, 102, 113, 0.7)',
    },
    marginRight: '8px',
    marginTop: '8px',
    float: 'right',
  },
}))(Button);

export const renderField = (
  key: string,
  value: string | null | undefined,
  isCard: boolean = false,
  isLink: boolean = false,
  linkUrl: string = ''
) => (
  <Field key={key} isCardField={isCard} title={key} testId={key}>
    <Tooltip title={value}>
      {isLink ? (
        <Link className={isCard ? cx('whiteLink') : cx('link')} to={linkUrl}>
          {value}
        </Link>
      ) : (
        value
      )}
    </Tooltip>
  </Field>
);

export const renderCardData = (
  status: string,
  associatedCreditNote?: CreditMemoRow
) => {
  const transactionId = associatedCreditNote?.id;
  if (status === 'Rejected') {
    const transactionDate =
      associatedCreditNote?.created_on &&
      truncateDatetime(associatedCreditNote.created_on);
    return (
      <>
        {transactionId &&
          renderField(
            'Credit Trans ID',
            transactionId,
            true,
            true,
            creditMemoPath(transactionId)
          )}
        {renderField('Credit Trans Date', transactionDate || '', true)}
      </>
    );
  } else if (status === 'Approved') {
    const transactionDate =
      associatedCreditNote?.created_on &&
      truncateDatetime(associatedCreditNote.created_on);

    return (
      <>
        {renderField(
          'Credit Trans ID',
          transactionId || '',
          true,
          !!transactionId,
          transactionId ? creditMemoPath(transactionId) : ''
        )}
        {renderField('Credit Trans Date', transactionDate || '', true)}
      </>
    );
  } else if (status === 'Processing') {
    return (
      <>
        {renderField('Credit Trans ID', '', true)}
        {renderField('Credit Trans Date', '', true)}
      </>
    );
  } else {
    return null;
  }
};

const getOverrideAuditAction = (operation: string): string => {
  if (operation === 'override-request') {
    return 'Override Requested';
  } else if (operation === 'override-reply') {
    return 'Override Replied';
  } else if (operation === 'override-result') {
    return 'Override Confirmed';
  } else if (operation === 'audit-request') {
    return 'Audit Requested';
  } else if (operation === 'audit-reply') {
    return 'Audit Replied';
  } else if (operation === 'audit-result') {
    return 'Audit Confirmed';
  }
  return '';
};

export const getHistory = (chargeback: ChargebackRequestRow): Action[] => {
  const submittedEvent = {
    name: 'Submitted',
    date: chargeback.timestamp,
  };

  let adjudicatedEvents: Action[] = [];
  if (chargeback.errors && chargeback.errors.length > 0) {
    adjudicatedEvents = [
      {
        name: 'Adjudicated',
        date: dayjs(chargeback.timestamp).add(1, 'second').toISOString(),
      },
    ];
  } else if (chargeback.responses) {
    adjudicatedEvents = chargeback.responses.map((response) => ({
      name: 'Adjudicated',
      date: response.timestamp ? dayjs(response.timestamp).add(1, 'second').toISOString() : '',
    }));
  }

  const overrideAuditEvents = (chargeback.override_audit ?? []).map(
    (event) => ({
      name: getOverrideAuditAction(event.operation),
      date: event.timestamp,
    })
  );

  const allEvents = [
    submittedEvent,
    ...adjudicatedEvents,
    ...overrideAuditEvents,
  ];

  return orderBy(allEvents, 'date', 'desc');
};

export const renderParagraphSection = (
  key: string,
  text: string,
  header: string,
  children?: null | JSX.Element
) => (
  <div
    key={key}
    className={cx('leftMargin', 'column', 'divider')}
    data-testid={key}
  >
    <header className={cx('header', 'topMargin')}>{header}</header>
    <div className={cx('paragraph')}>{text}</div>
    {children}
  </div>
);
