// Vendor
import { Button, MenuItem, Popover } from '@material-ui/core';
import React, { FC, useState } from 'react';

// Internal
import { DEFAULT_GREY, DISABLED_COLOR } from 'common/constants';
import { IconArrowDown, IconArrowUp, IconFilters } from 'styles/images';
import { RosterKind } from 'pages/Roster/helpers';

// Styles
import classnames from 'classnames/bind';
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  applyFilters: (id: RosterKind) => void;
  disableFilters: boolean;
  selectedItem: RosterKind;
}

const RosterFilter: FC<Props> = ({
  applyFilters,
  disableFilters,
  selectedItem = RosterKind.ALL,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const filtersActive = selectedItem !== RosterKind.ALL;

  let buttonClasses;

  if (disableFilters) buttonClasses = cx('disabled');
  else if (filtersActive) {
    if (open) {
      buttonClasses = cx('activated', 'open');
    } else {
      buttonClasses = cx('activated');
    }
  } else if (open) {
    buttonClasses = cx('default', 'open');
  } else {
    buttonClasses = cx('default');
  }

  let selectedItemName;
  switch (selectedItem) {
    case RosterKind.ALL:
      selectedItemName = 'All Networks';
      break;
    case RosterKind.IN_NETWORK:
      selectedItemName = 'In-Network';
      break;
    case RosterKind.OUT_OF_NETWORK:
      selectedItemName = 'Out-of-Network';
      break;
    default:
  }

  return (
    <>
      <Button
        data-testid='filter_button'
        disabled={disableFilters}
        onClick={handleClick}
        style={{
          marginLeft: 16,
          paddingLeft: 16,
          minWidth: 170,
          justifyContent: 'left',
        }}
        color='secondary'
        variant='outlined'
        className={buttonClasses}
        startIcon={
          <IconFilters color={disableFilters ? DISABLED_COLOR : DEFAULT_GREY} />
        }
        endIcon={
          open ? (
            <IconArrowUp />
          ) : (
            <IconArrowDown
              color={disableFilters ? DISABLED_COLOR : DEFAULT_GREY}
            />
          )
        }
      >
        {selectedItemName}
      </Button>
      <Popover
        data-testid='roster-filter-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          data-testid='all-networks-btn'
          onClick={() => {
            handleClose();
            applyFilters(RosterKind.ALL);
          }}
        >
          All Networks
        </MenuItem>
        <MenuItem
          data-testid='in-network-btn'
          onClick={() => {
            handleClose();
            applyFilters(RosterKind.IN_NETWORK);
          }}
        >
          In-Network
        </MenuItem>
        <MenuItem
          data-testid='out-of-network-btn'
          onClick={() => {
            handleClose();
            applyFilters(RosterKind.OUT_OF_NETWORK);
          }}
        >
          Out-of-Network
        </MenuItem>
      </Popover>
    </>
  );
};

export default RosterFilter;
