// Vendor
import { useSelector } from 'react-redux';

// Internal
import {
  Attribute,
  AuthorityDatabases,
  CustomerConfig,
  DashboardConfig,
  DocumentType,
  NodeType,
  PriceType,
  ProductConfig,
  ProductTypeType,
  TradingPartnerConfig,
  UnitOfMeasureType,
} from 'common/config';
import { RootState } from 'reducers';
import { AuthService } from 'actions/auth';

const DEFAULT_DISPLAYABLE_TRADING_PARTNER_TYPES: string[] = [
  'gpo',
  'mfr',
  'dist',
  'health-system',
];
const DEFAULT_ROSTER_TRADING_PARTNER_TYPES: string[] = ['gpo', 'health-system'];

// React hook that returns dashboard config from Redux store
export const useConfig: () => DashboardConfig | undefined = () =>
  useSelector<RootState, DashboardConfig | undefined>(
    (state) => state?.config?.dashboard
  );

// React hook that returns auth config
export const useAuthConfig = () =>
  useSelector<RootState, AuthService>((state) => state?.service?.auth);

// React hook that returns customer config from Redux store
export const useCustomerConfig = (): CustomerConfig | undefined =>
  useConfig()?.customer;

// React hook that returns customer management config from Redux store
export const useCustomerMgmt = () => useConfig()?.['customer-management'];
export const useRequiredFields = () => useCustomerMgmt()?.['required-fields'];

// React hook that returns the list of attributes to be used as columns for Roster table
export const useRosterAttributeColumns = () =>
  useConfig()?.['roster-attribute-columns'] ?? [];

// React hook that returns attributes from Redux store
export const useAttributes = (): Attribute[] =>
  useCustomerConfig()?.attributes || [];

// React hook that returns document types from Redux store
export const useDocumentTypes = (): DocumentType[] =>
  useCustomerConfig()?.documentTypes || [];

export const useProductConfig = (): ProductConfig | null =>
  useConfig()?.product || {};
export const usePriceTypes = (): PriceType[] =>
  useProductConfig()?.priceTypes || [];
export const useUnitsOfMeasure = (): UnitOfMeasureType[] =>
  useProductConfig()?.unitsOfMeasure || [];
export const useProductTypes = (): ProductTypeType[] =>
  useProductConfig()?.productTypes || [];

// React hook that returns feature flag config from Redux store
export const useFeatureFlagsConfig = () => useConfig()?.['feature-flags'];
export const useAuthDatabases = (): AuthorityDatabases[] =>
  useFeatureFlagsConfig()?.['acceptable-authority-databases'] ?? [];
export const useChargebacksFlag = () =>
  useFeatureFlagsConfig()?.chargebacks ?? false;
export const useCreditMemosFlag = () =>
  useFeatureFlagsConfig()?.['credit-memos'] ?? false;
export const useContractsModuleFlag = () =>
  useFeatureFlagsConfig()?.['contracts-module'] ?? false;
export const useContractManagementFlag = () =>
  useFeatureFlagsConfig()?.['contract-management'] ?? false;
export const useCsvUploadFlag = () =>
  useFeatureFlagsConfig()?.['csv-upload'] ?? false;
export const useCustomerMngmtFlag = () =>
  useFeatureFlagsConfig()?.['customer-management'] ?? false;
export const useCustomerListsModuleFlag = () =>
  useFeatureFlagsConfig()?.['customer-lists-module'] ?? false;
export const useCustomersModuleFlag = () =>
  useFeatureFlagsConfig()?.['customers-module'] ?? false;
export const useDEAValidationOnly = () =>
  useFeatureFlagsConfig()?.['dea-validation-only-mode'] ?? false;
export const useDisplayableTradingPartnerTypes = (): string[] =>
  useFeatureFlagsConfig()?.['displayable-trading-partner-types'] ||
  DEFAULT_DISPLAYABLE_TRADING_PARTNER_TYPES;
export const useManualMappingFlag = () =>
  useFeatureFlagsConfig()?.['manual-mapping'] ?? false;
export const useRosterFlag = () => useFeatureFlagsConfig()?.roster ?? false;
export const useProposalsFlag = () =>
  useFeatureFlagsConfig()?.proposals ?? false;
export const useProductListsFlag = () =>
  useFeatureFlagsConfig()?.['product-lists'] ?? false;
export const useProductsFlag = () => useFeatureFlagsConfig()?.products ?? false;
export const useRosterTradingPartnerTypes = (): string[] =>
  useFeatureFlagsConfig()?.['roster-trading-partner-types'] ||
  DEFAULT_ROSTER_TRADING_PARTNER_TYPES;
export const useSimplifiedContractsFlag = () =>
  useFeatureFlagsConfig()?.['simplified-contracts'] ?? false;
export const useContractDetailsExportFlag = () =>
  useFeatureFlagsConfig()?.['contract-details-export'] ?? false;
export const useFlatfileLocalCustomersImportFlag = () =>
  useFeatureFlagsConfig()?.['flatfile-local-customers-import'] ?? false;
export const useFlatfileOonCustomersImportFlag = () =>
  useFeatureFlagsConfig()?.['flatfile-oon-customers-import'] ?? false;
export const useShouldSendProposalToExtFlag = () =>
  useFeatureFlagsConfig()?.['should-send-proposal-to-ext'] ?? false;
export const useUploadContractHeaderFlag = () =>
  useFeatureFlagsConfig()?.['flatfile-contract-header-import'] ?? false;
export const useUploadContractPricingFlag = () =>
  useFeatureFlagsConfig()?.['flatfile-contract-pricing-import'] ?? false;

// React hook that returns trading partner config from Redux store
export const useTradingPartnerConfig: () =>
  | TradingPartnerConfig
  | undefined = () => useConfig()?.['trading-partner'];

// React hook that returns trading partner type from Redux store
export const useTradingPartnerType = () =>
  useTradingPartnerConfig()?.type ?? NodeType.MFR;
