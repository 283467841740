// Internal
import { TableColumn } from 'components/Table';
import { productPath } from 'common/helpers';
import {
  DESCRIPTION_COLUMN,
  END_DATE_COLUMN,
  PRODUCT_ID_TYPE_COLUMN,
  START_DATE_COLUMN,
  UOM_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';

export const displayTextFn = (data: any) => data.externalId;
export const urlFn = (data: any) => productPath(data.id);

export const COLUMNS: TableColumn[] = [
  {
    displayTextFn,
    headerName: 'PRODUCT ID',
    size: 'small',
    type: 'link',
    urlFn,
  },
  PRODUCT_ID_TYPE_COLUMN,
  DESCRIPTION_COLUMN,
  UOM_COLUMN,
  {
    dataField: 'basisPrice',
    headerName: 'BASIS PRICE',
    size: 'small',
    type: 'expanding',
  },
  START_DATE_COLUMN,
  END_DATE_COLUMN,
  UPDATED_AT_COLUMN,
];
