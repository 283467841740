// Vendor
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import classnames from 'classnames/bind';

// Internal
import { Button } from 'components';
import { isOonMemberId, nonEmptySpaces, required } from 'common/validation';
import Section, { SectionField } from '../../../../components/Section';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface OONFormValues {
  memberId: string;
  rosterId: string;
  hinDelimiter?: string;
  _340bDelimiter?: string;
  deaDelimiter?: string;
}

interface Props {
  isLoading: boolean;
  onSubmit: (values: OONFormValues) => void;
  initialValues: OONFormValues | undefined;
  onCancel: () => void;
}

const OONForm: FC<Props> = ({
  isLoading,
  onSubmit,
  onCancel,
  initialValues,
}) => {
  const fields: SectionField[] = [
    {
      label: 'Member ID',
      type: 'text',
      name: 'memberId',
      placeholder: 'Associated roster member id, e.g 50001',
      validation: [required, isOonMemberId],
      size: 6,
      isDisabled: isLoading,
    },
    {
      label: 'Roster ID',
      type: 'text',
      name: 'rosterId',
      placeholder: 'Internal roster id e.g apexus01',
      validation: [required, nonEmptySpaces],
      size: 6,
      isDisabled: isLoading,
    },
    {
      label: 'HIN Delimiter',
      type: 'text',
      name: 'hinDelimiter',
      placeholder: 'Delimiter for multiple HIN identifiers',
      validation: [nonEmptySpaces],
      size: 4,
      isDisabled: isLoading,
    },
    {
      label: '340B Delimiter',
      type: 'text',
      name: '_340bDelimiter',
      placeholder: 'Delimiter for multiple 340B identifiers',
      validation: [nonEmptySpaces],
      size: 4,
      isDisabled: isLoading,
    },
    {
      label: 'DEA Delimiter',
      type: 'text',
      name: 'deaDelimiter',
      placeholder: 'Delimiter for multiple DEA identifiers',
      validation: [nonEmptySpaces],
      size: 4,
      isDisabled: isLoading,
    },
  ];
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, values }) => (
        <form className={cx('form')} onSubmit={handleSubmit}>
          <>
            <div className={cx('buttons-section')}>
              <Button
                color='secondary'
                dataTestId='cancel-btn'
                disabled={false}
                label='Cancel'
                onClick={onCancel}
                type='button'
                variant='outlined'
              />
              <Button
                color='primary'
                dataTestId='upload-btn'
                disabled={form.getState().invalid || isLoading}
                label='Upload'
                loading={isLoading}
                type='submit'
                variant='contained'
              />
            </div>
            <Section
              change={form.change}
              fields={fields}
              title='Roster Information'
              values={values}
            />
          </>
        </form>
      )}
    />
  );
};

export default OONForm;
