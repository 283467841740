// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { keyBy } from 'lodash';

// Internal
import SearchBar from 'components/SearchBar';
import { Button, Pagination } from 'components';
import { NUMBER_PER_PAGE } from 'common/constants';
import { ContractViewRow, TradingPartner } from 'query';
import { NodeType } from 'common/config';
import { useContractManagementFlag, useUploadContractHeaderFlag } from 'hooks';
import BasicFilter from 'components/Filters/BasicFilter';
import Table, { TableRow } from 'components/Table';
import {
  convertStringToFilterStatus,
  filterItems,
  getColumns,
  rawDataToRows,
} from './service';
import { FilterStatus } from '../../service';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  contracts: ContractViewRow[];
  countLoading: boolean;
  currentPage: number;
  loading: boolean;
  paginationToggle: (params: number) => void;
  searchText: string;
  totalCount: number;
  updateSearchText: (params: string) => void;
  updateFilterStatus: (status: FilterStatus, active: boolean) => void;
  statusFilter: FilterStatus;
  isFilterActive?: boolean;
  tpType: NodeType;
  tradingPartners: TradingPartner[];
  isCreateButtonVisible?: boolean;
  uploadContractHeader: () => void;
  isUploadButtonVisible?: boolean;
}

const Contracts: FC<Props> = (props) => {
  const {
    contracts,
    countLoading,
    currentPage,
    statusFilter,
    isFilterActive,
    loading,
    paginationToggle,
    searchText,
    totalCount,
    updateSearchText,
    updateFilterStatus,
    tpType,
    tradingPartners,
    isCreateButtonVisible = true,
    uploadContractHeader,
    isUploadButtonVisible,
  } = props;

  const navigate = useNavigate();
  const uploadContractHeaderFlag = useUploadContractHeaderFlag();

  const hasContracts = contracts.length > 0;

  if (!tpType) return null;

  const isMFR = tpType === 'MFR';

  const isFiltered =
    searchText !== '' || statusFilter !== FilterStatus.ALL_STATUS;
  const disableSearchFilter = !loading && !hasContracts && !isFiltered;

  const applyFiltersCallback = (selected: string, active: boolean) => {
    const status: FilterStatus = convertStringToFilterStatus(selected);
    updateFilterStatus(status, active);
  };

  const tradingPartnersDictionary = keyBy(tradingPartners, (p) => p.id);

  const columns = getColumns(tpType);
  const [rows, updateRows] = useState([] as TableRow[]);

  // Format the raw data
  useEffect(() => {
    if (!loading) {
      updateRows(
        rawDataToRows(contracts, tradingPartners, tradingPartnersDictionary)
      );
    }
  }, [contracts, tradingPartners]);

  return (
    <div className={cx('container')}>
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            className={cx('search')}
            onSubmit={(text) => {
              updateSearchText(text);
            }}
            placeholder='Search by ID or description'
            disabled={disableSearchFilter}
            text={searchText || ''}
          />
          {isMFR && (
            <BasicFilter
              cFilterActive={isFilterActive}
              cFilter={statusFilter}
              items={filterItems}
              applyFilters={applyFiltersCallback}
              disableFilters={disableSearchFilter}
            />
          )}
        </div>
        <div className={cx('floatRight', 'row')}>
          {uploadContractHeaderFlag && isUploadButtonVisible && (
            <Button
              dataTestId='uploadContractHeaderBtn'
              label='Upload Contract Header'
              onClick={uploadContractHeader}
              style={{
                marginRight: 8,
                minWidth: 152,
                width: '180px',
              }}
            />
          )}
          {useContractManagementFlag() && isCreateButtonVisible && (
            <Button
              dataTestId='createContractBtn'
              label='Create Contract'
              onClick={() => navigate('/contracts/create', { replace: true })}
              style={{
                marginRight: 8,
                minWidth: 152,
              }}
            />
          )}
        </div>
      </section>
      <Table
        columns={columns}
        emptyText='No contracts found'
        isLoading={loading}
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={countLoading}
        onChangePage={({ currentPage }: { currentPage: number }) => {
          paginationToggle(currentPage);
        }}
        isDisplayed={hasContracts}
        totalCount={totalCount}
      />
    </div>
  );
};

export default Contracts;
