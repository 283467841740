// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';
import { Grid, GridSize, MenuItem } from '@material-ui/core';
import { get } from 'lodash';

// Internal
import { composeValidators, required } from 'common/validation';
import {
  Checkbox,
  CheckboxWithPopover,
  DatePicker,
  PriceField,
  Select,
  TextField,
} from 'components/index';
import { truncateDatetime } from 'common/helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface SectionField {
  checked?: boolean;
  customError?: string;
  fallbackDate?: string;
  handleOnBlur?: (values: any) => void;
  handleOnChange?: any;
  handleOnClick?: (key: string) => void;
  isDisabled?: boolean;
  label: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  namePrefix?: string;
  placeholder?: string;
  selectItems?: {
    disabled?: boolean;
    label: string;
    name: string;
  }[];
  showLabel?: boolean;
  size?: GridSize;
  type?: string;
  validation?: any[];
  width?: string;
}

interface Props {
  change?: any;
  children?: any;
  customRootStyles?: string;
  dataTestId?: string;
  fields?: SectionField[];
  title: string;
  values?: any;
}

const Section: FC<Props> = (props) => {
  const {
    change,
    children,
    customRootStyles = '',
    dataTestId,
    fields,
    title,
    values,
  } = props;

  return (
    <div
      className={cx(`${customRootStyles}` || 'root')}
      data-testid={dataTestId}
    >
      <header className={cx('header')}>{title}</header>
      {fields && fields.length > 0 && (
        <Grid container direction='row' spacing={4}>
          {fields.map((f) => {
            const {
              checked,
              customError,
              fallbackDate,
              handleOnBlur,
              handleOnChange,
              handleOnClick,
              isDisabled = false,
              label,
              maxDate,
              minDate,
              name,
              namePrefix,
              placeholder,
              selectItems,
              showLabel = true,
              size = 4,
              type = 'text',
              validation = [],
              width = '100%',
            } = f;
            const constructedFieldName = namePrefix
              ? `${namePrefix}-${name}`
              : name;

            return (
              <Grid item key={name} className={cx('field')} xs={size}>
                {showLabel && (
                  <span className={cx('key')}>
                    {label}
                    {validation.includes(required) && ' *'}
                  </span>
                )}
                {(type === 'text' || type === 'string') && (
                  <Field
                    // @ts-ignore
                    component={TextField}
                    customError={customError}
                    disabled={isDisabled}
                    handleOnBlur={handleOnBlur}
                    id={constructedFieldName}
                    name={constructedFieldName}
                    placeholder={`Enter ${placeholder || label}`}
                    style={{ width }}
                    validate={composeValidators(...validation)}
                  />
                )}
                {type === 'date' && (
                  <div style={{ width }}>
                    <Field
                      // @ts-ignore
                      component={DatePicker}
                      fallbackDate={fallbackDate}
                      format={(value) => (value ? truncateDatetime(value) : '')}
                      id={constructedFieldName}
                      maxDate={maxDate}
                      minDate={minDate}
                      name={constructedFieldName}
                      placeholder={`Enter ${placeholder || label}`}
                      validate={composeValidators(...validation)}
                    />
                  </div>
                )}
                {type === 'select' && (
                  <Field
                    // @ts-ignore
                    component={Select}
                    disabled={isDisabled}
                    id={name}
                    label={
                      // @ts-ignore
                      selectItems?.find(
                        ({ name: itemName }) => itemName === values?.[name]
                      )?.label ||
                      // @ts-ignore
                      values?.[name] ||
                      `Select ${placeholder || label}`
                    }
                    name={name}
                    style={{ width }}
                    validate={composeValidators(...validation)}
                  >
                    {selectItems?.map(
                      ({
                        disabled = false,
                        label: itemLabel,
                        name: itemName,
                      }) => (
                        <MenuItem
                          disabled={disabled}
                          key={itemName}
                          onClick={() => {
                            change(name, itemName);
                            if (handleOnClick) handleOnClick(itemName);
                          }}
                          style={{
                            maxWidth: 'none',
                            paddingRight: 0,
                          }}
                          value={itemName}
                        >
                          {itemLabel}
                        </MenuItem>
                      )
                    )}
                  </Field>
                )}
                {type === 'checkbox' && (
                  <Field
                    key={type}
                    checked={
                      checked || get(values, constructedFieldName, false)
                    }
                    // @ts-ignore
                    component={Checkbox}
                    disabled={isDisabled}
                    handleOnChange={handleOnChange}
                    label={label}
                    name={constructedFieldName}
                    type='checkbox'
                  />
                )}
                {type === 'CheckboxWithPopover' && (
                  <Field
                    key={type}
                    checked={get(values, constructedFieldName, false)}
                    // @ts-ignore
                    component={CheckboxWithPopover}
                    disabled={isDisabled}
                    label={label}
                    name={constructedFieldName}
                    type='checkbox'
                  />
                )}
                {type === 'price' && (
                  <Field
                    // @ts-ignore
                    component={PriceField}
                    customError={customError}
                    disabled={isDisabled}
                    handleOnBlur={handleOnBlur}
                    name={constructedFieldName}
                    placeholder={`Enter ${placeholder || label}`}
                    style={{ width }}
                    validate={composeValidators(...validation)}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
      {children}
    </div>
  );
};

export default Section;
