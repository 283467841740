// Vendor
import {
  Checkbox,
  FormControlLabel,
  Popover,
  Typography,
} from '@material-ui/core';
import React, { CSSProperties, FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Button } from 'components';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  values?: any;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  handleOnChange?: any;
  input: {
    onChange: (e: any) => void;
  };
  label: string;
  style?: CSSProperties;
}

const CheckboxComponent: FC<Props> = ({
  checked = false,
  disabled = false,
  handleOnChange,
  values = [],
  name = '',
  input: { onChange },
  label = '',
  style = {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            color='primary'
            disabled={disabled}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onClick={(e) => (checked ? onChange(e) : handleClick(e))}
          />
        }
        label={label}
        style={{ ...style }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div>
          <Typography className={cx('title')}>{`Add ${label}?`}</Typography>
          <section className={cx('buttons')}>
            <Button
              color='secondary'
              dataTestId='popoverCancelBtn'
              label='Cancel'
              onClick={handleClose}
              variant='outlined'
              style={{
                marginLeft: 8,
                padding: 0,
                width: 70,
              }}
            />
            <Button
              dataTestId='popoverSubmitButton'
              label='Add'
              onClick={() => {
                onChange(!values[name]);
                if (handleOnChange) handleOnChange();
                handleClose();
              }}
              style={{
                marginLeft: 8,
                padding: 0,
                width: 70,
              }}
              type='submit'
            />
          </section>
        </div>
      </Popover>
    </>
  );
};

export default CheckboxComponent;
