// Vendor
import React from 'react';

const IconLoading = () => (
  <svg width='312' height='282' viewBox='0 0 312 282' data-testid='iconLoading'>
    <defs>
      <filter
        id='icon_loading_a'
        width='106.7%'
        height='109.2%'
        x='-3.3%'
        y='-3.7%'
        filterUnits='objectBoundingBox'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
          stdDeviation='3'
        />
        <feColorMatrix
          in='shadowBlurOuter1'
          values='0 0 0 0 0.48627451 0 0 0 0 0.560784314 0 0 0 0 0.592156863 0 0 0 0.295290647 0'
        />
      </filter>
      <rect
        id='icon_loading_b'
        width='300'
        height='218'
        x='0'
        y='.264'
        rx='4'
      />
    </defs>
    <g fill='none' transform='translate(6)'>
      <ellipse
        cx='150.748'
        cy='140.648'
        fill='#E6E8F2'
        rx='140.274'
        ry='140.648'
      />
      <g transform='translate(0 29.925)'>
        <use
          fill='#000'
          filter='url(#icon_loading_a)'
          xlinkHref='#icon_loading_b'
        />
        <use fill='#FFF' xlinkHref='#icon_loading_b' />
      </g>
      <path
        fill='#E6E8F2'
        d='M31.708 53.827h96c1.105 0 2 .895 2 2v7.97c0 1.105-.895 2-2 2h-96c-1.105 0-2-.895-2-2v-7.97c0-1.105.895-2 2-2zM32 79h236c1.105 0 2 .895 2 2v7.97c0 1.105-.895 2-2 2H32c-1.105 0-2-.895-2-2V81c0-1.105.895-2 2-2zm-.292 44.827h96c1.105 0 2 .895 2 2v7.97c0 1.105-.895 2-2 2h-96c-1.105 0-2-.895-2-2v-7.97c0-1.105.895-2 2-2zM32 149h236c1.105 0 2 .895 2 2v7.97c0 1.105-.895 2-2 2H32c-1.105 0-2-.895-2-2V151c0-1.105.895-2 2-2zm-.292 44.827h96c1.105 0 2 .895 2 2v7.97c0 1.105-.895 2-2 2h-96c-1.105 0-2-.895-2-2v-7.97c0-1.105.895-2 2-2zM32 219h236c1.105 0 2 .895 2 2v7.97c0 1.105-.895 2-2 2H32c-1.105 0-2-.895-2-2V221c0-1.105.895-2 2-2z'
      />
    </g>
  </svg>
);

export default IconLoading;
