// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { first } from 'lodash';

// Internal
import { QUERY_CUSTOMER_BY_ID } from 'query';
import { useAppBarSetTitle, useTradingPartnerConfig } from 'hooks';
import { CreateCustomer } from 'pages';
import { dirtyCustomer } from 'common/helpers';
import { IconLoading, IconSystemError } from 'styles/images';

// Styles
import styles from './UpdateCustomer.module.css';
const cx = classnames.bind(styles);

const Container: FC = () => {
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const { id } = useParams() as { id: string };

  const { data, loading, error } = useQuery(QUERY_CUSTOMER_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
      memberId,
    },
    skip: !id || !memberId,
  });

  const customer = data?.customer;

  const name = first(customer?.names);
  useAppBarSetTitle(loading ? '' : `Update ${name ?? '{ }'}`);

  if (loading || !customer) return null;

  // Note: use dirty customer in the update page so that all invalid data will be present
  const dirty = dirtyCustomer(customer);

  return error ? (
    <div className={cx('error')}>
      <IconSystemError />
    </div>
  ) : loading ? (
    <div className={cx('error')}>
      <IconLoading />
    </div>
  ) : (
    <CreateCustomer isUpdateAction currentCustomer={dirty} />
  );
};

export default Container;
