// Vendor
import React from 'react';

const IconCrossPay = () => (
  <svg width='13' height='17' viewBox='0 0 13 17'>
    <g fill='none' fillRule='evenodd'>
      <g fill='#00BC96' fillRule='nonzero'>
        <path
          d='M22 12v-.19c0-.197-.078-.388-.219-.529l-3.06-3.062c-.14-.14-.33-.219-.53-.219H18v4h4zm-.75 12c.416 0 .75-.334.75-.75V13h-4.25c-.413 0-.75-.337-.75-.75V8h-6.25c-.416 0-.75.334-.75.75v14.5c0 .416.334.75.75.75h10.5zm-6.5-13h-2.5c-.138 0-.25-.112-.25-.25v-.5c0-.138.112-.25.25-.25h2.5c.138 0 .25.112.25.25v.5c0 .138-.112.25-.25.25zm0 2h-2.5c-.138 0-.25-.112-.25-.25v-.5c0-.138.112-.25.25-.25h2.5c.138 0 .25.112.25.25v.5c0 .138-.112.25-.25.25zm1.5 9h-.5c-.138 0-.25-.112-.25-.25v-.76c-.353-.017-.696-.14-.98-.354-.122-.091-.128-.274-.018-.38l.367-.35c.087-.082.215-.086.317-.022.12.075.258.116.4.116h.879c.203 0 .368-.185.368-.412 0-.186-.112-.35-.274-.398l-1.406-.422c-.58-.174-.987-.732-.987-1.356 0-.766.596-1.389 1.334-1.408v-.754c0-.138.112-.25.25-.25h.5c.138 0 .25.112.25.25v.76c.352.017.696.14.98.354.122.091.128.274.018.38l-.367.35c-.087.082-.216.086-.317.022-.12-.076-.258-.116-.4-.116h-.879c-.203 0-.369.185-.369.412 0 .186.113.35.274.398l1.407.422c.58.174.986.732.986 1.356 0 .766-.595 1.389-1.333 1.408v.754c0 .138-.112.25-.25.25z'
          transform='translate(-554 -520) translate(289 489) translate(231) translate(24.467 23.035)'
        />
      </g>
    </g>
  </svg>
);

export default IconCrossPay;
