// Vendor
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { GridSize } from '@material-ui/core';
import { get } from 'lodash';

// Internal
import { required } from 'common/validation';
import {
  ContractProductPrice,
  MultiplePriceProduct,
  ContractFormProps,
} from 'pages/Contracts/Create/constants';
import { DateRange } from 'common/types';
import { Product } from 'query';
import { truncateDatetime } from 'common/helpers';
import { SectionField } from 'components/Section';

export const getInitialContractProductPrice = (
  startDate: string,
  endDate: string
) =>
  ({
    id: v4(),
    startDate,
    endDate,
    contractPrice: '',
  } as ContractProductPrice);

/**
 * @description uses contract and product date range to determine a valid range of dates for pricing
 * @param values object, containing contract-header data
 * @param product object, containing data of a single product
 * @returns valid range of dates to select for pricing
 */

// for now this function works properly only if date ranges overlap
export const getPricingDateRange = (
  values: ContractFormProps,
  product: MultiplePriceProduct | Product
): string[] => {
  let pricingStartDate;
  let pricingEndDate;

  const contractDateRange: DateRange = {
    startDate: get(values, 'startDate', ''),
    endDate: get(values, 'endDate', ''),
  };

  const productDateRange: DateRange = {
    startDate:
      get(product, 'data.startDate', '') || get(product, 'start_date', ''),
    endDate: get(product, 'data.endDate', '') || get(product, 'end_date', ''),
  };

  const contractStartDate = contractDateRange.startDate;
  const contractEndDate = contractDateRange.endDate;

  const productStartDate = productDateRange.startDate;
  const productEndDate = productDateRange.endDate;

  if (
    contractStartDate === '' ||
    contractEndDate === '' ||
    productStartDate === '' ||
    productEndDate === ''
  ) {
    throw new Error('Missing one of the provided dates');
  }

  // determine start date
  if (dayjs(productStartDate).isAfter(contractStartDate)) {
    pricingStartDate = productStartDate;
  } else {
    pricingStartDate = contractStartDate;
  }

  // determine end date
  if (dayjs(productEndDate).isBefore(contractEndDate)) {
    pricingEndDate = productEndDate;
  } else {
    pricingEndDate = contractEndDate;
  }

  // convert date-string to utc-format-date-string
  pricingStartDate = truncateDatetime(pricingStartDate) || '';
  pricingEndDate = truncateDatetime(pricingEndDate) || '';

  return [pricingStartDate, pricingEndDate];
};

export const convertStringToDate = (string: string) => dayjs(string).toDate();

export const productPriceFields = (
  index: number,
  pricingIndex: number,
  minimumDatePossible: string,
  maximumDatePossible: string
): SectionField[] => [
  {
    fallbackDate: minimumDatePossible,
    label: 'Start Date',
    maxDate: convertStringToDate(maximumDatePossible),
    minDate: convertStringToDate(minimumDatePossible),
    name: `products.${index}.pricings.${pricingIndex}.startDate`,
    size: 4 as GridSize,
    type: 'date',
    validation: [required],
  },
  {
    fallbackDate: maximumDatePossible,
    label: 'End Date',
    maxDate: convertStringToDate(maximumDatePossible),
    minDate: convertStringToDate(minimumDatePossible),
    name: `products.${index}.pricings.${pricingIndex}.endDate`,
    size: 4 as GridSize,
    type: 'date',
    validation: [required],
  },
  {
    label: 'Contract Price',
    name: `products.${index}.pricings.${pricingIndex}.contractPrice`,
    size: 4 as GridSize,
    type: 'price',
    validation: [required],
  },
];
