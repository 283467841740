// Vendor
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrAfter';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { concatAll, Monoid } from 'fp-ts/lib/Monoid';

// Internal
import { generateID } from 'common/utils';
import { ContractPricingRow, TradingPartner } from 'query';
import {
  contractPath,
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  truncateDatetime,
  PricingErrors,
  validatePricingsOverlapping,
  convertDollarValueToAPIValue,
} from 'common/helpers';
import {
  isSameOrAfterDate,
  maxPriceLengthValidator,
  required,
  withinDateRange,
} from 'common/validation';
import {
  toMediledgerListId,
  toMediledgerProductListId,
} from 'common/helpers/mlIdUtils';
import { ProductPrice } from 'types/generated/Product';
import {
  ButtonsLoading,
  ContractFormProps,
  Distributor,
  FormList,
  openClose,
  Pricing,
  Product,
  ContractMode,
  ContractProductPrice,
  NUMBER_OF_PRICINGS_PER_PAGE,
} from 'pages/Contracts/Create/constants';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export const PRICING_NOT_IN_RANGE =
  'Contract pricing date range must be within contract date range';

export const DIST_NOT_IN_RANGE =
  'Distributor effectivity dates must be within contract date range';

export type ButtonsAvailability = {
  cancel: boolean;
  submit: boolean;
  back: boolean;
  next: boolean;
};

export const onCancel = (
  activeStep: number,
  contractId: string,
  contractModeType: ContractMode,
  memberId: number,
  navigate: any,
  setActiveStep: any
) => {
  setActiveStep(activeStep);

  if (memberId && contractModeType === 'update') {
    // redirect user to contract details
    navigate(
      `${contractPath(memberId, contractId)}?initialTab=${activeStep + 1}`,
      { replace: true }
    );
  } else {
    navigate('/contracts', { replace: true });
  }
};

export const onSubmit = async (
  api: any,
  buttonsLoading: ButtonsLoading,
  initialValues: ContractFormProps,
  formValues: ContractFormProps,
  memberId: number,
  setButtonsLoading: Dispatch<SetStateAction<ButtonsLoading>>,
  setContractId: (id: string) => void,
  setIsFormSubmitted: (isFormSubmitted: boolean) => void
) => {
  const initialValuesProducts = initialValues?.products;

  try {
    setButtonsLoading({ ...buttonsLoading, submit: true });

    let id: string = formValues.contractId;
    if (id === '') {
      id = generateID();
      setContractId(id);
    }

    // Current timestamp when request has started to being called
    const timestamp = new Date().toISOString();

    const apiPromises: (Promise<AxiosResponse> | undefined)[] = [];

    // Create contract header
    apiPromises.push(
      api?.createContractHeader({
        id,
        timestamp,
        ...parseOverViewInfoForAPICall(formValues),
      })
    );

    if (
      !isEqual(
        { products: removeProductFields(initialValuesProducts) },
        { products: removeProductFields(formValues.products) }
      ) ||
      !isEqual(initialValues.priceTypes, formValues.priceTypes)
    ) {
      onSubmitPricings(
        api,
        apiPromises,
        formValues,
        id,
        initialValuesProducts,
        timestamp
      );
    }

    if (
      !isEqual(
        { productLists: removeUncheckedLists(initialValues.productLists) },
        { productLists: removeUncheckedLists(formValues.productLists) }
      )
    ) {
      onSubmitAssociation(
        api,
        apiPromises,
        initialValues,
        formValues,
        id,
        memberId,
        timestamp,
        true
      );
    }

    if (!isEqual(initialValues.customerList, formValues.customerList)) {
      onSubmitAssociation(
        api,
        apiPromises,
        initialValues,
        formValues,
        id,
        memberId,
        timestamp,
        false
      );
    }

    await Promise.all(apiPromises);

    setIsFormSubmitted(true);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const onSubmitPricings = (
  api: any,
  apiPromises: (Promise<AxiosResponse> | undefined)[],
  formValues: ContractFormProps,
  id: string,
  initialValuesProducts: Product[],
  timestamp: string
) => {
  formValues.products?.forEach((product: Product) => {
    const formPricingsWithFormPriceTypes = product.pricings.map((p) => ({
      ...p,
      priceType: formValues.priceTypes.find(
        (pt) => pt.externalId === product.externalId
      )?.priceType,
    }));
    // We should NOT update pricings that are not changed
    if (
      isEqual(
        formPricingsWithFormPriceTypes,
        initialValuesProducts.find((prod) => prod.id === product.id)?.pricings
      )
    )
      return;
    apiPromises.push(
      api?.createContractPricing({
        id: `${id}:${product.idType}:${product.externalId}`,
        productId: `${product.idType}:${product.externalId}`,
        productDescription: product.description,
        contractId: id,
        timestamp,
        pricing: parsePricingsForAPICall(
          formPricingsWithFormPriceTypes,
          product.productPrices,
          product.unitOfMeasure
        ),
      })
    );
  });
};

// For product list and customer list
export const onSubmitAssociation = (
  api: any,
  apiPromises: (Promise<AxiosResponse> | undefined)[],
  fetchedData: ContractFormProps,
  formValues: ContractFormProps,
  id: string,
  memberId: number,
  timestamp: string,
  isProductList: boolean
) => {
  const oldAssociations = isProductList
    ? fetchedData.productLists || []
    : [{ id: fetchedData.customerList, checked: true }];
  const formAssociations = isProductList
    ? formValues.productLists
    : // This checks if customer list form value is empty since it can be deselected
    formValues.customerList
    ? [{ id: formValues.customerList, checked: true }]
    : [];

  const associationIDsToDelete = getIdsToDelete({
    oldIDs: oldAssociations.map((list) => list.id) ?? [],
    currentIDs:
      removeUncheckedLists(formAssociations).map((list: FormList) => list.id) ??
      [],
  });

  if (isProductList) {
    // Delete unselected associations
    associationIDsToDelete.forEach((listId) => {
      apiPromises.push(
        api?.upsertContractAssociation({
          active: false,
          memberId: `ml:member:${memberId}`,
          toId: `ml:contract-header:${memberId}:${id}`,
          timestamp,
          kind: 'product-list-association',
          fromId: toMediledgerProductListId(listId, memberId),
        })
      );
    });

    // Add associations
    removeUncheckedLists(formAssociations)
      .map((list: FormList) => list.id)
      .forEach((listId: string) => {
        apiPromises.push(
          api?.upsertContractAssociation({
            active: true,
            memberId: `ml:member:${memberId}`,
            toId: `ml:contract-header:${memberId}:${id}`,
            timestamp,
            kind: 'product-list-association',
            fromId: toMediledgerProductListId(listId, memberId),
          })
        );
      });
  } else {
    // Delete unselected associations
    associationIDsToDelete.forEach((listId) => {
      apiPromises.push(
        api?.upsertCustomerAssociation({
          active: false,
          memberId: `ml:member:${memberId}`,
          toId: `ml:contract-header:${memberId}:${id}`,
          timestamp,
          kind: 'contract-list-association',
          fromId: toMediledgerListId(listId, memberId),
        })
      );
    });

    // Add associations
    removeUncheckedLists(formAssociations)
      .map((list: FormList) => list.id)
      .forEach((listId: string) => {
        apiPromises.push(
          api?.upsertCustomerAssociation({
            active: true,
            memberId: `ml:member:${memberId}`,
            toId: `ml:contract-header:${memberId}:${id}`,
            timestamp,
            kind: 'contract-list-association',
            fromId: toMediledgerListId(listId, memberId),
          })
        );
      });
  }
};

export const getIdsToDelete = ({
  oldIDs,
  currentIDs,
}: {
  oldIDs: string[];
  currentIDs: string[];
}) =>
  oldIDs.filter((oldID) => {
    if (oldID === '') return false;
    const isOldIDInNewList = currentIDs.find((id) => id === oldID);
    return !isOldIDInNewList;
  });

export const parseOverViewInfoForAPICall = (values: ContractFormProps) => {
  const {
    contractType,
    description,
    distributors: distProps,
    endDate: endDateProp,
    entity,
    openClosed,
    startDate: startDateProp,
  } = values;

  const open = openClosed === openClose[0].name;

  const checkedDists = removeUncheckedLists(distProps);

  const distributors = checkedDists.map((dist: Distributor) => ({
    id: dist.id,
    startDate: convertStartDateToUtcTimestamp(dist.startDate),
    endDate: convertEndDateToUtcTimestamp(dist.endDate),
  }));

  const startDate = convertStartDateToUtcTimestamp(startDateProp);
  const endDate = convertEndDateToUtcTimestamp(endDateProp);

  const contractEntities = entity ? [{ id: entity }] : [];

  return {
    contractType,
    description,
    open,
    distributors,
    startDate,
    endDate,
    contractEntities,
  };
};

export const parsePricingsForAPICall = (
  pricings: Pricing[],
  productPrices: ProductPrice[] | undefined,
  unitOfMeasure: string
) => {
  const allParsedPricings: {}[] = [];
  pricings.forEach((pricing) => {
    const { startDate, endDate, contractPrice, priceType } = pricing;

    const parsedPricings = getProductPricingWAC(
      priceType || 'WAC',
      productPrices,
      startDate,
      endDate,
      convertDollarValueToAPIValue(contractPrice),
      unitOfMeasure
    );

    allParsedPricings.push(...parsedPricings);
  });

  return allParsedPricings;
};

export const getProductPricingWAC = (
  priceType: string,
  productPricesProp: ProductPrice[] | undefined,
  contractPricingStartDate: string,
  contractPricingEndDate: string,
  contractPrice: number,
  unitOfMeasure: string
): {}[] => {
  const parsedPricings: {}[] = [];

  const productPrices = productPricesProp?.filter(
    (productPrice) => productPrice.priceType === priceType
  );
  if (!productPrices) return parsedPricings;

  // Grab the first product pricing that our contract price start date falls into
  let indexOfFirstProductPricing: number = 0;
  const firstProductPricing = productPrices?.find((productPrice, i) => {
    indexOfFirstProductPricing = i;
    return dayjs(contractPricingStartDate).isBetween(
      dayjs(productPrice.startDate),
      dayjs(productPrice.endDate),
      'day',
      '[]'
    );
  });

  const findAdditionalPricings = (
    index: number,
    productPrice: ProductPrice | undefined,
    pricingStart: string
  ) => {
    if (!productPrice) return;

    if (dayjs(contractPricingEndDate).isSameOrBefore(productPrice.endDate)) {
      parsedPricings.push({
        startDate: convertStartDateToUtcTimestamp(
          truncateDatetime(pricingStart) || ''
        ),
        endDate: convertEndDateToUtcTimestamp(
          truncateDatetime(contractPricingEndDate) || ''
        ),
        contractPrice,
        unitOfMeasure,
        wac: Number(productPrice?.price),
        priceType,
      });
    } else {
      parsedPricings.push({
        startDate: convertStartDateToUtcTimestamp(
          truncateDatetime(pricingStart) || ''
        ),
        endDate: convertEndDateToUtcTimestamp(
          truncateDatetime(productPrice.endDate) || ''
        ),
        contractPrice,
        unitOfMeasure,
        wac: Number(productPrice?.price),
        priceType,
      });

      const nextProductPrice = productPrices[index + 1];
      if (!nextProductPrice) return;

      if (
        dayjs(productPrice.endDate).isBetween(
          dayjs(productPrice.endDate),
          dayjs(nextProductPrice.startDate),
          'day',
          '[]'
        )
      ) {
        findAdditionalPricings(
          index + 1,
          nextProductPrice,
          nextProductPrice.startDate || ''
        );
      }
    }
  };

  findAdditionalPricings(
    indexOfFirstProductPricing,
    firstProductPricing,
    contractPricingStartDate
  );

  return parsedPricings;
};

// Disable 'Set Pricing' tab if no product lists are selected
export const pricingTabDisabledCallback = (productLists: []): boolean =>
  !productLists?.some((list: any) => list.checked);

type PricingFieldsErrorMessages = {
  contractPrice?: string;
  pricingIndex: number;
  startDate?: string;
  endDate?: string;
};

type ProductErrorsWithIdentifier = {
  id: string;
  index: number;
  pricings: PricingFieldsErrorMessages[];
};

/**
 * @description validates pricings overlapping of each product. Validation only occurs on form change.
 * @param contractStartDate
 * @param contractEndDate
 * @param products object, containing products errors of the contract
 */
export const getPricingsErrors = (
  contractStartDate: string,
  contractEndDate: string,
  products: any[]
) => {
  const productErrors: ProductErrorsWithIdentifier[] = [];

  products?.forEach((product, productIndex) => {
    const pricings: ContractProductPrice[] = product.pricings ?? [];

    // Validate products pricings overlapping
    const pricingsOverlapErrorMessages: PricingErrors =
      validatePricingsOverlapping(pricings);

    const pricingErrors: PricingFieldsErrorMessages[] = [];

    // For every pricing error, construct object, that contains error messages for every input
    pricings.forEach((pricing, i: number) => {
      const { contractPrice, endDate, startDate } = pricing;
      const contractPriceError =
        required(contractPrice) ||
        maxPriceLengthValidator(contractPrice.toString());

      const startDateError =
        withinDateRange({
          startDate: contractStartDate,
          endDate: contractEndDate,
          message: PRICING_NOT_IN_RANGE,
        })(startDate) || pricingsOverlapErrorMessages[i];
      const endDateError =
        isSameOrAfterDate(startDate)(endDate) ||
        withinDateRange({
          startDate: contractStartDate,
          endDate: contractEndDate,
          message: PRICING_NOT_IN_RANGE,
        })(endDate) ||
        pricingsOverlapErrorMessages[i];

      if (
        pricingsOverlapErrorMessages[i] !== undefined ||
        contractPriceError ||
        startDateError ||
        endDateError
      ) {
        pricingErrors.push({
          pricingIndex: i,
          ...(contractPriceError && { contractPrice: contractPriceError }),
          ...(startDateError && { startDate: startDateError }),
          ...(endDateError && { endDate: endDateError }),
        });
      }
    });

    if (pricingErrors.length !== 0) {
      productErrors.push({
        id: product.id,
        index: productIndex,
        pricings: pricingErrors,
      });
    }
  });

  return productErrors;
};

type TpType = 'gpo' | 'dist' | 'health-system';

// Converts trading partners to name/label pairs
export const getTPNameLabelPairs = (
  tpType: TpType,
  tradingPartners: TradingPartner[]
) =>
  tradingPartners
    .filter((tp) => tp.type.toLowerCase() === tpType)
    .map((tp) => ({ label: tp.name, name: `ml:member:${tp.id}` }));

export const convertPricingRowToContractProduct = (
  contractPricingRow: ContractPricingRow
) => ({
  description: contractPricingRow?.productDescription ?? '',
  idType: '',
  rawPriceValue: 0,
  unitOfMeasure: '',
  memberId: contractPricingRow.memberId,
  id: contractPricingRow.productId,
  contractId: contractPricingRow.contractId,
  timestamp: contractPricingRow.timestamp,
  productId: contractPricingRow.productId,
  pricings: contractPricingRow.pricing?.map((price: Pricing) => {
    const { endDate, startDate, contractPrice } = price;
    return {
      ...price,
      contractPrice: contractPrice / 1_000_000,
      productId: contractPricingRow.productId,
      endDate: truncateDatetime(endDate) ?? '',
      startDate: truncateDatetime(startDate) ?? '',
    };
  }),
  externalId: contractPricingRow.productId.split(':')[1],
  errors: contractPricingRow.errors,
});

export const calculateDisabledButtons = (
  formErrors: any,
  formValues: ContractFormProps,
  initialValues: any,
  activeStep: number,
  activeSubStep: number,
  buttonsLoading: ButtonsLoading
): ButtonsAvailability => {
  if (buttonsLoading.submit) {
    return {
      submit: true,
      next: true,
      back: true,
      cancel: true,
    };
  }

  const validations = {
    submit: !isEmpty(formErrors),
    next:
      buttonsLoading.next ||
      nextButtonValidation(formErrors, activeStep, activeSubStep),
    back: disabledButtonsMonoid.empty.back,
    cancel: disabledButtonsMonoid.empty.cancel,
  };
  const validationsResult = concatAll(disabledButtonsMonoid)([validations]);

  const dataHasChanged = updateDataChangedValidation(formValues, initialValues);

  return {
    ...validationsResult,
    submit: validationsResult.submit || !dataHasChanged,
  };
};

export const nextButtonValidation = (
  formErrors: any,
  activeStep: number,
  activeSubStep: number
): boolean => {
  if (activeStep === 1 && activeSubStep === 1) {
    return !isEmpty(formErrors);
  } else {
    const noProductErrors = { ...formErrors };
    delete noProductErrors.products;
    delete noProductErrors.productError;
    delete noProductErrors.missingPricingsToFill;
    return !isEmpty(noProductErrors);
  }
};

export const updateDataChangedValidation = (
  formValues: ContractFormProps,
  initialState: any
): boolean => {
  // Removing any extra fields from grabbing data when updating customer that is not in the form values
  // and removing any checked: false form values before comparing
  const initial = {
    ...initialState,
    distributors: removeUncheckedLists(initialState.distributors),
    productLists: removeUncheckedLists(initialState.productLists),
    products: removeProductFields(initialState.products),
  };

  const current = {
    ...formValues,
    distributors: removeUncheckedLists(formValues.distributors),
    productLists: removeUncheckedLists(formValues.productLists),
    products: removeProductFields(formValues.products),
  };

  return !isEqual(
    { ...initial, selectedCustomerListIDs: undefined, overallPriceType: '' },
    { ...current, selectedCustomerListIDs: undefined, overallPriceType: '' }
  );
};

export const removeProductFields = (products: Product[]) =>
  orderBy(products, ['id'])?.map((product: Product) => ({
    pricings: product.pricings?.map((pricing) => ({
      contractPrice: pricing.contractPrice,
      startDate: pricing.startDate,
      endDate: pricing.endDate,
    })),
  }));

export const removeUncheckedLists = (lists: any) =>
  // Form values will have all of the product lists available but since it's a checkbox
  // the checked attribute is a boolean, here we are creating a copy and removing the false
  // checked customer lists since it does not mean an update has been made if they are unchecked
  orderBy(
    lists?.filter((list: any) => list.checked),
    ['id'],
    ['asc']
  );

export const disabledButtonsMonoid: Monoid<ButtonsAvailability> = {
  empty: {
    cancel: false,
    submit: false,
    back: false,
    next: false,
  },
  concat(x: ButtonsAvailability, y: ButtonsAvailability): ButtonsAvailability {
    return {
      submit: x.submit || y.submit,
      next: x.next || y.next,
      cancel: x.cancel || y.cancel,
      back: x.back || y.back,
    };
  },
};

export const formValidation = (
  values: ContractFormProps,
  totalProductsCount: number
) => {
  const {
    distributors,
    endDate: contractEndDate,
    products,
    startDate: contractStartDate,
  } = values;

  const errors: any = {
    distributors: [],
    products: [],
  };

  const contractEndDateError =
    isSameOrAfterDate(contractStartDate)(contractEndDate);
  if (contractEndDateError) {
    errors.endDate = contractEndDateError;
  }

  distributors.forEach((distributor: Distributor, i: number) => {
    const { endDate, startDate } = distributor;

    const startDateError =
      startDate &&
      withinDateRange({
        startDate: contractStartDate,
        endDate: contractEndDate,
        message: DIST_NOT_IN_RANGE,
      })(startDate);

    const endDateError =
      endDate &&
      (withinDateRange({
        startDate: contractStartDate,
        endDate: contractEndDate,
        message: DIST_NOT_IN_RANGE,
      })(endDate) ||
        isSameOrAfterDate(startDate)(endDate));

    if (startDateError || endDateError) {
      errors.distributors[i] = {
        ...(startDateError && { startDate: startDateError }),
        ...(endDateError && { endDate: endDateError }),
      };
    }
  });

  // Check each pricings dates for overlapping and price errors then update error messages for them
  // Pricings in every product are validated separately
  const errMsgs = getPricingsErrors(
    contractStartDate,
    contractEndDate,
    products
  );

  errMsgs.forEach((msg) => {
    const { index, pricings } = msg;

    if (pricings.length > 0) {
      errors.products[index] = { pricings: [] };
    }

    pricings.forEach((pricing) => {
      const { contractPrice, startDate, endDate, pricingIndex } = pricing;

      errors.products[index].pricings[pricingIndex] = {
        contractPrice,
        startDate,
        endDate,
      };
    });
  });

  if (errors.distributors.length === 0) {
    delete errors.distributors;
  }

  if (errors.products.length === 0) {
    delete errors.products;
  }

  if (errors.products) {
    errors.productError = true;
  }

  if ((values.products?.length ?? 0) < totalProductsCount) {
    errors.missingPricingsToFill = true;
  }

  return errors;
};

export const calculatePricingPaginatedIndex = (
  currentIndex: number,
  currentPage: number
) => currentIndex + (currentPage - 1) * NUMBER_OF_PRICINGS_PER_PAGE;
