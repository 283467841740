export const formatPublishError = (error: any): string => {
  switch (error.kind) {
    case 'schema-error':
      return 'Customer invalid format';
    case 'non-existent-path':
      return `Invalid API path ${error.path}`;
    case 'batch-process-error':
      return `Error publishing batch`;
    default:
      return 'Unknown error';
  }
};
