// Vendor
import React, { FC } from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const IconCustomerProposals: FC<Props> = ({ className, fill = '#1482FC' }) => (
  <svg className={className} width='24' height='26' viewBox='0 0 24 26'>
    <defs>
      <path
        id='a'
        d='M8.75 13.7c.347 0 .642-.125.885-.374.243-.25.365-.553.365-.91V3.532c0-.357-.122-.66-.365-.91L7.448.375A1.19 1.19 0 0 0 6.562 0H1.25a1.19 1.19 0 0 0-.885.375C.122.625 0 .928 0 1.285v11.13c0 .358.122.66.365.91s.538.375.885.375h7.5zm0-1.284h-7.5V1.284h4.167v2.783c0 .179.06.33.182.455.121.125.27.187.443.187H8.75v7.707zm-.104-8.99h-1.98V1.39l1.98 2.034zm-1.459 4.28c.087 0 .161-.03.222-.093a.314.314 0 0 0 .091-.228v-.749c0-.09-.03-.165-.091-.227a.297.297 0 0 0-.221-.094H2.813a.297.297 0 0 0-.222.094.314.314 0 0 0-.091.227v.75c0 .089.03.164.091.227.06.062.135.093.221.093h4.376zm0 2.248c.087 0 .161-.031.222-.094a.314.314 0 0 0 .091-.227v-.75c0-.088-.03-.164-.091-.227a.297.297 0 0 0-.221-.093H2.813a.297.297 0 0 0-.222.093.314.314 0 0 0-.091.228v.749c0 .09.03.165.091.227.06.063.135.094.221.094h4.376z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(7 5.972)'>
      <mask id='customerProposal' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <use fill='#011F42' fillRule='nonzero' xlinkHref='#a' />
      <g fill={fill} mask='url(#customerProposal)'>
        <path d='M-7-5.138h24v24.66H-7z' />
      </g>
    </g>
  </svg>
);

export default IconCustomerProposals;
