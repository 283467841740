// Vendor
import React, { FC } from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const IconErrorAlert: FC<Props> = ({ className, fill = '#CB6671' }) => (
  <svg className={className} width='16' height='16' viewBox='0 0 16 16'>
    <defs>
      <filter
        id='IconErrorAlertA'
        width='187.5%'
        height='187.5%'
        x='-43.8%'
        y='-31.2%'
        filterUnits='objectBoundingBox'
      >
        <feComposite
          in='shadowBlurOuter1'
          in2='SourceAlpha'
          operator='out'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          in='shadowBlurOuter1'
          values='0 0 0 0 0.321568627 0 0 0 0 0.37254902 0 0 0 0 0.498039216 0 0 0 0.150568182 0'
        />
      </filter>
      <circle id='IconErrorAlertB' cx='8' cy='8' r='8' />
    </defs>
    <use
      fill='#000'
      filter='url(#IconErrorAlertA)'
      xlinkHref='#IconErrorAlertB'
    />
    <circle
      cx='8'
      cy='8'
      r='7.25'
      fill={fill}
      stroke='#FFF'
      strokeLinejoin='inherit'
      strokeWidth='1.5'
    />
    <path
      fill='#FFF'
      fillRule='nonzero'
      d='M8.448 9.6l.182-4.8H7.334l.173 4.8h.941zm-.48 2.131c.432 0 .778-.326.778-.739 0-.422-.346-.749-.778-.749-.422 0-.768.327-.768.749 0 .413.346.74.768.74z'
    />
  </svg>
);

export default IconErrorAlert;
