// Vendor
import React, { FC, Fragment } from 'react';

interface Props {
  pointUp?: boolean;
  fillValue?: string;
}

interface PointProps {
  fillValue?: string;
}

const PointDown: FC<PointProps> = ({ fillValue = '#525f7f' }) => (
  <>
    <mask id='IconChevronC' fill='#fff'>
      <use xlinkHref='#IconChevronA' />
    </mask>
    <use
      fill={fillValue}
      opacity='.8'
      transform='scale(1 -1) rotate(-45 -12.243 0)'
      xlinkHref='#IconChevronA'
    />
  </>
);

const PointUp: FC<PointProps> = ({ fillValue = '#525f7f' }) => (
  <>
    <mask id='IconChevronD' fill='#fff'>
      <use xlinkHref='#IconChevronB' />
    </mask>
    <use
      fill={fillValue}
      opacity='.8'
      transform='rotate(-45 12.071 15.071)'
      xlinkHref='#IconChevronB'
    />
  </>
);

const IconChevron: FC<Props> = ({
  pointUp = false,
  fillValue = '#525f7f',
  ...rest
}) => (
  <svg width='10' height='10' viewBox='3 3 16 16' {...rest}>
    <defs>
      <path
        id='IconChevronA'
        d='M17.071 6.321v8.125a.625.625 0 1 1-1.25 0V6.321H7.696a.625.625 0 1 1 0-1.25h8.743a.632.632 0 0 1 .632.632v.618z'
      />
      <path
        id='IconChevronB'
        d='M17.071 11.321v8.125a.625.625 0 1 1-1.25 0v-8.125H7.696a.625.625 0 1 1 0-1.25h8.743a.632.632 0 0 1 .632.632v.618z'
      />
    </defs>
    <g fill={fillValue} fillRule='evenodd'>
      {pointUp ? (
        <PointUp fillValue={fillValue} />
      ) : (
        <PointDown fillValue={fillValue} />
      )}
    </g>
  </svg>
);

export default IconChevron;
