// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';
import { Field } from 'react-final-form';

// Internal
import { Radio } from 'components';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface RadioColumnInterface {
  checkedFn: (data: any) => boolean;
  disabledFn: (data: any) => boolean;
  handleOnChangeFn: (data: any) => void;
  headerName?: string;
  idFn: (data: any) => string;
  nameFn: (data: any) => string;
  size?: Size;
  type: 'radio';
}

export interface Props {
  checkedFn: (data: any) => boolean;
  data: any;
  disabledFn: (data: any) => boolean;
  handleOnChangeFn: (data: any) => void;
  idFn: (data: any) => string;
  nameFn: (data: any) => string;
  size?: Size;
}

const RadioCell: FC<Props> = (props) => {
  const {
    checkedFn,
    data,
    disabledFn,
    handleOnChangeFn,
    idFn,
    nameFn,
    size = 'small',
  } = props;

  const id = idFn(data);
  const name = nameFn(data);
  const handleOnClick = () => handleOnChangeFn(data);
  const isChecked = checkedFn(data);
  const isDisabled = disabledFn(data);

  return (
    <TableCell size={size} className={cx('cell')}>
      <Field
        checked={isChecked}
        disabled={isDisabled}
        // @ts-ignore
        component={Radio}
        componentValue={id}
        handleOnClick={handleOnClick}
        name={name}
        type='radio'
      />
    </TableCell>
  );
};

export default RadioCell;
