// Vendor
import React, { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { uniq } from 'lodash';

// Internal
import {
  useAppBarSetTitle,
  useDEAValidationOnly,
  useIsAfterBushNetwork,
  useIsFirstRender,
} from 'hooks';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import {
  CurrentPageParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import {
  QUERY_CUSTOMER_VIEW,
  QUERY_CUSTOMER_VIEW_TOTAL_COUNT,
  QUERY_MULTIPLE_COT,
  QueryClassOfTradeResult,
  QueryCustomerView,
  QueryCustomerViewCountResult,
  wrapSearchText,
} from 'query';
import { AuthorityIDMapping, isDEA, isHIBCC, isHRSA } from 'common/helpers';
import { NUMBER_PER_PAGE } from 'common/constants';
import { IconSystemError } from 'styles/images';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { Pagination, Table } from 'components';
import { TableRow } from 'components/Table';
import SearchBar from 'components/SearchBar';
import { getColumns, getIdHeaderText, getRows } from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

interface Props {
  memberId: AuthorityIDMapping;
}

const Container: FC<Props> = (props) => {
  const { memberId } = props;
  const memberName = AuthorityIDMapping[memberId];
  const { isAfterBush, networkLoading } = useIsAfterBushNetwork();
  const isSimpleDEA = useDEAValidationOnly() && isDEA(memberId);
  const hasRelatedHIN = isHIBCC(memberId) && isAfterBush;
  const hasContractPharmacies = isHRSA(memberId) && isAfterBush;
  const hasCustomerDetail = !isDEA(memberId);

  const { currentSearch } = usePersistedSearchParams(
    { module: memberName, params: [SearchParamConfig, CurrentPageParamConfig] },
    SessionStorage
  );
  const [searchText, updateSearchText] = currentSearch[SearchParamConfig.key];

  // Pagination
  const [currentPage, updateCurrentPage] =
    currentSearch[CurrentPageParamConfig.key];

  const firstRender = useIsFirstRender();
  useEffect(() => {
    if (firstRender && currentPage === 1) {
      updateCurrentPage(1);
    }
  }, [memberId]);
  useAppBarSetTitle(memberName.toUpperCase());

  const [rows, updateRows] = useState([] as TableRow[]);
  const columns = getColumns(
    hasContractPharmacies,
    hasCustomerDetail,
    hasRelatedHIN,
    isSimpleDEA,
    memberId
  );
  const paginationFilters = {
    limit: NUMBER_PER_PAGE,
    offset: (currentPage - 1) * NUMBER_PER_PAGE,
  };
  const baseFilters = { memberId, searchText: wrapSearchText(searchText) };

  const { data, error, loading, refetch } = useQuery<QueryCustomerView>(
    QUERY_CUSTOMER_VIEW,
    {
      variables: {
        ...baseFilters,
        ...paginationFilters,
        simpleDEA: isSimpleDEA,
        skipRelationships: isSimpleDEA,
      },
    }
  );

  const { data: countData, loading: countLoading } =
    useQuery<QueryCustomerViewCountResult>(QUERY_CUSTOMER_VIEW_TOTAL_COUNT, {
      variables: baseFilters,
    });

  const customers = data?.customers || [];

  const onSearchCallback = useCallback((searchText: string) => {
    updateSearchText(searchText);
    updateCurrentPage(1);
  }, []);

  const hasCustomers = customers.length > 0;
  const identifierPlaceholder = getIdHeaderText(memberId);
  const placeholder = `Search by ${identifierPlaceholder}${
    isSimpleDEA ? '' : ', name or address'
  }`;
  const isFiltered = searchText !== '';
  const disableSearchFilter = !loading && !hasCustomers && !isFiltered;

  const cotIds = uniq(
    customers.flatMap((c) => c.classesOfTrade?.map((cot) => cot.classOfTradeId))
  );
  const { data: cotData, loading: cotLoading } =
    useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
      variables: {
        ids: cotIds,
        memberId,
      },
      skip: isSimpleDEA || cotIds.length === 0 || memberId === undefined,
    });

  // FORMAT RAW DATA
  useEffect(() => {
    if (!loading && !cotLoading) {
      updateRows(getRows(customers, cotData));
    }
  }, [customers, cotData, loading, cotLoading]);

  return error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('root')} data-testid='identifierDataCustomers'>
      <section className={cx('toolbar')}>
        <SearchBar
          onSubmit={(text) => {
            onSearchCallback(text);
          }}
          placeholder={placeholder}
          disabled={disableSearchFilter}
          text={searchText || ''}
        />
      </section>
      <Table
        columns={columns}
        isLoading={loading || networkLoading || cotLoading}
        emptyText='No customers found'
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        isDisplayed={hasCustomers}
        loading={countLoading}
        numberPerPage={NUMBER_PER_PAGE}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
          refetch({ offset: (currentPage - 1) * NUMBER_PER_PAGE }).then();
        }}
        totalCount={countData?.customerCount?.aggregate.count ?? 0}
      />
    </div>
  );
};

export default Container;
