// CONSTANTS
export const HIDDEN = 'hidden';
export const VISIBLE = 'visible';
export type VISIBILITY = typeof HIDDEN | typeof VISIBLE;

export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export type COLOR = typeof PRIMARY | typeof SECONDARY;

export const CONTAINED = 'contained';
export const OUTLINED = 'outlined';
export const TEXT = 'text';
export type VARIANT = typeof CONTAINED | typeof OUTLINED | typeof TEXT;

export const BUTTON = 'button';
export const RESET = 'reset';
export const SUBMIT = 'submit';
export type TYPE = typeof BUTTON | typeof RESET | typeof SUBMIT;

export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export type SIZE = typeof XSMALL | typeof SMALL | typeof MEDIUM | typeof LARGE;

export enum FontSize {
  SMALL = '10px',
  MEDIUM = '10px',
  LARGE = '12px',
}

export type IconStateVariant = 'default' | 'active' | 'hover' | 'disabled';
