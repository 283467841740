// Vendor
import React, { FC } from 'react';
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

// Internal
import { useAuthService } from 'hooks';
import { App, Loading, Login, Logout } from './pages';

const RedirectHome = <Navigate to='/' />;
const RedirectLogin = <Navigate to='login/' />;

const Root: FC = () => {
  const auth = useAuthService();

  if (!auth?.isReady()) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        <Route path='logout/' element={<Logout />} />
        <Route
          path='login/'
          element={auth?.isAuthenticated() ? RedirectHome : <Login />}
        />
        <Route
          path='/*'
          element={auth?.isAuthenticated() ? <App /> : RedirectLogin}
        />
      </Routes>
    </Router>
  );
};

export default Root;
