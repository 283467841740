// Vendor
import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';

// Internal
import { Tooltip } from 'components/Tooltips';

export interface ExpandingColumnInterface {
  type: 'expanding';
  headerName?: string;
  size?: Size;
  dataField: string;
  style?: React.CSSProperties;
}

export interface Props {
  data: any;
  handleRowClick?: (data: any) => void;
  size?: Size;
  isExpanded: boolean;
  style?: React.CSSProperties;
}

const Expanding: FC<Props> = (props) => {
  const {
    data,
    handleRowClick,
    isExpanded,
    size = 'small',
    style = {},
  } = props;

  const isDataAnArray = Array.isArray(data);
  const shortData = isDataAnArray ? data[0] : data;

  const renderData = () => {
    if (isExpanded && isDataAnArray) {
      return data.map((v: any, j: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tooltip title={v} key={`${v}-${j}`} style={style}>
          {v}
        </Tooltip>
      ));
    } else {
      const text = isExpanded ? data : shortData;
      return (
        <Tooltip title={text} style={style}>
          {text}
        </Tooltip>
      );
    }
  };

  return (
    <TableCell onClick={handleRowClick} size={size}>
      {renderData()}
    </TableCell>
  );
};

export default Expanding;
