// Internal
import { QueryCustomersListResult } from 'query';
import { TableColumn, TableRow } from 'components/Table';
import { formatDate, truncateDatetime } from 'common/helpers';
import {
  DESCRIPTION_COLUMN,
  END_DATE_COLUMN,
  NAME_COLUMN,
  START_DATE_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { ContractFormProps } from '../../constants';

export const filtersToQuery = (
  endDate: string,
  startDate: string,
  searchText: string
): any => ({
  type: { _eq: 'contract' },
  _and: [
    {
      _or: [
        {
          _and: {
            end_date: { _gte: startDate },
            start_date: { _lte: startDate },
          },
        },
        {
          _and: {
            end_date: { _gte: endDate },
            start_date: { _lte: endDate },
          },
        },
        {
          _and: {
            end_date: { _lte: endDate },
            start_date: { _gte: startDate },
          },
        },
      ],
    },
    {
      _or: [
        { name: { _ilike: searchText } },
        { id: { _ilike: searchText } },
        { description: { _ilike: searchText } },
      ],
    },
  ],
});

export const rawDataToRows = (
  customersLists: QueryCustomersListResult,
  values: ContractFormProps
): TableRow[] => {
  if (!customersLists || !customersLists.lists) {
    return [];
  }

  return customersLists.lists.map((list) => ({
    data: {
      names: list.name,
      type: list.type,
      description: list.description,
      id: list.id,
      startDate: truncateDatetime(list.start_date),
      endDate: truncateDatetime(list.end_date),
      revisedAt: truncateDatetime(list.timestamp),
      timestamp: formatDate(list.timestamp),
      checked: list.id === values.customerList,
      name: 'customerList',
    },
    type: 'expanding',
  }));
};

export const getColumns = (
  change: (key: string, value: string) => void,
  customerList: string
): TableColumn[] => [
  {
    checkedFn: (data: any) => data.checked || false,
    disabledFn: () => false,
    handleOnChangeFn: (data: any) => {
      // ALLOWS FOR RADIO TO BE DESELECTED
      if (customerList === data.id) {
        change('customerList', '');
      } else {
        change('customerList', data.id);
      }
    },
    headerName: '',
    idFn: (data: any) => data.id,
    nameFn: (data: any) => data.name,
    size: 'small',
    type: 'radio',
  },
  {
    dataField: 'id',
    headerName: 'CUSTOMER LIST ID',
    type: 'expanding',
    size: 'small',
  },
  NAME_COLUMN,
  DESCRIPTION_COLUMN,
  START_DATE_COLUMN,
  END_DATE_COLUMN,
  UPDATED_AT_COLUMN,
];
