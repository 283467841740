// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Dictionary } from 'lodash';

// Internal
import { Breadcrumb } from 'components';
import {
  GetLatestProductByIdAndContractIdResult,
  ChargebackRequestRow,
  TradingPartner,
  CreditMemoRow,
  CustomerRow,
} from 'query';
import { RevisionLookup } from 'types/generated/RevisionLookup';
import { AuditedData } from 'types/generated/AuditedData';
import { parseMediledgerId } from 'common/helpers';
import { NodeType } from 'common/config';
import Chargeback from './Chargeback';
import Details from './Details';
import Audit from './Audit';
import { ModalKind } from '../constants';

// Styles
import styles from './invoice.module.css';
const cx = classnames.bind(styles);

export interface Props {
  mainChargeback: ChargebackRequestRow; // Main chargeback is the last approved Original, or the first Original (when all are rejected)
  chargebacks: ChargebackRequestRow[]; // All chargebacks that are on the same thread
  tradingPartners: Dictionary<TradingPartner>;
  tpType: NodeType;
  productData?: GetLatestProductByIdAndContractIdResult;
  associatedCreditNotes?: CreditMemoRow[];
  showModal: React.Dispatch<React.SetStateAction<ModalKind>>;
  showAuditPreview: boolean;
  setShowAuditPreview: React.Dispatch<React.SetStateAction<boolean>>;
  setRequest: React.Dispatch<React.SetStateAction<RevisionLookup | undefined>>;
  setAuditedData: React.Dispatch<React.SetStateAction<AuditedData | undefined>>;
  setShowCustomerModal: React.Dispatch<
    React.SetStateAction<CustomerRow | undefined>
  >;
}

const Invoice: FC<Props> = ({
  mainChargeback,
  chargebacks,
  tradingPartners,
  tpType,
  productData,
  associatedCreditNotes,
  showModal,
  showAuditPreview,
  setShowAuditPreview,
  setRequest,
  setAuditedData,
  setShowCustomerModal,
}) => {
  const chargeback = mainChargeback;
  const isMFR = tpType === NodeType.MFR;
  const mfrId = parseMediledgerId(chargeback.manufacturer_id).memberId;

  let customerId;
  let customer;
  let partnerId;
  let partnerCustomerId;
  let partnerCustomer;

  if (isMFR) {
    customerId = chargeback.manufacturer_customer_id;
    customer = chargeback.manufacturer_customer;

    partnerId = chargeback.sale.member_id;
    partnerCustomerId = chargeback.distributor_customer_id;
    partnerCustomer = chargeback.distributor_customer;
  } else {
    customerId = chargeback.distributor_customer_id;
    customer = chargeback.distributor_customer;

    partnerId = mfrId;
    partnerCustomerId = chargeback.manufacturer_customer_id;
    partnerCustomer = chargeback.manufacturer_customer;
  }

  return (
    <div className={cx('column')}>
      <Breadcrumb
        crumbs={['Chargebacks', `Invoice # ${chargeback.sale.id}`]}
        links={['/chargebacks']}
      />
      <Details
        customerId={customerId}
        customer={customer}
        sale={chargeback.sale}
        tradingPartners={tradingPartners}
        productDescription={
          productData?.dash_contract_pricings[0]?.description ?? ''
        }
        isMFR={isMFR}
        partnerId={partnerId}
        partnerCustomerId={partnerCustomerId}
        partnerCustomer={partnerCustomer}
        setShowCustomerModal={setShowCustomerModal}
      />
      {showAuditPreview && (
        <Audit
          chargeback={chargeback}
          status='Pending'
          isEditing
          handleSubmit={(data) => {
            setRequest({
              id: chargeback.id,
              memberId: `ml:member:${chargeback.member_id}`,
              timestamp: chargeback.timestamp,
            });
            setAuditedData(data);
            showModal('audit-request');
          }}
          handleCancel={() => setShowAuditPreview(false)}
        />
      )}
      {chargebacks.map((chargeback, i) => {
        const marginBottom = i !== chargebacks.length - 1;
        const associatedCreditNote = associatedCreditNotes?.find((credit) =>
          credit.claim_lines.some(
            (line) =>
              line.chargeback_id === chargeback.id &&
              line.chargeback_timestamp === chargeback.timestamp
          )
        );
        return (
          <div
            key={`${chargeback.id}-${chargeback.timestamp}`}
            className={cx({ marginBottom })}
          >
            <Chargeback
              associatedCreditNote={associatedCreditNote}
              isLatestInThread={i === 0}
              chargeback={chargeback}
              tradingPartners={tradingPartners}
              tpType={tpType}
              showModal={showModal}
              setShowAuditPreview={setShowAuditPreview}
              setRequest={setRequest}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Invoice;
