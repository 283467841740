// Vendor
import React, { FC, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';

// Internal
import { Select } from 'components';
import { Tooltip } from 'components/Tooltips';
import { IconChevron } from 'styles/images';
import {
  ContractFormProps,
  MultiplePriceProduct,
  NUMBER_OF_PRICINGS_PER_PAGE,
} from 'pages/Contracts/Create/constants';
import SingleProductPricesRows from './SingleProductPricesRows';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface ProductRowProps {
  formRemove: (...args: any[]) => any;
  formUpdate: (...args: any[]) => any;
  productIndex: number;
  productRow: MultiplePriceProduct;
  values: ContractFormProps;
  setOpenedProductId: (productId: string) => void;
  currentPage: number;
}

// TODO: fix warning `validateDOMNesting(...): <td> cannot appear as a child of <tbody>.`
const ProductRow: FC<ProductRowProps> = (props) => {
  const {
    formRemove,
    formUpdate,
    productIndex,
    productRow,
    values,
    setOpenedProductId,
    currentPage,
  } = props;
  const [open, setOpen] = useState(true);

  const {
    description,
    endDate,
    externalId,
    idType,
    priceTypes,
    startDate,
    unitOfMeasure,
    updatedAt,
  } = productRow.data;

  let priceTypeIndex: number;
  const priceTypeValue = values.priceTypes.find((priceType, index) => {
    priceTypeIndex = index + (currentPage - 1) * NUMBER_OF_PRICINGS_PER_PAGE;
    return priceType.externalId === externalId;
  }) as {
    externalId: string;
    priceType: string;
  };

  const rows = [
    idType,
    description,
    unitOfMeasure,
    <Field
      // @ts-ignore
      component={Select}
      label={priceTypeValue.priceType}
      name={`${priceTypeValue}.priceType`}
    >
      {priceTypes.map((priceType) => (
        <MenuItem
          key={priceType}
          onClick={() =>
            formUpdate('priceTypes', priceTypeIndex, {
              externalId,
              priceType,
            })
          }
          value={priceType}
        >
          {priceType}
        </MenuItem>
      ))}
    </Field>,
    startDate,
    endDate,
    updatedAt,
  ];

  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          <Tooltip title={externalId}>
            <Link
              onClick={(e) => {
                e.preventDefault();
                setOpenedProductId(externalId);
              }}
              href='#'
            >
              {externalId}
            </Link>
          </Tooltip>
        </TableCell>
        {rows.map((row) => (
          <TableCell key={row?.toString()}>
            <Tooltip title={row}>{row}</Tooltip>
          </TableCell>
        ))}
        <TableCell>
          <IconButton
            data-testid='expand-list-row-btn'
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            <IconChevron pointUp={open} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableCell style={{ padding: 0 }} colSpan={12}>
        <Collapse in={open}>
          <Box className={cx('box')}>
            <Table size='small'>
              <TableBody
                className={cx(productIndex % 2 === 0 ? 'gray-background' : '')}
              >
                <SingleProductPricesRows
                  formRemove={formRemove}
                  formUpdate={formUpdate}
                  productIndex={productIndex}
                  productRow={productRow}
                  values={values}
                />
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </>
  );
};

export default ProductRow;
