// Vendor
import React, { FC, ReactElement } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Tooltip } from 'components/Tooltips';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  title: string;
  titleIcon?: ReactElement;
}

const Key: FC<Props> = (props) => {
  const { title, titleIcon } = props;

  return (
    <div className={cx('row')}>
      <Tooltip title={title}>
        <div data-testid='key' className={cx('ellipsis', 'key')}>
          {title}
        </div>
      </Tooltip>
      <div className={cx('titleIcon')}>{titleIcon}</div>
    </div>
  );
};

export default Key;
