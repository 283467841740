// Vendor
import React from 'react';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { FormApi } from 'final-form';

// Internal
import { formatCurrency, truncateDatetime } from 'common/helpers';
import { ExpandingRowInterface } from 'components/Rows/ExpandingRow';
import { TableColumn, TableRow } from 'components/Table';
import IconPlus from 'styles/images/IconPlus';
import { IconTrash } from 'styles/images';
import { StringifiedProductPriceWithID } from 'common/types';
import { maxPriceLengthValidator, required } from 'common/validation';
import { ProductDataType } from '../../service';

export const generateProductPricingRow = (
  data: StringifiedProductPriceWithID
): TableRow => ({
  type: 'expanding',
  isExpandable: false,
  data,
});

/**
 * @description takes a few factors into account to return correct price value for edit-table or view-table
 * if pricing is an empty string, than we need to return empty string because we want the value of price field to be empty
 * @param price pricings price in string format
 * @param convertPriceToCurrency boolean, that defines if we need to add '$' sign and divide price value by 1_000_000 or not
 * @returns re-formatted product price
 */
export const formatProductPricingPrice = (
  price: string,
  convertPriceToCurrency: boolean
): string => {
  const priceIsEmpty = !price.length;
  if (priceIsEmpty) {
    return '';
  } else {
    const priceAsNumber: number = Number.parseFloat(price);

    return convertPriceToCurrency
      ? formatCurrency(priceAsNumber).toString()
      : (priceAsNumber / 1_000_000).toString();
  }
};

export const pricingsToRows = (
  pricings: StringifiedProductPriceWithID[]
): TableRow[] => pricings.map((pricing) => generateProductPricingRow(pricing));

/**
 *
 * @param rows array of rows of type ExpandingRowInterface
 * @returns array, containing fields data of all rows
 */
export const getRowsFieldsData = (
  rows: ExpandingRowInterface[]
): Record<string, any>[] => rows.map((row) => row.data);

export const getColumns = (
  formValues: Record<string, any>,
  priceTypes: ProductDataType[],
  form: FormApi
): TableColumn[] => {
  const { endDate, pricings, startDate } = formValues;
  const { update, push, remove } = form.mutators;
  const { change } = form;

  return [
    {
      headerName: 'PRICE TYPE',
      type: 'selectInput',
      name: (index) => getPricingFieldName(index, 'priceType'),
      items: priceTypes.map(({ name: priceType }) => ({
        label: priceType,
      })),
      specificFormData: pricings,
      validation: [required],
      size: 'medium',
      handleOnChangeFn: (name, itemLabel) => {
        // here we need to manually change value of select input on chnage,
        // because react-final-form itself does not detect the change
        change(name, itemLabel);
      },
      placeholder: 'Select Price Type *',
    },
    {
      headerName: 'PRICE',
      name: (index) => getPricingFieldName(index, 'price'),
      placeholder: 'Set Price *',
      size: 'medium',
      type: 'priceInput',
      validation: [required, maxPriceLengthValidator],
    },
    {
      fallbackDate: startDate,
      format: 'short',
      headerName: 'START DATE',
      maxDate: dayjs(truncateDatetime(endDate)).toDate(),
      minDate: dayjs(truncateDatetime(startDate)).toDate(),
      name: getStartDateName,
      placeholder: 'Select Start Date *',
      size: 'small',
      type: 'dateInput',
      validation: [required],
    },
    {
      fallbackDate: endDate,
      format: 'short',
      headerName: 'END DATE',
      maxDate: dayjs(truncateDatetime(endDate)).toDate(),
      minDate: dayjs(truncateDatetime(startDate)).toDate(),
      name: getEndDateName,
      placeholder: 'Select End Date *',
      size: 'small',
      type: 'dateInput',
      validation: [required],
    },
    {
      type: 'multipleButtons',
      headerName: 'ACTIONS',
      size: 'small',
      buttonsData: [
        {
          title: <IconTrash />,
          onClickHandler: (rowIndex: number) => {
            if (pricings.length === 1) {
              const emptyPricing: StringifiedProductPriceWithID = {
                id: v4(),
                priceType: '',
                price: '',
                startDate: '',
                endDate: '',
              };

              // clear the current pricing values
              update('pricings', 0, emptyPricing);
            } else {
              // remove target pricing
              remove('pricings', rowIndex);
            }
          },
        },
        {
          title: <IconPlus />,
          displayedInRows: [pricings.length - 1],
          onClickHandler: (rowIndex, data) => {
            const { priceType } = data;

            const newPricing: StringifiedProductPriceWithID = {
              id: v4(),
              priceType,
              price: '',
              startDate: '',
              endDate: '',
            };

            push('pricings', newPricing);
          },
        },
      ],
    },
  ];
};

/**
 * Generates names for final-form
 * @example getPricingFieldName(3, 'startDate') generates 'pricings.3.startDate'
 * */
export const getPricingFieldName = (index: number, field: string) =>
  `pricings.${index}.${field}`;

// shortcuts to get startDate and endDate
export const getStartDateName = (index: number) =>
  getPricingFieldName(index, 'startDate');
export const getEndDateName = (index: number) =>
  getPricingFieldName(index, 'endDate');
