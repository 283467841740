// common
export const APP_BAR_TITLE_RESET = 'APP_BAR_TITLE_RESET';
// eslint-disable-next-line no-redeclare
export type APP_BAR_TITLE_RESET = typeof APP_BAR_TITLE_RESET;

export const APP_BAR_TITLE_SET = 'APP_BAR_TITLE_SET';
// eslint-disable-next-line no-redeclare
export type APP_BAR_TITLE_SET = typeof APP_BAR_TITLE_SET;

export const SNACKBAR_HIDE = 'SNACKBAR_HIDE';
// eslint-disable-next-line no-redeclare
export type SNACKBAR_HIDE = typeof SNACKBAR_HIDE;

export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
// eslint-disable-next-line no-redeclare
export type SNACKBAR_SHOW = typeof SNACKBAR_SHOW;

export interface CommonAction {
  type: APP_BAR_TITLE_RESET | APP_BAR_TITLE_SET | SNACKBAR_HIDE | SNACKBAR_SHOW;
  payload?: Object;
}

/** Service */
export const SERVICE_UPDATE = 'SERVICE_UPDATE';
// eslint-disable-next-line no-redeclare
export type SERVICE_UPDATE = typeof SERVICE_UPDATE;

export const SIGNED_IN = 'SIGNED_IN';
// eslint-disable-next-line no-redeclare
export type SIGNED_IN = typeof SIGNED_IN;
export const SIGNED_OUT = 'SIGNED_OUT';
// eslint-disable-next-line no-redeclare
export type SIGNED_OUT = typeof SIGNED_OUT;

export interface ServiceAction {
  type: SERVICE_UPDATE;
  payload: [string[], any];
}

export interface SignedIn {
  type: SIGNED_IN;
  payload: { token: string };
}

export interface SignedOut {
  type: SIGNED_OUT;
}

export type AuthAction = SignedIn | SignedOut;

export type CoronaAction = CommonAction | ServiceAction | AuthAction;
