// Vendor
import React from 'react';
import dayjs from 'dayjs';
import { Field } from 'react-final-form';
import { pipe } from 'fp-ts/function';
import { findFirst } from 'fp-ts/Array';
import { getOrElse, map, Option } from 'fp-ts/Option';

// Internal
import { TableColumn, TableRow } from 'components/Table';
import {
  decorateAndPartitionCustomerErrorsBySeverity,
  displayAddress,
  formatIdentifiers,
  truncateDatetime,
} from 'common/helpers';
import {
  ADDRESS_COLUMN,
  COT_COLUMN,
  END_DATE_COLUMN,
  ERRORS_COLUMN,
  IDENTIFIERS_COLUMN,
  INTERNAL_ID_COLUMN,
  NAME_COLUMN,
  START_DATE_COLUMN,
} from 'components/Cells/constants';
import { CustomerDetailsList, CustomerViewRow, wrapSearchText } from 'query';
import { DateRange } from 'common/types';
import { Checkbox } from 'components';
import { SimpleCustomer } from '../../EditCustomerList';
import { FormValues } from '../../service';

export const getCustomersTableColumns = (values: FormValues): TableColumn[] => {
  const { endDate, startDate } = values;

  return [
    {
      dataField: 'checkbox',
      headerName: '',
      size: 'small',
      type: 'expanding',
    },
    INTERNAL_ID_COLUMN,
    NAME_COLUMN,
    IDENTIFIERS_COLUMN,
    ADDRESS_COLUMN,
    COT_COLUMN,
    START_DATE_COLUMN,
    END_DATE_COLUMN,
    {
      fallbackDate: startDate,
      format: 'short',
      headerName: 'MEMBERSHIP START DATE',
      hiddenFn: (data: any) => !data.checked,
      maxDate: dayjs(truncateDatetime(endDate)).toDate(),
      minDate: dayjs(truncateDatetime(startDate)).toDate(),
      nameFn: (data: any) => `customers.${data.index}.membershipStartDate`,
      placeholder: 'Select Start Date *',
      size: 'small',
      type: 'dateInput',
      validation: [],
    },
    {
      fallbackDate: endDate,
      format: 'short',
      headerName: 'MEMBERSHIP END DATE',
      hiddenFn: (data: any) => !data.checked,
      maxDate: dayjs(truncateDatetime(endDate)).toDate(),
      minDate: dayjs(truncateDatetime(startDate)).toDate(),
      nameFn: (data: any) => `customers.${data.index}.membershipEndDate`,
      placeholder: 'Select End Date *',
      size: 'small',
      type: 'dateInput',
      validation: [],
    },
    ERRORS_COLUMN,
  ];
};

export const filtersToQueryCustomersTable = (
  searchText: string | undefined = undefined
) => {
  // Default where clause
  const whereObject: any = {};

  if (searchText !== undefined && searchText !== null) {
    whereObject._search_text = { _ilike: wrapSearchText(searchText) };
  }

  return whereObject;
};

const getLatestDate = (d1: string, d2: string): string =>
  dayjs(d1).isAfter(dayjs(d2)) ? d1 : d2;

const getEarlierDate = (d1: string, d2: string): string =>
  dayjs(d1).isAfter(dayjs(d2)) ? d2 : d1;

const getValidDateIfThereIsOne = (d1: string, d2: string): string =>
  dayjs(d1).isValid() ? d1 : d2;

/*
  We calculate the earlier endDate and the latest endDate
  for the range date values.
 */
const calculateDateRange = (dr1: DateRange, dr2: DateRange): DateRange => {
  const startDateValidComp =
    dayjs(dr1.startDate).isValid() && dayjs(dr2.startDate).isValid();

  const endDateValidComp =
    dayjs(dr1.endDate).isValid() && dayjs(dr2.endDate).isValid();

  const startDate = startDateValidComp
    ? getLatestDate(dr1.startDate, dr2.startDate)
    : getValidDateIfThereIsOne(dr1.startDate, dr2.startDate);

  const endDate = endDateValidComp
    ? getEarlierDate(dr1.endDate, dr2.endDate)
    : getValidDateIfThereIsOne(dr1.endDate, dr2.endDate);

  return {
    startDate,
    endDate,
  };
};

export const getInitialDateRange = (
  currentMembershipDateRange: DateRange,
  customerDateRange: DateRange,
  customerListDateRange: DateRange
): DateRange => {
  const startDateByDefault = dayjs(currentMembershipDateRange.startDate);
  const endDateByDefault = dayjs(currentMembershipDateRange.endDate);
  // Whenever we have a previous membership range, we keep it
  if (startDateByDefault.isValid() && endDateByDefault.isValid()) {
    return currentMembershipDateRange;
  }
  // Otherwise we get the date range according to C13.a requirement
  return calculateDateRange(customerDateRange, customerListDateRange);
};

export const getCustomerMembershipDateForList = (
  customer: CustomerViewRow,
  listId: string
): Option<DateRange> =>
  pipe(
    customer.memberships,
    findFirst(
      (membership: CustomerDetailsList) => membership.listId === listId
    ),
    map((membership: CustomerDetailsList) => ({
      startDate: membership.startDate ?? '',
      endDate: membership.endDate ?? '',
    }))
  );

const getCustomerDefaultDateRange = (
  customer: CustomerViewRow,
  listId: string,
  listDateRange: DateRange
): DateRange => {
  const { sDate, eDate } = pipe(
    getCustomerMembershipDateForList(customer, listId),
    map(({ startDate: sDate, endDate: eDate }) => ({
      sDate: truncateDatetime(sDate) ?? '',
      eDate: truncateDatetime(eDate) ?? '',
    })),
    getOrElse(() => {
      const currentMembershipDateRange = pipe(
        getCustomerMembershipDateForList(customer, listId),
        map(({ startDate: sDate, endDate: eDate }) => ({ sDate, eDate })),
        getOrElse(() => ({ sDate: '', eDate: '' }))
      );
      const targetDateRange = getInitialDateRange(
        {
          startDate: currentMembershipDateRange.sDate,
          endDate: currentMembershipDateRange.eDate,
        },
        {
          startDate: customer.startDate ?? '',
          endDate: customer.endDate ?? '',
        },
        listDateRange
      );
      // In case we are not updating but creating a new one, we auto-populate
      return {
        sDate: truncateDatetime(targetDateRange.startDate) ?? '',
        eDate: truncateDatetime(targetDateRange.endDate) ?? '',
      };
    })
  );

  return {
    startDate: sDate,
    endDate: eDate,
  };
};

export const rawDataToRowsCustomersTable = (
  customers: CustomerViewRow[],
  formUpdate: (...args: any[]) => any,
  listId: string,
  values: FormValues
): TableRow[] => {
  const { endDate: listEndDate, startDate: listStartDate } = values;

  return customers.map((customer) => {
    const customerInValues = values.customers.find(
      (c: SimpleCustomer) => c.id === customer.id
    );
    const customerInValuesIndex = values.customers.findIndex(
      (c: SimpleCustomer) => c.id === customer.id
    );
    const index =
      customerInValuesIndex !== -1
        ? customerInValuesIndex
        : values.customers.length;
    const isChecked = customerInValues?.checked || false;

    const identifiers = customer.identifiers
      ? formatIdentifiers(customer.identifiers)
      : [];
    const addresses =
      customer.addresses?.map((address) => displayAddress(address)) ?? [];
    const names = customer.names ?? [];

    const cots = customer.classesOfTrade
      ? Object.values(customer.classesOfTrade).map(
          ({ classOfTradeId }) => classOfTradeId
        )
      : [];
    const { severityErrors, severityWarnings } =
      decorateAndPartitionCustomerErrorsBySeverity(customer);

    const { startDate, endDate } = getCustomerDefaultDateRange(
      customer,
      listId,
      { startDate: listStartDate, endDate: listEndDate }
    );

    // The query that fetches customers already filters memberships by list id and member id, so we just take the first one
    const membership =
      customer.memberships.length > 0 ? customer.memberships[0] : undefined;

    const membershipStartDate = membership ? membership.startDate : startDate;

    const membershipEndDate = membership ? membership.endDate : endDate;

    const isExpandable =
      names.length > 1 ||
      identifiers?.length > 1 ||
      addresses.length > 1 ||
      cots?.length > 1;

    return {
      type: 'expanding',
      isExpandable,
      data: {
        checkbox: (
          <Field
            checked={isChecked}
            // @ts-ignore
            component={Checkbox}
            handleOnChange={() => {
              if (!isChecked) {
                formUpdate('customers', index, {
                  checked: true,
                  id: customer.id,
                  membershipStartDate,
                  membershipEndDate,
                });
              } else {
                formUpdate('customers', index, {
                  checked: false,
                  id: customer.id,
                });
              }
            }}
            name={`customers.${index}.checked}`}
            type='checkbox'
          />
        ),
        id: customer.id,
        names,
        identifiers,
        addresses,
        cots,
        checked: isChecked,
        membershipStartDate,
        membershipEndDate,
        startDate: customer.startDate,
        endDate: customer.endDate,
        severityErrors,
        severityWarnings,
        index,
      },
    };
  });
};
