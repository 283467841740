// Vendor
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import { NUMBER_PER_PAGE } from 'common/constants';
import { TableRow } from 'components/Table';
import {
  filtersToQueryOnCreateEdit,
  getColumnsForEditPage,
  productsToRowsForEditPage,
} from 'services/ProductListMgmt/service';
import { Pagination, SearchBar, Table } from 'components';
import {
  Product,
  QUERY_PRODUCTS,
  QUERY_PRODUCTS_COUNT,
  QueryProductCountResult,
  QueryProductsResult,
} from 'query';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  currentListProducts: Product[];
  onListProductsChange: (updatedList: Product[]) => void;
  setFetchingProducts: Dispatch<SetStateAction<boolean>>;
}

const CreateEditProductsTable: FC<Props> = (props) => {
  const { currentListProducts, onListProductsChange, setFetchingProducts } =
    props;
  // Table rows
  const [rows, updateRows] = useState([] as TableRow[]);
  // Search
  const [searchText, updateSearchText] = useState('');
  // Pagination
  const [currentPage, updateCurrentPage] = useState(1);
  // Where condition
  const [where, updateWhere] = useState(filtersToQueryOnCreateEdit(searchText));

  // Fetch the data
  const {
    data: productsData,
    loading,
    refetch,
  } = useQuery<QueryProductsResult>(QUERY_PRODUCTS, {
    variables: {
      where,
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
    },
  });
  const products = productsData?.products || [];

  // Fetch the count
  const {
    data: countData,
    loading: countLoading,
    refetch: countRefetch,
  } = useQuery<QueryProductCountResult>(QUERY_PRODUCTS_COUNT, {
    variables: {
      where,
    },
  });

  useEffect(() => {
    refetch().then();
    countRefetch().then();
  }, []);

  useEffect(() => {
    if (!loading) {
      const mappedRows = productsToRowsForEditPage(
        currentListProducts,
        productsData
      );
      updateRows(mappedRows);
    }
  }, [productsData, loading, currentListProducts]);

  useEffect(() => {
    // hide the submit button loader
    setFetchingProducts(loading);
  }, [loading]);

  // Build the search query
  useEffect(() => {
    updateCurrentPage(1);
    updateWhere(filtersToQueryOnCreateEdit(searchText));
  }, [searchText]);

  const hasProducts = products.length > 0;

  const isFiltered = searchText !== '';
  const disableSearchFilter = !loading && !hasProducts && !isFiltered;

  return (
    <div>
      <div className={cx('row')}>
        <SearchBar
          placeholder='Search by product ID or description'
          onSubmit={(text) => updateSearchText(text)}
          disabled={disableSearchFilter}
        />
      </div>
      <Table
        columns={getColumnsForEditPage(
          currentListProducts,
          onListProductsChange,
          productsData
        )}
        rows={rows}
        emptyText='No products found'
        isLoading={loading}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={countLoading}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={hasProducts}
        totalCount={countData?.productsCount?.aggregate.count || 0}
      />
    </div>
  );
};

export default CreateEditProductsTable;
