// Vendor
import React from 'react';

const IconDocument = () => (
  <svg width='35' height='44' viewBox='0 0 35 44'>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#00BCD4'
        d='M23.076 12.837c-.955 0-1.737-.792-1.737-1.76V0H4.341C1.953 0 0 1.98 0 4.401V39.6C0 42.019 1.953 44 4.34 44h25.32c2.387 0 4.34-1.98 4.34-4.401V12.837H23.076'
      />
      <path
        fill='#FFF'
        stroke='#EBEBEB'
        d='M22.645 13H34L21 0v11.353c.062.88.765 1.583 1.645 1.647'
      />
      <path fill='#018090' d='M34 22L34 13 23 13 34 22' />
    </g>
  </svg>
);

export default IconDocument;
