// Vendor
import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';

// Internal
import { IconSystemError } from 'styles/images';
import TradingPartnersList from './TradingPartnersList';
import { useShowableTradingPartners } from './service';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

const Container: FC<{}> = () => {
  const { partners, error, refetch } = useShowableTradingPartners();

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error(
        `An error occurred when re-fetching trading partners info.`
      );
    });
  }, []);

  return error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <TradingPartnersList partners={partners} />
  );
};

export default Container;
