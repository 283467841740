// Vendor
import React, { FC } from 'react';

interface Props {
  className?: string;
  color?: string;
}

const IconRemove: FC<Props> = ({ className, color = '#4F5F82' }) => (
  <svg className={className} width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd'>
      <rect
        width='23'
        height='23'
        x='.5'
        y='.5'
        fill='#FFF'
        stroke='#E4EBEF'
        rx='4'
      />
      <path
        fill={color}
        fillRule='nonzero'
        d='M15.13 18.5c.584 0 1.087-.473 1.123-1.055L16.75 9.5h.375c.207 0 .375-.168.375-.375V8.75c0-.207-.168-.375-.375-.375h-1.931l-.797-1.329c-.181-.301-.613-.546-.965-.546h-2.364c-.352 0-.784.245-.965.546l-.797 1.329H7.375c-.207 0-.375.168-.375.375v.375c0 .207.168.375.375.375h.375l.497 7.945c.036.582.54 1.055 1.123 1.055h5.76zM13.881 8.375H10.62l.45-.75h2.362l.45.75zm1.25 9H9.37L8.878 9.5h6.745l-.493 7.875z'
      />
    </g>
  </svg>
);

export default IconRemove;
