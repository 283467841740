// Vendor
import DialogContent from '@material-ui/core/DialogContent';
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import Dialog from 'components/Dialog';
import { Button } from 'components';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  title: string;
  bodyText: string;
  onConfirmationClick: (val: boolean) => void;
  toggleModal: (val: boolean) => void;
}

const ActionModal: FC<Props> = (props) => {
  const { onConfirmationClick, toggleModal, title, bodyText } = props;

  return (
    <Dialog title={title} toggleDialog={() => toggleModal(false)}>
      <DialogContent>
        <div className={cx('text')}>{bodyText}</div>
        <div className={cx('buttonContainer')}>
          <Button
            color='secondary'
            dataTestId='yesButton'
            label='Yes'
            onClick={() => onConfirmationClick(true)}
            style={{ width: 120 }}
            variant='outlined'
          />
          <Button
            dataTestId='noButton'
            label='No'
            onClick={() => onConfirmationClick(false)}
            style={{
              border: 'none',
              marginLeft: 8,
              padding: 0,
              width: 120,
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ActionModal;
