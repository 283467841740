import React, { FC } from 'react';
import { Paper } from '@material-ui/core';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface IconSettings {
  color: string;
  icon: any;
}

export interface Props {
  active: boolean;
  title: string;
  iconSettings: IconSettings;
  linkTo?: string;
}

const EntryIcon: FC<IconSettings> = ({ color, icon }) => (
  <div className={cx('icon-container')} style={{ backgroundColor: color }}>
    <img src={icon} className={cx('icon')} alt='' />
  </div>
);

export const DashboardEntryCard: FC<Props> = ({
  title,
  iconSettings,
  linkTo,
  active,
}) => {
  const card = (
    <Paper className={cx('card-container', { 'card-disabled': !active })}>
      <EntryIcon icon={iconSettings.icon} color={iconSettings.color} />
      <span className={cx('title')}>{title}</span>
    </Paper>
  );

  return !active ? (
    card
  ) : (
    <Link to={linkTo || ''} className={cx('link')}>
      {card}
    </Link>
  );
};
