// Vendor
import React from 'react';

const IconSystemError = () => (
  <svg width='312' height='327' viewBox='0 0 312 327'>
    <defs>
      <rect id='IconSystemErrorB' width='300' height='218' y='.264' rx='4' />
      <filter
        id='IconSystemErrorA'
        width='106.7%'
        height='109.2%'
        x='-3.3%'
        y='-3.7%'
        filterUnits='objectBoundingBox'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
          stdDeviation='3'
        />
        <feColorMatrix
          in='shadowBlurOuter1'
          values='0 0 0 0 0.48627451 0 0 0 0 0.560784314 0 0 0 0 0.592156863 0 0 0 0.295290647 0'
        />
      </filter>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g fillRule='nonzero' transform='translate(6)'>
        <ellipse
          cx='150.748'
          cy='140.648'
          fill='#E6E8F2'
          rx='140.274'
          ry='140.648'
        />
        <g transform='translate(0 29.925)'>
          <use
            fill='#000'
            filter='url(#IconSystemErrorA)'
            xlinkHref='#IconSystemErrorB'
          />
          <use fill='#FFF' xlinkHref='#IconSystemErrorB' />
        </g>
        <path
          fill='#E6E8F2'
          d='M31.708 53.827h96a2 2 0 0 1 2 2v7.97a2 2 0 0 1-2 2h-96a2 2 0 0 1-2-2v-7.97a2 2 0 0 1 2-2zM32 79h236a2 2 0 0 1 2 2v7.97a2 2 0 0 1-2 2H32a2 2 0 0 1-2-2V81a2 2 0 0 1 2-2zm-.292 44.827h96a2 2 0 0 1 2 2v7.97a2 2 0 0 1-2 2h-96a2 2 0 0 1-2-2v-7.97a2 2 0 0 1 2-2zM32 149h236a2 2 0 0 1 2 2v7.97a2 2 0 0 1-2 2H32a2 2 0 0 1-2-2V151a2 2 0 0 1 2-2zm-.292 44.827h96a2 2 0 0 1 2 2v7.97a2 2 0 0 1-2 2h-96a2 2 0 0 1-2-2v-7.97a2 2 0 0 1 2-2zM32 219h236a2 2 0 0 1 2 2v7.97a2 2 0 0 1-2 2H32a2 2 0 0 1-2-2V221a2 2 0 0 1 2-2z'
        />
      </g>
      <path
        fill='#CB6671'
        fillRule='nonzero'
        d='M178.431 162c1.147 0 2.134-.367 2.962-1.1.828-.733 1.338-1.626 1.529-2.678.19-1.053.032-2.089-.478-3.11l-22.931-39.792c-.573-1.02-1.385-1.706-2.436-2.057a4.856 4.856 0 0 0-3.154 0c-1.05.35-1.863 1.036-2.436 2.057l-22.931 39.793c-.51 1.02-.669 2.056-.478 3.109.191 1.052.7 1.945 1.529 2.678.828.733 1.815 1.1 2.962 1.1h45.862zm-20.25-17h-4.363c-.303 0-.56-.11-.773-.328-.212-.219-.318-.453-.318-.703l-.727-12.75c0-.375.106-.672.318-.89.212-.22.47-.329.773-.329h5.818c.303 0 .56.11.773.328.212.219.318.516.318.89l-.727 12.75c0 .25-.106.485-.318.704-.213.219-.47.328-.773.328zm-2.681 11c-1.24 0-2.299-.44-3.18-1.32-.88-.881-1.32-1.94-1.32-3.18 0-1.24.44-2.299 1.32-3.18.881-.88 1.94-1.32 3.18-1.32 1.24 0 2.299.44 3.18 1.32.88.881 1.32 1.94 1.32 3.18 0 1.24-.44 2.299-1.32 3.18-.881.88-1.94 1.32-3.18 1.32z'
      />
      <text
        fill='#525F7F'
        fontFamily='Graphik-Light, Graphik'
        fontSize='18'
        fontWeight='300'
        transform='translate(6)'
      >
        <tspan x='97.629' y='318'>
          System Error
        </tspan>
      </text>
    </g>
  </svg>
);

export default IconSystemError;
