// Vendor
import React, { FC } from 'react';

const IconTrash: FC = () => (
  <svg
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.6875 2.9375H10.418L9.48828 1.40625C9.26953 1.05078 8.77734 0.75 8.36719 0.75H5.60547C5.19531 0.75 4.70312 1.05078 4.48438 1.40625L3.55469 2.9375H1.3125C1.06641 2.9375 0.875 3.15625 0.875 3.375V3.8125C0.875 4.05859 1.06641 4.25 1.3125 4.25H1.75L2.32422 13.5195C2.35156 14.2031 2.95312 14.75 3.63672 14.75H10.3359C11.0195 14.75 11.6211 14.2031 11.6484 13.5195L12.25 4.25H12.6875C12.9062 4.25 13.125 4.05859 13.125 3.8125V3.375C13.125 3.15625 12.9062 2.9375 12.6875 2.9375ZM5.60547 2.0625H8.36719L8.88672 2.9375H5.08594L5.60547 2.0625ZM10.3359 13.4375H3.63672L3.0625 4.25H10.9102L10.3359 13.4375Z'
      fill='#525F7F'
    />
  </svg>
);

export default IconTrash;
