// Internal
import { DocumentType } from 'common/config';
import {
  arrayOperators,
  dateOperators,
  numberOperators,
  stringOperators,
} from './constants';
import { allFields, customerTypes, valueField } from '../../constants';
import { AttributesType, FieldType, ValuesType } from '../../types';

// Add Value field when an Auth customer is selected on the right hand side
const getAttributesForValue = (
  customerType: string,
  filteredAttributes: AttributesType[]
): AttributesType[] => {
  if (
    customerType === '340B Customer' ||
    customerType === 'DEA Customer' ||
    customerType === 'HIBCC Customer'
  ) {
    return [valueField, ...filteredAttributes];
  }

  return filteredAttributes;
};

// Here is specific logic to checking which select field customer types should display on the
// right side of the operator and which rule attributes should be displayed based on current rule set
// Separate from getFields() for better unit testing
export const getAttributes = (
  customerTypeProp: string,
  documentTypes: DocumentType[],
  ruleSet: string
): AttributesType[] => {
  // Allowed fields filtered by rule set and customer type
  const fieldsFiltered = allFields(documentTypes).filter(
    ({ customerTypes, ruleSets }) =>
      (ruleSets.includes(ruleSet) || ruleSets.length === 0) &&
      ((customerTypeProp && customerTypes.includes(customerTypeProp)) ||
        customerTypes.length === 0)
  );

  return fieldsFiltered.map((field) => ({
    ...field,
    valueSources: ['value', 'select'],
    values: customerTypes.map(
      (customerType): ValuesType => ({
        label: customerType.label,
        name: customerType.name,
        attributes: getAttributesForValue(customerType.label, fieldsFiltered),
      })
    ),
  }));
};

export const getFields = (
  documentTypes: DocumentType[],
  ruleSet: string
): FieldType[] =>
  customerTypes.map((customerType) => ({
    ...customerType,
    fieldData: {
      attributes: getAttributes(customerType.label, documentTypes, ruleSet),
    },
  }));

// Our field name has all field names joined with a '|' since they all belong to 1 rule
// Here we grab each field name from the single rule field name
export const separateFieldName = (fieldName: string): string[] =>
  fieldName.split('|');

// This is to grab the field objects based on the field name
export const getFieldObjects = (
  conditionFields: FieldType[],
  fieldName: string
) => {
  const separatedFields = separateFieldName(fieldName);

  const fieldObjects: any[] = [];

  const addFieldObject = (fields: string[], allFieldObjects: any) => {
    // Added some logic specific to if the field is a key text field
    // Field name for key text field is key#key and once we updated the text field it is textFieldValue#key
    const fieldObj = allFieldObjects.find((afo: FieldType) =>
      fields[0]?.includes('#key')
        ? afo.name === 'key#key'
        : afo.name === fields[0]
    );
    fields.shift();

    const attributes = fieldObj?.fieldData?.attributes || fieldObj?.attributes;

    const newFieldObject = {
      ...fieldObj,
      attributes,
    };
    delete newFieldObject.fieldData;
    fieldObjects.push(newFieldObject);

    if (attributes) {
      addFieldObject(
        fields,
        fieldObj?.fieldData?.attributes || fieldObj?.attributes
      );
    }
  };
  addFieldObject([...separatedFields], conditionFields);

  return fieldObjects;
};

export const getInputType = (
  conditionFields: FieldType[],
  fieldName: string
) => {
  const fieldObjects = getFieldObjects(conditionFields, fieldName);
  return fieldObjects[fieldObjects.length - 1].inputType;
};

export const getValues = (conditionFields: FieldType[], fieldName: string) => {
  const fieldObjects = getFieldObjects(conditionFields, fieldName);
  const { values } = fieldObjects[1];
  return values ? [{ options: values }] : [];
};

export const getValueSources = (
  conditionFields: FieldType[],
  fieldName: string
): string[] => {
  const fieldObjects = getFieldObjects(conditionFields, fieldName);
  return fieldObjects[1].valueSources || ['value'];
};

export const getOperators = (
  conditionFields: FieldType[],
  fieldName: string
) => {
  const fieldObjects = getFieldObjects(conditionFields, fieldName);
  const { inputType, operators } = fieldObjects[fieldObjects.length - 1];

  // If we have custom operators for specific fields
  if (operators) return operators;

  switch (inputType) {
    case 'string':
      return stringOperators;
    case 'number':
      return numberOperators;
    case 'date':
      return dateOperators;
    case 'array':
      return arrayOperators;
    default:
      return [];
  }
};
