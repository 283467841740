// Vendor
import React, { FC } from 'react';
import { FormApi } from 'final-form';

// Internal
import Table from 'components/Table';
import { StringifiedProductPriceWithID } from 'common/types';
import { pricingsToRows, getColumns } from './service';
import { ProductDataType } from '../../service';

interface PricingsEditTableProps {
  priceTypes: ProductDataType[];
  formValues: Record<string, any>;
  form: FormApi;
}

export const PricingsEditTable: FC<PricingsEditTableProps> = (props) => {
  const { priceTypes, formValues, form } = props;

  const rows = pricingsToRows(
    formValues.pricings as StringifiedProductPriceWithID[]
  );

  const COLUMNS = getColumns(formValues, priceTypes, form);

  return (
    <Table
      {...{ form, rows }}
      columns={COLUMNS}
      emptyText=''
      isLoading={false}
    />
  );
};
