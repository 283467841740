// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { truncateDatetime } from 'common/helpers';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  classesOfTrade?: {
    description?: string;
    endDate?: string;
    id: string;
    name: string;
    startDate?: string;
  }[];
}

const CotTooltip: FC<Props> = ({ classesOfTrade = [] }) => (
  <div className={cx('root')}>
    Class of Trade
    <div className={cx('row')}>
      <div className={cx('column')}>
        <b className={cx('key')}>Type</b>
        {classesOfTrade.map((cot) => {
          const { endDate, id, name, startDate } = cot;
          return (
            <div
              className={cx('marginTop', 'value')}
              key={id + startDate + endDate}
            >
              {name || <>&nbsp;</>}
            </div>
          );
        })}
      </div>
      <div className={cx('column')}>
        <b className={cx('key')}>Description</b>
        {classesOfTrade.map((cot) => {
          const { description, endDate, id, startDate } = cot;
          return (
            <div
              className={cx('marginTop', 'value')}
              key={id + startDate + endDate}
            >
              {description || <>&nbsp;</>}
            </div>
          );
        })}
      </div>
      <div className={cx('column')}>
        <b className={cx('key')}>Start Date</b>
        {classesOfTrade.map((cot) => {
          const { endDate, id, startDate } = cot;
          return (
            <div
              className={cx('marginTop', 'value')}
              key={id + startDate + endDate}
            >
              {truncateDatetime(startDate) || <>&nbsp;</>}
            </div>
          );
        })}
      </div>
      <div className={cx('column')}>
        <b className={cx('key')}>End Date</b>
        {classesOfTrade.map((cot) => {
          const { endDate, id, startDate } = cot;
          return (
            <div
              className={cx('marginTop', 'value')}
              key={id + startDate + endDate}
            >
              {truncateDatetime(endDate) || <>&nbsp;</>}
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default CotTooltip;
