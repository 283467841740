// Vendor
import React, { FC } from 'react';

interface Props {
  className?: string;
}

const IconArrowRight: FC<Props> = ({ className }) => (
  <svg className={className} width='24' height='24' viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      opacity='.8'
      transform='rotate(45 9 12)'
      d='M14 8.25v8.125a.625.625 0 1 1-1.25 0V8.25H4.625a.625.625 0 1 1 0-1.25h8.743a.632.632 0 0 1 .632.632v.618z'
    />
  </svg>
);

export default IconArrowRight;
