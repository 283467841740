// Vendor
import React, { FC, ReactElement } from 'react';
import classnames from 'classnames/bind';

// Internal
import Key from './Key';
import Value from './Value';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  children?: React.ReactNode;
  linkUrl?: string;
  title: string;
  titleIcon?: ReactElement;
  value?: string | null;
  valueIcon?: ReactElement;
  valueLength?: number | null;
  width?: string;
}

const KeyValuePair: FC<Props> = (props) => {
  const {
    children,
    linkUrl,
    title,
    titleIcon,
    value,
    valueIcon,
    valueLength,
    width = '33%',
  } = props;

  return (
    <div
      className={cx('column', 'propertyGroup')}
      style={{ width }}
      data-testid={title}
    >
      <Key title={title} titleIcon={titleIcon} />
      {children ? (
        <div data-testid='children-content'>{children}</div>
      ) : (
        <Value
          linkUrl={linkUrl}
          value={value}
          valueIcon={valueIcon}
          valueLength={valueLength}
        />
      )}
    </div>
  );
};

export default KeyValuePair;
