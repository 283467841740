import { IdentifiersDelimiters } from './application/row-processor/row-processing-service';

export const CUSTOMER_PUBLISH_CHUNK_SIZE = 100;
export const PARALLEL_BATCHES = 5;
export enum FLATFILE_FLOW {
  OutOfNetwork = 'oon',
  Local = 'local',
  ContractHeader = 'contract-header',
  ContractPricing = 'contract-pricing',
}
export const DEFAULT_IDENTIFIER_DELIMITER = ',';
export const CUSTOMER_DEFAULT_IDENTIFIER_DELIMITERS: IdentifiersDelimiters = {
  hin: DEFAULT_IDENTIFIER_DELIMITER,
  dea: DEFAULT_IDENTIFIER_DELIMITER,
  _340b: DEFAULT_IDENTIFIER_DELIMITER,
};
