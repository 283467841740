// Vendor
import { capitalize } from 'lodash';

export enum ProposalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export const getStatusChipColor = (status: string) => {
  let statusChipColor;
  switch (status) {
    case ProposalStatus.PENDING:
      statusChipColor = '#F9BC60';
      break;
    case ProposalStatus.APPROVED:
      statusChipColor = '#00BC96';
      break;
    case ProposalStatus.REJECTED:
      statusChipColor = '#CB6671';
      break;
    default:
      statusChipColor = '#F9BC60';
  }
  return statusChipColor;
};

export const formatImpact = (impact: string[]) => {
  const impactText =
    impact.length > 0 ? formatImpactUpdates(impact) : 'New customer';

  return impactText;
};

const formatImpactUpdates = (impactProp: string[]) => {
  const impact: string[] = impactProp.map((singleImpact) => {
    switch (singleImpact) {
      case 'addresses':
        return 'address';
      case 'attributes':
        return 'attribute';
      case 'classesOfTrade':
        return 'class of trade';
      case 'documents':
        return 'document';
      case 'endDate':
        return 'end date';
      case 'identifiers':
        return 'identifier';
      case 'lists':
        return 'list';
      case 'names':
        return 'name';
      case 'parentId':
        return 'parent id';
      case 'startDate':
        return 'start date';
      case 'relationships':
        return 'relationship';
      default:
        return singleImpact;
    }
  });

  impact[0] = capitalize(impact[0]);
  return `${impact.join(', ')} updated`;
};
