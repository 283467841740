// Internal
import { productListPath } from 'common/helpers';
import {
  DESCRIPTION_COLUMN,
  NAME_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { TableColumn, TableRow } from 'components/Table';
import { QueryProductListsResult, wrapSearchText } from 'query';

export function rawDataToRows(
  productLists: QueryProductListsResult
): TableRow[] {
  if (!productLists || !productLists.productLists) {
    return [];
  }

  return productLists.productLists.map((list) => ({
    type: 'expanding',
    isExpandable: false,
    data: {
      id: list.id,
      names: list.name,
      description: list.description,
      timestamp: list.revisedAt,
    },
  }));
}

export const COLUMNS: TableColumn[] = [
  {
    headerName: 'PRODUCT LIST ID',
    type: 'link',
    size: 'medium',
    displayTextFn: (data: any) => data.id,
    urlFn: (data: any) => productListPath(data.id),
  },
  NAME_COLUMN,
  DESCRIPTION_COLUMN,
  UPDATED_AT_COLUMN,
];

export const filtersToQuery = (searchText: string | undefined = undefined) => {
  // Default where clause
  const whereObject: any = {};

  if (searchText !== undefined && searchText !== null) {
    const searchTextWrapped = wrapSearchText(searchText);

    whereObject._or = [
      { name: { _ilike: searchTextWrapped } },
      { id: { _ilike: searchTextWrapped } },
      { description: { _ilike: searchTextWrapped } },
    ];
  }

  return whereObject;
};
