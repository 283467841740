export const minimumDatePossible = '1000-06-15';
export const maximumDatePossible = '9999-06-15';

export const contractTypes = [
  { name: 'GPO', label: 'GPO' },
  { name: 'Wholesaler', label: 'Wholesaler' },
  { name: '340B', label: '340B' },
  { name: 'Government', label: 'Government' },
  { name: 'NA', label: 'NA' },
  { name: 'Vendor', label: 'Vendor' },
  { name: 'Individual', label: 'Individual' },
];
