// Vendor
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';

// Internal
import {
  DEFAULT_EMPTY_STATE,
  LONG_DATETIME_FORMAT,
  SHORT_DATE_FORMAT,
} from 'common/constants';
import { IconInvalidName } from 'styles/images';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

// DO NOT USE THIS FUNCTION FOR SHORT FORMAT. USE TRUNCATEDATETIME.
// ONLY USE TO CONVERT TIMESTAMP/UPDATED AT FROM BACKEND TO LEGIBLE DATE TIME FORMAT
export const formatDate = (date: string) => {
  if (!date) {
    return null;
  }

  if (!dayjs(date).isValid()) {
    return DEFAULT_EMPTY_STATE;
  }

  const dateAsMoment = dayjs(date);

  const noTime = !date.includes(':');
  if (noTime) {
    return dateAsMoment.format(LONG_DATETIME_FORMAT);
  }

  return `${dateAsMoment.format(`${LONG_DATETIME_FORMAT} z`)}`;
};

export const getDay = (date = ' ') => {
  if (!dayjs(date).isValid()) {
    return <IconInvalidName />;
  }
  const formattedDate = formatDate(date);
  const dayjsDate = dayjs(formattedDate || undefined, SHORT_DATE_FORMAT);
  return dayjsDate ? dayjsDate.format('D') : <IconInvalidName />;
};

export const getMonthAndYear = (date = ' ') => {
  if (!dayjs(date).isValid()) {
    return (
      <>
        SYSTEM
        <br />
        ERROR
      </>
    );
  }
  const formattedDate = formatDate(date);
  const dayjsDate = dayjs(formattedDate || undefined, SHORT_DATE_FORMAT);
  return dayjsDate ? (
    `${dayjsDate.format('MMM').toUpperCase()} ${dayjsDate.format('YYYY')}`
  ) : (
    <>
      SYSTEM
      <br />
      ERROR
    </>
  );
};

/**
 * This method is to deal with a date string (e.g. "1960-01-01") being imported from the connector as
 * "1960-01-01T00:00:00Z" (by converting to UTC midnight of that date). This data traverses throughout system,
 * and could arrive at the FE with different timezone than UTC. E.g. if the backend postgres is in PST timezone,
 * the data will be "1959-12-31T16:00:00-08". So what we'll need to do is:
 * 1. Convert the date back to UTC
 * 2. Then truncate the time and the zone to get the date only
 * Also, in some instances (e.g. pricing tier), the absence of a date is indicated by a value '-999999999-01-01T00:00:00+18:00',
 * and this is not a valid dayjs date
 */
export const truncateDatetime = (
  datetime: string | undefined | null,
  format = SHORT_DATE_FORMAT
) => {
  if (!datetime) {
    return null;
  }

  // dayjs isValid is strict and certain timestamps (1959-12-31T16:00:00-08) are invalid so here
  // we try to convert to a valid timestamp and if that is still invalid it gets caught on line 100
  const date = !dayjs(datetime).isValid()
    ? dayjs(datetime, LONG_DATETIME_FORMAT).format()
    : datetime;

  if (!dayjs(date).isValid()) {
    return DEFAULT_EMPTY_STATE;
  }

  return dayjs.utc(date).format(format);
};

// Return true if the two dates refer to the same day, ignoring the time
export const isSameDay = (date1: Date, date2: Date) =>
  date1.toLocaleDateString() === date2.toLocaleDateString();

// Instead of using date DB types we store dates in datetime fields.
// To avoid timezone issues with these we hardcode the time part
// of start dates to the first instant of the UTC date.
/**
 *
 * @param date start date in SHORT FORMAT (YY-MM-DD). Format YY/MM/DD is considered as invalid
 * @returns start date in UTC format
 */
export const convertStartDateToUtcTimestamp = (date?: string) => {
  const dayjsDate = dayjs(date, SHORT_DATE_FORMAT, /* strict */ true);
  if (!dayjsDate || !dayjsDate.isValid()) {
    return undefined;
  }
  return `${date}T00:00:00.000Z`;
};

// Instead of using date DB types we store dates in datetime fields.
// To avoid timezone issues with date fields we hardcode the time part
// of end dates to the last instant of the UTC date.
/**
 *
 * @param date end date in SHORT FORMAT (YY-MM-DD). Format YY/MM/DD is considered as invalid
 * @returns end date in UTC format
 */
export const convertEndDateToUtcTimestamp = (date?: string) => {
  const dayjsDate = dayJsDate(date);
  if (!dayjsDate || !dayjsDate.isValid()) {
    return undefined;
  }
  return `${date}T23:59:59.999Z`;
};

/**
 * @param date end date in SHORT FORMAT (YY-MM-DD). Format YY/MM/DD is considered as invalid
 * @returns Dayjs object
 */
export const dayJsDate = (date?: string) =>
  dayjs(date, SHORT_DATE_FORMAT, /* strict */ true);

// Return the larger startDate between the two dates
export const clampStartDate = (
  customerStartDate: string | undefined | null,
  contractStartDate: string | undefined | null
): string => {
  let startDate;
  if (!customerStartDate) {
    startDate = contractStartDate;
  } else if (!contractStartDate) {
    startDate = customerStartDate;
  } else if (dayjs(customerStartDate).isBefore(dayjs(contractStartDate))) {
    startDate = contractStartDate;
  } else {
    startDate = customerStartDate;
  }
  return !startDate
    ? DEFAULT_EMPTY_STATE
    : truncateDatetime(startDate) ?? DEFAULT_EMPTY_STATE;
};

// Return the smaller endDate between the two dates
export const clampEndDate = (
  customerEndDate: string | undefined | null,
  contractEndDate: string | undefined | null
): string => {
  let endDate;
  if (!customerEndDate) {
    endDate = contractEndDate;
  } else if (!contractEndDate) {
    endDate = customerEndDate;
  } else if (dayjs(customerEndDate).isBefore(dayjs(contractEndDate))) {
    endDate = customerEndDate;
  } else {
    endDate = contractEndDate;
  }
  return !endDate
    ? DEFAULT_EMPTY_STATE
    : truncateDatetime(endDate) ?? DEFAULT_EMPTY_STATE;
};

export const dateFromISOString = (
  str: string | null | undefined
): Date | null => (str ? dayjs(str).utc().toDate() : null);
