// Internal
import {
  END_DATE_COLUMN,
  NAME_COLUMN,
  START_DATE_COLUMN,
} from 'components/Cells/constants';
import { TableColumn, TableRow } from 'components/Table';
import { CustomerDetailsList } from 'query';
import {
  localCustomerPath,
  parseMediledgerId,
  tradingPartnerPath,
} from 'common/helpers';

export const getColumns = (title: string): TableColumn[] => {
  // GPO Affiliation has an extra ID column
  if (title === 'GPO affiliation') {
    return [
      {
        headerName: 'ID',
        size: 'small',
        type: 'link',
        displayTextFn: (data) => data.id,
        urlFn: (data) => data.url,
      },
      NAME_COLUMN,
      START_DATE_COLUMN,
      END_DATE_COLUMN,
    ];
  }

  return [NAME_COLUMN, START_DATE_COLUMN, END_DATE_COLUMN];
};

export const getRows = (
  lists: CustomerDetailsList[],
  title: string
): TableRow[] => {
  if (lists.length === 0) return [];

  return lists.map((l) => {
    const { list, startDate, endDate } = l;

    const rawName = list?.name;
    let name = rawName;
    let id;
    let url = '';

    // GPO Affiliation can be either member ID or customer ID
    if (title === 'GPO affiliation') {
      name = list?.gpoCustomerIdRecord?.names[0] ?? rawName;
      if (name && name.startsWith('ml:customer:')) {
        const { id: customerId } = parseMediledgerId(name);
        name = undefined;
        id = customerId;
        url = localCustomerPath(customerId);
      } else if (list?.id) {
        const { memberId } = parseMediledgerId(list.id);
        id = memberId;
        url = tradingPartnerPath(memberId);
      }
    }

    return {
      isExpandable: false,
      data: {
        names: name,
        startDate,
        endDate,
        id,
        url,
      },
      type: 'expanding',
    };
  });
};
