// Vendor
import React from 'react';

// Internal
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  displayAddress,
  formatDate,
  formatIdentifiers,
  truncateDatetime,
} from 'common/helpers';
import { CustomerProposal, Proposal } from 'hooks';
import { isEmpty, isEqual } from 'lodash';
import { IconChange } from 'styles/images';
import { CustomerListById } from 'query';
import { Eligibility, Relationship } from 'types/generated/Relationship';
import { fromMediledgerIdToRawId } from 'common/helpers/mlIdUtils';

interface List {
  listId: string;
  startDate?: string;
  endDate?: string;
}

export const getGpoAffiliations = (
  allLists: CustomerListById[],
  lists?: List[],
  relationships?: Relationship[]
): List[] => {
  const result: List[] = [];

  lists?.forEach((list) => {
    if (allLists.find((l) => l.id === list.listId)?.type === 'gpo') {
      result.push(list);
    }
  });

  relationships?.forEach((relationship) => {
    if (relationship.relationship === 'gpo-affiliation') {
      result.push({
        listId: relationship.to,
        startDate: relationship.startDate,
        endDate: relationship.endDate,
      });
    }
  });

  return result;
};

export const getCustomerKeyValues = (
  allLists: CustomerListById[],
  gpoList: { name: string; label: string }[],
  customer: CustomerProposal,
  impact: string[],
  proposal?: CustomerProposal
) => {
  const {
    addresses,
    classesOfTrade,
    id,
    identifiers: identifiersProp,
    lists,
    names,
    relationships,
  } = customer;

  const identifiers = formatIdentifiers(identifiersProp || []);

  const gpos: List[] = getGpoAffiliations(allLists, lists, relationships);
  const proposalGpos: List[] = getGpoAffiliations(
    allLists,
    proposal?.lists,
    proposal?.relationships
  );
  // Look up GPO name in lists first, then in GPO names, fallback to ID
  const gpoName: string =
    allLists.find((list) => list.id === gpos[0]?.listId)?.name ||
    gpoList.find((list) => list.name === gpos[0]?.listId)?.label ||
    gpos[0]?.listId;

  const eligibilities = (relationships?.filter(
    (r) => r.relationship === 'eligibility'
  ) ?? []) as Eligibility[];
  const proposalEligibilities = (proposal?.relationships?.filter(
    (r) => r.relationship === 'eligibility'
  ) ?? []) as Eligibility[];

  const titleIcon = (customerField: any, proposalField?: any) => {
    if (isEmpty(proposalField)) return undefined;
    if (isEqual(customerField, proposalField)) return undefined;
    return <IconChange />;
  };

  return [
    {
      title: 'Customer ID',
      value: id,
    },
    {
      title: 'Identifier',
      titleIcon: impact.includes('identifiers') ? <IconChange /> : undefined,
      value: identifiers?.[0],
    },
    {
      title: 'Name',
      titleIcon: impact.includes('names') ? <IconChange /> : undefined,
      value: names?.[0],
    },
    {
      title: 'Class of Trade',
      titleIcon: impact.includes('classesOfTrade') ? <IconChange /> : undefined,
      value: classesOfTrade?.[0]?.classOfTradeId,
    },
    {
      title: 'Address',
      titleIcon: impact.includes('addresses') ? <IconChange /> : undefined,
      value:
        addresses && addresses.length > 0
          ? displayAddress(addresses?.[0])
          : undefined,
    },
    {
      title: 'Updated At',
      value: formatDate(customer.timestamp),
    },
    {
      title: 'GPO Affiliation',
      titleIcon: titleIcon(gpos, proposalGpos),
      value: gpoName,
    },
    {
      title: 'Affiliation Start Date',
      value: truncateDatetime(gpos[0]?.startDate),
    },
    {
      title: 'Affiliation End Date',
      value: truncateDatetime(gpos[0]?.endDate),
    },
    {
      title: 'Contract Name',
      titleIcon: titleIcon(eligibilities, proposalEligibilities),
      value:
        eligibilities.length === 0
          ? null
          : fromMediledgerIdToRawId(eligibilities[0]?.to),
    },
    {
      title: 'Contract Customer Start Date',
      value: truncateDatetime(eligibilities[0]?.startDate),
    },
    {
      title: 'Contract Customer End Date',
      value: truncateDatetime(eligibilities[0]?.endDate),
    },
  ];
};

export const getSourceKeyValues = (
  customer: CustomerProposal,
  triggerSource: string
) => {
  const {
    addresses,
    classesOfTrade,
    identifiers: identifiersProp,
    names,
  } = customer;

  const identifiers = formatIdentifiers(identifiersProp || []);

  return [
    {
      title: 'Trigger Source',
      value: triggerSource,
    },
    {
      title: 'Identifier',
      value: identifiers?.[0],
    },
    {
      title: 'Name',
      value: names?.[0],
    },
    {
      title: 'Class of Trade',
      value: classesOfTrade?.[0]?.classOfTradeId,
    },
    {
      title: 'Address',
      value:
        addresses && addresses.length > 0
          ? displayAddress(addresses?.[0])
          : undefined,
    },
    {
      title: 'Updated At',
      value: formatDate(customer.timestamp),
    },
  ];
};

export const formatProposalForCreateCustomer = (p: Proposal) => {
  const { proposal } = p;
  const { attributes, classesOfTrade, endDate, lists, startDate } = proposal;

  const customer = {
    ...proposal,
    attributes: attributes?.map(({ endDate, name, startDate, value }) => ({
      endDate: convertEndDateToUtcTimestamp(truncateDatetime(endDate) || ''),
      name,
      startDate: convertStartDateToUtcTimestamp(
        truncateDatetime(startDate) || ''
      ),
      value,
    })),
    classesOfTrade: classesOfTrade?.map(
      ({ endDate, classOfTradeId, startDate }) => ({
        endDate: convertEndDateToUtcTimestamp(truncateDatetime(endDate) || ''),
        classOfTradeId,
        startDate: convertStartDateToUtcTimestamp(
          truncateDatetime(startDate) || ''
        ),
      })
    ),
    lists: lists?.map(({ endDate, listId, startDate }) => ({
      endDate: convertEndDateToUtcTimestamp(truncateDatetime(endDate) || ''),
      listId,
      startDate: convertStartDateToUtcTimestamp(
        truncateDatetime(startDate) || ''
      ),
    })),
    endDate: convertEndDateToUtcTimestamp(truncateDatetime(endDate) || ''),
    startDate: convertStartDateToUtcTimestamp(
      truncateDatetime(startDate) || ''
    ),
  };
  delete customer.documents;
  delete customer.errors;
  delete customer._system;

  return customer;
};
