// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Breadcrumb, Button } from 'components';
import { useAppBarSetTitle } from 'hooks';
import { TradingPartnerEntity } from 'query';
import { cabBeDeactivated, canBeActivated } from 'common/helpers';
import Details from './Details';
import Config from './Config';
import ActionModal from './ActionModal';

// Styles
import styles from './TradingPartnerDetails.module.css';

const cx = classnames.bind(styles);

interface Props {
  partner: TradingPartnerEntity;
}

const TradingPartnerDetails: FC<Props> = ({ partner }) => {
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const { id, name = '', visualStatus } = partner;
  const canBeActivatedValue = canBeActivated(visualStatus);
  const canBeDeactivated = cabBeDeactivated(visualStatus);

  useAppBarSetTitle(name);

  return (
    <div className={cx('root')} id='trading-partner-details'>
      {showModal && (
        <ActionModal
          canBeActivated={canBeActivatedValue}
          partner={partner}
          toggleModal={toggleModal}
          onLoadingChange={(loading) => setLoadingAuth(loading)}
        />
      )}
      <section className={cx('row', 'sub-header')}>
        <Breadcrumb
          crumbs={['Trading Partner Management', name]}
          links={['/admin/trading-partners', `/admin/trading-partners/${id}`]}
        />
        <div>
          {(canBeActivatedValue || canBeDeactivated) && (
            <Button
              dataTestId='authorizeBtn'
              disabled={loadingAuth}
              id='authorize-btn'
              label={canBeActivatedValue ? 'Authorize' : 'Deauthorize'}
              loading={loadingAuth}
              onClick={() => toggleModal(true)}
              style={{ marginLeft: 8, width: 152 }}
            />
          )}
        </div>
      </section>
      <main className={cx('content')}>
        <Details partner={partner} />
        <Config partner={partner} />
      </main>
    </div>
  );
};

export default TradingPartnerDetails;
