// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { isEmpty } from 'lodash';

// Internal
import { Button } from 'components';
import {
  Documents,
  Entities,
  InputValues,
  Manufacturer,
  Review,
} from '../Steps';
import { CustomerWithDocuments } from '../../types';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

const getSteps = () => [
  'MANUFACTURER',
  'DOCUMENTS',
  'INPUT VALUES',
  'CUSTOMERS',
  'REVIEW',
];

interface Props {
  change: (key: string, value: string) => void;
  customer: CustomerWithDocuments;
  errors: any;
  handleCancel: () => void;
  memberId: number;
  onSubmit: () => void;
  values: any;
}

const AddDocumentStepper: FC<Props> = (props) => {
  const { change, customer, errors, handleCancel, memberId, onSubmit, values } =
    props;
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const nextDisabled =
    !isEmpty(errors) ||
    (activeStep === 1 && !values.documentType) ||
    (activeStep === 1 &&
      values.documentType &&
      !Object.values(values.documentType).some((type) => type === true));

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <Manufacturer change={change} values={values} />;
      case 1:
        return <Documents values={values} />;
      case 2:
        return <InputValues change={change} values={values} />;
      case 3:
        return (
          <Entities customer={customer} memberId={memberId} values={values} />
        );
      default:
        return (
          <Review
            customer={customer}
            memberId={memberId}
            change={change}
            values={values}
          />
        );
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={cx('root')}>
      <div className={cx('row')}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={cx('buttonContainer')}>
          <Button
            color='secondary'
            dataTestId='backButton'
            label={activeStep === 0 ? 'Cancel' : 'Back'}
            onClick={activeStep === 0 ? handleCancel : handleBack}
            style={{
              width: 120,
            }}
            variant='outlined'
          />
          <Button
            dataTestId='submitButton'
            disabled={nextDisabled}
            label={activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            onClick={activeStep === steps.length - 1 ? onSubmit : handleNext}
            style={{
              marginLeft: 16,
              width: 120,
            }}
          />
        </div>
      </div>
      {getStepContent(activeStep)}
    </div>
  );
};

export default AddDocumentStepper;
