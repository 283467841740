// Internal
import { wrapSearchText } from 'query';
import { toMediledgerContractHeaderId } from '../../common/helpers/mlIdUtils';

export interface Filters {
  showValidWithNoWarning: boolean;
  showValidWithWarning: boolean;
  showInvalidWithError: boolean;
}

const showNoErrors = { has_errors: { _eq: false } };

const showValidSelected = {
  _and: [showNoErrors, { has_warnings: { _eq: false } }],
};

const showValidSelectedForContract = (contractMlId: string): any => ({
  _and: [showNoErrors, showNonWarningsForContract(contractMlId)],
});

const showInvalidSelected = { has_errors: { _eq: true } };

const showWarningSelected = {
  _and: [{ has_warnings: { _eq: true }, has_errors: { _eq: false } }],
};

const showNone = {
  _and: [{ has_errors: { _eq: true } }, { has_errors: { _eq: false } }],
};

const showWarningsForContract = (contractMlId: string): any => ({
  _or: [
    { has_non_contract_warnings: { _eq: true } },
    { contract_warnings: { _contains: contractMlId } },
  ],
});

const showNonWarningsForContract = (contractMlId: string): any => ({
  _not: showWarningsForContract(contractMlId),
});

export const getValidationConditions = (
  filters: Filters,
  contractId?: string,
  memberId?: number
): Record<string, any>[] => {
  const conditionsArray = [];

  const keys = Object.keys(filters);
  const allFiltersSelected = keys.every(
    (entry) => (filters as any)[entry] === true
  );
  const allFiltersNonSelected = keys.every((entry) => !(filters as any)[entry]);

  if (allFiltersSelected) {
    // Show all. No filter.
  } else if (allFiltersNonSelected) {
    // Show none.
    conditionsArray.push(showNone);
  } else {
    const condOr = [];
    if (filters.showInvalidWithError) condOr.push(showInvalidSelected);

    if (contractId && memberId) {
      const contractMlId = toMediledgerContractHeaderId(contractId, +memberId);

      if (filters.showValidWithNoWarning)
        condOr.push(showValidSelectedForContract(contractMlId));
      if (filters.showValidWithWarning) {
        condOr.push({
          _and: [showWarningsForContract(contractMlId), showNoErrors],
        });
      }
    } else {
      if (filters.showValidWithNoWarning) condOr.push(showValidSelected);
      if (filters.showValidWithWarning) condOr.push(showWarningSelected);
    }

    conditionsArray.push({ _or: condOr });
  }

  return conditionsArray;
};

export const filtersToQuery = (
  filters: Filters,
  filtersActive: boolean,
  searchText: string,
  contractId: string,
  memberId: number,
  isLatestContractCustomers: boolean
) => {
  const whereObject = {} as any;

  // Add memberId
  whereObject.member_id = { _eq: memberId };

  if (isLatestContractCustomers) {
    whereObject.list_ids = { _contains: contractId };
  } // For non-latest contract customers this filter is performed in a SQL function.

  if (searchText.length > 0) {
    whereObject._search_text = { _ilike: wrapSearchText(searchText) };
  }

  // Return default if no filters are selected
  if (!filtersActive) {
    return whereObject;
  }

  const conditionsArray = getValidationConditions(
    filters,
    contractId,
    memberId
  );

  if (conditionsArray.length > 0) {
    whereObject._and = conditionsArray;
  }

  return whereObject;
};
