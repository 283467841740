// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

// Internal
import {
  QUERY_CUSTOMER_LIST_BY_ID,
  QUERY_CUSTOMER_LISTS,
  QueryCustomerListResult,
  QueryCustomersListResult,
} from 'query';
import { IconSystemError } from 'styles/images';
import {
  useApi,
  useAppBarSetTitle,
  useSnackbar,
  useTradingPartnerConfig,
} from 'hooks';
import { Breadcrumb } from 'components';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import { generateID } from 'common/utils';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  truncateDatetime,
} from 'common/helpers';
import CreateCustomersListForm from './CreateEditCustomersListForm/container';
import { SimpleCustomer } from './EditCustomerList';
import { updateCustomerList } from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

interface Props {
  editOnFinish?: () => void;
  initialCustomers?: SimpleCustomer[];
  listId?: string;
}

const CreateEditCustomerList: FC<Props> = (props) => {
  const { editOnFinish, initialCustomers = [], listId = '' } = props;
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [createCustomerListId, setCreateCustomerListId] = useState('');
  const [postUpdateWhere, setPostUpdateWhere] = useState({});
  const [loadingApi, setLoadingApi] = useState(false);

  const { data: customerListResult, loading } =
    useQuery<QueryCustomerListResult>(QUERY_CUSTOMER_LIST_BY_ID, {
      variables: {
        id: listId,
        memberId,
      },
      skip: !(listId && memberId),
    });
  const customerList = customerListResult?.customer_list;

  // We query for the updated/created Customer List
  const { data: createdCustomerList } = useQuery<QueryCustomersListResult>(
    QUERY_CUSTOMER_LISTS,
    {
      variables: {
        where: postUpdateWhere,
      },
      skip: loadingApi || !(createCustomerListId && memberId),
      pollInterval: SHORT_POLL_INTERVAL,
      notifyOnNetworkStatusChange: true,
      onCompleted: (result) => {
        if (result.lists && result.lists.length > 0) {
          navigate(
            createCustomerListId
              ? `/customer-lists/${createCustomerListId}`
              : '/customer-lists',
            { replace: true }
          );
          snackbar.open({
            message: `Customer List ${createCustomerListId} has been ${
              listId ? 'updated' : 'created'
            }.`,
            variant: 'success',
          });
        }
      },
    }
  );

  const listCreated = (createdCustomerList?.lists?.length ?? 0) > 0;
  const creatingOrUpdating =
    loadingApi || !!(createCustomerListId && !listCreated);

  const api = useApi();

  useAppBarSetTitle(listId ? 'Update Customer List' : 'Create Customer List');

  const onSubmit = async (values: any) => {
    setLoadingApi(true);
    const newId = generateID();
    const targetListId = listId || newId;

    setCreateCustomerListId(targetListId);

    setPostUpdateWhere({
      member_id: { _eq: memberId },
      id: { _eq: targetListId },
      name: { _eq: values.name },
      description: { _eq: values.description ? values.description : null },
      start_date: { _eq: convertStartDateToUtcTimestamp(values.startDate) },
      end_date: { _eq: convertEndDateToUtcTimestamp(values.endDate) },
    });

    const params = {
      id: targetListId,
      name: values.name,
      type: 'contract',
      timestamp: new Date().toISOString(),
      startDate: convertStartDateToUtcTimestamp(values.startDate),
      endDate: convertEndDateToUtcTimestamp(values.endDate),
      description: values.description ? values.description : null,
      belongsTo: [],
      identifiers: [],
    };

    if (memberId) {
      api
        ?.createCustomerList(params)
        .then(() =>
          updateCustomerList(
            initialCustomers,
            values.customers,
            memberId,
            targetListId,
            api
          )
        )
        .finally(() => setLoadingApi(false));
    } else {
      // eslint-disable-next-line no-console
      console.warn('MemberId expected but not present');
      setLoadingApi(false);
    }
  };

  const createOnFinish = () => {
    navigate('/customer-lists', { replace: true });
  };

  const onFinish = editOnFinish || createOnFinish;

  return !memberId ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('root')}>
      <Breadcrumb
        crumbs={
          listId
            ? ['Customer Lists', listId, 'Update']
            : ['Customer Lists', 'Create Customer List']
        }
        links={
          listId
            ? ['/customer-lists', `/customer-lists/${listId}`]
            : ['/customer-lists']
        }
      />
      {!loading && (
        <CreateCustomersListForm
          createLoading={creatingOrUpdating}
          fetchedData={{
            description: customerList?.description,
            name: customerList?.name,
            startDate: truncateDatetime(customerList?.start_date),
            endDate: truncateDatetime(customerList?.end_date),
            customers: initialCustomers,
          }}
          handleOnSubmit={onSubmit}
          id={listId}
          onCancel={onFinish}
        />
      )}
    </div>
  );
};

export default CreateEditCustomerList;
