// Vendor
import gql from 'graphql-tag';

export const GET_CUSTOMER_PROPOSALS = gql`
  query CustomerProposals(
    $limit: Int
    $offset: Int
    $where: dash_customer_proposals_bool_exp!
    $order: [dash_customer_proposals_order_by!]
    $onlyTriggerSource: Boolean! = false
    $distinct: [dash_customer_proposals_select_column]
  ) {
    dash_customer_proposals(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
      distinct_on: $distinct
    ) {
      customerId: customer_id
      existing @skip(if: $onlyTriggerSource)
      impact @skip(if: $onlyTriggerSource)
      proposal @skip(if: $onlyTriggerSource)
      status
      timestamp
      triggerSource: trigger_source
    }
  }
`;

export const GET_CUSTOMER_PROPOSALS_COUNT = gql`
  query CustomerProposalsCount($where: dash_customer_proposals_bool_exp!) {
    dash_customer_proposals_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CUSTOMER_PROPOSAL = gql`
  query QueryCustomerProposal($id: String!, $timestamp: timestamptz!) {
    dash_customer_proposals(
      where: { customer_id: { _eq: $id }, timestamp: { _eq: $timestamp } }
    ) {
      customerId: customer_id
      existing
      impact
      proposal
      status
      timestamp
      triggerSource: trigger_source
    }
  }
`;

export const UPDATE_PROPOSAL_STATUS = gql`
  mutation UpdateCustomerProposal(
    $id: String!
    $status: String!
    $timestamp: timestamptz!
  ) {
    update_dash_customer_proposals(
      where: { customer_id: { _eq: $id }, timestamp: { _eq: $timestamp } }
      _set: { status: $status }
    ) {
      returning {
        customerId: customer_id
        status
        timestamp
      }
    }
  }
`;

export interface QueryProposalsRulesResult {
  dash_proposal_rules: {
    id: string;
    ruleData: {};
    proposals: boolean;
  }[];
}

export const QUERY_PROPOSAL_RULES = gql`
  query QueryProposalRules($where: dash_proposal_rules_bool_exp) {
    dash_proposal_rules(where: $where) {
      id
      ruleData: rule_data
      proposals
    }
  }
`;

export const INSERT_PROPOSAL_RULES = gql`
  mutation InsertProposalRules(
    $proposalRules: dash_proposal_rules_insert_input!
  ) {
    insert_dash_proposal_rules_one(
      object: $proposalRules
      on_conflict: {
        constraint: proposal_rules_pk
        update_columns: [rule_data, proposals]
      }
    ) {
      id
      ruleData: rule_data
      proposals
    }
  }
`;
