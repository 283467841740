// Vendor
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import React, { FC, useEffect, useMemo, useState } from 'react';

// Internal
import {
  CustomerViewRow,
  QUERY_CUSTOMERS_FOR_LIST,
  QUERY_CUSTOMERS_COUNT,
  QueryCustomerView,
  QueryCustomerViewCountResult,
} from 'query';
import { NUMBER_PER_PAGE } from 'common/constants';
import { useTradingPartnerConfig } from 'hooks';
import { TableRow } from 'components/Table';
import { Pagination, SearchBar, Table } from 'components';
import { withMemberId } from 'services/queryService';
import {
  filtersToQueryCustomersTable,
  getCustomersTableColumns,
  rawDataToRowsCustomersTable,
} from './service';
import { FormValues } from '../../service';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

interface Props {
  formUpdate: (...args: any[]) => any;
  listId: string;
  values: FormValues;
}

const CustomersTable: FC<Props> = (props) => {
  const { formUpdate, listId, values } = props;

  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const [order] = useState({ timestamp: 'desc' });
  const [currentPage, updateCurrentPage] = useState(1);
  const [searchText, updateSearchText] = useState('');
  const [where, updateWhere] = useState({});

  const {
    data: customersData,
    loading,
    refetch,
  } = useQuery<QueryCustomerView>(QUERY_CUSTOMERS_FOR_LIST, {
    variables: {
      where,
      order,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      limit: NUMBER_PER_PAGE,
      includeMemberships: true,
      membershipsWhere: {
        list_id: { _eq: listId },
        list: { member_id: { _eq: memberId } },
      },
    },
    skip: !memberId,
  });
  const customers: CustomerViewRow[] = useMemo(
    () => customersData?.customers || [],
    [customersData]
  );

  const { data: countData, loading: countLoading } =
    useQuery<QueryCustomerViewCountResult>(QUERY_CUSTOMERS_COUNT, {
      variables: { where },
      skip: !memberId,
    });

  const rows: TableRow[] = rawDataToRowsCustomersTable(
    customers,
    formUpdate,
    listId,
    values
  );

  useEffect(() => {
    updateCurrentPage(1);
    updateWhere(
      withMemberId(filtersToQueryCustomersTable(searchText), memberId)
    );
  }, [searchText]);

  // Refetch the customers to avoid cache
  useEffect(() => {
    refetch().then();
  });

  const hasCustomers = customers.length > 0;

  const isFiltered = searchText !== '';
  const disableSearchFilter = !loading && !hasCustomers && !isFiltered;

  return (
    <div>
      <div className={cx('row')}>
        <SearchBar
          className='customerSearch'
          placeholder='Search by ID, identifier, name, or address'
          onSubmit={(text) => updateSearchText(text)}
          disabled={disableSearchFilter}
        />
      </div>
      <Table
        columns={getCustomersTableColumns(values)}
        rows={rows}
        emptyText='No customers found'
        isLoading={loading}
      />
      <Pagination
        loading={countLoading}
        currentPage={currentPage}
        className={cx('pagination')}
        numberPerPage={NUMBER_PER_PAGE}
        isDisplayed={hasCustomers}
        totalCount={countData?.customerCount?.aggregate.count ?? -1}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
      />
    </div>
  );
};

export default CustomersTable;
