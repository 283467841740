// Vendor
import React, { FC } from 'react';

// Internal
import { CustomerFormProps } from 'pages/Customers/Create/constants';
import { useAppBarSetTitle } from 'hooks';
import Relationships from './Relationships';

interface Props {
  customError: string;
  setParentId: (parentId: string) => void;
  values: CustomerFormProps;
}

const Container: FC<Props> = ({ customError, setParentId, values }) => {
  useAppBarSetTitle('Create Customer');

  return (
    <Relationships
      customError={customError}
      setParentId={setParentId}
      values={values}
    />
  );
};

export default Container;
