// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { TableCell, TableRow } from '@material-ui/core';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { FormApi } from 'final-form';

// Internal
import { IconChevron } from 'styles/images';
import LinkCell from '../Cells/Link';
import ChipCell from '../Cells/Chip';
import DateCell from '../Cells/Date';
import Expanding from '../Cells/Expanding';
import ErrorCell from '../Cells/Errors';
import CheckBoxCell from '../Cells/CheckBox';
import { TableColumn } from '../Table';
import { ButtonCell } from '../Cells/Button';
import DateInputCell from '../Cells/DateInput';
import SelectInputCell from '../Cells/SelectInput';
import PriceInputCell from '../Cells/PriceInput';
import { MultipleButtonsCell } from '../Cells/MultipleButtonsCell';
import RadioCell from '../Cells/RadioInput';

// Styles
import styles from './index.module.css';
import muiStyles from './inlineStyles';

const cx = classnames.bind(styles);

export interface ExpandingRowInterface {
  data: Record<string, any>;
  type: 'expanding';
  isActiveRow?: boolean;
  isExpandable?: boolean;
  rowHoverBorder?: boolean;
  expandedInitially?: boolean;
}

export interface Props {
  order: number;
  classes: {
    hover: string;
    root: string;
  };
  columns: TableColumn[];
  data: Record<string, any>;
  isActiveRow?: boolean;
  isExpandable?: boolean;
  handleRowClick?: (data: any) => void;
  rowHoverBorder?: boolean;
  expandedInitially?: boolean;
  form?: FormApi;
}

const ExpandingRow: FC<Props> = (props) => {
  const {
    order,
    classes,
    columns,
    data,
    isActiveRow,
    isExpandable,
    handleRowClick,
    rowHoverBorder,
    expandedInitially,
    form,
  } = props;

  const [isExpanded, updateIsExpanded] = useState(expandedInitially ?? false);

  return (
    <TableRow
      classes={
        isActiveRow
          ? { root: classes.root }
          : rowHoverBorder
          ? { root: classes.hover }
          : {}
      }
    >
      {columns.map((column, i) => {
        switch (column.type) {
          case 'link':
            return (
              <LinkCell
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                displayTextFn={column.displayTextFn}
                urlFn={column.urlFn}
                data={data}
                size={column.size}
              />
            );
          case 'chip':
            return (
              <ChipCell
                data={get(data, column.dataField)}
                backgroundColor={get(data, column.colorField, '#f9bc60')}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                size={column.size}
                extraClass={column.extraClass}
              />
            );
          case 'checkBox':
            return (
              <CheckBoxCell
                checkedFn={column.checkedFn}
                data={data}
                disabledFn={column.disabledFn}
                handleOnChangeFn={column.handleOnChangeFn}
                idFn={column.idFn}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                size={column.size}
              />
            );
          case 'date':
            return (
              <DateCell
                data={get(data, column.dataField)}
                format={column.format}
                handleRowClick={() => {
                  if (handleRowClick) handleRowClick(data);
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                size={column.size}
              />
            );
          case 'errors':
            return (
              <ErrorCell
                disableHoverListener={column.disableHoverListener}
                handleRowClick={() => {
                  if (handleRowClick) handleRowClick(data);
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                severityErrors={get(data, column.errorsField)}
                severityWarnings={get(data, column.warningsField)}
                size={column.size}
              />
            );
          case 'button':
            return (
              <ButtonCell
                style={column.style}
                size={column.size}
                title={column.title}
                handleOnClickFn={column.handleOnClickFn}
                id={`test-btn-${get(data, 'id')}`}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                disabledFn={column.disabledFn}
                data={data}
              />
            );
          case 'multipleButtons':
            return (
              <MultipleButtonsCell
                data={data}
                buttonsData={column.buttonsData}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                size={column.size}
                rowOrder={order}
              />
            );
          // this is a new date input that is designed to be compatible with react-final-form
          case 'dateInput': {
            return (
              <DateInputCell
                fallbackDate={column.fallbackDate}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                disabledFn={column.disabledFn}
                form={form}
                formData={data}
                handleOnChangeFn={column.handleOnChangeFn}
                hiddenFn={column.hiddenFn}
                maxDate={column.maxDate}
                minDate={column.minDate}
                name={column.name && column.name(order)}
                nameFn={column.nameFn}
                placeholder={column.placeholder}
                size={column.size}
                validation={column.validation}
              />
            );
          }
          case 'priceInput': {
            return (
              <PriceInputCell
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                disabledFn={column.disabledFn}
                formData={data}
                name={column.name(order)}
                placeholder={column.placeholder}
                size={column.size}
                validation={column.validation}
              />
            );
          }
          case 'selectInput': {
            return (
              <SelectInputCell
                name={column.name(order)}
                items={column.items}
                placeholder={column.placeholder}
                validation={column.validation}
                specificFormData={column.specificFormData}
                formData={data}
                size={column.size}
                handleOnChangeFn={column.handleOnChangeFn}
                disabledFn={column.disabledFn}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
              />
            );
          }
          case 'radio': {
            return (
              <RadioCell
                checkedFn={column.checkedFn}
                data={data}
                disabledFn={column.disabledFn}
                handleOnChangeFn={column.handleOnChangeFn}
                idFn={column.idFn}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                nameFn={column.nameFn}
                size={column.size}
              />
            );
          }
          default:
            return (
              <Expanding
                style={column.style}
                data={get(data, column.dataField)}
                handleRowClick={() => {
                  if (handleRowClick) handleRowClick(data);
                }}
                isExpanded={isExpanded}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${i}`}
                size={column.size}
              />
            );
        }
      })}
      {isExpandable ? (
        <TableCell
          className={cx('arrow')}
          onClick={() => updateIsExpanded(!isExpanded)}
          data-testid='expandingRowExpandButton'
          size='small'
        >
          <IconChevron pointUp={isExpanded} />
        </TableCell>
      ) : (
        <TableCell
          onClick={() => {
            if (handleRowClick) handleRowClick(data);
          }}
          size='small'
        />
      )}
    </TableRow>
  );
};

// export default ExpandingRow;
export default withStyles(muiStyles)(ExpandingRow);
