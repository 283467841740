// Vendor
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import Paper from '@material-ui/core/Paper';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { get, isUndefined } from 'lodash';

// Internal
import { ValueTooltip } from 'components/Tooltips';
import {
  parseMediledgerId,
  truncateDatetime,
  toNamedIdentifier,
  isRosterOON,
} from 'common/helpers';
import { KeyValuePair } from 'components';
import { ContractViewRow, ParticipantType } from 'query';
import { DEFAULT_EMPTY_STATE } from 'common/constants';
import { ContractEntity } from 'types/generated/ContractEntity';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  contractOverview: ContractViewRow;
  memberDetails: any;
  participantType: ParticipantType | null;
  tradingPartners: any;
}

const Overview: FC<Props> = (props) => {
  dayjs.extend(isBetween);

  const { contractOverview, memberDetails, participantType, tradingPartners } =
    props;
  const {
    contract_entities = [],
    type,
    description = DEFAULT_EMPTY_STATE,
    end_date,
    list_id = '',
    list_member_id,
    open,
    start_date,
  } = contractOverview;

  const startDateTruncated = !isUndefined(start_date)
    ? truncateDatetime(start_date)
    : DEFAULT_EMPTY_STATE;
  const endDateTruncated = !isUndefined(end_date)
    ? truncateDatetime(end_date)
    : DEFAULT_EMPTY_STATE;

  const status = dayjs().isBetween(start_date, end_date)
    ? 'Active'
    : 'Inactive';

  const entityNames: string[] =
    contract_entities && contract_entities.length
      ? contract_entities.map((ent: ContractEntity) => {
          const { memberId } = parseMediledgerId(ent.id);
          if (memberId === 0 || isRosterOON(memberId)) {
            // This ID is not parsable, must be an OON ID
            return ent.id;
          } else {
            return tradingPartners?.[memberId]?.name ?? memberId;
          }
        })
      : [];

  const entityIds =
    contract_entities && contract_entities.length
      ? contract_entities
          .map((ent: ContractEntity) =>
            get(memberDetails[ent.id], 'modelNIds', []).concat(
              get(memberDetails[ent.id], 'ediId', [])
            )
          )
          .reduce((acc, curr) => acc.concat(curr), [])
          .map((i: string) => toNamedIdentifier(i))
      : [];

  const manufacturer = get(
    tradingPartners[list_member_id],
    'name',
    list_member_id
  );
  const openStatus = open === null ? '' : open ? 'Open' : 'Closed';

  const entityTooltip = (
    <ValueTooltip header='Contract Entity' values={entityNames} />
  );

  const entityIdsTooltip = (
    <ValueTooltip header='Contract Entity Identifiers' values={entityIds} />
  );

  const keyValuePairs = [
    { title: 'Contract ID', value: list_id },
    { title: 'Description', value: description },
    ...(participantType !== ParticipantType.gpo &&
    participantType !== ParticipantType.hs
      ? [
          {
            title: 'Contract Entity',
            value: entityNames[0],
            valueIcon: entityNames.length > 1 ? entityTooltip : undefined,
            valueLength: entityNames.length - 1,
          },
        ]
      : []),
    ...(participantType !== ParticipantType.gpo &&
    participantType !== ParticipantType.hs
      ? [
          {
            title: 'Contract Entity Identifiers',
            value: entityIds[0],
            valueIcon: entityIds.length > 1 ? entityIdsTooltip : undefined,
            valueLength: entityIds.length - 1,
          },
        ]
      : []),
    { title: 'Contract Start Date', value: startDateTruncated },
    { title: 'Contract End Date', value: endDateTruncated },
    ...(participantType !== ParticipantType.manufacturer
      ? [{ title: 'Manufacturer', value: manufacturer }]
      : []),
    { title: 'Contract Status', value: status },
    ...(participantType !== ParticipantType.gpo &&
    participantType !== ParticipantType.hs
      ? [
          {
            title: 'Contract Type',
            value: type,
          },
        ]
      : []),
    ...(participantType === ParticipantType.manufacturer
      ? [
          {
            title: 'Open/Closed',
            value: openStatus,
          },
        ]
      : []),
  ];

  return (
    <Paper className={cx('root')}>
      <div className={cx('column', 'paperContent')}>
        <div className={cx('row')}>
          <header className={cx('header')}>Overview</header>
        </div>
        <div className={cx('row')}>
          {keyValuePairs.map(
            ({ title = '', value, valueIcon, valueLength }) => (
              <KeyValuePair
                key={title}
                title={title}
                value={value}
                valueIcon={valueIcon}
                valueLength={valueLength}
              />
            )
          )}
        </div>
      </div>
    </Paper>
  );
};

export default Overview;
