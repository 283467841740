// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconList: FC<Props> = ({ color = '#011F42' }) => (
  <svg width='17' height='14' viewBox='0 0 17 14' fill='none'>
    <path
      d='M2 10.5C1.15625 10.5 0.5 11.1875 0.5 12C0.5 12.8438 1.15625 13.5 2 13.5C2.8125 13.5 3.5 12.8438 3.5 12C3.5 11.1875 2.8125 10.5 2 10.5ZM2 5.5C1.15625 5.5 0.5 6.1875 0.5 7C0.5 7.84375 1.15625 8.5 2 8.5C2.8125 8.5 3.5 7.84375 3.5 7C3.5 6.1875 2.8125 5.5 2 5.5ZM2 0.5C1.15625 0.5 0.5 1.1875 0.5 2C0.5 2.84375 1.15625 3.5 2 3.5C2.8125 3.5 3.5 2.84375 3.5 2C3.5 1.1875 2.8125 0.5 2 0.5ZM16 1.25H6C5.71875 1.25 5.5 1.5 5.5 1.75V2.25C5.5 2.53125 5.71875 2.75 6 2.75H16C16.25 2.75 16.5 2.53125 16.5 2.25V1.75C16.5 1.5 16.25 1.25 16 1.25ZM16 6.25H6C5.71875 6.25 5.5 6.5 5.5 6.75V7.25C5.5 7.53125 5.71875 7.75 6 7.75H16C16.25 7.75 16.5 7.53125 16.5 7.25V6.75C16.5 6.5 16.25 6.25 16 6.25ZM16 11.25H6C5.71875 11.25 5.5 11.5 5.5 11.75V12.25C5.5 12.5312 5.71875 12.75 6 12.75H16C16.25 12.75 16.5 12.5312 16.5 12.25V11.75C16.5 11.5 16.25 11.25 16 11.25Z'
      fill={color}
    />
  </svg>
);

export default IconList;
