// Vendor
import { Dictionary, get } from 'lodash';

// Internal
import { NodeType } from 'common/config';
import { parseMediledgerId } from 'common/helpers';
import { TableColumn, TableRow } from 'components/Table';
import { ContractEntity } from 'types/generated/ContractEntity';
import { TradingPartner } from 'query';
import {
  CONTRACT_ENTITY_COLUMN,
  MANUFACTURER_COLUMN,
} from 'components/Cells/constants';
import { ContractsForView } from './index';

export const getColumns = (tpType: NodeType | undefined): TableColumn[] => {
  const distributors: TableColumn = {
    dataField: 'distributors',
    headerName: 'DISTRIBUTOR(S)',
    size: 'small',
    type: 'expanding',
  };

  return [
    {
      displayTextFn: (data: any) => data.description,
      headerName: 'DESCRIPTION',
      size: 'medium',
      type: 'link',
      urlFn: (data: any) =>
        `/contracts/${data.memberId}/${encodeURIComponent(data.id)}`,
    },
    {
      dataField: 'startDates',
      headerName: 'START DATE',
      size: 'small',
      type: 'expanding',
    },
    {
      dataField: 'endDates',
      headerName: 'END DATE',
      size: 'small',
      type: 'expanding',
    },
    ...(tpType !== 'GPO' ? [CONTRACT_ENTITY_COLUMN] : []),
    ...(tpType !== 'MFR' ? [MANUFACTURER_COLUMN] : []),
    ...(tpType !== 'DIST' ? [distributors] : []),
  ];
};

export const getRows = (
  contracts: ContractsForView[],
  tradingPartners: Dictionary<TradingPartner>
): TableRow[] => {
  if (contracts.length === 0) return [];

  const getEntityNames = (entities: ContractEntity[] | undefined) =>
    entities?.map((ent) =>
      get(tradingPartners[parseMediledgerId(ent.id).memberId], 'name', ent.id)
    ) || [];

  return contracts.map((contract: any) => {
    const {
      contract_entities,
      description,
      distributors,
      endDates,
      list_id,
      list_member_id,
      startDates,
    } = contract;

    const contractEntityNames = getEntityNames(contract_entities);
    const manufacturer = get(
      tradingPartners[list_member_id],
      'name',
      list_member_id
    );
    const distNames = getEntityNames(distributors);

    const isExpandable =
      startDates.length > 1 ||
      endDates.length > 1 ||
      distNames.length > 1 ||
      contractEntityNames.length > 1;

    return {
      isExpandable,
      data: {
        description,
        startDates,
        endDates,
        contractEntities: contractEntityNames,
        manufacturer,
        distributors: distNames,
        id: list_id,
        memberId: list_member_id,
      },
      type: 'expanding',
    };
  });
};
