// Vendor
import React from 'react';
import { Field } from 'react-final-form';

// Internal
import { TableColumn, TableRow } from 'components/Table';
import { COLUMNS as PRODUCT_LIST_COLUMNS } from 'pages/ProductLists/List/service';
import { QueryProductListsResult } from 'query';
import { Checkbox } from 'components';
import { ContractFormProps, FormList } from 'pages/Contracts/Create/constants';
import { removeUncheckedLists } from 'pages/Contracts/Create/service';

// re-export filtersToQuery
export { filtersToQuery as productListsFiltersToQuery } from 'pages/ProductLists/List/service';

export const getColumns = (): TableColumn[] => [
  {
    dataField: 'checkbox',
    headerName: '',
    size: 'small',
    type: 'expanding',
  },
  ...PRODUCT_LIST_COLUMNS,
];

export const rawDataToRows = (
  formRemoveBatch: (...args: any[]) => any,
  formUpdate: (...args: any[]) => any,
  productLists: QueryProductListsResult,
  values: ContractFormProps
): TableRow[] => {
  if (!productLists || !productLists.productLists) {
    return [];
  }

  return productLists.productLists.map((list, i) => {
    const isChecked = values.productLists && values.productLists[i]?.checked;

    return {
      data: {
        id: list.id,
        names: list.name,
        description: list.description,
        timestamp: list.revisedAt,
        checkbox: (
          <Field
            checked={isChecked}
            // @ts-ignore
            component={Checkbox}
            handleOnChange={() => {
              if (values.productLists && !values.productLists[i]?.checked) {
                formUpdate('productLists', i, {
                  checked: true,
                  id: list.id,
                });
              } else {
                // If we uncheck all product lists, all existing products must be removed
                const productListsWithoutCurrent = values.productLists?.filter(
                  (l: FormList) => l.id !== list.id
                );
                const areAnyProductListsChecked = removeUncheckedLists(
                  productListsWithoutCurrent
                );
                /* values.products might be undefined if we unselected all the product lists with products,
                   this is a side effect from mutators */
                const shouldRemoveProducts =
                  areAnyProductListsChecked.length === 0 &&
                  (values.products?.length || 0) > 0;
                if (shouldRemoveProducts) {
                  const indexesToRemove: number[] = values.products.map(
                    (_, i) => i
                  );
                  formRemoveBatch('products', indexesToRemove);
                }

                formUpdate('productLists', i, {
                  checked: false,
                  id: list.id,
                });
              }
            }}
            name={`productLists.${i}.checked}`}
            type='checkbox'
          />
        ),
      },
      isExpandable: false,
      type: 'expanding',
    };
  });
};

/**
 * Get a where clause for the query getProductsForList
 * @param productListsIDs - IDs of the product lists
 * @param startDate - start date of the contract
 * @param endDate - end date of the contract
 */
export const getProductsForListWhere = (
  productListsIDs: string[],
  startDate: string,
  endDate: string
) => ({
  active: { _eq: true },
  kind: { _eq: 'product-association' },
  to_id_full: { _in: productListsIDs },
  product: {
    _and: [
      {
        _or: [
          { start_date: { _is_null: true } },
          { start_date: { _lte: endDate } },
        ],
      },
      {
        _or: [
          { end_date: { _is_null: true } },
          { end_date: { _gte: startDate } },
        ],
      },
    ],
  },
});
