// Vendor
import React from 'react';

const IconErrorRed = () => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd'>
      <circle cx='12' cy='12' r='12' fill='#CB6671' />
      <path
        fill='#FFF'
        fillRule='nonzero'
        d='M8.992 16.686l3.383-3.383 2.804 2.804.579.579c.085.085.224.085.309 0l.619-.62c.085-.084.085-.223 0-.308l-3.383-3.383 2.804-2.804.579-.579c.085-.085.085-.224 0-.309l-.62-.619c-.084-.085-.223-.085-.308 0l-3.383 3.383-3.383-3.383c-.085-.085-.224-.085-.309 0l-.619.619c-.085.085-.085.224 0 .309l3.383 3.383-3.383 3.383c-.085.085-.085.224 0 .309l.619.619c.085.085.224.085.309 0z'
      />
    </g>
  </svg>
);

export default IconErrorRed;
