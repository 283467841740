// Vendor
import React from 'react';

const IconOriginal = () => (
  <svg width='13' height='17' viewBox='0 0 13 17'>
    <path
      className='iconOriginal'
      d='M12.949 4.035v-.19c0-.197-.079-.388-.22-.529L9.67.254c-.14-.14-.33-.219-.53-.219h-.191v4h4zm-.75 12c.415 0 .75-.334.75-.75V5.035h-4.25c-.413 0-.75-.337-.75-.75V.035h-6.25c-.416 0-.75.335-.75.75v14.5c0 .416.334.75.75.75h10.5zm-6.5-13h-2.5c-.139 0-.25-.112-.25-.25v-.5c0-.138.111-.25.25-.25h2.5c.138 0 .25.112.25.25v.5c0 .138-.112.25-.25.25zm0 2h-2.5c-.139 0-.25-.112-.25-.25v-.5c0-.138.111-.25.25-.25h2.5c.138 0 .25.112.25.25v.5c0 .138-.112.25-.25.25zm4.75 6h-7c-.277 0-.5-.224-.5-.5v-3c0-.276.223-.5.5-.5h7c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5zm-.5-1v-2h-6v2h6zm.75 4h-2.5c-.139 0-.25-.112-.25-.25v-.5c0-.138.111-.25.25-.25h2.5c.138 0 .25.112.25.25v.5c0 .138-.112.25-.25.25z'
    />
  </svg>
);

export default IconOriginal;
