export const CSV_TYPE = 'data:text/comma-separated-values,';
export const TSV_TYPE = 'data:text/tab-separated-values,';

export const onDownload = (data: any, fileName: string, type: string) => {
  const link = window.document.createElement('a');
  link.href = URL.createObjectURL(new Blob([data], { type }));
  link.download = fileName;
  window.document.body.appendChild(link);
  link.click();
};
