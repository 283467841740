import {
  QUERY_LOCAL_CUSTOMER_DETAILS,
  QUERY_LOCAL_CUSTOMER_DETAILS_LATEST,
  QUERY_THIRD_PARTY_CUSTOMER_DETAILS,
} from 'query';

export function getDetailsQuery(
  isLocalCustomer: boolean,
  isLatestRevision: boolean
) {
  if (isLocalCustomer) {
    if (isLatestRevision) return QUERY_LOCAL_CUSTOMER_DETAILS_LATEST;
    return QUERY_LOCAL_CUSTOMER_DETAILS;
  }
  return QUERY_THIRD_PARTY_CUSTOMER_DETAILS;
}
