// Internal
import { CreditMemoRow, QueryClaimLines, wrapSearchText } from 'query';
import { TableRow } from 'components/Table';
import { formatCurrency } from 'common/helpers';
import {
  getChargebackStatus,
  statusCondition,
} from '../../Chargebacks/List/service';

export interface ClaimLine {
  chargebackId: string;
  saleId?: string;
  chargebackType?: string;
  amount?: string;
  contractId?: string;
  saleDate?: string;
  status: string;
  statusChipColor: string;
  mfrId: number;
  distId: number;
}

export function rawDataToRows(
  creditNote: CreditMemoRow,
  queryResult?: QueryClaimLines
): TableRow[] {
  if (
    !queryResult ||
    !queryResult.claimLines ||
    queryResult.claimLines.length === 0
  ) {
    return [];
  }

  return queryResult.claimLines.map((clRow) => {
    const { chargeback } = clRow;
    // Response 0 is the latest one due to the order in the query
    const lastResponse = chargeback?.responses
      ? chargeback.responses[0]
      : undefined;
    const { status, statusChipColor } = getChargebackStatus(
      chargeback?.errors ?? [],
      false,
      lastResponse?.kind
    );

    const amount = status === 'Rejected' ? 0 : chargeback?.amount || 0;

    return {
      type: 'expanding',
      data: <ClaimLine>{
        chargebackId: clRow.chargeback_id,
        saleId: chargeback?.sale?.id,
        chargebackType: chargeback?.operation,
        amount: formatCurrency(amount),
        contractId: clRow.contract_id ?? chargeback?.contract.list_id,
        saleDate: chargeback?.sale.invoice_date,
        status,
        statusChipColor,
        mfrId: creditNote.manufacturer_member_id,
        distId: creditNote.distributor_member_id,
      },
      isExpandable: false,
    };
  });
}

export function buildWhereClause(
  filters: string[],
  searchTerm: string,
  creditMemoId: string
): any {
  const wrappedSearchTerm = wrapSearchText(searchTerm);

  return {
    _or: [
      { chargeback_id: { _ilike: wrappedSearchTerm } },
      { chargeback: { contract_id: { _ilike: wrappedSearchTerm } } },
    ],
    credit_note_id: { _eq: creditMemoId },
    chargeback: statusCondition(filters),
  };
}
