// Vendor
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { isUndefined } from 'lodash';

import { RawAuditRequest } from 'types/generated/RawAuditRequest';
import { RawAuditReply } from 'types/generated/RawAuditReply';
import { RawOverrideRequest } from 'types/generated/RawOverrideRequest';
import { RawOverrideReply } from 'types/generated/RawOverrideReply';

// Internal
import { Program } from 'types/generated/Clang';
import { ApiService, AuthService } from 'actions';
import {
  APP_BAR_TITLE_RESET,
  APP_BAR_TITLE_SET,
  SNACKBAR_HIDE,
  SNACKBAR_SHOW,
} from 'actions/types';
import { AppBarState } from 'reducers/state';
import { QueryNetwork, GET_NETWORK_UPGRADE } from 'query';
import { RootState } from '../reducers';
import {
  DocumentApiArgs,
  MappingAssociation,
  NodesHealthResponse,
  ProposalGenerationData,
} from './types';

export * from './config';
export * from './types';

/**
 * React hook that executes callback on specified interval.
 * Copied from: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback
 * @param interval
 */
export const useInterval = (callback: () => any, interval: number | null) => {
  const callbackRef = useRef<() => any>();

  // Remember the latest callback function.
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (callbackRef.current) {
        callbackRef.current();
      }
    }

    if (interval !== null) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    } else {
      return () => {};
    }
  }, [interval]);
};

export const authServiceSelector = (state: Partial<RootState>) =>
  state?.service?.auth as AuthService;

// React hook that returns AuthService instance from Redux store. Beware: this instance may change often.
export const useAuthService = () =>
  useSelector<RootState, AuthService | undefined>(authServiceSelector);

/**
 * React hook that returns ApiService instance from Redux store.
 * This will be obsolete in the future, please use useApi() hook instead.
 */
export const useApiService = () =>
  useSelector<RootState, ApiService | undefined>(
    (state) => state?.service?.api as ApiService
  );

// React hook that returns API instance
export const useApi = () => {
  const apiService = useApiService();

  const createInstance = () => {
    if (!apiService) {
      if (process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.warn('useApi: apiService is null');
      }
      return undefined;
    }

    return {
      createCustomer: (data: {}) =>
        apiService.post(`/v2/customer-management/upsert-customer`, data),
      shareProposal: (data: {}) =>
        apiService.post(`/v1/customer_proposal/share`, data),
      documents: (data: DocumentApiArgs) =>
        apiService.post(`/v1/documents`, data),
      exportContractCustomersGenerate: (data: any) =>
        apiService.post(`/v1/export/contract-customers`, data),
      exportContractHeaderGenerate: (data: any) =>
        apiService.post(`/v1/export/contract-header`, data),
      exportContractPricingGenerate: (data: any) =>
        apiService.post(`/v1/export/contract-pricing`, data),
      exportCustomersGenerate: (data: any) =>
        apiService.post(`/v1/export/customer`, data),
      exportStatus: (exportId: string) =>
        apiService.get(`/v1/export/status?export-id=${exportId}`),
      exportDownload: (downloadPath: string) =>
        apiService.get(`/v1/export/file/${downloadPath}`, undefined, {
          responseType: 'blob',
        }),
      tradingPartnerAuthorize: (dto: any) =>
        apiService.post(`/v2/trading-partner/authorize`, dto),
      tradingPartnerDeauthorize: (dto: any) =>
        apiService.post(`/v2/trading-partner/deauthorize`, dto),
      uploadCSV: (file: File) => {
        const formData = new FormData();
        formData.append('data', file);
        // Add the filename as a header
        const headers = {
          filename: file.name,
        };
        return apiService.post(`/v1/upload/csv`, formData, headers);
      },
      upsertCustomerAssociation: (payload: MappingAssociation) =>
        apiService.post(`/v1/customer-management/upsert-association`, payload),
      upsertContractAssociation: (payload: MappingAssociation) =>
        apiService.post(`/v1/contract/association/upsert`, payload),
      customerProposalGenerate: (data: any) =>
        apiService.post<ProposalGenerationData>(
          `/v1/customer-proposal/generate`,
          data
        ),
      createCustomerList: (data: any) =>
        apiService.post(`/v1/customer-management/upsert-customer-list`, data),
      checkNodeHealthStatus: (nodeId: number) =>
        apiService.post<NodesHealthResponse>(
          `/v1/cbk-admin/cmi-connectivity-test`,
          { targetPeers: [nodeId] }
        ),
      createProduct: (data: any) =>
        apiService.post(`/v1/contract/product/upsert`, data),
      createProductList: (data: any) =>
        apiService.post(`/v1/contract/product-list/upsert`, data),
      createContractHeader: (data: any) =>
        apiService.post(`/v2/contract/header/upsert`, data),
      createContractPricing: (data: any) =>
        apiService.post(`/v2/contract/pricing/upsert`, data),
      generateFlatFileToken: (data: any) =>
        apiService.post<{ token: string }>(`/v1/flatfile/auth`, data),
      upsertChargebackOverride: (data: RawOverrideRequest) =>
        apiService.post(`/v2/chargebacks/override/upsert`, data),
      upsertChargebackOverrideReply: (data: RawOverrideReply) =>
        apiService.post(`/v2/chargebacks/override/reply`, data),
      upsertChargebackAudit: (data: RawAuditRequest) =>
        apiService.post(`/v2/chargebacks/audit/upsert`, data),
      upsertChargebackAuditReply: (data: RawAuditReply) =>
        apiService.post(`/v2/chargebacks/audit/reply`, data),
      upsertClangProgram: (data: Program) => apiService.post('/v1/clang', data),
    };
  };

  // useMemo: only create a new instance when dependencies change
  return useMemo(createInstance, [apiService]);
};

// React hook that returns Snackbar instance
export const useSnackbar = () => {
  const dispatch = useDispatch();

  const createInstance = () => ({
    open: (payload: any) => {
      dispatch({
        type: SNACKBAR_SHOW,
        payload,
      });
    },
    close: () => {
      dispatch({ type: SNACKBAR_HIDE });
    },
  });

  // useMemo: only create a new instance when dependencies change
  return useMemo(createInstance, [dispatch]);
};

// React hook that returns AppBar instance
export const useAppBar = () => {
  const dispatch = useDispatch();
  const appBar = useSelector<RootState, AppBarState | undefined>(
    (state) => state?.common?.appBar
  );
  const title = appBar?.title || null;

  const createInstance = () => ({
    title,
    setTitle: (title: string) => {
      dispatch({
        type: APP_BAR_TITLE_SET,
        payload: title,
      });
    },
    resetTitle: () => {
      dispatch({ type: APP_BAR_TITLE_RESET });
    },
  });

  // useMemo: only create a new instance when dependencies changes
  return useMemo(createInstance, [dispatch, title]);
};

/**
 * React hook that sets title when it changes, and resets title when component unmounts
 * @param title
 */
export const useAppBarSetTitle = (title: string) => {
  const appBar = useAppBar();

  useEffect(() => {
    appBar.setTitle(title);
    return () => appBar.resetTitle();
  }, [appBar, title]);
};

// React hook that returns if network upgrade is after Bush
export const useIsAfterBushNetwork = (timestamp = '', revised_at = '') => {
  const { data: networkData = {}, loading: networkLoading } =
    useQuery<QueryNetwork>(GET_NETWORK_UPGRADE);
  const networkTimestamp = networkData?.dash_networkUpgrade_by_pk?.timestamp;

  const entityTimestamp = timestamp || new Date().toISOString();
  const entityRevisedAt = revised_at || new Date().toISOString();

  const isAfterBush =
    !networkLoading &&
    !isUndefined(networkTimestamp) &&
    (dayjs(entityTimestamp) >= dayjs(networkTimestamp) ||
      dayjs(entityRevisedAt) >= dayjs(networkTimestamp));

  return {
    isAfterBush,
    networkLoading,
  };
};

export const useIsFirstRender = () => {
  const value = useRef(true);
  useLayoutEffect(() => {
    value.current = false;
  }, []);
  return value.current;
};
