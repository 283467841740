// Vendor
import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

// Internal
import { Button, Table } from 'components';
import { useApi, useAppBarSetTitle, useSnackbar } from 'hooks';
import {
  INSERT_PROPOSAL_RULES,
  QUERY_PROPOSAL_RULES,
  QueryProposalsRulesResult,
} from 'query';
import { COLUMNS, triggers as triggersProp } from './constants';
import { getProgram, getRows } from './services';
import { TriggersType } from './types';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

const Triggers = () => {
  const api = useApi();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  useAppBarSetTitle('Triggers');

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [triggers, setTriggers] = useState<TriggersType[]>(triggersProp);

  const [insertProposalRules] = useMutation(INSERT_PROPOSAL_RULES);

  const { data, loading, refetch } = useQuery<QueryProposalsRulesResult>(
    QUERY_PROPOSAL_RULES,
    {
      variables: {},
    }
  );

  useEffect(() => {
    if (loading) return;
    const triggersWithProposals = triggers.map((trigger) => {
      const proposalRule = data?.dash_proposal_rules?.find(
        (pr) => pr.id === trigger.id
      );
      return {
        ...trigger,
        proposals: proposalRule?.proposals || false,
        ruleData: proposalRule?.ruleData || {},
      };
    });
    setTriggers(triggersWithProposals);
    getRows(triggersWithProposals);
  }, [data]);

  const handleCheckbox = (id: string) => {
    const newTriggers = triggers.map((trigger) => {
      if (trigger.id === id) {
        return {
          ...trigger,
          proposals: !trigger.proposals,
        };
      } else {
        return trigger;
      }
    });
    setTriggers(newTriggers);
  };

  const handleSave = () => {
    setIsSaving(true);
    triggers.forEach(async (trigger, i) => {
      const { id, proposals, ruleData } = trigger;
      const { edges = [], nodes = [] } = ruleData;

      insertProposalRules({
        variables: {
          proposalRules: {
            id,
            proposals,
            rule_data: ruleData,
          },
        },
      }).then(() => {
        if (i === triggers.length - 1) setIsSaving(false);
      });

      const program = await getProgram(
        proposals ? edges : [],
        proposals ? nodes : [],
        snackbar,
        id
      );

      if (program) api?.upsertClangProgram(program);
    });
  };

  useEffect(() => {
    refetch().then();
  }, []);

  return (
    <>
      <div className={cx('buttons')}>
        <Button
          color='secondary'
          dataTestId='cancelBtn'
          disabled={isSaving}
          label='Cancel'
          onClick={() => {
            navigate('/customer-proposals', { replace: true });
          }}
          style={{ marginRight: 8 }}
          variant='outlined'
        />
        <Button
          dataTestId='saveBtn'
          disabled={isSaving}
          label='Submit'
          loading={isSaving}
          onClick={() => handleSave()}
        />
      </div>
      <Table
        columns={COLUMNS((id: string) => handleCheckbox(id))}
        rows={getRows(triggers)}
        emptyText='No triggers found'
        isLoading={loading}
      />
    </>
  );
};

export default Triggers;
