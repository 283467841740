// Vendor
import React, { FC } from 'react';

// Internal
import { Document } from 'types/generated/Document';
import DocumentDetails from './DocumentDetails';
import { CustomerWithDocuments } from '../types';

interface Props {
  changeSection: (s: string) => void;
  customer: CustomerWithDocuments;
  isHistoryView: boolean;
  selectedDocument: Document;
  setDocument: (doc: Document) => void;
  toggleDialog: (val: boolean) => void;
  updateDeletePayload: (payload: {
    documentType: string;
    manufacturer: string;
  }) => void;
}

const Container: FC<Props> = ({
  changeSection,
  customer,
  isHistoryView,
  selectedDocument,
  setDocument,
  toggleDialog,
  updateDeletePayload,
}) => (
  <DocumentDetails
    changeSection={changeSection}
    customer={customer}
    isHistoryView={isHistoryView}
    selectedDocument={selectedDocument}
    setDocument={setDocument}
    toggleDialog={toggleDialog}
    updateDeletePayload={updateDeletePayload}
  />
);

export default Container;
