// Vendor
import React from 'react';

const IconInvalidChargeback = () => (
  <svg width='13' height='17' viewBox='0 0 13 17'>
    <g fill='none' fillRule='evenodd'>
      <g fill='#CB6671' fillRule='nonzero'>
        <path
          d='M21.252 24c.413 0 .748-.335.748-.748V13h-4.25c-.413-.001-.749-.337-.75-.75V8h-6.252c-.413 0-.748.335-.748.748v14.504c0 .413.335.748.748.748h10.504zM22 12v-.19c0-.171-.098-.408-.219-.529l-3.06-3.062c-.12-.121-.357-.219-.529-.219H18v4h4zm-4.232 8.621c-.115 0-.273-.065-.354-.146L16 19.061l-1.414 1.414c-.08.08-.24.146-.354.146-.114 0-.272-.065-.353-.146l-.354-.354c-.08-.08-.146-.239-.146-.353 0-.115.065-.273.146-.354L14.939 18l-1.414-1.414c-.08-.08-.146-.24-.146-.354 0-.114.065-.272.146-.353l.354-.354c.08-.08.239-.147.353-.147.115 0 .273.066.354.147L16 16.939l1.414-1.414c.08-.08.24-.147.354-.147.114 0 .272.066.353.147l.354.354c.08.08.146.239.146.353 0 .115-.065.273-.146.354L17.061 18l1.414 1.414c.08.08.146.24.146.354 0 .114-.065.272-.146.353l-.354.354c-.08.08-.239.146-.353.146z'
          transform='translate(-554 -880) translate(289 849) translate(231) translate(24.467 23.035)'
        />
      </g>
    </g>
  </svg>
);

export default IconInvalidChargeback;
