// Vendor
import { pipe } from 'fp-ts/function';
import { findFirst } from 'fp-ts/lib/Array';
import { getOrElse, map } from 'fp-ts/lib/Option';

// Internal
import {
  CustomerDetailsList,
  QueryClassOfTradeResult,
  QueryCustomerViewInCustomerLists,
  wrapSearchText,
} from 'query';
import { TableRow } from 'components/Table';
import {
  decorateAndPartitionCustomerErrorsBySeverity,
  displayAddress,
  formatIdentifiers,
  getValidCustomerCOTFields,
  truncateDatetime,
} from 'common/helpers';
import { Filters, getValidationConditions } from 'pages/Contracts/service';

export const rawDataToRows = (
  listId: string,
  customersQueryResult?: QueryCustomerViewInCustomerLists,
  cotQueryResult?: QueryClassOfTradeResult
): TableRow[] => {
  if (
    !customersQueryResult ||
    !customersQueryResult.customers ||
    customersQueryResult.customers.length === 0
  ) {
    return [];
  }

  return (customersQueryResult ?? []).customers.map(({ customer }) => {
    const { id } = customer;
    const identifiers = customer.identifiers
      ? formatIdentifiers(customer.identifiers)
      : [];
    const addresses =
      customer.addresses?.map((address) => displayAddress(address)) ?? [];
    const names = customer.names ?? [];
    const cots = getValidCustomerCOTFields(customer, cotQueryResult);
    const { severityErrors, severityWarnings } =
      decorateAndPartitionCustomerErrorsBySeverity(customer);
    const { sDate, eDate } = pipe(
      customer.memberships ?? [],
      findFirst(
        (membership: CustomerDetailsList) => membership.listId === listId
      ),
      map((membership: CustomerDetailsList) => ({
        sDate: membership.startDate,
        eDate: membership.endDate,
      })),
      map(({ sDate, eDate }) => ({
        sDate: truncateDatetime(sDate),
        eDate: truncateDatetime(eDate),
      })),
      getOrElse(
        () =>
          ({ sDate: '', eDate: '' } as {
            sDate: string | null;
            eDate: string | null;
          })
      )
    );

    const isExpandable =
      identifiers?.length > 1 ||
      names.length > 1 ||
      addresses.length > 1 ||
      cots?.length > 1;

    return {
      type: 'expanding',
      isExpandable,
      data: {
        id,
        names,
        identifiers,
        addresses,
        cots,
        startDate: customer.startDate,
        endDate: customer.endDate,
        membershipStartDate: sDate,
        membershipEndDate: eDate,
        timestamp: customer.timestamp,
        severityErrors,
        severityWarnings,
      },
    };
  });
};

export const filtersToQuery = (
  searchText: string,
  listId: string,
  listMemberId: number | undefined,
  filtersActive: boolean,
  filters?: Filters
): Record<string, any> => {
  const whereObject: Record<string, any> = {
    list_id: { _eq: listId },
    customer: {
      _search_text: {
        _ilike: wrapSearchText(searchText),
      },
    },
  };

  if (listMemberId) {
    whereObject.list_member_id = { _eq: listMemberId };
  }

  if (!filtersActive) {
    return whereObject;
  }

  const validationConditions = getValidationConditions(filters as Filters);
  whereObject.customer = {
    ...whereObject.customer,
    ...(validationConditions.length === 0
      ? {}
      : {
          _and: validationConditions,
        }),
  };

  return whereObject;
};
