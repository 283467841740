// Vendor
import React from 'react';

const IconRebill = () => (
  <svg width='12' height='16' viewBox='13.685 11.339 13.422 17.465'>
    <defs>
      <filter
        id='IconRebillA'
        width='111.2%'
        height='145.7%'
        x='-5.6%'
        y='-22.1%'
        filterUnits='objectBoundingBox'
      >
        <feOffset dx='6' dy='7' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
          stdDeviation='10'
        />
        <feColorMatrix
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
          values='0 0 0 0 0.48627451 0 0 0 0 0.560784314 0 0 0 0 0.592156863 0 0 0 0.2 0'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
    </defs>
    <g
      fill='#00BC96'
      filter='url(#IconRebillA)'
      transform='translate(-252 -20)'
    >
      <path d='M273.467 32.035v4.25c0 .413.338.75.75.75h4.25v10.25c0 .416-.334.75-.75.75h-10.5c-.416 0-.75-.334-.75-.75v-14.5c0-.415.334-.75.75-.75h6.25zm-.995 6.844c-1.603-.003-2.911 1.303-2.911 2.906 0 1.605 1.301 2.906 2.906 2.906.75 0 1.432-.283 1.947-.749.06-.054.063-.147.006-.204l-.465-.465c-.052-.052-.136-.055-.192-.006-.358.315-.814.487-1.296.487-1.1 0-1.96-.89-1.969-1.952-.009-1.119.904-1.995 1.987-1.986.497.005.965.192 1.326.531l-.489.49c-.177.176-.052.48.199.48h1.571c.155 0 .281-.127.281-.282v-1.57c0-.251-.303-.377-.48-.2l-.418.419c-.52-.498-1.226-.804-2.003-.805zm2.186-6.844c.2 0 .39.078.53.219l3.06 3.062c.14.141.219.332.219.529v.19h-4v-4z' />
    </g>
  </svg>
);

export default IconRebill;
