// Vendor
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

// Internal
import {
  GET_CONTRACT_HEADER,
  GET_CONTRACT_PRICINGS,
  QUERY_ASSOCIATIONS,
  QueryAssociationsResult,
  QueryContractPricingsResult,
} from 'query';
import { parseMediledgerId, truncateDatetime } from 'common/helpers';
import { useTradingPartnerConfig } from 'hooks';
import { NUMBER_PER_PAGE } from 'common/constants';
import { IconSystemError } from 'styles/images';
import { convertPricingRowToContractProduct } from 'pages/Contracts/Create/service';
import Create from './container';
import {
  ContractFormProps,
  Distributor,
  emptyFormObject,
  openClose,
} from './constants';

// Styles
import styles from './EditContract.module.css';

const cx = classnames.bind(styles);

const EditContract = () => {
  const { id: contractId } = useParams() as { id: string };
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const [timestampHour] = useState(new Date().toISOString());

  // fetch contract header
  const {
    data: contractHeader,
    loading: contractHeaderLoading,
    error: contractHeaderError,
    refetch: contractHeaderRefetch,
  } = useQuery<{ dash_contract_headers_history?: any[] }>(GET_CONTRACT_HEADER, {
    variables: {
      contractId,
      memberId,
      timestampHour,
    },
  });

  // Get associations related to the contract
  const {
    data: associationsData,
    loading: associationsLoading,
    refetch: associationRefetch,
  } = useQuery<QueryAssociationsResult>(QUERY_ASSOCIATIONS, {
    variables: {
      where: {
        member_id: { _eq: memberId },
        active: { _eq: true },
        to_id_full: { _eq: `ml:contract-header:${memberId}:${contractId}` },
      },
    },
    skip: !(contractId && memberId),
  });

  // Fetch contract pricing
  const {
    data: contractPricing,
    loading: contractPricingLoading,
    refetch: contractPricingRefetch,
  } = useQuery<QueryContractPricingsResult>(GET_CONTRACT_PRICINGS, {
    variables: {
      contractId,
      memberId,
      offset: 0,
      limit: NUMBER_PER_PAGE,
      where: {
        search_text: { _ilike: '%' },
        pricing: { _neq: 'null' },
      },
      timestampHour,
    },
    skip: !timestampHour,
  });

  useEffect(() => {
    contractHeaderRefetch();
    associationRefetch();
    contractPricingRefetch();
  }, []);

  const contract = contractHeader?.dash_contract_headers_history?.[0];

  const fetchedDataMem: ContractFormProps = useMemo(() => {
    const fetchedData: ContractFormProps = {
      ...emptyFormObject,
      priceTypes: [],
      contractId: contract?.list_id,
      entity: contract?.contract_entities?.[0].id,
      startDate: truncateDatetime(contract?.start_date) ?? '',
      endDate: truncateDatetime(contract?.end_date) ?? '',
      description: contract?.description,
      contractType: contract?.type,
      distributors:
        contract?.distributors?.map((d: Distributor) => {
          const { id, endDate, startDate } = d;
          return {
            id,
            checked: true,
            startDate: truncateDatetime(startDate),
            endDate: truncateDatetime(endDate),
          } as Distributor;
        }) ?? [],
      openClosed:
        contract?.open === true ? openClose[0].name : openClose[1].name,
    };

    fetchedData.products =
      contractPricing?.dash_get_contract_pricings_by_hour?.map(
        (contractPricingRow) => {
          const { pricing, productId } = contractPricingRow;
          fetchedData.priceTypes.push({
            externalId: productId.split(':')[1],
            priceType: pricing[0].priceType || 'WAC',
          });
          return convertPricingRowToContractProduct(contractPricingRow);
        }
      ) ?? [];

    fetchedData.productLists =
      associationsData?.associations
        .filter(
          (association) => association.kind === 'product-list-association'
        )
        .map((pa) => ({
          checked: true,
          id: parseMediledgerId(pa.fromId).id,
        })) ?? [];

    const customerListAssociationFromId =
      associationsData?.associations.find(
        (association) => association.kind === 'contract-list-association'
      )?.fromId ?? '';

    fetchedData.customerList = parseMediledgerId(
      customerListAssociationFromId
    ).id;

    return fetchedData;
  }, [contract, contractPricing, associationsData]);

  if (associationsLoading || contractHeaderLoading || contractPricingLoading) {
    return <div>Loading</div>;
  }

  if (contractHeaderError) {
    return (
      <div className={cx('errorCode')}>
        <IconSystemError />
      </div>
    );
  }

  return <Create fetchedData={fetchedDataMem} contractModeType='update' />;
};

export default EditContract;
