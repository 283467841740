// Vendor
import { Dictionary, first } from 'lodash';

// Internal
import { ErrorCode } from 'types/generated/ErrorCode';
import {
  ChargebackRequestRow,
  QueryChargebacks,
  TradingPartner,
  wrapSearchText,
} from 'query';
import { TableColumn, TableRow } from 'components/Table';
import {
  chargebackPath,
  contractPath,
  formatCurrency,
  hasError,
  localCustomerPath,
  parseMediledgerId,
} from 'common/helpers';
import { FilterItem } from 'components/Filters/BasicFilter';

export interface Chargeback {
  id: string;
  memberId: number;
  invoiceId: string;
  type: string;
  partnerName: string;
  submittedAmount: string;
  contractId?: string;
  contractMemberId: number;
  customerId?: string;
  saleDate: string;
  status: string;
  statusChipColor: string;
  details?: string;
  submissionDate: string;
  errors?: ErrorCode[];
}

export function rawDataToRows(
  tradingPartners: Dictionary<TradingPartner>,
  isMFR: boolean,
  queryResult?: QueryChargebacks
): TableRow[] {
  if (
    !queryResult ||
    !queryResult.chargebacks ||
    queryResult.chargebacks.length === 0
  ) {
    return [];
  }

  return queryResult.chargebacks.map((cbRow) => {
    let invoiceId = cbRow.sale.id;
    const lineNumber = getLineNumber(cbRow.identifiers);
    if (lineNumber) {
      invoiceId = `${invoiceId}-${lineNumber}`;
    }

    // TODO: verify that trading partner will always be present
    const partnerId = isMFR
      ? cbRow.sale.member_id
      : parseMediledgerId(cbRow.manufacturer_id).memberId;
    const partnerName = tradingPartners[partnerId]?.name ?? 'UNKNOWN';

    const { status, statusChipColor, details } =
      getChargebackStatusForRow(cbRow);

    return {
      type: 'expanding',
      data: <Chargeback>{
        id: cbRow.id,
        memberId: cbRow.member_id,
        invoiceId,
        saleId: cbRow.sale.id,
        type: cbRow.operation,
        partnerName,
        submittedAmount: formatCurrency(cbRow.submitted_amount),
        contractId: cbRow.contract_id,
        contractMemberId: cbRow.contract_member_id,
        customerId: isMFR
          ? cbRow.manufacturer_customer_id
          : cbRow.distributor_customer_id,
        saleDate: cbRow.sale.invoice_date,
        status,
        statusChipColor,
        details,
        submissionDate: cbRow.timestamp,
        errors: cbRow.errors,
      },
      isExpandable: false,
    };
  });
}

export function buildWhereClause(filters: string[], searchTerm: string): any {
  return {
    _search_text: { _ilike: wrapSearchText(searchTerm) },
    ...statusCondition(filters),
  };
}

export function statusCondition(filters: string[]): any {
  if (filters.length === 1) {
    if (filters[0] === 'approved') {
      return {
        responses: { kind: { _eq: 'success' } }, // approved via adjudication, must pass validation already
      };
    } else if (filters[0] === 'rejected') {
      return {
        _or: [
          { responses: { kind: { _eq: 'error' } } }, // rejected via adjudication
          { has_errors: { _eq: true } }, // rejected via validation
        ],
      };
    } else if (filters[0] === 'processing') {
      return {
        _not: { responses: {} }, // adjudication is in-progress
        has_errors: { _eq: false }, // validation is done
      };
    }
  }

  return {};
}

/**
 * Look up line number in the identifiers array
 * Format: invoice:<invoice-number>:<line-number>
 * Example: invoice:889483-91:4
 * @param identifiers
 */
export function getLineNumber(identifiers: string[]): string | undefined {
  if (!identifiers || identifiers.length === 0) {
    return undefined;
  }

  const invoiceLine = identifiers.find((identifier) =>
    identifier.startsWith('invoice:')
  );

  if (!invoiceLine) {
    return undefined;
  }

  return invoiceLine.split(':')[2];
}
export interface ChargebackStatus {
  status: string;
  statusChipColor: string;
  details: string | undefined;
}

export function getChargebackStatusForRow(
  chargeback: ChargebackRequestRow
): ChargebackStatus {
  const lastOverrideAuditAction = first(chargeback.override_audit ?? []);

  // Override
  const isLatestActionOverrideRequest =
    lastOverrideAuditAction?.operation === 'override-request';
  const isLatestActionOverrideReply =
    lastOverrideAuditAction?.operation === 'override-reply';
  // Audit
  const isLatestActionAuditRequest =
    lastOverrideAuditAction?.operation === 'audit-request';

  let lastResponseKind = chargeback.responses?.[0]?.kind;

  if (lastOverrideAuditAction) {
    // Override/Audit will take precedence over chargeback response
    if (lastOverrideAuditAction.operation === 'override-result') {
      const { data } = lastOverrideAuditAction;
      const zkpProcessingErrors = data.errors && data.errors.length > 0;
      const mfrApproved = data.response === true;
      if (zkpProcessingErrors || !mfrApproved) {
        lastResponseKind = 'error';
      } else if (mfrApproved) {
        lastResponseKind = 'success';
      }
    }
  }

  return getChargebackStatus(
    chargeback.errors ?? [],
    isLatestActionOverrideRequest ||
      isLatestActionOverrideReply ||
      isLatestActionAuditRequest,
    lastResponseKind
  );
}

export function getChargebackStatus(
  errors: ErrorCode[],
  hasOverrideRequest: boolean,
  lastResponseKind?: string
): ChargebackStatus {
  let status;
  let statusChipColor;
  // TODO: values are: Customer Onboarding, Override Requested, Cross-Pay Eligible, Invalid
  let details;

  if (!hasError(errors) && !lastResponseKind) {
    status = 'Processing';
    statusChipColor = '#00BCD4';
  } else if (hasError(errors) || lastResponseKind === 'error') {
    if (hasOverrideRequest) {
      status = 'Pending Override';
      statusChipColor = '#f9bc60';
      details = 'Override Requested';
    } else {
      status = 'Rejected';
      statusChipColor = '#cb6671';
      details = 'Invalid';
    }
  } else {
    status = 'Approved';
    statusChipColor = '#00bc96';
  }

  return {
    status,
    statusChipColor,
    details,
  };
}

// Table definitions
export function getColumns(isMFR: boolean, statusClass: string): TableColumn[] {
  return [
    // Unique ID for this specific chargeback
    // Data source: chargeback.id
    {
      headerName: 'CBK LINE ID',
      type: 'link',
      size: 'small',
      displayTextFn: (data: any) => data.id,
      urlFn: (data: any) => chargebackPath(+data.memberId, data.id),
    },
    // Concatenation of invoice number and line number separated by a hyphen
    // Data source: chargeback.sale.id
    {
      dataField: 'invoiceId',
      headerName: 'INVOICE LINE #',
      type: 'expanding',
      size: 'small',
    },
    // Type of chargeback (original, resubmission, return, credit, rebill)
    // Data source: chargeback.operation
    {
      dataField: 'type',
      headerName: 'CBK TYPE',
      type: 'expanding',
      size: 'small',
    },
    // Depending on the node, the submitting distributor or the receiving manufacturer
    // Data source: look up chargeback.sale.member_id in tradingPartners dictionary
    {
      dataField: 'partnerName',
      headerName: isMFR ? 'DISTRIBUTOR' : 'MANUFACTURER',
      type: 'expanding',
      size: 'small',
    },
    // Total amount of the claim
    // Data source: chargeback.submitted_amount
    {
      dataField: 'submittedAmount',
      headerName: 'CLAIM AMOUNT',
      type: 'expanding',
      size: 'small',
    },
    // Manufacturer contract ID claim is submitted against
    {
      headerName: 'CONTRACT ID',
      type: 'link',
      size: 'small',
      displayTextFn: (data: any) => data.contractId,
      urlFn: (data: any) =>
        contractPath(+data.contractMemberId, data.contractId),
    },
    // Customer ID of submitted customer, will differ by node between mfr and dist
    {
      headerName: 'CUSTOMER',
      type: 'link',
      size: 'small',
      displayTextFn: (data: any) => data.customerId,
      urlFn: (data: any) => localCustomerPath(data.customerId),
    },
    // Date of sale
    // Data source: chargeback.sale.invoice_date
    {
      dataField: 'saleDate',
      headerName: 'SALE DATE',
      type: 'date',
      size: 'medium',
      format: 'short',
    },
    // Current status of Chargeback (Processing, Pending, Approved, Rejected)
    // Data source: calculated from the last response object
    {
      dataField: 'status',
      headerName: 'ADJUDICATION STATUS',
      type: 'chip',
      colorField: 'statusChipColor',
      size: 'small',
      extraClass: statusClass,
    },
    // Date & time Chargeback was submitted from Distributor
    // Data source: chargeback.timestamp
    {
      dataField: 'submissionDate',
      headerName: 'SUBMISSION DATE',
      type: 'date',
      size: 'medium',
    },
  ];
}

// Filter definitions
export function getFilterItems(): FilterItem[] {
  return [
    {
      id: 'all',
      label: 'All',
      isDefault: true,
    },
    {
      id: 'approved',
      label: 'Approved',
      isDefault: false,
    },
    {
      id: 'processing',
      label: 'Processing',
      isDefault: false,
    },
    {
      id: 'rejected',
      label: 'Rejected',
      isDefault: false,
    },
  ];
}
