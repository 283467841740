// Vendor
import get from 'lodash/get';
import { NodeType } from 'common/config';

interface Props {
  chargebacksEnabled?: boolean;
  contractsEnabled?: boolean;
  creditMemosEnabled?: boolean;
  customersEnabled: boolean;
  proposalsEnabled?: boolean;
  productsEnabled?: boolean;
  productListsEnabled?: boolean;
  customerListsEnabled?: boolean;
}

export interface DrawerEntry {
  hash: string;
  text: string;
  subEntries: DrawerEntry[];
}

const CUSTOMERS_ENTRY: DrawerEntry = {
  hash: 'customers',
  text: 'Customers',
  subEntries: [],
};

const CUSTOMER_LIST_ENTRY: DrawerEntry = {
  hash: 'customer-lists',
  text: 'Customer Lists',
  subEntries: [],
};

const CHARGEBACKS_ENTRY: DrawerEntry = {
  hash: 'chargebacks',
  text: 'Chargebacks',
  subEntries: [],
};

const CREDIT_MEMOS_ENTRY: DrawerEntry = {
  hash: 'credit-memos',
  text: 'Credit Memos',
  subEntries: [],
};

const PROPOSALS_ENTRY: DrawerEntry = {
  hash: 'customer-proposals',
  text: 'Customer Proposals',
  subEntries: [],
};

const CONTRACTS_ENTRY: DrawerEntry = {
  hash: 'contracts',
  text: 'Contracts',
  subEntries: [],
};

const PRODUCTS_ENTRY: DrawerEntry = {
  hash: 'products',
  text: 'Products',
  subEntries: [],
};

const PRODUCT_LIST_ENTRY: DrawerEntry = {
  hash: 'product-lists',
  text: 'Product Lists',
  subEntries: [],
};

const generateCustomersEntry = ({
  customerListsEnabled,
  proposalsEnabled,
}: {
  customerListsEnabled: boolean;
  proposalsEnabled: boolean;
}): DrawerEntry => ({
  ...CUSTOMERS_ENTRY,
  subEntries: [
    ...(customerListsEnabled ? [CUSTOMER_LIST_ENTRY] : []),
    ...(proposalsEnabled ? [PROPOSALS_ENTRY] : []),
  ],
});

const generateProductsEntry = ({
  productListsEnabled,
}: {
  productListsEnabled: boolean;
}): DrawerEntry => ({
  ...PRODUCTS_ENTRY,
  subEntries: [...(productListsEnabled ? [PRODUCT_LIST_ENTRY] : [])],
});

const DIST = ({
  chargebacksEnabled,
  creditMemosEnabled,
  customersEnabled,
  customerListsEnabled,
  productsEnabled,
  productListsEnabled,
}: Props): DrawerEntry[] => [
  ...(customersEnabled
    ? [
        generateCustomersEntry({
          customerListsEnabled: customerListsEnabled ?? false,
          proposalsEnabled: false,
        }),
      ]
    : []),
  ...(chargebacksEnabled ? [CHARGEBACKS_ENTRY] : []),
  ...(creditMemosEnabled ? [CREDIT_MEMOS_ENTRY] : []),
  ...(productsEnabled
    ? [
        generateProductsEntry({
          productListsEnabled: productListsEnabled ?? false,
        }),
      ]
    : []),
];

const GPO = ({
  customersEnabled,
  customerListsEnabled,
  productsEnabled,
  productListsEnabled,
}: Props): DrawerEntry[] => [
  ...(customersEnabled
    ? [
        generateCustomersEntry({
          customerListsEnabled: customerListsEnabled ?? false,
          proposalsEnabled: false,
        }),
      ]
    : []),
  ...(productsEnabled
    ? [
        generateProductsEntry({
          productListsEnabled: productListsEnabled ?? false,
        }),
      ]
    : []),
];

const MFR = ({
  chargebacksEnabled,
  contractsEnabled,
  creditMemosEnabled,
  customersEnabled,
  proposalsEnabled,
  customerListsEnabled,
  productsEnabled,
  productListsEnabled,
}: Props) => [
  ...(customersEnabled
    ? [
        generateCustomersEntry({
          customerListsEnabled: customerListsEnabled ?? false,
          proposalsEnabled: proposalsEnabled ?? false,
        }),
      ]
    : []),
  ...(contractsEnabled ? [CONTRACTS_ENTRY] : []),
  ...(chargebacksEnabled ? [CHARGEBACKS_ENTRY] : []),
  ...(creditMemosEnabled ? [CREDIT_MEMOS_ENTRY] : []),
  ...(productsEnabled
    ? [
        generateProductsEntry({
          productListsEnabled: productListsEnabled ?? false,
        }),
      ]
    : []),
];

const DEFAULT = ({
  customersEnabled,
  customerListsEnabled,
  productsEnabled,
  productListsEnabled,
}: Props) => [
  ...(customersEnabled
    ? [
        generateCustomersEntry({
          customerListsEnabled: customerListsEnabled ?? false,
          proposalsEnabled: false,
        }),
      ]
    : []),
  ...(productsEnabled
    ? [
        generateProductsEntry({
          productListsEnabled: productListsEnabled ?? false,
        }),
      ]
    : []),
];

export const getLocalData = ({
  customerListsEnabled,
  chargebacksEnabled,
  contractsEnabled,
  creditMemosEnabled,
  customersEnabled,
  proposalsEnabled,
  productsEnabled,
  productListsEnabled,
  xpType: type,
}: {
  customerListsEnabled: boolean;
  chargebacksEnabled: boolean;
  contractsEnabled: boolean;
  creditMemosEnabled: boolean;
  customersEnabled: boolean;
  proposalsEnabled: boolean;
  productsEnabled: boolean;
  productListsEnabled: boolean;
  xpType?: NodeType;
}): DrawerEntry[] => {
  const dataMapper = {
    DIST: DIST({
      customerListsEnabled,
      chargebacksEnabled,
      creditMemosEnabled,
      customersEnabled,
      productsEnabled,
      productListsEnabled,
    }),
    GPO: GPO({
      customerListsEnabled,
      customersEnabled,
      productsEnabled,
      productListsEnabled,
    }),
    MFR: MFR({
      customerListsEnabled,
      chargebacksEnabled,
      contractsEnabled,
      creditMemosEnabled,
      customersEnabled,
      proposalsEnabled,
      productsEnabled,
      productListsEnabled,
    }),
  };
  return get(
    dataMapper,
    type ?? '',
    DEFAULT({ customerListsEnabled, customersEnabled })
  );
};
