// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import {
  CustomerDetails,
  QueryClassOfTradeResult,
  QUERY_MULTIPLE_COT,
} from 'query';
import { mapByField } from 'common/helpers';
import { KeyValuePair } from 'components';
import { getOverviewData } from './helpers';

// Styles
import styles from './Overview.module.css';
const cx = classnames.bind(styles);

interface Props {
  customer: CustomerDetails;
}

const Overview: FC<Props> = (props) => {
  const { customer } = props;
  const { classesOfTrade, memberId } = customer;

  const cotIds = classesOfTrade?.map((cot: any) => cot.classOfTradeId) ?? [];
  const { data } = useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
    variables: {
      ids: cotIds,
      memberId,
    },
    skip: cotIds.length === 0 || memberId === undefined,
  });
  const cots = mapByField(data?.classesOfTrade ?? [], 'id');
  const customerCot = classesOfTrade
    ?.map((ccot) => cots.get(ccot.classOfTradeId))
    .find((cot) => !!cot);

  const overviewData = getOverviewData(customer, customerCot);

  return (
    <div className={cx('column', 'root')}>
      <header className={cx('header')}>Overview</header>
      <div className={cx('row')}>
        {overviewData.map(({ title, value, valueIcon, valueLength }) => (
          <KeyValuePair
            key={title}
            title={title}
            value={value}
            valueIcon={valueIcon}
            valueLength={valueLength}
          />
        ))}
      </div>
    </div>
  );
};

export default Overview;
