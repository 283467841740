// Vendor
import { connect } from 'react-redux';

// Internal
import { RootState } from 'reducers';
import AppBar from './AppBar';

const mapStateToProps = ({ common }: RootState) => common;

export default connect(mapStateToProps)(AppBar);
