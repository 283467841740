// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

// Internal
import {
  QUERY_AUTH_CUSTOMER_DETAILS,
  QUERY_AUTH_CUSTOMER_HISTORY,
  QueryIdentiferDataDetailsResult,
  QueryCustomerHistoryResult,
} from 'query';
import { IconSystemError } from 'styles/images';
import { AuthorityIDMapping } from 'common/helpers';
import { NotFoundSection } from 'components';
import IdentifierDataDetails from './IdentifierDataDetails';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  memberId: AuthorityIDMapping;
}

const Container: FC<Props> = (props) => {
  const { memberId } = props;

  const { id } = useParams() as { id: string };

  const { data: historyData, loading: historyLoading } =
    useQuery<QueryCustomerHistoryResult>(QUERY_AUTH_CUSTOMER_HISTORY, {
      variables: {
        id,
        memberId,
      },
      skip: !id || !memberId,
    });

  const customerHistory = historyData?.customers || [];

  const timestamp = customerHistory[0]?.timestamp || '';
  const { data, error, loading } = useQuery<QueryIdentiferDataDetailsResult>(
    QUERY_AUTH_CUSTOMER_DETAILS,
    {
      variables: {
        id,
        memberId,
        timestamp,
      },
      skip: !timestamp,
    }
  );

  const customer = data?.customer;

  if (loading || historyLoading) return null;

  if (error) {
    return (
      <div className={cx('errorCode')}>
        <IconSystemError />
      </div>
    );
  }

  if (!customer) {
    return (
      <div className={cx('notFound')}>
        <NotFoundSection text='Identifier Data not found.' />
      </div>
    );
  }

  return <IdentifierDataDetails customer={customer} memberId={memberId} />;
};

export default Container;
