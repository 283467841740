// Vendor
import { getOrElse, isLeft, map } from 'fp-ts/lib/Either';
import { partition } from 'lodash/fp';
import { RecordError } from '@flatfile/sdk';
import { fold, Applicative } from 'fp-ts/Either';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import { sequence } from 'fp-ts/lib/Array';
import { constant, identity, pipe } from 'fp-ts/function';

// Internal
import { RosterAdapter } from '../../domain/roster-adapter';
import { RosterValidator, RowError } from '../../domain/validator';
import { Validated } from './validation';

export interface RowWithExternalId<Schema> {
  id: number;
  row: Schema;
}

export interface RecordValidation<Schema> {
  id: number;
  validation: Validated<RowError, Schema>;
}

export interface ProcessedRows<Output> {
  errors: RecordError[];
  rows: Output[];
}

export type IdentifiersDelimiters = {
  hin?: string;
  _340b?: string;
  dea?: string;
};

export class RowProcessingService<Schema, Data, Output> {
  rosterAdapter: RosterAdapter<Schema, Data, Output>;

  validator: RosterValidator<Schema>;

  constructor(
    rosterAdapter: RosterAdapter<Schema, Data, Output>,
    validator: RosterValidator<Schema>
  ) {
    this.rosterAdapter = rosterAdapter;
    this.validator = validator;
  }

  processRows(
    rows: RowWithExternalId<Schema>[],
    data: Data
  ): ProcessedRows<Output> {
    const validatedRows = rows.map(({ id, row }) => ({
      id,
      validation: this.validator.validate(row),
    }));
    const [invalidRows, validRows] = partition<RecordValidation<Schema>>(
      ({ validation }) => isLeft(validation)
    )(validatedRows);
    const validationErrors = invalidRows.map(({ id, validation }) => {
      const errors = fold<NonEmptyArray<RowError>, Schema, Array<RowError>>(
        identity,
        () => []
      )(validation);
      return new RecordError(id, errors);
    });

    const resultingRows = pipe(
      validRows.map(({ validation }) => validation),
      sequence(Applicative),
      map((rows) => rows.map((row) => this.rosterAdapter.convert(row, data))),
      getOrElse(constant<Output[]>([]))
    );

    return { errors: validationErrors, rows: resultingRows };
  }
}
