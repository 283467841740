// Vendor
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { uniq } from 'lodash';

// Internal
import Table, { TableRow } from 'components/Table';
import {
  CustomerViewRow,
  QueryClassOfTradeResult,
  QUERY_MULTIPLE_COT,
} from 'query';
import { useAuthDatabases, useTradingPartnerType } from 'hooks';
import { getHeaders, getRows } from './helpers';

interface Props {
  activeCustomer?: any;
  customers: CustomerViewRow[];
  handleRowClick?: (data: any) => void;
  loading: boolean;
}

const CustomersTable: FC<Props> = (props) => {
  const acceptableAuthData = useAuthDatabases();
  const tpType = useTradingPartnerType();

  const { activeCustomer, customers = [], handleRowClick, loading } = props;

  const [rows, updateRows] = useState([] as TableRow[]);

  const { columns, hasCotColumn, hasProgramColumn } = getHeaders(
    tpType,
    acceptableAuthData,
    handleRowClick
  );

  // Local customers will have the same memberId, just fetching the first customers' memberId is enough to fetch all the COTs
  const memberId = customers?.[0]?.memberId;

  const cotIds = hasCotColumn
    ? uniq(
        customers.flatMap((c) =>
          c.classesOfTrade?.map((cot) => cot.classOfTradeId)
        )
      )?.filter((cotId) => cotId)
    : [];
  const { data: cotData, loading: cotLoading } =
    useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
      variables: {
        ids: cotIds,
        memberId,
      },
      skip: cotIds.length === 0 || memberId === undefined,
    });

  useEffect(() => {
    if (!loading && !cotLoading) {
      updateRows(
        getRows(
          hasCotColumn,
          hasProgramColumn,
          acceptableAuthData,
          activeCustomer,
          customers,
          cotData
        )
      );
    }
  }, [activeCustomer, cotData, loading, cotLoading]);

  return (
    <Table
      columns={columns}
      isLoading={loading || cotLoading}
      emptyText='No customers found'
      handleRowClick={handleRowClick}
      rows={rows}
    />
  );
};

export default CustomersTable;
