// Vendor
import gql from 'graphql-tag';
import { OUT_OF_NETWORK_DIGIT } from '../common/constants';

export interface QueryTradingPartners {
  tradingPartners?: TradingPartner[];
}

export interface TradingPartner {
  id: number;
  name: string;
  type: string;
  status?: string;
  identifiers?: string;
  timestamp?: string;
}

export interface TradingPartnerEntity extends TradingPartner {
  member?: Member;
  visualStatus: string;
}

export interface QueryMembers {
  dash_members?: Member[];
}

export interface Member {
  id: number;
  name?: string;
  blockchainAccountId?: string;
  cmiPeerAddress?: string;
  cmiPeerId?: string;
  timestamp: string;
  status?: string;
}

export enum ParticipantType {
  distributor = 'Distributor',
  gpo = 'GPO',
  hs = 'Health System',
  manufacturer = 'Manufacturer',
  netman = 'NetMan',
}

const TRADING_PARTNER_FIELDS = `
  id
  name
  type
  status
  timestamp
  identifiers
`;

const MEMBER_FIELDS = `
  blockchainAccountId
  cmiPeerAddress
  cmiPeerId
  id
  name
  status
  timestamp
`;

export const GET_MEMBER_DETAILS = gql`
  query QueryMemberDetails($id: Int!) {
    member: dash_members(
      where: {
        id: {_eq: $id}
      },
      order_by: {timestamp: desc},
      limit: 1
    ) {
      ${MEMBER_FIELDS}
    }
  }
`;

export const GET_TRADING_PARTNER = gql`
  query QueryTradingPartner($id: Int!) {
    tradingPartner: dash_trading_partners(
      where: {
        id: {_eq: $id}
      },
      order_by: {timestamp: desc},
      limit: 1
    ) {
      ${TRADING_PARTNER_FIELDS}
    }
  }
`;

export const GET_MEMBERS = gql`
  query QueryMembers($memberId: Int) {
    dash_members(
      order_by: [ { id: asc }, { timestamp: desc } ],
      distinct_on: id,
      where: { _and: [{ id:  { _lte: ${OUT_OF_NETWORK_DIGIT} } }, { id: { _neq: $memberId } }] }
    ) {
      blockchainAccountId
      cmiPeerAddress
      cmiPeerId
      id
      name
      status
      timestamp
    }
  }
`;

export const GET_TRADING_PARTNERS = gql`
  query QueryTradingPartners(
    $id: Int_comparison_exp,
    $name: String,
  ) {
    tradingPartners: dash_trading_partners(
      distinct_on: [name, id],
      order_by: [{ name: asc }, { id: asc }],
      where: {
        id: $id,
        name: { _ilike: $name },
      }
    ) {
      ${TRADING_PARTNER_FIELDS}
    }
  }
`;
