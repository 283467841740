import React, { ChangeEvent, FC, useCallback } from 'react';
import { Checkbox, TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';

export interface CheckBoxColumnInterface {
  headerName?: string;
  type: 'checkBox';
  size?: Size;
  idFn: (data: any) => string;
  checkedFn: (data: any) => boolean;
  disabledFn: (data: any) => boolean;
  handleOnChangeFn: (id: string, updatedChecked: boolean) => void;
}

export interface Props {
  size?: Size;
  idFn: (data: any) => string;
  checkedFn: (data: any) => boolean;
  disabledFn: (data: any) => boolean;
  handleOnChangeFn: (id: string, updatedChecked: boolean) => void;
  data: any;
}

const CheckBoxCell: FC<Props> = (props) => {
  const {
    size = 'small',
    idFn,
    checkedFn,
    disabledFn,
    handleOnChangeFn,
    data,
  } = props;

  const id = idFn(data);
  const isChecked = checkedFn(data);
  const isDisabled = disabledFn(data);

  const internalHandleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleOnChangeFn(id, event.target.checked);
    },
    [handleOnChangeFn]
  );

  return (
    <TableCell size={size}>
      <Checkbox
        checked={isChecked}
        color='primary'
        disabled={isDisabled}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={internalHandleOnChange}
        data-testid='checkBoxCell'
      />
    </TableCell>
  );
};

export default CheckBoxCell;
