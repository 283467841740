import classnames from 'classnames/bind';
import React, { FC } from 'react';
import {
  IconBackgroundPill,
  IconBackgroundSyringe,
  IconMediLedgerLogo,
} from 'styles/images';
import styles from './index.module.css';

const cx = classnames.bind(styles);

const Loading: FC = () => (
  <div className={cx('root')}>
    <div className={cx('top')}>
      <IconBackgroundSyringe
        className={cx('syringe')}
        height={240}
        width={310}
      />
      <IconBackgroundPill className={cx('pill')} height={290} width={310} />
    </div>
    <div className={cx('bottom')} />
    <div className={cx('container')}>
      <IconMediLedgerLogo className={cx('logo')} height='260px' width='260px' />
      <div className={cx('lds-ring')}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default Loading;
