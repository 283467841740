// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

// Internal
import {
  useApi,
  useAppBarSetTitle,
  usePriceTypes,
  useProductTypes,
  useSnackbar,
  useTradingPartnerConfig,
  useUnitsOfMeasure,
} from 'hooks';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import {
  QUERY_PRODUCT_BY_ID,
  QUERY_PRODUCTS_COUNT,
  QueryProductCountResult,
  QueryProductResult,
} from 'query';
import { IconSystemError } from 'styles/images';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  productPath,
} from 'common/helpers';
import { ProductPrice } from 'types/generated/Product';
import { formatProductPricingsForAPI } from './service';
import CreateEditProductsForm from './CreateEditProductsForm';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const CreateProduct: FC = () => {
  useAppBarSetTitle('Create Product');

  const navigate = useNavigate();
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const api = useApi();
  const snackbar = useSnackbar();

  const priceTypes = usePriceTypes();
  const unitsOfMeasure = useUnitsOfMeasure();
  const productTypes = useProductTypes();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [productId, setProductId] = useState('');

  const apolloClient = useApolloClient();

  // We query for the updated/created Product
  useQuery<QueryProductResult>(QUERY_PRODUCT_BY_ID, {
    variables: {
      id: productId,
      memberId,
    },
    skip: !(isFormSubmitted && memberId),
    pollInterval: SHORT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ product }) => {
      if (product) {
        navigate(product.id ? productPath(product.id) : '/products', {
          replace: true,
        });
        snackbar.open({
          message: `Product ${product.external_id} has been created.`,
          variant: 'success',
        });
      }
    },
  });

  const handleSubmit = async (values: any) => {
    const id = `${values.idType}:${values.externalId}`;
    setProductId(id);

    const { data: productCountData, loading: productCountLoading } =
      await apolloClient.query<QueryProductCountResult>({
        query: QUERY_PRODUCTS_COUNT,
        variables: {
          where: {
            member_id: { _eq: memberId },
            id: { _eq: id },
          },
        },
        fetchPolicy: 'network-only',
      });

    const foundDuplicateId =
      productCountLoading ||
      (!!productCountData &&
        !!productCountData.productsCount &&
        !!productCountData.productsCount.aggregate &&
        productCountData.productsCount.aggregate.count > 0);

    if (foundDuplicateId) {
      snackbar.open({
        message: `Product with ID ${values.externalId} already exists.`,
        variant: 'error',
      });
      setProductId('');
    } else {
      const { pricings } = values;

      // format pricings data to make them API-friendly
      const formattedPricings: ProductPrice[] =
        formatProductPricingsForAPI(pricings);

      const params = {
        id,
        externalId: values.externalId,
        memberId: `ml:member:${memberId}`,
        timestamp: new Date().toISOString(),
        description: values.description,
        endDate: convertEndDateToUtcTimestamp(values.endDate),
        kind: values.idType,
        prices: formattedPricings,
        relationships: [],
        revisedAt: new Date().toISOString(),
        startDate: convertStartDateToUtcTimestamp(values.startDate),
        unitOfMeasure: values.unitOfMeasure,
      };

      await api?.createProduct(params);

      setIsFormSubmitted(true);
    }
  };

  const onCancel = () => {
    navigate('/products', { replace: true });
  };

  return !memberId ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <CreateEditProductsForm
      handleOnSubmit={handleSubmit}
      onCancel={onCancel}
      id={productId}
      priceTypes={priceTypes}
      unitsOfMeasure={unitsOfMeasure}
      productTypes={productTypes}
      fetchingProducts={productId !== ''}
    />
  );
};

export default CreateProduct;
