// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import Paper from '@material-ui/core/Paper';
import { Dictionary } from 'lodash';

// Internal
import { KeyValuePair } from 'components';
import {
  displayAddressTypeAndAddress,
  formatAddresses,
  getValidIdentifiers,
  identifiersToDisplayValues,
  localCustomerPath,
  truncateDatetime,
} from 'common/helpers';
import { CustomerRow, SaleRow, TradingPartner } from 'query';
import { AddressTooltip, ValueTooltip } from 'components/Tooltips';

// Styles
import { Link } from '@material-ui/core';
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  customerId?: string;
  customer?: CustomerRow;
  sale: SaleRow;
  tradingPartners: Dictionary<TradingPartner>;
  productDescription: string;
  isMFR: boolean;
  partnerId: number;
  partnerCustomerId?: string;
  partnerCustomer?: CustomerRow;
  setShowCustomerModal: React.Dispatch<
    React.SetStateAction<CustomerRow | undefined>
  >;
}

const Details: FC<Props> = (props) => {
  const {
    customerId,
    customer,
    sale,
    tradingPartners,
    productDescription,
    isMFR,
    partnerId,
    partnerCustomerId,
    partnerCustomer,
    setShowCustomerModal,
  } = props;
  const { addresses = [], names = [] } = customer || {};

  // Sale data
  const partnerName = tradingPartners[partnerId]?.name ?? '';

  // Customer data
  const formattedAddresses = formatAddresses(customer?.addresses ?? []);
  const displayedAddress =
    formattedAddresses?.length === 1
      ? displayAddressTypeAndAddress(formattedAddresses)
      : formattedAddresses;
  const displayIds = identifiersToDisplayValues(
    getValidIdentifiers(customer?.identifiers ?? [], customer?.errors)
  ).filter((id) => id !== customer?.id);

  // Product data
  const productId = sale.product_id;

  const addressTooltip = (
    <ValueTooltip
      title={<AddressTooltip addresses={customer?.addresses ?? []} />}
    />
  );

  const idsTooltip = (
    <ValueTooltip header='Identifier(s)' values={displayIds} />
  );

  const nameTooltip = <ValueTooltip header='Name' values={names} />;

  return (
    <Paper className={cx('paperMargin')}>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Original Sale Overview</header>
        <div className={cx('content')}>
          <KeyValuePair
            title={isMFR ? 'Distributor' : 'Manufacturer'}
            value={partnerName}
          />
          <KeyValuePair
            title={isMFR ? 'Distributor ID' : 'Manufacturer ID'}
            value={`${partnerId}`}
          />
          <KeyValuePair
            title='Invoice Date'
            value={truncateDatetime(sale.invoice_date)}
          />
        </div>
        <hr className={cx('divider')} />
      </div>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Original Customer</header>
        <div className={cx('content')}>
          <KeyValuePair
            linkUrl={localCustomerPath(customerId ?? '')}
            title='Customer ID'
            value={customerId}
          />
          <KeyValuePair
            title='Name(s)'
            value={names?.[0]}
            valueIcon={names && names.length > 1 ? nameTooltip : undefined}
            valueLength={names && names.length - 1}
          />
          <KeyValuePair
            title='Identifier(s)'
            value={displayIds[0]}
            valueIcon={displayIds?.length > 1 ? idsTooltip : undefined}
            valueLength={displayIds?.length - 1}
          />
          <KeyValuePair
            title='Address(es)'
            value={displayedAddress[0]?.fullAddress}
            valueIcon={
              addresses && addresses.length > 1 ? addressTooltip : undefined
            }
            valueLength={addresses && addresses.length - 1}
          />
          <KeyValuePair
            title={isMFR ? 'Distributor Customer' : 'Manufacturer Customer'}
          >
            <Link
              className={cx('ellipsis', 'key')}
              onClick={(e) => {
                e.preventDefault();
                setShowCustomerModal(partnerCustomer);
              }}
              href='#'
            >
              {partnerCustomerId}
            </Link>
          </KeyValuePair>
        </div>
        <hr className={cx('divider')} />
      </div>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Original Product</header>
        <div className={cx('content')}>
          <KeyValuePair title='Product ID' value={productId} />
          <KeyValuePair
            title='Product Description'
            value={productDescription}
          />
        </div>
      </div>
    </Paper>
  );
};

export default Details;
