// Vendor
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { CombinatorSelectorProps, Option } from 'react-querybuilder';
import { MenuItem } from '@material-ui/core';

// Internal
import { Select } from 'components';

const CustomOperatorSelector = (props: CombinatorSelectorProps) => {
  const { handleOnChange, options: optionProps, value = '' } = props;
  const options = optionProps as Option[];

  // Operator disabled if all of the select fields are not selected since we will
  // not know the operators based on input type yet
  const disabled = options.length === 0;

  return (
    <div>
      {options.length === 1 ? (
        <div data-testid='oneOption'>{options[0].label}</div>
      ) : (
        <Select
          disabled={disabled}
          label={
            (!disabled &&
              options.find((option: Option) => option.name === value)?.label) ||
            ''
          }
        >
          {options.map(({ label, name }) => (
            <MenuItem
              key={name}
              onClick={() => handleOnChange(name)}
              value={name}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
};

export default CustomOperatorSelector;
