// Vendor
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import classnames from 'classnames/bind';
import WarningIcon from '@material-ui/icons/Warning';
import { Handle, useReactFlow, Position, Node, Edge } from 'reactflow';
import '@reactflow/node-resizer/dist/style.css';

// Internal
import { IconDelete } from 'styles/images';
import { NodeTypes } from '../../types';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

const CustomNode = ({ data }: Node) => {
  const { deleteElements, getNodes, getEdges } = useReactFlow();

  const reconnectEdges = (
    setEdges: Dispatch<SetStateAction<Edge[]>>,
    setNodes: Dispatch<SetStateAction<Node[]>>
  ) => {
    const selectedNodes = getNodes().filter((node) => node.selected);

    const allEdges = getEdges();
    const allNodes = getNodes();

    selectedNodes.forEach((selectedNode) => {
      const { id } = selectedNode;
      const targets = allEdges.findIndex((edge: Edge) => edge.target === id);
      const sources = allEdges.findIndex((edge: Edge) => edge.source === id);
      const allnodekey = allNodes.findIndex((node: Node) => node.id === id);

      // findIndex returns -1 if it doesn't exist
      if (targets !== -1 && sources !== -1) {
        allEdges[targets].target = allEdges[sources].target;
        allEdges.splice(sources, 1);
      } else if (targets !== -1) {
        allEdges.splice(targets, 1);
      } else if (sources !== -1) {
        allEdges.splice(sources, 1);
      }
      allNodes.splice(allnodekey, 1);
    });

    setNodes(allNodes);
    setEdges(allEdges);
  };

  const removeNodePage1 = useCallback(() => {
    reconnectEdges(data.setPage1Edges, data.setPage1Nodes);
  }, [deleteElements, getNodes(), getEdges()]);

  const removeNodePage2 = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);
    const selectedEdges = getEdges().filter((edge) => edge.selected);

    deleteElements({ nodes: selectedNodes, edges: selectedEdges });
  }, [deleteElements, getNodes(), getEdges()]);

  const removeNode =
    data.nodeType === NodeTypes.RULE_SET ? removeNodePage1 : removeNodePage2;

  // Page 2 rules are not connectable
  const showHandles = data.nodeType !== NodeTypes.RULE;

  const title =
    data.nodeType === NodeTypes.RULE_SET
      ? 'Rule Set'
      : data.nodeType === NodeTypes.RULE
      ? data.activePage1NodeLabel
      : '';

  return (
    <>
      {showHandles && (
        <div data-testid='handles'>
          <Handle
            className={cx('hiddenHandle')}
            data-testid='handleA'
            id='a'
            position={Position.Left}
            type='target'
          />
          <Handle
            className={cx('hiddenHandle')}
            data-testid='handleB'
            id='b'
            position={Position.Top}
            type='target'
          />
          <Handle
            className={cx('hiddenHandle')}
            data-testid='handleC'
            id='c'
            position={Position.Bottom}
            type='target'
          />
          <Handle
            className={cx('defaultHandle')}
            data-testid='handleD'
            id='d'
            position={Position.Right}
            type='source'
          />
        </div>
      )}
      <div className={cx('row', 'node', { errors: data?.error?.isShow })}>
        <div className={cx('column')}>
          <div data-testid='title' className={cx('title')}>
            {title}
          </div>
          <div data-testid='label' className={cx('name')}>
            {data.name}
          </div>
        </div>
        <div
          data-testid='deleteIcon'
          className={cx('icon')}
          onClick={() => removeNode()}
        >
          <IconDelete />
        </div>
      </div>
      {data.error.isShow && (
        <div className={cx(['errorsMessage'])}>
          <WarningIcon
            style={{
              height: 15,
              marginBottom: 3,
              marginRight: 4,
              width: 15,
            }}
          />
          <span>{data.error.message}</span>
        </div>
      )}
    </>
  );
};

export default CustomNode;
