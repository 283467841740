// Vendor
import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { orderBy } from 'lodash';

// Internal
import { customerListPath, truncateDatetime } from 'common/helpers';
import {
  QUERY_CUSTOMERS_FROM_LIST,
  QueryCustomerViewInCustomerLists,
} from 'query';
import CreateEditCustomerList from './container';

// Styles
import styles from './EditCustomerList.module.css';
const cx = classnames.bind(styles);

export interface SimpleCustomer {
  checked: boolean;
  id: string;
  memberId: number;
  membershipStartDate: string;
  membershipEndDate: string;
}

const EditCustomersList: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data, loading, refetch } = useQuery<QueryCustomerViewInCustomerLists>(
    QUERY_CUSTOMERS_FROM_LIST,
    {
      variables: {
        where: {
          list_id: { _eq: id },
        },
      },
    }
  );

  useEffect(() => {
    refetch().then();
  });

  const onFinish = () => {
    navigate(`${customerListPath(id)}`, { replace: true });
  };

  const initialCustomers = data?.customers.map(({ customer }) => {
    const { id, memberId, memberships } = customer;
    const latestMembership = orderBy(
      memberships,
      (membership) => membership.list?.timestamp,
      ['desc']
    )[0];
    const { endDate, startDate } = latestMembership;

    return {
      checked: true,
      id,
      memberId,
      membershipStartDate: truncateDatetime(startDate) || '',
      membershipEndDate: truncateDatetime(endDate) || '',
    } as SimpleCustomer;
  });

  return loading ? (
    <div className={cx('circularProgress')}>
      <CircularProgress
        data-testid='circularProgress'
        disableShrink
        size={80}
      />
    </div>
  ) : (
    <CreateEditCustomerList
      editOnFinish={onFinish}
      initialCustomers={initialCustomers}
      listId={id}
    />
  );
};

export default EditCustomersList;
