// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  Drawer as MUIDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

// Internal
import {
  IconChevron,
  IconMediLedgerLogo,
  IconMediLedgerLogoCollapsed,
  IconProfilePicture,
} from 'styles/images';
import { DrawerEntry } from 'services/Navigation';
import { AuthorityDatabases } from 'common/config';
import NavItem from './NavItem';

// Styles
import inlineStyles from './inlineStyles';
import styles from './Drawer.module.css';

const cx = classnames.bind(styles);
// @ts-ignore
const cxInline = classnames.bind(inlineStyles);

interface Props {
  classes: {
    divider: string;
    drawerClose: string;
    drawerOpen: string;
    listItemProfile: string;
  };
  open: boolean;
  toggleDrawer: (toggle: boolean) => void;
  isLoginEnabled: boolean;
  userName: string;
  tradingPartnerName: string;
  tradingPartnerVisible: boolean;
  showTradingPartners: React.Dispatch<React.SetStateAction<boolean>>;
  localData: DrawerEntry[];
  identifierData: DrawerEntry[];
  tpData: DrawerEntry[];
  acceptableAuthData: AuthorityDatabases[];
}

const Drawer: FC<Props> = (props) => {
  const {
    classes,
    open,
    toggleDrawer,
    isLoginEnabled,
    userName,
    tradingPartnerName,
    tradingPartnerVisible,
    showTradingPartners,
    localData,
    identifierData,
    tpData,
    acceptableAuthData,
  } = props;

  return (
    <MUIDrawer
      classes={{
        paper: cxInline({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      className={cxInline({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      open={open}
      variant='permanent'
    >
      <IconButton
        className={cx('logo')}
        onClick={open ? () => toggleDrawer(false) : () => toggleDrawer(true)}
      >
        {open ? <IconMediLedgerLogo /> : <IconMediLedgerLogoCollapsed />}
      </IconButton>
      <Divider
        classes={{
          root: classes.divider,
        }}
      />
      <div className={cx('scroll')}>
        {localData.length > 0 && (
          <>
            <header className={cx('header')}>
              {open ? 'MY DATA' : 'M.D.'}
            </header>
            <List>
              {localData.map(({ hash, text, subEntries }) => (
                <React.Fragment key={`frg-${hash}`}>
                  <NavItem key={hash} hash={hash} text={text} />
                  {subEntries.map((se) => (
                    <NavItem
                      key={se.hash}
                      hash={se.hash}
                      text={se.text}
                      subEntry
                    />
                  ))}
                </React.Fragment>
              ))}
            </List>
          </>
        )}
        {tpData.length > 0 && (
          <>
            <Divider />
            <header className={cx('header')}>
              {open ? 'TRADING PARTNER DATA' : 'T.P.'}
            </header>
            <List>
              {tpData.map(({ hash, text }) => (
                <NavItem key={hash} hash={hash} text={text} />
              ))}
            </List>
          </>
        )}
        {acceptableAuthData.length > 0 && identifierData.length > 0 && (
          <>
            <Divider />
            <header className={cx('header')}>
              {open ? 'IDENTIFIER DATA' : 'I.D.'}
            </header>
            <List>
              {identifierData.map(({ hash, text }) => (
                <NavItem key={hash} hash={hash} text={text} />
              ))}
            </List>
          </>
        )}
        <Divider />
        {!isLoginEnabled && (
          <header className={cx('tradingPartner', 'tradingPartnerGPO')}>
            {tradingPartnerName}
          </header>
        )}
        <List>
          {isLoginEnabled ? (
            <ListItem
              disableGutters
              onClick={() => showTradingPartners(!tradingPartnerVisible)}
              classes={{
                root: classes.listItemProfile,
              }}
            >
              <div className={cx('row')}>
                <ListItemIcon className={cx('icon')}>
                  <IconProfilePicture />
                </ListItemIcon>
                <div className={cx('column', 'user')}>
                  <ListItemText
                    className={cx('ellipsis')}
                    disableTypography
                    primary={userName}
                  />
                  <header className={cx('tradingPartner')}>
                    {tradingPartnerName}
                  </header>
                </div>
                <div className={cx('align-end-right', 'point')}>
                  <ListItemIcon>
                    <IconChevron
                      pointUp={tradingPartnerVisible}
                      fillValue='#ffffff'
                    />
                  </ListItemIcon>
                </div>
              </div>
            </ListItem>
          ) : null}
          <div className={cx('dashboard-menu')}>
            <NavItem key='admin' hash='admin' text='IT Admin Dashboard' />
          </div>
          {isLoginEnabled ? (
            <NavItem key='logout' hash='logout' text='Logout' />
          ) : null}
        </List>
      </div>
    </MUIDrawer>
  );
};

// @ts-ignore
const StyledDrawer = withStyles(inlineStyles, { withTheme: true })(Drawer);

export default StyledDrawer;
