// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import Paper from '@material-ui/core/Paper';

// Internal
import { formatDate, truncateDatetime } from 'common/helpers';
import { KeyValuePair } from 'components';

// Styles
import styles from './Overview.module.css';

const cx = classnames.bind(styles);

interface Props {
  detailsInfo: {
    id: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    updatedAt: string;
  };
}

const Overview: FC<Props> = ({ detailsInfo }) => {
  const { description, endDate, id, name, startDate, updatedAt } = detailsInfo;

  return (
    <Paper className={cx('paperMargin')}>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Overview</header>
        <div className={cx('content')}>
          <KeyValuePair title='Customer List ID' value={id} />
          <KeyValuePair title='Name' value={name} />
          <KeyValuePair title='Description' value={description} />
          <KeyValuePair
            title='Start Date'
            value={truncateDatetime(startDate)}
          />
          <KeyValuePair
            title='End Date'
            data-testid='endDate'
            value={truncateDatetime(endDate)}
          />
          <KeyValuePair
            title='Updated At'
            data-testid='updatedAt'
            value={formatDate(updatedAt)}
          />
        </div>
      </div>
    </Paper>
  );
};

export default Overview;
