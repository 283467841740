// Vendor
import Paper from '@material-ui/core/Paper';
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import { Pagination, SearchBar } from 'components';
import {
  GET_CONTRACT_PRICINGS,
  GET_CONTRACT_PRICINGS_TOTAL_COUNT,
  QueryContractPricingsResult,
  QueryContractPricingsTotalCountResult,
} from 'query/contract';
import { wrapSearchText } from 'query';
import { NUMBER_PER_PAGE, SHORT_POLL_INTERVAL } from 'common/constants';
import ProductPricingTable from './Table';

// Styles
import styles from './container.module.css';
const cx = classnames.bind(styles);

interface Props {
  contractId: string;
  memberId: string;
  timestampHour: string;
  updatePricingsWhere: (where: any) => void;
}

const ProductPricingDetails: FC<Props> = (props) => {
  const { contractId, memberId, timestampHour, updatePricingsWhere } = props;

  const [searchText, updateSearchText] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const {
    data: pricingData,
    loading: pricingLoading,
    refetch: pricingRefetch,
  } = useQuery<QueryContractPricingsResult>(GET_CONTRACT_PRICINGS, {
    variables: {
      contractId,
      memberId,
      offset: 0,
      limit: NUMBER_PER_PAGE,
      where: {
        search_text: { _ilike: wrapSearchText(searchText) },
        pricing: { _neq: 'null' },
      },
      timestampHour,
    },
    pollInterval: SHORT_POLL_INTERVAL,
    skip: !timestampHour,
  });

  const {
    data: countData,
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<QueryContractPricingsTotalCountResult>(
    GET_CONTRACT_PRICINGS_TOTAL_COUNT,
    {
      variables: {
        contractId,
        memberId,
        where: {
          search_text: { _ilike: wrapSearchText(searchText) },
          pricing: { _neq: 'null' },
        },
        timestampHour,
      },
      pollInterval: SHORT_POLL_INTERVAL,
      skip: !timestampHour,
    }
  );

  useEffect(() => {
    pricingRefetch();
    refetchCount();
  }, []);

  useEffect(() => {
    updatePricingsWhere({
      search_text: { _ilike: wrapSearchText(searchText) },
      pricing: { _neq: 'null' },
    });
  }, [searchText]);

  const pricings = pricingData?.dash_get_contract_pricings_by_hour || [];
  const hasPricings = pricings.length > 0;
  const totalCount =
    countData?.dash_get_contract_pricings_by_hour_aggregate?.aggregate.count ||
    0;
  const isFiltered = searchText !== '';
  const disableSearchFilter = !pricingLoading && !hasPricings && !isFiltered;

  return (
    <>
      <section className={cx('toolbar')}>
        <SearchBar
          onSubmit={(text) => {
            updateSearchText(text);
            setPageNumber(1);
          }}
          placeholder='Search by ID or description'
          disabled={disableSearchFilter}
        />
      </section>
      <Paper
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          marginBottom: 16,
        }}
      >
        <ProductPricingTable
          pricings={pricings}
          pricingLoading={pricingLoading}
        />
      </Paper>
      <div style={{ display: 'flex' }}>
        <Pagination
          className={cx('pagination')}
          currentPage={pageNumber}
          loading={countLoading || pricingLoading}
          numberPerPage={NUMBER_PER_PAGE}
          onChangePage={({ currentPage }) => {
            pricingRefetch({ offset: (currentPage - 1) * NUMBER_PER_PAGE });
            setPageNumber(currentPage);
          }}
          isDisplayed={hasPricings}
          totalCount={totalCount as number}
        />
      </div>
    </>
  );
};

export default ProductPricingDetails;
