// Vendor
import DialogContent from '@material-ui/core/DialogContent';
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import Dialog from 'components/Dialog';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  children?: React.ReactNode;
  title: string;
  bodyText?: string;
  onClose: () => void;
}

const InfoModal: FC<Props> = (props) => {
  const { children, onClose, title, bodyText } = props;

  return (
    <Dialog title={title} toggleDialog={() => onClose()}>
      <DialogContent>
        {children ? (
          <div data-testid='children-content'>{children}</div>
        ) : (
          <div className={cx('text')}>{bodyText}</div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
