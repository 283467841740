// Vendor
import dayjs from 'dayjs';

// Internal
import { CSV_TYPE, onDownload } from 'services/export';

type Api = {
  exportCustomersGenerate: (config: any) => Promise<any>;
  exportDownload: (downloadPath: string) => Promise<any>;
  exportStatus: (id: string) => Promise<any>;
};

type ExportCustomersTypes = {
  api: Api;
  exportId: string;
  toggleExportLoading: (value: boolean) => void;
  config: {
    memberId: string | undefined | number;
    date: string;
    membershipsWhere: {};
    where: {};
    format: string;
    fields: string[];
  };
  snackbar: { open: (config: object) => void };
};

export const getNormalizedListInfo = (data: {
  id: string;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  timestamp: string;
}) => ({
  id: data.id,
  name: data.name,
  description: data.description,
  startDate: data.start_date,
  endDate: data.end_date,
  updatedAt: data.timestamp,
});

const checkForStatus = (
  api: Api,
  exportId: string,
  toggleExportLoading: (value: boolean) => void,
  snackbar: { open: (config: object) => void },
  titlePrefix: string
) => {
  api
    ?.exportStatus(exportId)
    .then(
      ({
        data,
      }: {
        data: { status: string; result: { downloadPath: string } };
      }) => {
        const { status } = data;

        if (status === 'complete') {
          toggleExportLoading(false);
          api
            ?.exportDownload(data.result.downloadPath)
            .then(({ data }: any) => {
              const fileNamePrefix = titlePrefix || 'CUSTOMERS';

              onDownload(
                data,
                `${fileNamePrefix}_${dayjs().utc().format('YYYY_MM_DD')}.csv`,
                CSV_TYPE
              );
              snackbar.open({
                message:
                  'All set! Your customer export file is ready and downloading.',
                variant: 'success',
              });
            });
        } else if (status === 'error') {
          toggleExportLoading(false);
        } else {
          window.setTimeout(
            () =>
              checkForStatus(
                api,
                exportId,
                toggleExportLoading,
                snackbar,
                titlePrefix
              ),
            500
          );
        }
      }
    );
};

export const exportCSV = ({
  api,
  config,
  toggleExportLoading,
  snackbar,
  fileNamePrefix,
}: ExportCustomersTypes & { fileNamePrefix: string }) => {
  toggleExportLoading(true);
  api?.exportCustomersGenerate(config).then(({ data }) => {
    const { exportId, status } = data;

    toggleExportLoading(status === 'processing');
    snackbar.open({
      message: `Hang tight! Your customer export file is being prepared for download. This might take a few minutes and we'll notify you when it's ready. Leaving or refreshing the page will cancel the export.`,
      variant: 'info',
    });

    checkForStatus(
      api,
      exportId,
      toggleExportLoading,
      snackbar,
      fileNamePrefix
    );
  });
};
