// Vendor
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import classnames from 'classnames/bind';
import { first, keyBy, orderBy } from 'lodash';
import { useQuery } from '@apollo/client';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

// Internal
import { IconSystemError } from 'styles/images';
import {
  contractPath,
  getSearchParam,
  getTradingPartnerName,
} from 'common/helpers';
import {
  GET_CONTRACT_EVENTS_BY_HOUR,
  GET_CONTRACT_HEADER,
  GET_CONTRACT_PRICINGS,
  GET_LATEST_CONTRACT_EVENT_IN_HOUR,
  GET_TRADING_PARTNERS,
  QueryContractEventsByHour,
  QueryContractPricingsResult,
  QueryTradingPartners,
  wrapSearchText,
} from 'query';
import { POLL_INTERVAL } from 'common/constants';
import {
  useApi,
  useApiService,
  useAppBarSetTitle,
  useContractDetailsExportFlag,
  useContractManagementFlag,
  useSimplifiedContractsFlag,
  useSnackbar,
  useTradingPartnerType,
  useUploadContractPricingFlag,
} from 'hooks';
import { BreadcrumbWithSearch } from 'components/BreadcrumbWithSearch';
import { NodeType } from 'common/config';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import NonLinearTabber from 'components/NonLinearTabber';
import { Button, HistoryTimeline, HistoryView, Status } from 'components';
import { FLATFILE_FLOW } from 'flatfile-import/constants';
import { IdentityPublishAdapter } from 'flatfile-import/application/publisher/publish-adapter';
import contractPricingAdapter from 'flatfile-import/domain/contract-pricing/contract-pricing-adapter';
import {
  RawContractPricing,
  ContractPricingRoster,
  ContractPricingInputData,
} from 'flatfile-import/domain/contract-pricing/contract-pricing-roster';
import { PersistedAggregator } from 'flatfile-import/infrastructure/persisted-aggregator';
import { db } from 'flatfile-import/infrastructure/contract-pricing/contract-pricing-db';
import { BatchPublisherService } from 'flatfile-import/application/publisher/batch-publisher-service';
import {
  concatContractPricings,
  contractPricingSemigroup,
} from 'flatfile-import/domain/contract-pricing/contract-pricing-semigroup';
import { ContractPricingAggregationService } from 'flatfile-import/application/aggregator/contract-pricing/contract-pricing-aggregation-service';
import contractPricingValidationService from 'flatfile-import/application/row-processor/contract-pricing/contract-pricing-validation-service';
import { HttpContractPricingPublisher } from 'flatfile-import/infrastructure/contract-pricing/http-contract-pricing-publisher';
import RosterImport from '../../OONRosterImport/RosterImport/container';
import Distributors from './components/Distributors';
import ContractCustomerDetails from './components/ContractCustomerDetails/container';
import ProductPricingDetails from './components/ProductPricingDetails/container';
import Overview from './components/Overview';
import { handleExport } from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

export interface ExportLoading {
  customersLoading: boolean;
  headerLoading: boolean;
  pricingLoading: boolean;
}

const ContractDetailsContainer = () => {
  const api = useApi();
  const snackbar = useSnackbar();
  const { search } = useLocation();
  const navigate = useNavigate();
  const apiService = useApiService();

  if (!apiService) {
    throw new Error('apiService instance is required but not found.');
  }

  // UI FLAGS
  const simplifiedContracts = useSimplifiedContractsFlag();
  const contractMgmtFlag = useContractManagementFlag();
  const exportFlag = useContractDetailsExportFlag();
  const uploadContractPricingFlag = useUploadContractPricingFlag();

  const xpType = useTradingPartnerType() || '';
  const isMFR = xpType === NodeType.MFR;

  const { id, memberId } = useParams() as {
    id: string;
    memberId: string;
  };

  useAppBarSetTitle(`Contract ID: ${id}`);

  const [params] = useSearchParams();

  const initialTab = Number.parseInt(params.get('initialTab') || '1', 10);
  const [currentTab, setCurrentTab] = useState<number>(initialTab - 1);

  const isHistoryView = !!getSearchParam(search, 'historyView');
  const urlTimestamp = getSearchParam(search, 'update');

  // CUSTOMERS WHERE
  const [customersWhere, updateCustomersWhere] = useState({
    list_ids: { _contains: id },
  });
  // PRICINGS WHERE
  const [pricingsWhere, updatePricingsWhere] = useState({
    search_text: { _ilike: '%' },
    pricing: { _neq: 'null' },
  });

  // EXPORT STATE
  const [exportsLoading, toggleExportLoading] = useState<ExportLoading>({
    customersLoading: false,
    headerLoading: false,
    pricingLoading: false,
  });

  const [statusCollapsed, setStatusCollapsed] = useState<boolean>(false);

  const [memberDetails, setMemberDetails] = useState({} as any);

  const [contractPricingImportOpen, setContractPricingImportOpen] =
    useState(false);

  const { data, error, loading } = useQuery<QueryContractEventsByHour>(
    GET_CONTRACT_EVENTS_BY_HOUR,
    {
      variables: {
        contractId: id,
        memberId,
      },
      pollInterval: POLL_INTERVAL,
    }
  );

  const contractEvents = data?.dash_get_contract_events_by_hour ?? [];

  const timestampHour = isHistoryView
    ? urlTimestamp
    : first(contractEvents)?.hour;

  const {
    data: contractEventsWithinHour,
    loading: contractEventsLoading,
    refetch: refetchContractEvents,
  } = useQuery(GET_LATEST_CONTRACT_EVENT_IN_HOUR, {
    fetchPolicy: 'no-cache',
    variables: {
      memberId,
      contractId: id,
      timestampHour,
    },
    pollInterval: POLL_INTERVAL,
    skip: !id || !timestampHour,
  });

  const {
    data: contractData,
    loading: contractLoading,
    refetch: refetchContractHeader,
  } = useQuery(GET_CONTRACT_HEADER, {
    fetchPolicy: 'no-cache',
    variables: {
      memberId,
      contractId: id,
      timestampHour,
    },
    skip: !id || !timestampHour,
  });

  const { data: pricingData, refetch: refetchPricing } =
    useQuery<QueryContractPricingsResult>(GET_CONTRACT_PRICINGS, {
      fetchPolicy: 'no-cache',
      variables: {
        contractId: id,
        memberId,
        offset: 0,
        limit: 100, // 100 because when we have more than this many errors, the UI can't show them all anyway
        timestampHour,
        where: {
          errors: { _neq: null },
        },
      },
      pollInterval: POLL_INTERVAL,
      skip: !id || !timestampHour,
    });

  const lastestContract =
    contractEventsWithinHour?.dash_contract_events[0] || {};
  const latestTimestamp = lastestContract.timestamp;

  const header = contractData?.dash_contract_headers_history[0] || {};
  const contract = {
    ...header,
    pricings: pricingData?.dash_get_contract_pricings_by_hour?.map((r) => ({
      product_id: r.productId,
      errors: r.errors || [],
    })),
  };

  const { data: tpData = {}, loading: tradingPartnerLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });
  const tradingPartners = keyBy(tpData?.tradingPartners || [], (p) => p.id);

  const timestamp = urlTimestamp
    ? decodeURIComponent(urlTimestamp)
    : contractEvents[0]?.hour;

  // Timeline component is shared with other pages and the logic uses timeline and not hour
  const timelineEvents = contractEvents.map((event) => ({
    timestamp: event.hour,
  }));

  const fetchMemberDetails = async () => {
    const result = await axios('/static/member_detail/member_detail.json');
    if (result.status === 200) {
      setMemberDetails(result.data);
    }
  };

  useEffect(() => {
    // fetchMemberDetails promise returns nothing
    // eslint-disable-next-line no-console
    fetchMemberDetails().then().catch(console.error);
  }, []);

  const isLoading = tradingPartnerLoading;
  const showDistributors = xpType !== NodeType.DIST;

  const onUpdateClick = () => {
    navigate(
      `${contractPath(
        Number.parseInt(memberId, 10),
        contract.list_id
      )}/update?initialStep=${currentTab + 1}`,
      {
        replace: true,
      }
    );
  };

  const onFinishContractPricingImport = useCallback(() => {
    // TODO: Add refetch
    setContractPricingImportOpen(false);
  }, []);

  const aggregationService = useMemo(
    () =>
      new ContractPricingAggregationService(
        new PersistedAggregator(
          db,
          contractPricingSemigroup,
          concatContractPricings
        )
      ),
    []
  );

  const publisherService: BatchPublisherService<
    RawContractPricing,
    RawContractPricing
  > = useMemo(() => {
    const publisher = new HttpContractPricingPublisher(apiService);
    return new BatchPublisherService(publisher);
  }, [apiService]);

  const ContractCustomerDetailsComponent = (
    <ContractCustomerDetails
      contractId={id}
      memberId={memberId}
      latestTimestampInHour={latestTimestamp || ''}
      isHistoryView={isHistoryView}
      updateCustomersWhere={updateCustomersWhere}
    />
  );

  const ProductPricingDetailsComponent = (
    <ProductPricingDetails
      contractId={id}
      memberId={memberId}
      timestampHour={timestamp}
      updatePricingsWhere={updatePricingsWhere}
    />
  );

  const ContractOverviewComponent = (
    <>
      {!isLoading && (
        <Status
          collapsed={statusCollapsed}
          onCollapsedChange={setStatusCollapsed}
          entity={contract}
          timestamp={latestTimestamp || ''}
          tradingPartners={tradingPartners}
        />
      )}
      {!isLoading && (
        <Overview
          contractOverview={contract}
          memberDetails={memberDetails}
          participantType={getTradingPartnerName(xpType)}
          tradingPartners={tradingPartners}
        />
      )}
      {!isLoading && showDistributors && (
        <Distributors
          contractOverview={contract}
          memberDetails={memberDetails}
          title='Distributors'
          tradingPartners={tradingPartners}
          isMFR={isMFR}
        />
      )}
    </>
  );

  if (
    loading ||
    contractLoading ||
    contractEventsLoading ||
    tradingPartnerLoading
  ) {
    return null;
  }

  if (error || !contractEvents.length) {
    return (
      <div className={cx('errorCode')}>
        <IconSystemError />
      </div>
    );
  }

  const showContractPricingBtn =
    uploadContractPricingFlag && !isHistoryView && currentTab === 1;
  const showUpdateBtn = contractMgmtFlag && !isHistoryView;
  const showExportBtn = exportFlag;

  const amountOfButtons = [
    showContractPricingBtn ? 1 : 0,
    showUpdateBtn ? 1 : 0,
    showExportBtn ? 1 : 0,
  ].reduce((acc, n) => acc + n, 0);

  const buttonsClass = amountOfButtons <= 1 ? 'buttons' : 'buttons-plus';

  return (
    <div className={cx('row')}>
      <div className={cx('column')}>
        <RosterImport<
          ContractPricingRoster,
          ContractPricingInputData,
          RawContractPricing,
          RawContractPricing
        >
          entityLabel='Contract Pricing'
          rosterValidator={contractPricingValidationService}
          publisher={publisherService}
          open={contractPricingImportOpen}
          containerId='contract-pricing'
          embedNode={FLATFILE_FLOW.ContractPricing}
          rosterAdapter={contractPricingAdapter}
          formValues={{
            timestamp: new Date().toISOString(),
            contractId: id,
          }}
          onFinish={onFinishContractPricingImport}
          onCancel={() => setContractPricingImportOpen(false)}
          aggregator={aggregationService}
          publishAdapter={new IdentityPublishAdapter<RawContractPricing>()}
        />
        <BreadcrumbWithSearch
          crumbs={['Contracts', id]}
          links={[
            '/contracts',
            `/contracts/${memberId}/${encodeURIComponent(id)}`,
          ]}
          module='contracts'
          storage={SessionStorage}
        />
        <main>
          {isHistoryView && <HistoryView />}
          <div className={cx(buttonsClass)}>
            {showContractPricingBtn && (
              <Button
                dataTestId='uploadContractPricing'
                label='Upload Contract Pricing'
                onClick={() => setContractPricingImportOpen(true)}
                style={{
                  marginRight: 8,
                  minWidth: '180px',
                }}
              />
            )}
            {showUpdateBtn && (
              <Button
                dataTestId='updateButton'
                label='Update'
                onClick={onUpdateClick}
              />
            )}
            {showExportBtn && (
              <Button
                dataTestId='exportContractBtn'
                disabled={Object.values(exportsLoading).includes(true)}
                label='Export All'
                loading={Object.values(exportsLoading).includes(true)}
                onClick={() => {
                  handleExport(
                    api,
                    customersWhere,
                    exportsLoading,
                    {
                      contractId: id,
                      memberId,
                      timestampHour: timestamp as string,
                    },
                    pricingsWhere,
                    snackbar,
                    toggleExportLoading
                  );
                }}
                style={{
                  marginLeft: 8,
                  minWidth: 152,
                }}
              />
            )}
          </div>
          <NonLinearTabber
            fullWidth={amountOfButtons > 1}
            setTab={setCurrentTab}
            tab={currentTab}
            tabs={[
              { label: 'Overview' },
              { label: 'Products & Pricing' },
              { label: 'Contract Customers' },
            ]}
            views={[
              ContractOverviewComponent,
              ProductPricingDetailsComponent,
              ContractCustomerDetailsComponent,
            ]}
          />
        </main>
      </div>
      {!simplifiedContracts && (
        <HistoryTimeline
          pathname={`/contracts/${memberId}/${encodeURIComponent(id)}`}
          refetch={(hour: string) => {
            refetchContractEvents({
              memberId,
              contractId: id,
              timestampHour: hour,
            });
            refetchContractHeader({
              memberId,
              contractId: id,
              timestampHour: hour,
            });
            refetchPricing({
              contractId: id,
              memberId,
              offset: 0,
              limit: 100, // 100 because when we have more than this many errors, the UI can't show them all anyway
              searchText: wrapSearchText(''),
              timestampHour: hour,
              isError: true,
            });
          }}
          timestamp={timestampHour || ''}
          updates={orderBy(timelineEvents, (h) => h.timestamp, ['desc'])}
        />
      )}
    </div>
  );
};

export default ContractDetailsContainer;
