// Vendor
import { head, last } from 'fp-ts/Array';
import { Json, parse } from 'fp-ts/Json';
import {
  ap,
  chain,
  fold,
  fromEither,
  fromNullable,
  Option,
  sequenceArray,
  some,
} from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

// Internal
import { NodeType } from 'common/config';
import {
  GET_MEMBERS,
  GET_TRADING_PARTNERS,
  Member,
  QueryMembers,
  QueryTradingPartners,
  TradingPartner,
  TradingPartnerEntity,
} from '../../../../query';
import {
  useDisplayableTradingPartnerTypes,
  useTradingPartnerConfig,
  useTradingPartnerType,
} from '../../../../hooks';
import { OUT_OF_NETWORK_DIGIT } from '../../../../common/constants';

export interface ParsedIdentifier {
  type: string;
  id: string;
}

export const mergeTradingPartnerWithMember = (
  tradingPartner: TradingPartner,
  member?: Member
): TradingPartnerEntity => {
  const visualStatus = member ? tradingPartner.status : 'pending';
  return {
    ...tradingPartner,
    member,
    visualStatus: visualStatus ?? 'pending',
  };
};

export const isDisplayablePartnerType = (
  partner: TradingPartnerEntity,
  displayableTpIds: string[]
): boolean =>
  displayableTpIds.some(
    (type) => type.toLowerCase() === partner.type.toLowerCase()
  );

export const isDisplayablePartnerByNodeType = (
  partner: TradingPartnerEntity,
  nodeType: NodeType
): boolean => partner.type.toUpperCase() !== nodeType;

export const filterPartnersToShow = (
  partners: TradingPartnerEntity[],
  nodeType: NodeType,
  displayableTpIds: string[]
): TradingPartnerEntity[] =>
  partners.filter(
    (partner) =>
      isDisplayablePartnerType(partner, displayableTpIds) &&
      isDisplayablePartnerByNodeType(partner, nodeType)
  );

export const getMergedPartners = (
  tradingPartners: TradingPartner[],
  members: Member[]
): TradingPartnerEntity[] =>
  tradingPartners.map((tp) =>
    mergeTradingPartnerWithMember(
      tp,
      members.find((member) => member.id === tp.id)
    )
  );

export const parseIdentifier = (
  rawIdentifier: string
): Option<ParsedIdentifier> => {
  const identifierParts = rawIdentifier.split(':');
  const typeTarget = head<string>(identifierParts);
  const idTarget = last<string>(identifierParts);

  const getIdentifier =
    (type: string) =>
    (id: string): ParsedIdentifier => {
      const validTypes = [
        'dea',
        'hin',
        '340b',
        'hrsa',
        'hibcc',
        'npi',
        'ahd',
        'ml',
      ];
      return { type: validTypes.includes(type) ? type : 'custom', id };
    };

  return pipe(some(getIdentifier), ap(typeTarget), ap(idTarget));
};

const parseRawIdentifiers = (identifiers: any): string[] => {
  const parseFn = pipe(identifiers, parse, fromEither, chain(fromNullable));
  return fold<Json, string[]>(
    () => {
      // eslint-disable-next-line no-console
      console.warn(
        `[WARN] Trying to parse invalid JSON for identifiers: ${identifiers}. Using empty identifiers as default.`
      );
      return [];
    },
    (x) => x as string[]
  )(parseFn);
};

export const parseIdentifiers = (identifiers: any): ParsedIdentifier[] => {
  const rawIdentifiers = parseRawIdentifiers(identifiers);
  const identifiersParsed = (rawIdentifiers ?? []).map(parseIdentifier);
  const optIdentifiers = sequenceArray(identifiersParsed);
  return fold<ReadonlyArray<ParsedIdentifier>, ParsedIdentifier[]>(
    () => [],
    (x) => x.concat([])
  )(optIdentifiers);
};

export const useShowableTradingPartners = () => {
  const displayableTpIds = useDisplayableTradingPartnerTypes();
  const nodeType = useTradingPartnerType();
  const [partners, setPartners] = useState<TradingPartnerEntity[]>([]);
  const memberId = useTradingPartnerConfig()?.id;

  const {
    data: dataTradingPartners,
    error: errorTradingPartners,
    refetch: refecthPartners,
    loading: loadingPartners,
  } = useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
    variables: { id: { _lt: OUT_OF_NETWORK_DIGIT, _neq: memberId } },
  });

  const {
    data: dataMembers,
    error: errorMembers,
    refetch: refecthMembers,
    loading: loadingMembers,
  } = useQuery<QueryMembers>(GET_MEMBERS, {
    variables: { memberId },
    skip: (dataTradingPartners?.tradingPartners ?? []).length === 0,
  });

  const error = errorTradingPartners || errorMembers;

  const loading = loadingPartners || loadingMembers;

  useEffect(() => {
    const partners = getMergedPartners(
      dataTradingPartners?.tradingPartners ?? [],
      dataMembers?.dash_members ?? []
    );
    const partnersToShow = filterPartnersToShow(
      partners,
      nodeType,
      displayableTpIds
    );
    setPartners(partnersToShow);
  }, [dataMembers, dataTradingPartners]);

  const refetch = () =>
    refecthPartners({ id: { _lt: OUT_OF_NETWORK_DIGIT, _neq: memberId } }).then(
      () => refecthMembers({ memberId })
    );

  return useMemo(
    () => ({ partners, error, loading, refetch }),
    [partners, error]
  );
};
