// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Divider, Paper } from '@material-ui/core';
import { Dictionary } from 'lodash';

// Internal
import { KeyValuePair, Table } from 'components';
import {
  decorateAndPartitionCustomerErrorsBySeverity,
  formatDate,
  Customer,
  decorateAndPartitionContractErrorsBySeverity,
  isRosterOON,
  formatOONErrorValues,
} from 'common/helpers';
import { ContractViewRow, TradingPartner } from 'query';
import { IconChevron } from 'styles/images';
import { getColumns, getRows } from './helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  entity: ContractViewRow | Customer;
  collapsed: boolean;
  onCollapsedChange: (newCollapsedStatus: boolean) => void;
  timestamp?: string;
  tradingPartners?: Dictionary<TradingPartner>;
}

function isContract(
  entity: ContractViewRow | Customer
): entity is ContractViewRow {
  // So this weird looking condition is to handle these 3 different situations:
  // 1. Contract header without contract pricing: the `list_id` will be present, but the `pricings` will not
  // 2. Contract pricing without contract header: this will happen when pricing arrives before header, and create an entry in the history that doesn't
  // have an associated header. In this case, the `pricings` will be present, but the `list_id` will not (as there's no header)
  // 3. Contract header with contract pricing: both fields will be present. This is the most common case.
  return 'list_id' in entity || 'pricings' in entity;
}

const Status: FC<Props> = (props) => {
  const { entity, collapsed, onCollapsedChange, timestamp, tradingPartners } =
    props;

  const isOON = !isContract(entity) && isRosterOON(Number(entity.memberId));

  const { severityErrors, severityWarnings } = isContract(entity)
    ? decorateAndPartitionContractErrorsBySeverity(entity, tradingPartners)
    : decorateAndPartitionCustomerErrorsBySeverity(entity);

  const hasErrors = severityErrors.length > 0;
  const hasWarnings = severityWarnings.length > 0;
  const hasErrorsToShow = hasErrors || hasWarnings;

  const statusText = !hasErrors
    ? !hasWarnings
      ? 'Valid'
      : 'Warning'
    : 'Invalid';

  const formattedErrors = isOON
    ? formatOONErrorValues(severityErrors)
    : severityErrors;
  const formattedWarnings = isOON
    ? formatOONErrorValues(severityWarnings)
    : severityWarnings;

  const columns = getColumns();
  const rows = getRows(formattedErrors, formattedWarnings);

  return (
    <>
      <div
        className={cx('borderTop', {
          success: !hasErrors && !hasWarnings,
          failure: hasErrors,
          warning: !hasErrors && hasWarnings,
        })}
      />
      <Paper className={cx('paperMargin')}>
        <div className={cx('row', 'paperContent')}>
          <KeyValuePair title='Status' value={statusText} />
          <KeyValuePair
            title='Updated At'
            value={formatDate(timestamp || '')}
          />
          {hasErrorsToShow && (
            <div
              className={cx('arrowContainer')}
              onClick={() => onCollapsedChange(!collapsed)}
            >
              <IconChevron pointUp={!collapsed} />
            </div>
          )}
        </div>
        {!collapsed && hasErrorsToShow && (
          <div className={cx('reasons')}>
            <Divider variant='fullWidth' />
            <header className={cx('header')}>Reasons</header>
            <Table columns={columns} isLoading={false} emptyText='' rows={rows}>
              <colgroup>
                <col style={{ width: '2%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '93%' }} />
              </colgroup>
            </Table>
          </div>
        )}
      </Paper>
    </>
  );
};

export default Status;
