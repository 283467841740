import gql from 'graphql-tag';

export interface QueryNetwork {
  dash_networkUpgrade_by_pk?: NetworkUpgrade;
}

export interface NetworkUpgrade {
  blockNumber: number;
  eventName: string;
  timestamp: string;
  version: string;
}

export const GET_NETWORK_UPGRADE = gql`
  query QueryNetwork {
    dash_networkUpgrade_by_pk(version: "bush") {
      blockNumber
      eventName
      timestamp
      version
    }
  }
`;
