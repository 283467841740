// Vendor
import React, { FC } from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const IconInformation: FC<Props> = ({ className, fill = '#525f7f' }) => (
  <svg className={className} width='16' height='16' viewBox='0 0 16 16'>
    <defs>
      <filter
        id='icon_information'
        width='187.5%'
        height='187.5%'
        x='-43.8%'
        y='-31.2%'
        filterUnits='objectBoundingBox'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
          stdDeviation='2'
        />
        <feComposite
          in='shadowBlurOuter1'
          in2='SourceAlpha'
          operator='out'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          in='shadowBlurOuter1'
          values='0 0 0 0 0.321568627 0 0 0 0 0.37254902 0 0 0 0 0.498039216 0 0 0 0.150568182 0'
        />
      </filter>
      <circle id='icon_information_b' cx='8' cy='8' r='8' />
    </defs>
    <use
      fill='#000'
      filter='url(#icon_information)'
      xlinkHref='#icon_information_b'
    />
    <circle
      cx='8'
      cy='8'
      r='7.25'
      fill={fill}
      stroke='#FFF'
      strokeLinejoin='inherit'
      strokeWidth='1.5'
    />
    <path
      fill='#FFF'
      fillRule='nonzero'
      d='M7.8 5.958c.333 0 .6-.25.6-.575 0-.333-.267-.583-.6-.583-.333 0-.6.25-.6.583 0 .325.267.575.6.575zm.517 5.075V6.675H7.308v4.358h1.009z'
    />
  </svg>
);

export default IconInformation;
