// Vendor
import React, { FC } from 'react';

interface Props {
  className?: string;
  height?: string;
  width?: string;
}

const IconLink: FC<Props> = ({
  className = '',
  height = '16',
  width = '16',
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
  >
    <g clipPath='url(#clip0)'>
      <g filter='url(#filter0_d)'>
        <circle cx='12' cy='12' r='10' fill='#525F7F' />
      </g>
      <path
        d='M12.5195 10.6055C12.3262 10.4121 12.1152 10.2715 11.8867 10.1484C11.7988 10.1133 11.7109 10.1309 11.6406 10.2012L11.5703 10.2715C11.4121 10.4121 11.3242 10.6055 11.3066 10.7988C11.2891 10.8867 11.3418 10.9746 11.4121 11.0273C11.5352 11.0801 11.7285 11.2031 11.8164 11.3086C12.3965 11.8887 12.3965 12.8203 11.8164 13.4004L10.498 14.7188C9.91797 15.2988 8.98633 15.2988 8.40625 14.7188C7.82617 14.1387 7.82617 13.207 8.40625 12.627L9.21484 11.8184C9.26758 11.7656 9.28516 11.6953 9.26758 11.625C9.23242 11.4492 9.19727 11.1504 9.17969 10.957C9.17969 10.7812 8.95117 10.6934 8.82812 10.8164C8.61719 11.0273 8.2832 11.3613 7.7207 11.9238C6.75391 12.8906 6.75391 14.4551 7.7207 15.4043C8.66992 16.3711 10.2344 16.3711 11.2012 15.4043C12.6426 13.9629 12.5723 14.0332 12.6777 13.8926C13.4688 12.9434 13.416 11.502 12.5195 10.6055ZM15.2617 7.86328C14.3125 6.89648 12.748 6.89648 11.7812 7.86328C10.3398 9.30469 10.4102 9.23438 10.3047 9.375C9.51367 10.3242 9.56641 11.7656 10.4629 12.6621C10.6562 12.8555 10.8672 12.9961 11.0957 13.1191C11.1836 13.1543 11.2715 13.1367 11.3418 13.0664L11.4121 12.9961C11.5703 12.8555 11.6582 12.6621 11.6758 12.4688C11.6934 12.3809 11.6406 12.293 11.5703 12.2402C11.4473 12.1875 11.2539 12.0645 11.166 11.959C10.5859 11.3789 10.5859 10.4473 11.166 9.86719L12.4844 8.54883C13.0645 7.96875 13.9961 7.96875 14.5762 8.54883C15.1562 9.12891 15.1562 10.0605 14.5762 10.6406L13.7676 11.4492C13.7148 11.502 13.6973 11.5723 13.7148 11.6426C13.75 11.8184 13.7852 12.1172 13.8027 12.3105C13.8027 12.4863 14.0312 12.5742 14.1543 12.4512C14.3652 12.2402 14.6992 11.9062 15.2617 11.3438C16.2285 10.377 16.2285 8.8125 15.2617 7.86328Z'
        fill='white'
      />
    </g>
    <defs>
      <filter
        id='filter0_d'
        x='-2'
        y='0'
        width='28'
        height='28'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.321569 0 0 0 0 0.372549 0 0 0 0 0.498039 0 0 0 0.150568 0'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

export default IconLink;
