// Vendor
import { Dictionary } from 'lodash';

// Internal
import { NodeType } from 'common/config';
import {
  decorateAndPartitionContractErrorsBySeverity,
  getTradingPartnerNameById,
  truncateDatetime,
} from 'common/helpers';
import { TableColumn, TableRow } from 'components/Table';
import { ContractViewRow, TradingPartner } from 'query';
import { FilterItem } from 'components/Filters/BasicFilter';

import {
  CONTRACT_ENTITY_COLUMN,
  END_DATE_COLUMN,
  ERRORS_COLUMN,
  MANUFACTURER_COLUMN,
  START_DATE_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { FilterStatus } from '../../service';

/**
 * @description converts 'string' type into 'FilterStatus' type
 * @param status 'string' that needs to be converted
 * @returns 'FilterStatus' representation of the provided 'string'
 */
export const convertStringToFilterStatus = (status: string): FilterStatus => {
  const filterStatuses: FilterStatus[] = Object.values(FilterStatus);

  const selectedFilterStatus: FilterStatus | undefined = filterStatuses.find(
    (filterStatus: string) => filterStatus === status
  );

  if (selectedFilterStatus) {
    return selectedFilterStatus;
  } else {
    throw new Error('Provided filter status does not exist');
  }
};

export const getStatusText = (status: FilterStatus): string => {
  const textMap: Record<FilterStatus, string> = {
    [FilterStatus.ALL_STATUS]: 'All Status',
    [FilterStatus.VALID]: 'Valid',
    [FilterStatus.INVALID]: 'Invalid',
  };

  return textMap[status];
};

export const filterItems: FilterItem[] = [
  {
    id: 'all',
    label: 'All Status',
    isDefault: true,
  },
  {
    id: 'valid',
    label: 'Valid',
    isDefault: false,
  },
  {
    id: 'invalid',
    label: 'Invalid',
    isDefault: false,
  },
];

export const getColumns = (tpType: NodeType | undefined): TableColumn[] => {
  const distributor: TableColumn = {
    dataField: 'dist',
    headerName: 'DISTRIBUTOR',
    size: 'small',
    type: 'expanding',
  };

  return [
    {
      displayTextFn: (data: any) => data.id,
      urlFn: (data: any) =>
        `/contracts/${data.memberId}/${encodeURIComponent(data.id)}`,
      headerName: 'CONTRACT ID',
      size: 'small',
      type: 'link',
    },
    {
      dataField: 'description',
      headerName: 'CONTRACT DESCRIPTION',
      size: 'small',
      type: 'expanding',
    },
    ...(tpType !== 'MFR' ? [MANUFACTURER_COLUMN] : []),
    ...(tpType !== 'DIST' ? [distributor] : []),
    ...(tpType !== 'GPO' ? [CONTRACT_ENTITY_COLUMN] : []),
    START_DATE_COLUMN,
    END_DATE_COLUMN,
    UPDATED_AT_COLUMN,
    ERRORS_COLUMN,
  ];
};

export const rawDataToRows = (
  contracts: ContractViewRow[],
  tradingPartners: TradingPartner[],
  tradingPartnersDictionary: Dictionary<TradingPartner>
): TableRow[] => {
  if (contracts.length === 0) return [];

  return contracts.map((contract) => {
    const {
      description,
      contract_entities = [],
      list_id,
      distributors = [],
      end_date,
      list_member_id,
      start_date,
      latest_timestamp,
    } = contract;

    const manufacturer =
      getTradingPartnerNameById(list_member_id, tradingPartners) || [];
    const dist = distributors?.map(
      ({ id }: { id: string }) =>
        getTradingPartnerNameById(id, tradingPartners) || id
    );
    const contractEntities = contract_entities?.map(
      ({ id }: { id: string }) =>
        getTradingPartnerNameById(id, tradingPartners) || id
    );

    const { severityErrors, severityWarnings } =
      decorateAndPartitionContractErrorsBySeverity(
        contract,
        tradingPartnersDictionary
      );

    const isExpandable = dist?.length > 1 || contractEntities?.length > 1;

    return {
      type: 'expanding',
      isExpandable,
      data: {
        id: list_id,
        description,
        manufacturer,
        dist,
        contractEntities,
        startDate: truncateDatetime(start_date),
        endDate: truncateDatetime(end_date),
        timestamp: latest_timestamp,
        severityErrors,
        severityWarnings,
        memberId: list_member_id,
      },
    };
  });
};
