import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

const ErrorImportAlert: FC<{ records: number; onClick: () => void }> = ({
  records,
  onClick,
}) => (
  <div className={cx('error-alert-container')}>
    <span className={cx('error-alert-title')}>
      {`${records} records failed to import`}
    </span>
    <span className={cx('error-alert-description')}>
      Click the link below to see the error details
    </span>
    <span
      className={cx('error-alert-action')}
      onClick={onClick}
      data-testid='show-errors-lbl'
    >
      Show errors
    </span>
  </div>
);

const SuccessImportAlert: FC<{ records: number }> = ({ records }) => (
  <div>
    <span className={cx('success-alert-title')}>
      {records} records successfully uploaded and are currently being processed
    </span>
  </div>
);

export { ErrorImportAlert, SuccessImportAlert };
