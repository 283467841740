import gql from 'graphql-tag';

export interface CustomerList {
  id: string;
  name: string;
  type: 'eligibility' | 'program' | 'gpo' | 'contract';
  description: string;
  timestamp: string;
  start_date: string;
  end_date: string;
}

export interface QueryCustomerListResult {
  customer_list?: CustomerList;
}

export interface QueryCustomersListResult {
  lists?: CustomerList[];
}

export interface QueryCustomersListCountResult {
  customerListsCount?: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_CUSTOMER_LISTS = gql`
  query QueryCustomerLists(
    $limit: Int
    $offset: Int
    $where: dash_customer_lists_bool_exp
    $order: [dash_customer_lists_order_by!]
  ) {
    lists: dash_customer_lists(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order
    ) {
      id
      identifiers
      member_id
      name
      start_date
      end_date
      revised_at
      type
      description
      timestamp
    }
  }
`;

export const QUERY_CUSTOMER_LISTS_COUNT = gql`
  query QueryCustomerListsCount($where: dash_customer_lists_bool_exp!) {
    customerListsCount: dash_customer_lists_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const QUERY_CUSTOMER_LIST_BY_ID = gql`
  query QueryCustomerListById($id: String!, $memberId: Int!) {
    customer_list: dash_customer_lists_by_pk(id: $id, member_id: $memberId) {
      id
      name
      type
      description
      timestamp
      revised_at
      start_date
      end_date
    }
  }
`;

export interface CustomerListById {
  id: string;
  name: string;
  type: string;
}
