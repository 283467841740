// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import {
  useApi,
  useAppBarSetTitle,
  usePriceTypes,
  useProductTypes,
  useSnackbar,
  useTradingPartnerConfig,
  useUnitsOfMeasure,
} from 'hooks';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import { QUERY_PRODUCT_BY_ID, QueryProductResult } from 'query';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  truncateDatetime,
} from 'common/helpers';
import { IconSystemError } from 'styles/images';
import { Relationship } from 'types/generated/Relationship';
import { ProductPrice } from 'types/generated/Product';
import { StringifiedProductPriceWithID } from 'common/types';
import {
  formatProductPricingsForAPI,
  formatProductPricingsFromAPI,
} from './service';
import CreateEditProductsForm from './CreateEditProductsForm';

// Styles
import styles from './EditProduct.module.css';

const cx = classnames.bind(styles);

const EditProduct: FC = () => {
  useAppBarSetTitle('Update Product');

  const navigate = useNavigate();
  const { id: productId } = useParams() as { id: string };
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const api = useApi();
  const snackbar = useSnackbar();

  const priceTypes = usePriceTypes();
  const unitsOfMeasure = useUnitsOfMeasure();
  const productTypes = useProductTypes();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [unitOfMeasure, setUnitOfMeasure] = useState('');
  const [idType, setIdType] = useState('');
  const [pricings, setPricings] = useState<StringifiedProductPriceWithID[]>([]);
  const [externalId, setExternalId] = useState('');
  const [relationships, setRelationships] = useState([] as Relationship[]);

  // Query the existing product
  const { data: oldProduct, loading: oldProductLoading } =
    useQuery<QueryProductResult>(QUERY_PRODUCT_BY_ID, {
      variables: {
        id: productId,
        memberId,
      },
      skip: !(productId && memberId),
    });

  // We query for the updated/created Product
  const { loading } = useQuery<QueryProductResult>(QUERY_PRODUCT_BY_ID, {
    variables: {
      id: productId,
      memberId,
    },
    skip: !(isFormSubmitted && memberId),
    pollInterval: SHORT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ product }) => {
      if (product) {
        navigate(product.id ? `/products/${product.id}` : '/products', {
          replace: true,
        });
        snackbar.open({
          message: `Product ${product.external_id} has been updated.`,
          variant: 'success',
        });
      }
    },
  });

  const fetchingProducts = loading || oldProductLoading;

  const handleSubmit = async (values: any) => {
    const { pricings } = values;

    // format pricings data to make them API-friendly
    const formattedPricings: ProductPrice[] =
      formatProductPricingsForAPI(pricings);

    const params = {
      id: productId,
      externalId: values.externalId,
      memberId: `ml:member:${memberId}`,
      timestamp: new Date().toISOString(),
      description: values.description,
      endDate: convertEndDateToUtcTimestamp(
        truncateDatetime(values.endDate) || ''
      ),
      kind: values.idType,
      prices: formattedPricings,
      relationships,
      revisedAt: new Date().toISOString(),
      startDate: convertStartDateToUtcTimestamp(
        truncateDatetime(values.startDate) || ''
      ),
      unitOfMeasure: values.unitOfMeasure,
    };

    await api?.createProduct(params);

    setIsFormSubmitted(true);
  };

  useEffect(() => {
    if (!oldProductLoading && oldProduct?.product) {
      const { product } = oldProduct;
      setDescription(product.description);
      setStartDate(product.start_date);
      setEndDate(product.end_date);
      setUnitOfMeasure(product.unit_of_measure);
      setIdType(product.kind);
      setPricings(formatProductPricingsFromAPI(product.prices));
      setExternalId(product.external_id);
      setRelationships(product.relationships);
    }
  }, [oldProduct, oldProductLoading]);

  const onCancel = () => {
    navigate(`/products/${productId}`, { replace: true });
  };

  return !memberId ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <CreateEditProductsForm
      handleOnSubmit={handleSubmit}
      onCancel={onCancel}
      id={productId}
      externalId={externalId}
      description={description}
      startDate={startDate}
      endDate={endDate}
      unitOfMeasure={unitOfMeasure}
      idType={idType}
      priceTypes={priceTypes}
      unitsOfMeasure={unitsOfMeasure}
      productTypes={productTypes}
      pricings={pricings}
      fetchingProducts={fetchingProducts}
    />
  );
};

export default EditProduct;
