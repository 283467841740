// Vendor
import React, { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

// Internal
import Section from 'components/Section';
import {
  MultiplePriceProduct,
  ContractFormProps,
} from 'pages/Contracts/Create/constants';
import { getPricingDateRange, productPriceFields } from './service';
import DynamicList from './DynamicList';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  formRemove: (...args: any[]) => any;
  formUpdate: (...args: any[]) => any;
  productIndex: number;
  productRow: MultiplePriceProduct;
  values: ContractFormProps;
}

// Prices Table for one product
const SingleProductPricesRows: FC<Props> = (props) => {
  const { formRemove, formUpdate, productIndex, productRow, values } = props;

  // Create date range variables for pricing when contract start and end date change
  const [pricingStartDate, pricingEndDate] = useMemo<string[]>(
    () => getPricingDateRange(values, productRow),
    [values.startDate, values.endDate]
  );

  return (
    <DynamicList
      formRemove={formRemove}
      formUpdate={formUpdate}
      product={productRow}
      productIndex={productIndex}
      rows={values.products[productIndex]?.pricings ?? []}
      values={values}
    >
      {(pricingIndex: number) => (
        <div className={cx('content-wrapper')}>
          <Section
            customRootStyles={cx('inputs-wrapper')}
            fields={productPriceFields(
              productIndex,
              pricingIndex,
              pricingStartDate,
              pricingEndDate
            )}
            title=''
            values={values}
          />
        </div>
      )}
    </DynamicList>
  );
};

export default SingleProductPricesRows;
