// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { keyBy, last } from 'lodash';

import dayjs from 'dayjs';

// Internal
import {
  ChargebackRequestRow,
  CreditMemoRow,
  CustomerRow,
  GET_CHARGEBACKS,
  GET_LATEST_PRODUCT_BY_ID_AND_CONTRACT_ID,
  GET_TRADING_PARTNERS,
  GetLatestProductByIdAndContractIdResult,
  QUERY_CREDIT_NOTES,
  QueryChargebacks,
  QueryCreditMemos,
  QueryTradingPartners,
} from 'query';
import { SHORT_POLL_INTERVAL } from 'common/constants';
import { IconLoading, IconSystemError } from 'styles/images';
import {
  useApi,
  useAppBarSetTitle,
  useSnackbar,
  useTradingPartnerType,
} from 'hooks';
import { parseMediledgerId } from 'common/helpers';
import { NodeType } from 'common/config';
import { RawOverrideRequest } from 'types/generated/RawOverrideRequest';
import { RawOverrideReply } from 'types/generated/RawOverrideReply';
import { RevisionLookup } from 'types/generated/RevisionLookup';
import { AuditedData } from 'types/generated/AuditedData';
import { RawAuditRequest } from 'types/generated/RawAuditRequest';
import { RawAuditReply } from 'types/generated/RawAuditReply';
import TextFieldModal from 'components/TextFieldModal';
import InfoModal from 'components/InfoModal';
import Invoice from './components/Invoice';
import CustomerDetailModalContent from './components/CustomerDetailModalContent';
import { getChargebackStatus } from '../List/service';
import { ModalKind } from './constants';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

function findAppropriateOriginal(
  chargebacks: ChargebackRequestRow[]
): ChargebackRequestRow | undefined {
  // Note: the chargebacks are already sorted by timestamp desc, so the originalCbks is also sorted
  // by timestamp desc
  const originalCbks = chargebacks.filter((c) => c.operation === 'original');
  const lastApproved = originalCbks.find((c) => {
    const { status } = getChargebackStatus(
      c.errors ?? [],
      false,
      c.responses?.[0]?.kind
    );
    return status === 'Approved';
  });

  // Return the last Approved, or the first Original if all of them are rejected
  return lastApproved ?? last(originalCbks);
}

const Container: FC = () => {
  // Get additional information
  const { id, memberId } = useParams<{ id: string; memberId: string }>();
  const tpType = useTradingPartnerType();

  const api = useApi();
  const snackbar = useSnackbar();

  const [modalKind, showModal] = useState<ModalKind>('none');
  const [showCustomerModal, setShowCustomerModal] = useState<CustomerRow>();
  const [showAuditPreview, setShowAuditPreview] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [request, setRequest] = useState<RevisionLookup>();
  const [auditedData, setAuditedData] = useState<AuditedData>();

  // Fetch the data
  const {
    data: chargebacksData,
    loading,
    error,
  } = useQuery<QueryChargebacks>(GET_CHARGEBACKS, {
    variables: {
      where: {
        id: {
          _eq: id,
        },
        member_id: {
          _eq: memberId,
        },
      },
    },
    skip: !memberId,
    pollInterval: SHORT_POLL_INTERVAL,
  });

  const chargebacks = chargebacksData?.chargebacks ?? [];

  // Fetch trading partners
  const { data: tradingPartnersData } = useQuery<QueryTradingPartners>(
    GET_TRADING_PARTNERS,
    {
      variables: { id: {} },
    }
  );
  const tradingPartners = keyBy(
    tradingPartnersData?.tradingPartners || [],
    (p) => p.id
  );

  const chargeback = chargebacks[0];

  // Fetch all chargebacks on the same thread
  const {
    data: chargebacksByThreadId,
    loading: loading2,
    error: error2,
  } = useQuery<QueryChargebacks>(GET_CHARGEBACKS, {
    variables: {
      where: {
        thread_id: {
          _eq: chargeback?.thread_id,
        },
        member_id: {
          _eq: memberId,
        },
      },
    },
    skip: !memberId || !chargeback,
    pollInterval: SHORT_POLL_INTERVAL,
  });

  const chargebackToShow = chargebacksByThreadId?.chargebacks ?? chargebacks;

  const mainChargeback =
    findAppropriateOriginal(chargebackToShow) ?? chargeback;

  // Fetch product
  const mfrId = parseMediledgerId(mainChargeback?.manufacturer_id).memberId;
  const { data: productData } =
    useQuery<GetLatestProductByIdAndContractIdResult>(
      GET_LATEST_PRODUCT_BY_ID_AND_CONTRACT_ID,
      {
        variables: {
          productId: mainChargeback?.sale.product_id,
          memberId: mfrId,
          contractId: mainChargeback?.contract_id,
        },
        skip: !mfrId,
      }
    );

  const { data: creditNotesData } = useQuery<QueryCreditMemos>(
    QUERY_CREDIT_NOTES,
    {
      variables: {
        where: {
          claim_lines: {
            chargeback_id: { _in: chargebackToShow.map((cbk) => cbk.id) },
            chargeback_member_id: { _eq: memberId },
          },
        },
        skip: chargebackToShow.length === 0,
      },
    }
  );
  const creditNotes: CreditMemoRow[] = creditNotesData?.creditNotes || [];

  const renderModal = () => {
    if (modalKind === 'override-request') {
      return (
        <TextFieldModal
          title='Submit claim for override?'
          bodyText='Enter override request details'
          placeholderText='Override request details...'
          onConfirmationClick={sendOverrideRequest}
          toggleModal={(value) =>
            showModal(value ? 'override-request' : 'none')
          }
          isLoading={isLoading}
        />
      );
    }

    if (modalKind === 'override-rejected') {
      if (!request) {
        throw new Error('request not set');
      }
      return (
        <TextFieldModal
          title='Reject override claim?'
          bodyText='Enter override response details'
          placeholderText='Override response details...'
          onConfirmationClick={(text) =>
            sendOverrideReply(request, false, text)
          }
          toggleModal={(value) =>
            showModal(value ? 'override-rejected' : 'none')
          }
          isLoading={isLoading}
        />
      );
    }

    if (modalKind === 'override-approved') {
      if (!request) {
        throw new Error('request not set');
      }
      return (
        <TextFieldModal
          title='Approve override claim?'
          bodyText='Enter override response details'
          placeholderText='Override response details...'
          onConfirmationClick={(text) => sendOverrideReply(request, true, text)}
          toggleModal={(value) =>
            showModal(value ? 'override-approved' : 'none')
          }
          isLoading={isLoading}
        />
      );
    }

    if (showCustomerModal) {
      if (!showCustomerModal) {
        return null;
      }
      let title: string;
      const isMFR = tpType === NodeType.MFR;
      if (isMFR) {
        title = `Distributor Customer ${showCustomerModal.id}`;
      } else {
        title = `Manufacturer Customer ${showCustomerModal.id}`;
      }
      return (
        <InfoModal
          title={title}
          onClose={() => setShowCustomerModal(undefined)}
        >
          <CustomerDetailModalContent customer={showCustomerModal} />
        </InfoModal>
      );
    }

    if (modalKind === 'audit-request' && tpType === NodeType.MFR) {
      return (
        <TextFieldModal
          title='Adjustment Submission'
          bodyText={`Are you sure you want to submit adjustment for claim ${id}?`}
          placeholderText='Adjustment details...'
          onConfirmationClick={sendAuditRequest}
          toggleModal={(value) => showModal(value ? 'audit-request' : 'none')}
          isLoading={isLoading}
        />
      );
    }

    if (modalKind === 'audit-approved' && tpType === NodeType.DIST) {
      return (
        <TextFieldModal
          title='Adjustment Response'
          bodyText={`Are you sure you want to approve adjustment for claim ${id}?`}
          placeholderText='Adjustment response details...'
          onConfirmationClick={(text) => sendAuditReply(true, text)}
          toggleModal={(value) => showModal(value ? 'audit-approved' : 'none')}
          isLoading={isLoading}
        />
      );
    }

    if (modalKind === 'audit-rejected' && tpType === NodeType.DIST) {
      return (
        <TextFieldModal
          title='Adjustment Response'
          bodyText={`Are you sure you want to reject adjustment for claim ${id}?`}
          placeholderText='Adjustment response details...'
          onConfirmationClick={(text) => sendAuditReply(false, text)}
          toggleModal={(value) => showModal(value ? 'audit-rejected' : 'none')}
          isLoading={isLoading}
        />
      );
    }

    return null;
  };

  const sendOverrideRequest = (text: string) => {
    setLoading(true);
    const request: RawOverrideRequest = {
      id: `${chargeback.id}-override-${dayjs().unix()}`,
      requestNote: text.trim().length > 0 ? text : undefined,
      chargeback: {
        id: chargeback.id,
        memberId: `ml:member:${chargeback.member_id}`,
        timestamp: chargeback.timestamp,
      },
    };
    api
      ?.upsertChargebackOverride(request)
      .then(() => {
        showModal('none');
        snackbar.open({
          message: `Override request has been submitted.`,
          variant: 'success',
        });
      })
      .catch(() => {
        snackbar.open({
          message: `An error occurred trying to submit the override request`,
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const sendOverrideReply = (
    request: RevisionLookup,
    response: boolean,
    responseNote?: string
  ) => {
    setLoading(true);
    const reply: RawOverrideReply = {
      id: `${chargeback.id}-override-reply-${dayjs().unix()}`,
      request,
      response,
      responseNote,
    };
    api
      ?.upsertChargebackOverrideReply(reply)
      .then(() => {
        showModal('none');
        snackbar.open({
          message: `Override response has been submitted.`,
          variant: 'success',
        });
      })
      .catch(() => {
        snackbar.open({
          message: `An error occurred trying to submit the override response`,
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const sendAuditRequest = (text: string) => {
    if (!auditedData) {
      throw new Error('auditedData must be specified');
    }
    if (!request) {
      throw new Error('request must be specified');
    }
    setLoading(true);

    const auditRequest: RawAuditRequest = {
      id: `${chargeback.id}-audit-request-${dayjs().unix()}`,
      auditedData,
      chargeback: request,
      auditNote: text.length > 0 ? text : undefined,
    };

    api
      ?.upsertChargebackAudit(auditRequest)
      .then(() => {
        showModal('none');
        setShowAuditPreview(false);
        snackbar.open({
          message: `Adjustment has been submitted.`,
          variant: 'success',
        });
      })
      .catch(() => {
        snackbar.open({
          message: `An error occurred trying to submit the adjustment`,
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const sendAuditReply = (isApproved: boolean, text: string) => {
    if (!request) {
      throw new Error('request must be specified');
    }
    setLoading(true);

    const auditReply: RawAuditReply = {
      id: `${chargeback.id}-audit-reply-${dayjs().unix()}`,
      response: isApproved,
      auditResult: request,
      responseNote: text.length > 0 ? text : undefined,
    };

    api
      ?.upsertChargebackAuditReply(auditReply)
      .then(() => {
        showModal('none');
        snackbar.open({
          message: `Adjustment response has been submitted.`,
          variant: 'success',
        });
      })
      .catch(() => {
        snackbar.open({
          message: `An error occurred trying to submit the adjustment response`,
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  useAppBarSetTitle(`Invoice # ${mainChargeback?.sale.id ?? ''}`);

  return error || error2 ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : loading || loading2 ? (
    <div className={cx('loading')}>
      <IconLoading />
    </div>
  ) : (
    <>
      {renderModal()}
      <Invoice
        mainChargeback={mainChargeback}
        associatedCreditNotes={creditNotes}
        chargebacks={chargebackToShow}
        tradingPartners={tradingPartners}
        tpType={tpType}
        productData={productData}
        showModal={showModal}
        showAuditPreview={showAuditPreview}
        setShowAuditPreview={setShowAuditPreview}
        setRequest={setRequest}
        setAuditedData={setAuditedData}
        setShowCustomerModal={setShowCustomerModal}
      />
    </>
  );
};

export default Container;
