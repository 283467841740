// Internal
import { wrapSearchText } from 'query';

export enum FilterStatus {
  ALL_STATUS = 'all',
  VALID = 'valid',
  INVALID = 'invalid',
}

export const getWhereClause = (
  statusFilter: FilterStatus,
  searchText: string
) => {
  if (statusFilter === FilterStatus.ALL_STATUS) {
    return {
      search_text: { _ilike: wrapSearchText(searchText) },
    };
  } else if (statusFilter === FilterStatus.INVALID) {
    return {
      _or: [
        { has_errors: { _eq: true } },
        { pricings: { has_errors: { _eq: true } } },
      ],
      search_text: { _ilike: wrapSearchText(searchText) },
    };
  } else {
    return {
      has_errors: { _eq: false },
      _not: { pricings: { has_errors: { _eq: true } } },
      search_text: { _ilike: wrapSearchText(searchText) },
    };
  }
};
