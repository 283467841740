// Vendor
import React, { FC, Fragment, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';

// Internal
import { IconCurrentView, IconHistoryView } from 'styles/images';
import { getDay, getMonthAndYear, getSearchParam } from 'common/helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface TimelineEntry {
  isMapping?: boolean;
  timestamp: string;
}

interface Props {
  pathname: string;
  refetch: (timestamp: string) => void;
  timestamp: string;
  updates: TimelineEntry[];
}

const HistoryTimeline: FC<Props> = (props) => {
  const { pathname, refetch, timestamp, updates } = props;
  const [selectedUpdate, setSelectedUpdate] = useState(
    updates.find((h) => h.timestamp === timestamp) || updates[0]
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation() as { search: string };
  const isHistoryView = !!getSearchParam(search, 'historyView');

  const pushToHistory = (update: TimelineEntry) => {
    const newIsHistoryView = update !== updates[0];
    const urlTimestamp = update.timestamp;

    let mappingTimestamp = null;
    if (update.isMapping) {
      mappingTimestamp = update.timestamp;
    }

    const { hash } = location;
    navigate(
      {
        hash,
        pathname,
        // Note: the s.hour contain special characters (plus and colon), so need to encode it
        search:
          `?update=${encodeURIComponent(urlTimestamp)}${
            newIsHistoryView ? '&historyView=true' : ''
          }` +
          `${
            mappingTimestamp !== null
              ? `&mappingTimestamp=${encodeURIComponent(update.timestamp)}`
              : ''
          }`,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    const update = isHistoryView ? selectedUpdate : updates[0];
    pushToHistory(update);
  }, [updates.length, timestamp]);

  const setUpdate = (update: TimelineEntry) => {
    pushToHistory(update);
    setSelectedUpdate(update);
    refetch(update.timestamp);
  };

  return (
    <div className={cx('root')}>
      {updates.map((update, i) => {
        const isSelectedUpdate = selectedUpdate.timestamp === update.timestamp;
        const isCurrent = i === 0;
        const mediumLine = isCurrent || i + 1 === updates.length;

        return (
          <Fragment key={update.timestamp}>
            {i < 2 && (
              <div className={cx('headerRow', 'row')}>
                {isCurrent ? <IconCurrentView /> : <IconHistoryView />}
                <p className={cx('header')}>
                  {isCurrent ? 'Current' : 'History'}
                </p>
              </div>
            )}
            <div className={cx('row')}>
              <div className={cx('column')}>
                <div className={cx('shortLine')} />
                <div className={cx('dot', { selectedDot: isSelectedUpdate })} />
                <div
                  className={cx('middleDot', { hidden: !isSelectedUpdate })}
                />
                <div
                  className={cx({ longLine: !mediumLine }, { mediumLine })}
                />
              </div>
              <button
                data-testid='history_timeline_refetch_button'
                className={cx('dateText', { selectedDate: isSelectedUpdate })}
                onClick={() => setUpdate(update)}
                type='button'
              >
                <p className={cx('day')}>{getDay(update.timestamp)}</p>
                <p
                  className={cx('month')}
                  data-testid={`selected:${isSelectedUpdate}`}
                >
                  {getMonthAndYear(update.timestamp)}
                </p>
              </button>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default HistoryTimeline;
