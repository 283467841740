// External
import React, { Dispatch, FC, SetStateAction } from 'react';
import classnames from 'classnames/bind';

// Internal
import NonLinearStepper, { StepperElement } from 'components/NonLinearStepper';
import ViewsManager from './ViewsManager';
import { internalOnBack, internalOnNext } from './utils';
import { MultiPageFormStep } from './service';
import { Buttons } from './Buttons';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  activeSubStep: number;
  setActiveSubStep: Dispatch<SetStateAction<number>>;
  onCancel: (activeStep: number) => void;
  onSubmit?: (activeStep: number) => void;
  onBack?: () => void;
  onNext?: (activeStep: number, activsSubStep: number) => void;
  stepsData: MultiPageFormStep[];
  stepperElements: StepperElement[];
  isUpdateMode?: boolean;
  buttonsLoading?: {
    cancel?: boolean;
    submit?: boolean;
    back?: boolean;
    next?: boolean;
  };
  buttonsDisabled?: {
    cancel?: boolean;
    submit?: boolean;
    back?: boolean;
    next?: boolean;
  };
  submitText: string;
  isStepperDisabled?: boolean;
}

const MultiPageForm: FC<Props> = (props) => {
  const {
    activeStep,
    setActiveStep,
    activeSubStep,
    setActiveSubStep,
    onCancel: externalOnCancel,
    onSubmit: externalOnSubmit,
    onNext: externalOnNext = () => {},
    onBack: externalOnBack = () => {},
    stepsData,
    stepperElements,
    buttonsLoading = {
      cancel: false,
      submit: false,
      back: false,
      next: false,
    },
    buttonsDisabled = {
      cancel: false,
      submit: false,
      back: false,
      next: false,
    },
    submitText,
    isStepperDisabled,
    isUpdateMode,
  } = props;

  const onNextCallback = () => {
    internalOnNext(
      stepsData,
      activeStep,
      activeSubStep,
      setActiveStep,
      setActiveSubStep,
      externalOnNext
    );
  };

  const onBackCallback = () => {
    externalOnBack();
    internalOnBack(
      stepsData,
      activeStep,
      activeSubStep,
      setActiveStep,
      setActiveSubStep
    );
  };

  const onSubmitCallback = () => {
    if (externalOnSubmit) externalOnSubmit(activeStep);
  };

  const onCancelCallback = () => {
    externalOnCancel(activeStep);
  };

  const resetSubstepIndex = () => {
    setActiveSubStep(0);
  };

  return (
    <div>
      <div className={cx('navigation-bar')}>
        {/* progress icons */}
        <NonLinearStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          items={stepperElements}
          disabled={isStepperDisabled}
          onChange={resetSubstepIndex}
        />

        {/* buttons */}
        <Buttons
          onCancel={onCancelCallback}
          onSubmit={onSubmitCallback}
          onNext={onNextCallback}
          onBack={onBackCallback}
          step={activeStep}
          stepsAmount={stepsData.length}
          buttonsLoading={buttonsLoading}
          buttonsDisabled={buttonsDisabled}
          submitText={submitText}
          isUpdateMode={isUpdateMode}
        />
      </div>

      {/* render view */}
      <ViewsManager
        stepsData={stepsData}
        activeStep={activeStep}
        activeSubStep={activeSubStep}
        setActiveSubStep={setActiveSubStep}
      />
    </div>
  );
};

export default MultiPageForm;
