import React, { FC, ReactNode } from 'react';
import { TabberPanel } from '../TabberPanel';

interface TabberViewsProps {
  views: ReactNode[];
  tab: number;
}

export const TabberViews: FC<TabberViewsProps> = (props) => {
  const { views, tab: tabIndex } = props;

  const tabberViews: ReactNode[] = views.map((view, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <TabberPanel value={tabIndex} index={index} key={index}>
      {view}
    </TabberPanel>
  ));

  return <>{tabberViews}</>;
};
