// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Divider, Drawer, IconButton, Toolbar } from '@material-ui/core';

// Internal
import { IconExit } from 'styles/images';

// Styles
import styles from './index.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const cx = classnames.bind(styles);

export interface SetPricingDrawerProps {
  open: boolean;
  children: React.ReactNode;
  productId: string;
  toggleDrawer: (open: boolean) => void;
}

export const SetPricingDrawer: FC<SetPricingDrawerProps> = ({
  children,
  open,
  productId,
  toggleDrawer,
}) => (
  <Drawer open={open} anchor='right' data-testid='SetPricingDrawer'>
    <header>
      <Toolbar className={cx('toolbar-container')}>
        <span className={cx('title')}>{productId}</span>
        <IconButton
          className={cx('close-button')}
          onClick={() => toggleDrawer(false)}
          data-testid='SetPricingDrawerCloseButton'
        >
          <IconExit />
        </IconButton>
      </Toolbar>
    </header>
    <Divider />
    {children}
  </Drawer>
);
