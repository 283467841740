import gql from 'graphql-tag';
import { CustomerDetails } from './customer';

export type CustomerParentChild = Pick<
  CustomerDetails,
  | 'id'
  | 'parentId'
  | 'names'
  | 'timestamp'
  | 'documents'
  | 'identifiers'
  | 'errors'
  | 'hasErrors'
>;

export interface QueryCustomerParentChildResult {
  customers: CustomerParentChild[];
}

const CHILD_PARENT_CUSTOMER_FIELDS = `
  documents
  errors
  hasErrors: has_errors
  id
  identifiers
  memberId: member_id
  names
  parentId: parent_id
  timestamp
`;

export const GET_CUSTOMER_CHILDREN = gql`
  query QueryCustomerChildren($memberId: Int!, $id: String!) {
    customers: dash_customers(
      where: {
        member_id: { _eq: $memberId }
        parent_id: { _eq: $id }
      }
    ) {
      ${CHILD_PARENT_CUSTOMER_FIELDS}
    }
  }
`;

export const GET_CUSTOMER_PARENT = gql`
  query QueryCustomerParent($memberId: Int!, $id: String!) {
    customers: dash_customers(
      where: {
        member_id: { _eq: $memberId }
        id: { _eq: $id }
      }
    ) {
      ${CHILD_PARENT_CUSTOMER_FIELDS}
    }
  }
`;
