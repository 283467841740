import React, { FC } from 'react';

import classnames from 'classnames/bind';
import { useAppBarSetTitle } from '../../hooks';
import { DashboardEntryCard, IconSettings } from './DashboardEntry';
import tpmIcon from './icons/tpm.svg';

import nodeIcon from './icons/node.svg';
import statusIcon from './icons/sharing.svg';
import confIcon from './icons/conf.svg';

import styles from './index.module.css';
const cx = classnames.bind(styles);

interface DashboardEntry {
  active: boolean;
  title: string;
  linkTo?: string;
  visualConfig: IconSettings;
}

export const entries: DashboardEntry[] = [
  {
    visualConfig: { icon: tpmIcon, color: '#CAC4FF' },
    title: 'Trading Partner Management',
    linkTo: '/admin/trading-partners',
    active: true,
  },
  {
    visualConfig: { icon: nodeIcon, color: 'rgba(203, 102, 113, 0.24)' },
    title: 'Node Health Check',
    linkTo: '/admin/node-health-check',
    active: true,
  },
  {
    visualConfig: { icon: statusIcon, color: '#B0F6FF' },
    title: 'Sharing and Receiving Status',
    active: false,
  },
  {
    visualConfig: { icon: confIcon, color: '#B3D6FE' },
    title: 'Configuration Settings',
    active: false,
  },
];

export const ITAdminDashboard: FC = () => {
  useAppBarSetTitle('IT Admin Dashboard');

  return (
    <div className={cx('container')}>
      {entries.map((entry) => (
        <DashboardEntryCard
          title={entry.title || ''}
          iconSettings={entry.visualConfig}
          linkTo={entry.linkTo}
          active={entry.active}
          key={entry.title}
        />
      ))}
    </div>
  );
};
