// Vendor
import dayjs from 'dayjs';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';
import { get } from 'lodash';

// Internal
import { CheckboxWithPopover, DatePicker } from 'components';
import { composeValidators, required } from 'common/validation';
import {
  ContractFormProps,
  Distributor,
} from 'pages/Contracts/Create/constants';
import { distributorFormFields } from './service';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  distList: { name: string; label: string }[];
  formUpdate: (...args: any[]) => any;
  oldSelectedDistributors: Distributor[];
  values: ContractFormProps;
}

const Distributors: FC<Props> = (props) => {
  const { distList, formUpdate, oldSelectedDistributors, values } = props;

  const distsFields = distributorFormFields(
    values.startDate || '',
    values.endDate || '',
    distList,
    oldSelectedDistributors
  );

  return (
    <div className={cx('distributors')}>
      <header className={cx('header')}>Distributors</header>
      <div className={cx('distributorsContent')}>
        {distsFields.map((field, i) => {
          const { children, isDisabled = false, label, name } = field;

          return (
            <div data-testid={`distributor-cell-${i}`} key={name}>
              <div className={cx('field')}>
                <Field
                  checked={get(values, `distributors.${i}.checked`, false)}
                  // @ts-ignore
                  component={CheckboxWithPopover}
                  disabled={isDisabled}
                  label={label}
                  name={`distributors.${i}.checked`}
                  handleOnChange={() => {
                    if (!values.distributors[i]?.checked) {
                      formUpdate('distributors', i, {
                        checked: true,
                        id: name,
                        startDate: '',
                        endDate: '',
                      });
                    }
                  }}
                  type='checkbox'
                />
              </div>
              <div className={cx('fields')}>
                {values.distributors[i]?.checked &&
                  children?.map((child: any) => {
                    const {
                      fallbackDate,
                      isDisabled = false,
                      label,
                      maxDate = '',
                      minDate = '',
                      name,
                      validation = [],
                    } = child;

                    return (
                      <div key={name} className={cx('field')}>
                        <div className={cx('key')}>
                          {label}
                          {validation.includes(required) && ' *'}
                        </div>
                        <Field
                          // @ts-ignore
                          component={DatePicker}
                          disabled={isDisabled}
                          fallbackDate={fallbackDate}
                          maxDate={dayjs(maxDate).toDate()}
                          minDate={dayjs(minDate).toDate()}
                          name={`distributors.${i}.${name}`}
                          placeholder={`Enter ${label}`}
                          style={{ width: '100%' }}
                          validate={composeValidators(...validation)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Distributors;
