// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { NavLink, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

// Internal
import { getIcon } from './helpers';

// Styles
import inlineStyles from './navItemInlineStyles';
import styles from './NavItem.module.css';
const cx = classnames.bind(styles);

export interface Props {
  classes: {
    listItem: string;
    listItemSelected: string;
  };
  hash: string;
  text: string;
  subEntry?: boolean;
}

const NavItem: FC<Props> = (props) => {
  const { classes, hash, text, subEntry } = props;
  const { pathname } = useLocation();

  const activePage = pathname.split('/')[1];
  const selected = activePage === hash;
  const subEntryProps: React.CSSProperties = subEntry
    ? { marginLeft: '16px' }
    : {};
  return (
    <NavLink key={text} className={cx('link')} to={`/${hash}`}>
      <ListItem
        button
        disableGutters
        key={text}
        classes={{
          root: classes.listItem,
          selected: classes.listItemSelected,
        }}
        selected={selected}
      >
        <ListItemIcon style={subEntryProps}>
          {getIcon('#fff', hash)}
        </ListItemIcon>
        <ListItemText disableTypography primary={text} />
      </ListItem>
    </NavLink>
  );
};

export default withStyles(inlineStyles)(NavItem);
