// Vendor
import randomstring from 'randomstring';
import React from 'react';

export enum Capitalization {
  lowercase = 'lowercase',
  uppercase = 'uppercase',
}

export enum Charset {
  alphabetic = 'alphabetic',
  alphanumeric = 'alphanumeric',
  binary = 'binary',
  hex = 'hex',
  numeric = 'numeric',
  octal = 'octal',
}

interface RandomStringProps {
  capitalization?: Capitalization;
  charset?: Charset;
  length: number;
}

export const generateRandomString = ({
  capitalization,
  charset = Charset.alphabetic,
  length,
}: RandomStringProps): string =>
  randomstring.generate({ capitalization, charset, length });

/**
 * Generate a random ID with 4 uppercase letters followed by 6 numbers
 */
export function generateID() {
  const fourRandomChars = generateRandomString({
    capitalization: Capitalization.uppercase,
    charset: Charset.alphabetic,
    length: 4,
  });
  // https://www.epochconverter.com (e.g. 1629927488)
  const unixEpochTime = `${Math.floor(new Date().getTime() / 1000.0)}`;
  const shortEpochTime = unixEpochTime.slice(4); // e.g. 927488
  return `${fourRandomChars}${shortEpochTime}`; // Will be 10 chars (e.g. VBCT927488)
}

export const cloneWithProps = (element: React.ReactElement, props: object) =>
  React.cloneElement(element, { ...props });

export const takeFromIterator = <A>(
  it: IterableIterator<A>,
  size: number
): A[] => {
  const result = [];
  for (let i = 0; i < size; i += 1) {
    const current = it.next();
    if (current.done) {
      break;
    }
    result.push(current.value);
  }
  return result;
};
