// External
import React, { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepIconProps,
  StepButton,
} from '@material-ui/core';

// Internal
import styles from './index.module.css';

const cx = classnames.bind(styles);

export type StepperElement = {
  error?: boolean;
  icon: ReactNode;
  label: string;
};

/**
 * @description an icon component that gets its state from Mui context, and internally determines icon styles.
 * This is the only way to style icon of StepLabel, we can't even do it through global MUI style overrides
 */
const StepperIcon: FC<StepIconProps> = (props) => {
  const { active, completed, error, icon } = props;

  const backgroundClassNames: string = cx(
    'icon',
    `${error ? 'icon-error' : ''}`,
    `${active ? 'icon-active' : ''}`,
    `${completed ? 'icon-completed' : ''}`
  );

  // TODO: this line throws a warning `Functions are not valid as a React child.` even though icon is a ReactElement
  return <div className={backgroundClassNames}>{icon}</div>;
};

export interface NonLinearStepperProps {
  items: StepperElement[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
  onChange?: () => void;
}

const NonLinearStepper: FC<NonLinearStepperProps> = (props) => {
  const {
    items,
    activeStep,
    setActiveStep,
    disabled,
    onChange: customOnChange = () => {},
  } = props;

  const handleStep = (index: number) => () => {
    customOnChange();
    setActiveStep(index);
  };

  return (
    <MuiStepper
      alternativeLabel
      activeStep={activeStep}
      classes={{
        root: cx('root'),
      }}
    >
      {items.map((item, index) => {
        const { icon, label, error = false } = item;

        return (
          <Step key={label} disabled={disabled}>
            <StepButton
              onClick={handleStep(index)}
              data-testid={`stepper-button-${index + 1}`}
            >
              <StepLabel
                classes={{
                  root: cx('label'),
                  active: cx('label-active'),
                  completed: cx('label-completed'),
                  error: cx(`${error ? 'label-error' : ''}`),
                }}
                error={error}
                StepIconComponent={StepperIcon}
                StepIconProps={{
                  error,
                  icon: error ? '!' : icon,
                }}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </MuiStepper>
  );
};

export default NonLinearStepper;
