// Vendor
import React, { FC } from 'react';

// Internal
import {
  ContractFormProps,
  Pricing,
  PricingUpdate,
} from 'pages/Contracts/Create/constants';
import ListRow from './ListRow';
import { getPricingDateRange } from '../service';

export interface Props {
  children: any;
  formRemove: (...args: any[]) => any;
  formUpdate: (...args: any[]) => any;
  product: any;
  productIndex: number;
  rows: Pricing[] | PricingUpdate[];
  values: ContractFormProps;
}

enum ListRowButtonType {
  DELETE,
  ADD,
}

const DynamicList: FC<Props> = (props) => {
  const {
    children,
    formRemove,
    formUpdate,
    product,
    productIndex,
    rows,
    values,
  } = props;
  const { id: productId } = product.data;

  const deleteRow = (i: number) => {
    if (rows.length === 1) {
      // clear fields of the row-form
      formUpdate(`products.${productIndex}.pricings`, i, {
        contractPrice: '',
        endDate: '',
        productId,
        startDate: '',
      });
    } else {
      formRemove(`products.${productIndex}.pricings`, i);
    }
  };

  const addRow = (i: number) => {
    const [pricingStartDate, pricingEndDate] = getPricingDateRange(
      values,
      product
    );

    // Add new row
    formUpdate(`products.${productIndex}.pricings`, i + 1, {
      startDate: pricingStartDate,
      endDate: pricingEndDate,
    });
  };

  return (
    <>
      {rows.map((row, i: number) => {
        if (i === rows.length - 1) {
          return (
            <ListRow
              // eslint-disable-next-line react/no-array-index-key
              key={row.startDate + row.endDate + i}
              addRow={() => addRow(i)}
              onSubmit={() => deleteRow(i)}
              buttonType={ListRowButtonType.DELETE}
              secondaryButtonType={ListRowButtonType.ADD}
            >
              {children(i)}
            </ListRow>
          );
        } else {
          return (
            <ListRow
              // eslint-disable-next-line react/no-array-index-key
              key={row.startDate + row.endDate + i}
              onSubmit={() => deleteRow(i)}
              buttonType={ListRowButtonType.DELETE}
            >
              {children(i)}
            </ListRow>
          );
        }
      })}
    </>
  );
};

export default DynamicList;
