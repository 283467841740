// Vendor
import React, { FC } from 'react';

// Internal
import Section from 'components/Section';

interface Props {
  attributes: {
    name: string;
    type: string;
  }[];
}

const Attributes: FC<Props> = (props) => {
  const { attributes } = props;
  return (
    <Section
      fields={attributes.map(({ name, type }) => ({
        label: name,
        name,
        namePrefix: 'attributes',
        type,
      }))}
      title='Attributes'
    />
  );
};

export default Attributes;
