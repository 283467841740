// Vendor
import React from 'react';

const IconCircleCheck = () => (
  <svg width='16' height='16' viewBox='0 0 16 16'>
    <g fill='none' fillRule='evenodd'>
      <g fill='#00BC96'>
        <path
          d='M8 62.25c4.28 0 7.75 3.47 7.75 7.75 0 4.28-3.47 7.75-7.75 7.75C3.72 77.75.25 74.28.25 70c0-4.28 3.47-7.75 7.75-7.75zm4.146 4.69c-.195-.196-.511-.196-.707 0L6.75 71.628l-2.19-2.19c-.195-.195-.511-.195-.706 0l-.708.707c-.195.196-.195.512 0 .708l3.25 3.25c.196.195.512.195.708 0l5.75-5.75c.195-.196.195-.512 0-.708z'
          transform='translate(-1079 -224) translate(288 152) translate(791 10)'
        />
      </g>
    </g>
  </svg>
);

export default IconCircleCheck;
