// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
  size?: string;
}

const IconExit: FC<Props> = ({ color = '#002E5F', size = '28', ...rest }) => (
  <svg {...rest} width={size} height={size} viewBox='0 0 28 28'>
    <defs>
      <path
        id='ExitA'
        d='M12 10.522L9.784 8.306a1.045 1.045 0 0 0-1.478 1.478L10.522 12l-2.216 2.216a1.045 1.045 0 1 0 1.478 1.478L12 13.478l2.216 2.216a1.045 1.045 0 1 0 1.478-1.478L13.478 12l2.216-2.216a1.045 1.045 0 1 0-1.478-1.478L12 10.522z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(2 2)'>
      <mask id='ExitB' fill='#fff'>
        <use xlinkHref='#ExitA' />
      </mask>
      <g fill={color} mask='url(#ExitB)'>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
);

export default IconExit;
