import { Dispatch } from 'redux';
import * as types from './types';

export class AbstractService {
  private _path: string[];

  constructor(path: string[] = []) {
    this._path = path;
  }

  clone() {
    return Object.create(
      Object.getPrototypeOf(this),
      Object.getOwnPropertyDescriptors(this)
    );
  }

  /** Dispatch an update to the store. `data` will be merged over this instance. */
  update(dispatch: Dispatch<types.ServiceAction>, data: any) {
    dispatch({
      type: types.SERVICE_UPDATE,
      payload: [this._path, data],
    });
  }
}

export function stripTypename(obj: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...rest } = obj;
  return rest;
}
