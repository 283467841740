// Vendor
import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

// Internal
import { Breadcrumb, KeyValuePair } from 'components';
import { useAppBarSetTitle } from 'hooks';
import { TradingPartnerEntity } from 'query';
import {
  getBackgroundColor,
  getMemberType,
  getStatusText,
} from 'common/helpers';
import { ValueTooltip } from 'components/Tooltips';
import { parseIdentifiers } from '../service';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  partners: TradingPartnerEntity[];
}

const TradingPartnersList: FC<Props> = ({ partners }) => {
  useAppBarSetTitle('Trading Partner Management');

  return (
    <div className={cx('root')} id='trading-partners-section'>
      <section className={cx('row', 'sub-header')}>
        <Breadcrumb
          crumbs={['Admin Dashboard', 'Trading Partner Management']}
          links={['/admin', `/admin/trading-partners`]}
        />
      </section>
      {partners.map((partner) => {
        const { identifiers, id, name, type, member, visualStatus } = partner;
        const cmiPeerId = member?.cmiPeerId ?? '';
        const mappedIdentifiers = parseIdentifiers(identifiers);

        return (
          <Link
            key={id}
            className={cx('link')}
            to={`/admin/trading-partners/${id}`}
          >
            <Paper key={name} className={cx('paper')}>
              <div className={cx('title')}>{name}</div>
              <div className={cx('subtitle')}>{getMemberType(type)}</div>
              <div className={cx('infoTable')}>
                <KeyValuePair
                  title='Mediledger ID'
                  width='18%'
                  value={id.toString()}
                />
                <KeyValuePair title='Peer ID' width='30%' value={cmiPeerId} />
                <KeyValuePair
                  title='Identifier Type'
                  width='20%'
                  value={mappedIdentifiers[0]?.type.toUpperCase() ?? ''}
                  valueLength={mappedIdentifiers.length - 1}
                  valueIcon={
                    mappedIdentifiers.length > 1 ? (
                      <div data-testid='value-tooltip'>
                        <ValueTooltip
                          header='Identifier Types'
                          values={mappedIdentifiers.map((xs) =>
                            xs.type.toUpperCase()
                          )}
                        />
                      </div>
                    ) : undefined
                  }
                />
                <KeyValuePair
                  title='Identifier ID'
                  width='20%'
                  value={mappedIdentifiers[0]?.id.toUpperCase() ?? ''}
                  valueLength={mappedIdentifiers.length - 1}
                  valueIcon={
                    mappedIdentifiers.length > 1 ? (
                      <div data-testid='value-tooltip'>
                        <ValueTooltip
                          header='Identifier IDs'
                          values={mappedIdentifiers.map((xs) =>
                            xs.id.toUpperCase()
                          )}
                        />
                      </div>
                    ) : undefined
                  }
                />
                <KeyValuePair title='Status' width='13%'>
                  <div className={cx('status')}>
                    <div
                      className={cx('statusText')}
                      style={{
                        background: visualStatus
                          ? getBackgroundColor(visualStatus)
                          : undefined,
                      }}
                    >
                      {visualStatus ? getStatusText(visualStatus) : ''}
                    </div>
                  </div>
                </KeyValuePair>
              </div>
            </Paper>
          </Link>
        );
      })}
    </div>
  );
};
export default TradingPartnersList;
