// Vendor
import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';

// Internal
import PriceField from 'components/PriceField';
import { composeValidators } from 'common/validation';

// styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface PriceInputColumnInterface {
  disabledFn?: (formData: any) => boolean;
  headerName: 'PRICE';
  name: (index: any) => string;
  placeholder?: string;
  size?: Size;
  type: 'priceInput';
  validation?: any[];
}

export type Props = Omit<
  PriceInputColumnInterface,
  'type' | 'headerName' | 'name'
> & {
  name: string;
  formData: any;
  placeholder?: string;
};

const PriceInputCell: FC<Props> = (props) => {
  const {
    disabledFn = () => false,
    formData,
    name,
    placeholder = '',
    size = 'small',
    validation = [],
  } = props;

  const isDisabled = disabledFn(formData) || false;

  return (
    <TableCell size={size} className={cx('cell')}>
      <Field
        // @ts-ignore
        component={PriceField}
        disabled={isDisabled}
        widthVariant='full'
        name={name}
        placeholder={placeholder}
        validate={composeValidators(...validation)}
      />
    </TableCell>
  );
};

export default PriceInputCell;
