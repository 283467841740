// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconSearch: FC<Props> = ({ color = '#FFF' }) => (
  <svg width='20' height='20' viewBox='0 0 18 18'>
    <path
      fill='none'
      fillRule='evenodd'
      stroke={color}
      d='M8.37 15.752A7.373 7.373 0 0 1 1 8.376C1 4.302 4.3 1 8.37 1s7.37 3.302 7.37 7.376c0 4.073-3.3 7.376-7.37 7.376zm5.343-2.045L17.333 17l-3.62-3.293z'
    />
  </svg>
);

export default IconSearch;
