import gql from 'graphql-tag';
import { ErrorCode } from 'types/generated/ErrorCode';

export interface CreditMemoRow {
  id: string;
  claim_lines: [
    {
      chargeback_id: string;
      chargeback_timestamp: string;
    }
  ];
  debit_memo_id: string;
  distributor_member_id: number;
  distributor_name: string;
  manufacturer_member_id: number;
  manufacturer_name: string;
  credit_amount: number;
  created_on: string;
  revised_at: string;
}

export interface ClaimLineRow {
  chargeback_id: string;
  contract_id: string;
  chargeback?: {
    operation: string;
    amount: number;
    contract: {
      list_id: string; // this data is from the chargeback itself, should be the same as the contract_id unless there is no matched contracts
    };
    sale: {
      id: string;
      invoice_date: string;
    };
    responses: [
      {
        kind: string;
      }?
    ];
    errors: ErrorCode[];
  };
}

export interface QueryCreditMemos {
  creditNotes: CreditMemoRow[];
}

export const QUERY_CREDIT_MEMOS = gql`
  query QueryCreditMemos(
    $limit: Int!
    $offset: Int
    $where: dash_credit_notes_bool_exp!
    $order: [dash_credit_notes_order_by!]
  ) {
    creditNotes: dash_credit_notes(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
    ) {
      id
      debit_memo_id
      distributor_member_id
      distributor_name
      manufacturer_member_id
      manufacturer_name
      credit_amount
      created_on
      revised_at
    }
  }
`;

export interface QueryCreditNotesCountResult {
  creditNotesCount?: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_CREDIT_MEMOS_COUNT = gql`
  query QueryCreditMemosCount($where: dash_credit_notes_bool_exp!) {
    creditNotesCount: dash_credit_notes_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export interface GetCreditNote {
  creditNote: CreditMemoRow;
}

export const GET_CREDIT_NOTE_BY_ID = gql`
  query GetCreditNoteById($id: String!) {
    creditNote: dash_credit_notes_by_pk(id: $id) {
      claim_lines_aggregate {
        aggregate {
          count
        }
      }
      id
      debit_memo_id
      distributor_member_id
      distributor_name
      manufacturer_member_id
      manufacturer_name
      credit_amount
      created_on
      revised_at
    }
  }
`;

export interface QueryClaimLines {
  claimLines: ClaimLineRow[];
}

export interface QueryClaimLinesCount {
  claimLinesCount: {
    aggregate: { count: number };
  };
}

export const QUERY_CLAIM_LINES = gql`
  query QueryClaimLines(
    $limit: Int!
    $offset: Int
    $where: dash_claim_lines_bool_exp!
    $order: [dash_claim_lines_order_by!]
  ) {
    claimLines: dash_claim_lines(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
    ) {
      chargeback_id
      contract_id
      chargeback {
        id
        member_id
        timestamp
        contract {
          list_id
        }
        sale {
          id
          member_id
          revised_at
          product_id
          quantity
          unit_of_measure
          invoice_date
        }
        operation
        amount
        responses(order_by: { timestamp: desc_nulls_last }, limit: 1) {
          kind
        }
        errors
      }
    }
  }
`;

export const QUERY_CLAIM_LINES_COUNT = gql`
  query QueryClaimLinesCount($where: dash_claim_lines_bool_exp!) {
    claimLinesCount: dash_claim_lines_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const QUERY_CREDIT_NOTES = gql`
  query QueryCreditNotes($where: dash_credit_notes_bool_exp!) {
    creditNotes: dash_credit_notes(where: $where) {
      id
      claim_lines {
        chargeback_id
        chargeback_timestamp
      }
      debit_memo_id
      distributor_member_id
      distributor_name
      manufacturer_member_id
      manufacturer_name
      credit_amount
      created_on
      revised_at
    }
  }
`;
