// Vendor
import Paper from '@material-ui/core/Paper';
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { KeyValuePair } from 'components';
import { DEFAULT_EMPTY_STATE } from 'common/constants';
import { TradingPartnerEntity } from 'query';
import { getBackgroundColor, getStatusText } from 'common/helpers';

// Styles
import styles from './index.module.css';
import {
  ParsedIdentifier,
  parseIdentifiers,
} from '../../TradingPartners/service';
const cx = classnames.bind(styles);

interface Props {
  partner: TradingPartnerEntity;
}

const Details: FC<Props> = ({ partner }) => {
  const { name, visualStatus: status, identifiers } = partner;
  const parsedIdentifiers = parseIdentifiers(identifiers);

  return (
    <Paper className={cx('paperMargin')}>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Trading Partner Configuration</header>
        <div className={cx('row')}>
          <KeyValuePair
            title='Display Name'
            width='20%'
            value={name || DEFAULT_EMPTY_STATE}
          />
          <KeyValuePair title='Identifier Type' width='30%'>
            {parsedIdentifiers.map((identifier: ParsedIdentifier) => (
              <React.Fragment
                key={`root-domain-${identifier.id}-${identifier.type}`}
              >
                <span
                  key={`domain-${identifier.id}-${identifier.type}`}
                  className={cx('value')}
                >
                  {identifier.type.toUpperCase()}
                </span>
                <br />
              </React.Fragment>
            ))}
          </KeyValuePair>
          <KeyValuePair title='Identifier ID' width='30%'>
            {parsedIdentifiers.map((identifier: ParsedIdentifier) => (
              <React.Fragment
                key={`root-ID-${identifier.id}-${identifier.type}`}
              >
                <span
                  key={`ID-${identifier.id}-${identifier.type}`}
                  className={cx('value')}
                >
                  {identifier.id}
                </span>
                <br />
              </React.Fragment>
            ))}
          </KeyValuePair>
          <KeyValuePair width='20%' title='Status'>
            <div className={cx('status')}>
              <span
                className={cx('statusText')}
                style={{
                  background: status ? getBackgroundColor(status) : undefined,
                }}
              >
                {status ? getStatusText(status) : ''}
              </span>
            </div>
          </KeyValuePair>
        </div>
      </div>
    </Paper>
  );
};

export default Details;
