import { ConnectionStatus } from '../NodeHealthContainer';
import { NodeStatus } from '../../../../hooks';

export const getStatusInfo = (status: ConnectionStatus) => {
  const map = {
    successful: { label: 'Successful', color: '#00bc96' },
    error: { label: 'Failed', color: '#cb6671' },
    pending: { label: 'Pending', color: '#f9bc60' },
    unknown: { label: '', color: '' },
  };

  return map[status] || { label: 'Error', color: '#cb6671' };
};

export const mapStatusFromResponse = (status: NodeStatus): ConnectionStatus => {
  switch (status) {
    case 'success':
      return 'successful';
    default:
      return 'error';
  }
};
