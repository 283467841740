// Vendor
import {
  right,
  left,
  map as mapE,
  getApplicativeValidation,
  mapLeft,
} from 'fp-ts/Either';
import { constant, pipe } from 'fp-ts/lib/function';
import { getSemigroup, map as mapNA } from 'fp-ts/lib/NonEmptyArray';
import { sequenceT } from 'fp-ts/lib/Apply';

// Internal
import { RosterValidator, RowError } from 'flatfile-import/domain/validator';
import { CustomerRosterSchema } from '../../../domain/customer/customer-roster';
import {
  asNonRequiredFieldValidation,
  FieldValidation,
  requiredField,
  Validated,
  validateDate,
} from '../validation';

export const validateRequiredField = (
  row: CustomerRosterSchema,
  key: keyof CustomerRosterSchema
): Validated<RowError, CustomerRosterSchema> => {
  const field = row[key];
  return pipe(
    requiredField(field),
    mapE(constant(row)),
    mapLeft(mapNA((message) => ({ field: key as string, message })))
  );
};

export const validateId: FieldValidation<CustomerRosterSchema> = (row) =>
  validateRequiredField(row, 'id');

export const validateName: FieldValidation<CustomerRosterSchema> = (row) =>
  validateRequiredField(row, 'name');

export const validateZipCode: FieldValidation<CustomerRosterSchema> = (row) => {
  const { zipCode } = row;
  const zipCodeRegExp = /^[0-9]+(-[0-9]+)?$/g;
  const nonRequiredZipCode = asNonRequiredFieldValidation(zipCode, right(row));
  return nonRequiredZipCode((zip) =>
    zipCodeRegExp.test(zip)
      ? right(row)
      : left([{ field: 'zipCode', message: 'Invalid Zip Code' }])
  );
};

export const validateStartDate: FieldValidation<CustomerRosterSchema> = (
  row
) => {
  const { startDate } = row;
  const nonRequiredDate = asNonRequiredFieldValidation<
    string,
    CustomerRosterSchema
  >(startDate, right(row));
  return nonRequiredDate((date) =>
    pipe(
      validateDate(date),
      mapE(constant(row)),
      mapLeft(mapNA((message) => ({ field: 'startDate', message })))
    )
  );
};

export const validateEndDate: FieldValidation<CustomerRosterSchema> = (row) => {
  const { endDate } = row;
  const nonRequiredDate = asNonRequiredFieldValidation<
    string,
    CustomerRosterSchema
  >(endDate, right(row));
  return nonRequiredDate((date) =>
    pipe(
      validateDate(date),
      mapE(constant(row)),
      mapLeft(mapNA((message) => ({ field: 'endDate', message })))
    )
  );
};

export const validateCot: FieldValidation<CustomerRosterSchema> = (row) =>
  asNonRequiredFieldValidation(
    row.cot,
    right(row)
  )((cotValue) =>
    cotValue.trim().length > 0
      ? right(row)
      : left([{ field: 'cot', message: 'Invalid COT' }])
  );

export const validateCity: FieldValidation<CustomerRosterSchema> = (row) => {
  const { city } = row;
  const regExp = /[A-Za-z]{2,}/g;
  const nonRequiredField = asNonRequiredFieldValidation(city, right(row));
  return nonRequiredField((cityValue) =>
    regExp.test(cityValue)
      ? right(row)
      : left([{ field: 'city', message: 'Invalid City' }])
  );
};

export const validateState: FieldValidation<CustomerRosterSchema> = (row) => {
  const { state } = row;
  const regExp = /^[A-Za-z]{2}$/g;
  const nonRequiredField = asNonRequiredFieldValidation(state, right(row));
  return nonRequiredField((stateValue) =>
    regExp.test(stateValue)
      ? right(row)
      : left([{ field: 'state', message: 'Invalid State Code' }])
  );
};

class CustomerRosterValidatorService
  implements RosterValidator<CustomerRosterSchema>
{
  validate(row: CustomerRosterSchema) {
    // Applicative instance with semigroup for collecting errors.
    const nonEmptyArrayAp = getApplicativeValidation(getSemigroup<RowError>());
    return pipe(
      // Sequence will create the placeholder curried function and will apply the ap for each Either instance.
      sequenceT(nonEmptyArrayAp)(
        validateId(row),
        validateName(row),
        validateStartDate(row),
        validateEndDate(row),
        validateCity(row),
        validateState(row),
        validateZipCode(row),
        validateCot(row)
      ),
      mapE(() => row)
    );
  }
}

export default new CustomerRosterValidatorService();
