// External
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Button } from 'components';

// Styles
import styles from '../index.module.css';

export interface Props {
  step: number;
  stepsAmount: number;
  onCancel: () => void;
  onSubmit: () => void;
  submitText: string;
  onNext: () => void;
  onBack: () => void;
  buttonsLoading?: {
    cancel?: boolean;
    submit?: boolean;
    back?: boolean;
    next?: boolean;
  };
  buttonsDisabled?: {
    cancel?: boolean;
    submit?: boolean;
    back?: boolean;
    next?: boolean;
  };
  isUpdateMode?: boolean;
}

const cx = classnames.bind(styles);

export const Buttons: FC<Props> = (props) => {
  const BUTTON_STYLE = {
    marginRight: 8,
    minWidth: 152,
    padding: 0,
  };

  const {
    step,
    stepsAmount,
    onCancel,
    onSubmit,
    onNext,
    onBack,
    buttonsLoading = {
      cancel: false,
      submit: false,
      back: false,
      next: false,
    },
    buttonsDisabled = {
      cancel: false,
      submit: false,
      back: false,
      next: false,
    },
    submitText,
    isUpdateMode,
  } = props;

  const cancelButton = (
    <Button
      color='secondary'
      dataTestId='cancelButton'
      disabled={buttonsDisabled.cancel}
      label='Cancel'
      loading={buttonsLoading.cancel}
      onClick={onCancel}
      variant='outlined'
      style={BUTTON_STYLE}
    />
  );

  const backButton = (
    <Button
      color='secondary'
      dataTestId='backButton'
      disabled={buttonsDisabled.back}
      label='Back'
      loading={buttonsLoading.back}
      onClick={onBack}
      style={BUTTON_STYLE}
      variant='outlined'
    />
  );

  const nextButton = (
    <Button
      dataTestId='nextButton'
      disabled={buttonsDisabled.next}
      label='Next'
      loading={buttonsLoading.next}
      style={BUTTON_STYLE}
      onClick={onNext}
    />
  );

  const submitButton = (
    <Button
      dataTestId='submitButton'
      disabled={buttonsDisabled.submit}
      label={submitText}
      loading={buttonsLoading.submit}
      onClick={onSubmit}
      style={BUTTON_STYLE}
      type='submit'
    />
  );

  const formattedStepsAmount = stepsAmount - 1;

  const isBackButtonVisible = step > 0 && !isUpdateMode;
  const isNextButtonVisible = step < formattedStepsAmount && !isUpdateMode;
  const isSubmitButtonVisible = step === formattedStepsAmount || isUpdateMode;

  return (
    <div className={cx('container', 'toolbar')}>
      <section className={cx('buttons', 'row')}>
        {cancelButton}
        {isBackButtonVisible ? backButton : ''}
        {isNextButtonVisible ? nextButton : ''}
        {isSubmitButtonVisible ? submitButton : ''}
      </section>
    </div>
  );
};
