// Vendor
import React, { FC } from 'react';
import { find } from 'lodash';

// Internal
import { addressTypes, SHORT_TEXT, states } from 'common/constants';
import { authorityIDToName } from 'common/helpers';
import { maxLength, required } from 'common/validation';
import { CustomerViewRow } from 'query';
import { useAuthDatabases, useDEAValidationOnly } from 'hooks';
import { CustomerFormProps } from 'pages/Customers/Create/constants';
import Section from 'components/Section';

interface Props {
  change: <F extends keyof CustomerFormProps>(
    name: F,
    value?: CustomerFormProps[F] | undefined
  ) => void;
  cots: {
    id: string;
    name: string;
  }[];
  identifierData: CustomerViewRow[];
  isUpdateAction?: boolean;
  requiredFields: string[];
  setIdentifier: (customer?: CustomerViewRow) => void;
  values: CustomerFormProps;
}

const Overview: FC<Props> = (props) => {
  const {
    change,
    cots,
    identifierData,
    isUpdateAction,
    requiredFields,
    setIdentifier,
    values,
  } = props;

  const acceptableAuthData = useAuthDatabases();
  const isSimpleDEA = useDEAValidationOnly();

  const selectCots = cots.map(({ id, name }) => ({ label: name, name: id }));
  selectCots.unshift({ label: 'Select Class of Trade', name: '' });

  return (
    <Section
      change={change}
      fields={[
        ...(acceptableAuthData.length > 0
          ? [
              {
                label: `${isUpdateAction ? 'Rei' : 'I'}mport Identifier Data`,
                name: 'identifierData',
                handleOnClick: (id: string) =>
                  setIdentifier(find(identifierData, { id })),
                isDisabled: identifierData.length === 0,
                placeholder: 'Identifier Data',
                selectItems: identifierData.map(({ id, memberId }) => {
                  const tradingPartnerName = authorityIDToName(memberId);
                  const disabled = isSimpleDEA && memberId === 99999;
                  return { disabled, label: tradingPartnerName, name: id };
                }),
                type: 'select',
              },
            ]
          : []),
        {
          label: 'Name',
          name: 'name',
          validation: [
            ...(requiredFields?.includes('name') ? [required] : []),
            maxLength(SHORT_TEXT),
          ],
        },
        {
          label: 'Address Type',
          name: 'addressType',
          selectItems: addressTypes.map(({ label, name }) => ({ label, name })),
          type: 'select',
          validation: [
            ...(requiredFields?.includes('addressType') ? [required] : []),
          ],
        },
        {
          label: 'Address Line 1',
          name: 'address1',
          validation: [
            ...(requiredFields?.includes('address1') ? [required] : []),
          ],
        },
        {
          label: 'Address Line 2',
          name: 'address2',
          validation: [
            ...(requiredFields?.includes('address2') ? [required] : []),
          ],
        },
        {
          label: 'Address Line 3',
          name: 'address3',
          validation: [
            ...(requiredFields?.includes('address3') ? [required] : []),
          ],
        },
        {
          label: 'City',
          name: 'city',
          validation: [
            ...(requiredFields?.includes('city') ? [required] : []),
            maxLength(SHORT_TEXT),
          ],
        },
        {
          label: 'State',
          name: 'state',
          selectItems: states.map(({ label, name }) => ({ label, name })),
          type: 'select',
          validation: [
            ...(requiredFields?.includes('state') ? [required] : []),
          ],
        },
        {
          label: 'Zip',
          name: 'zip',
          validation: [...(requiredFields?.includes('zip') ? [required] : [])],
        },
        {
          label: 'Address Start Date',
          name: 'addressStartDate',
          type: 'date',
          validation: [
            ...(requiredFields?.includes('addressStartDate') ? [required] : []),
          ],
        },
        {
          label: 'Address End Date',
          name: 'addressEndDate',
          type: 'date',
          validation: [
            ...(requiredFields?.includes('addressEndDate') ? [required] : []),
          ],
        },
        {
          label: 'Class of Trade',
          name: 'classOfTrade',
          selectItems: selectCots,
          type: 'select',
          validation: [
            ...(requiredFields?.includes('classOfTrade') ? [required] : []),
          ],
        },
        {
          label: 'Class of Trade Start Date',
          name: 'cotStartDate',
          type: 'date',
          validation: [
            ...(requiredFields?.includes('cotStartDate') ? [required] : []),
          ],
        },
        {
          label: 'Class of Trade End Date',
          name: 'cotEndDate',
          type: 'date',
          validation: [
            ...(requiredFields?.includes('cotEndDate') ? [required] : []),
          ],
        },
      ]}
      title='Overview'
      values={values}
    />
  );
};

export default Overview;
