// Vendor
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { MenuItem } from '@material-ui/core';

// Internal
import { Select, TextField } from 'components';

export interface Props {
  fieldIndex: number;
  fields: string[];
  handleOnChange: (field: string) => void;
  isTextField?: boolean;
  items: {
    inputType?: string;
    label: string;
    name: string;
    attributes?: {
      label: string;
      name: string;
    }[];
  }[];
  separatedFields: string[];
}

const SelectField = (props: Props) => {
  const {
    fieldIndex,
    fields,
    handleOnChange,
    isTextField,
    items,
    separatedFields,
  } = props;

  const newFields = [...fields];
  newFields.shift();

  // Logic specific to if the field is a key text field
  const selectedFieldObj = items.find((fieldValue) =>
    separatedFields[fieldIndex]?.includes('#key')
      ? fieldValue.name === 'key#key'
      : fieldValue.name === separatedFields[fieldIndex]
  );

  return (
    <>
      {isTextField ? (
        <TextField
          disabled={false}
          input={{
            onChange: (e) => {
              separatedFields[fieldIndex] = `${e.target.value}#key`;
              handleOnChange(separatedFields.join('|'));
            },
            value:
              fields[0] && fields[0] !== 'key#key'
                ? fields[0].split('#')[0]
                : '',
          }}
          meta={{
            error: '',
            touched: true,
          }}
          placeholder='Key *'
          {...{ style: { width: 'fit-content' } }}
        />
      ) : (
        <Select
          label={
            items.find((item: any) => item.name === fields[0])?.label ||
            'Select a field'
          }
        >
          {items.map(({ label, name }) => (
            <MenuItem
              key={name}
              onClick={() => {
                separatedFields.length = fieldIndex;
                separatedFields[fieldIndex] = name;
                handleOnChange(separatedFields.join('|'));
              }}
              value={name}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      )}
      {selectedFieldObj?.attributes && (
        <SelectField
          fieldIndex={fieldIndex + 1}
          fields={newFields}
          handleOnChange={handleOnChange}
          isTextField={fields[0] === 'attributes#array'}
          items={selectedFieldObj.attributes}
          separatedFields={separatedFields}
        />
      )}
    </>
  );
};

export default SelectField;
