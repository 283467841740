// Vendor
import dayjs from 'dayjs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import {
  CustomerViewRow,
  QUERY_CUSTOMER_LIST_BY_ID,
  QueryCustomerListResult,
} from 'query';
import {
  useApi,
  useAppBarSetTitle,
  useSnackbar,
  useTradingPartnerConfig,
} from 'hooks';
import { BreadcrumbWithSearch } from 'components/BreadcrumbWithSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import NonLinearTabber from 'components/NonLinearTabber';
import { Button } from 'components';
import { customerListPath } from 'common/helpers';
import { exportCSV, getNormalizedListInfo } from './service';
import Overview from './Overview/Overview';
import CustomersForListTable from './Customers/container';
import { CustomerContractsContainer } from './Contracts/container';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const CustomerListDetails: FC = () => {
  // GET ID FOR TITLE
  const { id: memberId } = useTradingPartnerConfig() ?? { id: 0 };
  const { id } = useParams() as { id: string };
  useAppBarSetTitle(`Customer List ID: ${id}`);

  const [isExportLoading, toggleExportLoading] = useState(false);

  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [customersForExport, setCustomersForExport] = useState<
    CustomerViewRow[]
  >([]);
  const [areCustomersFiltered, setAreCustomersFiltered] = useState(false);
  const [whereCustomers, setWhereCustomers] = useState<any>({});

  const [activeTab, setActiveTab] = useState(0);

  const [detailsInfo, setDetailsInfo] = useState({
    id: '',
    name: '',
    endDate: '',
    startDate: '',
    updatedAt: '',
    description: '',
  });

  const {
    data: customerListDetails,
    loading,
    refetch,
  } = useQuery<QueryCustomerListResult>(QUERY_CUSTOMER_LIST_BY_ID, {
    variables: {
      id,
      memberId,
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (customerListDetails?.customer_list) {
      setDetailsInfo(
        getNormalizedListInfo(customerListDetails?.customer_list || {})
      );
    }
  }, [customerListDetails]);

  const customerListName = customerListDetails?.customer_list?.name;
  const filteredText = areCustomersFiltered ? '_FILTERED' : '';
  const fileNamePrefix = `CUSTOMER_LIST${filteredText}${
    customerListName ? `_${customerListName}` : ''
  }`;

  const whereForCustomersExport = useMemo(
    () => ({
      ...(whereCustomers ?? {}),
      list_memberships: { list_id: { _eq: id } },
    }),
    [whereCustomers]
  );

  const handleExport = () =>
    exportCSV({
      // @ts-ignore
      api,
      snackbar,
      toggleExportLoading,
      config: {
        memberId,
        date: dayjs().format(),
        membershipsWhere: { list_id: { _eq: id } },
        where: whereForCustomersExport,
        format: 'csv',
        fields: [
          'Customer ID',
          'HIN',
          'DEA',
          '340B',
          'Additional IDs',
          'Name 1',
          'Name 2',
          'Name 3',
          'Additional Names',
          'COT',
          'Address Type',
          'Address Line 1',
          'Address Line 2',
          'Address Line 3',
          'City',
          'State',
          'ZIP',
          'Overall Customer Start Date',
          'Overall Customer End Date',
          'Overall Customer Status',
          'Relationship ID',
          'Relationship Name',
          'Relationship Type',
          'Attributes',
          'Customer List Name',
          'Customer Membership Start Date',
          'Customer Membership End Date',
          'Updated At',
        ],
      },
      fileNamePrefix,
    });

  if (loading) return null;

  const exportButtonText = areCustomersFiltered
    ? 'Export Filtered List'
    : 'Export All';
  const exportButtonMinWidth = areCustomersFiltered ? 150 : 100;

  return (
    <div className={cx('root')}>
      <BreadcrumbWithSearch
        crumbs={['Customer Lists', id]}
        links={['/customer-lists']}
        module='customer-lists'
        storage={SessionStorage}
      />
      <section className={cx('row', 'toolbar')}>
        {activeTab === 1 && (
          <Button
            dataTestId={exportButtonText}
            disabled={customersForExport.length === 0}
            label={exportButtonText}
            loading={isExportLoading}
            onClick={handleExport}
            style={{
              minWidth: exportButtonMinWidth,
              marginRight: 10,
            }}
          />
        )}
        <Button
          dataTestId='updateButton'
          label='Update'
          onClick={() =>
            navigate(`${customerListPath(id)}/update`, { replace: true })
          }
          style={{
            minWidth: 90,
            visibility:
              activeTab === 0 || activeTab === 1 ? 'visible' : 'hidden',
          }}
        />
      </section>
      <NonLinearTabber
        setTab={setActiveTab}
        tab={activeTab}
        tabs={[
          { label: 'Overview' },
          { label: 'Customers' },
          { label: 'Contracts' },
        ]}
        views={[
          <Overview detailsInfo={detailsInfo} />,
          <CustomersForListTable
            customerListId={id}
            memberId={memberId}
            setCustomersForExport={setCustomersForExport}
            setAreCustomersFiltered={setAreCustomersFiltered}
            setWhereCustomers={setWhereCustomers}
          />,
          <CustomerContractsContainer
            customerListId={id}
            memberId={memberId}
            listStartDate={detailsInfo.startDate}
            listEndDate={detailsInfo.endDate}
          />,
        ]}
      />
    </div>
  );
};

export default CustomerListDetails;
