// Vendor
import React, { FC, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { DialogContent, LinearProgress } from '@material-ui/core';

// Internal
import { Button, Dialog } from 'components';
import { IconExit, IconUpload } from 'styles/images';
import { useApi, useSnackbar } from 'hooks';
import { RED_ERROR_COLOR } from 'common/constants';
import { CSV_TYPE, onDownload } from 'services/export';
import { dragEnter, dragLeave, dragOver } from './helpers';
import { csvFormat } from './constants';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  toggleDialog: (val: boolean) => void;
}

const UploadCustomers: FC<Props> = ({ toggleDialog }) => {
  const [errorMsg, setErrMsg] = useState('');
  const [file, setFile] = useState(null as File | null);
  const [validFile, setValidFile] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const progressContainerRef = useRef<HTMLDivElement>(null);
  const snackbar = useSnackbar();
  const api = useApi();

  const validateFileType = ({
    file,
  }: {
    file: { type: string; name: string };
  }) => {
    if (file.type === 'application/vnd.ms-excel' || file.name.endsWith('.csv'))
      return true;
    setErrMsg(
      'The file imported contained the incorrect file extension. Only files with extension .csv can be imported.'
    );
    return false;
  };

  const validateFileSize = ({ bytes }: { bytes: number }) => {
    const megabytes = bytes / 1024 / 1024;
    if (megabytes <= 25) return true;
    setErrMsg(
      'The file imported was too large. Only files that are 25MB or less can be imported.'
    );
    return false;
  };

  const handleFiles = (files: FileList) => {
    const file = files[0];
    if (validateFileSize({ bytes: file.size }) && validateFileType({ file })) {
      setValidFile(true);
      if (errorMsg && progressContainerRef.current) {
        progressContainerRef.current.style.backgroundColor = 'transparent';
        progressContainerRef.current.style.display = 'none';
      }
      setErrMsg('');
    } else {
      setValidFile(false);
    }
    setFile(file);
  };

  const fileDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (files.length) handleFiles(files);
  };

  const removeFile = () => {
    const fi = document.getElementById('fileInput') as HTMLInputElement | null;
    if (fi) {
      fi.value = '';
    }
    setValidFile(true);
    setFile(null);
    setErrMsg('');
    if (progressContainerRef.current) {
      progressContainerRef.current.style.display = 'none';
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current?.files?.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current?.click();
  };

  const uploadFiles = () => {
    if (!file) {
      // eslint-disable-next-line no-console
      console.error('uploadFiles: file is null');
      return;
    }
    if (progressContainerRef.current && progressBarRef.current) {
      progressContainerRef.current.style.display = 'block';
      progressBarRef.current.style.display = 'block';
    }

    api
      ?.uploadCSV(file)
      .then(() => {
        setValidFile(true);
        setFile(null);
        snackbar.open({
          message:
            'Customer import file was uploaded successfully. Processing will continue in the background.',
          variant: 'success',
        });
        toggleDialog(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(`Upload error: ${JSON.stringify(err)}`);
        setErrMsg('Error importing file.');
        let errMsg;
        // Note: this API will send back 400 or 500 status code only
        if (err.message.includes('status code 400')) {
          errMsg =
            'The file imported did not meet the correct column format.  Only files that match the example CSV file column format can be imported.';
        } else {
          errMsg =
            'Failed to upload file. Please try again. If the problem persists, please contact your IT team.';
        }
        setErrMsg(errMsg);
        if (progressContainerRef.current && progressBarRef.current) {
          progressContainerRef.current.style.backgroundColor = RED_ERROR_COLOR;
          progressBarRef.current.style.display = 'none';
        }
      });
  };

  const DISABLE_UPLOAD = !file?.name || !validFile;

  return (
    <Dialog
      title='Import Customer Data'
      toggleDialog={() => toggleDialog(false)}
    >
      <DialogContent>
        <div className={cx('root')}>
          <button
            className={cx('button', 'dropContainer')}
            onClick={fileInputClicked}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            type='button'
          >
            <div className={cx('dropMessage')}>
              <input
                className={cx('fileInput')}
                id='fileInput'
                onChange={filesSelected}
                ref={fileInputRef}
                type='file'
                accept='.csv'
              />
              <IconUpload />
              <p>
                {' '}
                Drag here or <span className={cx('blue')}>browse</span> to
                import.
              </p>
              <span className={cx('csvFormat')}>CSV format only.</span>
            </div>
          </button>
          {file?.name && (
            <>
              <div className={cx('fileDisplayContainer')}>
                <div className={cx('column')}>
                  <b>{file?.name}</b>
                  {errorMsg && (
                    <span className={cx('fileErrorMessage')}>{errorMsg}</span>
                  )}
                </div>
                <div className={cx('circle')} />
                <button
                  className={cx('button', 'fileRemove')}
                  onClick={() => removeFile()}
                  type='button'
                >
                  <IconExit color='#fff' size='16' />
                </button>
              </div>
              <div
                className={cx('progressContainer')}
                ref={progressContainerRef}
              >
                <div className={cx('progressBar')} ref={progressBarRef}>
                  <LinearProgress />
                </div>
              </div>
            </>
          )}
          <div className={cx('text')}>
            <b>Format must be exactly like the example</b>
          </div>
          <button
            className={cx('blue', 'button')}
            onClick={() => {
              onDownload(csvFormat, 'myCSV.csv', CSV_TYPE);
            }}
            type='button'
          >
            Download CSV format example
          </button>
        </div>
        <div className={cx('buttonContainer')}>
          <Button
            color='secondary'
            dataTestId='cancelButton'
            label='Cancel'
            onClick={() => {
              toggleDialog(false);
            }}
            style={{ width: 120 }}
            variant='outlined'
          />
          <Button
            dataTestId='importButton'
            disabled={DISABLE_UPLOAD}
            label='Import'
            onClick={uploadFiles}
            style={{
              border: 'none',
              marginLeft: 8,
              padding: 0,
              width: 120,
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadCustomers;
