// Vendor
import MUIAppBar from '@material-ui/core/AppBar';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Toolbar } from '@material-ui/core';

// Internal
import { IconChevronLeft, IconChevronRight } from 'styles/images';

// Styles
import inlineStyles from './inlineStyles';
import styles from './AppBar.module.css';
const cx = classnames.bind(styles);
// @ts-ignore
const cxInline = classnames.bind(inlineStyles);

interface Props {
  appBar: any;
  classes: {
    appBarClose: string;
    appBarOpen: string;
    button: string;
    label: string;
  };
  open: boolean;
  toggleDrawer: (open: boolean) => void;
}

const AppBar: FC<Props> = (props) => {
  const { appBar: appBarState, classes, open, toggleDrawer } = props;
  const pageTitle = get(appBarState, 'title');

  return (
    <MUIAppBar
      className={cxInline({
        [classes.appBarOpen]: open,
        [classes.appBarClose]: !open,
      })}
      position='fixed'
    >
      <Toolbar disableGutters variant='dense'>
        <IconButton
          classes={{
            root: classes.button,
            label: classes.label,
          }}
          className={cx('menuButton', { hide: open })}
          onClick={() => toggleDrawer(true)}
        >
          <IconChevronRight />
        </IconButton>
        <IconButton
          classes={{
            root: classes.button,
            label: classes.label,
          }}
          className={cx('menuButton', { hide: !open })}
          onClick={() => toggleDrawer(false)}
        >
          <IconChevronLeft />
        </IconButton>
        {pageTitle}
      </Toolbar>
    </MUIAppBar>
  );
};

// @ts-ignore
export default withStyles(inlineStyles, { withTheme: true })(AppBar);
