// Vendor
import React, { FC } from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classnames from 'classnames/bind';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

// Internal
import {
  IconAlert,
  IconError,
  IconSuccess,
  IconAlertCustom,
} from 'styles/images';

// Styles
import inlineStyles from './inlineStyles';
import styles from './SnackbarContentWrapper.module.css';
const cx = classnames.bind(styles);

const Info = (props: any) => (
  <IconAlert {...props} fill='#1482fc' height={24} width={24} />
);
const Warning = (props: any) => (
  <IconAlert {...props} fill='#f99704' height={24} width={24} />
);

const getIcon = (variant: string) => {
  const iconMapper = {
    error: IconError,
    info: Info,
    session: Info,
    success: IconSuccess,
    warning: Warning,
    'error-alert': IconAlertCustom,
  };
  return get(iconMapper, variant, Info);
};

interface Props {
  classes: any;
  message?: string | Element;
  variant: 'error' | 'info' | 'session' | 'success' | 'warning' | 'error-alert';
}

const SnackbarContentWrapper: FC<Props> = ({ classes, message, variant }) => {
  const Icon = getIcon(variant);

  return (
    <SnackbarContent
      aria-describedby='client-snackbar'
      className={cx(classes[variant])}
      message={
        <div style={{ display: 'flex' }}>
          <Icon className={classes.icon} />
          <span className={cx('text')}>{message}</span>
        </div>
      }
    />
  );
};

// @ts-ignore
export default withStyles(inlineStyles)(SnackbarContentWrapper);
