// Internal
import * as types from 'actions/types';
import { CommonState } from 'reducers/state';

const defaultState: CommonState = {
  appBar: {
    title: null,
  },
  snackbar: {
    duration: 4000,
    message: '',
    open: false,
    variant: undefined,
  },
};

const commonReducer = (
  state = defaultState,
  action: types.CommonAction
): CommonState => {
  switch (action.type) {
    case types.APP_BAR_TITLE_RESET:
      return {
        ...state,
        appBar: {
          ...defaultState.appBar,
        },
      };
    case types.APP_BAR_TITLE_SET:
      return {
        ...state,
        appBar: {
          title: action.payload as string | null,
        },
      };
    case types.SNACKBAR_HIDE:
      return {
        ...state,
        snackbar: defaultState.snackbar,
      };
    case types.SNACKBAR_SHOW:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...action.payload,
          open: true,
        },
      };
    default:
      return state;
  }
};

export default commonReducer;
