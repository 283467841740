import gql from 'graphql-tag';

import { ErrorCode } from 'types/generated/ErrorCode';
import { Relationship } from 'types/generated/Relationship';
import { CustomerAddress } from '../types/generated/CustomerAddress';

export interface SaleRow {
  id: string;
  member_id: number;
  drop_shipment: boolean;
  invoice_date: string;
  product_id: string;
  quantity: number;
  unit_of_measure: string;
  identifiers: string[];
  revised_at: string;
}

export interface ClassOfTradeRow {
  startDate: string;
  endDate: string;
  classOfTradeId: string;
}

export interface CustomerRow {
  id: string;
  member_id: number;
  names: string[] | null;
  identifiers: string[] | null;
  addresses: CustomerAddress[] | null;
  errors: ErrorCode[];
  timestamp?: string;
  classes_of_trade: ClassOfTradeRow[] | null;
}

export interface ChargebackResponseRow {
  kind: 'error' | 'success';
  has_errors: boolean;
  errors: ErrorCode[];
  timestamp?: string;
}

export interface OverrideAudit {
  id: string;
  member_id: number;
  timestamp: string;
  operation: string;
  data: any;
}

export interface ChargebackRequestRow {
  id: string;
  member_id: number;
  timestamp: string;
  contract_id?: string;
  contract_member_id?: number;
  contract_timestamp?: string;
  contract_revised_at?: string;
  distributor_customer_id: string;
  manufacturer_customer_id: string;
  distributor_customer?: CustomerRow;
  manufacturer_customer?: CustomerRow;
  sale: SaleRow;
  responses?: ChargebackResponseRow[];
  override_audit?: OverrideAudit[];
  manufacturer_id: string;
  amount: number;
  submitted_amount: number;
  contract_price: number;
  credit_id: string;
  debit_memo_id: string;
  identifiers: string[];
  operation: string;
  quantity: number;
  relationships: Relationship[];
  wac_price: number;
  revised_at: string;
  _search_text: string;
  has_errors: boolean;
  errors?: ErrorCode[];
  thread_id: string;
  prev_cbk_id?: string;
}

export interface QueryChargebacks {
  chargebacks: ChargebackRequestRow[];
}

export const QUERY_CHARGEBACKS = gql`
  query QueryChargebacks(
    $limit: Int!
    $offset: Int
    $where: dash_chargebacks_bool_exp!
    $order: [dash_chargebacks_order_by!]
  ) {
    chargebacks: dash_chargebacks(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
    ) {
      id
      member_id
      timestamp
      contract_id
      contract_member_id
      contract_timestamp
      contract_revised_at
      distributor_customer_id
      manufacturer_customer_id
      sale {
        id
        member_id
        drop_shipment
        invoice_date
        product_id
        quantity
        unit_of_measure
        identifiers
        revised_at
      }
      responses(order_by: { timestamp: desc_nulls_last }) {
        kind
        has_errors
        errors
        timestamp
      }
      override_audit(order_by: { timestamp: desc_nulls_last }, limit: 1) {
        operation
        data
      }
      manufacturer_id
      amount
      submitted_amount
      contract_price
      credit_id
      debit_memo_id
      identifiers
      operation
      quantity
      relationships
      wac_price
      revised_at
      _search_text
      has_errors
      errors
    }
  }
`;

export interface QueryChargebacksCountResult {
  chargebacksCount?: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_CHARGEBACKS_COUNT = gql`
  query QueryChargebacksCount($where: dash_chargebacks_bool_exp!) {
    chargebacksCount: dash_chargebacks_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CHARGEBACKS = gql`
  query GetChargebacks($where: dash_chargebacks_bool_exp!) {
    chargebacks: dash_chargebacks(
      where: $where
      order_by: { timestamp: desc_nulls_last }
    ) {
      id
      member_id
      timestamp
      contract_id
      contract_member_id
      contract_timestamp
      contract_revised_at
      distributor_customer_id
      manufacturer_customer_id
      distributor_customer {
        id
        member_id
        identifiers
        names
        addresses
        timestamp
        errors
        classes_of_trade
      }
      manufacturer_customer {
        id
        member_id
        identifiers
        names
        addresses
        timestamp
        errors
        classes_of_trade
      }
      sale {
        id
        member_id
        drop_shipment
        invoice_date
        product_id
        quantity
        unit_of_measure
        identifiers
        revised_at
      }
      responses(order_by: { timestamp: desc_nulls_last }) {
        kind
        has_errors
        errors
        timestamp
      }
      override_audit(order_by: { timestamp: desc_nulls_last }) {
        id
        member_id
        timestamp
        operation
        data
      }
      manufacturer_id
      amount
      submitted_amount
      contract_price
      credit_id
      debit_memo_id
      identifiers
      operation
      quantity
      relationships
      wac_price
      revised_at
      _search_text
      has_errors
      errors
      thread_id
      prev_cbk_id
    }
  }
`;
