// Vendor
import dayjs from 'dayjs';
import React, { FC } from 'react';

// Internal
import { useApi, useSnackbar, DocumentApiArgs } from 'hooks';
import { Document } from 'types/generated/Document';
import DeleteDialog from './DeleteDialog';
import { CustomerWithDocuments } from '../types';

interface Props {
  changeSection: (section: string) => void;
  customer: CustomerWithDocuments;
  deletePayload: {
    documentType: string;
    manufacturer: string;
  };
  memberId: number;
  selectedDocument: Document;
  toggleDialog: (val: boolean) => void;
}

const Container: FC<Props> = ({
  changeSection,
  customer,
  deletePayload,
  memberId,
  selectedDocument,
  toggleDialog,
}) => {
  const api = useApi();
  const snackbar = useSnackbar();

  const apiArgs: DocumentApiArgs = {
    id: customer.id,
    memberId: `ml:member:${memberId}`,
    timestamp: dayjs(Date.now()).utc(),
    actions: [
      {
        action: 'delete',
        document: selectedDocument,
      },
    ],
  };

  const deleteDocument = () => {
    const { documentType, manufacturer } = deletePayload;
    api?.documents(apiArgs)?.then(() => {
      changeSection('documentList');
      snackbar.open({
        message: `${manufacturer}'s ${documentType} has been removed.`,
        variant: 'success',
      });
    });
  };

  return (
    <DeleteDialog
      deleteDocument={deleteDocument}
      deletePayload={deletePayload}
      toggleDialog={toggleDialog}
    />
  );
};

export default Container;
