// Vendor
import React, { FC } from 'react';

interface Props {
  height?: number | string;
  width?: number | string;
}

const IconEmptyResults: FC<Props> = ({ height = 223, width = 245 }) => (
  <svg width={width} height={height} viewBox='0 0 245 223'>
    <defs>
      <rect id='path-1' x='0' y='0' width='237' height='53' rx='5' />
      <filter
        x='-3.0%'
        y='-9.4%'
        width='105.9%'
        height='126.4%'
        filterUnits='objectBoundingBox'
        id='filter-2'
      >
        <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          stdDeviation='2'
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.305882353   0 0 0 0 0.749019608   0 0 0 0 0.788235294  0 0 0 0.314113451 0'
          type='matrix'
          in='shadowBlurOuter1'
        />
      </filter>
      <rect id='path-3' x='0' y='0' width='237' height='53' rx='5' />
      <filter
        x='-3.0%'
        y='-9.4%'
        width='105.9%'
        height='126.4%'
        filterUnits='objectBoundingBox'
        id='filter-4'
      >
        <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          stdDeviation='2'
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.305882353   0 0 0 0 0.749019608   0 0 0 0 0.788235294  0 0 0 0.314113451 0'
          type='matrix'
          in='shadowBlurOuter1'
        />
      </filter>
      <rect id='path-5' x='0' y='0' width='237' height='53' rx='5' />
      <filter
        x='-3.0%'
        y='-9.4%'
        width='105.9%'
        height='126.4%'
        filterUnits='objectBoundingBox'
        id='filter-6'
      >
        <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          stdDeviation='2'
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.305882353   0 0 0 0 0.749019608   0 0 0 0 0.788235294  0 0 0 0.314113451 0'
          type='matrix'
          in='shadowBlurOuter1'
        />
      </filter>
    </defs>
    <g id='GTIN' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        id='01_GTIN-Search_No-results-found'
        transform='translate(-464.000000, -374.000000)'
      >
        <g id='Empty-State' transform='translate(453.000000, 374.000000)'>
          <g id='Graphic' transform='translate(15.000000, 0.000000)'>
            <circle id='Oval' fill='#F5F6FA' cx='118.5' cy='111.5' r='111.5' />
            <g id='Tables' transform='translate(0.000000, 22.000000)'>
              <g id='Row-3' transform='translate(0.000000, 126.000000)'>
                <g id='Rectangle'>
                  <use
                    fill='black'
                    fillOpacity='1'
                    filter='url(#filter-2)'
                    xlinkHref='#path-1'
                  />
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-1' />
                </g>
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='48'
                  y='22'
                  width='61'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='119'
                  y='22'
                  width='45'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='174'
                  y='22'
                  width='45'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-3'
                  fill='#60A8F7'
                  x='15'
                  y='17'
                  width='20'
                  height='20'
                  rx='5'
                />
              </g>
              <g id='Row-2' transform='translate(0.000000, 63.000000)'>
                <g id='Rectangle'>
                  <use
                    fill='black'
                    fillOpacity='1'
                    filter='url(#filter-4)'
                    xlinkHref='#path-3'
                  />
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-3' />
                </g>
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='48'
                  y='22'
                  width='61'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='119'
                  y='22'
                  width='45'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='174'
                  y='22'
                  width='45'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-3'
                  fill='#60A8F7'
                  x='15'
                  y='17'
                  width='20'
                  height='20'
                  rx='5'
                />
              </g>
              <g id='Row-1'>
                <g id='Rectangle'>
                  <use
                    fill='black'
                    fillOpacity='1'
                    filter='url(#filter-6)'
                    xlinkHref='#path-5'
                  />
                  <use fill='#FFFFFF' fillRule='evenodd' xlinkHref='#path-5' />
                </g>
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='48'
                  y='22'
                  width='61'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='119'
                  y='22'
                  width='45'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-4'
                  fill='#E6E8F2'
                  x='174'
                  y='22'
                  width='45'
                  height='10'
                  rx='3'
                />
                <rect
                  id='Rectangle-3'
                  fill='#60A8F7'
                  x='15'
                  y='17'
                  width='20'
                  height='20'
                  rx='5'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconEmptyResults;
