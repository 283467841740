import is from 'is_js';

export function browserDetect() {
  // If not IE Explorer 11 nor Chrome, alert
  // (for Opera, is_js returns `false` for `is.not.chrome()`)
  const isUnsupportedBrowser = (is.not.chrome() && is.not.ie(11)) || is.opera();

  if (isUnsupportedBrowser) {
    // eslint-disable-next-line no-alert
    window.alert(
      'Your browser version is not supported. Please use Chrome or Internet Explorer 11 for an optimized experience.'
    );
  }
}
