// Internal
import { ProductPrice } from 'types/generated/Product';
import { Relationship } from 'types/generated/Relationship';

export interface ButtonsLoading {
  next: boolean;
  submit: boolean;
}

export interface Distributor {
  checked: boolean;
  id: string;
  startDate: string;
  endDate: string;
}

export interface FormList {
  checked: boolean;
  id: string;
}

export interface Pricing {
  contractPrice: number;
  endDate: string;
  startDate: string;
  priceType?: string;
}

export interface PricingUpdate {
  contractPrice: number;
  endDate: string;
  productId: string;
  startDate: string;
  unitOfMeasure: string;
  wac: number;
}

export interface Product {
  description: string;
  externalId: string;
  id: string;
  idType: string;
  pricings: Pricing[];
  productPrices?: ProductPrice[];
  rawPriceValue: number;
  unitOfMeasure: string;
}

export interface ContractFormProps {
  contractId: string;
  contractType: string;
  customerList: string;
  description: string;
  distributors: Distributor[];
  endDate: string;
  entity: string;
  openClosed: string;
  overallPriceType: string;
  priceTypes: {
    externalId: string;
    priceType: string;
  }[];
  products: Product[];
  productLists?: FormList[];
  startDate: string;
}

export const emptyFormObject: ContractFormProps = {
  contractId: '',
  contractType: '',
  customerList: '',
  description: '',
  distributors: [],
  endDate: '',
  entity: '',
  openClosed: '',
  overallPriceType: 'WAC',
  priceTypes: [],
  productLists: [],
  products: [],
  startDate: '',
};

export const openClose = [
  { name: 'Open', label: 'Open' },
  { name: 'Closed', label: 'Closed' },
];

export interface MultiplePriceProduct {
  type: 'expanding';
  isExpandable?: boolean;
  data: {
    id: string;
    externalId: string;
    idType: string;
    description: string;
    relationships: Relationship[];
    pricing: ContractProductPrice[];
    unitOfMeasure: string;
    priceTypes: string[];
    priceValue: string;
    productPrices?: ProductPrice[];
    rawPriceValue: string | number;
    startDate: string | null;
    endDate: string | null;
    updatedAt: string | null;
  };
}

export interface ContractProductPrice {
  startDate: string;
  endDate: string;
  contractPrice: string | number;
  id: string;
  wac?: number | string;
  unitOfMeasure?: string;
}

export type ContractMode = 'create' | 'update';

// This is a special case where we want less pricings per page due to usability reasons.
export const NUMBER_OF_PRICINGS_PER_PAGE = 10;
