// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Internal
import { KeyValuePair, ViewSection } from 'components';
import { CustomerAddress } from 'types/generated/CustomerAddress';
import { formatAddressType } from 'common/helpers';
import { KeyValueProps } from 'components/ViewSection';

// Styles
import inlineStyles from './inlineStyles';
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  addresses: CustomerAddress[];
  classes: {
    divider: string;
  };
  memberName?: string;
}

const Addresses: FC<Props> = (props) => {
  const { addresses, classes, memberName } = props;

  const content = addresses?.map((address, idx) => {
    const { address1, address2, address3, addressType, city, state, zipCode } =
      address;

    const keyValues = [
      { key: 'Address Type', value: formatAddressType(addressType) },
      { key: 'Address Line 1', value: address1 },
      { key: 'Address Line 2', value: address2 },
      { key: 'Address Line 3', value: address3 },
      { key: 'City', value: city },
      { key: 'State', value: state },
      { key: 'ZIP', value: zipCode },
    ];

    const notLast = idx < addresses.length - 1;

    return (
      <div key={address1 + address2 + address3}>
        <div className={cx('content')}>
          {keyValues.map((props) => {
            const { key = '', value } = props as KeyValueProps;
            return <KeyValuePair key={key} title={key} value={value} />;
          })}
        </div>
        {notLast && (
          <Divider
            classes={{
              root: classes.divider,
            }}
          />
        )}
      </div>
    );
  });

  return (
    <ViewSection
      dataTestid='addresses'
      disableCollapse={addresses.length === 0}
      emptySection={addresses.length === 0}
      header='Addresses'
      sourceName={memberName}
    >
      {content}
    </ViewSection>
  );
};

export default withStyles(inlineStyles)(Addresses);
