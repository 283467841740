// Internal
import { localCustomerPath } from 'common/helpers';
import {
  ADDRESS_COLUMN,
  COT_COLUMN,
  END_DATE_COLUMN,
  ERRORS_COLUMN,
  IDENTIFIERS_COLUMN,
  NAME_COLUMN,
  START_DATE_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { TableColumn } from 'components/Table';

export const COLUMNS: TableColumn[] = [
  {
    headerName: 'CUSTOMER ID',
    type: 'link',
    size: 'small',
    displayTextFn: (data: any) => data.id,
    urlFn: (data: any) => localCustomerPath(data.id),
  },
  NAME_COLUMN,
  IDENTIFIERS_COLUMN,
  ADDRESS_COLUMN,
  COT_COLUMN,
  START_DATE_COLUMN,
  END_DATE_COLUMN,
  {
    dataField: 'membershipStartDate',
    headerName: 'MEMBERSHIP START DATE',
    type: 'expanding',
    size: 'small',
  },
  {
    dataField: 'membershipEndDate',
    headerName: 'MEMBERSHIP END DATE',
    type: 'expanding',
    size: 'small',
  },
  UPDATED_AT_COLUMN,
  ERRORS_COLUMN,
];
