// Vendor
import React, { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

// Internal
import { useApiService, useAppBarSetTitle } from 'hooks';
import { Breadcrumb } from 'components';
import oonRosterAdapter from 'flatfile-import/domain/customer/oon-customer/oon-roster-adapter';
import { FLATFILE_FLOW } from 'flatfile-import/constants';
import { HttpCustomerPublisher } from 'flatfile-import/infrastructure/customer/http-customer-publisher';
import customerRosterValidatorService from 'flatfile-import/application/row-processor/customer/customer-roster-validator-service';
import { BatchPublisherService } from 'flatfile-import/application/publisher/batch-publisher-service';
import { toMediledgerMemberId } from 'common/helpers/mlIdUtils';
import { CustomerAggregationService } from 'flatfile-import/application/aggregator/customer/customer-aggregation-service';
import { PersistedAggregator } from 'flatfile-import/infrastructure/persisted-aggregator';
import { db } from 'flatfile-import/infrastructure/customer/customer-db';
import { CustomerRosterInputData } from 'flatfile-import/domain/customer/customer-roster-adapter';
import { CustomerRosterSchema } from 'flatfile-import/domain/customer/customer-roster';
import { Customer } from 'types/generated/Customer';
import { UpsertCustomer } from 'types/generated/UpsertCustomer';
import upsertPublishAdapter from 'flatfile-import/application/publisher/customer/upsert-customer-publish-adapter';
import {
  concatCustomers,
  customerSemigroup,
} from 'flatfile-import/domain/customer/customer';
import RosterImport from './RosterImport/container';
import OONForm, { OONFormValues } from './components/OONForm';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

const OONRosterImport: FC = () => {
  const { memberId, memberName } = useParams();
  const navigate = useNavigate();
  if (!memberId || !memberName) {
    throw new Error(
      'Roster memberId and memberName fields must be provided in the URL'
    );
  }

  const apiService = useApiService();
  if (!apiService) {
    throw new Error('apiService instance is required but not found.');
  }

  useAppBarSetTitle(`Out-of-Network Roster Import - ${memberName}`);
  const [isRosterImportOpen, setIsRosterImportOpen] = useState(false);
  const [rosterFormValues, setRosterFormValues] = useState<
    OONFormValues | undefined
  >({ memberId, rosterId: '' });

  const handleUpload = (formValues: OONFormValues) => {
    setRosterFormValues(formValues);
    setIsRosterImportOpen(true);
  };

  const publisherService = useMemo(() => {
    const publisher = new HttpCustomerPublisher(apiService, true);
    return new BatchPublisherService(publisher);
  }, [apiService]);
  const aggregationService = useMemo(
    () =>
      new CustomerAggregationService(
        new PersistedAggregator(db, customerSemigroup, concatCustomers)
      ),
    []
  );

  return (
    <>
      {rosterFormValues && (
        <RosterImport<
          CustomerRosterSchema,
          CustomerRosterInputData,
          Customer,
          UpsertCustomer
        >
          entityLabel='Customer'
          publishAdapter={upsertPublishAdapter}
          rosterValidator={customerRosterValidatorService}
          publisher={publisherService}
          open={isRosterImportOpen}
          containerId='oon'
          embedNode={FLATFILE_FLOW.OutOfNetwork}
          rosterAdapter={oonRosterAdapter}
          formValues={{
            memberId: toMediledgerMemberId(rosterFormValues.memberId),
            rosterId: rosterFormValues.rosterId,
            timestamp: new Date().toISOString(),
            identifiersDelimiters: {
              dea: rosterFormValues.deaDelimiter,
              hin: rosterFormValues.hinDelimiter,
              _340b: rosterFormValues._340bDelimiter,
            },
          }}
          aggregator={aggregationService}
          onFinish={() => setIsRosterImportOpen(false)}
          onCancel={() => setIsRosterImportOpen(false)}
        />
      )}
      <div className={cx('breadcrumb-section')}>
        <Breadcrumb
          crumbs={['Roster', memberName ?? '', 'Import']}
          links={['/roster', `/roster/${memberId}/${memberName}`]}
        />
      </div>
      <OONForm
        isLoading={false}
        onSubmit={handleUpload}
        onCancel={() => navigate(`/roster/${memberId}/${memberName}`)}
        initialValues={rosterFormValues}
      />
    </>
  );
};

export default OONRosterImport;
