// Vendor
import { Semigroup } from 'fp-ts/lib/Semigroup';
import { struct } from 'fp-ts/Eq';
import { Eq as eqString } from 'fp-ts/string';

// Internal
import { ContractEntity } from 'types/generated/ContractEntity';
import { getOptionalEq } from 'common/eqUtils';
import { RawContractHeader } from './contract-header-roster';
import { getArraySemigroup } from '../../../common/fpUtils';
import { getConcatAll } from '../semigroup-util';

const eqContractEntity = struct<ContractEntity>({
  id: eqString,
  endDate: getOptionalEq(eqString),
  startDate: getOptionalEq(eqString),
});
const contractEntityListSemigroup = getArraySemigroup(eqContractEntity);

export const contractHeaderSemigroup: Semigroup<RawContractHeader> = {
  concat(x, y): RawContractHeader {
    const {
      id,
      description,
      open,
      endDate,
      startDate,
      contractType,
      timestamp,
    } = x;
    return {
      id,
      description,
      startDate,
      endDate,
      contractType,
      open,
      contractEntities: contractEntityListSemigroup.concat(
        x.contractEntities ?? [],
        y.contractEntities ?? []
      ),
      distributors: contractEntityListSemigroup.concat(
        x.distributors ?? [],
        y.distributors ?? []
      ),
      timestamp,
    };
  },
};

export const concatContractHeaders = (contractHeaders: RawContractHeader[]) =>
  getConcatAll<RawContractHeader>(contractHeaders, contractHeaderSemigroup);
