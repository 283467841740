// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { first, get } from 'lodash';
import { useParams } from 'react-router-dom';

// Internal
import { Checkbox } from 'components';
import {
  GET_CUSTOMER_CHILDREN,
  GET_CUSTOMER_PARENT,
  QueryCustomerParentChildResult,
} from 'query/parent';
import { toNamedIdentifier } from 'common/helpers';
import { RED_ERROR_COLOR } from 'common/constants';
import { Tooltip } from 'components/Tooltips';
import { IconErrorAlert } from 'styles/images';
import { CustomerWithDocuments } from '../../../types';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

// Constants
const inputText = 'Which customer(s) do you want to apply the document(s) to?';
const reviewText = 'Customers applying the documents to';

interface Props {
  customer: CustomerWithDocuments;
  indent?: number;
  isReviewSection?: boolean;
  memberId: number;
  showChildren?: boolean;
  values: {};
}

const Entities: FC<Props> = (props) => {
  const {
    customer,
    indent = 0,
    isReviewSection = false,
    memberId,
    showChildren,
    values,
  } = props;
  const { id: currentCustomerId } = useParams() as { id: string };
  const id = customer.id || '';
  const identifiers =
    customer.identifiers?.filter((i) => !i.startsWith('ml')) || [];
  const name = first(customer.names || []) || '';
  const { parentId } = customer;
  const isError = customer.hasErrors;
  const isCurrentCustomer = id === currentCustomerId;

  const { data: parentData, loading: loadingParent } =
    useQuery<QueryCustomerParentChildResult>(GET_CUSTOMER_PARENT, {
      variables: {
        id: parentId,
        memberId,
      },
      skip: !parentId || showChildren,
    });

  const { data: childrenData, loading: loadingChildren } =
    useQuery<QueryCustomerParentChildResult>(GET_CUSTOMER_CHILDREN, {
      variables: {
        id,
        memberId,
      },
      skip: !id || !showChildren,
    });

  if (loadingParent || loadingChildren) {
    return null;
  }

  const parent = first(parentData?.customers);
  const childCustomers = childrenData?.customers || [];

  if (parent) {
    // while customer has a parent, keep rendering next customer up the tree instead
    return (
      <Entities
        customer={parent}
        isReviewSection={isReviewSection}
        memberId={memberId}
        showChildren={false}
        values={values}
      />
    );
  } else if (!showChildren) {
    // once customer has no parent, start rendering tree from top
    return (
      <div className={cx('root')}>
        <span className={cx('title')}>
          {isReviewSection ? reviewText : inputText}
        </span>
        <Entities
          customer={customer}
          isReviewSection={isReviewSection}
          memberId={memberId}
          showChildren
          values={values}
        />
      </div>
    );
  }

  const formValue = get(values, `entities._${id}`, false);
  const checked = isCurrentCustomer || formValue;

  // render customer and each of its children
  return (
    <>
      {(!isReviewSection || (isReviewSection && checked)) && (
        <div
          className={cx('field')}
          style={{ paddingLeft: `${indent * 20}px` }}
        >
          <span className={cx('cell')}>
            <Field
              key={name}
              checked={checked}
              // @ts-ignore
              component={Checkbox}
              disabled={isCurrentCustomer || isReviewSection || isError}
              label={name}
              name={`entities._${id}`}
            />
            {isError && (
              <Tooltip
                alwaysActive
                title='Please ensure that the latest customer update is valid before making changes to documents'
              >
                <IconErrorAlert className={cx('icon')} fill={RED_ERROR_COLOR} />
              </Tooltip>
            )}
          </span>
          <span>
            Customer ID {id} | {identifiers.map(toNamedIdentifier).join(' | ')}
          </span>
        </div>
      )}
      {childCustomers.map((c) => (
        <Entities
          key={c.id}
          customer={c}
          indent={indent + 1}
          isReviewSection={isReviewSection}
          memberId={memberId}
          showChildren
          values={values}
        />
      ))}
    </>
  );
};

export default Entities;
