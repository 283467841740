// Vendor
import dayjs from 'dayjs';
import React, { ChangeEvent, FC } from 'react';
import classnames from 'classnames/bind';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  GridSize,
  OutlinedInput,
} from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// Internal
import { SHORT_DATE_FORMAT } from 'common/constants';
import { dateFromISOString } from 'common/helpers/date';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface FilterDefinitionsInterface {
  allSelected?: boolean;
  filters: {
    label: string;
    name: string;
    type?: string;
  }[];
  filterValues: any;
  fullSize?: boolean;
  initialValues?: any;
  title: string;
  id?: string;
}

export interface Props extends FilterDefinitionsInterface {
  setFilters: any;
}

const FilterGroup: FC<Props> = (props) => {
  const {
    allSelected,
    filters,
    filterValues,
    fullSize = true,
    initialValues,
    setFilters,
    title,
  } = props;
  const endOfDay: Date = dayjs().endOf('day').toDate();

  const valuesAreArray = Array.isArray(filterValues);

  const handleAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilters(initialValues, true);
    } else {
      setFilters([], false);
    }
  };

  const handleArrayChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const newValues = [...filterValues];

    if (event.target.checked) {
      // Append the selected program to the array
      newValues.push(name);
    } else {
      // Remove the selected program from the array
      const index = newValues.indexOf(name);
      if (index >= 0) {
        newValues.splice(index, 1);
      }
    }

    setFilters(newValues, newValues.length === initialValues.length);
  };

  // Handle checkbox changes
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    setFilters({ ...filterValues, [name]: checked });
  };

  const handleDateChange = (
    date: Date | [Date | null, Date | null] | null,
    name: string
  ) => {
    if (date) setFilters({ ...filterValues, [name]: date });
  };

  const handleDateRangeChange = (
    name: string,
    from?: Date | [Date | null, Date | null] | null,
    to?: Date | [Date | null, Date | null] | null
  ) => {
    if (from) {
      setFilters({ ...filterValues, [name]: { ...filterValues[name], from } });
    } else if (to) {
      setFilters({ ...filterValues, [name]: { ...filterValues[name], to } });
    } else {
      console.error('From and To should not be null at the same time');
    }
  };

  return (
    <Grid key={title} item xs={fullSize ? (12 as GridSize) : (6 as GridSize)}>
      <div className={cx('header')}>{title}</div>
      <Grid container direction={fullSize ? 'row' : 'column'}>
        {typeof allSelected !== 'undefined' && (
          <Grid item xs={fullSize ? (6 as GridSize) : (12 as GridSize)}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allSelected}
                  name='all'
                  onChange={handleAllChange}
                />
              }
              label='All'
            />
            <br />
          </Grid>
        )}
        {filters.map(({ label, name, type = 'checkbox' }) => {
          if (type === 'date') {
            return (
              <ReactDatePicker
                key={name}
                id={name}
                name={name}
                title={name}
                wrapperClassName={cx('datePicker')}
                calendarClassName={cx('calendar')}
                className={cx('input')}
                onChange={(date) => handleDateChange(date, name)}
                selected={filterValues[name]}
                maxDate={endOfDay}
                customInput={
                  <OutlinedInput
                    fullWidth
                    value={dayjs(filterValues[name].valueOf()).format(
                      SHORT_DATE_FORMAT
                    )}
                    endAdornment={<KeyboardArrowDownIcon />}
                  />
                }
              />
            );
          }
          if (type === 'date-range') {
            const fromPickerName = `${name}-from`;
            const toPickerName = `${name}-to`;
            return (
              <div
                className={cx('date-range-section')}
                key={`range-${fromPickerName}-${toPickerName}`}
              >
                <ReactDatePicker
                  key={fromPickerName}
                  id={fromPickerName}
                  name={fromPickerName}
                  title={fromPickerName}
                  wrapperClassName={cx('datePicker')}
                  calendarClassName={cx('calendar')}
                  className={cx('input')}
                  onChange={(date) =>
                    handleDateRangeChange(name, date, undefined)
                  }
                  selected={dateFromISOString(filterValues[name].from)}
                  maxDate={dateFromISOString(filterValues[name].to)}
                  customInput={
                    <OutlinedInput
                      fullWidth
                      value={
                        filterValues[name].from
                          ? dayjs(filterValues[name].from?.valueOf()).format(
                              SHORT_DATE_FORMAT
                            )
                          : ''
                      }
                      endAdornment={<KeyboardArrowDownIcon />}
                    />
                  }
                />
                <FormControlLabel control={<></>} label='To' />
                <ReactDatePicker
                  key={toPickerName}
                  id={toPickerName}
                  name={toPickerName}
                  title={toPickerName}
                  wrapperClassName={cx('datePicker')}
                  calendarClassName={cx('calendar')}
                  className={cx('input')}
                  onChange={(date) =>
                    handleDateRangeChange(name, undefined, date)
                  }
                  minDate={dateFromISOString(filterValues[name].from)}
                  selected={dateFromISOString(filterValues[name].to)}
                  customInput={
                    <OutlinedInput
                      fullWidth
                      value={
                        filterValues[name].to
                          ? dayjs(filterValues[name].to?.valueOf()).format(
                              SHORT_DATE_FORMAT
                            )
                          : ''
                      }
                      endAdornment={<KeyboardArrowDownIcon />}
                    />
                  }
                />
              </div>
            );
          }
          return (
            <Grid
              key={name}
              item
              xs={fullSize ? (6 as GridSize) : (12 as GridSize)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      valuesAreArray
                        ? filterValues.includes(name)
                        : filterValues[name]
                    }
                    name={name}
                    onChange={(e) =>
                      valuesAreArray
                        ? handleArrayChange(e, name)
                        : handleChange(e)
                    }
                  />
                }
                label={label}
              />
              <br />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FilterGroup;
