// Vendor
import React, { FC } from 'react';
import { ValueSourceSelectorProps } from 'react-querybuilder';
import { MenuItem } from '@material-ui/core';
import { capitalize } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';

// Internal
import { Select } from 'components';
import { FieldType } from 'pages/CustomerProposals/RuleConfig/ManageRules/types';
import { getValueSources } from '../../services';

export interface Props extends ValueSourceSelectorProps {
  conditionFields: FieldType[];
}

const CustomValueSourceSelector: FC<Props> = (props) => {
  const { conditionFields, field, handleOnChange, value = '' } = props;

  const valueSources = getValueSources(conditionFields, field);

  if (valueSources.length === 1) return null;

  return (
    <Select label={value === 'value' ? 'Text' : capitalize(value)}>
      {valueSources.map((valueSource) => (
        <MenuItem
          key={valueSource}
          onClick={() => handleOnChange(valueSource)}
          value={valueSource}
        >
          {valueSource === 'value' ? 'Text' : capitalize(valueSource)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomValueSourceSelector;
