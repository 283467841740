// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Documents, Entities, InputValues, Manufacturer } from '..';
import { CustomerWithDocuments } from '../../../types';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  change: (key: string, value: string) => void;
  customer: CustomerWithDocuments;
  memberId: number;
  values: {
    documentType: string;
    manufacturer: string;
  };
}

const Review: FC<Props> = (props) => {
  const { change, customer, memberId, values } = props;

  return (
    <div className={cx('root')}>
      <Manufacturer disabled change={change} values={values} />
      <Documents disabled values={values} />
      <InputValues disabled values={values} />
      <Entities
        isReviewSection
        customer={customer}
        memberId={memberId}
        values={values}
      />
    </div>
  );
};

export default Review;
