// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconChargebacks: FC<Props> = ({ color = '#011F42' }) => (
  <svg width='16' height='17' viewBox='0 0 16 17'>
    <g fill={color} fillRule='nonzero' stroke={color} strokeWidth='.5'>
      <path d='M12.19 4.122l-.322.405c1.844 1.404 2.764 3.676 2.4 5.93a6.21 6.21 0 0 1-2.559 4.096 6.475 6.475 0 0 1-4.769 1.125c-3.492-.538-5.886-3.755-5.337-7.17a6.21 6.21 0 0 1 2.56-4.095A6.463 6.463 0 0 1 8.277 3.22l-1.33 1.366.38.354 1.965-2.017L7.229 1l-.362.372L8.3 2.707a6.99 6.99 0 0 0-4.446 1.29 6.719 6.719 0 0 0-2.77 4.431c-.593 3.695 1.997 7.176 5.774 7.758.365.056.73.084 1.093.084 1.455 0 2.869-.448 4.068-1.302a6.718 6.718 0 0 0 2.768-4.43c.393-2.44-.602-4.898-2.596-6.416z' />
      <path d='M8.434 7.21v-.608H7.84v.612l-.154.018c-.368.044-1.57.267-1.57 1.32 0 1.038.844 1.213 1.947 1.334.401.046 1.463.169 1.463.903 0 .595-.56.963-1.46.963-.57 0-1.252-.245-1.557-.715l-.56.232c.42.758 1.254.943 1.732.984l.159.014v.676h.593v-.714l.162-.012c.796-.056 1.598-.52 1.598-1.427 0-1.09-.905-1.286-2.09-1.429-.574-.067-1.363-.159-1.363-.81 0-.542.494-.853 1.356-.853.509 0 1.038.212 1.31.51l.503-.228c-.265-.419-.682-.652-1.327-.748l-.15-.023z' />
    </g>
  </svg>
);

export default IconChargebacks;
