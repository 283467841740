// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import Paper from '@material-ui/core/Paper';

// Internal
import { formatDate } from 'common/helpers';
import { KeyValuePair } from 'components';
import { ProductList } from 'types/generated/ProductList';

import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  detailsInfo?: ProductList;
}

const Overview: FC<Props> = ({ detailsInfo }) => {
  if (!detailsInfo) {
    return null;
  }

  return (
    <Paper className={cx('paperMargin')}>
      <div className={cx('column', 'paperContent')}>
        <header className={cx('header')}>Overview</header>
        <div className={cx('content')}>
          <KeyValuePair
            title='Product List ID'
            value={detailsInfo.id}
            width='50%'
          />
          <KeyValuePair title='Name' value={detailsInfo.name} />
        </div>
        <div className={cx('content')}>
          <KeyValuePair
            title='Description'
            value={detailsInfo.description}
            width='50%'
          />
          <KeyValuePair
            title='Updated At'
            value={formatDate(detailsInfo.timestamp)}
          />
        </div>
      </div>
    </Paper>
  );
};

export default Overview;
