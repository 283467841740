// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

import Paper from '@material-ui/core/Paper';

const cx = classnames.bind(styles);

import KeyValuePair from 'components/KeyValuePair';
import { CustomerRow } from 'query';
import { AddressTooltip, ValueTooltip } from 'components/Tooltips';
import {
  displayAddressTypeAndAddress,
  formatAddresses,
  getValidIdentifiers,
  identifiersToDisplayValues,
} from 'common/helpers';
import styles from './index.module.css';

export interface Props {
  customer: CustomerRow;
}

const CustomerDetailModalContent: FC<Props> = (props) => {
  const { customer } = props;

  const { addresses = [], names = [] } = customer;

  // Customer data
  const formattedAddresses = formatAddresses(customer?.addresses ?? []);
  const displayedAddress =
    formattedAddresses?.length === 1
      ? displayAddressTypeAndAddress(formattedAddresses)
      : formattedAddresses;
  const displayIds = identifiersToDisplayValues(
    getValidIdentifiers(customer?.identifiers ?? [], customer?.errors)
  ).filter((id) => id !== customer?.id);

  const addressTooltip = (
    <ValueTooltip
      title={<AddressTooltip addresses={customer?.addresses ?? []} />}
    />
  );

  const idsTooltip = (
    <ValueTooltip header='Identifier(s)' values={displayIds} />
  );

  const nameTooltip = <ValueTooltip header='Name' values={names} />;

  const classesOfTrade =
    customer.classes_of_trade?.map((cot) => cot.classOfTradeId) ?? [];
  const cotTooltip = (
    <ValueTooltip header='Class(es) of Trade' values={classesOfTrade} />
  );

  return (
    <Paper className={cx('paperMargin')}>
      <div className={cx('column', 'paperContent')}>
        <div className={cx('content')}>
          <KeyValuePair title='Customer ID' value={customer.id} />
          <KeyValuePair
            title='Identifier(s)'
            value={displayIds[0]}
            valueIcon={displayIds?.length > 1 ? idsTooltip : undefined}
            valueLength={displayIds?.length - 1}
          />
          <KeyValuePair
            title='Class(es) of Trade'
            value={classesOfTrade[0]}
            valueIcon={classesOfTrade?.length > 1 ? cotTooltip : undefined}
            valueLength={classesOfTrade?.length - 1}
          />
        </div>
        <div className={cx('content')}>
          <KeyValuePair
            title='Name(s)'
            value={names?.[0]}
            valueIcon={names && names.length > 1 ? nameTooltip : undefined}
            valueLength={names && names.length - 1}
            width='100%'
          />
        </div>
        <div className={cx('content')}>
          <KeyValuePair
            title='Address(es)'
            value={displayedAddress[0]?.fullAddress}
            valueIcon={
              addresses && addresses.length > 1 ? addressTooltip : undefined
            }
            valueLength={addresses && addresses.length - 1}
            width='100%'
          />
        </div>
      </div>
    </Paper>
  );
};

export default CustomerDetailModalContent;
