// Vendor
import React, { Dispatch, SetStateAction } from 'react';
import { Node, Edge, Connection, MarkerType } from 'reactflow';
import { v4 } from 'uuid';
import { RuleGroupArray, RuleGroupType } from 'react-querybuilder';

// Internal
import {
  DragAndMoveTabItems,
  HandleSubmitValidate,
  HandleValidate,
  LeftPanelItems,
  NodeTypes,
  OnDrop,
  Pages,
} from './types';
import { mainColor } from './constants';

const dragAndMoveDataPage1 = (ruleSets: DragAndMoveTabItems[]) => ({
  items: ruleSets || [],
  title: 'Rule sets',
});

const dragAndMoveDataPage2 = (
  activePage1NodeLabel: string,
  crumbs: string[],
  rules: DragAndMoveTabItems[],
  setActivePage1Node: Dispatch<Node>,
  setActivePage2Node: Dispatch<Node>,
  setCrumbs: Dispatch<SetStateAction<string[]>>,
  setPage: Dispatch<SetStateAction<string>>
) => ({
  buttonLabel: 'Create rule',
  buttonOnClick: () => {
    setPage(Pages.RULE);
    setCrumbs(crumbs.concat('Create rule'));

    const newNode: Node = {
      data: {
        activePage1NodeLabel,
        description: '',
        edges: [],
        error: {
          isShow: false,
          message: '',
        },
        handleNextPage: (
          nextPage: string,
          nextCrumbs: string,
          activeNode: Node
        ) => {
          setPage(nextPage);
          setCrumbs(crumbs.concat(nextCrumbs));
          setActivePage2Node(activeNode);
        },
        name: '',
        nextCrumbs: '',
        nextPage: 'rule',
        nodes: [],
        nodeType: 'rule',
        setActivePage1Node,
      },
      id: v4(),
      position: { x: 0, y: 0 },
      type: 'rule',
      zIndex: 20,
    };
    setActivePage2Node(newNode);
  },
  items: rules || [],
  title: 'Rules',
});

// We check if the items that are displayed in the left panel exist in the React Flow nodes, if they do we do
// not display them in the left panel (drag out of panel -> drop into react flow area -> removed from left panel)
export const getPanelItemsNotInNodes = (
  panelItems: DragAndMoveTabItems[],
  nodes: Node[]
): DragAndMoveTabItems[] =>
  panelItems.filter((panelItem: DragAndMoveTabItems) => {
    const existsInNodes = nodes.find(
      (node: Node) => node.id === panelItem.name
    );
    return !existsInNodes;
  });

export const getLeftPanelItems = (
  activePage1Node: Node,
  crumbs: string[],
  leftPanelRuleSets: DragAndMoveTabItems[],
  page: string,
  page1Nodes: Node[],
  page2Nodes: Node[],
  setActivePage1Node: Dispatch<Node>,
  setActivePage2Node: Dispatch<Node>,
  setCrumbs: Dispatch<SetStateAction<string[]>>,
  setPage: Dispatch<SetStateAction<string>>
): LeftPanelItems => {
  if (page === Pages.MANAGE_RULES) {
    return dragAndMoveDataPage1(
      getPanelItemsNotInNodes(leftPanelRuleSets, page1Nodes)
    );
  }

  return dragAndMoveDataPage2(
    activePage1Node.data.name,
    crumbs,
    getPanelItemsNotInNodes(activePage1Node.data.rules, page2Nodes),
    setActivePage1Node,
    setActivePage2Node,
    setCrumbs,
    setPage
  );
};

// Check if 1 node (by node id) is connected on it's source and target edges
export const getIsNodeFullyConnected = (
  edges: Edge[],
  nodeId: string | null
): boolean => {
  const nodeHasSourceEdge = edges.some((edge) => edge.source === nodeId);
  const nodeHasTargetEdge = edges.some((edge) => edge.target === nodeId);
  return nodeHasSourceEdge && nodeHasTargetEdge;
};

// Returns all nodes not fully connected
export const getNodesNotConnected = (
  edges: Edge[],
  nodes: Node[],
  setPage1Nodes: Dispatch<SetStateAction<Node[]>>
): Node[] =>
  nodes.filter((node: Node) => {
    const isNodeFullyConnected = getIsNodeFullyConnected(edges, node.id);

    if (!isNodeFullyConnected) {
      updateNodeErrors(node, nodes, setPage1Nodes, true);
    }

    // Default nodes will never be connected on their source and target
    if (node.id === 'default_0' || node.id === 'default_1') return false;
    return !isNodeFullyConnected;
  });

export const handleSubmit = async ({
  edges,
  nodes,
  setPage1Nodes,
  snackbar,
  insertProposalRules,
  triggerId,
  proposals,
}: HandleSubmitValidate) => {
  const errorMessages: { bold?: string; message: string }[] = [];

  if (nodes.length === 2) {
    errorMessages.push({ message: 'Does not contain any rule sets.' });
  }

  const notConnectedNodes = getNodesNotConnected(edges, nodes, setPage1Nodes);
  if (notConnectedNodes.length !== 0) {
    errorMessages.push({ message: 'Some rule sets are not connected.' });
  }

  nodes.forEach((page1Node: Node) => {
    if (
      !page1Node.data.nodes.length &&
      page1Node.data.name !== 'Start' &&
      page1Node.data.name !== 'Generate proposal'
    ) {
      errorMessages.push({
        bold: page1Node.data.name,
        message: ' rule set has no rules.',
      });
    }
  });

  if (errorMessages.length > 0) {
    snackbar.open({
      message: (
        <>
          <div style={{ fontWeight: 'bold' }}>
            Please review errors before submitting:
          </div>
          {errorMessages.map((msg) => {
            const { bold, message } = msg;
            return (
              <div key={message}>
                <span style={{ fontWeight: 'bold' }}>{bold}</span>
                {message}
              </div>
            );
          })}
        </>
      ),
      variant: 'error',
    });
  } else {
    snackbar.open({
      message: 'Saving proposal.',
      variant: 'success',
    });

    insertProposalRules({
      variables: {
        proposalRules: {
          id: triggerId,
          proposals,
          rule_data: {
            nodes,
            edges,
          },
        },
      },
    });
  }
};

export const handleValidateBtn = ({
  edges,
  nodes,
  setPage1Nodes,
  snackbar,
}: HandleValidate) => {
  // There are 2 default nodes so if there are only 2 nodes there are zero added rule sets
  if (nodes.length === 2) {
    snackbar.open({
      message: 'The diagram is empty. Does not contain any rule sets.',
      variant: 'error',
    });
  } else {
    const notConnectedNodes = getNodesNotConnected(edges, nodes, setPage1Nodes);
    if (notConnectedNodes.length === 0) {
      snackbar.open({
        message: 'Looks good.',
        variant: 'success',
      });
    } else {
      snackbar.open({
        message: 'The diagram is invalid. Some rule sets are not connected.',
        variant: 'error',
      });
    }
  }
};

export const onConnect = (
  params: Connection | Edge,
  page1Edges: Edge[],
  page1Nodes: Node[],
  setPage1Edges: Dispatch<SetStateAction<Edge[]>>,
  setPage1Nodes: Dispatch<SetStateAction<Node[]>>
) => {
  const additionalParams = {
    id: `${params.source}-${params.target}`,
    key: `${params.source}-${params.target}`,
    type: 'smoothstep',
    style: {
      strokeWidth: 2,
      stroke: mainColor,
    },
    markerEnd: {
      type: MarkerType.Arrow,
      color: mainColor,
    },
  };

  const newParams = {
    ...params,
    ...additionalParams,
  };

  // We need to remove existing edge if it has the same source/target since we can only connect a source
  // handle to one target handle
  const edgesWithoutExistingEdges = page1Edges.filter(
    (edge) => edge.source !== params.source && edge.target !== params.target
  );

  edgesWithoutExistingEdges.push(newParams as Edge);

  // Remove error on node(s)(1 connect could remove the error from both the source and target nodes) if
  // it has a source and target edge
  if (getIsNodeFullyConnected(edgesWithoutExistingEdges, params.source)) {
    const sourceNode = page1Nodes.find((node) => node.id === params.source);
    updateNodeErrors(sourceNode, page1Nodes, setPage1Nodes, false);
  }

  if (getIsNodeFullyConnected(edgesWithoutExistingEdges, params.target)) {
    const targetNode = page1Nodes.find((node) => node.id === params.target);
    updateNodeErrors(targetNode, page1Nodes, setPage1Nodes, false);
  }

  setPage1Edges(edgesWithoutExistingEdges);
};

export const updatePageNodesOnDoneClick = (
  activeNode: Node,
  pageNodesToUpdate: Node[],
  setPageNodes: Dispatch<SetStateAction<Node[]>>,
  edges: Edge[],
  nodes: Node[],
  activePage3IfQuery?: {},
  activePage3ThenQuery?: {},
  activePage3ElseQuery?: {}
) => {
  const existingPageNodes = pageNodesToUpdate;
  const index = pageNodesToUpdate.findIndex(
    (pageNode: Node) => pageNode.id === activeNode.id
  );

  const newNode: Node = {
    ...pageNodesToUpdate[index],
    data: {
      ...pageNodesToUpdate[index].data,
      edges,
      nodes,
      description: activeNode.data.description,
      name: activeNode.data.name,
    },
  };

  if (activePage3IfQuery) {
    newNode.data.ifQuery = activePage3IfQuery;
    newNode.data.thenQuery = activePage3ThenQuery;
    newNode.data.elseQuery = activePage3ElseQuery;
  }

  existingPageNodes[index] = newNode;

  setPageNodes(existingPageNodes);
};

// We need to update more than just the active node to display errors and the errors should
// be the only data being updated since it's on the fly and only when clicking done to save
export const updateNodeErrors = (
  activeNode: Node | undefined,
  page1Nodes: Node[],
  setPage1Nodes: Dispatch<SetStateAction<Node[]>>,
  errorState: boolean
) => {
  const existingPageNodes = page1Nodes;
  const index = page1Nodes.findIndex(
    (pageNode: Node) => pageNode.id === activeNode?.id
  );
  existingPageNodes[index].data.error = {
    isShow: errorState,
    message: errorState ? 'Must connect rule set.' : '',
  };

  setPage1Nodes(existingPageNodes);
};

export const updateActiveNodeData = (
  activeNode: Node,
  name: string,
  setActiveNode: Dispatch<SetStateAction<Node>>,
  value: string
) => {
  setActiveNode({
    ...activeNode,
    data: {
      ...activeNode.data,
      [name]: value,
    },
  });
};

export const onDrop = ({
  event,
  extraData = {},
  reactFlowInstance,
  reactFlowWrapper,
  setNodes,
}: OnDrop): void => {
  event.preventDefault();

  const eventData = event.dataTransfer.getData('application/reactflow');

  // This is to check if a non-React Flow element on the UI was dragged and dropped into the React Flow area
  if (!eventData) return;

  const parsed = JSON.parse(eventData);

  const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
  const position = reactFlowInstance?.project({
    x: event.clientX - (reactFlowBounds?.left || 0),
    y: event.clientY - (reactFlowBounds?.top || 0),
  });

  const data = {
    description: parsed.description,
    edges: parsed.edges || [],
    error: {
      isShow: false,
      message: '',
    },
    ...extraData,
    name: parsed.name,
    nextCrumbs: parsed.name,
    nextPage: parsed.nextPage,
    nodes: parsed.nodes || [],
    nodeType: parsed.nodeType,
    ...(parsed.nodeType === NodeTypes.RULE_SET && {
      rules: parsed.rules || [],
    }),
    ...(parsed.elseQuery && { elseQuery: parsed.elseQuery }),
    ...(parsed.ifQuery && { ifQuery: parsed.ifQuery }),
    ...(parsed.thenQuery && { thenQuery: parsed.thenQuery }),
  };

  const newNode: Node = {
    data,
    id: parsed.name,
    position,
    type: parsed.nodeType,
  };

  setNodes((nodes: Node[]) => nodes.concat(newNode));
};

export const getInitialQuery = (
  combinator: string,
  field?: string
): RuleGroupType => ({
  combinator,
  rules: !field ? [] : [{ field, operator: '', value: '' }],
});

export const areAnyQBValuesEmpty = (queryRules: RuleGroupArray): boolean => {
  let error = false;
  const checkRulesValue = (rules: string | any[]) => {
    for (let i = 0; i < rules.length; i += 1) {
      if (rules[i].rules) checkRulesValue(rules[i].rules);
      else if (
        rules[i].value === '' &&
        rules[i].operator !== 'null' &&
        rules[i].operator !== 'notNull'
      ) {
        error = true;
        break;
      }
    }
  };
  checkRulesValue(queryRules);

  if (document.documentElement.innerText?.indexOf('Select a field') > -1) {
    error = true;
  }

  return error;
};
