// Vendor
import { identity } from 'lodash';

// Internal
import { FiltersSerializationService } from 'common/helpers/filtersSerializationService';
import { RosterKind } from 'pages/Roster/helpers';
import { FilterStatus } from 'pages/Contracts/List/service';
import { ParamConfig } from './persistedSearch';

export const SearchParamConfig: ParamConfig = {
  key: 'search',
  serialize: identity,
  parse: identity,
  default: '',
};

export const CurrentPageParamConfig: ParamConfig = {
  key: 'currentPage',
  serialize: (n) => n && n.toString(),
  parse: (str) => str && Number(str),
  default: 1,
};

export const FiltersParamConfig: ParamConfig = {
  key: 'filters',
  serialize: (fs) => fs && FiltersSerializationService.serialize(fs),
  parse: identity,
};

export const FiltersActiveParamConfig: ParamConfig = {
  key: 'filtersActive',
  serialize: (b) => (typeof b === 'boolean' ? b.toString() : ''),
  parse: (str) => Boolean(str === 'true'),
  default: false,
};

export const RosterKindParamConfig: ParamConfig = {
  key: 'rosterKind',
  serialize: identity,
  parse: identity,
  default: RosterKind.ALL,
};

export const FilterStatusParamConfig: ParamConfig = {
  key: 'filterStatus',
  serialize: identity,
  parse: identity,
  default: FilterStatus.ALL_STATUS,
};
