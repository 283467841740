// Vendor
import { Tabs, Tab, CircularProgress } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

// Internal
import {
  getIsTabDisabledValue,
  NonLinearTab,
} from 'components/MultipageForm/service';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface TabberButtonsProps {
  onChange: ((_event: React.ChangeEvent<{}>, value: any) => void) | undefined;
  tab: number;
  tabs: NonLinearTab[];
  fullWidth?: boolean;
}

export const TabberButtons: FC<TabberButtonsProps> = (props) => {
  const { onChange, tab: tabIndex, tabs, fullWidth = false } = props;

  const tabberButtons: ReactNode[] = tabs.map((tab, index) => {
    const { disabled, label, loading } = tab;

    return (
      <Tab
        disabled={getIsTabDisabledValue(disabled || false)}
        label={
          loading ? (
            <CircularProgress
              disableShrink
              size={20}
              data-testid='circular-progress'
            />
          ) : (
            `${index + 1}. ${label}`
          )
        }
        classes={{ root: cx('button') }}
        className={disabled ? cx('button-disabled') : ''}
        key={label}
        data-testid={`tabber-button-${index + 1}`}
      />
    );
  });

  return (
    <Tabs
      value={tabIndex}
      onChange={onChange}
      variant={fullWidth ? 'fullWidth' : 'standard'}
    >
      {tabberButtons}
    </Tabs>
  );
};
