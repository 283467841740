// Vendor
import MenuItem from '@material-ui/core/MenuItem';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';
import { capitalize, get, uniqBy } from 'lodash';

// Internal
import { required } from 'common/validation';
import { Select } from 'components';
import { useDocumentTypes } from 'hooks';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

// Constants
const inputText = 'Which manufacturer are the documents from?';
const reviewText = 'Manufacturer associated with the documents';

interface Props {
  change: (key: string, value: string) => void;
  disabled?: boolean;
  values: {};
}

const Manufacturer: FC<Props> = (props) => {
  const { change, disabled, values } = props;
  const documentTypes = useDocumentTypes();

  const manufacturers = documentTypes.map(({ attributes }) =>
    // @ts-ignore
    attributes.find(
      ({ fieldName }: { fieldName: string }) => fieldName === 'manufacturer'
    )
  );

  const manufacturerValue = get(values, 'manufacturer', '');

  return (
    <div className={cx('root')}>
      <span className={cx('title')}>{disabled ? reviewText : inputText}</span>
      <div className={cx('field')}>
        <span className={cx('key')}>Manufacturer</span>
        <Field
          // @ts-ignore
          component={Select}
          disabled={disabled}
          label={capitalize(manufacturerValue)}
          name='manufacturer'
          validate={required}
        >
          {uniqBy(manufacturers, 'name').map(
            (manufacturer) =>
              manufacturer && (
                <MenuItem
                  key={manufacturer.name}
                  onClick={() => {
                    change('manufacturer', manufacturer.defaultValue as string);
                  }}
                  value={manufacturer.defaultValue as string}
                  style={{
                    maxWidth: 'none',
                    paddingRight: 0,
                  }}
                >
                  {/* TODO: Generate defaultValue from type */}
                  {manufacturer.defaultValue as string}
                </MenuItem>
              )
          )}
        </Field>
      </div>
    </div>
  );
};

export default Manufacturer;
