// Vendor
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { Node } from 'reactflow';
import { v4 as uuid } from 'uuid';

// Internal
import {
  Action,
  Bindings,
  Conditional,
  Connector,
  Flow,
  Oper,
  Path,
  Program,
  TypeSchema,
  TypeSchemas,
} from 'types/generated/Clang';

// Schemas
const ATTRIBUTE_SCHEMA: TypeSchema = {
  kind: 'object',
  properties: {
    name: {
      kind: 'string',
    },
    value: {
      kind: 'string',
    },
    startDate: {
      kind: 'date',
      optional: true,
    },
    endDate: {
      kind: 'date',
      optional: true,
    },
  },
};
const COT_SCHEMA: TypeSchema = {
  kind: 'object',
  properties: {
    classOfTradeId: {
      kind: 'string',
    },
    startDate: {
      kind: 'date',
      optional: true,
    },
    endDate: {
      kind: 'date',
      optional: true,
    },
  },
};
const RELATIONSHIP_SCHEMA: TypeSchema = {
  kind: 'object',
  properties: {
    relationship: {
      kind: 'string',
    },
    to: {
      kind: 'string',
    },
    startDate: {
      kind: 'date',
      optional: true,
    },
    endDate: {
      kind: 'date',
      optional: true,
    },
  },
};
const ADDRESS_SCHEMA: TypeSchema = {
  kind: 'object',
  properties: {
    addressType: {
      kind: 'string',
      optional: true,
    },
    city: {
      kind: 'string',
    },
    state: {
      kind: 'string',
    },
    zipCode: {
      kind: 'string',
    },
    address1: {
      kind: 'string',
    },
    address2: {
      kind: 'string',
      optional: true,
    },
    address3: {
      kind: 'string',
      optional: true,
    },
    startDate: {
      kind: 'date',
      optional: true,
    },
    endDate: {
      kind: 'date',
      optional: true,
    },
  },
};
const LIST_SCHEMA: TypeSchema = {
  kind: 'object',
  properties: {
    listId: {
      kind: 'string',
    },
    startDate: {
      kind: 'date',
      optional: true,
    },
    endDate: {
      kind: 'date',
      optional: true,
    },
  },
};

export interface FieldPart {
  name: string;
  kind: string;
}

type Trigger =
  | 'local-customer-update'
  | 'mapping-update'
  | 'auth-customer-update'
  | 'no-mapping-found';

export function parseField(
  field: string,
  partSeparator: string = '|',
  kindSeparator: string = '#'
): FieldPart[] {
  return (
    field
      .split(partSeparator)
      .map((part) => {
        const [name, kind] = part.split(kindSeparator);
        return {
          name,
          kind,
        };
      })
      // Drop
      .filter(({ kind }) => kind !== 'empty')
  );
}

function translateRootPath(
  fieldPart: FieldPart,
  subfield: Path | undefined,
  trigger: Trigger
): Path {
  let result: Path;

  if (fieldPart.name === 'internalCustomer') {
    result = {
      kind: 'root',
      context: 'local',
    };

    if (subfield) {
      result.subfield = subfield;
    }

    return result;
  }

  if (fieldPart.name === 'updatedCustomer') {
    result = {
      kind: 'root',
      context: 'update',
    };

    if (subfield) {
      result.subfield = subfield;
    }

    return result;
  }

  if (fieldPart.name === 'proposedCustomer') {
    result = {
      kind: 'root',
      context: 'proposal',
    };

    if (subfield) {
      result.subfield = subfield;
    }

    return result;
  }

  if (fieldPart.name === 'gpoCustomer') {
    if (trigger === 'mapping-update' || trigger === 'no-mapping-found') {
      result = {
        kind: 'root',
        context: 'update',
      };

      if (subfield) {
        result.subfield = subfield;
      }

      return result;
    }

    if (!subfield) {
      throw new Error('Subfield must be specified for GPO customer.');
    }

    return {
      kind: 'root',
      context: 'local',
      subfield: {
        kind: 'index',
        field: 'mapped',
        subfield: {
          kind: 'find',
          condition: {
            name: 'gpoCustomerLookup',
            kind: 'existence',
            exists: subfield,
          },
        },
      },
    };
  }

  let identifierType;
  if (fieldPart.name === '340bCustomer') {
    identifierType = '340b:number:*';
  } else if (fieldPart.name === 'hibccCustomer') {
    identifierType = 'hin:number:*';
  } else if (fieldPart.name === 'deaCustomer') {
    identifierType = 'dea:number:*';
  } else {
    throw new Error(`Unknown customer type: ${fieldPart.name}`);
  }

  if (!subfield) {
    throw new Error('Subfield must be specified for auth customer.');
  }

  let ctx = 'local';
  if (trigger === 'no-mapping-found') {
    ctx = 'update';
  }

  if (subfield.kind === 'index' && subfield.field === 'id') {
    // Special treatment for ID of auth customer, because we must use a qualified format such as 'hin:number:XYZ'
    // instead of just `XYZ'. And the way to achieve that is to pull it from the identifiers field of the ctx.
    // Note that this is actually not a limitation of the clang itself, but rather a problem that we introduce
    // ourselves, because we have more than one way to refer to an ID of an auth customer.
    result = {
      kind: 'root',
      context: ctx,
      subfield: {
        kind: 'index',
        field: 'identifiers',
        subfield: {
          kind: 'find',
          condition: {
            name: 'identifierLookup',
            kind: 'relational',
            lhs: {
              kind: 'self',
            },
            oper: 'contains',
            rhs: {
              kind: 'constant',
              value: {
                kind: 'string',
                value: identifierType,
              },
            },
          },
        },
      },
    };
  } else {
    result = {
      kind: 'root',
      context: 'auth',
      subfield: {
        kind: 'index',
        field: 'data',
        subfield: {
          kind: 'lookup',
          key: {
            kind: 'root',
            context: ctx,
            subfield: {
              kind: 'index',
              field: 'identifiers',
              subfield: {
                kind: 'find',
                condition: {
                  name: 'identifierLookup',
                  kind: 'relational',
                  lhs: { kind: 'self' },
                  oper: 'contains',
                  rhs: {
                    kind: 'constant',
                    value: {
                      kind: 'string',
                      value: identifierType,
                    },
                  },
                },
              },
            },
          },
          subfield,
        },
      },
    };
  }

  return result;
}

function sanitizeFieldName(name: string): string {
  if (name === 'programs') {
    return 'lists';
  }

  if (
    name === 'primary-identifier' ||
    name === 'gpo-affiliation' ||
    name === 'eligibility'
  ) {
    return 'relationships';
  }

  return name;
}

export function translatePathPart(
  fieldPart: FieldPart,
  trigger: Trigger,
  condition?: Conditional,
  subfield?: Path
): Path {
  let result: Path;

  switch (fieldPart.kind) {
    case 'root':
      return translateRootPath(fieldPart, subfield, trigger);
    case 'string':
    case 'number':
    case 'date':
    case 'array':
    case 'boolean':
      result = {
        kind: 'index',
        field: sanitizeFieldName(fieldPart.name),
      };
      break;
    case 'condition':
      if (!condition) {
        throw new Error('Condition not provided');
      }
      result = {
        kind: 'find',
        condition,
      };
      break;
    default:
      throw new Error(`Unknown kind in fieldPart ${JSON.stringify(fieldPart)}`);
  }

  if (subfield) {
    result.subfield = subfield;
  }

  return result;
}

function relationshipPath(
  to: string,
  options?: {
    isProposal?: boolean;
    actionId?: string;
    subfield?: { name: string; kind: string };
  }
): Path {
  if (!options?.actionId) {
    throw new Error(
      'Must not compute a relationship path without specifying actionId'
    );
  }
  if (
    options.subfield &&
    (options.subfield.kind === 'string' || options.subfield.kind === 'date')
  ) {
    return {
      kind: 'index',
      field: 'relationships',
      subfield: {
        kind: 'find',
        condition: {
          name: `findRelationship:${options?.actionId}`,
          kind: 'relational',
          lhs: {
            kind: 'parse',
            name: 'relationship',
            as: {
              kind: 'string',
            },
          },
          oper: '==',
          rhs: {
            kind: 'constant',
            value: {
              kind: 'string',
              value: to,
            },
          },
        },
        subfield: {
          kind: 'parse',
          name: options.subfield.name,
          as: {
            kind: options.subfield.kind,
          },
        },
      },
    };
  }

  return {
    kind: 'index',
    field: 'relationships',
    subfield: {
      kind: 'find',
      condition: {
        name: `findRelationship:${options?.actionId}`,
        kind: 'relational',
        lhs: {
          kind: 'parse',
          name: 'relationship',
          as: {
            kind: 'string',
          },
        },
        oper: '==',
        rhs: {
          kind: 'constant',
          value: {
            kind: 'string',
            value: to,
          },
        },
      },
    },
  };
}

export function translatePath(
  field: string,
  trigger: Trigger,
  options?: {
    condition?: Conditional;
    isProposal?: boolean;
    actionId?: string;
    addHeadCondition?: boolean;
  }
): Path {
  const fields = parseField(field);

  // Auth customer value
  if (fields.length === 2 && fields[1].name === 'value') {
    return translateRootPath(
      fields[0],
      { kind: 'index', field: 'id' },
      trigger
    );
  }

  // Special relationships
  if (
    fields[1].name === 'gpo-affiliation' ||
    fields[1].name === 'primary-identifier' ||
    fields[1].name === 'eligibility'
  ) {
    const path = relationshipPath(fields[1].name, {
      ...options,
      subfield: fields[2],
    });
    return translatePathPart(fields[0], trigger, undefined, path);
  }

  // Attributes
  if (fields[1].name === 'attributes') {
    const { name } = fields[2];
    const attributePath: Path = {
      kind: 'index',
      field: 'attributes',
      subfield: {
        kind: 'find',
        condition: {
          name: `findAttribute:${options?.actionId || uuid()}`,
          kind: 'relational',
          lhs: {
            kind: 'index',
            field: 'name',
          },
          oper: '==',
          rhs: {
            kind: 'constant',
            value: {
              kind: 'string',
              value: name,
            },
          },
        },
        subfield: {
          kind: 'index',
          field: fields[3].name,
        },
      },
    };

    return translatePathPart(fields[0], trigger, undefined, attributePath);
  }

  // Addresses
  if (fields[1].name === 'addresses') {
    const { name: addressType } = fields[2];
    const addressSearch: Path = {
      kind: 'find',
      condition: {
        name: `findAddress:${options?.actionId || uuid()}`,
        kind: 'relational',
        lhs: {
          kind: 'index',
          field: 'addressType',
        },
        oper: '==',
        rhs: {
          kind: 'constant',
          value: {
            kind: 'string',
            value: addressType,
          },
        },
      },
    };
    if (fields.length === 4) {
      addressSearch.subfield = {
        kind: 'index',
        field: fields[3].name,
      };
    }
    const addressPath: Path = {
      kind: 'index',
      field: 'addresses',
      subfield: addressSearch,
    };

    return translatePathPart(fields[0], trigger, undefined, addressPath);
  }

  // Documents
  if (fields[1].name === 'documents') {
    const documentType = fields[2].name;
    const documentField = fields[3];

    const findDocumentPath: Path = {
      kind: 'find',
      condition: {
        name: `findDocument:${options?.actionId || uuid()}`,
        kind: 'relational',
        lhs: {
          kind: 'index',
          field: 'type',
        },
        oper: '==',
        rhs: {
          kind: 'constant',
          value: {
            kind: 'string',
            value: documentType,
          },
        },
      },
    };

    if (documentField) {
      findDocumentPath.subfield = {
        kind: 'index',
        field: 'metadata',
        subfield: {
          kind: 'parse',
          name: documentField.name,
          as: {
            kind: documentField?.name?.toLowerCase().endsWith('date')
              ? 'date'
              : 'string',
          },
        },
      };
    }

    const documentPath: Path = {
      kind: 'index',
      field: 'documents',
      subfield: findDocumentPath,
    };

    return translatePathPart(fields[0], trigger, undefined, documentPath);
  }

  if (options?.condition) {
    // Append condition to the list of fields
    fields.push({ kind: 'condition', name: 'condition' });
  }
  const isArray = fields[1].kind === 'array';

  if (isArray && options?.addHeadCondition) {
    fields.splice(2, 0, { kind: 'condition', name: 'condition' });
  }

  if (fields.length === 0) {
    throw new Error(`Invalid path: ${field}`);
  }

  let subfield: Path | undefined;
  for (let i = fields.length - 1; i > 0; i -= 1) {
    if (isArray && options?.addHeadCondition) {
      const headCondition: Conditional = {
        name: `head:${options?.actionId || uuid()}`,
        kind: 'relational',
        lhs: {
          kind: 'constant',
          value: {
            kind: 'number',
            value: 0,
          },
        },
        oper: '==',
        rhs: {
          kind: 'constant',
          value: {
            kind: 'number',
            value: 0,
          },
        },
      };
      subfield = translatePathPart(
        fields[i],
        trigger,
        headCondition,
        subfield || undefined
      );
    } else {
      subfield = translatePathPart(
        fields[i],
        trigger,
        options?.condition,
        subfield || undefined
      );
    }
  }

  if (options?.isProposal) {
    return {
      kind: 'root',
      context: 'proposal',
      subfield,
    };
  }

  return translatePathPart(fields[0], trigger, undefined, subfield);
}

export function translateConstantPath(part: FieldPart, value: string): Path {
  return {
    kind: 'constant',
    value: {
      kind: part.kind === 'date' ? 'date' : 'string',
      value:
        part.kind === 'date'
          ? toUTCOffset(value, part.name === 'endDate')
          : value,
    },
  };
}

function translateOperator(operator: string): Oper {
  switch (operator) {
    case '==':
      return '==';
    case '!=':
      return '!=';
    case '>':
      return '>';
    case '>=':
      return '>=';
    case '<':
      return '<';
    case '<=':
      return '<=';
    case 'contains':
      return 'contains';
    default:
      return '==';
  }
}

export function toUTCOffset(date: string, isEndDate: boolean) {
  if (isEndDate) {
    return `${date}T23:59:59.999+00:00`;
  } else {
    return `${date}T00:00:00.000+00:00`;
  }
}

export function translateRelationshipRule(
  rule: RuleType,
  relationship: string,
  trigger: Trigger
): {
  condition: Conditional;
  extraActions?: Flow[];
} {
  const { id, value, operator, field } = rule;
  const fields = parseField(field);
  const lastFieldName = fields[fields.length - 1].name;
  // @ts-ignore
  const isConstantComparison = rule.valueSource !== 'select';

  if (operator === 'notNull') {
    return {
      condition: {
        name: `existence:${id || uuid()}`,
        kind: 'existence',
        exists: translatePath(field, trigger, { actionId: id }),
      },
    };
  }

  if (operator === 'null') {
    return {
      condition: {
        kind: 'composite',
        name: `compositeNot:${id || uuid()}`,
        connector: 'NOT',
        clauses: [
          {
            name: `existence:${id || uuid()}`,
            kind: 'existence',
            exists: translatePath(field, trigger, { actionId: id }),
          },
        ],
      },
    };
  }

  const relationshipVariableName = `relationship-location:${id || uuid()}`;
  const valueVariableName = `value-location:${id || uuid()}`;
  if (isConstantComparison) {
    // TODO: to avoid an error in CC when relationship is not found -- add an 'exist' step using a when
    return {
      condition: {
        name: `relational:${id || uuid()}`,
        kind: 'relational',
        lhs: {
          kind: 'root',
          context: relationshipVariableName,
          subfield: {
            kind: 'index',
            field: lastFieldName,
          },
        },
        oper: translateOperator(operator),
        rhs: {
          kind: 'root',
          context: valueVariableName,
        },
      },
      extraActions: [
        {
          kind: 'action',
          name: `define-variables:${id || uuid()}`,
          action: 'define',
          values: {},
          provides: {
            [relationshipVariableName]: {
              schema: RELATIONSHIP_SCHEMA,
              via: {
                kind: 'constant',
                value: {
                  kind: 'object',
                  value: {
                    relationship: {
                      kind: 'string',
                      value: relationship,
                    },
                    to: {
                      kind: 'string',
                      value: '<undefined>',
                    },
                  },
                },
              },
            },
            [valueVariableName]: {
              schema: {
                kind: 'string',
              },
              via: {
                kind: 'constant',
                value: {
                  kind: 'string',
                  value: '<undefined>',
                },
              },
            },
          },
        },
        {
          kind: 'action',
          name: `copy-identifier-variable:${id || uuid()}`,
          action: 'copy',
          values: {
            from: translatePath(field, trigger, { actionId: id }),
            to: {
              kind: 'root',
              context: relationshipVariableName,
              subfield: {
                kind: 'index',
                field: lastFieldName,
              },
            },
          },
        },
        {
          kind: 'action',
          name: `copy-value-variable:${id || uuid()}`,
          action: 'copy',
          values: {
            from: {
              kind: 'constant',
              value: {
                kind: 'string',
                value,
              },
            },
            to: {
              kind: 'root',
              context: valueVariableName,
            },
          },
        },
      ],
    };
  } else {
    return {
      condition: {
        name: `relational:${id || uuid()}`,
        kind: 'relational',
        lhs: {
          kind: 'root',
          context: relationshipVariableName,
          subfield: {
            kind: 'index',
            field: lastFieldName,
          },
        },
        oper: translateOperator(operator),
        rhs: {
          kind: 'root',
          context: valueVariableName,
          subfield: {
            kind: 'index',
            field: lastFieldName,
          },
        },
      },
      extraActions: [
        {
          kind: 'action',
          name: `define-variables:${id || uuid()}`,
          action: 'define',
          values: {},
          provides: {
            [relationshipVariableName]: {
              schema: RELATIONSHIP_SCHEMA,
              via: {
                kind: 'constant',
                value: {
                  kind: 'object',
                  value: {
                    relationship: {
                      kind: 'string',
                      value: relationship,
                    },
                    to: {
                      kind: 'string',
                      value: '<undefined>',
                    },
                  },
                },
              },
            },
            [valueVariableName]: {
              schema: RELATIONSHIP_SCHEMA,
              via: {
                kind: 'constant',
                value: {
                  kind: 'object',
                  value: {
                    relationship: {
                      kind: 'string',
                      value: relationship,
                    },
                    to: {
                      kind: 'string',
                      value: '<undefined>',
                    },
                  },
                },
              },
            },
          },
        },
        {
          kind: 'action',
          name: `copy-identifier-variable:${id || uuid()}`,
          action: 'copy',
          values: {
            from: translatePath(field, trigger, { actionId: id }),
            to: {
              kind: 'root',
              context: relationshipVariableName,
              subfield: {
                kind: 'index',
                field: lastFieldName,
              },
            },
          },
        },
        {
          kind: 'action',
          name: `copy-value-variable:${id || uuid()}`,
          action: 'copy',
          values: {
            from: translatePath(value, trigger, {
              actionId: `${id}:to`,
            }),
            to: {
              kind: 'root',
              context: valueVariableName,
              subfield: {
                kind: 'index',
                field: lastFieldName,
              },
            },
          },
        },
      ],
    };
  }
}

export function translateRuleWithType(
  rule: RuleType,
  trigger: Trigger
): {
  condition: Conditional;
  extraActions?: Flow[];
} {
  const { id, value, operator, field } = rule;
  const fields = parseField(field);
  // @ts-ignore
  const isConstantComparison = rule.valueSource !== 'select';

  if (operator === 'notNull') {
    return {
      condition: {
        name: `existence:${id || uuid()}`,
        kind: 'existence',
        exists: translatePath(field, trigger, { actionId: id }),
      },
    };
  }

  if (operator === 'null') {
    return {
      condition: {
        kind: 'composite',
        name: `compositeNot:${id || uuid()}`,
        connector: 'NOT',
        clauses: [
          {
            name: `existence:${id || uuid()}`,
            kind: 'existence',
            exists: translatePath(field, trigger, { actionId: id }),
          },
        ],
      },
    };
  }

  const subfield = fields[3];

  if (isConstantComparison) {
    return {
      condition: {
        name: `relational:${id || uuid()}`,
        kind: 'relational',
        lhs: translatePath(field, trigger, { actionId: id }),
        oper: translateOperator(operator),
        rhs: translateConstantPath(subfield, value),
      },
    };
  } else {
    return {
      condition: {
        name: `relational:${id || uuid()}`,
        kind: 'relational',
        lhs: translatePath(field, trigger, { actionId: id }),
        oper: translateOperator(operator),
        rhs: translatePath(value, trigger, { actionId: `${id}:to` }),
      },
    };
  }
}

function buildRhs(
  rule: RuleType,
  trigger: Trigger,
  isConstantComparison: boolean,
  isDate: boolean,
  isEndDate: boolean,
  isArray: boolean
): Path {
  let rhs: Path;
  if (isConstantComparison) {
    rhs = {
      kind: 'constant',
      value: {
        kind: isDate ? 'date' : 'string',
        value: isDate ? toUTCOffset(rule.value, isEndDate) : rule.value,
      },
    };
  } else {
    rhs = translatePath(rule.value, trigger, {
      actionId: rule.id,
      addHeadCondition: isArray,
    });
  }
  return rhs;
}

export function translateRule(
  rule: RuleType,
  trigger: Trigger
): {
  condition: Conditional;
  extraActions?: Flow[];
} {
  const fields = parseField(rule.field);
  const firstField = fields[1];
  const lastField = fields[fields.length - 1];
  const isArray = firstField.kind === 'array';
  // @ts-ignore
  const isConstantComparison = rule.valueSource !== 'select';
  const isDate = lastField.kind === 'date';
  const isEndDate = isDate && lastField.name === 'endDate';

  const ruleId = rule.id || uuid();

  // Handle identifiers
  if (
    firstField.name === 'gpo-affiliation' ||
    firstField.name === 'primary-identifier' ||
    firstField.name === 'eligibility'
  ) {
    return translateRelationshipRule(rule, firstField.name, trigger);
  }

  // Handle attributes and addresses
  if (
    firstField.name === 'attributes' ||
    firstField.name === 'addresses' ||
    firstField.name === 'documents'
  ) {
    return translateRuleWithType(rule, trigger);
  }

  if (rule.operator === 'null' || rule.operator === 'notNull') {
    let nullCondition: Conditional | undefined;
    if (isArray) {
      nullCondition = {
        name: `nullCondition:${ruleId}`,
        kind: 'relational',
        lhs: {
          kind: 'constant',
          value: {
            kind: 'number',
            value: 0,
          },
        },
        oper: '==',
        rhs: {
          kind: 'constant',
          value: {
            kind: 'number',
            value: 0,
          },
        },
      };
    }

    if (rule.operator === 'notNull') {
      return {
        condition: {
          name: `existenceCondition:${ruleId}`,
          kind: 'existence',
          exists: translatePath(rule.field, trigger, {
            condition: nullCondition,
            actionId: ruleId,
          }),
        },
      };
    }

    return {
      condition: {
        kind: 'composite',
        name: `compositeNot:${ruleId}`,
        connector: 'NOT',
        clauses: [
          {
            name: `existenceCondition:${ruleId}`,
            kind: 'existence',
            exists: translatePath(rule.field, trigger, {
              condition: nullCondition,
              actionId: ruleId,
            }),
          },
        ],
      },
    };
  }

  if (rule.operator === 'notNull') {
    return {
      condition: {
        name: `rule:${rule.id || uuid()}`,
        kind: 'existence',
        exists: translatePath(rule.field, trigger),
      },
    };
  }

  // Build the comparison path
  const rhs: Path = buildRhs(
    rule,
    trigger,
    isConstantComparison,
    isDate,
    isEndDate,
    isArray
  );

  if (isArray) {
    if (fields.length === 3) {
      // Object array with subfield
      const subfield = fields[2].name;
      if (isConstantComparison) {
        const conditionPath: Path = translatePathPart(
          {
            kind: 'condition',
            name: 'condition',
          },
          trigger,
          {
            name: `relationalCondition:${ruleId}`,
            kind: 'relational',
            lhs: {
              kind: 'index',
              field: subfield,
            },
            oper: translateOperator(rule.operator),
            rhs,
          }
        );
        const subArrayPath: Path = translatePathPart(
          fields[1],
          trigger,
          undefined,
          conditionPath
        );
        const rootPath: Path = translatePathPart(
          fields[0],
          trigger,
          undefined,
          subArrayPath
        );
        return {
          condition: {
            name: `rule:${ruleId}`,
            kind: 'existence',
            exists: rootPath,
          },
        };
      } else {
        const condition: Conditional = {
          name: `existenceCondition:${ruleId}`,
          kind: 'existence',
          exists: translatePath(rule.value, trigger),
        };
        return {
          condition: {
            name: `rule:${ruleId}`,
            kind: 'existence',
            exists: translatePath(rule.field, trigger, { condition }),
          },
        };
      }
    } else {
      const condition: Conditional = {
        name: `relationalCondition:${ruleId}`,
        kind: 'relational',
        lhs: { kind: 'self' },
        oper: translateOperator(rule.operator),
        rhs,
      };
      return {
        condition: {
          name: `rule:${ruleId}`,
          kind: 'existence',
          exists: translatePath(rule.field, trigger, {
            condition,
            actionId: ruleId,
          }),
        },
      };
    }
  } else {
    return {
      condition: {
        name: `relationalCondition:${ruleId}`,
        kind: 'relational',
        lhs: translatePath(rule.field, trigger),
        oper: translateOperator(rule.operator),
        rhs,
      },
    };
  }
}

function translateConnector(connector: string): Connector {
  switch (connector) {
    case 'or':
      return 'OR';
    case 'not':
      return 'NOT';
    default:
      return 'AND';
  }
}

export function translateRuleGroup(
  ruleGroup: RuleGroupType,
  trigger: Trigger
): {
  condition: Conditional;
  extraActions?: Flow[];
} {
  const clauses: Conditional[] = [];
  const extraActions: Flow[] = [];

  ruleGroup.rules.forEach((rule) => {
    if (Object.prototype.hasOwnProperty.call(rule, 'combinator')) {
      const { condition, extraActions: ea } = translateRuleGroup(
        rule as RuleGroupType,
        trigger
      );
      clauses.push(condition);
      if (ea) extraActions.push(...ea);
    } else {
      const { condition, extraActions: ea } = translateRule(
        rule as RuleType,
        trigger
      );
      clauses.push(condition);
      if (ea) extraActions.push(...ea);
    }
  });

  return {
    condition: {
      kind: 'composite',
      name: `rule:${ruleGroup.id || uuid()}`,
      connector: translateConnector(ruleGroup.combinator),
      clauses,
    },
    extraActions,
  };
}

export function translateCopyAction(
  actionId: string,
  from: Path,
  to: Path
): Flow {
  return {
    kind: 'action',
    name: `copyAction:${actionId}`,
    action: 'copy',
    values: {
      to,
      from,
    },
  };
}

export function translatePushAction(
  actionId: string,
  to: Path,
  schema: TypeSchema,
  values: Bindings,
  copyPaths: { from: Path; to: Path }[]
): Flow {
  const tempVariableName = `tmp:${actionId}`;
  const defineAction: Action = {
    kind: 'action',
    name: `define:${actionId}`,
    action: 'define',
    values: {},
    provides: {
      [tempVariableName]: {
        schema,
        via: {
          kind: 'constant',
          value: {
            kind: 'object',
            value: values,
          },
        },
      },
    },
  };

  const copyActions: Action[] = copyPaths.map(({ from, to }, index) => ({
    kind: 'action',
    name: `copy:${index}:${actionId}`,
    action: 'copy',
    values: {
      from,
      to: {
        kind: 'root',
        context: tempVariableName,
        subfield: to,
      },
    },
  }));

  const pushAction: Action = {
    kind: 'action',
    name: `push:${actionId}`,
    action: 'push',
    values: {
      from: {
        kind: 'root',
        context: tempVariableName,
      },
      to,
    },
  };

  return {
    kind: 'composite',
    flows: [defineAction, ...copyActions, pushAction],
  };
}

export function translatePushStringAction(
  actionId: string,
  from: Path,
  to: Path
): Flow {
  return {
    kind: 'action',
    name: `pushString:${actionId}`,
    action: 'push',
    values: {
      from,
      to,
    },
  };
}

export function translateDocumentAction(
  actionId: string,
  type: string,
  copyPaths: { from: Path; to: string }[]
): Flow {
  const tempVariableName = `tmpDocument:${actionId}`;

  const metadataSchema: TypeSchemas = {};
  const metadataBindings: Bindings = {};

  copyPaths.forEach(({ to }) => {
    metadataSchema[to] = {
      kind: 'string',
    };
    metadataBindings[to] = {
      kind: 'string',
      value: '<undefined>',
    };
  });

  const defineAction: Action = {
    kind: 'action',
    name: `define:${actionId}`,
    action: 'define',
    values: {},
    provides: {
      [tempVariableName]: {
        schema: {
          kind: 'object',
          properties: {
            id: {
              kind: 'string',
            },
            type: {
              kind: 'string',
            },
            metadata: {
              kind: 'object',
              properties: metadataSchema,
            },
          },
        },
        via: {
          kind: 'constant',
          value: {
            kind: 'object',
            value: {
              id: {
                kind: 'string',
                value: actionId,
              },
              type: {
                kind: 'string',
                value: type,
              },
              metadata: {
                kind: 'object',
                value: metadataBindings,
              },
            },
          },
        },
      },
    },
  };

  const copyActions: Action[] = copyPaths.map(({ from, to }, index) => ({
    kind: 'action',
    name: `copy:${index}:${actionId}`,
    action: 'copy',
    values: {
      from,
      to: {
        kind: 'root',
        context: tempVariableName,
        subfield: {
          kind: 'index',
          field: 'metadata',
          subfield: {
            kind: 'index',
            field: to,
          },
        },
      },
    },
  }));

  const pushAction: Action = {
    kind: 'action',
    name: `push:${actionId}`,
    action: 'push',
    values: {
      from: {
        kind: 'root',
        context: tempVariableName,
      },
      to: {
        kind: 'root',
        context: 'proposal',
        subfield: {
          kind: 'index',
          field: 'documents',
        },
      },
    },
  };

  return {
    kind: 'composite',
    flows: [defineAction, ...copyActions, pushAction],
  };
}

export type ActionContainer =
  | CopyActionContainer
  | PushActionContainer
  | PushStringActionContainer
  | DocumentActionContainer;

export interface CopyActionContainer {
  id: string;
  name: string;
  kind: 'copy';
  to: Path;
  from: Path;
}

export interface PushActionContainer {
  id: string;
  name: string;
  kind: 'push';
  to: Path;
  copyPaths: { from: Path; to: Path }[];
  schema: TypeSchema;
  values: Bindings;
}

export interface PushStringActionContainer {
  id: string;
  name: string;
  kind: 'push_string';
  to: Path;
  from: Path;
}

export interface DocumentActionContainer {
  id: string;
  name: string;
  kind: 'document';
  type: string;
  copyPaths: { from: Path; to: string }[];
}

export function toActionContainer(
  actionId: string,
  actionName: string,
  pushToField: Path,
  toFieldName: string,
  from: Path,
  schema: TypeSchema,
  values: Bindings
): ActionContainer {
  return {
    id: actionId,
    kind: 'push',
    name: actionName,
    to: {
      kind: 'root',
      context: 'proposal',
      subfield: pushToField,
    },
    copyPaths: [
      {
        to: {
          kind: 'index',
          field: toFieldName,
        },
        from,
      },
    ],
    schema,
    values,
  };
}

export function parseAction(
  action: RuleType,
  trigger: Trigger
): ActionContainer {
  const fields = parseField(action.field);
  const firstField = fields[1];
  const lastField = fields[fields.length - 1];
  // @ts-ignore
  const isConstantComparison = action.valueSource !== 'select';
  const isDate = lastField.kind === 'date';
  const isEndDate = isDate && lastField.name === 'endDate';
  const actionId = action.id || uuid();

  let from: Path;

  if (isConstantComparison) {
    from = {
      kind: 'constant',
      value: {
        kind: isDate ? 'date' : 'string',
        value: isDate ? toUTCOffset(action.value, isEndDate) : action.value,
      },
    };
  } else {
    from = translatePath(action.value, trigger, {
      addHeadCondition: true,
      actionId: action.id,
    });
  }

  // Strings and dates
  if (firstField.name === 'startDate' || firstField.name === 'endDate') {
    return {
      id: actionId,
      kind: 'copy',
      name: firstField.name,
      from,
      to: translatePath(action.field, trigger, { isProposal: true }),
    };
  }

  // String arrays
  if (firstField.name === 'names') {
    return {
      id: actionId,
      kind: 'push_string',
      name: firstField.name,
      from,
      to: translatePath(action.field, trigger, { isProposal: true }),
    };
  }

  // Object arrays
  if (firstField.kind === 'array') {
    const subField = fields[2];
    if (
      firstField.name === 'gpo-affiliation' ||
      firstField.name === 'primary-identifier' ||
      firstField.name === 'eligibility'
    ) {
      return toActionContainer(
        actionId,
        firstField.name,
        {
          kind: 'index',
          field: 'relationships',
        },
        subField.name,
        from,
        RELATIONSHIP_SCHEMA,
        {
          relationship: {
            kind: 'string',
            value: firstField.name,
          },
          [subField.name]: {
            kind: subField.kind === 'date' ? 'date' : 'string',
            value:
              subField.kind === 'date'
                ? toUTCOffset('1960-01-01', subField.name === 'endDate')
                : '<undefined>',
          },
        }
      );
    } else if (firstField.name === 'classesOfTrade') {
      return toActionContainer(
        actionId,
        'classesOfTrade',
        {
          kind: 'index',
          field: 'classesOfTrade',
        },
        subField.name,
        from,
        COT_SCHEMA,
        {
          [subField.name]: {
            kind: subField.kind === 'date' ? 'date' : 'string',
            value:
              subField.kind === 'date'
                ? toUTCOffset('1960-01-01', subField.name === 'endDate')
                : '<undefined>',
          },
        }
      );
    } else if (firstField.name === 'attributes') {
      const key = subField.name;
      const valueField = fields[3];

      return toActionContainer(
        actionId,
        `attribute:${key}`,
        {
          kind: 'index',
          field: 'attributes',
        },
        valueField.name,
        from,
        ATTRIBUTE_SCHEMA,
        {
          name: {
            kind: 'string',
            value: key,
          },
          [valueField.name]: {
            kind: valueField.kind === 'date' ? 'date' : 'string',
            value:
              valueField.kind === 'date'
                ? toUTCOffset('1960-01-01', valueField.name === 'endDate')
                : '<undefined>',
          },
        }
      );
    } else if (firstField.name === 'addresses') {
      const key = subField.name;
      const valueField = fields[3];

      return toActionContainer(
        actionId,
        `address:${key}`,
        {
          kind: 'index',
          field: 'addresses',
        },
        valueField.name,
        from,
        ADDRESS_SCHEMA,
        {
          addressType: {
            kind: 'string',
            value: key,
          },
          [valueField.name]: {
            kind: valueField.kind === 'date' ? 'date' : 'string',
            value:
              valueField.kind === 'date'
                ? toUTCOffset('1960-01-01', valueField.name === 'endDate')
                : '<undefined>',
          },
        }
      );
    } else if (firstField.name === 'lists' || firstField.name === 'programs') {
      return toActionContainer(
        actionId,
        firstField.name,
        {
          kind: 'index',
          field: 'lists',
        },
        subField.name,
        from,
        LIST_SCHEMA,
        {
          [subField.name]: {
            kind: subField.kind === 'date' ? 'date' : 'string',
            value:
              subField.kind === 'date'
                ? toUTCOffset('1960-01-01', subField.name === 'endDate')
                : '<undefined>',
          },
        }
      );
    } else if (firstField.name === 'documents') {
      const documentType = fields[2].name;
      const documentMetadata = fields[3].name;
      return {
        id: actionId,
        kind: 'document',
        name: `document-${documentType}`,
        type: documentType,
        copyPaths: [
          {
            to: documentMetadata,
            from,
          },
        ],
      };
    } else {
      throw new Error(`Unknown field: ${firstField.name}`);
    }
  }

  throw new Error(`Unknown field: ${firstField.name}`);
}

export function mergeActions(
  first: ActionContainer,
  second: ActionContainer | undefined
): ActionContainer {
  if (!second || first.kind === 'copy' || first.kind === 'push_string') {
    return first;
  }

  if (first.kind === 'document') {
    if (second.kind === 'document') {
      let copyPaths = first.copyPaths || [];
      copyPaths = copyPaths.concat(second.copyPaths);
      return {
        ...first,
        copyPaths,
      };
    } else {
      return first;
    }
  }

  let copyPaths = first.copyPaths || [];
  if (
    second.kind === 'push' &&
    second.copyPaths &&
    second.copyPaths.length > 0
  ) {
    copyPaths = copyPaths.concat(second.copyPaths);
  }

  let { values } = first;
  if (second.kind === 'push') {
    values = {
      ...values,
      ...second.values,
    };
  }

  return {
    ...first,
    copyPaths,
    values,
  };
}

export function translateActionGroup(
  actionGroup: RuleGroupType,
  trigger: Trigger
): Flow {
  const actionMap = new Map<string, ActionContainer>();
  actionGroup.rules.forEach((rule) => {
    if (Object.prototype.hasOwnProperty.call(rule, 'combinator')) {
      throw new Error('Nested groups are not supported.');
    }

    const action: ActionContainer = parseAction(rule as RuleType, trigger);
    actionMap.set(
      action.name,
      mergeActions(action, actionMap.get(action.name))
    );
  });

  const flows = Array.from(actionMap.values()).map((container) => {
    if (container.kind === 'copy') {
      return translateCopyAction(container.id, container.from, container.to);
    } else if (container.kind === 'push') {
      return translatePushAction(
        container.id,
        container.to,
        container.schema,
        container.values,
        container.copyPaths
      );
    } else if (container.kind === 'push_string') {
      return translatePushStringAction(
        container.id,
        container.from,
        container.to
      );
    } else if (container.kind === 'document') {
      return translateDocumentAction(
        container.id,
        container.type,
        container.copyPaths
      );
    } else {
      throw new Error(`Unknown action container: ${JSON.stringify(container)}`);
    }
  });

  if (flows.length === 1) {
    return flows[0];
  }

  return {
    kind: 'composite',
    flows,
  };
}

function translateTrigger(trigger: string): Trigger {
  switch (trigger) {
    case 'local-customer-update':
      return 'local-customer-update';
    case 'mapping-update':
      return 'mapping-update';
    case 'auth-customer-update':
      return 'auth-customer-update';
    case 'no-mapping-found':
      return 'no-mapping-found';
    default:
      throw new Error(`Unknown trigger: ${trigger}`);
  }
}

export function translateProgram(
  programId: string,
  trigger: string,
  timestamp: string,
  revisedAt: string,
  nodes: Node[]
): Program {
  const flows: Flow[] = [];

  nodes.forEach((node) => {
    const extraActions: Flow[] = [];
    const subNodes: Node<any, string | undefined>[] = node.data.nodes;
    const subFlows: Flow[] = subNodes.map((subNode) => {
      const { ifQuery, thenQuery, elseQuery } = subNode.data;

      const { condition, extraActions: ea } = translateRuleGroup(
        ifQuery,
        translateTrigger(trigger)
      );
      if (ea) extraActions.push(...ea);

      if (!!elseQuery && elseQuery.rules && elseQuery.rules.length > 0) {
        return {
          kind: 'rule',
          when: condition,
          thenDo: translateActionGroup(thenQuery, translateTrigger(trigger)),
          elseDo: translateActionGroup(elseQuery, translateTrigger(trigger)),
        };
      }

      if (!!elseQuery && elseQuery.rules && elseQuery.rules.length > 0) {
        return {
          kind: 'rule',
          when: condition,
          thenDo: translateActionGroup(thenQuery, translateTrigger(trigger)),
        };
      }

      return {
        kind: 'rule',
        when: condition,
        thenDo: translateActionGroup(thenQuery, translateTrigger(trigger)),
      };
    });

    flows.push({
      kind: 'composite',
      flows: [...extraActions, ...subFlows],
    });
  });

  return {
    id: programId,
    scope: 'proposals',
    timestamp,
    revisedAt,
    trigger,
    flow: {
      kind: 'composite',
      flows,
    },
  };
}
