import Decimal from 'decimal.js';

export const formatCurrency = (
  value: number,
  isMicrodollars: boolean = true
): string =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 6,
  }).format(isMicrodollars ? value / 1_000_000 : value);

// Note: API values must be Integer, and must be in microdollars
export const convertDollarValueToAPIValue = (price: number | string): number =>
  new Decimal(price).times(1_000_000).trunc().toNumber();
