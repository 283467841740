import { getToken } from 'actions/common/utils';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiConfig } from 'common/config';
import { AbstractService } from '../core';

/**
 * Legacy service, please use useApi() hook instead
 */
export class ApiService extends AbstractService {
  config: ApiConfig;

  private readonly _endpoint: string;

  private _axios: AxiosInstance;

  constructor(cfg: ApiConfig, path: string[]) {
    super(path);
    this.config = cfg;
    this._axios = axios.create();
    this._endpoint = this.config?.endpoint || window.location.origin;
  }

  authorizationHeader(token: string): string {
    return `Bearer ${token}`;
  }

  url(route: string): string {
    return `${this._endpoint}${route}`;
  }

  get<T>(
    route: string,
    queryParams: any = {},
    additionalConfig: AxiosRequestConfig = {}
  ) {
    const options: AxiosRequestConfig = {
      headers: {},
      params: queryParams,
      ...additionalConfig,
    };
    const token = getToken();
    if (token && options.headers) {
      options.headers.Authorization = this.authorizationHeader(token);
    }
    return this._axios.get<T>(this.url(route), options);
  }

  post<T>(route: string, data: any, headers: any = {}) {
    const options: AxiosRequestConfig = { headers };
    const token = getToken();
    if (token && options.headers) {
      options.headers.Authorization = this.authorizationHeader(token);
    }
    return this._axios.post<T>(this.url(route), data, options);
  }
}
