// Vendor
import MUIDialog from '@material-ui/core/Dialog';
import React, { FC, ReactNode } from 'react';

// Internal
import Title from './Title';

interface Props {
  children: ReactNode;
  title: string;
  toggleDialog: () => void;
  width?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
}

const Dialog: FC<Props> = ({ children, title, toggleDialog, width = 'sm' }) => (
  <MUIDialog
    open
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        toggleDialog();
      }
    }}
    maxWidth={width}
    scroll='paper'
  >
    {Title({ title, toggleDialog })}
    {children}
  </MUIDialog>
);

export default Dialog;
