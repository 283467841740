// Vendor
import React from 'react';

const IconOutOfNetwork = () => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 22C16.9706 22 21 17.9706 21 13C21 8.02944 16.9706 4 12 4C7.02944 4 3 8.02944 3 13C3 17.9706 7.02944 22 12 22Z'
      stroke='#FFF'
      strokeMiterlimit='10'
    />
    <path
      d='M4.51624 8.58319L20.1047 17.5832'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.93121 20.6682C9.72481 21.7038 13.1394 19.1473 15.558 14.9582C17.9766 10.7691 18.4832 6.53376 16.6896 5.49823C14.896 4.4627 11.4814 7.01915 9.06282 11.2082C6.64425 15.3973 6.13761 19.6327 7.93121 20.6682Z'
      stroke='#FFF'
      strokeMiterlimit='10'
    />
    <path
      d='M21 5.25C22.1046 5.25 23 4.35457 23 3.25C23 2.14543 22.1046 1.25 21 1.25C19.8954 1.25 19 2.14543 19 3.25C19 4.35457 19.8954 5.25 21 5.25Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.51623 10.3152C4.47282 10.8675 5.696 10.5398 6.24828 9.58319C6.80057 8.6266 6.47282 7.40342 5.51623 6.85114C4.55965 6.29885 3.33646 6.6266 2.78418 7.58319C2.23189 8.53977 2.55965 9.76295 3.51623 10.3152Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.31042 21.7434C8.26701 22.2957 9.49019 21.9679 10.0425 21.0113C10.5948 20.0548 10.267 18.8316 9.31042 18.2793C8.35383 17.727 7.13065 18.0548 6.57837 19.0113C6.02608 19.9679 6.35383 21.1911 7.31042 21.7434Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.1046 19.3152C20.0612 19.8675 21.2844 19.5398 21.8367 18.5832C22.3889 17.6266 22.0612 16.4034 21.1046 15.8511C20.148 15.2989 18.9248 15.6266 18.3726 16.5832C17.8203 17.5398 18.148 18.763 19.1046 19.3152Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconOutOfNetwork;
