// Vendor
import { isBoolean } from 'lodash';
import { ReactNode } from 'react';

export type TabDisabledFunction = (...args: any[]) => boolean;

export interface NonLinearTab {
  disabled?: boolean | TabDisabledFunction;
  label: string;
  loading?: boolean;
}

export type MultiPageFormStep = {
  views: ReactNode[];
  tabs?: NonLinearTab[];
};

/**
 * @description disabled prop can be either boolean or function, so we process here to get the value
 * @param disabled, tabs prop of MultiPageForm component, that is a boolean, or a function that returns a boolean
 * @param activeStep current step
 * @returns boolean, which defines, if tabber is disabled or not
 */
export const getIsTabDisabledValue = (
  disabled: boolean | TabDisabledFunction
) => (isBoolean(disabled) ? disabled : disabled());
