// Internal
import get from 'lodash/get';
import { DrawerEntry } from './LocalData';

interface Props {
  chargebacksEnabled?: boolean;
  contractsEnabled?: boolean;
  creditMemosEnabled?: boolean;
  rosterEnabled?: boolean;
}

const DIST = ({ contractsEnabled, rosterEnabled }: Props): DrawerEntry[] => [
  ...(rosterEnabled
    ? [{ hash: 'roster', text: 'Roster', subEntries: [] }]
    : []),
  ...(contractsEnabled
    ? [{ hash: 'contracts', text: 'Contracts', subEntries: [] }]
    : []),
];

const GPO = ({
  chargebacksEnabled,
  contractsEnabled,
  creditMemosEnabled,
  rosterEnabled,
}: Props): DrawerEntry[] => [
  ...(rosterEnabled
    ? [{ hash: 'roster', text: 'Roster', subEntries: [] }]
    : []),
  ...(contractsEnabled
    ? [{ hash: 'contracts', text: 'Contracts', subEntries: [] }]
    : []),
  ...(chargebacksEnabled
    ? [{ hash: 'chargebacks', text: 'Chargebacks', subEntries: [] }]
    : []),
  ...(creditMemosEnabled
    ? [{ hash: 'credit-memos', text: 'Credit Memos', subEntries: [] }]
    : []),
];

const MFR = ({ rosterEnabled }: Props): DrawerEntry[] => [
  ...(rosterEnabled
    ? [{ hash: 'roster', text: 'Roster', subEntries: [] }]
    : []),
];

const DEFAULT = ({ contractsEnabled, rosterEnabled }: Props): DrawerEntry[] => [
  ...(rosterEnabled
    ? [{ hash: 'roster', text: 'Roster', subEntries: [] }]
    : []),
  ...(contractsEnabled
    ? [{ hash: 'contracts', text: 'Contracts', subEntries: [] }]
    : []),
];

export const getTPData = (
  chargebacksEnabled: boolean,
  contractsEnabled: boolean,
  creditMemosEnabled: boolean,
  rosterEnabled: boolean,
  type: string
): DrawerEntry[] => {
  const dataMapper = {
    DIST: DIST({ contractsEnabled, rosterEnabled }),
    GPO: GPO({
      chargebacksEnabled,
      contractsEnabled,
      creditMemosEnabled,
      rosterEnabled,
    }),
    MFR: MFR({ rosterEnabled }),
  };
  return get(dataMapper, type, DEFAULT({ contractsEnabled, rosterEnabled }));
};
