// Vendor
import gql from 'graphql-tag';
import { Relationship } from 'types/generated/Relationship';

export interface RelationshipRow {
  fromId: string;
  fromMemberId: number;
  fromTimestamp: string;
  relationship: string;
  toId: string;
  toMemberId: number;
  extra?: Relationship;
}

export interface QueryRelationshipsResult {
  children: RelationshipRow[];
  parents: RelationshipRow[];
}

const RELATIONSHIPS_FIELDS = `
  fromId: from_id
  fromMemberId: from_member_id
  fromTimestamp: from_timestamp
  relationship
  toId: to_id
  toMemberId: to_member_id
  extra
`;

export const GET_RELATED_HINS_BY_ID = gql`
  query QueryRelationships($relationshipType: String!, $id: String!, $memberId: Int!) {
    children: dash_get_child_relationships(args: {
      hin_relationship_type: $relationshipType,
      hin_from_id: $id,
      hin_from_member_id: $memberId
    }) {
      ${RELATIONSHIPS_FIELDS}
    }
    parents: dash_get_parent_relationships(args: {
      hin_relationship_type: $relationshipType,
      hin_to_id: $id,
      hin_to_member_id: $memberId
    }) {
      ${RELATIONSHIPS_FIELDS}
    }
  }
`;
