import { Flatfile, ImportSession, PartialRejection } from '@flatfile/sdk';
import { IImportMeta } from '@flatfile/sdk/dist/importer/ImportSession';
import { FlatfileError } from '@flatfile/sdk/dist/errors/FlatfileError';
import {
  RowProcessingService,
  RowWithExternalId,
} from 'flatfile-import/application/row-processor/row-processing-service';
import { AggregationService } from 'flatfile-import/application/aggregator/aggregation-service';

class FlatfileService<Schema, Data, Output> {
  containerId: string;

  api: any;

  embedNode: string;

  constructor(containerId: string, embedNode: string, api: any) {
    if (!api) {
      throw new Error('API Instance is required');
    }
    this.api = api;
    this.containerId = containerId;
    this.embedNode = embedNode;
  }

  runImport = (
    data: Data,
    services: {
      aggregator: AggregationService<Output>;
      rowProcessingService: RowProcessingService<Schema, Data, Output>;
    },
    callbacks: {
      onCompleted: () => void;
      onError: (error: { error: FlatfileError }) => void;
      onInit: ({
        session,
        meta,
      }: {
        session: ImportSession;
        meta: IImportMeta;
      }) => void | Promise<void>;
    }
  ) => {
    const { onCompleted, onError } = callbacks;
    Flatfile.requestDataFromUser({
      chunkTimeout: 30000,
      chunkSize: 10000,
      open: 'iframe',
      mountOn: `#${this.containerId}`,
      theme: { displayName: 'Chronicled', hideConfetti: true },
      onInit: callbacks.onInit,
      token: () =>
        this.api
          .generateFlatFileToken({ embedNode: this.embedNode })
          .then(({ data }: { data: any }) => data.token),

      onData: (chunk, next) => {
        const rowsWithId: RowWithExternalId<Schema>[] = chunk.records.map(
          (rec) => ({
            id: rec.recordId,
            row: rec.data as Schema,
          })
        );
        const { rows, errors } = services.rowProcessingService.processRows(
          rowsWithId,
          data
        );
        services.aggregator
          .addBulk(rows)
          .then(() => {
            // Show records with errors in Flatfile
            if (errors.length > 0) {
              next(new PartialRejection(errors));
            } else {
              next();
            }
          })
          // eslint-disable-next-line no-console
          .catch((e) => console.error('Error saving records', e));
      },
      onComplete: onCompleted,
      onError,
    });
  };
}

export default FlatfileService;
