// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';

// Internal
import { IconChevron } from 'styles/images';
import Table, { TableColumn, TableRow } from 'components/Table';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Action {
  name: String;
  date: String;
}

export interface Props {
  actions: Action[];
}

const ClaimHistory: FC<Props> = ({ actions }) => {
  const [collapsed, updateCollapsed] = useState(true);

  const columns: TableColumn[] = [
    {
      headerName: 'ACTION',
      dataField: 'name',
      size: 'small',
      type: 'expanding',
    },
    {
      headerName: 'DATE',
      dataField: 'date',
      size: 'small',
      type: 'date',
    },
  ];

  const rows: TableRow[] = actions.map((action) => ({
    type: 'expanding',
    data: { ...action },
  }));

  return (
    <div className={cx('row')} data-testid='claim-history-section'>
      <header className={cx('header')}>
        Claim History
        <div
          className={cx('arrowContainer')}
          onClick={() => updateCollapsed(!collapsed)}
          data-testid='claimHistoryOpenButton'
        >
          <IconChevron pointUp={!collapsed} />
        </div>
      </header>
      {!collapsed && (
        <Table
          columns={columns}
          rows={rows}
          isLoading={false}
          emptyText='No History'
        />
      )}
    </div>
  );
};

export default ClaimHistory;
