// Vendor
import { MouseEvent } from 'react';

export const dragEnter = (e: MouseEvent) => {
  e.preventDefault();
};

export const dragLeave = (e: MouseEvent) => {
  e.preventDefault();
};

export const dragOver = (e: MouseEvent) => {
  e.preventDefault();
};
