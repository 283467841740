// Vendor
import React, { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import NonLinearTabber from 'components/NonLinearTabber';
import { MultiPageFormStep } from '../service';

// Internal

export interface ViewsManagerProps {
  stepsData: MultiPageFormStep[];
  activeStep: number;
  activeSubStep: number;
  setActiveSubStep: Dispatch<SetStateAction<number>>;
}

const ViewsManager: FC<ViewsManagerProps> = (props) => {
  const { stepsData, activeSubStep, setActiveSubStep, activeStep } = props;

  return (
    <>
      {stepsData.map((stepData, index) => {
        if (index === activeStep) {
          // if subViews array has only one element, return the element.
          if (stepData.views.length === 1) {
            // add react key to the view and render it
            return React.cloneElement(stepData.views[0] as ReactElement, {
              // eslint-disable-next-line react/no-array-index-key
              key: index,
            });
            // if subViews array has more than 1 element, return tabber
          } else {
            return (
              <NonLinearTabber
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                setTab={setActiveSubStep}
                tab={activeSubStep}
                tabs={stepData.tabs || []}
                views={stepData.views}
              />
            );
          }
        }
        return null;
      })}
    </>
  );
};

export default ViewsManager;
