export interface PublishAdapter<Schema, POutput> {
  convert(entity: Schema): POutput;
}

export class IdentityPublishAdapter<Schema>
  implements PublishAdapter<Schema, Schema>
{
  convert(entity: Schema): Schema {
    return entity;
  }
}
