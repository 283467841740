// Vendor
import React from 'react';

const IconInNetwork = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      stroke='#FFF'
      strokeMiterlimit='10'
    />
    <path
      d='M4.01831 7.44977L19.6068 16.4498'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.43329 19.5348C9.22688 20.5703 12.6415 18.0139 15.0601 13.8248C17.4786 9.63573 17.9853 5.40034 16.1917 4.36481C14.3981 3.32927 10.9835 5.88573 8.56489 10.0748C6.14633 14.2639 5.63969 18.4993 7.43329 19.5348Z'
      stroke='#FFF'
      strokeMiterlimit='10'
    />
    <path
      d='M15.1875 6.10412C16.1441 6.6564 17.3673 6.32865 17.9195 5.37207C18.4718 4.41548 18.1441 3.1923 17.1875 2.64001C16.2309 2.08773 15.0077 2.41548 14.4554 3.37207C13.9032 4.32865 14.2309 5.55183 15.1875 6.10412Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.01831 9.18181C3.97489 9.7341 5.19807 9.40635 5.75036 8.44976C6.30264 7.49318 5.97489 6.27 5.01831 5.71771C4.06172 5.16543 2.83854 5.49318 2.28625 6.44976C1.73397 7.40635 2.06172 8.62953 3.01831 9.18181Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.8125 20.61C7.76908 21.1623 8.99226 20.8346 9.54455 19.878C10.0968 18.9214 9.76908 17.6982 8.8125 17.1459C7.85591 16.5937 6.63273 16.9214 6.08044 17.878C5.52816 18.8346 5.85591 20.0578 6.8125 20.61Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.6067 18.1818C19.5633 18.7341 20.7865 18.4063 21.3387 17.4498C21.891 16.4932 21.5633 15.27 20.6067 14.7177C19.6501 14.1654 18.4269 14.4932 17.8746 15.4498C17.3223 16.4063 17.6501 17.6295 18.6067 18.1818Z'
      fill='white'
      stroke='#FFF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconInNetwork;
