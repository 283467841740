// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { capitalize, startCase } from 'lodash';

// Internal
import { IconChevron, IconErrorAlert } from 'styles/images';
import { ErrorWithMessage } from 'common/helpers';
import { RED_ERROR_COLOR, YELLOW_WARNING_COLOR } from 'common/constants';
import Table, { TableColumn, TableRow } from 'components/Table';
import { REASON_COLUMN, TYPE_COLUMN } from 'components/Cells/constants';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  errors: ErrorWithMessage[];
}

const getFormattedMessage = (error: ErrorWithMessage) => (
  <>
    {error.message}
    {error.details.map(({ key, value }) => (
      <div key={key}>
        <strong>{startCase(key)}:</strong> {value}
      </div>
    ))}
  </>
);

const RejectionReasons: FC<Props> = ({ errors }) => {
  const [collapsed, updateCollapsed] = useState(false);

  const columns: TableColumn[] = [
    {
      headerName: '',
      dataField: 'errorIcon',
      type: 'expanding',
      size: 'small',
    },
    TYPE_COLUMN,
    REASON_COLUMN,
  ];

  const rows: TableRow[] = errors.map((err) => ({
    isExpandable: false,
    data: {
      reason: getFormattedMessage(err),
      errorIcon: (
        <IconErrorAlert
          className={cx('icon')}
          fill={
            err.severity === 'ERROR' ? RED_ERROR_COLOR : YELLOW_WARNING_COLOR
          }
        />
      ),
      type: capitalize(err.severity),
    },
    type: 'expanding',
  }));

  return (
    <div className={cx('row')} data-testid='rejection-reasons-section'>
      <header className={cx('header')}>
        Rejection Reasons
        <div
          className={cx('arrowContainer')}
          onClick={() => updateCollapsed(!collapsed)}
          data-testid='rejectionReasonsOpenButton'
        >
          <IconChevron pointUp={!collapsed} />
        </div>
      </header>
      {!collapsed && (
        <Table columns={columns} isLoading={false} emptyText='' rows={rows}>
          <colgroup>
            <col style={{ width: '2%' }} />
            <col style={{ width: '5%' }} />
            <col style={{ width: '93%' }} />
          </colgroup>
        </Table>
      )}
    </div>
  );
};

export default RejectionReasons;
