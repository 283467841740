// Vendor
import React, { FC, useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Styles
import inlineStyles from './inlineStyles';

export interface Props {
  label: string;
  checked?: boolean;
  classes: {
    label: string;
    root: string;
  };
  onChange: () => void;
}

const SwitchComponent: FC<Props> = ({
  checked: checkedProps,
  classes,
  onChange,
  label = '',
}) => {
  const [checked, setChecked] = useState(checkedProps ?? true);

  return (
    <FormControlLabel
      classes={{
        label: classes.label,
        root: classes.root,
      }}
      control={
        <Switch
          checked={checked}
          color='primary'
          data-testid='switch'
          onChange={() => {
            if (onChange) onChange();
            setChecked(!checked);
          }}
        />
      }
      label={label}
    />
  );
};

export default withStyles(inlineStyles)(SwitchComponent);
