// Vendor
import { pipe } from 'fp-ts/function';
import { fromNullable, getOrElseW, map, none, some, alt } from 'fp-ts/Option';

// Internal
import { toMediledgerMemberId } from 'common/helpers/mlIdUtils';
import { prepareDate } from '../adapter-utils';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
} from '../../../common/helpers';
import { RosterAdapter } from '../roster-adapter';
import {
  ContractHeaderRoster,
  ContractHeaderRosterInputData,
  RawContractHeader,
} from './contract-header-roster';

function parseOpenValue(rawOpen?: string): boolean | undefined {
  const isOpen = rawOpen?.toUpperCase() === 'OPEN' ? some(true) : none;
  const isClosed = rawOpen?.toUpperCase() === 'CLOSED' ? some(false) : none;

  return pipe(
    isOpen,
    alt(() => isClosed),
    getOrElseW(() => undefined)
  );
}

class ContractHeaderRosterAdapter
  implements
    RosterAdapter<
      ContractHeaderRoster,
      ContractHeaderRosterInputData,
      RawContractHeader
    >
{
  convert = (
    row: ContractHeaderRoster,
    data: ContractHeaderRosterInputData
  ): RawContractHeader => {
    const {
      id,
      startDate,
      endDate,
      description,
      open,
      contractEntityId,
      contractEntityStartDate,
      contractEntityEndDate,
      distributorId,
      distributorStartDate,
      distributorEndDate,
      contractType,
    } = row;
    const { timestamp } = data;
    const processedStartDate = prepareDate(
      startDate,
      convertStartDateToUtcTimestamp
    );
    const processedEndDate = prepareDate(endDate, convertEndDateToUtcTimestamp);
    const contractEntities = pipe(
      fromNullable(contractEntityId),
      map((contractEntityIdV) => [
        {
          id: toMediledgerMemberId(contractEntityIdV),
          startDate: prepareDate(
            contractEntityStartDate,
            convertStartDateToUtcTimestamp
          ),
          endDate: prepareDate(
            contractEntityEndDate,
            convertEndDateToUtcTimestamp
          ),
        },
      ]),
      getOrElseW(() => [])
    );
    const distributors = pipe(
      fromNullable(distributorId),
      map((distributorIdV) => [
        {
          id: toMediledgerMemberId(distributorIdV),
          startDate: prepareDate(
            distributorStartDate,
            convertStartDateToUtcTimestamp
          ),
          endDate: prepareDate(
            distributorEndDate,
            convertEndDateToUtcTimestamp
          ),
        },
      ]),
      getOrElseW(() => [])
    );

    return {
      id,
      description,
      timestamp,
      startDate: processedStartDate,
      endDate: processedEndDate,
      contractEntities,
      distributors,
      contractType,
      open: parseOpenValue(open),
    };
  };
}

export default new ContractHeaderRosterAdapter();
