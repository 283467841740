// Internal
import { TableRow } from 'components/Table';
import { QueryCustomersListResult } from 'query';

export const rawDataToRows = (
  customersLists?: QueryCustomersListResult
): TableRow[] => {
  if (!customersLists || !customersLists.lists) {
    return [];
  }

  return customersLists.lists.map((list) => ({
    type: 'expanding',
    data: {
      names: list.name,
      type: list.type,
      description: list.description,
      id: list.id,
      startDate: list.start_date,
      endDate: list.end_date,
      timestamp: list.timestamp,
    },
  }));
};

export const filtersToQuery = (searchText: string): any => ({
  type: { _eq: 'contract' },
  _or: [
    { name: { _ilike: searchText } },
    { id: { _ilike: searchText } },
    { description: { _ilike: searchText } },
  ],
});
