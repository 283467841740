// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Paper } from '@material-ui/core';

// Internal
import Table from 'components/Table';
import { useAuthDatabases, useSimplifiedContractsFlag } from 'hooks';
import { ContractCustomer } from 'query';
import { getColumns, getRows } from './service';

// Styles
import styles from './Table.module.css';

const cx = classnames.bind(styles);

interface Props {
  contractId: string;
  customers: ContractCustomer[];
  customerLoading: boolean;
  isMFR: boolean;
}

const CustomersTable: FC<Props> = (props) => {
  const acceptableAuthData = useAuthDatabases();
  const simplifiedContracts = useSimplifiedContractsFlag();

  const { contractId, customerLoading, customers, isMFR } = props;

  const columns = getColumns(acceptableAuthData, isMFR, simplifiedContracts);
  const rows = getRows(acceptableAuthData, customers, contractId);

  return (
    <Paper className={cx('paper')}>
      <Table
        columns={columns}
        isLoading={customerLoading}
        emptyText='No contract customers found'
        rows={rows}
      />
    </Paper>
  );
};

export default CustomersTable;
