// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

// Internal
import {
  GET_MEMBER_DETAILS,
  GET_TRADING_PARTNER,
  Member,
  QueryMembers,
  QueryTradingPartners,
  TradingPartner,
  TradingPartnerEntity,
} from 'query';
import { IconSystemError } from 'styles/images';
import { POLL_INTERVAL } from 'common/constants';
import { mergeTradingPartnerWithMember } from '../TradingPartners/service';
import TradingPartnerDetails from './TradingPartnerDetails';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

const Container: FC<{}> = () => {
  const [partner, setPartner] = useState<TradingPartnerEntity | null>(null);
  const { id } = useParams<{ id: string }>();

  const {
    data: dataTp,
    error: errorTp,
    loading: loadingTp,
  } = useQuery<QueryTradingPartners>(GET_TRADING_PARTNER, {
    variables: { id },
    pollInterval: POLL_INTERVAL,
  });

  const { data, error, loading } = useQuery<QueryMembers>(GET_MEMBER_DETAILS, {
    variables: { id },
    pollInterval: POLL_INTERVAL,
  });

  const member: Member | undefined = get(data, 'member', [])[0];
  const tradingPartner: TradingPartner | undefined = get(
    dataTp,
    'tradingPartner',
    []
  )[0];

  useEffect(() => {
    if (tradingPartner) {
      const candidate = mergeTradingPartnerWithMember(tradingPartner, member);
      setPartner(candidate);
    }
  }, [member, tradingPartner]);

  if (loading || loadingTp) {
    return null;
  }

  const currentError = error || errorTp;

  return currentError ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    partner && <TradingPartnerDetails partner={partner} />
  );
};

export default Container;
