// Vendor
import React, { FC } from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

const IconError: FC<Props> = ({
  fill = '#CB6671',
  height = '13',
  width = '12',
  ...rest
}) => (
  <svg {...rest} width={width} height={height} viewBox='0 0 12 13'>
    <path
      fill={fill}
      fillRule='nonzero'
      d='M6 .688c1.047 0 2.016.261 2.906.785a5.873 5.873 0 0 1 2.121 2.12c.524.891.785 1.86.785 2.907a5.634 5.634 0 0 1-.785 2.906 5.873 5.873 0 0 1-2.12 2.121c-.891.524-1.86.785-2.907.785a5.634 5.634 0 0 1-2.906-.785 5.873 5.873 0 0 1-2.121-2.12A5.634 5.634 0 0 1 .188 6.5c0-1.047.261-2.016.785-2.906a5.873 5.873 0 0 1 2.12-2.121A5.634 5.634 0 0 1 6 .688zm0 10.5c.844 0 1.625-.211 2.344-.633a4.727 4.727 0 0 0 1.71-1.711c.423-.719.633-1.5.633-2.344 0-.844-.21-1.625-.632-2.344a4.727 4.727 0 0 0-1.711-1.71A4.549 4.549 0 0 0 6 1.812c-.844 0-1.625.21-2.344.632a4.727 4.727 0 0 0-1.71 1.711A4.549 4.549 0 0 0 1.311 6.5c0 .844.211 1.625.633 2.344.422.719.992 1.289 1.711 1.71.719.423 1.5.633 2.344.633zM6.937 6.5l1.454 1.453c.046.063.07.133.07.211 0 .078-.024.14-.07.188l-.54.539a.253.253 0 0 1-.187.07.343.343 0 0 1-.21-.07L6 7.438 4.547 8.89a.343.343 0 0 1-.211.07.253.253 0 0 1-.188-.07l-.539-.54a.253.253 0 0 1-.07-.187c0-.078.023-.148.07-.21L5.062 6.5 3.61 5.047a.343.343 0 0 1-.07-.211c0-.078.023-.14.07-.188l.54-.539a.253.253 0 0 1 .187-.07c.078 0 .148.024.21.07L6 5.563l1.453-1.454a.343.343 0 0 1 .211-.07c.078 0 .14.024.188.07l.539.54c.046.046.07.109.07.187a.343.343 0 0 1-.07.21L6.937 6.5z'
    />
  </svg>
);

export default IconError;
