// Vendor
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { CombinatorSelectorProps, Option } from 'react-querybuilder';
import { MenuItem } from '@material-ui/core';

// Internal
import { Select } from 'components';

const CustomComboSelector = (props: CombinatorSelectorProps) => {
  const { handleOnChange, options: optionProps, value = '' } = props;
  const options = optionProps as Option[];

  return options.length === 1 ? (
    <div data-testid='oneOption' style={{ minWidth: '40px' }}>
      {options[0].label}
    </div>
  ) : (
    <Select
      label={options.find((option) => option.name === value)?.label || ''}
    >
      {options.map((option: Option) => {
        const { label, name } = option;
        return (
          <MenuItem
            key={name}
            onClick={() => handleOnChange(name)}
            value={name}
          >
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CustomComboSelector;
