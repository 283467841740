// Vendor
import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';
import Chip from '@material-ui/core/Chip';
import { isEmpty } from 'lodash';

export interface ChipColumnInterface {
  type: 'chip';
  headerName?: string;
  size?: Size;
  dataField: string;
  colorField: string;
  extraClass?: string;
}

export interface Props {
  data: string;
  backgroundColor: string;
  size?: Size;
  extraClass?: string;
}

const ChipCell: FC<Props> = (props) => {
  const { data, backgroundColor, size = 'small', extraClass } = props;

  return (
    <TableCell size={size} data-testid='chipCell' className={extraClass ?? ''}>
      {!isEmpty(data) && (
        <Chip
          label={data}
          style={{
            backgroundColor,
            color: '#fff',
            height: 20,
            fontFamily: 'Graphik',
            fontSize: 12,
            width: 90,
          }}
          data-testid='chipCellChip'
        />
      )}
    </TableCell>
  );
};

export default ChipCell;
