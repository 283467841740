// Vendor
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';

// Internal
import Dialog from 'components/Dialog';
import { Button } from 'components';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  title: string;
  bodyText: string;
  placeholderText?: string;
  onConfirmationClick: (text: string) => void;
  toggleModal: (val: boolean) => void;
  isLoading?: boolean;
}

const TextFieldModal: FC<Props> = (props) => {
  const {
    onConfirmationClick,
    toggleModal,
    title,
    bodyText,
    placeholderText,
    isLoading = false,
  } = props;

  const [text, setText] = useState('');

  return (
    <Dialog title={title} toggleDialog={() => toggleModal(false)}>
      <DialogContent>
        <div className={cx('text')}>{bodyText}</div>
        <div>
          <form noValidate autoComplete='off' className={cx('form')}>
            <TextField
              id='modalTextArea'
              placeholder={placeholderText}
              multiline
              fullWidth
              variant='outlined'
              onChange={(e) => setText(e.target.value)}
              disabled={isLoading}
            />
          </form>
        </div>
        <div className={cx('buttonContainer')}>
          <Button
            color='secondary'
            dataTestId='cancelButton'
            label='Cancel'
            onClick={() => toggleModal(false)}
            style={{
              width: 120,
            }}
            variant='outlined'
            disabled={isLoading}
          />
          <Button
            dataTestId='applyButton'
            label='Apply'
            onClick={() => onConfirmationClick(text)}
            style={{
              border: 'none',
              marginLeft: 8,
              padding: 0,
              width: 120,
            }}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TextFieldModal;
