// Vendor
import { GridSize } from '@material-ui/core';

// Internal
import {
  maxIDLengthValidator,
  maxLongTextLengthValidator,
  nonEmptyValidator,
  required,
  withinDateRange,
} from 'common/validation';
import { SectionField } from 'components/Section';
import {
  ContractFormProps,
  Distributor,
  openClose,
  ContractMode,
} from 'pages/Contracts/Create/constants';
import {
  minimumDatePossible,
  maximumDatePossible,
  contractTypes,
} from './constants';

export const overViewFormFields = (
  entitiesList: { name: string; label: string }[],
  formMode: ContractMode,
  initialValues: ContractFormProps
): SectionField[] => [
  {
    isDisabled: formMode === 'update',
    label: 'External Contract ID',
    name: 'contractId',
    size: 6 as GridSize,
    type: 'text',
    validation: [maxIDLengthValidator, nonEmptyValidator],
  },
  {
    label: 'Contract Description',
    name: 'description',
    size: 6 as GridSize,
    type: 'text',
    validation: [required, maxLongTextLengthValidator],
  },
  {
    isDisabled: formMode === 'update',
    label: 'Contract Type',
    name: 'contractType',
    selectItems: contractTypes,
    size: 6 as GridSize,
    type: 'select',
    validation: [required],
  },
  {
    isDisabled: formMode === 'update' && !!initialValues.entity,
    label: 'Contract Entity',
    name: 'entity',
    selectItems: entitiesList.concat({ label: 'No Contract Entity', name: '' }),
    size: 6 as GridSize,
    type: 'select',
  },
  {
    fallbackDate: minimumDatePossible,
    label: 'Start Date',
    name: 'startDate',
    size: 6 as GridSize,
    type: 'date',
    validation: [
      required,
      withinDateRange({
        startDate: minimumDatePossible,
        endDate: maximumDatePossible,
      }),
    ],
  },
  {
    fallbackDate: maximumDatePossible,
    label: 'End Date',
    name: 'endDate',
    size: 6 as GridSize,
    type: 'date',
    validation: [
      required,
      withinDateRange({
        startDate: minimumDatePossible,
        endDate: maximumDatePossible,
      }),
    ],
  },
  {
    label: 'Open/Closed',
    name: 'openClosed',
    selectItems: openClose,
    size: 6 as GridSize,
    type: 'select',
  },
];

interface DistFields extends SectionField {
  children: any;
}

export const distributorFormFields = (
  contractStartDate: string,
  contractEndDate: string,
  distList: { name: string; label: string }[],
  oldSelectedDistributors?: Distributor[]
): DistFields[] =>
  distList.map((dist) => ({
    children: [
      {
        fallbackDate: contractStartDate,
        isDisabled: false,
        label: 'Start Date',
        maxDate: contractEndDate,
        minDate: contractStartDate,
        name: `startDate`,
        validation: [required],
      },
      {
        fallbackDate: contractEndDate,
        isDisabled: false,
        label: 'End Date',
        maxDate: contractEndDate,
        minDate: contractStartDate,
        name: `endDate`,
        validation: [required],
      },
    ],
    isDisabled: !!oldSelectedDistributors?.find((d) => d.id === dist.name),
    label: dist.label,
    name: dist.name,
  }));
