// Vendor
import React, { FC, useEffect, useMemo, useRef } from 'react';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames/bind';
import { Form } from 'react-final-form';
import { first, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

// Internal
import { CustomerFormProps } from 'pages/Customers/Create/constants';
import { Breadcrumb, Button } from 'components';
import { CustomerViewRow } from 'query';
import {
  useAttributes,
  useAuthDatabases,
  useIsFirstRender,
  useRequiredFields,
} from 'hooks';
import {
  localCustomerPath,
  partitionUniqueCustomerLists,
} from 'common/helpers';
import { required } from 'common/validation';
import {
  formValidation,
  getGpoAffiliation,
  getInitialValues,
  importIdentifierData,
  isParentOrIdentifierUpdated,
} from 'services/CustomerMgmt';
import Section from 'components/Section';
import Relationships from './Relationships';
import Identifiers from './EditSections/Identifiers';
import Overview from './EditSections/Overview';
import Attributes from './EditSections/Attributes';

// Styles
import styles from './CustomerMgmt.module.css';
const cx = classnames.bind(styles);

interface Props {
  cots: {
    id: string;
    name: string;
  }[];
  createLoading: boolean;
  currentCustomer?: CustomerViewRow;
  customErrors: any;
  gpoAffiliation: {
    id: string;
    name: string;
  }[];
  identifierData: CustomerViewRow[];
  isUpdateAction?: boolean;
  onSubmit: (values: CustomerFormProps) => void;
  setFormValues: (parentId: any) => void;
  setParentId: (parentId: string) => void;
  validateIds: (values: CustomerFormProps) => void;
}

const CustomerMgmt: FC<Props> = (props) => {
  const requiredFields = useRequiredFields() ?? [];

  const {
    cots,
    createLoading,
    currentCustomer,
    customErrors,
    gpoAffiliation,
    identifierData,
    isUpdateAction,
    onSubmit,
    setFormValues,
    setParentId,
    validateIds,
  } = props;
  const formRef = useRef<CustomerFormProps>();

  const acceptableAuthData = useAuthDatabases();
  const attributes = useAttributes();

  const navigate = useNavigate();
  const handleOnClickCancel = () =>
    isUpdateAction && currentCustomer
      ? navigate(`${localCustomerPath(currentCustomer?.id)}`, { replace: true })
      : navigate('/customers', { replace: true });

  useEffect(() => {
    setFormValues(formRef.current);
  }, [formRef.current]);

  const name = first(currentCustomer?.names) ?? '';
  const { id = '' } = currentCustomer || {};

  const firstRender = useIsFirstRender();
  const initialValues = useMemo(
    () => (firstRender ? getInitialValues(currentCustomer) : formRef.current),
    []
  );

  return (
    <div className={cx('root')} data-testid='customer-mgmt'>
      <Breadcrumb
        crumbs={[
          'Customers',
          ...(isUpdateAction ? [name] : []),
          `${isUpdateAction ? 'Update' : 'Create'} Customer`,
        ]}
        links={[
          `/customers`,
          ...(isUpdateAction ? [localCustomerPath(id)] : []),
        ]}
      />
      <Form
        data-testid='form-hey'
        initialValues={initialValues}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={onSubmit}
        validate={(values) => formValidation(values)}
        render={({
          errors,
          form,
          handleSubmit,
          pristine,
          submitting,
          values,
        }) => {
          const { dea, hin, parentId, three40b } = values;
          const {
            dea: deaErr,
            hin: hinErr,
            parentId: parentIdErr,
            three40b: three40bErr,
          } = customErrors;
          formRef.current = values;
          const isDisabled = submitting || pristine;
          const { change, mutators } = form;

          return (
            <form onSubmit={handleSubmit}>
              <>
                <section className={cx('buttons')}>
                  <Button
                    color='secondary'
                    dataTestId='cancelButton'
                    label='Cancel'
                    onClick={handleOnClickCancel}
                    style={{
                      marginRight: 8,
                      minWidth: 152,
                    }}
                    variant='outlined'
                  />
                  <Button
                    dataTestId='submitButton'
                    disabled={
                      !isEmpty(errors) ||
                      (!isParentOrIdentifierUpdated(values, currentCustomer) &&
                        isDisabled) ||
                      createLoading
                    }
                    label={isUpdateAction ? 'Update' : 'Create'}
                    loading={createLoading}
                    style={{ minWidth: 152 }}
                    type='submit'
                  />
                </section>
                {acceptableAuthData.length > 0 && (
                  <Identifiers
                    dea={dea}
                    deaErr={deaErr}
                    hin={hin}
                    hinErr={hinErr}
                    requiredFields={requiredFields}
                    three40b={three40b}
                    three40bErr={three40bErr}
                    validateIds={validateIds}
                    values={values}
                  />
                )}
                <Overview
                  change={change}
                  cots={cots}
                  identifierData={identifierData}
                  setIdentifier={(customer: any) =>
                    importIdentifierData(change, cots, customer, values)
                  }
                  isUpdateAction={isUpdateAction}
                  requiredFields={requiredFields}
                  values={values}
                />
                {attributes.length > 0 && (
                  <Attributes attributes={attributes} />
                )}
                <Relationships
                  customError={parentId && parentIdErr}
                  setParentId={(parentId: string) => {
                    change('parentId', parentId);
                    setParentId(parentId);
                  }}
                  values={values}
                />
                {gpoAffiliation.length > 0 && (
                  <Section
                    fields={getGpoAffiliation(
                      partitionUniqueCustomerLists(
                        currentCustomer?.memberships || []
                      ).gpo,
                      mutators.update,
                      gpoAffiliation,
                      values
                    )}
                    title='GPO Affiliation'
                    values={values}
                  />
                )}
                <Section
                  fields={[
                    {
                      label: 'Start Date',
                      name: 'startDate',
                      type: 'date',
                      validation: [
                        ...(requiredFields?.includes('startDate')
                          ? [required]
                          : []),
                      ],
                    },
                    {
                      label: 'End Date',
                      name: 'endDate',
                      type: 'date',
                      validation: [
                        ...(requiredFields?.includes('endDate')
                          ? [required]
                          : []),
                      ],
                    },
                  ]}
                  title='Dates'
                  values={values}
                />
              </>
            </form>
          );
        }}
      />
    </div>
  );
};

export default CustomerMgmt;
