export default () => ({
  listItem: {
    '&:hover': {
      backgroundColor: '#011f42 !important',
      color: '#fff',
    },
  },
  listItemSelected: {
    backgroundColor: '#011f42 !important',
    color: '#fff',
  },
});
