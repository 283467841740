// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import {
  Paper,
  Table as MUITable,
  TableBody,
  TableHead,
  TableRow as Row,
} from '@material-ui/core';
import { FormApi } from 'final-form';

// Internal
import { EmptyResults } from 'components';
import { IconLoading } from 'styles/images';
import ExpandingRow, { ExpandingRowInterface } from '../Rows/ExpandingRow';
import NestedTableRow, {
  NestedTableRowInterface,
} from '../Rows/NestedTableRow';
import HeaderCell from '../Cells/Header';
import { ChipColumnInterface } from '../Cells/Chip';
import { DateColumnInterface } from '../Cells/Date';
import { ExpandingColumnInterface } from '../Cells/Expanding';
import { LinkColumnInterface } from '../Cells/Link';
import { ErrorsColumnInterface } from '../Cells/Errors';
import { CheckBoxColumnInterface } from '../Cells/CheckBox';
import { ButtonColumnInterface } from '../Cells/Button';
import { DateInputColumnInterface } from '../Cells/DateInput';
import { PriceInputColumnInterface } from '../Cells/PriceInput';
import { SelectPricingTypeInputColumnInterface } from '../Cells/SelectInput';
import { MultipleButtonsColumnInterface } from '../Cells/MultipleButtonsCell';
import { RadioColumnInterface } from '../Cells/RadioInput';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export type TableColumn =
  | CheckBoxColumnInterface
  | ChipColumnInterface
  | DateColumnInterface
  | ExpandingColumnInterface
  | LinkColumnInterface
  | ErrorsColumnInterface
  | ButtonColumnInterface
  | MultipleButtonsColumnInterface
  | DateInputColumnInterface
  | PriceInputColumnInterface
  | RadioColumnInterface
  | SelectPricingTypeInputColumnInterface;

export type TableRow = ExpandingRowInterface | NestedTableRowInterface;

export interface Props {
  children?: any;
  columns: TableColumn[];
  rows: TableRow[];
  isLoading: boolean;
  emptyText: string;
  handleRowClick?: (data: any) => void;
  hasExpandingRows?: boolean;
  form?: FormApi;
}

const Table: FC<Props> = (props) => {
  const {
    children,
    columns,
    rows,
    isLoading,
    emptyText,
    handleRowClick,
    hasExpandingRows = true,
    form,
  } = props;

  return (
    <Paper className={cx('tableRoot')}>
      {isLoading && (
        <div className={cx('loading')}>
          <IconLoading />
        </div>
      )}
      {!isLoading && rows.length > 0 ? (
        <MUITable>
          {children}
          <TableHead
            className={cx('tableHead')}
            style={{ zIndex: 1, boxShadow: '0px 2px 10px 1px rgba(0,0,0,0.1)' }}
          >
            <Row>
              {columns.map((column, i) => (
                <HeaderCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`header-${i}`}
                  text={'headerName' in column ? column.headerName : ''}
                  size={column.size || 'small'}
                />
              ))}
              {hasExpandingRows && <HeaderCell size='small' />}
            </Row>
          </TableHead>
          <TableBody>
            {rows.map((row: TableRow, i) => {
              switch (row.type) {
                case 'expanding':
                  return (
                    <ExpandingRow
                      order={i}
                      data={row.data}
                      columns={columns}
                      isActiveRow={row.isActiveRow}
                      isExpandable={row.isExpandable}
                      handleRowClick={handleRowClick}
                      rowHoverBorder={row.rowHoverBorder}
                      expandedInitially={row.expandedInitially}
                      form={form}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`row-${i}`}
                    />
                  );
                case 'table':
                  return (
                    <NestedTableRow
                      columns={row.columns}
                      rows={row.rows}
                      isLoading={row.isLoading}
                      emptyText={row.emptyText}
                      colSpan={row.colSpan}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`row-${i}`}
                    />
                  );
                default:
                  return null;
              }
            })}
          </TableBody>
        </MUITable>
      ) : (
        !isLoading && <EmptyResults text={emptyText} />
      )}
    </Paper>
  );
};

export default Table;
