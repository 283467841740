// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconIdentifierData: FC<Props> = ({ color = '#011F42' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='IconIdentifierDataA'
        d='M11.437 10.75c.311 0 .563-.252.563-.563V3.063c0-.444-.49-.713-.865-.475l-3.26 2.075v-1.6c0-.444-.49-.713-.865-.475L3.75 4.663V.813c0-.311-.252-.563-.563-.563H.562C.252.25 0 .502 0 .813v9.374c0 .311.252.563.562.563h10.875zm-.562-1.125h-9.75v-8.25h1.5v4.313c0 .443.49.712.864.474L6.75 4.087v1.6c0 .444.49.713.864.475l3.261-2.075v5.538zM9.469 8.5c.155 0 .281-.126.281-.281V7.28c0-.155-.126-.281-.281-.281H8.53c-.155 0-.281.126-.281.281v.938c0 .155.126.281.281.281h.938zm-3 0c.155 0 .281-.126.281-.281V7.28c0-.155-.126-.281-.281-.281H5.53c-.155 0-.281.126-.281.281v.938c0 .155.126.281.281.281h.938zm-3 0c.155 0 .281-.126.281-.281V7.28c0-.155-.126-.281-.281-.281H2.53c-.155 0-.281.126-.281.281v.938c0 .155.126.281.281.281h.938z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(6 7)'>
      <mask id='IconIdentifierDataB' fill={color}>
        <use xlinkHref='#IconIdentifierDataA' />
      </mask>
      <use fill={color} fillRule='nonzero' xlinkHref='#IconIdentifierDataA' />
      <g fill={color} mask='url(#IconIdentifierDataB)'>
        <path d='M0 0L24 0 24 24 0 24z' transform='translate(-6 -7)' />
      </g>
    </g>
  </svg>
);

export default IconIdentifierData;
