// Vendor
import React, { FC, Fragment } from 'react';
import classnames from 'classnames/bind';

// Internal
import { CustomerViewRow } from 'query';
import { formatAddress, formatAddressType } from 'common/helpers';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  addresses: NonNullable<CustomerViewRow['addresses']>;
}

const AddressTooltip: FC<Props> = ({ addresses }) => (
  <div className={cx('root')}>
    Addresses
    <div className={cx('column')}>
      {addresses.map((address) => {
        const {
          address1 = '',
          address2 = '',
          address3 = '',
          addressType = '',
          city = '',
          state = '',
          zipCode = '',
        } = address;
        const street = [address1, address2, address3];
        const cityState = [city, state, zipCode];

        return (
          <Fragment key={zipCode}>
            <b className={cx('key')} key={addressType}>
              {formatAddressType(addressType)}
            </b>
            <div className={cx('value')} key={address1 + address2 + address3}>
              {formatAddress(street)}
              <br />
              {formatAddress(cityState)}
            </div>
          </Fragment>
        );
      })}
    </div>
  </div>
);

export default AddressTooltip;
