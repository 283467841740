// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { IconEmptyResults } from '../../styles/images';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  height: number | string;
  width: number | string;
  iconStyle: string;
  text: string;
  textStyle: string;
}

const TableEmptyResults: FC<Props> = (props) => {
  const {
    text = 'No results found',
    iconStyle = '',
    textStyle = '',
    height,
    width,
  } = props;
  return (
    <div className={cx('root', iconStyle)}>
      <IconEmptyResults height={height} width={width} />
      <p className={cx('text', textStyle)}>{text}</p>
    </div>
  );
};

export default TableEmptyResults;
