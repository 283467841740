// Internal
import { OUT_OF_NETWORK_DIGIT } from 'common/constants';

export enum RosterKind {
  ALL = 'all',
  IN_NETWORK = 'in-network',
  OUT_OF_NETWORK = 'out-of-network',
}

export const filtersToQuery = (filter: RosterKind): {} => {
  switch (filter) {
    case RosterKind.IN_NETWORK:
      return { _lt: OUT_OF_NETWORK_DIGIT };
    case RosterKind.OUT_OF_NETWORK:
      return { _gte: OUT_OF_NETWORK_DIGIT };
    default:
      return {};
  }
};
