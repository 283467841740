// Vendor
import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';

// Internal
import Section from 'components/Section';
import {
  ContractFormProps,
  Distributor,
  ContractMode,
} from 'pages/Contracts/Create/constants';
import { overViewFormFields } from './service';
import Distributors from './Distributors';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface Props {
  change?: any;
  distList: { name: string; label: string }[];
  formMode: ContractMode;
  formUpdate: (...args: any[]) => any;
  entitiesList: { name: string; label: string }[];
  oldSelectedDistributors: Distributor[];
  values: ContractFormProps;
  initialValues: ContractFormProps;
}

const OverviewView: FC<Props> = (props) => {
  const {
    change,
    distList,
    formMode,
    formUpdate,
    entitiesList,
    oldSelectedDistributors,
    values,
    initialValues,
  } = props;

  // Updating the final form distributors array here because we grab the distributors on this step
  useEffect(() => {
    distList.forEach((dist, i) => {
      const currentValue = values.distributors.find(
        (d: Distributor) => d.id === dist.name
      );
      const oldValue = oldSelectedDistributors.find(
        (d: Distributor) => d.id === dist.name
      );

      const checked = currentValue
        ? currentValue.checked
        : oldValue?.checked ?? false;

      const startDate = currentValue
        ? currentValue.startDate
        : oldValue?.startDate ?? '';

      const endDate = currentValue
        ? currentValue.endDate
        : oldValue?.endDate ?? '';

      formUpdate('distributors', i, {
        checked,
        id: dist.name,
        startDate,
        endDate,
      });
    });
  }, [distList]);

  return (
    <div data-testid='product-mgmt' className={cx('overview-forms-container')}>
      <div className={cx('overview')}>
        <Section
          change={change}
          fields={overViewFormFields(entitiesList, formMode, initialValues)}
          title='Overview'
          values={values}
        />
      </div>
      <Distributors
        distList={distList}
        formUpdate={formUpdate}
        oldSelectedDistributors={oldSelectedDistributors}
        values={values}
      />
    </div>
  );
};

export default OverviewView;
