// Vendor
import { GridSize } from '@material-ui/core';
import { flattenDeep, isEmpty } from 'lodash';

// Internal
import { CustomerViewRow } from 'query';
import { DATE_FORMAT_MMDDYYYY } from 'common/constants';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
  partitionUniqueCustomerLists,
  pluckId,
  truncateDatetime,
} from 'common/helpers';
import { Attribute } from 'types/generated/Attribute';
import { generateID } from 'common/utils';
import { isSameOrAfterDate, withinDateRange } from 'common/validation';
import {
  CustomerFormProps,
  emptyFormObject,
  GPOAffiliation,
} from 'pages/Customers/Create/constants';
import { UpsertCustomer } from 'types/generated/UpsertCustomer';

export const toAddressType = (
  type: string
): 'primary' | 'shipTo' | 'billTo' | 'mailTo' | '' => {
  switch (type) {
    case 'primary':
      return 'primary';
    case 'shipTo':
      return 'shipTo';
    case 'billTo':
      return 'billTo';
    case 'mailTo':
      return 'mailTo';
    case '':
      return '';
    default:
      throw new Error(`Unknown address type: ${type}`);
  }
};

export const constructCustomer = (
  values: CustomerFormProps,
  currentCustomer?: CustomerViewRow
): UpsertCustomer => {
  const {
    address1,
    address2,
    address3,
    addressEndDate: addressEndDateNoFormat,
    addressStartDate: addressStartDateNoFormat,
    addressType,
    city,
    classOfTrade,
    cotEndDate: cotEndDateNoFormat,
    cotStartDate: cotStartDateNoFormat,
    dea,
    endDate: endDateNoFormat,
    hin,
    name,
    parentId,
    startDate: startDateNoFormat,
    state,
    three40b,
    zip,
  } = values;

  const identifiers = formIdstoCustomerIds({ dea, hin, three40b });

  const endDate = convertEndDateToUtcTimestamp(endDateNoFormat);
  const startDate = convertStartDateToUtcTimestamp(startDateNoFormat);
  const addressEndDate = convertEndDateToUtcTimestamp(addressEndDateNoFormat);
  const addressStartDate = convertStartDateToUtcTimestamp(
    addressStartDateNoFormat
  );
  const cotEndDate = convertEndDateToUtcTimestamp(cotEndDateNoFormat);
  const cotStartDate = convertStartDateToUtcTimestamp(cotStartDateNoFormat);

  const classesOfTrade = classOfTrade
    ? [
        {
          classOfTradeId: classOfTrade,
          endDate: cotEndDate,
          startDate: cotStartDate,
        },
      ]
    : [];
  const id = currentCustomer ? currentCustomer.id : generateID();

  const attributes: { name: string; value: string }[] = [];
  Object.entries(values).forEach(([key, value]) => {
    if (key.startsWith('attributes-')) {
      attributes.push({ name: key.slice(11), value });
    }
  });

  const lists: { endDate?: string; listId: string; startDate?: string }[] = [];
  values.gpoAffiliation.forEach((gpo) => {
    const { checked, endDate, listId, startDate } = gpo;

    if (checked) {
      lists.push({
        endDate: convertEndDateToUtcTimestamp(endDate),
        listId,
        startDate: convertStartDateToUtcTimestamp(startDate),
      });
    }
  });

  return {
    addresses: [
      {
        address1,
        address2,
        address3,
        addressType: toAddressType(addressType),
        city,
        endDate: addressEndDate,
        startDate: addressStartDate,
        state,
        zipCode: zip,
      },
    ],
    attributes,
    classesOfTrade,
    endDate,
    id,
    identifiers: identifiers.length > 0 ? identifiers : undefined,
    lists,
    names: [name],
    parentId,
    startDate,
    relationships: currentCustomer?.relationships || undefined,
  };
};

export const formatFormIds = (values: any): {} => {
  const ids: { dea?: string; hin?: string; three40b?: string } = {};
  if (values.hin) ids.hin = values.hin;
  if (values.dea) ids.dea = values.dea;
  if (values.three40b) ids.three40b = values.three40b;
  return ids;
};

export const getGpoAffiliation = (
  existingGpos: {
    listId: string;
  }[],
  formUpdate: (...args: any[]) => any,
  gpoAffiliation: {
    id: string;
    name: string;
  }[],
  values: CustomerFormProps
): any[] =>
  flattenDeep(
    gpoAffiliation.map(({ id, name }) => {
      const checked = values.gpoAffiliation?.find(
        (list) => list.listId === id
      )?.checked;
      let valueIndex = values.gpoAffiliation?.findIndex(
        (list) => list.listId === id
      );
      if (valueIndex === -1) valueIndex = values.gpoAffiliation.length + 1;

      const isDisabled = !isEmpty(
        existingGpos?.find((list) => list.listId === id)
      );

      const affiliation = [];
      affiliation.push({
        checked,
        handleOnChange: () => {
          if (!values.gpoAffiliation[valueIndex]?.checked) {
            formUpdate('gpoAffiliation', valueIndex, {
              checked: true,
              listId: id,
              name,
              startDate: '',
              endDate: '',
            });
          } else {
            formUpdate('gpoAffiliation', valueIndex, {
              checked: false,
              listId: id,
              name,
              startDate: '',
              endDate: '',
            });
          }
        },
        label: name,
        name: id,
        isDisabled,
        showLabel: false,
        size: checked ? 4 : (9 as GridSize),
        type: 'checkbox',
      });

      if (checked) {
        affiliation.push({
          label: 'Start Date',
          name: `gpoAffiliation.${valueIndex}.startDate`,
          type: 'date',
        });
        affiliation.push({
          label: 'End Date',
          name: `gpoAffiliation.${valueIndex}.endDate`,
          type: 'date',
        });
      }
      return affiliation;
    })
  );

export const importIdentifierData = (
  change: any,
  cots: {
    id: string;
    name: string;
  }[],
  selectedIdentifier: any,
  values: CustomerFormProps
) => {
  const {
    classOfTrade: cot,
    cotEndDate: cotEndDateValue,
    cotStartDate: cotStartDateValue,
  } = values;
  // Grab variables from identifier selected from drop down + consider no selected identifier
  const {
    addresses: identifierAddresses,
    classesOfTrade: identifierCOTS,
    names: identifierNames,
  } = selectedIdentifier || {};

  const firstIdentifierAddress = identifierAddresses?.[0] || {};
  const {
    address1: identifierAddress1,
    address2: identifierAddress2,
    address3: identifierAddress3,
    addressType: identifierAddressType,
    city: identifierCity,
    endDate: identifierAddressEnd,
    startDate: identifierAddressStart,
    state: identifierState,
    zipCode: identifierZip,
  } = firstIdentifierAddress || {};

  const firstCOT = identifierCOTS?.[0] || {};
  const doesIdentifierCOTExist = cots.some(
    (cot: any) => cot.id === firstCOT?.classOfTradeId
  );
  const classOfTrade = doesIdentifierCOTExist ? firstCOT?.classOfTradeId : cot;
  const cotEndDate = truncateDatetime(firstCOT?.endDate) || cotEndDateValue;
  const cotStartDate =
    truncateDatetime(firstCOT?.startDate) || cotStartDateValue;

  const address1 = identifierAddress1 || values.address1;
  const address2 = identifierAddress2 || values.address2;
  const address3 = identifierAddress3 || values.address3;
  const addressEndDate =
    truncateDatetime(identifierAddressEnd) || values.addressEndDate;
  const addressStartDate =
    truncateDatetime(identifierAddressStart) || values.addressStartDate;
  const addressType = identifierAddressType || values.addressType;
  const city = identifierCity || values.city;
  const name = identifierNames?.[0] || values.name;
  const state = identifierState || values.state;
  const zip = identifierZip || values.zip;

  const newValues = {
    address1,
    address2,
    address3,
    addressEndDate,
    addressStartDate,
    addressType,
    city,
    classOfTrade,
    cotEndDate,
    cotStartDate,
    name,
    state,
    zip,
  };

  Object.entries(newValues).forEach(([key, value]) => {
    change(key, value);
  });

  return newValues;
};

export const getInitialValues = (currentCustomer: any) => {
  // Grab variables from current customer (update) + consider create customer not update
  const {
    addresses,
    attributes,
    classesOfTrade,
    endDate,
    identifiers: currentIdentifiers,
    names,
    parentId,
    startDate,
  } = currentCustomer || {};

  const firstCOT = classesOfTrade?.[0] || {};
  const firstAddress = addresses?.[0] || {};

  const {
    address1,
    address2,
    address3,
    addressType,
    city,
    endDate: addressEnd,
    startDate: addressStart,
    state,
    zipCode: zip,
  } = firstAddress || {};

  const dea = currentIdentifiers?.find((identifier: string) =>
    identifier.includes('dea')
  );
  const hin = currentIdentifiers?.find((identifier: string) =>
    identifier.includes('hin')
  );
  const three40b = currentIdentifiers?.find((identifier: string) =>
    identifier.includes('340b')
  );

  const { gpo } = partitionUniqueCustomerLists(
    currentCustomer?.memberships ?? []
  );

  const initialValues: any = {
    ...emptyFormObject,
    address1,
    address2,
    address3,
    addressEndDate: truncateDatetime(addressEnd),
    addressStartDate: truncateDatetime(addressStart),
    addressType,
    city,
    classOfTrade: firstCOT.classOfTradeId,
    cotEndDate: truncateDatetime(firstCOT.endDate),
    cotStartDate: truncateDatetime(firstCOT.startDate),
    dea: pluckId(dea),
    endDate: truncateDatetime(endDate),
    gpoAffiliation:
      gpo?.map((list: any) => {
        const {
          endDate,
          list: { name },
          listId,
          startDate,
        } = list;
        return {
          checked: true,
          endDate: truncateDatetime(endDate),
          listId,
          name: name ?? listId,
          startDate: truncateDatetime(startDate),
        };
      }) ?? [],
    hin: pluckId(hin),
    name: names?.[0],
    parentId,
    startDate: truncateDatetime(startDate),
    state,
    three40b: pluckId(three40b),
    zip,
  };

  attributes?.forEach((attribute: Attribute) => {
    const { name, value } = attribute;
    initialValues[`attributes-${name}`] = value;
  });

  return initialValues;
};

// ['dea:number:AA1701196', 'hin:number:00C5JVV00'] -> ['AA1701196', '00C5JVV00']
export const customerIdsToFormIds = (
  identifiers: string[] | null
): string[] => {
  if (identifiers === null) return [];
  return identifiers.map((id) => pluckId(id));
};

// {dea: AA1701196}, {hin: 00C5JVV00} -> ['dea:number:AA1701196', 'hin:number:00C5JVV00']
export const formIdstoCustomerIds = ({
  dea,
  hin,
  three40b,
}: {
  dea?: string;
  hin?: string;
  three40b?: string;
}) => {
  if (dea === null && hin === null && three40b === null) return [];
  const identifiers = [];
  if (dea) identifiers.push(`dea:number:${dea}`);
  if (hin) identifiers.push(`hin:number:${hin}`);
  if (three40b) identifiers.push(`340b:number:${three40b}`);
  return identifiers;
};

export const isParentOrIdentifierUpdated = (
  values: CustomerFormProps,
  customer?: CustomerViewRow
) => {
  if (!customer) return false;

  const { identifiers, parentId } = customer;
  const { dea, hin, three40b } = values;

  const formattedIds = customer && customerIdsToFormIds(identifiers);
  // Lack of a parentId on a customer gives us a null value while lack of a parentId in our form gives us undefined
  // If values parentId is undefined, we set it to null, so we can still use === comparison
  const formParentId = values.parentId || null;

  return (
    !(formParentId === parentId) ||
    (formattedIds && dea && !formattedIds.includes(dea)) ||
    (formattedIds && hin && !formattedIds.includes(hin)) ||
    (formattedIds && three40b && !formattedIds.includes(three40b))
  );
};

export const endDateValidation = (
  customerEndDate: string | null,
  customerStartDate: string | null,
  endDate: string | null,
  startDate: string | null
): string | undefined => {
  if (endDate === customerEndDate) return undefined;
  return (
    withinDateRange({ startDate: customerStartDate, endDate: customerEndDate })(
      endDate
    ) ||
    isSameOrAfterDate(customerStartDate)(endDate) ||
    isSameOrAfterDate(startDate)(endDate)
  );
};

export const startDateValidation = (
  customerEndDate: string | null,
  customerStartDate: string | null,
  startDate: string | null
): string | undefined => {
  if (startDate === customerStartDate) return undefined;
  return (
    withinDateRange({ startDate: customerStartDate, endDate: customerEndDate })(
      startDate
    ) || isSameOrAfterDate(customerStartDate)(startDate)
  );
};

export const GPO_NOT_IN_RANGE =
  'GPO affiliation effectivity dates must be within customer date range';

export const formValidation = (values: any) => {
  const {
    addressEndDate = '',
    addressStartDate = '',
    cotEndDate = '',
    cotStartDate = '',
    endDate = '',
    gpoAffiliation = [],
    startDate = '',
  } = values;
  const errors: any = {
    gpoAffiliation: [],
  };

  const formatAddressStartDate = truncateDatetime(
    addressStartDate,
    DATE_FORMAT_MMDDYYYY
  );
  const formatAddressEndDate = truncateDatetime(
    addressEndDate,
    DATE_FORMAT_MMDDYYYY
  );
  const formatCotStartDate = truncateDatetime(
    cotStartDate,
    DATE_FORMAT_MMDDYYYY
  );
  const formatCotEndDate = truncateDatetime(cotEndDate, DATE_FORMAT_MMDDYYYY);
  const formatStartDate = truncateDatetime(startDate, DATE_FORMAT_MMDDYYYY);
  const formatEndDate = truncateDatetime(endDate, DATE_FORMAT_MMDDYYYY);

  if (addressStartDate) {
    errors.addressStartDate = startDateValidation(
      formatEndDate,
      formatStartDate,
      formatAddressStartDate
    );
  }
  if (addressEndDate) {
    errors.addressEndDate = endDateValidation(
      formatEndDate,
      formatStartDate,
      formatAddressEndDate,
      formatAddressStartDate
    );
  }
  if (cotStartDate) {
    errors.cotStartDate = startDateValidation(
      formatEndDate,
      formatStartDate,
      formatCotStartDate
    );
  }
  if (cotEndDate) {
    errors.cotEndDate = endDateValidation(
      formatEndDate,
      formatStartDate,
      formatCotEndDate,
      formatCotStartDate
    );
  }
  if (formatEndDate) {
    errors.endDate = isSameOrAfterDate(formatStartDate)(formatEndDate);
  }

  gpoAffiliation.forEach((gpo: GPOAffiliation, i: number) => {
    const { endDate: gpoEndDate, startDate: gpoStartDate } = gpo;

    const startDateError =
      gpoStartDate &&
      withinDateRange({
        startDate,
        endDate,
        message: GPO_NOT_IN_RANGE,
      })(gpoStartDate);

    const endDateError =
      gpoEndDate &&
      (withinDateRange({
        startDate,
        endDate,
        message: GPO_NOT_IN_RANGE,
      })(gpoEndDate) ||
        isSameOrAfterDate(gpoStartDate)(gpoEndDate));

    if (startDateError || endDateError) {
      errors.gpoAffiliation[i] = {
        ...(startDateError && { startDate: startDateError }),
        ...(endDateError && { endDate: endDateError }),
      };
    }
  });

  if (errors.gpoAffiliation.length === 0) {
    delete errors.gpoAffiliation;
  }

  return errors;
};
