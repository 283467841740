// Vendor
import Paper from '@material-ui/core/Paper';
import React, { FC, Fragment } from 'react';
import classnames from 'classnames/bind';
import { get, startCase } from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

// Internal
import { Tooltip, ValueTooltip } from 'components/Tooltips';
import { NotFoundSection } from 'components';
import {
  parseMediledgerId,
  truncateDatetime,
  toNamedIdentifier,
  isRosterOON,
} from 'common/helpers';
import { ContractViewRow } from 'query';
import { DEFAULT_EMPTY_STATE } from 'common/constants';
import { ContractEntity } from 'types/generated/ContractEntity';
import Value from 'components/KeyValuePair/Value';
import { useAuthDatabases } from 'hooks';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  contractOverview: ContractViewRow;
  memberDetails: any;
  title: string;
  tradingPartners: any;
  isMFR: boolean;
}

interface DistData {
  name: string;
  startDate: string | undefined;
  endDate: string | undefined;
  modelNIds: string[];
}

const Distributors: FC<Props> = (props) => {
  const { contractOverview, memberDetails, title, tradingPartners, isMFR } =
    props;
  const { distributors = [] } = contractOverview;

  const acceptableAuthData = useAuthDatabases();

  const dists: DistData[] =
    distributors && distributors.length
      ? distributors.map((dist: ContractEntity) => {
          const distId = parseMediledgerId(dist.id).memberId;
          return {
            name: isRosterOON(distId)
              ? dist.id
              : tradingPartners?.[parseMediledgerId(dist.id).memberId]?.name ??
                dist.id,
            endDate: dist.endDate,
            startDate: dist.startDate,
            modelNIds: get(memberDetails[dist.id], 'modelNIds', []),
          };
        })
      : [];

  return (
    <Paper className={cx('root')}>
      <div className={cx('column', 'content')}>
        <div className={cx('row')}>
          <header className={cx('header')}>{startCase(title)}</header>
        </div>
        {dists.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NAME</TableCell>
                {acceptableAuthData.length > 0 && isMFR && (
                  <TableCell>IDENTIFIERS</TableCell>
                )}
                <TableCell data-testid='startDate'>START DATE</TableCell>
                <TableCell data-testid='endDate'>END DATE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dists.map((dist: DistData) => {
                const { name, startDate, endDate, modelNIds } = dist;
                const formattedIds = modelNIds.map((i) => toNamedIdentifier(i));

                const idTooltip = (
                  <ValueTooltip header='Identifiers' values={formattedIds} />
                );

                return (
                  <Fragment key={name + startDate + endDate}>
                    <TableRow>
                      <TableCell style={{ minWidth: 128 }}>
                        <Tooltip title={name}>
                          {name || DEFAULT_EMPTY_STATE}
                        </Tooltip>
                      </TableCell>
                      {acceptableAuthData.length > 0 && isMFR && (
                        <TableCell>
                          <Value
                            value={formattedIds[0]}
                            valueIcon={
                              formattedIds.length > 1 ? idTooltip : undefined
                            }
                            valueLength={formattedIds.length - 1}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        {truncateDatetime(startDate) || DEFAULT_EMPTY_STATE}
                      </TableCell>
                      <TableCell>
                        {truncateDatetime(endDate) || DEFAULT_EMPTY_STATE}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}
        {!dists.length && <NotFoundSection text={`No ${title} found`} />}
      </div>
    </Paper>
  );
};

export default Distributors;
