// Vendor
import React from 'react';

const IconProfilePicture = () => (
  <svg width='35' height='35' viewBox='0 0 28 28'>
    <defs>
      <linearGradient
        id='prefix__c'
        x1='22.76%'
        x2='79.648%'
        y1='0%'
        y2='82.409%'
      >
        <stop offset='0%' stopColor='#FFF' />
        <stop offset='100%' stopColor='#FFF' />
      </linearGradient>
      <circle id='prefix__a' cx='11' cy='11' r='11' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g transform='translate(3 3)'>
        <mask id='prefix__b' fill='#fff'>
          <use xlinkHref='#prefix__a' />
        </mask>
        <use fill='#D1DCE2' xlinkHref='#prefix__a' />
        <path
          fill='#FFF'
          fillRule='nonzero'
          d='M10.875 14.25c2.486 0 4.5-2.014 4.5-4.5s-2.014-4.5-4.5-4.5-4.5 2.014-4.5 4.5 2.014 4.5 4.5 4.5zm0-1.125c-1.86 0-3.375-1.515-3.375-3.375s1.515-3.375 3.375-3.375S14.25 7.89 14.25 9.75s-1.515 3.375-3.375 3.375zm6.187 10.125c.932 0 1.688-.756 1.688-1.688V20.1c0-2.609-2.116-4.725-4.725-4.725-1.009 0-1.494.563-3.15.563s-2.138-.563-3.15-.563C5.116 15.375 3 17.491 3 20.1v1.462c0 .932.756 1.688 1.687 1.688h12.375zm0-1.125H4.687c-.309 0-.562-.253-.562-.563V20.1c0-1.986 1.614-3.6 3.6-3.6.689 0 1.375.563 3.15.563 1.772 0 2.46-.563 3.15-.563 1.986 0 3.6 1.614 3.6 3.6v1.462c0 .31-.253.563-.563.563z'
          mask='url(#prefix__b)'
        />
      </g>
      <circle
        cx='14'
        cy='14'
        r='14'
        stroke='url(#prefix__c)'
        strokeWidth='1.5'
      />
    </g>
  </svg>
);

export default IconProfilePicture;
