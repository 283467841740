// Vendor
import { last } from 'lodash';

// Internal
import {
  AuthorityIDMapping,
  isRosterOON,
  parseMediledgerId,
} from 'common/helpers';
import { ParticipantType, TradingPartner } from 'query';

export * from './address';
export * from './authorityData';
export * from './customerDetails';
export * from './customerList';
export * from './date';
export * from './documents';
export * from './errors';
export * from './roster';
export * from './routing';
export * from './relationships';
export * from './tradingPartner';
export * from './identifiers';
export * from './currency';
export * from './pricings';

export const getSearchParam = (
  search: string,
  value: string
): string | null => {
  const urlParams = new URLSearchParams(search);
  return urlParams.get(value);
};

export const localCustomerPath = (id: string) =>
  `/customers/${encodeURIComponent(id)}`;

export const contractPath = (memberId: number, id: string) =>
  `/contracts/${memberId}/${encodeURIComponent(id)}`;

export const chargebackPath = (memberId: number, id: string) =>
  `/chargebacks/${memberId}/${encodeURIComponent(id)}`;

export const creditMemoPath = (id: string) =>
  `/credit-memos/${encodeURIComponent(id)}`;

export const customerListPath = (id: string) =>
  `/customer-lists/${encodeURIComponent(id)}`;

export const productPath = (id: string) =>
  `/products/${encodeURIComponent(id)}`;

export const productListPath = (id: string) =>
  `/product-lists/${encodeURIComponent(id)}`;

export const proposalPath = (id: string, timestamp: string) =>
  `/customer-proposals/${encodeURIComponent(id)}/${timestamp}`;

export const manageRulesPath = (trigger: string) =>
  `/customer-proposals/manage-rules/${encodeURIComponent(trigger)}`;

export const rosterCustomerPath = (
  participantId: number,
  participantName: string,
  id: string
) =>
  `/roster/${participantId}/${encodeURIComponent(
    participantName
  )}/${encodeURIComponent(id)}`;

export const authPath = (id: string, memberId: number) => {
  if (memberId === 88888) {
    return `/hibcc/${id}`;
  } else if (memberId === 77777) {
    return `/hrsa/${id}`;
  }
  return '';
};

export const tradingPartnerPath = (id: number) =>
  `/admin/trading-partners/${encodeURIComponent(id)}`;

export const getCotText = (memberId: AuthorityIDMapping) => {
  const textMapper: Record<AuthorityIDMapping, string> = {
    [AuthorityIDMapping.DEA]: 'Activity Code',
    [AuthorityIDMapping.HIBCC]: 'COT',
    [AuthorityIDMapping.HRSA]: 'Type',
    [AuthorityIDMapping.NPI]: '',
    [AuthorityIDMapping.AHD]: '',
  };
  return textMapper[memberId];
};

export const getTradingPartnerName = (type: string): ParticipantType => {
  const xpTypes: { [key: string]: ParticipantType } = {
    DIST: ParticipantType.distributor,
    GPO: ParticipantType.gpo,
    HS: ParticipantType.hs,
    MFR: ParticipantType.manufacturer,
    NETMAN: ParticipantType.netman,
  };
  return xpTypes[type];
};

export const getTradingPartnerNameById = (
  tpId: string | number,
  tradingPartners: TradingPartner[]
) => {
  const tradingPartnerId =
    typeof tpId === 'string' ? parseMediledgerId(tpId).memberId : tpId;
  if (tradingPartnerId === 0 || isRosterOON(tradingPartnerId)) {
    return tpId.toString();
  }
  return tradingPartners.find(({ id }) => id === tradingPartnerId)?.name;
};

// ml:customer:1111:12345 --> 12345
// 340b:number:A9156123 --> A9156123
export const pluckId = (id: string) => {
  if (!id || !id.trim()) return id;
  return last(id.split(':')) || id;
};

// dea:number:aa123456 --> DEA AA123456
export const toNamedIdentifier = (id: string) => {
  if (!id) return '';
  const arr = id.split(':');
  if (arr.length < 3) return `Internal ${id}`;
  return `${arr[0].toUpperCase()} ${arr[2].toUpperCase()}`;
};

// ml:customer:1111:12345 --> 1111
export const pluckParticipantId = (id: string) => {
  if (!id || !id.trim().length) return '';
  const shortenedId = id.split(':')[2];
  if (!shortenedId) return id;
  return shortenedId;
};

export function mapByField<V extends object, K extends keyof V>(
  collection: V[],
  field: K
): Map<V[K], V> {
  return mapByFunction(collection, (e) => e[field]);
}

export function mapByFunction<V extends any, K>(
  collection: V[],
  fn: (v: V) => K
): Map<K, V> {
  return collection.reduce(
    (map, entity) => map.set(fn(entity), entity),
    new Map<K, V>()
  );
}
