import { FC, useEffect } from 'react';
import { useApiService, useAuthService } from 'hooks';

const LogoutContainer: FC = () => {
  const auth = useAuthService();
  const api = useApiService();

  useEffect(() => {
    if (api) {
      auth?.logoutAction(api);
    }
  }, []);

  return null;
};

export default LogoutContainer;
