// Vendor
import React from 'react';

const IconChevronRight = () => (
  <svg width='13' height='12' viewBox='0 0 8 14'>
    <defs>
      <path
        id='IconChevronRightA'
        d='M15 8.25v8.125a.625.625 0 1 1-1.25 0V8.25H5.625a.625.625 0 1 1 0-1.25h8.743a.632.632 0 0 1 .632.632v.618z'
      />
    </defs>
    <use
      fill='#757575'
      fillRule='evenodd'
      opacity='.8'
      transform='scale(1 -1) rotate(45 28.435 -8.364)'
      xlinkHref='#IconChevronRightA'
    />
  </svg>
);

export default IconChevronRight;
