// Vendor
import Paper from '@material-ui/core/Paper';
import React, { Fragment, FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import classnames from 'classnames/bind';
import { cloneDeep } from 'lodash';

// Internal
import { EmptyResults } from 'components';
import { formatDate } from 'common/helpers';
import { ContractPricingRow } from 'query/contract';
import { IconChevron, IconLoading } from 'styles/images';
import { GREY_COLOR, WHITE_COLOR } from 'common/constants';
import { Tooltip } from 'components/Tooltips';
import TierTable from './TierTable';

// Styles
import styles from './Table.module.css';

const cx = classnames.bind(styles);

interface Props {
  pricings: ContractPricingRow[];
  pricingLoading: boolean;
}

interface State {
  openStatus: Map<string, boolean>;
}

const ProductPricingTable: FC<Props> = (props) => {
  const { pricingLoading, pricings } = props;

  const headerData = ['PRODUCT ID', 'DESCRIPTION', 'UPDATED AT', ''];

  const [state, setState] = useState<State>({
    openStatus: new Map<string, boolean>(),
  });

  const toggle = (productId: string, prevState: State) => () => {
    const statusMap = cloneDeep(prevState.openStatus);
    const existingStatus = statusMap.get(productId) ?? true;
    const updatedStatus = statusMap.set(productId, !existingStatus);
    setState({
      ...prevState,
      openStatus: updatedStatus,
    });
  };

  return (
    <Paper className={cx('paper')}>
      {pricingLoading && (
        <div data-testid='loading' className={cx('loading')}>
          <IconLoading />
        </div>
      )}
      {!pricingLoading && pricings.length > 0 && (
        <Table>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '55%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '5%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              {headerData.map((data) => (
                <TableCell className={cx('head')} key={`header-${data}`}>
                  {data}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pricings.map((row: ContractPricingRow, index: number) => {
              const { productDescription, productId, timestamp, pricing } = row;

              const lastUpdated = formatDate(timestamp);
              const isOpen = state.openStatus.get(productId) ?? true;

              // Note: need to manually specify the bgColor for a row and the pricing tier table to match
              // with UX design.
              const bgColor = index % 2 ? WHITE_COLOR : GREY_COLOR;
              // We need to avoid expand products without valid pricing tiers
              const hasValidPricingTiers = pricing?.length > 0;

              return (
                <Fragment key={`pricing-${productId}`}>
                  <TableRow style={{ background: bgColor }}>
                    <TableCell style={{ minWidth: 128 }}>
                      <Tooltip title={productId}>{productId}</Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={productDescription}>
                        {productDescription}
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={lastUpdated}>{lastUpdated}</Tooltip>
                    </TableCell>
                    {hasValidPricingTiers ? (
                      <TableCell
                        id={`${productId?.replace(/:/g, '-')}-arrow`}
                        className={cx('arrow')}
                        onClick={toggle(productId, state)}
                      >
                        <IconChevron pointUp={isOpen} />
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                  </TableRow>
                  {hasValidPricingTiers && isOpen && (
                    <TableRow style={{ background: bgColor }}>
                      <TableCell colSpan={4} style={{ paddingTop: 0 }}>
                        <TierTable pricings={pricing} bgColor={bgColor} />
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
      {!pricingLoading && !pricings.length && (
        <EmptyResults text='No products found' />
      )}
    </Paper>
  );
};

export default ProductPricingTable;
