// Vendor
import React, { FC } from 'react';

interface Props {
  color?: string;
}

const IconClassOfTrade: FC<Props> = ({ color = '#011F42' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='IconClassOfTradeA'
        d='M14.062 6.848a.474.474 0 0 1-.123.328.404.404 0 0 1-.314.137H7.5V1.188c0-.11.041-.21.123-.301A.404.404 0 0 1 7.937.75h.028a6.395 6.395 0 0 1 2.967.943 6.605 6.605 0 0 1 2.187 2.188c.556.902.87 1.891.943 2.967zM8.812 6h3.774a5.136 5.136 0 0 0-1.367-2.406 5.136 5.136 0 0 0-2.407-1.367V6zM6.187 8.625l4.266 4.266a.421.421 0 0 1 .123.341.389.389 0 0 1-.178.315 6.335 6.335 0 0 1-3.691 1.203h-.082a6.263 6.263 0 0 1-3.186-.861 6.874 6.874 0 0 1-2.406-2.31 6.158 6.158 0 0 1-.97-3.145 6.454 6.454 0 0 1 .67-3.145A6.578 6.578 0 0 1 2.77 2.883a6.336 6.336 0 0 1 2.925-1.176h.055c.128 0 .232.041.314.123a.426.426 0 0 1 .123.315v6.48zm.493 4.812A5.443 5.443 0 0 0 8.703 13L4.875 9.172V3.238a5.169 5.169 0 0 0-2.584 1.983c-.647.939-.952 1.991-.916 3.158.036.893.296 1.727.78 2.502a5.462 5.462 0 0 0 1.927 1.86c.802.464 1.65.696 2.543.696h.055zm7.82-4.812c.128 0 .237.05.328.15.091.1.128.215.11.342a6.49 6.49 0 0 1-.684 2.106 6.88 6.88 0 0 1-1.313 1.75.49.49 0 0 1-.314.109.35.35 0 0 1-.287-.137l-4.32-4.32h6.48z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(5 4)'>
      <mask id='IconClassOfTradeB' fill='#fff'>
        <use xlinkHref='#IconClassOfTradeA' />
      </mask>
      <use fill='#011F42' fillRule='nonzero' xlinkHref='#IconClassOfTradeA' />
      <g fill={color} mask='url(#IconClassOfTradeB)'>
        <path d='M-5-4h24v24H-5z' />
      </g>
    </g>
  </svg>
);

export default IconClassOfTrade;
