// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Pagination, Table } from 'components';
import { NUMBER_PER_PAGE } from 'common/constants';
import { CustomerViewRow } from 'query';
import { getColumns, getRows } from './helpers';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

interface Props {
  customers: CustomerViewRow[];
  loading: boolean;
}

const PharmaciesTable: FC<Props> = ({ customers, loading }) => {
  const [currentPage, updateCurrentPage] = useState(1);

  const paginatedCustomers = customers.slice(
    (currentPage - 1) * NUMBER_PER_PAGE,
    currentPage * NUMBER_PER_PAGE
  );

  const columns = getColumns();
  const rows = getRows(paginatedCustomers);

  return (
    <div className={cx('root')}>
      <Table
        columns={columns}
        isLoading={loading}
        emptyText='No pharmacies found'
        rows={rows}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={false}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={customers.length > 0}
        totalCount={customers.length}
      />
    </div>
  );
};

export default PharmaciesTable;
