// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { Field } from 'react-final-form';

// Internal
import { CustomerFormProps } from 'pages/Customers/Create/constants';
import { Button, TextField } from 'components';
import { maxLength } from 'common/validation';
import Section from 'components/Section';
import SearchDialog from './SearchDialog';

// Styles
import styles from './Relationships.module.css';

const cx = classnames.bind(styles);

interface Props {
  customError: string;
  setParentId: (parentId: string) => void;
  values: CustomerFormProps;
}

const Relationships: FC<Props> = (props) => {
  const { customError, setParentId, values } = props;
  const [showDialog, toggleDialog] = useState(false);

  return (
    <Section title='Relationships' data-testid='relationships'>
      {showDialog && (
        <SearchDialog setParentId={setParentId} toggleDialog={toggleDialog} />
      )}
      <div className={cx('key')}>Parent Customer ID</div>
      <div className={cx('row')}>
        <div className={cx('field')}>
          <Field
            // @ts-ignore
            component={TextField}
            customError={customError}
            handleOnBlur={() => setParentId(values.parentId || '')}
            name='parentId'
            placeholder='Enter parent customer ID'
            validate={maxLength(100)}
          />
        </div>
        <span className={cx('orTag')}>OR</span>
        <Button
          dataTestId='searchButton'
          label='Search'
          onClick={() => toggleDialog(true)}
          style={{
            marginRight: 8,
            minWidth: 152,
          }}
        />
      </div>
    </Section>
  );
};

export default Relationships;
