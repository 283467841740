// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { keyBy } from 'lodash';

// Internal
import {
  GET_CREDIT_NOTE_BY_ID,
  GET_TRADING_PARTNERS,
  GetCreditNote,
  QUERY_CLAIM_LINES,
  QUERY_CLAIM_LINES_COUNT,
  QueryClaimLines,
  QueryClaimLinesCount,
  QueryTradingPartners,
} from 'query';
import { IconLoading, IconSystemError } from 'styles/images';
import { useAppBarSetTitle, useTradingPartnerType } from 'hooks';
import Table, { TableColumn, TableRow } from 'components/Table';
import { Breadcrumb, Pagination, SearchBar } from 'components';
import BasicFilter from 'components/Filters/BasicFilter';
import { NUMBER_PER_PAGE } from 'common/constants';
import { chargebackPath, contractPath } from 'common/helpers';
import CreditMemoDetails from './components/CreditMemoDetails';
import { getFilterItems } from '../../Chargebacks/List/service';
import { buildWhereClause, ClaimLine, rawDataToRows } from './service';
import { getTradingPartnerName } from '../List/service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const Container: FC = () => {
  // Get additional information
  const { id } = useParams() as { id: string };

  const tpType = useTradingPartnerType();
  const isMFR = tpType === 'MFR';

  useAppBarSetTitle(`Credit Memo # ${id}`);

  // Table definitions
  const columns: TableColumn[] = [
    // Chargeback ID (links to chargeback detail page)
    // Data source: chargeback.id
    {
      headerName: 'CBK LINE ID',
      type: 'link',
      size: 'small',
      displayTextFn: (data: ClaimLine) => data.chargebackId,
      urlFn: (data: ClaimLine) =>
        chargebackPath(data.distId, data.chargebackId),
    },
    // Type of chargeback (original, resubmission, return, credit, rebill)
    // Data source: chargeback.operation
    {
      dataField: 'chargebackType',
      headerName: 'CBK TYPE',
      type: 'expanding',
      size: 'small',
    },
    // Paid amount of each claim (rejected claims will have a value of $0)
    // Data source: chargeback.submitted_amount
    {
      dataField: 'amount',
      headerName: 'AMOUNT',
      type: 'expanding',
      size: 'small',
    },
    // Contract ID of associated chargeback (links to contract page)
    // Data source: chargeback.contract_id
    {
      headerName: 'CONTRACT ID',
      type: 'link',
      size: 'small',
      displayTextFn: (data: ClaimLine) => data.contractId || '',
      urlFn: (data: ClaimLine) =>
        data.contractId ? contractPath(data.mfrId, data.contractId) : '',
    },
    // Pricing Date (date, no timestamp) provided by distributor
    // Data source: chargeback.sale.invoice_date
    {
      dataField: 'saleDate',
      headerName: 'SALE DATE',
      type: 'date',
      size: 'medium',
      format: 'short',
    },
    // Chargeback status (Processing, Pending, Approved, Rejected)
    // Data source: calculated from the last response object
    {
      dataField: 'status',
      headerName: 'STATUS',
      type: 'chip',
      colorField: 'statusChipColor',
      size: 'small',
    },
  ];

  // Table rows
  const [rows, updateRows] = useState([] as TableRow[]);
  // Trading partner name
  const [tradingPartnerName, updateTradingPartnerName] = useState<string>();
  // Search
  const [searchTerm, updateSearchTerm] = useState('');
  // Filters
  const [filters, updateFilters] = useState([] as string[]);
  // Order
  const [order] = useState({ revised_at: 'desc' });
  // Pagination
  const [currentPage, updateCurrentPage] = useState(1);
  // Where condition
  const [where, updateWhere] = useState(
    buildWhereClause(filters, searchTerm, id)
  );

  // Fetch the data
  const {
    data: creditNoteData,
    loading: creditNoteLoading,
    error: creditNoteError,
  } = useQuery<GetCreditNote>(GET_CREDIT_NOTE_BY_ID, {
    variables: {
      id,
    },
  });

  const {
    data: claimLinesData,
    loading: claimLinesLoading,
    error: claimLinesError,
  } = useQuery<QueryClaimLines>(QUERY_CLAIM_LINES, {
    variables: {
      where,
      order,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      limit: NUMBER_PER_PAGE,
    },
  });

  const {
    data: claimLinesCountData,
    loading: claimLinesCountLoading,
    error: claimLinesCountError,
  } = useQuery<QueryClaimLinesCount>(QUERY_CLAIM_LINES_COUNT, {
    variables: {
      where,
    },
  });

  const claimLines = claimLinesData?.claimLines || [];
  const claimLinesCount = claimLinesCountData?.claimLinesCount?.aggregate.count;

  // Fetch trading partners
  const {
    data: tradingPartnersData,
    loading: tradingPartnersLoading,
    error: tradingPartnersError,
  } = useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
    variables: { id: {} },
  });

  // Format the raw data
  useEffect(() => {
    if (!claimLinesLoading && creditNoteData) {
      updateRows(rawDataToRows(creditNoteData.creditNote, claimLinesData));
    }
  }, [claimLinesData, claimLinesLoading]);

  // Build the search query
  useEffect(() => {
    updateCurrentPage(1);
    updateWhere(buildWhereClause(filters, searchTerm, id));
  }, [filters, searchTerm]);

  // Get trading partner name
  useEffect(() => {
    if (creditNoteData && creditNoteData.creditNote) {
      const tradingPartners = keyBy(
        tradingPartnersData?.tradingPartners || [],
        (p) => p.id
      );
      updateTradingPartnerName(
        getTradingPartnerName(creditNoteData.creditNote, tradingPartners, isMFR)
      );
    }
  }, [tradingPartnersData, creditNoteData]);

  const hasClaimLines = claimLines.length > 0;

  const isFiltered = searchTerm !== '' || filters.length > 0;

  const loading =
    creditNoteLoading ||
    claimLinesLoading ||
    claimLinesCountLoading ||
    tradingPartnersLoading;
  const error =
    creditNoteError ||
    claimLinesError ||
    claimLinesCountError ||
    tradingPartnersError;

  const disableSearchFilter = !loading && !hasClaimLines && !isFiltered;

  return error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : loading ? (
    <div className={cx('loading')}>
      <IconLoading />
    </div>
  ) : (
    <div className={cx('root')}>
      <Breadcrumb
        crumbs={['Credit Transactions', `Credit Memo # ${id}`]}
        links={['/credit-memos/']}
      />

      <CreditMemoDetails
        creditMemo={creditNoteData?.creditNote!}
        tradingPartnerName={tradingPartnerName}
        isMFR={isMFR}
      />

      <section className={cx('row', 'marginBottom')}>
        <SearchBar
          disabled={disableSearchFilter}
          onSubmit={(text) => updateSearchTerm(text)}
          placeholder='Search by Chargeback Line ID or Contract ID'
        />
        <BasicFilter
          items={getFilterItems()}
          applyFilters={(selected) => updateFilters([selected])}
        />
      </section>

      <Table
        columns={columns}
        rows={rows}
        emptyText='No claim lines found'
        isLoading={loading}
      />

      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={claimLinesCountLoading}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={rows.length > 0}
        totalCount={claimLinesCount ?? -1}
      />
    </div>
  );
};

export default Container;
