// Vendor
import Paper from '@material-ui/core/Paper';
import React, { FC, ReactElement, useState } from 'react';
import classnames from 'classnames/bind';

// Internal
import { NotFoundSection } from 'components';
import { IconChevron } from 'styles/images';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface KeyValueProps {
  key: string;
  linkUrl?: string;
  value: string;
  valueIcon?: ReactElement;
}

export interface Props {
  children: any;
  dataTestid: string;
  disableCollapse?: boolean;
  emptySection?: boolean;
  header: string;
  headerContent?: any;
  sourceName?: string;
}

const ViewSection: FC<Props> = (props) => {
  const {
    children,
    dataTestid,
    disableCollapse = false,
    emptySection = false,
    header,
    headerContent,
    sourceName = '',
  } = props;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const margin = sourceName ? '8px 0' : '0';

  return (
    <Paper className={cx('root')}>
      <div className={cx('column', 'content')} data-testid={dataTestid}>
        <div className={cx('row', 'source')}>
          <header className={cx('header')}>{header}</header>
          <div>
            <div className={cx('column')}>
              {sourceName && (
                <span>{`(Source: ${sourceName.toUpperCase()})`}</span>
              )}
              {/* Collapsed icon since these sections are used on details pages and
              they can get pretty lengthy */}
              {!disableCollapse && (
                <div
                  className={cx('arrowContainer')}
                  data-testid='arrow'
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  style={{ margin }}
                >
                  <IconChevron pointUp={!isCollapsed} />
                </div>
              )}
            </div>
            {!isCollapsed && headerContent}
          </div>
        </div>
        {
          // We only display the section content if the section is not collapsed
          !isCollapsed &&
            (emptySection ? (
              <NotFoundSection text={`No ${header} found`} />
            ) : (
              children
            ))
        }
      </div>
    </Paper>
  );
};

export default ViewSection;
