// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';

// Internal
import { ERROR_STATE_MSG } from 'common/constants';
import { Button, NotFoundSection } from 'components';
import { getDocumentAttribute, formatValue } from 'common/helpers';
import { useDocumentTypes, useTradingPartnerType } from 'hooks';
import { Document } from 'types/generated/Document';
import { Tooltip } from 'components/Tooltips';
import { CustomerWithDocuments } from '../types';

// Styles
import styles from './DocumentDetails.module.css';
const cx = classnames.bind(styles);

interface Props {
  changeSection: (s: string) => void;
  customer: CustomerWithDocuments;
  isHistoryView: boolean;
  selectedDocument: Document;
  setDocument: (doc: Document) => void;
  toggleDialog: (val: boolean) => void;
  updateDeletePayload: (payload: {
    documentType: string;
    manufacturer: string;
  }) => void;
}

const DocumentDetails: FC<Props> = ({
  changeSection,
  customer,
  isHistoryView,
  selectedDocument,
  setDocument,
  toggleDialog,
  updateDeletePayload,
}) => {
  const documentTypes = useDocumentTypes();
  const tpType = useTradingPartnerType();
  const isGPO = tpType === 'GPO';
  const { errors = [] } = customer;

  const isErrorState = !!errors?.find((e) => e.severity === 'ERROR');
  const document = customer.documents?.find(
    (doc) => doc.id === selectedDocument.id
  );
  const metadata: any = document?.metadata ?? {};
  const type = document?.type || '';

  return (
    <>
      <section className={cx('buttonContainer')}>
        <Button
          color='secondary'
          dataTestId='backButton'
          label='Back'
          onClick={() => changeSection('documentList')}
          style={{ width: 120 }}
          variant='outlined'
        />
        {isGPO && (!isHistoryView || !isEmpty(document)) && (
          <>
            <Tooltip
              disabled={!isErrorState}
              disableHoverListener={false}
              placement='bottom'
              title={<p>{ERROR_STATE_MSG}</p>}
            >
              <Button
                color='secondary'
                dataTestId='removeButton'
                disabled={isErrorState}
                label='Remove Document'
                onClick={() => {
                  updateDeletePayload({
                    documentType: type,
                    // not sure what to do with the following:
                    // @ts-ignore
                    manufacturer: metadata.manufacturer,
                  });
                  toggleDialog(true);
                }}
                style={{ marginLeft: 8, width: 152 }}
                variant='outlined'
              />
            </Tooltip>
            <Tooltip
              disabled={!isErrorState}
              disableHoverListener={false}
              placement='bottom'
              title={<p>{ERROR_STATE_MSG}</p>}
            >
              <Button
                dataTestId='editButton'
                disabled={isErrorState}
                label='Edit Document'
                onClick={() => {
                  setDocument(selectedDocument);
                  changeSection('editDocument');
                }}
                style={{ marginLeft: 8, width: 152 }}
              />
            </Tooltip>
          </>
        )}
      </section>
      <main className={cx('details')}>
        {!isEmpty(document) ? (
          <>
            <div className={cx('field')}>
              <span className={cx('title')}>Type of Document</span>
              {type}
            </div>
            {Object.entries(metadata).map(([key, value]) => {
              const documentAttribute = getDocumentAttribute(
                documentTypes,
                key
              );
              const name = documentAttribute?.name;

              return (
                <div key={key} className={cx('field')}>
                  <span className={cx('title')}>{name || key}</span>
                  {formatValue({ value })}
                </div>
              );
            })}
          </>
        ) : (
          <div className={cx('notFound')}>
            <NotFoundSection text='Selected document did not exist on this date.' />
          </div>
        )}
      </main>
    </>
  );
};

export default DocumentDetails;
