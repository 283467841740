// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Internal
import { Pagination, SearchBar } from 'components';
import CustomersListLite from 'pages/Customers/List/ListLite/container';
import { NUMBER_PER_PAGE } from 'common/constants';
import { CustomerViewRow } from 'query';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  countLoading: boolean;
  currentPage: number;
  customerId: string;
  customers: CustomerViewRow[];
  loading: boolean;
  onChangePage: (currentPage: number) => void;
  onSubmit: (text: string) => void;
  resetInput: boolean;
  searchText?: string;
  setToCustomer: (toCustomer: {
    id: string;
    memberId: number;
    names: string[];
  }) => void;
  totalCount: number;
}

const RosterCustomer: FC<Props> = (props) => {
  const {
    countLoading,
    currentPage,
    customerId,
    customers,
    loading,
    onChangePage,
    onSubmit,
    resetInput,
    searchText,
    setToCustomer,
    totalCount,
  } = props;

  const hasCustomers = customers.length > 0;
  const isFiltered = searchText !== '';
  const disableSearchFilter = !loading && !hasCustomers && !isFiltered;

  return (
    <div className={cx('root')} data-testid='dialog'>
      <SearchBar
        className={cx('search')}
        onSubmit={(text) => {
          onSubmit(text);
        }}
        placeholder='Search by ID, identifier, name or address'
        disabled={disableSearchFilter}
      />
      <CustomersListLite
        activeCustomer={{ id: customerId }}
        customers={customers}
        handleRowClick={(data: any) => {
          setToCustomer({
            id: data.id,
            memberId: data.memberId,
            names: data.names,
          });
        }}
        loading={loading}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={countLoading}
        onChangePage={({ currentPage }) => {
          onChangePage(currentPage);
        }}
        resetInput={resetInput}
        isDisplayed={hasCustomers}
        totalCount={totalCount}
      />
    </div>
  );
};

export default RosterCustomer;
