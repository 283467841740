// Vendor
import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Paper } from '@material-ui/core';
import { useQuery } from '@apollo/client';

// Internal
import SearchBar from 'components/SearchBar';
import { Pagination } from 'components';
import {
  useAuthDatabases,
  useIsFirstRender,
  useTradingPartnerType,
} from 'hooks';
import {
  GET_CONTRACT_CUSTOMERS,
  GET_CONTRACT_CUSTOMERS_LATEST,
  GET_CONTRACT_CUSTOMERS_TOTAL_COUNT,
  GET_CONTRACT_CUSTOMERS_TOTAL_COUNT_LATEST,
  QueryContractCustomersCountResult,
  QueryContractCustomersResult,
} from 'query';
import { NUMBER_PER_PAGE, SHORT_POLL_INTERVAL } from 'common/constants';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { FiltersSerializationService } from 'common/helpers/filtersSerializationService';
import {
  CurrentPageParamConfig,
  FiltersActiveParamConfig,
  FiltersParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import { Filters, filtersToQuery } from 'pages/Contracts/service';
import ContractCustomerFilter from './ContractCustomerFilter';
import ContractCustomersTable from './Table';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

interface Props {
  contractId: string;
  memberId: string;
  latestTimestampInHour: string;
  isHistoryView: boolean;
  updateCustomersWhere: (where: any) => void;
}

const ContractCustomerDetails: FC<Props> = (props) => {
  const {
    contractId,
    memberId,
    latestTimestampInHour,
    isHistoryView,
    updateCustomersWhere,
  } = props;

  // Search
  const { currentSearch: searchMap } = usePersistedSearchParams(
    {
      module: `contracts/(.+)/${encodeURI(contractId)}`,
      params: [
        SearchParamConfig,
        CurrentPageParamConfig,
        FiltersParamConfig,
        FiltersActiveParamConfig,
      ],
    },
    SessionStorage
  );
  const [searchText, updateSearchText] = searchMap[SearchParamConfig.key];
  const [currentPage, updateCurrentPage] =
    searchMap[CurrentPageParamConfig.key];
  const [currFilters, updateFilters] = searchMap[FiltersParamConfig.key];
  const [fActive, updateFiltersActive] =
    searchMap[FiltersActiveParamConfig.key];

  const filters: Filters | undefined = useMemo(
    () => currFilters && FiltersSerializationService.parse(currFilters),
    [currFilters]
  ) as Filters;
  const filtersActive = !!filters && fActive;

  const isLatestContractCustomers = !isHistoryView;
  const [order] = useState({ timestamp: 'desc' });
  const [where, updateWhere] = useState(
    filtersToQuery(
      filters,
      filtersActive,
      searchText,
      contractId,
      +memberId,
      isLatestContractCustomers
    )
  );

  const tpType = useTradingPartnerType();
  const isMFR = tpType === 'MFR';

  const acceptableAuthData = useAuthDatabases();

  const contractCustomersQuery = isLatestContractCustomers
    ? GET_CONTRACT_CUSTOMERS_LATEST
    : GET_CONTRACT_CUSTOMERS;

  const { data: customerData, loading: customerLoading } =
    useQuery<QueryContractCustomersResult>(contractCustomersQuery, {
      variables: {
        contractId,
        memberId,
        timestampHour: latestTimestampInHour,
        limit: NUMBER_PER_PAGE,
        offset: (currentPage - 1) * NUMBER_PER_PAGE,
        where,
        order,
      },
      pollInterval: SHORT_POLL_INTERVAL,
      skip: !latestTimestampInHour,
    });

  const contractCustomersCountQuery = isLatestContractCustomers
    ? GET_CONTRACT_CUSTOMERS_TOTAL_COUNT_LATEST
    : GET_CONTRACT_CUSTOMERS_TOTAL_COUNT;

  const { data: countData, loading: countLoading } =
    useQuery<QueryContractCustomersCountResult>(contractCustomersCountQuery, {
      variables: {
        contractId,
        memberId,
        timestampHour: latestTimestampInHour,
        where,
      },
      pollInterval: SHORT_POLL_INTERVAL,
      skip: !latestTimestampInHour,
    });

  const firstRender = useIsFirstRender();
  useEffect(() => {
    // First render help us to identify when we navigate through URL
    if (
      (firstRender && currentPage === 1) ||
      (!firstRender && currentPage > 1)
    ) {
      updateCurrentPage(1);
    }
    updateWhere(
      filtersToQuery(
        filters,
        filtersActive,
        searchText,
        contractId,
        +memberId,
        isLatestContractCustomers
      )
    );
  }, [filters, searchText, latestTimestampInHour]);

  useEffect(() => {
    updateCustomersWhere(where);
  }, [where]);

  const customers = customerData?.dash_get_contract_customers || [];

  const hasCustomers = customers.length > 0;
  const totalCount =
    countData?.dash_get_contract_customers_aggregate?.aggregate.count || 0;
  const isFiltered = searchText !== '' || filtersActive;
  const disableSearchFilter = !customerLoading && !hasCustomers && !isFiltered;
  const placeholderText = `Search by ID, name${
    acceptableAuthData.length > 0 ? ', identifier' : ''
  } or address`;

  return (
    <>
      <section className={cx('toolbar')} data-testid='contractCustomers'>
        <SearchBar
          text={searchText || ''}
          onSubmit={(text) => updateSearchText(text)}
          placeholder={placeholderText}
          disabled={disableSearchFilter}
        />
        <ContractCustomerFilter
          applyFilters={(filters, filtersActive) => {
            updateFilters(filters);
            updateFiltersActive(filtersActive);
          }}
          disableFilters={disableSearchFilter}
          cFilters={filters}
          cFiltersActive={filtersActive}
        />
      </section>
      <Paper
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <ContractCustomersTable
          contractId={contractId}
          customers={customers}
          customerLoading={customerLoading}
          isMFR={isMFR}
        />
      </Paper>
      <div style={{ display: 'flex' }}>
        <Pagination
          className={cx('pagination')}
          currentPage={currentPage}
          loading={countLoading || customerLoading}
          numberPerPage={NUMBER_PER_PAGE}
          onChangePage={({ currentPage }) => updateCurrentPage(currentPage)}
          isDisplayed={hasCustomers}
          totalCount={totalCount as number}
        />
      </div>
    </>
  );
};

export default ContractCustomerDetails;
