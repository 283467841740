import { RawContractHeader } from 'flatfile-import/domain/contract-header/contract-header-roster';
import { ApiService } from '../../../actions';
import { HttpPublisher } from '../http-publisher';

export class HttpContractHeaderPublisher extends HttpPublisher<RawContractHeader> {
  apiService: ApiService;

  path: string;

  constructor(apiService: ApiService) {
    const path = `/v2/contract/header/upsert`;
    super(apiService, path);
    this.path = path;
    this.apiService = apiService;
  }
}
