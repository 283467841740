// Vendor
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { keyBy } from 'lodash';

// Internal
import BasicFilter from 'components/Filters/BasicFilter';
import { TableRow } from 'components/Table';
import { NUMBER_PER_PAGE } from 'common/constants';
import { Pagination, SearchBar, Table } from 'components';
import {
  useAppBarSetTitle,
  useTradingPartnerConfig,
  useTradingPartnerType,
} from 'hooks';
import {
  GET_TRADING_PARTNERS,
  QUERY_CHARGEBACKS,
  QUERY_CHARGEBACKS_COUNT,
  QueryChargebacks,
  QueryChargebacksCountResult,
  QueryTradingPartners,
} from 'query';
import { IconSystemError } from 'styles/images';
import {
  buildWhereClause,
  getColumns,
  getFilterItems,
  rawDataToRows,
} from './service';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const ChargebacksContainer: FC = () => {
  useAppBarSetTitle('Chargebacks');

  // Get additional information
  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const tpType = useTradingPartnerType();
  const isMFR = tpType === 'MFR';

  const { data: tradingPartnersData, loading: tpLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });

  // Table definitions
  const columns = getColumns(isMFR, cx('non-ellipsis'));
  // Table rows
  const [rows, updateRows] = useState([] as TableRow[]);
  // Search
  const [searchTerm, updateSearchTerm] = useState('');
  // Filters
  const [filters, updateFilters] = useState([] as string[]);
  // Order
  const [order] = useState({ timestamp: 'desc' });
  // Pagination
  const [currentPage, updateCurrentPage] = useState(1);
  // Where condition
  const [where, updateWhere] = useState(buildWhereClause(filters, searchTerm));

  // Fetch the data
  const {
    data: chargebacksData,
    loading,
    error,
  } = useQuery<QueryChargebacks>(QUERY_CHARGEBACKS, {
    variables: {
      where,
      order,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      limit: NUMBER_PER_PAGE,
    },
    skip: !memberId,
  });
  const chargebacks = chargebacksData?.chargebacks || [];

  // Fetch the count
  const { data: countData, loading: countLoading } =
    useQuery<QueryChargebacksCountResult>(QUERY_CHARGEBACKS_COUNT, {
      variables: {
        where,
      },
      skip: !memberId,
    });

  // Format the raw data
  useEffect(() => {
    if (!loading && !tpLoading) {
      const tradingPartners = keyBy(
        tradingPartnersData?.tradingPartners || [],
        (p) => p.id
      );
      updateRows(rawDataToRows(tradingPartners, isMFR, chargebacksData));
    }
  }, [chargebacksData, tradingPartnersData, loading, tpLoading]);

  // Build the search query
  useEffect(() => {
    updateCurrentPage(1);
    updateWhere(buildWhereClause(filters, searchTerm));
  }, [filters, searchTerm]);

  const hasChargebacks = chargebacks.length > 0;

  const isFiltered = searchTerm !== '' || filters.length > 0;
  const disableSearchFilter = !loading && !hasChargebacks && !isFiltered;

  // Table with layout
  return !memberId || error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <div className={cx('root')}>
      <section className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            disabled={disableSearchFilter}
            onSubmit={(text) => updateSearchTerm(text)}
            placeholder='Search by ID, Customer ID or Contract ID'
          />
          <BasicFilter
            items={getFilterItems()}
            applyFilters={(selected) => updateFilters([selected])}
          />
        </div>
      </section>
      <Table
        columns={columns}
        rows={rows}
        emptyText='No chargebacks found'
        isLoading={loading}
      />
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        numberPerPage={NUMBER_PER_PAGE}
        loading={countLoading}
        onChangePage={({ currentPage }) => {
          updateCurrentPage(currentPage);
        }}
        isDisplayed={hasChargebacks}
        totalCount={countData?.chargebacksCount?.aggregate.count ?? -1}
      />
    </div>
  );
};

export default ChargebacksContainer;
