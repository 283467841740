// Vendor
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { identity } from 'fp-ts/function';
import { get } from 'lodash';
import { delay } from 'fp-ts/Task';

// Internal
import { useApiService, useAppBarSetTitle, useTradingPartnerType } from 'hooks';
import {
  GET_CONTRACTS,
  GET_CONTRACTS_TOTAL_COUNT,
  GET_TRADING_PARTNERS,
  GetContractCountResult,
  GetContractsResult,
  QueryTradingPartners,
} from 'query';
import { IconSystemError } from 'styles/images';
import { NUMBER_PER_PAGE } from 'common/constants';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import {
  CurrentPageParamConfig,
  FiltersActiveParamConfig,
  FilterStatusParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import contractHeaderValidationService from 'flatfile-import/application/row-processor/contract-header/contract-header-validation-service';
import contractHeaderAdapter from 'flatfile-import/domain/contract-header/contract-header-adapter';
import {
  ContractHeaderRoster,
  ContractHeaderRosterInputData,
  RawContractHeader,
} from 'flatfile-import/domain/contract-header/contract-header-roster';
import { ContractHeaderAggregationService } from 'flatfile-import/application/aggregator/contract-header/contract-header-aggregation-service';
import {
  concatContractHeaders,
  contractHeaderSemigroup,
} from 'flatfile-import/domain/contract-header/contract-header-semigroup';
import { getWhereClause } from './service';
import Contracts from './components/Contracts';
import { FLATFILE_FLOW } from '../../../flatfile-import/constants';
import RosterImport from '../../OONRosterImport/RosterImport/container';
import { PersistedAggregator } from '../../../flatfile-import/infrastructure/persisted-aggregator';
import { db } from '../../../flatfile-import/infrastructure/contract-header/contract-header-db';
import { BatchPublisherService } from '../../../flatfile-import/application/publisher/batch-publisher-service';
import { HttpContractHeaderPublisher } from '../../../flatfile-import/infrastructure/contract-header/http-contract-header-publisher';
import { IdentityPublishAdapter } from '../../../flatfile-import/application/publisher/publish-adapter';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const ContractsContainer = () => {
  useAppBarSetTitle('Contracts');

  const apiService = useApiService();

  if (!apiService) {
    throw new Error('apiService instance is required but not found.');
  }

  const tpType = useTradingPartnerType();

  // SEARCH
  const { currentSearch } = usePersistedSearchParams(
    {
      module: 'contracts',
      params: [
        SearchParamConfig,
        CurrentPageParamConfig,
        FilterStatusParamConfig,
        FiltersActiveParamConfig,
      ],
    },
    SessionStorage
  );
  const [searchText, updateSearchText] = currentSearch[SearchParamConfig.key];

  // PAGINATION
  const [currentPage, paginationToggle] =
    currentSearch[CurrentPageParamConfig.key];

  // FILTERS
  const [filterStatus, updateFilterStatus] =
    currentSearch[FilterStatusParamConfig.key];

  const [filterActive, updateFilterActive] =
    currentSearch[FiltersActiveParamConfig.key];

  // WHERE
  const whereClause = getWhereClause(filterStatus, searchText);

  const [contractHeaderImportOpen, setContractHeaderImportOpen] =
    useState(false);

  const fetchContractVariables = {
    limit: NUMBER_PER_PAGE,
    offset: (currentPage - 1) * NUMBER_PER_PAGE,
    where: whereClause,
  };

  const {
    data = {},
    error,
    loading,
    refetch,
  } = useQuery<GetContractsResult>(GET_CONTRACTS, {
    variables: fetchContractVariables,
  });

  const {
    data: countData = {},
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<GetContractCountResult>(GET_CONTRACTS_TOTAL_COUNT, {
    variables: {
      where: whereClause,
    },
  });

  const { data: tp = {}, loading: tradingPartnerLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });

  const tradingPartners = get(tp, 'tradingPartners', []) || [];

  const contracts = data?.dash_contract_headers || [];
  const totalCount =
    countData?.dash_contract_headers_aggregate?.aggregate.count || 0;

  const refetchData = useCallback(
    (variables?: any) =>
      Promise.all([refetch(variables), refetchCount()]).then(() => {}),
    [refetch, refetchCount]
  );

  const onFinishContractHeadersImport = useCallback(() => {
    const withTimeout = delay(2000);
    const refetch = withTimeout(() => refetchData(fetchContractVariables));
    refetch().then(() => {});
    setContractHeaderImportOpen(false);
  }, []);

  const aggregationService = useMemo(
    () =>
      new ContractHeaderAggregationService(
        new PersistedAggregator(
          db,
          contractHeaderSemigroup,
          concatContractHeaders
        )
      ),
    []
  );

  const publisherService: BatchPublisherService<
    RawContractHeader,
    RawContractHeader
  > = useMemo(() => {
    const publisher = new HttpContractHeaderPublisher(apiService);
    return new BatchPublisherService(publisher);
  }, [apiService]);

  // Refetch the contracts on page load
  useEffect(() => {
    refetchData().then(identity);
  }, []);

  return error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <>
      <RosterImport<
        ContractHeaderRoster,
        ContractHeaderRosterInputData,
        RawContractHeader,
        RawContractHeader
      >
        entityLabel='Contract Header'
        rosterValidator={contractHeaderValidationService}
        publisher={publisherService}
        open={contractHeaderImportOpen}
        containerId='contract-header'
        embedNode={FLATFILE_FLOW.ContractHeader}
        rosterAdapter={contractHeaderAdapter}
        formValues={{
          timestamp: new Date().toISOString(),
        }}
        onFinish={onFinishContractHeadersImport}
        onCancel={() => setContractHeaderImportOpen(false)}
        aggregator={aggregationService}
        publishAdapter={new IdentityPublishAdapter<RawContractHeader>()}
      />
      <Contracts
        isUploadButtonVisible
        uploadContractHeader={() => setContractHeaderImportOpen(true)}
        contracts={contracts}
        tradingPartners={tradingPartners}
        countLoading={countLoading || tradingPartnerLoading}
        currentPage={currentPage}
        statusFilter={filterStatus}
        isFilterActive={filterActive}
        loading={loading}
        paginationToggle={paginationToggle}
        searchText={searchText}
        totalCount={totalCount}
        updateFilterStatus={(newStatus, active) => {
          updateFilterStatus(newStatus);
          paginationToggle(1);
          updateFilterActive(active);
        }}
        updateSearchText={(text: string) => {
          updateSearchText(text);
          paginationToggle(1);
        }}
        tpType={tpType}
      />
    </>
  );
};

export default ContractsContainer;
