import jwt from 'jsonwebtoken';
import { AuthConfig } from 'common/config';
import { Dispatch } from 'redux';
import { CoronaAction, SIGNED_IN, SIGNED_OUT } from 'actions/types';
import { ApiService } from 'actions/api';
import {
  getSessionCookieName,
  readCookie,
  readSession,
  writeCookie,
  writeSession,
} from 'actions/common/utils';
import { AuthDriver } from './driver';
import { SamlDriver } from './saml';
import { Oauth2Driver } from './oauth2';
import { AbstractService } from '../core';

const EXP_TOLERANCE_MS = 300000; // sessions expire 5 minutes before exp.
const CLOCK_INTERVAL_MS = 60000; // clock syncs every 1 minute

const defaultConfig: AuthConfig = {
  mode: 'oauth2',
  anonymous: false,
  'secure-cookies': true,
  'exp-tolerance-ms': EXP_TOLERANCE_MS,
  'clock-interval-ms': CLOCK_INTERVAL_MS,
};

export class AuthService extends AbstractService {
  config: AuthConfig;

  private readonly _ready: boolean;

  private readonly _clock: number;

  secureCookies: boolean;

  private _driver: AuthDriver;

  private readonly _csrf: any;

  private readonly _error: any;

  private readonly _session: any;

  returnRoute: any;

  constructor(cfg: AuthConfig, path: string[]) {
    super(path);
    this.config = { ...defaultConfig, ...cfg };
    this._csrf = null;
    this._error = null;
    this._session = null;
    this._ready = false;
    this._clock = new Date().getTime();

    // requires HTTPS to access cookies if true
    // off in dev only
    this.secureCookies = this.config['secure-cookies'] !== false;

    if (this.config.mode === 'saml') {
      // eslint-disable-next-line no-console
      console.log('Loading SAML Driver');
      this._driver = new SamlDriver();
    } else {
      // eslint-disable-next-line no-console
      console.log('Loading Auth0 Driver');
      this._driver = new Oauth2Driver();
    }
  }

  initializeAction(dispatch: Dispatch<CoronaAction>) {
    this._startClock(dispatch);
    this._requireCSRFCookie(dispatch)
      .then(() => readSession())
      .then((session) => {
        // TODO: renew session
        this._authenticatedStateAction(dispatch, session, null, false);
      })
      .catch((error) => {
        this.update(dispatch, {
          _error: error,
        });
      })
      .finally(() => {
        this.update(dispatch, {
          _ready: true,
        });
      });
  }

  logoutAction(apiService: ApiService) {
    this._driver
      .ssoLogoutStart(
        apiService,
        this._autoRedirectURI(),
        getSessionCookieName()
      )
      .then();
  }

  ssoLoginCompleteAction(
    dispatch: Dispatch<CoronaAction>,
    apiService: ApiService
  ) {
    const data = {
      _redirectPending: true,
    };
    this.update(dispatch, data);
    return this._driver
      .ssoLoginComplete(apiService, this._autoRedirectURI(), this._csrf)
      .finally(() => this._clearUrlState())
      .then((session) =>
        this._authenticatedStateAction(dispatch, session, null, true)
      )
      .catch((error) =>
        this._authenticatedStateAction(dispatch, null, error, true)
      )
      .finally(() => {
        this.update(dispatch, {
          _redirectPending: false,
        });
      });
  }

  ssoLoginErrorAction(
    dispatch: Dispatch<CoronaAction>,
    errorCode: string,
    errorDescription: string
  ) {
    this._clearUrlState();
    this.update(dispatch, {
      _error: new Error(errorDescription || errorCode),
    });
  }

  ssoLoginAction(dispatch: Dispatch<CoronaAction>, apiService: ApiService) {
    this._driver
      .ssoLoginStart(apiService, this._autoRedirectURI(), this._csrf)
      .then();
  }

  _clearUrlState() {
    // remove ?code=xstate=y parameters from URL
    window.history.replaceState(
      {},
      window.document.title,
      window.location.pathname
    );
  }

  _authenticatedStateAction(
    dispatch: Dispatch<CoronaAction>,
    session: any,
    error: any,
    persist: boolean
  ) {
    const data = error
      ? { _session: null, _error: error }
      : { _session: session, _error: null };
    if (persist) {
      writeSession(session, this.secureCookies);
    }
    this.update(dispatch, data);
    const token = data._session ? data._session.token : null;
    if (token) {
      dispatch({ type: SIGNED_IN, payload: { token } });
    } else {
      dispatch({ type: SIGNED_OUT });
    }
    return session;
  }

  isSessionExpired(): boolean {
    if (this._session && this._session.token) {
      const data = jwt.decode(this._session.token);
      if (data && typeof data === 'object' && data.exp) {
        const remainingMs = data.exp * 1000 - this._clock;
        return (
          remainingMs < (this.config['exp-tolerance-ms'] || EXP_TOLERANCE_MS)
        );
      }
    }
    return false;
  }

  isReady() {
    return this._ready;
  }

  isAuthenticated() {
    if (this._ready) {
      return (
        this._session != null &&
        this._session.user != null &&
        !this.isSessionExpired()
      );
    }
    return false;
  }

  isPasswordEnabled() {
    return false;
  }

  isSsoEnabled() {
    return true;
  }

  isLoginEnabled() {
    return this.isSsoEnabled() || this.isPasswordEnabled();
  }

  authorizationError() {
    return this._error;
  }

  user() {
    return this._session ? this._session.user : null;
  }

  token() {
    return this._session ? this._session.token : null;
  }

  _autoRedirectURI() {
    const loc = window.location;
    // hash URLs not supported in most OAuth services (including Google, Okta)
    let uri = loc.origin; // + '/#/login';
    if (this.returnRoute) {
      uri += `?return=${encodeURIComponent(loc.hash)}`;
    }
    return uri;
  }

  async _requireCSRFCookie(dispatch: Dispatch<CoronaAction>) {
    let csrf = readCookie('csrf');
    if (!csrf) {
      csrf = Math.random().toString();
      writeCookie('csrf', csrf, this.secureCookies);
    }
    this.update(dispatch, { _csrf: csrf });
    return csrf;
  }

  _startClock(dispatch: Dispatch<CoronaAction>) {
    window.setInterval(() => {
      this.update(dispatch, { _clock: new Date().getTime() });
    }, this.config['clock-interval-ms'] || CLOCK_INTERVAL_MS);
  }
}
