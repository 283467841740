// Vendor
import React from 'react';

const IconCorrectPay = () => (
  <svg width='17' height='16' viewBox='0 0 17 16'>
    <path
      fill='#00BC96'
      d='M8.467 15.785c4.281 0 7.75-3.469 7.75-7.75 0-4.281-3.469-7.75-7.75-7.75-4.281 0-7.75 3.469-7.75 7.75 0 4.281 3.469 7.75 7.75 7.75zm.25-2.75h-.5c-.275 0-.5-.225-.5-.5v-.506c-.516-.019-1.019-.181-1.45-.472-.272-.184-.312-.566-.072-.787l.375-.354c.169-.159.416-.168.616-.05.19.113.403.17.622.17h1.406c.353 0 .64-.329.64-.732 0-.331-.196-.622-.474-.71L7.123 8.41c-.906-.275-1.537-1.156-1.537-2.144 0-1.228.956-2.228 2.131-2.23v-.5c0-.276.225-.5.5-.5h.5c.275 0 .5.224.5.5v.505c.516.02 1.019.182 1.45.472.272.185.313.566.072.788l-.375.353c-.169.16-.416.169-.616.05-.19-.113-.403-.169-.622-.169H7.72c-.353 0-.64.328-.64.731 0 .332.196.622.475.71l2.25.684c.906.275 1.537 1.156 1.537 2.144.006 1.228-.95 2.225-2.125 2.231v.5c0 .275-.225.5-.5.5z'
    />
  </svg>
);

export default IconCorrectPay;
