import gql from 'graphql-tag';
import { ProductList } from 'types/generated/ProductList';

export interface QueryProductListsResult {
  productLists?: ProductList[];
}

export interface QueryProductListResult {
  productList: ProductList;
}

export interface QueryProductListsCountResult {
  productListsCount?: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_PRODUCT_LISTS = gql`
  query QueryProductLists(
    $limit: Int!
    $offset: Int
    $where: dash_product_lists_bool_exp!
    $order: [dash_product_lists_order_by!]
  ) {
    productLists: dash_product_lists(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order
    ) {
      id
      memberId: member_id
      timestamp
      name
      revisedAt: revised_at
      description
    }
  }
`;

export const QUERY_PRODUCT_LISTS_COUNT = gql`
  query QueryProductListsCount($where: dash_product_lists_bool_exp!) {
    productListsCount: dash_product_lists_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const QUERY_PRODUCT_LIST_BY_ID = gql`
  query QueryProductList($id: String!, $memberId: Int!) {
    productList: dash_product_lists_by_pk(id: $id, member_id: $memberId) {
      id
      member_id
      name
      revised_at
      timestamp
      description
    }
  }
`;
