import { chain, fromNullable, none, Option, some } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { Eq } from 'fp-ts/Eq';
import { Semigroup } from 'fp-ts/Semigroup';

export const fromNullableOrEmptyString = (
  str: string | undefined
): Option<string> =>
  pipe(
    fromNullable(str),
    chain((val) => (val === '' ? none : some(val)))
  );

export const getArraySemigroup = <A>(eq: Eq<A>): Semigroup<A[]> => ({
  concat(x, y) {
    return y.reduce(
      (acc, current) =>
        acc.some((el) => eq.equals(el, current)) ? acc : acc.concat([current]),
      x
    );
  },
});
