// Vendor
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

// Internal
import AdvancedFilter from 'components/Filters/AdvancedFilter';
import { useTradingPartnerType } from 'hooks';
import { FilterDefinitionsInterface } from 'components/FilterGroup';
import { Filters } from './helpers';

export interface Props {
  applyFilters: (filters: Filters, filtersActive: boolean) => void;
  isRoster?: boolean;
  lists: {
    id: string;
    name: string;
  }[];
  disableFilters: boolean;
  // Controlled filters
  cFilters?: Filters;
  cFiltersActive?: boolean;
}

const CustomerFilter: FC<Props> = (props) => {
  const {
    applyFilters,
    isRoster,
    lists,
    disableFilters,
    cFilters,
    cFiltersActive,
  } = props;

  const endOfDay: Date = dayjs().endOf('day').toDate();

  const tpType = useTradingPartnerType();
  const isGPO = tpType === 'GPO';

  const initialFilters: Filters = {
    activeWithinProgram: true,
    activeWithinRoster: true,
    allProgramsSelected: true,
    expiredWithinProgram: true,
    expiredWithinRoster: true,
    selectedPrograms: lists.map((p) => p.id).concat('noProgram'),
    selectedDate: endOfDay,
    showInvalid: true,
    showValid: true,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [filtersActive, setFiltersActive] = useState(false);

  // This effect allow us to force the load of the received (filters, filtersActive).
  useEffect(() => {
    if (cFilters !== undefined && !isEqual(cFilters, filters)) {
      setFilters(cFilters);
    }
    if (cFiltersActive !== undefined && cFiltersActive !== filtersActive) {
      setFiltersActive(cFiltersActive);
    }
  }, [cFilters, cFiltersActive]);

  useEffect(() => {
    setFiltersActive(!isEqual(filters, initialFilters));
  }, [filters]);

  const gpoAndRosterFilterDefinitions: FilterDefinitionsInterface[] = [
    {
      filters: [
        { label: 'Expired within roster', name: 'expiredWithinRoster' },
        { label: 'Active within roster', name: 'activeWithinRoster' },
      ],
      filterValues: {
        activeWithinRoster: filters.activeWithinRoster,
        expiredWithinRoster: filters.expiredWithinRoster,
      },
      fullSize: false,
      title: 'ROSTER STATUS',
    },
    {
      allSelected: filters.allProgramsSelected,
      filters: [
        { label: 'No program', name: 'noProgram' },
        ...lists.map(({ id, name }) => ({ label: name, name: id })),
      ],
      filterValues: filters.selectedPrograms,
      initialValues: initialFilters.selectedPrograms,
      title: 'PROGRAM',
    },
    {
      filters: [
        { label: 'Active within program', name: 'activeWithinProgram' },
        { label: 'Expired within program', name: 'expiredWithinProgram' },
      ],
      filterValues: {
        activeWithinProgram: filters.activeWithinProgram,
        expiredWithinProgram: filters.expiredWithinProgram,
      },
      fullSize: false,
      title: 'PROGRAM STATUS',
    },
  ];

  const filterDefinitions: FilterDefinitionsInterface[] = [
    {
      filters: [{ label: 'Date', name: 'selectedDate', type: 'date' }],
      filterValues: { selectedDate: filters.selectedDate },
      title: 'DATA AS OF',
    },
    {
      filters: [
        { label: 'Valid', name: 'showValid' },
        { label: 'Invalid', name: 'showInvalid' },
      ],
      filterValues: {
        showInvalid: filters.showInvalid,
        showValid: filters.showValid,
      },
      fullSize: false,
      title: 'VALIDATION',
    },
    ...(isGPO || isRoster ? [...gpoAndRosterFilterDefinitions] : []),
  ];

  const handleClear = () => setFilters(initialFilters);

  return (
    <AdvancedFilter
      applyFilters={() => {
        applyFilters(
          {
            ...filters,
          },
          filtersActive
        );
      }}
      filtersActive={filtersActive}
      filterDefinitions={filterDefinitions}
      handleClear={handleClear}
      setFilters={(newFilters) =>
        setFilters({
          ...filters,
          ...newFilters,
        })
      }
      disableFilters={disableFilters}
    />
  );
};

export default CustomerFilter;
