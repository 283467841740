// Vendor
import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

// Internal
import {
  wrapSearchText,
  QueryCustomerView,
  QueryCustomerViewCountResult,
  QUERY_CUSTOMERS_FOR_LIST,
  QUERY_CUSTOMERS_COUNT,
} from 'query';
import { useTradingPartnerConfig } from 'hooks';
import { NUMBER_PER_PAGE } from 'common/constants';
import SearchDialog from './SearchDialog';

interface Props {
  setParentId: (parentId: string) => void;
  toggleDialog: (val: boolean) => void;
}

const Container: FC<Props> = (props) => {
  const { setParentId, toggleDialog } = props;

  const { id } = useParams() as { id: string };

  const [currentPage, updatePagination] = useState(1);
  const [searchTextValue, updateSearchText] = useState('');

  const { id: memberId } = useTradingPartnerConfig() ?? {};
  const searchText = !searchTextValue
    ? undefined
    : wrapSearchText(searchTextValue);

  // Filters
  const [where, updateWhere] = useState({
    id: { _neq: id },
    member_id: { _eq: memberId },
    _search_text: { _ilike: searchText },
  });
  // Order
  const [order] = useState({ timestamp: 'desc' });
  // Pagination
  const paginationFilters = { limit: NUMBER_PER_PAGE, offset: 0 };

  const { data, loading, error, refetch } = useQuery<QueryCustomerView>(
    QUERY_CUSTOMERS_FOR_LIST,
    {
      variables: {
        where,
        order,
        ...paginationFilters,
        includeMemberships: false,
      },
      skip: !memberId,
    }
  );

  const { data: countData, loading: countLoading } =
    useQuery<QueryCustomerViewCountResult>(QUERY_CUSTOMERS_COUNT, {
      variables: { where },
      skip: !memberId,
    });

  const totalCount = countData?.customerCount?.aggregate.count || 0;
  const customers = data?.customers || [];

  return (
    <SearchDialog
      countLoading={countLoading}
      currentPage={currentPage}
      customers={customers}
      error={error}
      loading={loading}
      memberId={memberId}
      refetch={refetch}
      searchText={searchTextValue}
      setParentId={setParentId}
      toggleDialog={toggleDialog}
      totalCount={totalCount}
      updatePagination={updatePagination}
      updateSearchTerm={(term) => {
        updateSearchText(term);
        updatePagination(1);
        updateWhere({
          ...where,
          _search_text: { _ilike: wrapSearchText(term) },
        });
      }}
    />
  );
};

export default Container;
