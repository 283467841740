// Vendor
import { pipe } from 'fp-ts/lib/function';
import {
  chain,
  fromNullable,
  getOrElseW,
  none,
  some,
  Option,
  map,
  ap,
} from 'fp-ts/lib/Option';
import { compact } from 'fp-ts/lib/Array';

// Internal
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
} from 'common/helpers/date';
import { Customer } from 'types/generated/Customer';
import { CustomerListMembership } from 'types/generated/CustomerListMembership';
import { CustomerAddress } from 'types/generated/CustomerAddress';
import { CustomerClassOfTrade } from 'types/generated/CustomerClassOfTrade';
import {
  extractAttributes,
  extractAttributesFromCustomColumns,
  prepareDate,
  processIdentifiers,
} from '../../adapter-utils';
import {
  LOCAL_CUSTOMER_BASE_FIELDS,
  LocalCustomerRosterSchema,
} from '../customer-roster';
import { CustomerRosterInputData } from '../customer-roster-adapter';
import { RosterAdapter } from '../../roster-adapter';
import { CUSTOMER_DEFAULT_IDENTIFIER_DELIMITERS } from '../../../constants';

class LocalCustomerAdapter
  implements
    RosterAdapter<LocalCustomerRosterSchema, CustomerRosterInputData, Customer>
{
  convert = (
    row: LocalCustomerRosterSchema,
    data: CustomerRosterInputData
  ): Customer => {
    const {
      id,
      address1,
      city,
      state,
      zipCode,
      address2,
      cot,
      startDate,
      endDate,
      dea,
      hin,
      name,
      name2,
      address3,
      parentId,
      listId,
      listStartDate,
      listEndDate,
      programId,
      programStartDate,
      programEndDate,
    } = row;
    const { identifiersDelimiters, memberId, timestamp } = data;
    const processedStartDate = prepareDate(
      startDate,
      convertStartDateToUtcTimestamp
    );
    const processedEndDate = prepareDate(endDate, convertEndDateToUtcTimestamp);
    const identifiersToSave = processIdentifiers(
      { dea, hin, _340b: row['340b'] },
      identifiersDelimiters ?? CUSTOMER_DEFAULT_IDENTIFIER_DELIMITERS
    );
    const contractList: Option<CustomerListMembership> = pipe(
      fromNullable(listId),
      map((valueListId) => ({
        listId: valueListId,
        startDate: prepareDate(listStartDate, convertStartDateToUtcTimestamp),
        endDate: prepareDate(listEndDate, convertEndDateToUtcTimestamp),
      }))
    );
    const programList: Option<CustomerListMembership> = pipe(
      fromNullable(programId),
      map((valueListId) => ({
        listId: valueListId,
        startDate: prepareDate(
          programStartDate,
          convertStartDateToUtcTimestamp
        ),
        endDate: prepareDate(programEndDate, convertEndDateToUtcTimestamp),
      }))
    );
    const lists = compact([contractList, programList]);
    const addresses = pipe(
      some(
        (address1V: string) =>
          (cityV: string) =>
          (stateV: string) =>
          (zipCodeV: string) =>
            [
              {
                address1: address1V,
                city: cityV,
                state: stateV,
                zipCode: zipCodeV,
                address2,
                addressType: 'primary',
                address3,
                startDate: processedStartDate,
                endDate: processedEndDate,
              },
            ] as CustomerAddress[]
      ),
      ap(fromNullable(address1)),
      ap(fromNullable(city)),
      ap(fromNullable(state)),
      ap(fromNullable(zipCode)),
      getOrElseW(() => undefined)
    );
    const classesOfTrade = pipe(
      fromNullable(cot),
      map(
        (classOfTrade) =>
          [
            {
              classOfTradeId: classOfTrade,
              startDate: processedStartDate,
              endDate: processedEndDate,
            },
          ] as CustomerClassOfTrade[]
      ),
      getOrElseW(() => undefined)
    );
    const parentIdValidated = pipe(
      fromNullable(parentId),
      chain((pId) => (pId === id ? none : some(pId))),
      getOrElseW(() => undefined)
    );

    return {
      id,
      memberId,
      timestamp,
      addresses,
      classesOfTrade,
      startDate: processedStartDate,
      endDate: processedEndDate,
      identifiers: identifiersToSave.length > 0 ? identifiersToSave : undefined,
      names: [name].concat(name2 ? [name2] : []),
      attributes: extractAttributes(row, LOCAL_CUSTOMER_BASE_FIELDS).concat(
        extractAttributesFromCustomColumns(row)
      ),
      parentId: parentIdValidated,
      lists: lists.length > 0 ? lists : undefined,
    };
  };
}

export default new LocalCustomerAdapter();
