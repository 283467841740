// Vendor
import { sortBy } from 'lodash';

// Internal
import { TradingPartner } from 'query';
import { OUT_OF_NETWORK_DIGIT } from 'common/constants';

export const isRosterOON = (id: number) => id > OUT_OF_NETWORK_DIGIT;

export const splitRoster = (roster: TradingPartner[]) => {
  const inNetworkRosters = sortBy(
    roster.filter(({ id }) => !isRosterOON(id)),
    ['name']
  );
  const oonRosters = sortBy(
    roster.filter(({ id }) => isRosterOON(id)),
    ['name']
  );
  return { inNetworkRosters, oonRosters };
};
