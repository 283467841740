// Vendor
import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

// Internal
import { NotFoundSection, Table, ViewSection } from 'components';
import {
  GET_CUSTOMER_CHILDREN,
  QueryCustomerByIdResult,
  QueryCustomerParentChildResult,
  QUERY_CUSTOMER_BY_ID,
  QUERY_CUSTOMER_BY_ID_WITH_TOP_PARENT,
  QueryCustomersWithTopParentId,
} from 'query';
import { useAuthDatabases } from 'hooks';
import { POLL_INTERVAL } from 'common/constants';
import { getColumns, getRows, Relationship } from './helpers';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

export interface Props {
  customerId: string;
  isLocalCustomer?: boolean;
  memberId: number;
  memberName?: string;
  parentId: string | null;
}

const Relationships: FC<Props> = (props) => {
  const acceptableAuthData = useAuthDatabases();

  const {
    customerId,
    isLocalCustomer = true,
    memberId,
    memberName = '',
    parentId,
  } = props;

  // As we are querying with the parent_id, we won't need extra logic to handle when parent_id == top_parent_id
  // because it is handled by the SQL function get_top_parent_id
  const { data: parentData, loading: parentLoading } =
    useQuery<QueryCustomersWithTopParentId>(
      QUERY_CUSTOMER_BY_ID_WITH_TOP_PARENT,
      {
        variables: {
          id: parentId,
          memberId,
        },
        skip: !parentId,
      }
    );

  const topParentId = (parentData?.customers ?? [])[0]?.topParentId;

  const { data: topParentData, loading: topParentLoading } =
    useQuery<QueryCustomerByIdResult>(QUERY_CUSTOMER_BY_ID, {
      variables: {
        id: topParentId,
        memberId,
      },
      skip: !topParentId,
    });

  const {
    data: childrenData,
    loading: loadingChildren,
    refetch: refetchChildren,
  } = useQuery<QueryCustomerParentChildResult>(GET_CUSTOMER_CHILDREN, {
    variables: {
      id: customerId,
      memberId,
    },
    pollInterval: POLL_INTERVAL,
    skip: !customerId,
  });

  useEffect(() => {
    refetchChildren();
  }, [memberId, customerId]);

  if (parentLoading || topParentLoading || loadingChildren) {
    return null;
  }

  const children = childrenData?.customers || [];
  const parent = (parentData?.customers ?? [])[0] || null;
  const topParent = topParentData?.customer || null;

  const newParent = parent
    ? {
        id: parent.id,
        identifiers: parent.identifiers,
        relationship: 'Parent',
      }
    : null;

  const newTopParent = topParent
    ? {
        id: topParent.id,
        identifiers: topParent.identifiers,
        relationship: 'Top Parent',
      }
    : null;

  const newChildren: Relationship[] = children.map((customer: any) => ({
    id: customer.id,
    identifiers: customer.identifiers,
    relationship: 'Child',
  }));

  const relationships = [
    ...(newParent ? [newParent] : ''),
    ...(newTopParent ? [newTopParent] : ''),
    ...(newChildren.length > 0 ? newChildren : ''),
  ];

  const columns = getColumns(acceptableAuthData, isLocalCustomer);
  const rows = getRows(
    acceptableAuthData,
    relationships as Relationship[],
    memberId,
    memberName
  );

  return (
    <ViewSection
      disableCollapse
      dataTestid='relationships'
      header='Relationships'
      sourceName={memberName}
    >
      <div className={cx('tableContent')}>
        {relationships.length === 0 ? (
          <NotFoundSection text='No Relationships found' />
        ) : (
          <Table
            columns={columns}
            isLoading={false}
            emptyText='No Relationships found'
            rows={rows}
          />
        )}
      </div>
    </ViewSection>
  );
};

export default Relationships;
