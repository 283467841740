// Vendor
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// Internal
import { Snackbar } from 'components';
import { RootState } from 'reducers';
import {
  AuthorityIDMapping,
  getSearchParam,
  getTradingPartnerName,
} from 'common/helpers';
import {
  DrawerEntry,
  getIdentifierData,
  getLocalData,
  getTPData,
} from 'services/Navigation';
import {
  useAuthConfig,
  useAuthDatabases,
  useChargebacksFlag,
  useContractsModuleFlag,
  useCreditMemosFlag,
  useCustomerListsModuleFlag,
  useCustomersModuleFlag,
  useProductListsFlag,
  useProductsFlag,
  useProposalsFlag,
  useRosterFlag,
  useTradingPartnerType,
} from 'hooks';
import {
  ChargebacksView,
  ChargebacksList,
  ContractsView,
  ContractsList,
  ContractsCreate,
  CreateCustomer,
  CreateCustomersList,
  CreateMapping,
  CreateProduct,
  CreateProductList,
  CreditMemoDetails,
  CreditMemos,
  CustomerDetails,
  CustomerListDetails,
  CustomerLists,
  CustomerProposals,
  CustomerProposalsView,
  Customers,
  EditContract,
  EditCustomerList,
  EditProductList,
  IdentifierDataCustomers,
  IdentifierDataDetails,
  ManageRules,
  ProductDetails,
  ProductListDetails,
  ProductLists,
  Products,
  Roster,
  TradingPartnerDetails,
  TradingPartners,
  UpdateCustomer,
  UpdateProduct,
  Triggers,
  // PLOP: PAGE IMPORTS
} from '../pages';
import { ITAdminDashboard } from './ITAdminDashboard';
import { NodeHealthContainer } from './ITAdminDashboard/NodeHealthCheck/NodeHealthContainer';
import AppBar from '../components/Navigation/AppBar';
import Drawer from '../components/Navigation/Drawer';
import OONRosterImport from './OONRosterImport';

// Styles
import inlineStyles from './inlineStyles';
import styles from './App.module.css';
const cx = classnames.bind(styles);

interface Props {
  classes: {
    root: string;
  };
  snackbar: {
    duration?: number;
    message?: string | Element;
    open: boolean;
    variant?: 'error' | 'info' | 'session' | 'success' | 'warning';
  };
}

const App: FC<Props> = ({ classes, snackbar }) => {
  const location = useLocation();
  const historyView = !!getSearchParam(location.search, 'historyView');

  const { duration, message, open, variant } = snackbar;

  // Navigation data
  const [drawerOpen, toggleDrawer] = useState(true);
  const auth = useAuthConfig();
  const acceptableAuthData = useAuthDatabases();
  const chargebacksEnabled = useChargebacksFlag();
  const contractsEnabled = useContractsModuleFlag();
  const creditMemosEnabled = useCreditMemosFlag();
  const customersEnabled = useCustomersModuleFlag();
  const rosterEnabled = useRosterFlag();
  const proposalsEnabled = useProposalsFlag();
  const customerListsEnabled = useCustomerListsModuleFlag();
  const productListsEnabled = useProductListsFlag();
  const productsEnabled = useProductsFlag();
  const [tradingPartnerVisible, showTradingPartners] = useState(
    location?.pathname.includes('admin')
  );

  const tradingPartnerType = useTradingPartnerType();
  const tradingPartnerName =
    getTradingPartnerName(tradingPartnerType).toUpperCase();

  const localData: DrawerEntry[] = getLocalData({
    customerListsEnabled,
    chargebacksEnabled,
    contractsEnabled,
    creditMemosEnabled,
    customersEnabled,
    proposalsEnabled,
    productsEnabled,
    productListsEnabled,
    xpType: tradingPartnerType,
  });
  const identifierData = getIdentifierData(
    tradingPartnerType,
    acceptableAuthData
  );
  const tpData = getTPData(
    chargebacksEnabled,
    contractsEnabled,
    creditMemosEnabled,
    rosterEnabled,
    tradingPartnerType
  );

  // Calculate the first available item in navigation (defaults to admin if nothing else is present)
  const redirectFallbackRoute =
    localData[0]?.hash || tpData[0]?.hash || identifierData[0]?.hash || 'admin';

  return (
    <div className={cx('root')}>
      <>
        <AppBar open={drawerOpen} toggleDrawer={toggleDrawer} />
        <Drawer
          isLoginEnabled={auth.isLoginEnabled()}
          userName={auth.user().name}
          tradingPartnerName={tradingPartnerName}
          tradingPartnerVisible={tradingPartnerVisible}
          showTradingPartners={showTradingPartners}
          localData={localData}
          identifierData={identifierData}
          tpData={tpData}
          acceptableAuthData={acceptableAuthData}
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
        />
      </>
      <div id='body' className={cx('body', { historyView })}>
        <Snackbar
          classes={{
            root: classes.root,
          }}
          duration={duration}
          message={message}
          open={open}
          variant={variant}
        />
        <Routes>
          <Route
            path='oon-roster/import/:memberId/:memberName'
            element={<OONRosterImport />}
          />
          <Route path='contracts' element={<ContractsList />} />
          <Route path='contracts/create' element={<ContractsCreate />} />
          <Route path='contracts/:memberId/:id' element={<ContractsView />} />
          <Route
            path='contracts/:memberId/:id/update'
            element={<EditContract />}
          />
          <Route path='products' element={<Products />} />
          <Route path='products/create' element={<CreateProduct />} />
          <Route path='products/:id' element={<ProductDetails />} />
          <Route path='products/:id/update' element={<UpdateProduct />} />
          <Route path='product-lists' element={<ProductLists />} />
          <Route path='product-lists/create' element={<CreateProductList />} />
          <Route
            path='product-lists/:id/update'
            element={<EditProductList />}
          />
          <Route path='product-lists/:id' element={<ProductListDetails />} />
          <Route path='admin' element={<ITAdminDashboard />} />
          <Route
            path='admin/node-health-check'
            element={<NodeHealthContainer />}
          />
          <Route path='admin/trading-partners' element={<TradingPartners />} />
          <Route
            path='admin/trading-partners/:id'
            element={<TradingPartnerDetails />}
          />
          <Route path='chargebacks' element={<ChargebacksList />} />
          <Route
            path='chargebacks/:memberId/:id'
            element={<ChargebacksView />}
          />
          <Route path='credit-memos' element={<CreditMemos />} />
          <Route path='credit-memos/:id' element={<CreditMemoDetails />} />
          <Route path='customers' element={<Customers isLocalCustomer />} />
          <Route path='customers/create' element={<CreateCustomer />} />
          <Route path='customers/:id/update' element={<UpdateCustomer />} />
          <Route
            path='customers/:id'
            element={<CustomerDetails isLocalCustomer />}
          />
          <Route
            path='customers/:id/create-mapping'
            element={<CreateMapping />}
          />
          <Route path='customer-proposals' element={<CustomerProposals />} />
          <Route
            path='customer-proposals/:id/:timestamp'
            element={<CustomerProposalsView />}
          />
          <Route
            path='customer-proposals/manage-rules'
            element={<Triggers />}
          />
          <Route
            path='customer-proposals/manage-rules/:id'
            element={<ManageRules />}
          />
          <Route path='customer-lists' element={<CustomerLists />} />
          <Route
            path='customer-lists/create'
            element={<CreateCustomersList />}
          />
          <Route
            path='customer-lists/:id/update'
            element={<EditCustomerList />}
          />
          <Route path='customer-lists/:id' element={<CustomerListDetails />} />
          <Route
            path='dea'
            element={
              <IdentifierDataCustomers
                key='dea'
                memberId={AuthorityIDMapping.DEA}
              />
            }
          />
          <Route
            path='hibcc'
            element={
              <IdentifierDataCustomers
                key='hibcc'
                memberId={AuthorityIDMapping.HIBCC}
              />
            }
          />
          <Route
            path='hrsa'
            element={
              <IdentifierDataCustomers
                key='hrsa'
                memberId={AuthorityIDMapping.HRSA}
              />
            }
          />
          <Route
            path='dea/:id'
            element={
              <IdentifierDataDetails memberId={AuthorityIDMapping.DEA} />
            }
          />
          <Route
            path='hibcc/:id'
            element={
              <IdentifierDataDetails memberId={AuthorityIDMapping.HIBCC} />
            }
          />
          <Route
            path='hrsa/:id'
            element={
              <IdentifierDataDetails memberId={AuthorityIDMapping.HRSA} />
            }
          />
          <Route path='roster' element={<Roster />} />
          <Route path='roster/:memberId/:memberName' element={<Customers />} />
          <Route
            path='roster/:memberId/:memberName/:id'
            element={<CustomerDetails />}
          />
          {/* PLOP: ROUTES */}
          <Route
            path='*'
            element={
              <Navigate
                to={
                  location.search.length
                    ? redirectFallbackRoute + location.search
                    : redirectFallbackRoute
                }
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

const mapStateToProps = ({ common: { snackbar } }: RootState) => ({
  snackbar,
});

export default connect(
  mapStateToProps
  // @ts-ignore
)(withStyles(inlineStyles)(App));
