/* This object acts a configuration file for all Material-UI elements.
 * https://material-ui.com/customization/themes/
 * Values of type `number` are using pixels as units
 */

import { ThemeOptions } from '@material-ui/core';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#1482FC',
    },
    secondary: {
      main: '#FFF',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiDialog: {
      fullWidth: true,
      BackdropProps: {
        style: {
          background: 'linear-gradient(127deg, #00bcd4, #1482fc)',
          opacity: 0.75,
        },
      },
    },
    MuiDivider: {
      variant: 'middle',
    },
    MuiPaper: {
      elevation: 1,
    },
    MuiTab: {
      disableRipple: true,
      wrapped: false,
    },
    MuiTable: {
      size: 'small',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: '0 2px 6px 0 rgba(124, 143, 151, 0.11)',
        marginLeft: 57,
        width: `calc(100% - ${57}px)`,
      },
    },
    MuiButton: {
      root: {
        border: '1px solid #e4ebef',
        borderRadius: '5px',
        fill: '#525f7f',
        fontFamily: 'Graphik',
        fontSize: 12,
        height: 40,
        minWidth: 'unset',
        textOverflow: 'ellipsis',
        textTransform: 'none',
        width: 120,
      },
      containedPrimary: {
        '&:active': {
          backgroundColor: '#1482FC',
          boxShadow: 'inset 0px 1px 3px rgba(10, 46, 87, 0.5)',
        },
        '&$disabled': {
          backgroundColor: '#E4EBEF',
          color: 'rgba(82, 95, 127, 0.5)',
        },
        '&:hover': {
          backgroundColor: '#1266C3',
          boxShadow: 'none',
          color: '#FFF',
        },
        backgroundColor: '#1482FC',
        border: 'none',
        boxShadow: 'none',
        color: '#FFF',
      },
      outlinedSecondary: {
        '&:active': {
          boxShadow: 'inset 0px 1px 3px rgba(10, 46, 87, 0.25)',
        },
        '&$disabled': {
          border: '1px solid #E4EBEF',
          color: 'rgba(82, 95, 127, 0.5)',
          fill: 'rgba(82, 95, 127, 0.5)',
          '& .actionsIcon': {
            stroke: 'rgba(82, 95, 127, 0.5)',
          },
        },
        '&:hover': {
          backgroundColor: '#FAFAFB',
          border: '1px solid #AEBFC9',
          color: '#394157',
          fill: '#394157',
        },
        backgroundColor: '#fff',
        border: '1px solid #E4EBEF',
        color: '#525F7F',
        fill: '#525F7F',
        padding: '6px 8px',
      },
      text: {
        fill: '#525F7F',
        border: 'none',
        height: 'fit-content',
        width: 'fit-content',
        '&:hover': {
          backgroundColor: 'unset !important',
        },
      },
      endIcon: {
        marginLeft: 'auto',
        marginRight: 0,
      },
    },
    MuiBreadcrumbs: {
      root: {
        fontSize: 10,
        '& p': {
          fontSize: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& p.active': {
          color: '#017EFA',
        },
        '& p.disabled': {
          color: '#cbcad2',
          pointerEvents: 'none',
        },
        '& a': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#0b1641',
        },
        '& a.active': {
          color: '#017EFA',
        },
        '& a:hover': {
          color: '#017EFA',
        },
        '& a.disabled': {
          color: '#cbcad2',
          pointerEvents: 'none',
        },
      },
      separator: {
        color: '#CBCAD2',
        fontSize: 12,
        margin: '0 4px !important',
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: '#1482FC !important',
        },
        '&$disabled': {
          color: '#e4ebef !important',
        },
        color: '#525F7F !important',
        height: 16,
        width: 16,
      },
      checked: {},
    },
    MuiDialog: {
      paper: {
        overflowY: 'visible',
      },
      paperWidthLg: {
        borderRadius: '8px',
        boxShadow: '0 2px 6px 0 rgba(94, 105, 119, 0.2)',
      },
      paperWidthSm: {
        borderRadius: '8px',
        boxShadow: '0 2px 6px 0 rgba(94, 105, 119, 0.2)',
        maxHeight: 522,
        maxWidth: 522,
        minWidth: 390,
      },
    },
    MuiDialogContent: {
      root: {
        maxHeight: 422,
        padding: 0,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '0 5px 5px 5px',
        border: '1px solid #E4EBEF',
        boxShadow: '0px 2px 6px rgba(124, 143, 151, 0.24)',
        minWidth: 180,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#e4ebef',
        margin: '8px 0',
      },
    },
    MuiDrawer: {
      root: {
        flexShrink: 0,
        height: '100%',
        whiteSpace: 'nowrap',
        width: 256,
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: '0 2px 6px 0 rgba(124, 143, 151, 0.11)',
        borderRadius: '8px !important',
        color: '#011f42',
        fontFamily: 'Graphik',
        fontSize: '16px',
        marginBottom: '16px',
        width: '100%',
        zIndex: 1,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.07)',
        color: '#011f42',
        margin: '0px',
        zIndex: 1,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
      label: {
        color: '#456188',
        fontFamily: 'Graphik',
        fontSize: 12,
        marginLeft: 20,
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          margin: 0,
        },
        color: 'red',
        fontFamily: 'Graphik',
        position: 'absolute',
        top: 40,
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          border: 'none',
        },
      },
      input: {
        '&::-ms-clear': {
          display: 'none',
        },
      },
      inputAdornedEnd: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 4,
        height: 6,
      },
    },
    MuiList: {
      root: {
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
      },
    },
    MuiListItem: {
      root: {
        color: '#fff',
        fontFamily: 'Graphik',
        fontSize: 14,
        height: 48,
        padding: '6px !important',
      },
    },
    MuiListItemIcon: {
      root: {
        display: 'block',
        marginRight: 0,
        marginTop: 4,
        minWidth: 48,
        textAlign: 'center',
        width: 22,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: '#f7f8fa',
        },
        '&$selected': {
          backgroundColor: 'rgba(124, 143, 151, 0.11)',
        },
        color: '#525f7f',
        display: 'block',
        fontFamily: 'Graphik',
        fontSize: 12,
        height: 40,
        lineHeight: '40px',
        maxHeight: 40,
        maxWidth: 400,
        overflow: 'hidden',
        padding: 0,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      gutters: {
        padding: '0 12px !important',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$disabled': {
          border: '1px solid #e4ebef',
          borderColor: '1px solid #e4ebef',
        },
        '&:hover:not($disabled):not($focused):not($error)': {
          border: '1px solid #1482fc',
        },
        '&$focused': {
          border: '1px solid #1482FC',
        },
        backgroundColor: '#fff',
        border: '1px solid #e4ebef',
        borderRadius: 5,
        fontFamily: 'Graphik',
        fontSize: '12px',
        height: '40px',
      },
      adornedEnd: {
        paddingRight: 0,
      },
      input: {
        padding: '0 14px',
      },
      notchedOutline: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
          {
            top: 0,
          },
        border: 'none',
        transitionProperty: 'none',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '8px',
      },
      elevation1: {
        boxShadow: '0 2px 6px 0 rgba(124, 143, 151, 0.11)',
      },
      rounded: {
        borderRadius: '6px',
      },
    },
    MuiRadio: {
      root: {
        paddingBottom: 0,
        paddingTop: 0,
      },
      colorSecondary: {
        color: '#525f7f !important',
      },
    },
    MuiSelect: {
      root: {
        stroke: 'none',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiSnackbar: {
      root: {
        border: 0,
        paddingLeft: '12px',
      },
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: '8px !important',
        boxShadow: 'none',
        fontSize: '14px',
        paddingLeft: '12px',
      },
      action: {
        paddingLeft: 0,
      },
      message: {
        alignItems: 'center',
        display: 'flex',
      },
    },
    MuiStepConnector: {
      active: {
        '& $line': {
          borderColor: '#1482fc',
          backgroundColor: '#1482fc',
        },
      },
      alternativeLabel: {
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
        top: 14,
      },
      completed: {
        '& $line': {
          borderColor: '#1482fc',
          backgroundColor: '#1482fc',
        },
      },
      line: {
        transition:
          'border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
        border: 'solid 2px',
        borderColor: '#e4ebef',
        backgroundColor: '#e4ebef',
      },
    },
    MuiStepIcon: {
      root: {
        border: '2px solid #e4ebef',
        borderRadius: '50%',
        color: '#fff',
        height: 32,
        width: 32,
      },
      active: {
        borderColor: '#1482fc',
        color: '#fff !important',
        '& $text': {
          fill: '#1482fc !important',
        },
      },
      completed: {
        border: 'none',
      },
      text: {
        fill: '#525f7f',
        fontFamily: 'Graphik',
        fontSize: 14,
      },
    },
    MuiStepLabel: {
      label: {
        color: '#525f7f',
        fontFamily: 'Graphik',
        fontSize: 12,
        marginTop: '5px !important',
      },
      active: {
        color: '#1482fc !important',
      },
      completed: {
        // @ts-ignore
        fontWeight: 'bolder !important',
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '100%',
      },
    },
    MuiSvgIcon: {
      root: {
        height: 16,
      },
    },
    MuiSwitch: {
      root: {
        color: '#1482fc',
      },
    },
    MuiTableCell: {
      root: {
        '&.radioButton': {
          paddingLeft: '8px !important',
          width: 70,
        },
        borderBottom: 'none',
      },
      alignRight: {
        paddingRight: 0,
      },
      body: {
        '&:first-child': {
          paddingLeft: 16,
        },
        color: '#525f7f',
        fontFamily: 'Graphik',
        fontSize: 12,
        lineHeight: 1.5,
        maxWidth: 200,
        overflow: 'hidden',
        padding: 16,
        textOverflow: 'ellipsis',
        verticalAlign: 'text-top',
      },
      head: {
        '&:first-child': {
          borderRadius: '8px 0 0 0',
          paddingLeft: 16,
        },
        '&:last-child': {
          borderRadius: '0 8px 0 0',
        },
        '& .sort': {
          alignItems: 'center',
          display: 'flex',
        },
        color: '#011f42',
        fontFamily: 'Graphik',
        fontSize: 10,
        fontWeight: 'bold',
        letterSpacing: 0.8,
        lineHeight: 1.5,
        maxWidth: 100,
      },
      sizeSmall: {
        maxWidth: 100,
        padding: 16,
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
        '&:nth-of-type(odd)': {
          backgroundColor: '#f7f8fa',
        },
        '&:last-child td:first-child': {
          borderRadius: '0 0 0 8px',
        },
        '&:last-child td:last-child': {
          borderRadius: '0 0 8px 0',
        },
      },
      head: {
        '&:first-child': {
          backgroundColor: 'white',
        },
        height: 56,
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          color: '#1482fc',
        },
        '&:first-child': {
          borderRadius: '8px 0 0 8px',
          borderLeft: '1px solid #e4ebef',
        },
        '&:last-child': {
          borderRadius: '0 8px 8px 0',
        },
        backgroundColor: '#FFF',
        border: '1px solid #e4ebef',
        borderLeft: 'none',
        color: '#011F42',
        fontFamily: 'Graphik',
        fontSize: 12,
        minHeight: 40,
        textTransform: 'capitalize',
        width: 192,
      },
    },
    MuiTabs: {
      root: {
        background: 'transparent',
        marginBottom: 24,
        minHeight: 40,
      },
      indicator: {
        backgroundColor: '#1482fc',
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#fff',
        color: '#011f42',
        fontFamily: 'Graphik',
        fontSize: 24,
        fontWeight: 'bolder',
        lineHeight: 1,
      },
      dense: {
        maxHeight: 56,
        minHeight: 56,
      },
    },
    MuiTooltip: {
      popper: {
        opacity: 1,
      },
      tooltip: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(124, 143, 151, 0.28)',
        color: '#525f7f',
        fontFamily: 'Graphik',
        fontSize: '12px',
        maxWidth: 'none',
        padding: '16px',
      },
    },
  },
};

export default themeOptions;
