// Vendor
import React, { FC } from 'react';

// Internal
import { CustomerFormProps } from 'pages/Customers/Create/constants';
import { maxLength, required } from 'common/validation';
import { useAuthDatabases } from 'hooks';
import { AuthorityDatabases } from 'common/config';
import Section from 'components/Section';

interface Props {
  dea?: string;
  deaErr?: string;
  hin?: string;
  hinErr?: string;
  requiredFields: string[];
  three40b?: string;
  three40bErr?: string;
  validateIds?: (values: CustomerFormProps) => void;
  values: CustomerFormProps;
}

const Identifiers: FC<Props> = (props) => {
  const {
    dea,
    deaErr,
    hin,
    hinErr,
    requiredFields,
    three40b,
    three40bErr,
    validateIds,
    values,
  } = props;

  const acceptableAuthData = useAuthDatabases();

  return (
    <Section
      dataTestId='identifiers'
      fields={[
        ...(acceptableAuthData.includes(AuthorityDatabases.hin)
          ? [
              {
                customError: hin && hinErr,
                label: 'HIN',
                name: 'hin',
                handleOnBlur: () => {
                  if (validateIds) validateIds(values);
                },
                // Max length of 100 for identifiers (including hin:number:)
                validation: [
                  ...(requiredFields?.includes('hin') ? [required] : []),
                  maxLength(89),
                ],
              },
            ]
          : []),
        ...(acceptableAuthData.includes(AuthorityDatabases.dea)
          ? [
              {
                customError: dea && deaErr,
                label: 'DEA',
                name: 'dea',
                handleOnBlur: () => {
                  if (validateIds) validateIds(values);
                },
                // Max length of 100 for identifiers (including dea:number:)
                validation: [
                  ...(requiredFields?.includes('dea') ? [required] : []),
                  maxLength(89),
                ],
              },
            ]
          : []),
        ...(acceptableAuthData.includes(AuthorityDatabases['340b'])
          ? [
              {
                customError: three40b && three40bErr,
                label: '340B',
                name: 'three40b',
                handleOnBlur: () => {
                  if (validateIds) validateIds(values);
                },
                // Max length of 100 for identifiers (including 340b:number:)
                validation: [
                  ...(requiredFields?.includes('three40b') ? [required] : []),
                  maxLength(88),
                ],
              },
            ]
          : []),
      ]}
      title='Identifiers'
    />
  );
};

export default Identifiers;
