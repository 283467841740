import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'unfetch';

export const createApolloClient = (
  getToken: () => string | null
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: `${window.location.origin}/v1/graphql`,
    // @ts-ignore
    fetch,
  });

  const authLink = setContext((_, ctx) => {
    const headers = { ...ctx.headers };
    const token = getToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return { headers };
  });

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    graphQLErrors?.forEach(({ message }) =>
      // eslint-disable-next-line no-console
      console.error(`GraphQL Error: ${operation.operationName}, ${message}`)
    );

    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(
        `Network Error: ${operation.operationName}, ${networkError.message}`
      );
    }
  });

  const cachingOptions = {
    typePolicies: {
      dash_classes_of_trade: {
        keyFields: ['id', 'memberId', 'timestamp'],
      },
      dash_customers: {
        keyFields: ['id', 'memberId', 'timestamp'],
      },
      dash_customer_proposals: {
        keyFields: ['customerId', 'status', 'timestamp'],
      },
      dash_customers_history: {
        keyFields: ['id', 'timestamp'],
      },
      dash_relevant_customer_mappings_history: {
        keyFields: ['id', 'memberId', 'timestamp', 'upperBoundTimestamp'],
      },
      dash_mapping_events: {
        keyFields: ['fromId', 'timestamp', 'toId'],
      },
      dash_chargebacks: {
        keyFields: ['id', 'member_id', 'timestamp'],
      },
      dash_sales: {
        keyFields: [
          'id',
          'member_id',
          'revised_at',
          'product_id',
          'quantity',
          'unit_of_measure',
        ],
      },
    },
  };

  return new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(cachingOptions),
  });
};
