// Vendor
import React from 'react';
import classnames from 'classnames/bind';
import { capitalize } from 'lodash';

// Internal
import { ErrorWithMessage } from 'common/helpers';
import { TableColumn, TableRow } from 'components/Table';
import { IconErrorAlert } from 'styles/images';
import { RED_ERROR_COLOR, YELLOW_WARNING_COLOR } from 'common/constants';
import { REASON_COLUMN, TYPE_COLUMN } from 'components/Cells/constants';

// Styles
import styles from './index.module.css';

const cx = classnames.bind(styles);

export const getColumns = (): TableColumn[] => [
  {
    headerName: '',
    dataField: 'errorIcon',
    type: 'expanding',
    size: 'small',
  },
  TYPE_COLUMN,
  REASON_COLUMN,
];

const getFormattedMessage = (error: ErrorWithMessage) => (
  <>
    {error.message}
    {error.details.map(({ key, value }) => (
      <div key={key}>
        <strong>{key}:</strong> {value}
      </div>
    ))}
  </>
);

export const getRows = (
  severityErrors: any[],
  severityWarnings: any[]
): TableRow[] =>
  severityErrors.concat(severityWarnings).map((err) => ({
    isExpandable: false,
    data: {
      reason: getFormattedMessage(err),
      errorIcon: (
        <IconErrorAlert
          className={cx('icon')}
          fill={
            err.severity === 'ERROR' ? RED_ERROR_COLOR : YELLOW_WARNING_COLOR
          }
        />
      ),
      type: capitalize(err.severity),
    },
    type: 'expanding',
  }));

export const getSectionText = (error: ErrorWithMessage) => {
  switch (error.code) {
    case 'missing-identifier-reference':
    case 'illegal-identifier-reference':
      return 'Identifiers';
    default:
      return 'Overview';
  }
};
