// Vendor
import React from 'react';
import { TableCell } from '@material-ui/core';

// Internal
import { CONTRACT_PHARMACY, RELATED_HINS } from 'common/constants';
import {
  AuthorityIDMapping,
  displayAddress,
  extractRelationshipIds,
  generateStatus,
  getCotText,
  linkToAuthCustomerDetail,
  mapByField,
} from 'common/helpers';
import { TableColumn, TableRow } from 'components/Table';
import { CustomerViewRow, QueryClassOfTradeResult } from 'query';
import { IconCircleCheck } from 'styles/images';
import {
  ADDRESS_COLUMN,
  END_DATE_COLUMN,
  NAME_COLUMN,
  START_DATE_COLUMN,
  STATUS_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';

export const getColumns = (
  hasContractPharmacies: boolean,
  hasCustomerDetail: boolean,
  hasRelatedHIN: boolean,
  isSimpleDEA: boolean,
  memberId: number
): TableColumn[] => {
  const contractPharmacies: TableColumn = {
    dataField: 'contractPharmacies',
    headerName: 'CONTRACT PHARMACIES',
    size: 'small',
    type: 'expanding',
  };

  const cot: TableColumn = {
    dataField: 'cot',
    headerName: getCotText(memberId).toUpperCase(),
    size: 'small',
    type: 'expanding',
  };

  const relatedHin: TableColumn = {
    dataField: 'relatedHins',
    headerName: 'RELATED HIN',
    size: 'small',
    type: 'expanding',
  };

  const id: TableColumn = {
    dataField: 'id',
    headerName: getIdHeaderText(memberId),
    size: 'small',
    type: 'expanding',
  };

  const idLink: TableColumn = {
    displayTextFn: (data: any) => data.id,
    headerName: getIdHeaderText(memberId),
    size: 'small',
    type: 'link',
    urlFn: (data: any) => linkToAuthCustomerDetail(data.memberId, data.id),
  };

  return [
    hasCustomerDetail ? idLink : id,
    ...(isSimpleDEA ? [] : [NAME_COLUMN]),
    ...(isSimpleDEA ? [] : [ADDRESS_COLUMN]),
    ...(isSimpleDEA ? [] : [cot]),
    ...(isSimpleDEA ? [START_DATE_COLUMN] : []),
    ...(isSimpleDEA ? [END_DATE_COLUMN] : []),
    STATUS_COLUMN,
    ...(isSimpleDEA || !hasContractPharmacies ? [] : [contractPharmacies]),
    ...(isSimpleDEA || !hasRelatedHIN ? [] : [relatedHin]),
    UPDATED_AT_COLUMN,
  ];
};

export const getRows = (
  customers: CustomerViewRow[],
  cotQueryResult?: QueryClassOfTradeResult
): TableRow[] => {
  if (customers.length === 0) return [];

  const cots = mapByField(cotQueryResult?.classesOfTrade ?? [], 'id');

  return customers.map((customer: CustomerViewRow) => {
    const {
      addresses: addressesProp,
      classesOfTrade,
      endDate,
      id,
      memberId,
      names: namesProp,
      startDate,
      timestamp,
    } = customer;

    const addresses =
      addressesProp?.map((address) => displayAddress(address)) ?? [];
    const names = namesProp?.filter((name) => name);
    const customerCot = classesOfTrade
      ?.map((ccot) => cots.get(ccot.classOfTradeId))
      .find((cot) => !!cot);
    const relatedHins = Array.from(
      extractRelationshipIds([customer], RELATED_HINS)
    );
    const hasPharmacies = customer.relationshipsFrom?.some(
      (r) => r.relationship === CONTRACT_PHARMACY
    );

    const isExpandable =
      (names && names.length > 1) ||
      addresses.length > 1 ||
      relatedHins.length > 1;

    return {
      isExpandable,
      data: {
        names,
        addresses,
        cot: customerCot?.name,
        relatedHins,
        contractPharmacies: (
          <TableCell>{hasPharmacies && <IconCircleCheck />}</TableCell>
        ),
        startDate,
        endDate,
        status: generateStatus({ endDate, startDate }),
        timestamp,
        id,
        memberId,
      },
      type: 'expanding',
    };
  });
};

export const getIdHeaderText = (memberId: AuthorityIDMapping) => {
  const textMapper: Record<AuthorityIDMapping, string> = {
    [AuthorityIDMapping.DEA]: 'DEA #',
    [AuthorityIDMapping.HIBCC]: 'HIN #',
    [AuthorityIDMapping.HRSA]: '340B ID',
    [AuthorityIDMapping.AHD]: 'AHD #',
    [AuthorityIDMapping.NPI]: 'NPI #',
  };

  return textMapper[memberId];
};
