// Vendor
import { Eq, fromEquals } from 'fp-ts/Eq';

// Internal
import {
  Product,
  QueryProductsResult,
  GetProductsForListResult,
  wrapSearchText,
} from 'query';
import { TableColumn, TableRow } from 'components/Table';
import { formatDate, productPath, truncateDatetime } from 'common/helpers';
import { toMediledgerProductListId } from 'common/helpers/mlIdUtils';
import {
  DESCRIPTION_COLUMN,
  PRODUCT_ID_TYPE_COLUMN,
  UOM_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { Product as ProductEntity } from 'types/generated/Product';

export interface ProcessableSimpleProduct extends Product {
  active: boolean;
}

const SimpleProductEq: Eq<Product> = fromEquals(
  (sc1, sc2) => sc1.id === sc2.id
);

export const updateProduct = (
  product: Product,
  memberId: number,
  listId: string,
  timestamp: string
): ProductEntity => {
  const {
    id,
    external_id,
    description,
    kind,
    prices,
    start_date,
    end_date,
    unit_of_measure,
  } = product;

  return {
    id,
    externalId: external_id,
    memberId: `ml:member:${memberId}`,
    timestamp,
    description,
    kind,
    prices,
    relationships: [],
    revisedAt: timestamp,
    startDate: start_date,
    endDate: end_date,
    unitOfMeasure: unit_of_measure,
  };
};

export const updateProducts = async (
  products: Product[],
  memberId: number,
  api: any,
  targetListId: string
) => {
  const reqs = products.map(
    ({ active, id: productId, member_id: productMemberId }) =>
      api?.upsertContractAssociation({
        active,
        memberId: `ml:member:${memberId}`,
        fromId: `ml:product:${productMemberId}:${productId}`,
        timestamp: new Date().toISOString(),
        kind: 'product-association',
        toId: toMediledgerProductListId(targetListId, memberId),
      })
  );

  return Promise.all(reqs);
};

export const getProductsToUpdate = (
  prevProducts: Product[],
  selectedProducts: Product[]
): ProcessableSimpleProduct[] => {
  const productsToRemove: ProcessableSimpleProduct[] = prevProducts
    .filter((prod) => !selectedProducts.some((sProd) => prod.id === sProd.id))
    .map((prod) => ({ ...prod, active: false }));

  const productsToAdd: ProcessableSimpleProduct[] = selectedProducts
    .filter((sProd) => !prevProducts.some((prod) => prod.id === sProd.id))
    .map((cus) => ({ ...cus, active: true }));

  const productsToUpdate: ProcessableSimpleProduct[] = selectedProducts
    .filter((sProd) =>
      prevProducts.some(
        (prod) => prod.id === sProd.id && !SimpleProductEq.equals(prod, sProd)
      )
    )
    .map((prod) => ({ ...prod, active: true }));

  return productsToRemove.concat(productsToAdd).concat(productsToUpdate);
};

export function filtersToQueryOnCreateEdit(searchText: string) {
  return {
    _or: [
      { external_id: { _ilike: wrapSearchText(searchText) } },
      { description: { _ilike: wrapSearchText(searchText) } },
    ],
  };
}

export function filtersToQuery(
  searchText: string,
  memberId: number,
  listId: string
): any {
  // Return query for the view list page
  const whereObject: Record<string, any> = {
    _and: {
      active: { _eq: true },
      kind: { _eq: 'product-association' },
      to_id: { _eq: listId },
      to_member_id: { _eq: memberId },
    },
  };

  if (searchText) {
    const searchTextWrapped = wrapSearchText(searchText);

    whereObject._and.product = {
      _or: [
        {
          external_id: {
            _ilike: searchTextWrapped,
          },
        },
        {
          description: {
            _ilike: searchTextWrapped,
          },
        },
      ],
    };
  }

  return whereObject;
}

export const productsToRowsForEditPage = (
  currentListProducts: Product[],
  data?: QueryProductsResult
): TableRow[] => {
  if (!data) {
    return [];
  }

  return data.products.map((product) => {
    const { id: productId } = product;
    const simpleProduct = currentListProducts.find(
      (prod) => prod.id === productId
    );
    return {
      type: 'expanding',
      isExpandable: false,
      data: {
        id: product.id,
        externalId: product.external_id,
        checked: !!simpleProduct,
        idType: product.kind,
        description: product.description,
        relationships: product.relationships,
        uom: product.unit_of_measure,
        startDate: truncateDatetime(product.start_date),
        endDate: truncateDatetime(product.end_date),
        timestamp: formatDate(new Date(product.timestamp).toISOString()),
      },
    };
  });
};

export const productsToRowsForViewPage = (
  data?: GetProductsForListResult
): TableRow[] => {
  if (!data) {
    return [];
  }

  return data.products.map(({ product }) => ({
    type: 'expanding',
    isExpandable: false,
    data: {
      id: product.id,
      externalId: product.external_id,
      idType: product.kind,
      description: product.description,
      relationships: product.relationships,
      uom: product.unit_of_measure,
      startDate: truncateDatetime(product.start_date),
      endDate: truncateDatetime(product.end_date),
      timestamp: formatDate(new Date(product.timestamp).toISOString()),
    },
  }));
};

export function getColumnsForViewPage(): TableColumn[] {
  return [
    {
      headerName: 'PRODUCT ID',
      type: 'link',
      size: 'small',
      displayTextFn: (data: any) => data.externalId,
      urlFn: (data: any) => productPath(data.id),
    },
    PRODUCT_ID_TYPE_COLUMN,
    DESCRIPTION_COLUMN,
    UOM_COLUMN,
    UPDATED_AT_COLUMN,
  ];
}

export function getColumnsForEditPage(
  currentListProducts: Product[],
  onListProductsChange: (updatedList: Product[]) => void,
  data?: QueryProductsResult
): TableColumn[] {
  const handleOnChange = (id: string, updatedChecked: boolean) => {
    if (!data) {
      return;
    }

    const selectedProduct: Product | undefined = currentListProducts.find(
      (product) => product.id === id
    );

    if (updatedChecked && !selectedProduct) {
      const newProduct: Product | undefined = data.products.find(
        (product) => product.id === id
      );
      if (newProduct) {
        onListProductsChange([...currentListProducts, newProduct]);
      }
    } else if (!updatedChecked && selectedProduct) {
      onListProductsChange(
        currentListProducts.filter((prod) => prod.id !== id)
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `There is a suspicious update for ${id} with previous products: ${currentListProducts}`
      );
    }
  };

  return [
    {
      headerName: '',
      type: 'checkBox',
      size: 'small',
      idFn: (data: any) => data.id,
      checkedFn: (data: any) => data.checked || false,
      disabledFn: () => false,
      handleOnChangeFn: handleOnChange,
    },
    ...getColumnsForViewPage(),
  ];
}
