// Vendor
import dayjs from 'dayjs';

// Internal
import { LONG_DATETIME_FORMAT, SHORT_DATE_FORMAT } from 'common/constants';

const encodeB64 = (str: string) => Buffer.from(str).toString('base64');
const decodeB64 = (str: string) => Buffer.from(str, 'base64').toString();

const checkIsValidDate = (target: any): boolean =>
  typeof target !== 'number' &&
  typeof target !== 'boolean' &&
  !Array.isArray(target) &&
  (dayjs(target, LONG_DATETIME_FORMAT).isValid() ||
    dayjs(target, SHORT_DATE_FORMAT).isValid());

export const FiltersSerializationService = {
  serialize: (filters: Record<string, any>): string => {
    const rawObject = JSON.stringify(filters);
    return encodeB64(rawObject);
  },
  parse: (target: string): Record<string, any> | undefined => {
    try {
      const decodedStr = decodeB64(target);
      const filtersObject = JSON.parse(decodedStr);
      return Object.keys(filtersObject).reduce(
        (acc: Record<string, any>, key: string) => {
          // We need to create date instances from the string if applies
          acc[key] = checkIsValidDate(filtersObject[key])
            ? new Date(filtersObject[key])
            : filtersObject[key];
          return acc;
        },
        {}
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Invalid parsing for filters with error: ', e);
      return undefined;
    }
  },
};
