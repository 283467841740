// Vendor
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import { Button, ViewSection } from 'components';
import Table, { TableRow } from 'components/Table';
import {
  CustomerDetails,
  QueryClassOfTradeResult,
  QUERY_MULTIPLE_COT,
  GET_TRADING_PARTNERS,
  QueryTradingPartners,
} from 'query';
import {
  MappingAssociation,
  useApi,
  useAuthDatabases,
  useManualMappingFlag,
  useSnackbar,
  useTradingPartnerType,
} from 'hooks';
import { getCustomerMappingList, getHeaders, getRows } from './helpers';
import DeleteMapping from '../DeleteMapping';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

interface Props {
  customer: CustomerDetails;
  isLocalCustomer?: boolean;
  mappings: any[];
}

const Mapping: FC<Props> = (props) => {
  const { customer, isLocalCustomer = true, mappings } = props;

  const api = useApi();
  const snackbar = useSnackbar();
  const acceptableAuthData = useAuthDatabases();
  const tpType = useTradingPartnerType();
  const isManualMappingEnabled = useManualMappingFlag() && isLocalCustomer;
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const [showDialog, toggleDialog] = useState(false);
  const [tradingPartner, setTradingPartner] = useState({
    id: '',
    memberId: 0,
    names: [],
  });

  const customerMappingList = getCustomerMappingList(mappings, isLocalCustomer);
  const noMapping = customerMappingList.length === 0;

  const { data, loading: tpLoading } = useQuery<QueryTradingPartners>(
    GET_TRADING_PARTNERS,
    {
      variables: { id: {} },
      // Trading partner is only shown on local customer mapping section, so skip if not local cust
      skip: !isLocalCustomer,
    }
  );
  const partners = data?.tradingPartners || [];

  const memberId = customerMappingList?.[0]?.memberId;
  const cotIds = customerMappingList
    .flatMap((c) =>
      c.classesOfTrade?.map(
        (cot: { classOfTradeId: any }) => cot.classOfTradeId
      )
    )
    .filter((cot) => cot);
  const { data: cotData, loading: cotLoading } =
    useQuery<QueryClassOfTradeResult>(QUERY_MULTIPLE_COT, {
      variables: {
        ids: cotIds,
        memberId,
      },
      // Cots is only shown on roster customer mapping section, so skip if not roster cust
      skip: cotIds.length === 0 || memberId === undefined || isLocalCustomer,
    });
  const allCots = cotData?.classesOfTrade || [];

  const columns = getHeaders(isLocalCustomer, acceptableAuthData);

  const [rows, updateRows] = useState([] as TableRow[]);

  const loading = tpLoading || cotLoading;

  const deleteMapping = () => {
    const { memberId: fromMemberId } = customer;
    const { id: toId, memberId: toMemberId } = tradingPartner;

    const payload: MappingAssociation = {
      active: false,
      fromId: `ml:customer:${fromMemberId}:${id}`,
      kind: 'mapping-association',
      memberId: `ml:member:${fromMemberId}`,
      timestamp: dayjs(Date.now()).utc(),
      toId: `ml:customer:${toMemberId}:${toId}`,
    };
    api?.upsertCustomerAssociation(payload);

    toggleDialog(false);

    snackbar.open({
      message: `Customer ${tradingPartner.names[0]} mapping has been removed.`,
      variant: 'success',
    });
  };

  useEffect(() => {
    if (!loading) {
      updateRows(
        getRows(
          toggleDialog,
          setTradingPartner,
          isLocalCustomer,
          acceptableAuthData,
          tpType,
          customerMappingList,
          allCots,
          partners
        )
      );
    }
  }, [customer, cotData, loading, cotLoading]);

  return (
    <>
      {showDialog && (
        <DeleteMapping
          deleteMapping={deleteMapping}
          toggleDialog={toggleDialog}
          tradingPartner={tradingPartner}
        />
      )}
      <ViewSection
        dataTestid='mapping'
        disableCollapse={noMapping}
        emptySection={noMapping}
        header='Mapping'
        headerContent={
          isManualMappingEnabled && (
            <Button
              color='secondary'
              dataTestId='addMappingBtn'
              label='Add mapping'
              onClick={() =>
                navigate(
                  `/customers/${encodeURIComponent(id)}/create-mapping`,
                  {
                    replace: true,
                  }
                )
              }
              style={{
                marginTop: 8,
                minWidth: 152,
              }}
              variant='outlined'
            />
          )
        }
      >
        <div className={cx('tableContent')}>
          <Table
            columns={columns}
            isLoading={loading}
            emptyText='No mapping found'
            rows={rows}
          />
        </div>
      </ViewSection>
    </>
  );
};

export default Mapping;
