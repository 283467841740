import gql from 'graphql-tag';
import { ContractEntity } from 'types/generated/ContractEntity';
import { CustomerAddress } from 'types/generated/CustomerAddress';
import { ErrorCode } from 'types/generated/ErrorCode';
import { Pricing } from 'types/generated/Pricing';
import { Relationship } from 'types/generated/Relationship';

export interface QueryContractCustomersResult {
  dash_get_contract_customers?: ContractCustomer[];
}

export interface QueryContractCustomersCountResult {
  dash_get_contract_customers_aggregate?: {
    aggregate: {
      count: number;
    };
  };
}

export interface Identifier {
  identifier: string;
}

export interface ContractCustomer {
  id: string;
  memberId: number;
  timestamp: string;
  names: string[];
  startDate: string;
  endDate: string;
  addresses: CustomerAddress[];
  identifiers: string[];
  errors: ErrorCode[];
  relationships?: Relationship[];
}

export interface GetContractsResult {
  dash_contract_headers?: ContractViewRow[];
}

export interface GetContractCountResult {
  dash_contract_headers_aggregate?: {
    aggregate: {
      count: number;
    };
  };
}

export interface ContractViewRowPricing {
  product_id: string;
  errors?: ErrorCode[];
}

export interface ContractViewRow {
  list_id: string;
  list_member_id: number;
  contract_entities?: ContractEntity[];
  distributors?: ContractEntity[];
  type: string;
  description: string;
  open: Boolean | null;
  errors?: ErrorCode[] | null;
  start_date: string;
  end_date: string;
  timestamp: string;
  latest_timestamp: string;
  pricings?: ContractViewRowPricing[];
}

export interface Contract {
  id: string;
  contractEntities?: string;
  customData?: string;
  description: string;
  distributors?: string;
  endDate: string;
  memberId: string;
  resubmissionWindowDays?: number;
  startDate: string;
  submissionWindowDays?: number;
  timestamp: string;
}

export interface ContractEventHour {
  hour: string;
}

export interface QueryContractEventsByHour {
  dash_get_contract_events_by_hour: ContractEventHour[];
}

export const GET_CONTRACT_EVENTS_BY_HOUR = gql`
  query QueryContractEventsByHour($contractId: String!, $memberId: Int!) {
    dash_get_contract_events_by_hour(
      args: { contract_id_param: $contractId, member_id_param: $memberId }
    ) {
      hour
    }
  }
`;

export const GET_LATEST_CONTRACT_EVENT_IN_HOUR = gql`
  query QueryLatestContractEventInHour(
    $contractId: String!
    $memberId: Int!
    $timestampHour: timestamptz!
  ) {
    dash_contract_events(
      where: {
        contract_id: { _eq: $contractId }
        member_id: { _eq: $memberId }
        timestamp: { _lte: $timestampHour }
      }
      order_by: { timestamp: desc }
      limit: 1
    ) {
      timestamp
    }
  }
`;

export const QUERY_CONTRACT_HEADER_BY_IDS = gql`
  query QueryContractHeaderByIds($ids: [String!]!) {
    dash_contract_headers(where: { list_id: { _in: $ids } }) {
      list_id
      list_member_id
      contract_entities
      distributors
      type
      description
      open
      errors
      start_date
      end_date
      timestamp
      latest_timestamp
    }
  }
`;

export const GET_CONTRACT_HEADER = gql`
  query QueryContractHeader(
    $contractId: String!
    $memberId: Int!
    $timestampHour: timestamptz!
  ) {
    dash_contract_headers_history(
      where: {
        list_id: { _eq: $contractId }
        list_member_id: { _eq: $memberId }
        timestamp: { _lte: $timestampHour }
      }
      order_by: { timestamp: desc }
      limit: 1
    ) {
      contract_entities
      description
      distributors
      end_date
      errors
      list_id
      list_member_id
      metadata
      open
      resubmission_window_days
      start_date
      submission_window_days
      timestamp
      type
      revised_at
    }
  }
`;

export interface ContractPricingRow {
  memberId: string;
  id: string;
  timestamp: string;
  productId: string;
  contractId: string;
  productDescription?: string;
  pricing: Pricing[];
  externalId: string;
  errors?: ErrorCode[];
}

export interface QueryContractPricingsResult {
  dash_get_contract_pricings_by_hour?: ContractPricingRow[];
}

export interface QueryContractPricingsTotalCountResult {
  dash_get_contract_pricings_by_hour_aggregate?: {
    aggregate: {
      count: number;
    };
  };
}

export const GET_CONTRACT_PRICINGS = gql`
  query QueryContractPricings(
    $contractId: String!
    $memberId: Int!
    $limit: Int!
    $offset: Int!
    $timestampHour: timestamptz!
    $where: dash_contract_pricings_history_bool_exp!
  ) {
    dash_get_contract_pricings_by_hour(
      args: {
        contract_id: $contractId
        member_id: $memberId
        timestamp_hour: $timestampHour
      }
      where: $where
      order_by: { description: asc, product_id: desc, product_timestamp: desc }
      limit: $limit
      offset: $offset
    ) {
      contractId: contract_list_id
      memberId: contract_list_member_id
      productId: product_id
      productDescription: description
      timestamp: product_timestamp
      pricing
      errors
    }
  }
`;

export const GET_CONTRACT_PRICINGS_TOTAL_COUNT = gql`
  query QueryContractPricingsTotalCount(
    $contractId: String!
    $memberId: Int!
    $timestampHour: timestamptz!
    $where: dash_contract_pricings_history_bool_exp!
  ) {
    dash_get_contract_pricings_by_hour_aggregate(
      args: {
        contract_id: $contractId
        member_id: $memberId
        timestamp_hour: $timestampHour
      }
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

const contractCustomersFields = `
  id
  memberId: member_id
  timestamp
  names
  startDate: start_date
  endDate: end_date
  addresses
  identifiers
  errors
  relationships
`;

export const GET_CONTRACT_CUSTOMERS = gql`
  query QueryContractCustomers(
    $contractId: String!
    $memberId: Int!
    $timestampHour: timestamptz!
    $limit: Int!
    $offset: Int!
    $where: dash_customers_history_bool_exp!
    $order: [dash_customers_history_order_by!]
  ) {
    dash_get_contract_customers(
      args: {
        contract_id_param: $contractId
        member_id_param: $memberId
        timestamp_hour: $timestampHour
      }
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order
    ) {
      ${contractCustomersFields}
    }
  }
`;

export const GET_CONTRACT_CUSTOMERS_LATEST = gql`
  query QueryContractCustomersLatest(
    $limit: Int!
    $offset: Int!
    $where: dash_customers_bool_exp!
    $order: [dash_customers_order_by!]
  ) {
    dash_get_contract_customers: dash_customers(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order
    ) {
      ${contractCustomersFields}
    }
  }
`;

export const GET_CONTRACT_CUSTOMERS_TOTAL_COUNT = gql`
  query QueryContractCustomerTotalCount(
    $contractId: String!
    $memberId: Int!
    $timestampHour: timestamptz!
    $where: dash_customers_history_bool_exp!
  ) {
    dash_get_contract_customers_aggregate(
      args: {
        contract_id_param: $contractId
        member_id_param: $memberId
        timestamp_hour: $timestampHour
      }
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CONTRACT_CUSTOMERS_TOTAL_COUNT_LATEST = gql`
  query QueryContractCustomerTotalCountLatest(
    $where: dash_customers_bool_exp!
  ) {
    dash_get_contract_customers_aggregate: dash_customers_aggregate(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CONTRACTS = gql`
  query QueryContracts(
    $limit: Int!
    $offset: Int!
    $where: dash_contract_headers_bool_exp!
  ) {
    dash_contract_headers(
      limit: $limit
      offset: $offset
      order_by: { latest_timestamp: desc }
      where: $where
    ) {
      list_id
      list_member_id
      contract_entities
      distributors
      type
      description
      open
      has_errors
      errors
      start_date
      end_date
      timestamp
      latest_timestamp
      pricings(
        distinct_on: product_id
        order_by: [{ product_id: desc }, { product_timestamp: desc }]
        limit: 100
      ) {
        product_id
        has_errors
        errors
      }
    }
  }
`;

export const GET_CONTRACTS_TOTAL_COUNT = gql`
  query QueryContractsTotalCount($where: dash_contract_headers_bool_exp!) {
    dash_contract_headers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export interface GetLatestProductByIdAndContractIdResult {
  dash_contract_pricings: {
    description: string;
  }[];
}

export const GET_LATEST_PRODUCT_BY_ID_AND_CONTRACT_ID = gql`
  query GetLatestProductByIdAndContractId(
    $productId: String
    $memberId: Int
    $contractId: String
  ) {
    dash_contract_pricings(
      where: {
        product_id: { _eq: $productId }
        contract_list_member_id: { _eq: $memberId }
        contract_list_id: { _eq: $contractId }
      }
      limit: 1
      order_by: { product_timestamp: desc_nulls_last }
    ) {
      description
    }
  }
`;
