// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';

// Styles
import styles from './MultipleValues.module.css';
const cx = classnames.bind(styles);

export interface Props {
  value?: string | null;
  valueLength: number;
}

const MultipleValues: FC<Props> = (props) => {
  const { value, valueLength } = props;

  return (
    <div data-testid='multipleValues' className={cx('value', 'row')}>
      <span className={cx('ellipsis')}>{value}</span>
      <span>{`, +${valueLength} more`}</span>
    </div>
  );
};

export default MultipleValues;
