// External
import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

// Internal
import { useTradingPartnerType } from 'hooks';
import Contracts from 'pages/Contracts/List/components/Contracts';
import {
  GET_CUSTOMER_LIST_CONTRACTS,
  GET_CUSTOMER_LIST_CONTRACTS_TOTAL_COUNT,
  GET_TRADING_PARTNERS,
  QueryTradingPartners,
  QueryCustomerListContractsResult,
  QueryCustomerListContractsTotalCountResult,
} from 'query';
import { IconSystemError } from 'styles/images';
import { NUMBER_PER_PAGE, SHORT_POLL_INTERVAL } from 'common/constants';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { toMediledgerListId } from 'common/helpers/mlIdUtils';
import {
  CurrentPageParamConfig,
  FilterStatusParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';

// Styles
import styles from './container.module.css';
import { getWhereClauseForCustomerList } from './service';

const cx = classnames.bind(styles);

interface CustomerContractsContainerProps {
  customerListId: string | number;
  memberId: string | number;
  listStartDate: string;
  listEndDate: string;
}

export const CustomerContractsContainer: FC<CustomerContractsContainerProps> = (
  props
) => {
  const { customerListId, memberId, listStartDate, listEndDate } = props;

  const { currentSearch } = usePersistedSearchParams(
    {
      module: `customer-lists/${customerListId}`,
      params: [
        SearchParamConfig,
        CurrentPageParamConfig,
        FilterStatusParamConfig,
      ],
    },
    SessionStorage
  );
  const [searchText, updateSearchText] = currentSearch[SearchParamConfig.key];
  const [currentPage, paginationToggle] =
    currentSearch[CurrentPageParamConfig.key];
  const [filterStatus, updateFilterStatus] =
    currentSearch[FilterStatusParamConfig.key];

  const whereClause = getWhereClauseForCustomerList(
    filterStatus,
    searchText,
    toMediledgerListId(`${customerListId}`, +memberId),
    listStartDate,
    listEndDate
  );

  const [order] = useState({ timestamp: 'desc' });

  const tpType = useTradingPartnerType();

  const {
    data = {},
    error,
    loading,
  } = useQuery<QueryCustomerListContractsResult>(GET_CUSTOMER_LIST_CONTRACTS, {
    variables: {
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      where: whereClause,
      order,
    },
    pollInterval: SHORT_POLL_INTERVAL,
  });

  const { data: countData = {}, loading: countLoading } =
    useQuery<QueryCustomerListContractsTotalCountResult>(
      GET_CUSTOMER_LIST_CONTRACTS_TOTAL_COUNT,
      {
        variables: {
          fromId: toMediledgerListId(`${customerListId}`, +memberId),
          where: whereClause,
        },
        pollInterval: SHORT_POLL_INTERVAL,
      }
    );

  const { data: tp = {}, loading: tradingPartnerLoading } =
    useQuery<QueryTradingPartners>(GET_TRADING_PARTNERS, {
      variables: { id: {} },
    });

  const tradingPartners = get(tp, 'tradingPartners', []) || [];

  const contracts = data?.dash_contract_headers || [];
  const totalCount =
    countData?.dash_contract_headers_aggregate?.aggregate.count || 0;

  return error ? (
    <div className={cx('errorCode')}>
      <IconSystemError />
    </div>
  ) : (
    <Contracts
      uploadContractHeader={() => {}}
      isUploadButtonVisible={false}
      isCreateButtonVisible={false}
      contracts={contracts}
      tradingPartners={tradingPartners}
      countLoading={countLoading || tradingPartnerLoading}
      currentPage={currentPage}
      statusFilter={filterStatus}
      loading={loading}
      paginationToggle={paginationToggle}
      searchText={searchText}
      totalCount={totalCount}
      updateFilterStatus={(newStatus) => {
        updateFilterStatus(newStatus);
        paginationToggle(1);
      }}
      updateSearchText={(text: string) => {
        updateSearchText(text);
        paginationToggle(1);
      }}
      tpType={tpType}
    />
  );
};
