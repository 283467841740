// Vendor
import React, { FC } from 'react';

const IconPlus: FC = () => (
  <svg
    width='12'
    height='11'
    viewBox='0 0 12 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.8125 4.875H6.875V0.9375C6.875 0.71875 6.65625 0.5 6.4375 0.5H5.5625C5.31641 0.5 5.125 0.71875 5.125 0.9375V4.875H1.1875C0.941406 4.875 0.75 5.09375 0.75 5.3125V6.1875C0.75 6.43359 0.941406 6.625 1.1875 6.625H5.125V10.5625C5.125 10.8086 5.31641 11 5.5625 11H6.4375C6.65625 11 6.875 10.8086 6.875 10.5625V6.625H10.8125C11.0312 6.625 11.25 6.43359 11.25 6.1875V5.3125C11.25 5.09375 11.0312 4.875 10.8125 4.875Z'
      fill='#525F7F'
    />
  </svg>
);

export default IconPlus;
