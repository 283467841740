// Internal
import { prepareDate } from '../adapter-utils';
import {
  convertEndDateToUtcTimestamp,
  convertStartDateToUtcTimestamp,
} from '../../../common/helpers';
import { RosterAdapter } from '../roster-adapter';
import {
  ContractPricingInputData,
  ContractPricingRoster,
  RawContractPricing,
} from './contract-pricing-roster';

class ContractPricingAdapter
  implements
    RosterAdapter<
      ContractPricingRoster,
      ContractPricingInputData,
      RawContractPricing
    >
{
  convert = (
    row: ContractPricingRoster,
    data: ContractPricingInputData
  ): RawContractPricing => {
    const {
      id,
      contractPrice,
      fetInclusiveContractPrice,
      priceType,
      pricingWac,
      pricingEndDate,
      pricingStartDate,
      fet,
      productId,
      unitOfMeasure,
      productDescription,
      productType,
    } = row;
    const { timestamp, contractId } = data;
    const fetInclusiveContractPriceV = fetInclusiveContractPrice
      ? fetInclusiveContractPrice?.toUpperCase() === 'TRUE'
      : undefined;
    const processedStartDate = prepareDate(
      pricingStartDate,
      convertStartDateToUtcTimestamp
    );
    const processedEndDate = prepareDate(
      pricingEndDate,
      convertEndDateToUtcTimestamp
    );
    const pricing = [
      {
        contractPrice: contractPrice ?? undefined,
        fet: fet ?? undefined,
        fetInclusiveContractPrice: fetInclusiveContractPriceV,
        wac: pricingWac ?? undefined,
        priceType,
        unitOfMeasure: unitOfMeasure ?? undefined,
        startDate: processedStartDate,
        endDate: processedEndDate,
      },
    ];
    const productIdV = `${productType}:${productId}`;

    return {
      id,
      productId: productIdV,
      productDescription: productDescription ?? undefined,
      pricing,
      contractId,
      timestamp,
    };
  };
}

export default new ContractPricingAdapter();
