// Internal
import { customerListPath } from 'common/helpers';
import {
  DESCRIPTION_COLUMN,
  END_DATE_COLUMN,
  NAME_COLUMN,
  START_DATE_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';
import { TableColumn } from 'components/Table';

export const COLUMNS: TableColumn[] = [
  {
    headerName: 'CUSTOMER LIST ID',
    type: 'link',
    size: 'small',
    displayTextFn: (data: any) => data.id,
    urlFn: (data: any) => customerListPath(data.id),
  },
  NAME_COLUMN,
  DESCRIPTION_COLUMN,
  START_DATE_COLUMN,
  END_DATE_COLUMN,
  UPDATED_AT_COLUMN,
];
