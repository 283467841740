export default (theme) => ({
  root: {},
  appBarClose: {
    marginLeft: 56,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${56}px)`,
  },
  appBarOpen: {
    marginLeft: 256,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${256}px)`,
  },
  button: {
    padding: '0 !important',
  },
  label: {
    flexDirection: 'column',
    left: '-1px',
    position: 'relative',
  },
});
