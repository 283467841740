// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { TableCell } from '@material-ui/core';
import { Size } from '@material-ui/core/Table/Table';

// Styles
import styles from './index.module.css';
import Tooltip from '../Tooltips/Tooltip';

const cx = classnames.bind(styles);

export interface Props {
  text?: string;
  size?: Size;
}

const Header: FC<Props> = (props) => {
  const { text, size = 'small' } = props;

  return (
    <TableCell className={cx('head')} size={size}>
      <Tooltip title={text}>{text}</Tooltip>
    </TableCell>
  );
};

export default Header;
