// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { DialogContent } from '@material-ui/core';

// Internal
import { Button, Dialog } from 'components';

// Styles
import styles from './DeleteDialog.module.css';
const cx = classnames.bind(styles);

interface Props {
  deleteDocument: () => void;
  deletePayload: {
    documentType: string;
    manufacturer: string;
  };
  toggleDialog: (val: boolean) => void;
}

const DeleteDialog: FC<Props> = ({
  deleteDocument,
  deletePayload,
  toggleDialog,
}) => {
  const { documentType, manufacturer } = deletePayload;

  return (
    <Dialog title='Remove Document' toggleDialog={() => toggleDialog(false)}>
      <DialogContent>
        <div className={cx('text')}>
          Are you sure you want to remove{' '}
          <b>
            {manufacturer}&apos;s {documentType}
          </b>
          ?
        </div>
        <div className={cx('buttonContainer')}>
          <Button
            color='secondary'
            dataTestId='noButton'
            label='No'
            onClick={() => {
              toggleDialog(false);
            }}
            style={{ width: 120 }}
            variant='outlined'
          />
          <Button
            dataTestId='yesButton'
            label='Yes'
            onClick={() => {
              deleteDocument();
              toggleDialog(false);
            }}
            style={{
              border: 'none',
              marginLeft: 8,
              padding: 0,
              width: 120,
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog;
