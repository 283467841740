import { constant, pipe } from 'fp-ts/function';
import {
  getApplicativeValidation,
  left,
  map as mapE,
  mapLeft,
  right,
} from 'fp-ts/Either';
import { getSemigroup, map as mapNA } from 'fp-ts/NonEmptyArray';
import { sequenceT } from 'fp-ts/Apply';
import { ContractHeaderRoster } from 'flatfile-import/domain/contract-header/contract-header-roster';

import {
  asNonRequiredFieldValidation,
  FieldValidation,
  requiredField,
  Validated,
  validateDate,
} from '../validation';
import { RosterValidator, RowError } from '../../../domain/validator';
import { SchemaWithCustomAttributes } from '../../../domain/schema';

export const validateRequiredField = <E extends SchemaWithCustomAttributes>(
  row: E,
  key: keyof E
): Validated<RowError, E> => {
  const field = row[key];
  return pipe(
    requiredField(field),
    mapE(constant(row)),
    mapLeft(mapNA((message) => ({ field: key as string, message })))
  );
};

export const validateId: FieldValidation<ContractHeaderRoster> = (row) =>
  row.id.trim().length > 0
    ? right(row)
    : left([{ field: 'id', message: 'Field cannot be empty' }]);

export const validateDescription: FieldValidation<ContractHeaderRoster> = (
  row
) =>
  row.description.trim().length > 0
    ? right(row)
    : left([{ field: 'description', message: 'Field cannot be empty' }]);

export const validateStartDate: FieldValidation<ContractHeaderRoster> = (
  row
) => {
  const nonRequiredDate = asNonRequiredFieldValidation<
    string,
    ContractHeaderRoster
  >(row.startDate, right(row));
  return nonRequiredDate((date) =>
    pipe(
      validateDate(date),
      mapE(constant(row)),
      mapLeft(mapNA((message) => ({ field: 'startDate', message })))
    )
  );
};

export const validateEndDate: FieldValidation<ContractHeaderRoster> = (row) => {
  const { endDate } = row;
  const nonRequiredDate = asNonRequiredFieldValidation<
    string,
    ContractHeaderRoster
  >(endDate, right(row));
  return nonRequiredDate((date) =>
    pipe(
      validateDate(date),
      mapE(constant(row)),
      mapLeft(mapNA((message) => ({ field: 'endDate', message })))
    )
  );
};

class ContractHeaderValidationService
  implements RosterValidator<ContractHeaderRoster>
{
  validate(row: ContractHeaderRoster) {
    // Applicative instance with semigroup for collecting errors.
    const nonEmptyArrayAp = getApplicativeValidation(getSemigroup<RowError>());
    return pipe(
      // Sequence will create the placeholder curried function and will apply the ap for each Either instance.
      sequenceT(nonEmptyArrayAp)(
        validateId(row),
        validateDescription(row),
        validateStartDate(row),
        validateEndDate(row)
      ),
      mapE(() => row)
    );
  }
}

export default new ContractHeaderValidationService();
