import { UpsertCustomer } from 'types/generated/UpsertCustomer';
import { ApiService } from '../../../actions';
import { HttpPublisher } from '../http-publisher';

export class HttpCustomerPublisher extends HttpPublisher<UpsertCustomer> {
  apiService: ApiService;

  path: string;

  constructor(apiService: ApiService, isOutOfNetwork: boolean) {
    const path = `/v2/customer-management/upsert-${
      isOutOfNetwork ? 'oon-' : ''
    }customer`;
    super(apiService, path);
    this.path = path;
    this.apiService = apiService;
  }
}
