// Vendor
import React from 'react';
import classnames from 'classnames/bind';

// Styles
import styles from './index.module.css';
const cx = classnames.bind(styles);

const HistoryView = () => (
  <div className={cx('paperMargin', 'root')}>
    <p>Historical View</p>
  </div>
);

export default HistoryView;
