import React, { FC, useEffect } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

import * as R from 'fp-ts/Reader';
import { getToken } from 'actions/common/utils';
import { createApolloClient } from '../../query/client';
import themeOptions from '../../styles/theme';
import {
  ConfigError,
  ConfigState,
  DashboardConfig,
  getConfigErrorMessage,
} from '../config';
import stateReducer, { RootState } from '../../reducers';
import * as service from '../../actions';
import Root from '../../Root';

export interface AppDependencies {
  store: any;
  apolloClient: any;
  themeOptions: any;
  appService: any;
}

export function initialState(config: DashboardConfig): Partial<RootState> {
  return {
    service: service.create(config),
    config: { dashboard: config },
  };
}

export function initializeAppDependencies(
  config: DashboardConfig
): AppDependencies {
  const store = createStore(
    stateReducer,
    initialState(config),
    composeWithDevTools(applyMiddleware(thunk))
  );
  const appService = store.getState().service as service.AppService;
  const apolloClient = createApolloClient(getToken);
  return {
    appService,
    apolloClient,
    store,
    themeOptions,
  };
}

export const AppErrorComponent: FC<{ error: string }> = ({ error }) => (
  <div>{error}</div>
);
export const AppLoadingComponent: FC<{}> = () => <div>Loading...</div>;
export const AppComponent: FC<AppDependencies> = ({
  store,
  apolloClient,
  themeOptions,
  appService,
}) => {
  useEffect(() => {
    appService.initializeAction(apolloClient, store.dispatch);
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <MuiThemeProvider theme={createTheme(themeOptions)}>
          <Root />
        </MuiThemeProvider>
      </ApolloProvider>
    </Provider>
  );
};

export interface GenAppDependencies {
  App: FC<AppDependencies>;
  AppError: FC<{ error: string }>;
  AppLoading: FC<{}>;
}

export const generateApp = (
  cfg: O.Option<ConfigState>
): R.Reader<GenAppDependencies, FC> =>
  pipe(
    R.ask<GenAppDependencies>(),
    R.map(({ App, AppError, AppLoading }) =>
      pipe(
        cfg,
        O.map(
          E.fold(
            (e: ConfigError) => {
              // eslint-disable-next-line no-console
              console.error(getConfigErrorMessage(e));
              return () => (
                <AppError error='Error loading application config.' />
              );
            },
            (config: DashboardConfig) => {
              const { themeOptions, apolloClient, appService, store } =
                initializeAppDependencies(config);
              return () => (
                <App
                  store={store}
                  apolloClient={apolloClient}
                  themeOptions={themeOptions}
                  appService={appService}
                />
              );
            }
          )
        ),
        O.getOrElse(() => AppLoading)
      )
    )
  );
