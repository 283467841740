// Internal
import { TableRow } from 'components/Table';
import { truncateDatetime } from 'common/helpers';
import { QueryProductsResult, wrapSearchText } from 'query';
import { Filters } from './ProductsFilter';

export const rawDataToRows = (data: QueryProductsResult): TableRow[] =>
  data.products.map((product) => ({
    type: 'expanding',
    isExpandable: false,
    data: {
      id: product.id,
      externalId: product.external_id,
      idType: product.kind,
      description: product.description,
      uom: product.unit_of_measure,
      startDate: truncateDatetime(product.start_date),
      endDate: truncateDatetime(product.end_date),
      timestamp: product.timestamp,
    },
  }));

export function filtersToQuery(
  filters: Filters,
  filtersActive: boolean,
  searchText: string
): any {
  const conditionsArray: string | any[] = [];
  // Add the search conditions
  conditionsArray.push({
    _or: [
      { external_id: { _ilike: wrapSearchText(searchText) } },
      { description: { _ilike: wrapSearchText(searchText) } },
    ],
  });

  if (filtersActive && filters) {
    if (filters.selectedIdTypes.length > 0) {
      const idTypeConditions = filters?.selectedIdTypes.map((t) => ({
        kind: { _eq: t },
      }));
      if (idTypeConditions && idTypeConditions.length > 0) {
        conditionsArray.push({
          _or: idTypeConditions,
        });
      }
    } else {
      // Return nothing if no id types are selected
      conditionsArray.push({ id: { _is_null: true } });
    }
  }

  return { _and: conditionsArray };
}
