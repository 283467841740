import gql from 'graphql-tag';
import { Relationship } from 'types/generated/Relationship';
import { ProductPrice } from 'types/generated/Product';

export interface Product {
  id: string;
  external_id: string;
  member_id: string;
  timestamp: string;
  description: string;
  end_date: string;
  kind: string;
  prices: ProductPrice[];
  relationships: Relationship[];
  revisedAt: string;
  start_date: string;
  unit_of_measure: UnitOfMeasureType;
  revised_at?: string;
  active?: any;
}

export type UnitOfMeasureType = 'Package' | 'Each' | 'Unit' | 'Case' | 'Vial';

export interface GetProductsForListResult {
  products: {
    product: Product;
  }[];
}

export interface GetProductsForListCountResult {
  productCount?: {
    aggregate: {
      count: number;
    };
  };
}

export const GET_PRODUCTS_FOR_LIST = gql`
  query getProductsForList(
    $where: dash_associations_bool_exp!
    $limit: Int
    $offset: Int
    $distinct: [dash_associations_select_column]
    $order: [dash_associations_order_by]
  ) {
    products: dash_associations(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order
      distinct_on: $distinct
    ) {
      product {
        description
        end_date
        kind
        id
        external_id
        member_id
        prices
        relationships
        revised_at
        start_date
        timestamp
        unit_of_measure
      }
    }
  }
`;

export const GET_PRODUCTS_FOR_LIST_COUNT = gql`
  query getProductsForListCount($where: dash_associations_bool_exp!) {
    productCount: dash_associations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export interface QueryProductsResult {
  products: Product[];
}

export interface QueryProductResult {
  product: Product;
}

export const QUERY_PRODUCTS = gql`
  query QueryProducts(
    $limit: Int
    $offset: Int
    $where: dash_products_bool_exp
  ) {
    products: dash_products(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { kind: asc, external_id: asc }
    ) {
      id
      external_id
      prices
      start_date
      end_date
      timestamp
      description
      unit_of_measure
      kind
      relationships
      member_id
    }
  }
`;

export const QUERY_PRODUCT_BY_ID = gql`
  query QueryProductById($id: String!, $memberId: Int!) {
    product: dash_products_by_pk(id: $id, member_id: $memberId) {
      id
      external_id
      prices
      start_date
      end_date
      timestamp
      description
      unit_of_measure
      kind
      revised_at
      relationships
    }
  }
`;

export interface QueryProductCountResult {
  productsCount?: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_PRODUCTS_COUNT = gql`
  query QueryProductsCount($where: dash_products_bool_exp!) {
    productsCount: dash_products_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
