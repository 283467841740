// Vendor
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Dictionary, first, startCase } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Internal
import {
  contractPath,
  decorateAndPartitionChargebackErrorsBySeverity,
  formatCurrency,
  truncateDatetime,
} from 'common/helpers';
import { getChargebackStatusForRow } from 'pages/Chargebacks/List/service';
import {
  ChargebackRequestRow,
  CreditMemoRow,
  OverrideAudit,
  TradingPartner,
} from 'query';
import { NodeType } from 'common/config';
import { RevisionLookup } from 'types/generated/RevisionLookup';
import RejectionReasons from '../RejectionReasons';
import ClaimHistory, { Action } from '../ClaimHistory';
import {
  ApproveButton,
  getHistory,
  getIcon,
  RejectButton,
  renderCardData,
  renderField,
  renderParagraphSection,
} from '../helpers';
import { ModalKind } from '../../constants';

// Styles
import styles from '../chargeback.module.css';

const cx = classnames.bind(styles);

export interface Props {
  chargeback: ChargebackRequestRow;
  isLatestInThread: boolean;
  tradingPartners: Dictionary<TradingPartner>;
  associatedCreditNote?: CreditMemoRow;
  tpType: NodeType;
  showModal: React.Dispatch<React.SetStateAction<ModalKind>>;
  setShowAuditPreview: React.Dispatch<React.SetStateAction<boolean>>;
  setRequest: React.Dispatch<React.SetStateAction<RevisionLookup | undefined>>;
}

const Chargeback: FC<Props> = ({
  chargeback,
  isLatestInThread,
  tradingPartners,
  associatedCreditNote,
  tpType,
  showModal,
  setShowAuditPreview,
  setRequest,
}) => {
  const lastOverrideAuditAction = first(chargeback.override_audit ?? []);

  // Override
  const isLatestActionOverrideRequest =
    lastOverrideAuditAction?.operation === 'override-request';
  // Audit
  const isLatestActionAuditRequest =
    lastOverrideAuditAction?.operation === 'audit-request';

  const { status } = getChargebackStatusForRow(chargeback);

  const renderChargeback = (cbk: ChargebackRequestRow) => {
    // Details if original, return, cross-pay, credit, or rebill
    const type = startCase(cbk.operation);

    // Menu controls
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(
      null
    );

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setMenuAnchor(null);
    };

    const renderMenu = () => {
      const shouldShowMenu =
        (tpType === NodeType.DIST &&
          isLatestInThread &&
          status === 'Rejected') ||
        (tpType === NodeType.MFR && isLatestInThread && status === 'Approved');
      if (!shouldShowMenu) {
        return null;
      }
      return (
        <>
          <IconButton
            color='default'
            aria-label='more'
            aria-controls={`menu-${cbk.id}`}
            aria-haspopup='true'
            data-testid='overrideButton'
            component='span'
            onClick={handleOpenMenu}
            style={{ marginLeft: 'auto' }}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id={`menu-${cbk.id}`}
            data-testid='chargeback-menu'
            anchorEl={menuAnchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleCloseMenu}
          >
            {tpType === NodeType.DIST && (
              // DIST menu items
              <MenuItem
                onClick={() => {
                  showModal('override-request');
                  handleCloseMenu();
                }}
              >
                Override
              </MenuItem>
            )}
            {tpType === NodeType.MFR && (
              // MFR menu items
              <MenuItem
                onClick={() => {
                  setShowAuditPreview(true);
                  handleCloseMenu();
                }}
              >
                Adjust
              </MenuItem>
            )}
          </Menu>
        </>
      );
    };

    return (
      <div data-testid={`${cbk.operation}:${cbk.id}`}>
        <div className={cx('row', 'spaceBetween')}>
          <div className={cx('row')} style={{ width: '100%' }}>
            <div
              className={cx(
                'iconBackground',
                { greenOpacity: green },
                { redOpacity: red },
                { blueOpacity: blue },
                { yellowOpacity: yellow }
              )}
              data-testid='chargebackIcon'
            >
              {getIcon(status, type)}
            </div>
            <header className={cx('header')}>
              {type} {cbk.id}
            </header>
            {renderMenu()}
          </div>
        </div>
        <div className={cx('leftMargin', 'row')}>
          {renderField('Invoice ID', cbk.sale.id)}
          {renderField('Debit Memo', cbk.debit_memo_id)}
          {renderField('Submission Date', truncateDatetime(cbk.timestamp))}
        </div>
        <div className={cx('leftMargin', 'row')}>
          {renderField(
            'Contract ID',
            cbk.contract_id,
            false,
            true,
            contractPath(cbk.contract_member_id ?? 0, cbk.contract_id ?? '')
          )}
          {renderField('Quantity', `${cbk.sale.quantity}`)}
          {renderField('UoM', cbk.sale.unit_of_measure)}
        </div>
        <div className={cx('leftMargin', 'row')}>
          {renderField('WAC Price', formatCurrency(cbk.wac_price))}
          {renderField('Contract Price', formatCurrency(cbk.contract_price))}
          {renderField('Claim Amount', formatCurrency(cbk.submitted_amount))}
        </div>
      </div>
    );
  };

  const { severityErrors, severityWarnings } =
    decorateAndPartitionChargebackErrorsBySeverity(chargeback, tradingPartners);
  const errors = severityErrors.concat(severityWarnings);

  const history: Action[] = getHistory(chargeback);

  const green = status === 'Approved';
  const red = status === 'Rejected';
  const blue = status === 'Processing';
  const yellow = status === 'Pending Override';

  const renderResponseButtons = () => {
    const shouldShowButtons =
      isLatestInThread &&
      ((isLatestActionOverrideRequest && tpType === NodeType.MFR) ||
        (isLatestActionAuditRequest && tpType === NodeType.DIST));
    if (!shouldShowButtons) {
      return null;
    }

    return (
      <div className={cx('paragraph')}>
        <ApproveButton
          data-testid='approve_button'
          variant='contained'
          color='primary'
          onClick={() => {
            setRequest({
              id: lastOverrideAuditAction.id,
              memberId: `ml:member:${lastOverrideAuditAction.member_id}`,
              timestamp: lastOverrideAuditAction.timestamp,
            });
            if (tpType === NodeType.MFR) {
              showModal('override-approved');
            } else if (tpType === NodeType.DIST) {
              showModal('audit-approved');
            }
          }}
        >
          Approve
        </ApproveButton>
        <RejectButton
          data-testid='reject_button'
          variant='contained'
          color='primary'
          onClick={() => {
            setRequest({
              id: lastOverrideAuditAction.id,
              memberId: `ml:member:${lastOverrideAuditAction.member_id}`,
              timestamp: lastOverrideAuditAction.timestamp,
            });
            if (tpType === NodeType.MFR) {
              showModal('override-rejected');
            } else if (tpType === NodeType.DIST) {
              showModal('audit-rejected');
            }
          }}
        >
          Reject
        </RejectButton>
      </div>
    );
  };

  const getHeaderForOverrideAudit = (entry: OverrideAudit) => {
    if (entry.operation === 'override-request') {
      return 'Override request note';
    } else if (entry.operation === 'override-result') {
      return 'Override response note';
    } else if (entry.operation === 'audit-request') {
      return 'Audit request note';
    } else if (entry.operation === 'audit-reply') {
      return 'Audit reply note';
    }
    return '';
  };

  const getNoteFromOverrideAudit = (entry: OverrideAudit) => {
    if (
      entry.operation === 'override-request' ||
      entry.operation === 'audit-request'
    ) {
      return entry.data.requestNote ?? '(empty)';
    } else if (
      entry.operation === 'override-result' ||
      entry.operation === 'audit-reply'
    ) {
      return entry.data.responseNote ?? '(empty)';
    }
    return '';
  };

  return (
    <div
      className={cx('row')}
      data-testid={`chargeback:${chargeback.id}:${status}`}
    >
      <div
        className={cx(
          'card',
          'column',
          { green },
          { red },
          { blue },
          { yellow }
        )}
        data-testid='chargebackCard'
      >
        {renderField('Adjudication Results', status, true)}
        {renderCardData(status, associatedCreditNote)}
      </div>
      <div
        className={cx('column', 'container')}
        data-testid='chargebackDetails'
      >
        {renderChargeback(chargeback)}
        {chargeback.operation !== 'credit' &&
          status === 'Rejected' &&
          errors.length > 0 && (
            <div className={cx('leftMargin', 'row', 'divider')}>
              <RejectionReasons errors={errors} />
            </div>
          )}
        {chargeback.override_audit &&
          chargeback.override_audit
            .filter((entry) => !!getHeaderForOverrideAudit(entry))
            .map((entry, idx) =>
              renderParagraphSection(
                entry.id,
                getNoteFromOverrideAudit(entry),
                getHeaderForOverrideAudit(entry),
                idx === 0 ? renderResponseButtons() : null
              )
            )}
        <div className={cx('leftMargin', 'row', 'divider')}>
          <ClaimHistory actions={history} />
        </div>
      </div>
    </div>
  );
};

export default Chargeback;
