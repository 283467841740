// Vendor
import React, { FC } from 'react';

// Internal
import { Button } from 'components';

interface Props {
  onSsoSignin: (e: React.FormEvent<HTMLFormElement>) => void;
}

const SsoLogin: FC<Props> = ({ onSsoSignin }) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      onSsoSignin(e);
    }}
  >
    <Button
      dataTestId='signinButton'
      label='Sign In With SSO'
      style={{ whiteSpace: 'nowrap' }}
      type='submit'
    />
  </form>
);

export default SsoLogin;
