import { SchemaWithCustomAttributes } from '../schema';

const customerRequiredFields = ['id', 'name'] as const;
type keysBaseRequiredFields = typeof customerRequiredFields[number];

export const baseCustomerFields = [
  'startDate',
  'endDate',
  'dea',
  'hin',
  '340b',
  'address1',
  'address2',
  'address3',
  'city',
  'state',
  'zipCode',
  'cot',
  'parentId',
  'name2',
] as const;
type BaseCustomerFieldsKeys = typeof baseCustomerFields[number];

const localCustomerFields = [
  'listId',
  'listStartDate',
  'listEndDate',
  'programId',
  'programStartDate',
  'programEndDate',
] as const;
type LocalCustomerFieldsKeys = typeof localCustomerFields[number];

type BaseCustomerRosterSchema = {
  [key in keysBaseRequiredFields]: string;
};

export type CustomerRosterSchema = {
  [key in BaseCustomerFieldsKeys]?: string;
} & BaseCustomerRosterSchema;

export type OonCustomerRosterSchema = CustomerRosterSchema &
  SchemaWithCustomAttributes;

export type LocalCustomerRosterSchema = {
  [key in LocalCustomerFieldsKeys]?: string;
} & CustomerRosterSchema &
  SchemaWithCustomAttributes;

export const LOCAL_CUSTOMER_BASE_FIELDS = customerRequiredFields
  .map((key) => key.toString())
  .concat(baseCustomerFields.map((key) => key.toString()))
  .concat(localCustomerFields.map((key) => key.toString()));

export const OON_CUSTOMER_BASE_FIELDS = customerRequiredFields
  .map((key) => key.toString())
  .concat(baseCustomerFields.map((key) => key.toString()));
