// Vendor
import { DragEvent } from 'react';
import { Position, Node } from 'reactflow';

// Internal
import { DocumentType } from 'common/config';
import Line from './components/Line';
import { CustomNode, DefaultNode } from './components';
import { AttributesType, NodeTypes } from './types';

const initBgColor: string = '#1A192B';
export const mainColor: string = '#017EFA';

const initialNodes: Node[] = [
  {
    data: {
      type: 'source',
      position: Position.Right,
      color: initBgColor,
      name: 'Start',
      nodeType: NodeTypes.RULE_SET,
      style: {
        background: '#0B1641',
        color: '#FFFFFF',
        fontSize: 14,
        width: 116,
      },
      nodes: [],
      edges: [],
    },
    id: 'default_0',
    position: { x: 1, y: 5 },
    type: 'defaults',
  },
  {
    data: {
      type: 'target',
      position: Position.Left,
      color: initBgColor,
      name: 'Generate proposal',
      nodeType: NodeTypes.RULE_SET,
      style: {
        background: '#017EFA',
        color: '#FFFFFF',
        fontSize: 14,
        width: 207,
      },
      nodes: [],
      edges: [],
    },
    id: 'default_1',
    position: { x: 920.5, y: 371 },
    type: 'defaults',
  },
];
export const startGenerateNodes: Node[] = [initialNodes[0], initialNodes[1]];

export const nodeTypes = {
  defaults: DefaultNode,
  ruleSet: CustomNode,
  rule: CustomNode,
};

export const baseReactFlowData = {
  connectionLineComponent: Line,
  defaultEdgeOptions: { type: 'common-edge-options' },
  defaultViewport: { x: 50, y: 150, zoom: 0.8 },
  deleteKeyCode: ['Backspace'],
  onDragOver: (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  },
  proOptions: { hideAttribution: true },
  zoomActivationKeyCode: 'z',
  zoomOnScroll: false,
};

export const customerTypes: {
  label: string;
  name: string;
}[] = [
  {
    label: '340B Customer',
    name: '340bCustomer#root',
  },
  {
    label: 'DEA Customer',
    name: 'deaCustomer#root',
  },
  {
    label: 'GPO Customer',
    name: 'gpoCustomer#root',
  },
  {
    label: 'HIBCC Customer',
    name: 'hibccCustomer#root',
  },
  {
    label: 'Internal Customer',
    name: 'internalCustomer#root',
  },
  {
    label: 'Proposed Customer',
    name: 'proposedCustomer#root',
  },
  {
    label: 'Updated Customer',
    name: 'updatedCustomer#root',
  },
];

export const ruleSets = [
  'Basic Data',
  'Identifiers',
  'Class of Trade',
  'Program',
  'Documents',
  'GPO Affiliation',
  'Attributes',
];

const addressSubfields = [
  {
    inputType: 'array',
    label: 'No Subfield',
    name: 'none#empty',
  },
  {
    inputType: 'string',
    label: 'Address 1',
    name: 'address1#string',
  },
  {
    inputType: 'string',
    label: 'Address 2',
    name: 'address2#string',
  },
  {
    inputType: 'string',
    label: 'Address 3',
    name: 'address3#string',
  },
  {
    inputType: 'string',
    label: 'City',
    name: 'city#string',
  },
  {
    inputType: 'string',
    label: 'State',
    name: 'state#string',
  },
  {
    inputType: 'string',
    label: 'Zip Code',
    name: 'zipCode#string',
  },
  {
    inputType: 'date',
    label: 'Start Date',
    name: 'startDate#date',
  },
  {
    inputType: 'date',
    label: 'End Date',
    name: 'endDate#date',
  },
];

export const addressField = {
  label: 'Address',
  name: 'addresses#array',
  customerTypes: [],
  ruleSets: ['Basic Data'],
  attributes: [
    {
      inputType: 'string',
      label: 'Primary',
      name: 'primary#string',
      attributes: addressSubfields,
    },
    {
      inputType: 'string',
      label: 'Ship To',
      name: 'shipTo#string',
      attributes: addressSubfields,
    },
    {
      inputType: 'string',
      label: 'Bill To',
      name: 'billTo#string',
      attributes: addressSubfields,
    },
    {
      inputType: 'string',
      label: 'Mail To',
      name: 'mailTo#string',
      attributes: addressSubfields,
    },
  ],
};

export const classOfTradeField = {
  label: 'Class of Trade',
  name: 'classesOfTrade#array',
  customerTypes: [],
  ruleSets: [],
  attributes: [
    {
      inputType: 'array',
      label: 'No Subfield',
      name: 'none#empty',
    },
    {
      inputType: 'string',
      label: 'ID',
      name: 'classOfTradeId#string',
    },
    {
      inputType: 'date',
      label: 'Start Date',
      name: 'startDate#date',
    },
    {
      inputType: 'date',
      label: 'End Date',
      name: 'endDate#date',
    },
  ],
};

export const contractField = {
  label: 'Contract',
  name: 'contracts#array',
  customerTypes: [],
  ruleSets: [],
  attributes: [
    {
      inputType: 'array',
      label: 'No Subfield',
      name: 'none#empty',
    },
    {
      inputType: 'string',
      label: 'Value',
      name: 'value#string',
    },
    {
      inputType: 'date',
      label: 'Start Date',
      name: 'startDate#date',
    },
    {
      inputType: 'date',
      label: 'End Date',
      name: 'endDate#date',
    },
  ],
};

const programField = {
  label: 'Program',
  name: 'programs#array',
  customerTypes: [],
  ruleSets: [],
  attributes: [
    {
      inputType: 'array',
      label: 'No Subfield',
      name: 'none#empty',
    },
    {
      inputType: 'string',
      label: 'Program ID',
      name: 'listId#string',
    },
    {
      inputType: 'date',
      label: 'Start Date',
      name: 'startDate#date',
    },
    {
      inputType: 'date',
      label: 'End Date',
      name: 'endDate#date',
    },
  ],
};

const customerAttributesField = {
  label: 'Attributes',
  name: 'attributes#array',
  customerTypes: [],
  ruleSets: [],
  attributes: [
    {
      label: 'Key',
      name: 'key#key',
      attributes: [
        {
          inputType: 'array',
          label: 'Value',
          name: 'value#string',
        },
        {
          inputType: 'date',
          label: 'Start Date',
          name: 'startDate#date',
        },
        {
          inputType: 'date',
          label: 'End Date',
          name: 'endDate#date',
        },
      ],
    },
  ],
};

const documentsField = (documentTypes: DocumentType[]) => [
  ...(documentTypes
    ? [
        {
          label: 'Document',
          name: 'documents#array',
          customerTypes: [],
          ruleSets: [],
          attributes: documentTypes.map(({ attributes, type }) => ({
            label: type,
            name: `${type}#string`,
            attributes: attributes.map(({ fieldName, name, type }) => ({
              inputType: type,
              label: name,
              name: `${fieldName}#${type}`,
            })),
          })),
        },
      ]
    : []),
];

const startDateField = {
  inputType: 'date',
  label: 'Start Date',
  name: 'startDate#date',
  customerTypes: [],
  ruleSets: [],
};

const endDateField = {
  inputType: 'date',
  label: 'End Date',
  name: 'endDate#date',
  customerTypes: [],
  ruleSets: [],
};

export const gpoAffiliationField = {
  attributes: [
    {
      inputType: 'array',
      label: 'No Subfield',
      name: 'none#empty',
    },
    {
      inputType: 'string',
      label: 'Value',
      name: 'to#string',
    },
    {
      inputType: 'date',
      label: 'Start Date',
      name: 'startDate#date',
    },
    {
      inputType: 'date',
      label: 'End Date',
      name: 'endDate#date',
    },
  ],
  label: 'GPO Affiliation',
  name: 'gpo-affiliation#array',
  customerTypes: ['Internal Customer', 'Proposed Customer'],
  ruleSets: [],
};

export const primaryIdentifierField: AttributesType = {
  inputType: 'array',
  label: 'Primary Identifiers',
  name: 'primary-identifier#array',
  customerTypes: ['Internal Customer', 'Proposed Customer'],
  ruleSets: [],
  attributes: [
    {
      inputType: 'array',
      label: 'No Subfield',
      name: 'none#empty',
    },
    {
      inputType: 'string',
      label: 'Value',
      name: 'to#string',
    },
    {
      inputType: 'date',
      label: 'Start Date',
      name: 'startDate#date',
    },
    {
      inputType: 'date',
      label: 'End Date',
      name: 'endDate#date',
    },
  ],
};

const listField = {
  label: 'List',
  name: 'lists#array',
  customerTypes: [],
  ruleSets: [],
  attributes: [
    {
      inputType: 'array',
      label: 'No Subfield',
      name: 'none#empty',
    },
    {
      inputType: 'string',
      label: 'List ID',
      name: 'listId#string',
    },
    {
      inputType: 'date',
      label: 'Start Date',
      name: 'startDate#date',
    },
    {
      inputType: 'date',
      label: 'End Date',
      name: 'endDate#date',
    },
  ],
};

export const nameField = {
  inputType: 'string',
  label: 'Name',
  name: 'names#array',
  customerTypes: [],
  ruleSets: [],
};

const rosterField = {
  label: 'Roster',
  name: 'rosters#array',
  customerTypes: [],
  ruleSets: [],
  attributes: [
    {
      inputType: 'array',
      label: 'No Subfield',
      name: 'none#empty',
    },
    {
      inputType: 'string',
      label: 'Value',
      name: 'value#string',
    },
    {
      inputType: 'date',
      label: 'Start Date',
      name: 'startDate#date',
    },
    {
      inputType: 'date',
      label: 'End Date',
      name: 'endDate#date',
    },
  ],
};

const senderField = {
  inputType: 'string',
  label: 'Sender',
  name: 'memberId#string',
  customerTypes: ['GPO Customer', 'Updated Customer'],
  ruleSets: [],
};

export const valueField = {
  inputType: 'string',
  label: 'Value',
  name: 'value#string',
  customerTypes: [],
  ruleSets: [],
};

export const allFields = (documentTypes: DocumentType[]): AttributesType[] => [
  addressField,
  customerAttributesField,
  classOfTradeField,
  contractField,
  // We need to grab the document types from additional dashboard config since they
  // are not supplied in the manage rules config
  ...documentsField(documentTypes),
  startDateField,
  endDateField,
  gpoAffiliationField,
  primaryIdentifierField,
  listField,
  nameField,
  programField,
  rosterField,
  senderField,
];
