import { FilterItem } from './BasicFilter';

export const getDefaultItem = (
  items: FilterItem[] | undefined
): FilterItem | null => {
  // return null, if 'items' array is invalid or empty
  if (!items || !items.length) {
    return null;
  } else {
    // find the first item, marked as `default`
    let defaultItem = items.find((item) => item.isDefault);

    // if there are no items, marked as 'default', return the first one
    if (defaultItem === undefined) {
      [defaultItem] = items;
    }
    // return default item
    return defaultItem;
  }
};
