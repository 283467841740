// Vendor
import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

// Internal
import { useProductTypes } from 'hooks';
import AdvancedFilter from 'components/Filters/AdvancedFilter';

export interface Filters {
  selectedIdTypes: string[];
}

export interface Props {
  applyFilters: (filters: Filters, filtersActive: boolean) => void;
  disableFilters: boolean;
  // Controlled filters
  cFilters?: Filters;
  cFiltersActive?: boolean;
}

const ProductsFilter: FC<Props> = (props) => {
  const { applyFilters, disableFilters, cFilters, cFiltersActive } = props;

  const idTypes = useProductTypes().map((t) => t.name);

  const initialFilters: Filters = {
    selectedIdTypes: idTypes,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [filtersActive, setFiltersActive] = useState(false);

  // This effect allow us to force the load of the received (filters, filtersActive).
  useEffect(() => {
    if (cFilters !== undefined && !isEqual(cFilters, filters)) {
      setFilters(cFilters);
    }
    if (cFiltersActive !== undefined && cFiltersActive !== filtersActive) {
      setFiltersActive(cFiltersActive);
    }
  }, [cFilters, cFiltersActive]);

  useEffect(() => {
    setFiltersActive(
      !isEqual(filters.selectedIdTypes, initialFilters.selectedIdTypes)
    );
  }, [filters]);

  const filterDefinitions = [
    {
      filters: idTypes.map((t) => ({ label: t, name: t })),
      initialValues: initialFilters.selectedIdTypes,
      filterValues: filters.selectedIdTypes,
      fullSize: false,
      title: 'PRODUCT ID TYPE',
      id: 'idTypes',
    },
  ];

  return (
    <AdvancedFilter
      applyFilters={() => {
        applyFilters(
          {
            ...filters,
          },
          filtersActive
        );
      }}
      filtersActive={filtersActive}
      filterDefinitions={filterDefinitions}
      handleClear={() => setFilters(initialFilters)}
      setFilters={(newFilters) => {
        setFilters({
          ...filters,
          selectedIdTypes: newFilters.selectedPrograms,
        });
      }}
      disableFilters={disableFilters}
    />
  );
};

export default ProductsFilter;
