// Vendor
import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { Paper } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

// Internal
import { NUMBER_PER_PAGE } from 'common/constants';
import { Proposal, useApi, useAppBarSetTitle, useSnackbar } from 'hooks';
import { GET_CUSTOMER_PROPOSALS, GET_CUSTOMER_PROPOSALS_COUNT } from 'query';
import { Button, Pagination, SearchBar, Table } from 'components';
import { onDownload, TSV_TYPE } from 'services/export';
import {
  CurrentPageParamConfig,
  FiltersActiveParamConfig,
  FiltersParamConfig,
  SearchParamConfig,
} from 'hooks/persistedSearch/persistedSearchParams';
import { usePersistedSearchParams } from 'hooks/persistedSearch/persistedSearch';
import { SessionStorage } from 'common/helpers/keyValueStorage';
import { FiltersSerializationService } from 'common/helpers/filtersSerializationService';
import { buildWhereClause, Filters, getColumns, getRows } from './services';
import ListFilter from './Filter';

// Styles
import styles from './container.module.css';

const cx = classnames.bind(styles);

const CustomerProposalsContainer: FC = () => {
  useAppBarSetTitle('Customer Proposals');

  const [isDownloadLoading, setDownloadLoading] = useState(false);

  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);

  // SEARCH
  const { currentSearch } = usePersistedSearchParams(
    {
      module: 'customer-proposals',
      params: [
        SearchParamConfig,
        CurrentPageParamConfig,
        FiltersParamConfig,
        FiltersActiveParamConfig,
      ],
    },
    SessionStorage
  );
  const [searchText, setSearchText] = currentSearch[SearchParamConfig.key];

  // FILTERS
  const [currentFilters, updateFilters] = currentSearch[FiltersParamConfig.key];
  const [activeFilters, updateFiltersActive] =
    currentSearch[FiltersActiveParamConfig.key];
  const filters: Filters | undefined = useMemo(
    () => currentFilters && FiltersSerializationService.parse(currentFilters),
    [currentFilters]
  ) as Filters;
  const areFiltersActive = !!filters && activeFilters;

  // WHERE
  const [where, setWhere] = useState(
    buildWhereClause(searchText, filters, areFiltersActive)
  );

  const { data, loading, refetch } = useQuery(GET_CUSTOMER_PROPOSALS, {
    variables: {
      limit: NUMBER_PER_PAGE,
      offset: (currentPage - 1) * NUMBER_PER_PAGE,
      order: { timestamp: 'desc' },
      where,
    },
  });

  const {
    data: countData = {},
    loading: countLoading,
    refetch: countRefetch,
  } = useQuery(GET_CUSTOMER_PROPOSALS_COUNT, {
    variables: {
      where,
    },
  });

  // FETCH ALL TRIGGER SOURCES FOR FILTER
  const { data: sourcesData, loading: loadingSources } = useQuery(
    GET_CUSTOMER_PROPOSALS,
    {
      variables: {
        distinct: 'trigger_source',
        where: {},
        onlyTriggerSource: true,
      },
    }
  );

  const triggerSources =
    sourcesData?.dash_customer_proposals.map(
      (proposal: Proposal) => proposal.triggerSource
    ) ?? [];

  const proposals = data?.dash_customer_proposals || [];
  const totalCount =
    countData?.dash_customer_proposals_aggregate?.aggregate.count ?? 0;

  // BUILD SEARCH QUERY
  useEffect(() => {
    setCurrentPage(1);
    setWhere(buildWhereClause(searchText, filters, areFiltersActive));
  }, [filters, areFiltersActive, searchText]);

  useEffect(() => {
    refetch();
    countRefetch();
  }, []);

  const onError = (message: string) => {
    snackbar.open({ message, variant: 'error' });
  };

  const onSuccess = (message: string) =>
    snackbar.open({ message, variant: 'success' });

  const onHandleDownload = () => {
    setDownloadLoading(true);
    api
      ?.customerProposalGenerate({
        format: 'tsv',
        where,
      })
      .then(({ data }: { data: any }) => {
        const { exportId, status } = data;
        setDownloadLoading(status === 'processing');
        snackbar.open({
          message: `Hang tight! Your customer proposal file is being prepared for download. This might take a few minutes and we'll notify you when it's ready. Leaving or refreshing the page will cancel the export.`,
          variant: 'info',
        });
        checkForStatus(exportId);
      })
      .catch(() => {
        onError('Error generating proposals');
      });
  };

  const checkForStatus = (exportId: string) => {
    api?.exportStatus(exportId).then(({ data }) => {
      // @ts-ignore
      const { status } = data;
      if (status === 'complete') {
        // @ts-ignore
        const { downloadPath } = data.result;
        setDownloadLoading(false);
        api?.exportDownload(downloadPath).then(({ data }) => {
          const formatDate = (date?: string) =>
            dayjs(date).utc().format('YYYY_MM_DD');
          const anyDateRange = !!(
            filters?.dateRange.from || filters?.dateRange.to
          );
          const filteredText = isFiltered ? 'FILTERED_' : '';
          const today = formatDate();
          const from = filters?.dateRange.from
            ? formatDate(filters?.dateRange.from)
            : '1970_01_01';
          const to = filters?.dateRange.to
            ? formatDate(filters?.dateRange.to)
            : today;
          const timeLabel =
            areFiltersActive && anyDateRange ? `${from}_TO_${to}` : today;
          onDownload(
            data,
            `CUSTOMER_PROPOSALS_${filteredText}${timeLabel}.tsv`,
            TSV_TYPE
          );
          onSuccess(`Customer proposals downloaded`);
        });
      } else if (status === 'error') {
        onError('Error downloading proposals');
        setDownloadLoading(false);
      } else {
        window.setTimeout(() => checkForStatus(exportId), 500);
      }
    });
  };

  const columns = getColumns();
  const rows = getRows(proposals);

  const isFiltered = searchText !== '' || areFiltersActive;
  const hasCustomers = rows.length > 0;
  const disableSearchFilter = loading || (!hasCustomers && !isFiltered);
  const downloadBtnLabel = `Download ${isFiltered ? 'Filtered ' : ''}Proposals`;
  const downloadBtnWidth = isFiltered ? '200px' : '174px';

  return (
    <div className={cx('root')}>
      <div className={cx('row', 'toolbar')}>
        <div className={cx('row')}>
          <SearchBar
            disabled={disableSearchFilter}
            onSubmit={(searchText) => setSearchText(searchText)}
            placeholder='Search by customer ID or identifier'
            text={searchText || ''}
          />
          {!loadingSources && (
            <ListFilter
              applyFilters={(filters, areFiltersActive) => {
                updateFilters(filters);
                updateFiltersActive(areFiltersActive);
              }}
              disableFilters={disableSearchFilter}
              cFilters={filters}
              cFiltersActive={areFiltersActive}
              triggerSources={triggerSources}
            />
          )}
        </div>
        <div>
          <Button
            color='secondary'
            dataTestId='on-download-button'
            disabled={proposals.length === 0 || isDownloadLoading}
            label={downloadBtnLabel}
            loading={isDownloadLoading}
            onClick={onHandleDownload}
            style={{
              marginRight: 16,
              minHeight: '40px',
              width: downloadBtnWidth,
            }}
            variant='outlined'
          />
          <Button
            dataTestId='manage-rules-button'
            label='Manage Rules'
            onClick={() =>
              navigate('/customer-proposals/manage-rules', { replace: true })
            }
            style={{
              minHeight: '40px',
              width: '166px',
            }}
          />
        </div>
      </div>
      <Paper>
        <Table
          columns={columns}
          isLoading={loading}
          emptyText='No customer proposals found'
          rows={rows}
        />
      </Paper>
      <Pagination
        className={cx('pagination')}
        currentPage={currentPage}
        isDisplayed={proposals.length > 0}
        loading={countLoading}
        numberPerPage={NUMBER_PER_PAGE}
        onChangePage={({ currentPage }) => setCurrentPage(currentPage)}
        totalCount={totalCount}
      />
    </div>
  );
};

export default CustomerProposalsContainer;
