/*
  This file should contain function helpers related to creating specific Mediledger ID's.
  e.g. Lists -> ml:list:memberId:id
       Member -> ml:member:id
 */

export const toMediledgerMemberId = (memberId: string) =>
  `ml:member:${memberId}`;

export const toMediledgerListId = (id: string, memberId: number): string =>
  `ml:list:${memberId}:${id}`;

export const toMediledgerProductListId = (
  id: string,
  memberId: number
): string => `ml:product-list:${memberId}:${id}`;

export const toMediledgerContractHeaderId = (
  id: string,
  memberId: number
): string => `ml:contract-header:${memberId}:${id}`;

export const fromMediledgerIdToRawId = (mlId: string): string => {
  const split = mlId.split(':');
  if (split.length < 4) return mlId;
  const memberIdAndId = split.slice(2).join(':');
  return memberIdAndId.split(':').slice(1).join(':');
};
