export default () => ({
  hover: {
    '&:hover': {
      backgroundColor: '#B3D6FE',
    },
  },
  root: {
    backgroundColor: '#B3D6FE !important',
    '&:hover': {
      backgroundColor: '#B3D6FE',
    },
  },
});
