// Internal
import { AuthorityDatabases, NodeType } from 'common/config';
import { TableColumn, TableRow } from 'components/Table';
import {
  QueryClassOfTradeResult,
  QueryCustomerMembershipsResult,
  QueryCustomerView,
} from 'query';
import {
  decorateAndPartitionCustomerErrorsBySeverity,
  displayAddress,
  formatIdentifiers,
  formatOONErrorValues,
  getValidCustomerCOTFields,
  isListExpired,
  isRosterOON,
} from 'common/helpers';
import {
  ADDRESS_COLUMN,
  COT_COLUMN,
  ERRORS_COLUMN,
  EXTERNAL_ID_COLUMN,
  IDENTIFIERS_COLUMN,
  INTERNAL_ID_COLUMN,
  NAME_COLUMN,
  PROGRAM_COLUMN,
  UPDATED_AT_COLUMN,
} from 'components/Cells/constants';

export const getRows = (
  hasCotColumn: boolean,
  hasProgramColumn: boolean,
  acceptableAuthData: AuthorityDatabases[],
  memberName: string,
  customersQueryResult?: QueryCustomerView,
  cotQueryResult?: QueryClassOfTradeResult,
  membershipsData?: QueryCustomerMembershipsResult,
  attrColumns?: string[]
): TableRow[] => {
  if (
    !customersQueryResult ||
    !customersQueryResult.customers ||
    customersQueryResult.customers.length === 0
  ) {
    return [];
  }

  const customerProgramMap = getCustomerProgramMap(membershipsData);

  return customersQueryResult.customers.map((customer) => {
    const { id, memberId, timestamp } = customer;

    const isOON = isRosterOON(memberId);

    const identifiers = formatIdentifiers(customer.identifiers ?? []);
    const addresses =
      customer.addresses?.map((address) => displayAddress(address)) ?? [];
    const names = customer.names ?? [];
    const { severityErrors, severityWarnings } =
      decorateAndPartitionCustomerErrorsBySeverity(customer);
    const cots = hasCotColumn
      ? getValidCustomerCOTFields(customer, cotQueryResult)
      : [];

    const programs =
      customerProgramMap.get(`${customer.memberId}|${customer.id}`) || [];

    const isExpandable =
      (acceptableAuthData.length > 0 && identifiers?.length > 1) ||
      names.length > 1 ||
      addresses.length > 1 ||
      (hasCotColumn && cots?.length > 1) ||
      (hasProgramColumn && programs.length > 1);

    const formattedErrors = isOON
      ? formatOONErrorValues(severityErrors)
      : severityErrors;
    const formattedWarnings = isOON
      ? formatOONErrorValues(severityWarnings)
      : severityWarnings;
    const attributesToSpread: Record<string, string> = (
      attrColumns ?? []
    ).reduce(
      (acc, col) => ({
        ...acc,
        [col]:
          customer.attributes?.find(
            (attr) => attr.name.toUpperCase() === col.toUpperCase()
          )?.value ?? '',
      }),
      {}
    );

    return {
      type: 'expanding',
      isExpandable,
      data: {
        id,
        names,
        identifiers: acceptableAuthData.length > 0 ? identifiers : [],
        addresses,
        cots,
        programs,
        timestamp,
        severityErrors: formattedErrors,
        severityWarnings: formattedWarnings,
        memberId,
        memberName,
        ...attributesToSpread,
      },
    };
  });
};

export const getCustomerProgramMap = (
  membershipsData?: QueryCustomerMembershipsResult
): Map<string, string[]> => {
  const mapMemberships = new Map<string, string[]>();
  return (
    membershipsData?.memberships.reduce((mapResults, membership) => {
      if (!membership.list?.name || isListExpired(membership)) {
        return mapResults;
      }
      const key = `${membership.customerMemberId}|${membership.customerId}`;
      if (mapResults.has(key)) {
        mapResults.get(key)?.push(membership.list.name);
      } else {
        mapResults.set(key, [membership.list.name]);
      }
      return mapResults;
    }, mapMemberships) || mapMemberships
  );
};

export const getColumns = (
  isLocalCustomer: boolean,
  tpType: NodeType | undefined,
  acceptableAuthData: AuthorityDatabases[],
  rosterAttributeColumns: string[]
): {
  columns: TableColumn[];
  hasCotColumn: boolean;
  hasProgramColumn: boolean;
} => {
  const hasCotColumn = !isLocalCustomer || tpType !== 'DIST';
  const hasProgramColumn = !isLocalCustomer || tpType === 'GPO';
  const attrColumns: TableColumn[] = rosterAttributeColumns.map((name) => ({
    type: 'expanding',
    headerName: name.toUpperCase(),
    dataField: name,
    size: 'small',
  }));
  return {
    columns: [
      ...(isLocalCustomer ? [INTERNAL_ID_COLUMN] : []),
      ...(!isLocalCustomer ? [EXTERNAL_ID_COLUMN] : []),
      NAME_COLUMN,
      ...(acceptableAuthData.length > 0 ? [IDENTIFIERS_COLUMN] : []),
      ADDRESS_COLUMN,
      ...(hasCotColumn ? [COT_COLUMN] : []),
      ...(hasProgramColumn ? [PROGRAM_COLUMN] : []),
      ...(!isLocalCustomer && rosterAttributeColumns.length > 0
        ? attrColumns
        : []),
      UPDATED_AT_COLUMN,
      ERRORS_COLUMN,
    ],
    hasCotColumn,
    hasProgramColumn,
  };
};
